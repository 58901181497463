import { Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import ButtonSuccess from "../../home/buttonSuccess";
import { updateSiteId } from "../../action/registerAction";
class EditSiteTag extends Component {
  constructor(props) {
    super(props);
    this.submit.bind(this);
    this.state = {
      open: false,
      confirmSuccess: false,
      tag: ""
    };
  }
  handleOpen = async () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      tag: this.props.tag
    });
  };
  handleCancel = () =>
    this.setState({ open: false, confirmSuccess: false, tag: "" });
  handleChange = value => {
    this.setState({
      tag: value
    });
  };
  submit = () => {
    const updateSiteInput = {
      input: {
        siteId: this.props.siteId,
        serviceDate: this.props.serviceDate,
        tag: this.state.tag
      }
    };
    this.props.updateSiteId(updateSiteInput, this);
  }
  render() {
    const { Option } = Select;
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Icon as="i" name="edit" className="edit" onClick={this.handleOpen} />
        }
        open={open}
        className="add-box-form-container"
        style={{ width: "600px" }}
      >
        <Modal.Header>Modify Site Tag</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="8">
                  <label style={{ fontSize: 14 }}>Site Tag</label>
                  <Select
                    value={this.state.tag}
                    onChange={this.handleChange}
                    style={{
                      width: "100%",
                      marginTop: 5,
                      height: 35,
                      margin: "0 auto",
                      padding: "0 auto"
                    }}
                    placeholder="Please Select"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    {["Active", "Demo", "Trial"].map((p, i) => {
                      return (
                        <Option value={p} key={"tag" + i}>
                          {p}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.submit()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            OK
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
  updateSiteId: (updateSiteInput, self) =>
    dispatch(updateSiteId(updateSiteInput, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(EditSiteTag);
