import React, { Component } from "react";
import { Button, Form, Icon, Modal, Input, TextArea } from "semantic-ui-react";
import "../share/button.css";
import "../home/AccountStatus.css";
import ButtonSuccess from "../home/buttonSuccess";
import { changeTagSource } from "../action/boxAction";
import { connect } from "react-redux";

class TagSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tagSource: ""
    };
  }
  show = () =>
    this.setState({
      open: true,
      tagSource: this.props.tagSource
    });
  handleConfirm = async () => {
    this.setState({ confirmSuccess: true });

    const input = {
      tagId: this.props.tagId,
      tagSource: this.state.tagSource
    };
    this.props.changeTagSource(input, this);
  };

  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  handleInputChange(value) {
    this.setState({ tagSource: value });
  }

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={<Icon as="i" name="edit" className="edit" />}
        onClose={this.handleCancel}
        onOpen={this.show}
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>{"Siren Move Source"}</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <Input
                    className="note_comments"
                    onChange={(e, data) => {
                      this.handleInputChange(e.target.value);
                    }}
                    style={{ height: 50 }}
                    value={this.state.tagSource}
                    placeholder="Please input source..."
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  tagTimeWornByWeek: state.patientsListStore.tagTimeWornByWeek
});

const mapDispatchToProp = dispatch => ({
  changeTagSource: (input, self) => dispatch(changeTagSource(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(TagSource);
