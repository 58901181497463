import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Icon, Input } from "semantic-ui-react";
import { getLogFromS3, getPatientUserId } from "../action/SimulateAction";
import Progress from "../home/progress";
import "./PatientLog.css";
import "./simulateSock.css";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};

class PatientLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      searchSirenId: null,
      patient: null,
      patientInfo: null,
      userId: "",
      log: ""
    };
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  searchSirenIdChanged = event => {
    this.setState({ searchSirenId: event.target.value });
  };

  searchPatient = () => {
    const { getPatientUserId } = this.props;
    getPatientUserId(this, this.state.searchSirenId);
  };

  render() {
    const pageHeight = document.body.clientHeight - 200;
    const { getLogFromS3 } = this.props;
    return (
      <div className="device-list-container">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div>
          <div className="search_patient_container">
            <span className="search_label search_email_label">SirenId:</span>
            <Input
              placeholder="Search"
              type="text"
              onChange={this.searchSirenIdChanged.bind(this)}
              onBlur={event => this.searchPatient(event)}
              value={this.state.searchSirenId || ""}
            />
            <Icon
              name="search"
              size="small"
              className="search_icon"
              onClick={this.searchPatient}
            />
          </div>
          <div className="left_patientLog_box" style={{ height: pageHeight }}>
            <div className="patientLog_log_text">
              {this.props.logKeys.map((item, i) => {
                return (
                  <p
                    key={i}
                    onClick={() => {
                      getLogFromS3(this, item);
                    }}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="right_patientLog_box" style={{ height: pageHeight }}>
            <div className="patientLog_log_text">
              {/*<p>{this.state.log}</p>*/}
              <ReactMarkdown source={this.state.log} />
            </div>
          </div>
          <div className="clear_both" />
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  logKeys: state.boxInfoStore.logKeys
});
const mapDispatchToProp = dispatch => ({
  getPatientUserId: (self, sirenId) =>
    dispatch(getPatientUserId(self, sirenId)),
  getLogFromS3: (self, key) => dispatch(getLogFromS3(self, key))
});
export default connect(mapStateToProp, mapDispatchToProp)(PatientLog);
