import * as TYPES from "../types/types";
const initialState = {
  currentBoxInfo: [],
  replaceBoxInfo: [],
  logKeys: []
};

export default function boxInfo(state = initialState, action) {
  switch (action.type) {
    case TYPES.CURRENT_BOX_INFO:
      return {
        ...state,
        currentBoxInfo: action.text
      };
    case TYPES.REPLACE_BOX_INFO:
      return {
        ...state,
        replaceBoxInfo: action.text
      };
    case TYPES.LOG_KEYS:
      return {
        ...state,
        logKeys: action.text
      };
    default:
      return state;
  }
}
