import moment from "moment";
import React, { Component } from "react";
import Calendar from "react-calendar";
import {
  getMinuteWorn
} from "../action/patientAction";
import "./calendar.css";

export default class UserStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      wornDates: [],
      userId: ""
    };
  }
  turnChange = ({ activeStartDate, view }) => {
    let StartDate = moment(activeStartDate)
      .add(-6, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment(activeStartDate)
      .add(35, "days")
      .format("YYYY-MM-DD");
    getMinuteWorn(this.props.id, this.state.userId, StartDate, EndDate).then(data => {
      this.setState({
        wornDates: data
      });
    });
  };
  onChange = date => {
    this.setState({ date });
  };
  async componentDidMount() {
    const userId = this.props.complianceId;
    this.setState({ userId, id: this.props.id });
    this.getCurrentMonthCalendarDate(userId, this.props.id);
  }
  getCurrentMonthCalendarDate = (userId, id) => {
    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() + 1;
    currentMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth;
    let StartDate = moment(currentYear + "-" + currentMonth + "-01")
      .add(-6, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment().format("YYYY-MM-DD");
    getMinuteWorn(id, userId, StartDate, EndDate).then(data => {
      this.setState({
        wornDates: data
      })
    });
  };

  // everytime return a new function, so Calender will recalculate
  calendarClassName = patientWorn => ({ date, view }) => {
    if (!patientWorn) {
      return
    }
    let renderDate = moment(date).format("YYYY-MM-DD");
    const month = date.getMonth();
    let activemonth = new Date().getMonth();
    for (let k = 0; k < patientWorn.length; k++) {
      const item = patientWorn[k];
      if (item.date === renderDate && item.minuteWorn >= 30) {
        if (month === activemonth) {
          return "timeMore";
        } else {
          return "timeMoreLast";
        }
      }
    }
  };

  render() {
    return (
      <div style={{ width: 350, margin: "0 auto" }}>
        <Calendar
          onChange={this.onChange}
          value={this.state.date}
          tileClassName={this.calendarClassName(this.state.wornDates)}
          minDetail="month"
          locale="en"
          onActiveDateChange={this.turnChange}
          style={{ height: "340px" }}
        />
      </div>
    );
  }
}
