import _ from "lodash";
import moment from "moment";

/**
 *  Created by shengli.zhang on 4/2/21 9:43 AM
 */
const KGTOLB = 2.2046226; //1kg = 2.2046226lb
export function getWeightInfoOption(weightScaleList) {
  if (weightScaleList.length === 0) {
    return {};
  }
  const weightScale = _.orderBy(weightScaleList, ["localTime"], ["asc"]).map(
    p => {
      if (p.weightUnit && p.weightUnit === "kg") {
        const weightNew = (parseInt(p.weight) * KGTOLB).toFixed(2);
        return {
          localTime: moment(p.localTime).format("MM/DD/YY HH:mm"),
          weight: weightNew
        };
      }
      return {
        localTime: moment(p.localTime).format("MM/DD/YY HH:mm"),
        weight: p.weight
      };
    }
  );
  const weightY = weightScale.map(p => {
    return parseFloat(p.weight);
  });
  const weightX = weightScale.map(p => {
    return p.localTime;
  });
  const option = {
    title: {
      text: "Weight Trend",
      left: "right",
      top: 20
    },
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      axisPointer: {
        type: "line" //'line' | 'shadow'
      }
      // position: function(point, params, dom, rect, size) {
      //   // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
      //   let x = 0; // X position
      //   let y = 0; // Y position
      //
      //   // Current position
      //   let pointX = point[0];
      //
      //   // Outer view size
      //   let viewWidth = size.viewSize[0];
      //
      //   // Tooltip size
      //   let boxWidth = size.contentSize[0];
      //   let boxHeight = size.contentSize[1];
      //
      //   // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
      //   if (boxWidth >= viewWidth - pointX) {
      //     x = pointX - boxWidth - 20;
      //   } else {
      //     x = pointX + 20;
      //   }
      //
      //   y = (600 - boxHeight) / 2;
      //
      //   return [x, y];
      // }
    },
    color: ["#A600FF"],
    grid: {
      left: "3%",
      right: "4%",
      bottom: "2%",
      top: "20%",
      containLabel: true,
      tooltip: {
        trigger: "axis"
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -10,
        show: true,
        realtime: true,
        left: "25%",
        right: "25%",
        top: 20,
        start: weightX.length > 30 ? 30 : 0,
        end: 100
      }
    ],
    xAxis: [
      {
        position: "bottom",
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: weightX,
        axisLabel: {
          interval: "auto",
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "lb",
        type: "value",
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 10, 5, 0]
        },
        min: 0,
        max: _.max(weightY)
      }
    ],
    series: [
      {
        name: "Weight Scale",
        type: "line",
        data: weightY
      }
    ]
  };

  return option;
}
