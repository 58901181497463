import { Button, Divider, Form, Input, Modal, Row, Table, Switch } from 'antd';
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  listMedicalConditions,
  createMedicalCondition,
  editMedicalCondition,
  deleteMedicalCondition
} from "../../../action/userGroupAction";

const { confirm } = Modal;
const { Column } = Table;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

function defaultFilter(value, record) {
  return record.value.indexOf(value) === 0
}


class MedicalCondtionsManager extends Component {

  state = {
    loading: false,
    openAddModal: false,
    code: null,
    value: null,
  }

  closeModal = () => {
    this.setState({
      mode: null,
      openAddModal: false,
      code: null,
      value: null,
      chronic: false,
    })
  }

  commit = () => {
    this.setState({
      loading: true
    })
    if (this.state.mode === 'add') {
      this.props.createMedicalCondition(this.state.code.trim(), this.state.value && this.state.value.trim(), this.state.chronic ? 'Yes' : 'No')
        .then(() => {
          this.closeModal()
        })
        .finally(() => {
          this.setState({
            loading: false
          })
        })
    } else if (this.state.mode === 'edit') {
      this.props.editMedicalCondition(this.state.code.trim(), this.state.value && this.state.value.trim(), this.state.chronic ? 'Yes' : 'No')
        .then(() => {
          this.closeModal()
        })
        .finally(() => {
          this.setState({
            loading: false
          })
        })
    }
  }

  deleteCode = (record) => {
    confirm({
      title: `Are you sure you want to permanently delete condition "${record.condition}"?`,
      onOk: () => {
        this.setState({
          loading: true
        })
        this.props.deleteMedicalCondition(record.condition)
          .finally(() => {
            this.setState({
              loading: false
            })
          })
      }
    });
  }

  render() {
    return (
      <div style={{ textAlign: 'left' }}>
        <div style={{ marginBottom: 16 }}>
          <Button type="primary" onClick={() => { this.setState({ mode: 'add', openAddModal: true }) }}>
            Add condition
          </Button>
        </div>
        <Modal
          title={this.state.mode === 'add' ? 'Add Medical Condition' : 'Edit Medical Condition'}
          visible={this.state.openAddModal}
          onOk={this.commit}
          onCancel={this.closeModal}
        >
          <Form {...formItemLayout}>
            <Row gutter={[16, 16]}>
              <Form.Item
                label="Name"
                required
                className='form-item'
              >
                <Input
                  maxLength={50}
                  readOnly={this.state.mode === 'edit'}
                  value={this.state.code}
                  onChange={e => {
                    this.setState({ code: e.target.value.replace(',', ';') })
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Chronic"
                className='form-item'
              >
                <Switch checked={this.state.chronic} onChange={checked => this.setState({chronic: checked})} />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <Table dataSource={this.props.medicalConditions} loading={this.state.loading} rowKey="condition">
          <Column
            title="Name"
            dataIndex="value"
            onFilter={defaultFilter}
            sorter={(a, b) => {
              return a.value.localeCompare(b.value)
            }}
          />
          <Column
            title="Chronic"
            dataIndex="chronicType"
            onFilter={defaultFilter}
            sorter={(a, b) => {
              let fa = a.chronicType || ''
              let fb = b.chronicType || ''
              return fa.localeCompare(fb)
            }}
          />
          <Column title="Created by" dataIndex="createdBy" />
          <Column title="Created date" dataIndex="createdAt" />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <a onClick={() => {
                  this.setState({
                    mode: 'edit',
                    openAddModal: true,
                    code: record.condition,
                    chronic: record.chronicType === 'Yes',
                    value: record.value,
                  })
                }}>Edit</a>
                <Divider type="vertical" />
                <a onClick={() => this.deleteCode(record)}>Delete</a>
              </span>
            )}
          />
        </Table>
      </div>
    );
  }
}

const mapStateToProp = state => ({
  medicalConditions: state.app.medicalConditions
});
const mapDispatchToProp = dispatch => ({
  listMedicalConditions: () => dispatch(listMedicalConditions),
  createMedicalCondition: (condition, value, chronic) => dispatch(createMedicalCondition(condition, value, chronic)),
  editMedicalCondition: (condition, value, chronic) => dispatch(editMedicalCondition(condition, value, chronic)),
  deleteMedicalCondition: (condition) => dispatch(deleteMedicalCondition(condition))
});

export default connect(mapStateToProp, mapDispatchToProp)(MedicalCondtionsManager);
