export default function downloadFile(blob, filename) {
	let url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.download = filename;
  a.href = url;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function downloadCSV(csvStr, fileName) {
  const blob = new Blob([csvStr], {type: 'text/csv'})

  downloadFile(blob, fileName)
}

export function downloadJSON(obj, fileName) {
  const blob = new Blob([JSON.stringify(obj)], {type: 'application/json'})

  downloadFile(blob, fileName)
}