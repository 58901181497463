export function convertRelation(relation) {
  if (relation.includes("Husband") || relation.includes("Wife")) {
    return "Spouse/Partner";
  } else if (relation.includes("Daughter") || relation.includes("Son")) {
    return "Child";
  } else if (relation.includes("Brother") || relation.includes("Sister")) {
    return "Siblings";
  } else if (relation.includes("Granddaughter")) {
    return "Grandchild";
  }
  return relation;
}
