import { listStopwatchLogs, createStopwatchLog } from "../graphql/API"

const LocalLogKey = 'SavedLogKey'

class StopWatchLogManager {

  constructor() {
    this.retryCount = 0
    this.userGroups = null
  }

  checkInitial = () => {
    if (!this.userGroups) {
      console.error('StopWatchLogManager not initial, call "initialStopWatchLogManager" method firstly')
      return false
    }
    return true
  }

  fetchAllLogs = async (userId) => {
    const res = await listStopwatchLogs(userId)

    return res.data.listDoctorReviewHistory
  }

  startLog = (key) => {
    this.checkInitial();
    // only log nurse review activity
    if (!this.userGroups.includes('staff')) {
      return
    }
    if (this.currentLog) {
      console.error('there is a log, maybe meet a logic error')
      return
    }
    this.currentLog = {
      key,
      startTime: new Date()
    }
  }

  endLog = (key, timeSpent) => {
    this.checkInitial()
    // only log nurse review activity
    if (!this.userGroups.includes('staff')) {
      return
    }
    if (!this.currentLog || this.currentLog.key !== key) {
      console.error('log key error, end log may not match start log')
      return
    }
    Object.assign(this.currentLog, {
      timeSpent,
      endTime: new Date(),
    })
    const input = {
      userId: this.currentLog.key,
      reviewTime: this.currentLog.startTime,
      endTime: this.currentLog.endTime,
      timeSpent: this.currentLog.timeSpent
    }

    return createStopwatchLog(input).catch(err => {
      console.warn('failed to upload stopwatch log, saved locally')
      this.saveLogLocally()
    }).finally(() => {
      delete this.currentLog
    })
  }

  saveLogLocally = () => {
    if (!this.currentLog) {
      return
    }
    const savedLog = localStorage.getItem(LocalLogKey)

    let logs = []
    if (savedLog) {
      logs = JSON.parse(savedLog)
    }
    logs.push(this.currentLog)

    localStorage.setItem(LocalLogKey, JSON.stringify(logs))
  }

  uploadLocalLog = async () => {
    const savedLog = localStorage.getItem(LocalLogKey)

    if (!savedLog) {
      return
    }
    console.log('upload local logs')

    const logs = JSON.parse(savedLog)

    const tasks = logs.map(log => {
      const input = {
        userId: log.key,
        reviewTime: log.startTime,
        endTime: log.endTime,
        timeSpent: log.timeSpent
      }

      return createStopwatchLog(input)
    })

    const results = await Promise.allSettled(tasks)

    // handle exception
    const failLogs = []
    results.forEach((result, index) => {
      if (result.status === 'rejected') {
        failLogs.push(logs[index])
      }
    })

    if (failLogs.length > 0) {
      // give up to retry, network may interrupt
      if (this.retryCount >= 3) {
        return
      }
      this.retryCount++
      localStorage.setItem(LocalLogKey, JSON.stringify(failLogs))

      // sleep 1s, then retry
      await new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, 1000)
      })
      await this.uploadLocalLog()
    } else {
      // no exception
      this.retryCount = 0
      localStorage.removeItem(LocalLogKey)
    }
  }

}

const SharedStopWatchLogManager = new StopWatchLogManager()

export default SharedStopWatchLogManager

export function initialStopWatchLogManager(userGroups) {
  SharedStopWatchLogManager.userGroups = userGroups
}
