import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
import { Modal as AntdModal } from "antd";
/**
 *  Created by shengli.zhang on 8/16/21 10:28 AM
 */

export function listShoeTags(self, tagId) {
  return async dispatch => {
    try {
      self.setState({ open: true });
      const newEvent = await API.graphql(graphqlOperation(_api.listShoeTag()));
      dispatch(listShoeTagsChanged(newEvent.data.listShoeTags.items));
      self.setState({ open: false });
    } catch (e) {
      self.setState({ open: false });
      console.log(`get list tags error: ${e}`);
    }
  };
}

export function updateListShoeTag(listShoeTag) {
  return dispatch => {
    dispatch(listShoeTagsChanged(listShoeTag));
  };
}

function listShoeTagsChanged(listShoeTags) {
  return {
    type: TYPES.SHOE_TAG_LIST,
    text: listShoeTags
  };
}

export function addShoeTag(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.createShoeTag(), { input })
      );
      if (newEvent && newEvent.data.createShoeTag.status) {
        self.setState({
          open: false,
          mac: "",
          authCode: "",
          disableCreate: false
        });
        self.props.refreshShoeTagList();
      } else {
        self.setState({
          disableCreate: false
        });
        AntdModal.warning({
          title: "Add Tag Failed",
          content: JSON.stringify(newEvent),
          centered: true
        });
      }
    } catch (e) {
      self.setState({
        disableCreate: false
      });
      AntdModal.warning({
        title: "Add Tag Failed",
        content: `${JSON.stringify(e)}`,
        centered: true
      });
    }
  };
}
