import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Dropdown,
  Modal,
  Message
} from "semantic-ui-react";
import {
  addBox,
  getBoxInfo,
  getSockInfo,
  updateSockBox
} from "../action/boxAction";
import ButtonSuccess from "../home/buttonSuccess";
import "./AddBoxItem.css";
import { Tabs, Checkbox, Select, Modal as AntdModal } from "antd";
import _ from "lodash";
import { uppercaseRmSpace } from "../tools/stringProcess";

const { confirm } = AntdModal;
const { TabPane } = Tabs;
const { Option } = Select;
const footOptions = [
  { key: "left", text: "left", value: "left" },
  { key: "right", text: "right", value: "right" }
];

const SockSizeOptions = [
  { key: "S", text: "S", value: "S" },
  { key: "M", text: "M", value: "M" },
  { key: "L", text: "L", value: "L" }
];

const SockColorOptions = [
  { key: "white", text: "White", value: "white" },
  { key: "black", text: "Black", value: "black" }
];

export default class AddBoxItem extends Component {
  constructor(props) {
    super(props);
    this.boxId =
      moment().format("YYYYMMDD") + Math.round(Math.random() * 1000000);
    this.addSocks.bind(this);
    this.handleInputChange.bind(this);
    this.submit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.box = [];
    this.socks = [];
  }

  state = {
    open: false,
    box: [],
    socks: [],
    isSocksMax: false,
    boxAuthCode: "",
    confirmSuccess: false,
    hasConfirm: false,
    loading: false,
    errorMsg: null,
    reqBoxId: "",
    reqBoxInfo: [],
    reqSocks: [],
    activeKey: "add_manually",
    resBoxId: ""
  };

  handleOpen() {
    this.setState({
      open: true,
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null
    });
    this.boxId =
      moment().format("YYYYMMDD") + Math.round(Math.random() * 1000000);
  }

  close = () => {
    this.setState({
      open: false,
      box: [],
      socks: [],
      isSocksMax: false,
      boxAuthCode: "",
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null,
      reqBoxId: "",
      reqBoxInfo: [],
      reqSocks: [],
      activeKey: "add_manually",
      resBoxId: ""
    });
    this.box = [];
    this.socks = [];
    if (typeof this.props.onClose === "function") {
      this.props.onClose(true);
    }
  };

  addSocks() {
    let tmpSocks = Object.assign([], this.state.socks);
    if (tmpSocks.length < 14) {
      tmpSocks.push({});
      this.setState({ socks: tmpSocks });
    }
    if (tmpSocks.length === 14) {
      this.setState({ isSocksMax: true });
    } else {
      this.setState({ isSocksMax: false });
    }
  }
  submit() {
    this.setState({ hasConfirm: true, errorMsg: null });
    if (!(this.state.boxAuthCode && this.state.size && this.state.color)) {
      return;
    }

    const box = {
      boxId: this.boxId,
      authCode: this.state.boxAuthCode,
      socks:
        this.state.activeKey === "add_manually"
          ? this.state.socks
          : this.state.reqSocks,
      color: this.state.color,
      size: this.state.size,
      sku: this.genBoxSKU(this.state.size, this.state.color)
    };

    if (this.state.activeKey === "add_manually") {
      if (this.state.socks.length === 0) {
        AntdModal.warning({
          title: "Oops",
          content: "No socks in the new box.",
          centered: true
        });
        return;
      }
    } else {
      if (this.state.reqSocks.length === 0) {
        AntdModal.warning({
          title: "Oops",
          content: "No socks in the new box.",
          centered: true
        });
        return;
      }
    }
    this.setState({ loading: true });
    addBox(box)
      .then(async () => {
        if (this.state.activeKey === "add_from_box") {
          const newSocks = _.differenceBy(
            this.state.reqBoxInfo,
            this.state.reqSocks,
            "sn"
          );
          const input = {
            id: this.state.resBoxId,
            socks: newSocks
          };
          await updateSockBox(input);
        }
        this.setState({ confirmSuccess: true });
      })
      .catch(error => {
        console.log(error);
        this.setState({ errorMsg: error.toString() });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  genBoxSKU(size, color) {
    return `S${size}${color === "white" ? "W" : "B"}CCDUR`;
  }

  handleInputChange(field, value) {
    switch (field) {
      case "authCode":
        this.setState({
          boxAuthCode: uppercaseRmSpace(value.replace(/[^\w]/g, ""))
        });
        break;
      default:
        break;
    }
  }

  handleSockChange(index, field, value) {
    let socks = Object.assign([], this.state.socks);
    socks[index][field] = value;
    console.log(socks);
    this.setState({ socks: socks });
  }

  changeReqBoxId = event => {
    this.setState({ reqBoxId: event.target.value });
  };

  handleSearch = async () => {
    if (_.isEmpty(this.state.reqBoxId)) {
      AntdModal.warning({
        title: "Oops",
        content: "Please input Box Id.",
        centered: true
      });
      return;
    }
    try {
      const res = await getBoxInfo(this.state.reqBoxId);
      if (res.data.lambdaSearchBoxes.items.length > 0) {
        if (res.data.lambdaSearchBoxes.items[0].registered) {
          AntdModal.info({
            title: "Oops",
            content: `Box ${this.state.reqBoxId} has been registered, please unregister it first.`,
            centered: true
          });
          return;
        }
        const resBoxId = res.data.lambdaSearchBoxes.items[0].id;
        const reqBoxInfo = res.data.lambdaSearchBoxes.items[0].socks;
        const size = res.data.lambdaSearchBoxes.items[0].size;
        const color = res.data.lambdaSearchBoxes.items[0].color;
        this.setState({ reqBoxInfo, color, size, resBoxId, reqSocks: [] });
      }
    } catch (err) {
      console.log(`Search box(${this.state.reqBoxId}) info error: `, err);
    }
  };

  handleKeyPress = async e => {
    // We pass the new value of the text when calling onAccept
    if (e.key === "Enter") {
      // hack reset page
      const res = await getBoxInfo(this.state.reqBoxId);
      if (res.data.lambdaSearchBoxes.items.length > 0) {
        if (res.data.lambdaSearchBoxes.items[0].registered) {
          AntdModal.info({
            title: "Oops",
            content: `Box ${this.state.reqBoxId} has been registered, please unregister it first.`,
            centered: true
          });
          return;
        }
        const resBoxId = res.data.lambdaSearchBoxes.items[0].id;
        const reqBoxInfo = res.data.lambdaSearchBoxes.items[0].socks;
        const size = res.data.lambdaSearchBoxes.items[0].size;
        const color = res.data.lambdaSearchBoxes.items[0].color;
        this.setState({ reqBoxInfo, color, size, resBoxId, reqSocks: [] });
      }
    }
  };

  handleResSockChecked = (event, sockInfo) => {
    let reqSocks = this.state.reqSocks;
    if (event.target.checked) {
      const sock = {
        authCode: sockInfo.authCode,
        sn: sockInfo.sn,
        mac: sockInfo.mac,
        foot: sockInfo.foot
      };
      reqSocks.push(sock);
    } else {
      reqSocks = _.filter(reqSocks, function(t) {
        return t.sn !== sockInfo.sn;
      });
    }
    this.setState({ reqSocks });
  };

  handleWarnInfo = (event, sockInfo, info) => {
    confirm({
      title: "Oops...",
      content: info,
      cancelText: "No",
      onCancel: () => {},
      okText: "Yes",
      onOk: () => {
        this.handleResSockChecked(event, sockInfo);
      },
      centered: true
    });
  };

  handleReqSockChecked = (event, sockInfo) => {
    if (event.target.checked) {
      this.setState({ loading: true });
      getSockInfo(sockInfo.sn)
        .then(data => {
          const res = data.data.getSockBySerialNo;
          if (res) {
            let info = "";
            if (res.battery < 2800) {
              info = `Low Battery, would you like to continue choosing this sock?`;
              this.handleWarnInfo(event, sockInfo, info);
            } else if (res.status && res.status !== "OK") {
              info = `The sock has been marked with ${res.status}, would you like to continue choosing this sock?`;
              this.handleWarnInfo(event, sockInfo, info);
            } else if (res.lastSeen || res.dataLastSeen) {
              info = `This sock has been worn by others, would you like to continue choosing this sock?`;
              this.handleWarnInfo(event, sockInfo, info);
            } else {
              this.handleResSockChecked(event, sockInfo);
            }
          } else {
            this.handleResSockChecked(event, sockInfo);
          }
        })
        .catch(err => {
          console.log(`get sock info error: ${JSON.stringify(err)}`);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      this.handleResSockChecked(event, sockInfo);
    }
  };

  render() {
    const { open } = this.state;

    return (
      <Modal
        trigger={
          <Button size="small" onClick={this.handleOpen}>
            Add Box
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>Add Box Item</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form
              className="attached"
              loading={this.state.loading}
              error={this.state.errorMsg}
            >
              <Message
                error
                header="Action Forbidden"
                content={this.state.errorMsg}
              />
              <Form.Group>
                <Form.Field width="8">
                  <label>Box ID</label>
                  <span>{this.boxId}</span>
                </Form.Field>
                <Form.Field
                  width="8"
                  required
                  error={this.state.hasConfirm && !this.state.boxAuthCode}
                >
                  <label>Auth Code</label>
                  <Input
                    type="text"
                    value={this.state.boxAuthCode}
                    onChange={(e, data) => {
                      this.handleInputChange("authCode", e.target.value);
                    }}
                    maxLength={10}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field
                  width="8"
                  required
                  error={this.state.hasConfirm && !this.state.size}
                >
                  <label>Size</label>
                  <Dropdown
                    placeholder="Sock Size"
                    fluid
                    selection
                    options={SockSizeOptions}
                    value={this.state.size}
                    onChange={(e, data) => {
                      this.setState({ size: data.value });
                    }}
                    // disabled={this.state.activeKey === "add_from_box"}
                  />
                </Form.Field>
                <Form.Field
                  width="8"
                  required
                  error={this.state.hasConfirm && !this.state.color}
                >
                  <label>Color</label>
                  <Dropdown
                    placeholder="Sock Color"
                    fluid
                    selection
                    options={SockColorOptions}
                    value={this.state.color}
                    onChange={(e, data) => {
                      this.setState({ color: data.value });
                    }}
                    // disabled={this.state.activeKey === "add_from_box"}
                  />
                </Form.Field>
              </Form.Group>
              <Tabs
                style={{ textAlign: "left" }}
                onChange={activeKey => {
                  this.setState({ activeKey });
                }}
              >
                <TabPane tab="Add Manually" key="add_manually">
                  <Header as="h5" style={{ marginTop: -5 }}>
                    Socks
                    <Form.Field className="sock-add-icon-container">
                      <Icon
                        name="add"
                        className="sock-add-icon"
                        size="small"
                        onClick={e => {
                          this.addSocks();
                        }}
                      />
                      <Label
                        basic
                        color="red"
                        pointing="left"
                        className={
                          this.state.isSocksMax ? "v-visible" : "v-hidden"
                        }
                      >
                        Max Socks Count is 12!
                      </Label>
                    </Form.Field>
                  </Header>
                  {this.state.socks.map((item, index) => {
                    let sockDiv = (
                      <Form.Group widths="equal" key={"sock-" + index}>
                        <Form.Field>
                          <label>MAC</label>
                          <Input
                            placeholder="MAC"
                            onChange={(e, data) => {
                              this.handleSockChange(
                                index,
                                "mac",
                                uppercaseRmSpace(
                                  e.target.value.replace(/[^a-fA-F0-9:]/g, "")
                                )
                              );
                            }}
                            value={item.mac}
                            maxLength={17}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>Serial Number</label>
                          <Input
                            placeholder="Serial Number"
                            onChange={(e, data) => {
                              this.handleSockChange(
                                index,
                                "sn",
                                uppercaseRmSpace(
                                  e.target.value.replace(/[^\w]/g, "")
                                )
                              );
                            }}
                            value={item.sn}
                            maxLength={12}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>Sock Auth Code</label>
                          <Input
                            placeholder="Sock Auth Code"
                            onChange={(e, data) => {
                              this.handleSockChange(
                                index,
                                "authCode",
                                uppercaseRmSpace(
                                  e.target.value.replace(/[^\w]/g, "")
                                )
                              );
                            }}
                            value={item.authCode}
                            maxLength={8}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>Foot</label>
                          <Select
                            placeholder="Select Foot"
                            fluid
                            selection
                            onChange={e => {
                              this.handleSockChange(index, "foot", e);
                            }}
                            style={{ height: 37 }}
                          >
                            <Option value={"left"}>Left</Option>
                            <Option value={"right"}>Right</Option>
                          </Select>
                        </Form.Field>
                      </Form.Group>
                    );
                    return sockDiv;
                  })}
                </TabPane>
                <TabPane tab="Add From Box" key="add_from_box">
                  <div>
                    <div className="replace_input_box">
                      <Input
                        placeholder="Input Box Id"
                        className="replace_input_content"
                        onChange={this.changeReqBoxId}
                        value={this.state.reqBoxId.toUpperCase()}
                        onKeyPress={this.handleKeyPress}
                      />
                      <Button
                        size="small"
                        onClick={this.handleSearch}
                        className="replace_input_button"
                      >
                        Load
                      </Button>
                    </div>
                    {this.state.reqBoxInfo.map((p, index) => {
                      return (
                        <Form.Group widths="equal" key={"sock-" + index}>
                          <Form.Field style={{ width: 30 }}>
                            <Checkbox
                              style={{
                                marginTop: 10,
                                "--border-color": "black"
                              }}
                              checked={_.map(
                                this.state.reqSocks,
                                "sn"
                              ).includes(p.sn)}
                              onChange={e => this.handleReqSockChecked(e, p)}
                            />
                          </Form.Field>
                          <Form.Field>
                            <Input placeholder="MAC" value={p.mac} disabled />
                          </Form.Field>
                          <Form.Field>
                            <Input
                              placeholder="Serial Number"
                              value={p.sn}
                              disabled
                            />
                          </Form.Field>
                          <Form.Field>
                            <Input
                              placeholder="Sock Auth Code"
                              value={p.authCode}
                              disabled
                            />
                          </Form.Field>
                          <Form.Field>
                            <Dropdown
                              placeholder="Select Foot"
                              fluid
                              selection
                              options={footOptions}
                              value={p.foot}
                              disabled
                            />
                          </Form.Field>
                        </Form.Group>
                      );
                    })}
                  </div>
                </TabPane>
              </Tabs>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.submit()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Add
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.close()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
