import { API, graphqlOperation } from "aws-amplify";
import _api from "../graphql/_api";

export function upgradeApp(UpgradeAppInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.upgradeAppNotification(), UpgradeAppInput)
      );
      self.setState({ modalIsOpen: false });
      if (newEvent) {
        console.log(`newEvent: ${JSON.stringify(newEvent)}`);
      } else {
        console.log(`upgradeApp failed ${JSON.stringify(newEvent)}`);
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log(`upgradeApp exception ${JSON.stringify(e)}`);
    }
  };
}

export function checkPatientAppVersion(sirenId, appVersion, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.getSinglePatient(), {
          filter: {
            group: {
              ne: "TRIAL_SILENCE"
            },
            sirenId: { eq: sirenId }
          }
        })
      );
      if (newEvent) {
        const items = newEvent.data.lambdaSearchPatients.items;
        if (items.length > 0) {
          if (items[0].appVersion < appVersion) {
            self.upgradeAppNotifications(appVersion, items[0].userId);
          } else {
            self.setState({ modalIsOpen: false });
            self.warning(
              `There is not need ton upgrade app for the user(sirenId is ${sirenId}).`
            );
          }
        } else {
          self.setState({ modalIsOpen: false });
          self.info(`User not exist.`);
        }
      } else {
        self.setState({ modalIsOpen: false });
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log(`checkPatientVersion exception ${e}`);
    }
  };
}
