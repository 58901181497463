import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import EMRList from "../../emr/EMRist";
import SiteManagement from "./SiteManagement";

/**
 *  Created by shengli.zhang on 5/17/21 5:18 PM
 */
const { TabPane } = Tabs;
class SiteEMRManagement extends Component {

  render() {
    return (
      <div className="boxlistcontainer">
        <Tabs style={{ textAlign: "left" }} type="card">
          <TabPane tab="Sites" key="1">
            <SiteManagement />
          </TabPane>
          <TabPane tab="EMR" key="2">
            <EMRList />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(SiteEMRManagement);
