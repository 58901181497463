import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../SupportDashboard/account/SiteList.css";
import { Select } from "antd";
import Modal from "react-modal";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import "antd/dist/antd.css";
import AddPillBottle from "./AddPillBottle";
import RegisteredPillBottle from "./registerPillBottle";
import UnRegisteredPillBottle from "./unregisterPillBottle";
import moment from "moment";
import _ from "lodash";
import {
  listPillBottles,
  updateListPillBottle
} from "../action/pillBottleAction";
class PillBottleManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.props.listPillBottles(this);
  }

  refreshPage = () => {
    this.props.listPillBottles(this);
  };

  render() {
    const { Option } = Select;
    return (
      <div className="boxlistcontainer">
        <Modal
          isOpen={this.state.open}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="add_import_box" style={{ textAlign: "right" }}>
          <AddPillBottle
            refreshPillBottleList={() => {
              this.refreshPage();
            }}
          />
        </div>
        <ReactTable
          data={this.props.pillBottleList.map(p => {
            const registered = p.registered
              ? p.registered === "YES"
                ? "Yes"
                : "No"
              : "No";
            const createdAt = p.createdAt
              ? moment(p.createdAt).format("MM/DD/YYYY HH:mm")
              : "";
            const registeredAt = p.registeredAt
              ? moment(p.registeredAt).format("MM/DD/YYYY HH:mm")
              : "";
            return {
              ...p,
              registered,
              createdAt,
              registeredAt
            };
          })}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Pill Bottle Id",
              accessor: "pillBottleId",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.pillBottleId
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Pill Bottle",
              accessor: "mac",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.mac
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Auth Code",
              accessor: "authCode",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.authCode
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              style: { cursor: "pointer" },
              accessor: "registered",
              Header: "Registration Status",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                } else {
                  return row[filter.id] === filter.value;
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value={"Yes"}>Yes</Option>
                  <Option value={"No"}>No</Option>
                </Select>
              )
            },
            {
              Header: "Registered to",
              accessor: "sirenId",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.sirenId
                  ? String(row[filter.id]).toLowerCase()
                  : "";
                let filterfullname = String(filter.value).toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Registration Date",
              accessor: "registeredAt",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.registeredAt
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Manufacture Date",
              accessor: "createdAt",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.createdAt
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Actions",
              accessor: "",
              minWidth: 110,
              sortable: false,
              filterable: false,
              Cell: props => {
                return (
                  <div>
                    {props.original.registered === "Yes" ? (
                      <UnRegisteredPillBottle
                        item={props.original}
                        onChange={result => {
                          // const nextListData = _.clone(
                          //   this.props.pillBottleList
                          // );
                          // const item = _(nextListData).find({
                          //   pillBottleId: result.pillBottleId
                          // });
                          // item.registered = "NO";
                          // item.userId = "";
                          // this.props.updateListPillBottle(nextListData);
                          this.refreshPage();
                        }}
                      />
                    ) : (
                      <RegisteredPillBottle
                        item={props.original}
                        onChange={result => {
                          // const nextListData = _.clone(this.props.pillBottleList);
                          // const item = _(nextListData).find({
                          //   pillBottleId: result.pillBottleId
                          // });
                          // item.registered = "YES";
                          // item.userId = result.userId;
                          // this.props.updateListPillBottle(nextListData);
                          this.refreshPage();
                        }}
                      />
                    )}
                  </div>
                );
              }
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "pillBottleId",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  pillBottleList: state.patientsListStore.pillBottleList
});
const mapDispatchToProp = dispatch => ({
  listPillBottles: (self, pillBottleId) =>
    dispatch(listPillBottles(self, pillBottleId)),
  updateListPillBottle: listPillBottle =>
    dispatch(updateListPillBottle(listPillBottle))
});
export default connect(mapStateToProp, mapDispatchToProp)(PillBottleManagement);
