import { API, graphqlOperation } from "aws-amplify";
import { Control } from "react-keeper";
import _api from "../graphql/_api";
import { getPatientHubType } from "../tools/hubType";
import * as TYPES from "../types/types";
import { Modal as AntdModal } from "antd";
import { changeTagWeekWorn } from "./tagAction";
import _ from "lodash";

export function getUserBoxMgmt() {
  return dispatch => {
    API.get("dev-trial-dashboard", "/dashboard/listUserBox")
      .then(response => {
        dispatch(changeUserBoxLIstState(response.data.data));
      })
      .catch(error => {
        console.log("/dashboard/listUserBox err: ", error.response);
      });
  };
}
export function changeUserBoxLIstState(userBoxList) {
  return {
    type: TYPES.USER_BOX_LIST_NEW,
    text: userBoxList
  };
}

function changeBoxListState(boxList) {
  return {
    type: TYPES.BOX_LIST_NEW,
    text: boxList
  };
}
export function addBox(box) {
  const myInit = {
    body: box,
    headers: {} // OPTIONAL
  };
  return API.post("dev-trial-dashboard", "/admin/boxes", myInit);
}
// export function editAddBox(result, id, self) {
//   return async dispatch => {
//     const myInit = {
//       body: result,
//       id,
//       headers: {} // OPTIONAL
//     };
//     console.log(result, id);
//     API.put("dev-trial-dashboard", "/admin/boxes/" + id, myInit)
//       .then(response => {
//         self.setState({ open: false });
//         API.get("dev-trial-dashboard", "/dashboard/listBoxes")
//           .then(response => {
//             dispatch(changeBoxListState(response.data));
//           })
//           .catch(error => {
//             console.log(error.response);
//           });
//       })
//       .catch(error => {
//         console.log(error.response);
//       });
//   };
// }
// export function deleteBox(id, self) {
//   return async dispatch => {
//     const myInit = {
//       body: id,
//       headers: {} // OPTIONAL
//     };
//     API.del("dev-sock-box-manager", "/boxes/" + id, myInit)
//       .then(response => {
//         API.get("dev-trial-dashboard", "/dashboard/listBoxes")
//           .then(response => {
//             dispatch(changeBoxListState(response.data));
//           })
//           .catch(error => {
//             console.log(error.response);
//           });
//       })
//       .catch(error => {
//         console.log(error.response);
//       });
//   };
// }
export function breakUserSockLink(userId, sockId) {
  return async dispatch => {
    const myInit = {
      queryStringParameters: {
        sock: sockId,
        userId: userId
      }
    };
    API.get("dev-trial-dashboard", "/dashboard/unregisterSock", myInit)
      .then(response => {
        API.get("dev-trial-dashboard", "/dashboard/listUserBox")
          .then(response => {
            dispatch(changeUserBoxLIstState(response.data.data));
          })
          .catch(error => {
            console.log("/dashboard/listUserBox err: ", error.response);
          });
      })
      .catch(error => {
        console.log(error.response);
      });
  };
}

export function breakUserBoxLink(userId, boxId) {
  return async dispatch => {
    const myInit = {
      queryStringParameters: {
        box: boxId,
        userId: userId
      }
    };
    API.get("dev-trial-dashboard", "/dashboard/unregisterBox", myInit)
      .then(response => {
        API.get("dev-trial-dashboard", "/dashboard/listUserBox")
          .then(response => {
            dispatch(changeUserBoxLIstState(response.data.data));
          })
          .catch(error => {
            console.log("/dashboard/listUserBox err: ", error.response);
          });
      })
      .catch(error => {
        console.log(error.response);
      });
  };
}
export async function registerSock(creds) {
  const myInit = {
    queryStringParameters: {
      userId: creds.userId,
      boxId: creds.boxId,
      authCode: creds.authCode
    }
  };
  return API.get("dev-sock-box-manager", "/admin/registerAdmin", myInit);
}

export function registeredShoeTag(creds) {
  const apiName = "dev-iot-register-api";
  const path = "/admin/registerTagAdmin";
  const myInit = {
    queryStringParameters: {
      userId: creds.userId,
      authcode: creds.authcode,
      tagId: creds.tagId
    }
  };
  return API.get(apiName, path, myInit);
}

export function registeredPillBottle(creds) {
  const apiName = "dev-iot-register-api";
  const path = "/admin/registerPillBottleAdmin";
  const myInit = {
    queryStringParameters: {
      userId: creds.userId,
      authcode: creds.authcode,
      pillBottleId: creds.pillBottleId
    }
  };
  return API.get(apiName, path, myInit);
}

export function registerHub(creds) {
  const apiName = "dev-iot-register-api";
  const path = "/admin/registerHubAdmin";
  const myInit = {
    queryStringParameters: {
      userId: creds.userId,
      authcode: creds.authcode,
      serial: creds.serial
    }
  };
  return API.get(apiName, path, myInit);
}

export function unlinkRegisteredHub(creds) {
  const myInit = {
    queryStringParameters: {
      serial: creds.serial,
      userId: creds.userId
    }
  };
  return API.get("dev-iot-register-api", "/admin/unregisterHubAdmin", myInit);
}

export function unRegisteredShoeTag(creds) {
  const myInit = {
    queryStringParameters: {
      tagId: creds.tagId,
      userId: creds.userId
    }
  };
  return API.get("dev-iot-register-api", "/admin/unregisterTagAdmin", myInit);
}

export function unRegisteredPillBottle(creds) {
  const myInit = {
    queryStringParameters: {
      pillBottleId: creds.pillBottleId,
      userId: creds.userId
    }
  };
  return API.get(
    "dev-iot-register-api",
    "/admin/unregisterPillBottleAdmin",
    myInit
  );
}

export function getPatientByUserId(userId, self) {
  return async dispatch => {
    try {
      self.setState({ modalIsOpen: true });
      const filter = {
        userId: {
          eq: userId
        }
      };
      const newEvent = await API.graphql(
        graphqlOperation(_api.searchPatientsConnection(), {
          searchStr: "",
          filter,
          limit: 1
        })
      );
      self.setState({ modalIsOpen: false });
      if (newEvent && newEvent.data.lambdaSearchPatients.items.length > 0) {
        const id = newEvent.data.lambdaSearchPatients.items[0].id;
        const userId = newEvent.data.lambdaSearchPatients.items[0].userId;
        const userTimezone =
          newEvent.data.lambdaSearchPatients.items[0].userTimezone;
        const hubType = newEvent.data.lambdaSearchPatients.items[0].hubs
          ? getPatientHubType(newEvent.data.lambdaSearchPatients.items[0].hubs)
          : "";
        Control.go(
          "/sockstatus?BoxManagement/id=" +
            id +
            "&userId=" +
            userId +
            "&timeZone=" +
            userTimezone +
            "&hubType=" +
            hubType
        );
      } else {
        alert(`No this patient.`);
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("get patient by userid error: ", e);
    }
  };
}

export function changeHubStatus(input) {
  return API.graphql(
    graphqlOperation(_api.updateHubStatus(), {
      input
    })
  );
}

export function changeBoxStatus(input) {
  return API.graphql(
    graphqlOperation(_api.updateBoxStatus(), {
      input
    })
  );
}

export function changeTagSource(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateTagSource(), { input })
      );
      if (newEvent.data.updateTagSource.status) {
        const tagTimeWornByWeek = _.clone(self.props.tagTimeWornByWeek);
        let tagInfo = _(tagTimeWornByWeek).find({ tagId: input.tagId });
        if (tagInfo) {
          tagInfo.tagSource = input.tagSource;
        }
        dispatch(changeTagWeekWorn(tagTimeWornByWeek));
        self.setState({ open: false, confirmSuccess: false });
      } else {
        AntdModal.error({
          title: " Update Source Failed",
          content: newEvent.data.updateTagSource.message,
          centered: true
        });
      }
    } catch (e) {
      console.log("change tag source error: ", e);
      AntdModal.error({
        title: " Update Source Failed",
        content: JSON.stringify(e),
        centered: true
      });
    }
  };
}

export async function getBoxInfo(boxId) {
  try {
    const filter = { id: { wildcard: boxId } };
    const sort = { field: "registeredAt", direction: "desc" };
    return await API.graphql(
      graphqlOperation(_api.searchableBoxConnection(), {
        filter,
        sort,
        limit: 20,
        nextToken: 0
      })
    );
  } catch (e) {
    console.log(`get box info error: ${e}`);
  }
}

export async function updateSockBox(input) {
  try {
    const newEvent = await API.graphql(
      graphqlOperation(_api.updateSockBox(), {
        input
      })
    );
  } catch (e) {
    console.log(`update sock box error: ${e}`);
  }
}

export function getSockInfo(serialNo) {
  return API.graphql(
    graphqlOperation(_api.getSockBySerialNo(), {
      serialNo
    })
  );
}
