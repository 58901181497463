import * as TYPES from "../types/types";

const initialState = {
  userBoxList: []
};

export default function userBoxList(state = initialState, action) {
  switch (action.type) {
    case TYPES.USER_BOX_LIST_NEW:
      return {
        ...state,
        userBoxList: action.text
      };
    default:
      return state;
  }
}
