import patientsListReducer from "./patientReducer";
import authGroupReducer from "./authReducer";
import userBoxListReducer from "./userBoxListReducer";
import boxListReducer from "./boxListReducer";
import boxInfoReducer from "./boxInfoReducer";
import reportListReducer from "./doctorReportReducer";
import monthlyReportReducer from "./monthlyReportReducer";
import app from "./appReducer";
import tableFilter from "./tableFilterReducer";

const reducers = {
  patientsListStore: patientsListReducer,
  authGroupStore: authGroupReducer,
  userBoxListStore: userBoxListReducer,
  boxListStore: boxListReducer,
  boxInfoStore: boxInfoReducer,
  reportListStore: reportListReducer,
  monthlyReportStore: monthlyReportReducer,
  app,
  tableFilter
};
export default reducers;
