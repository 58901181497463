import ReactEcharts from "echarts-for-react";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { getTagBatteryInfo } from "../action/batteryChartAction";
import commonStyles from "../common/CommonStyles";
import { getMacInfoOption } from "../constant/batteryChartOptions";
import Progress from "../home/progress";

class tagBatteryChartsEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      sirenId: "",
      serialNo: "",
      startDate: "",
      endDate: "",
      battery: [],
      steps: [],
      count: []
    };
  }
  returnSockPage() {
    window.history.go(-1);
  }

  onChartReady = echarts => {
    // console.log("echart is ready", echarts);
  };

  componentDidMount() {
    const allParams = window.location.href.split("?")[1];
    console.log(allParams);
    let params = queryString.parse(allParams);
    const sirenId = params.sirenId;
    const serialNo = this.serialNoToMacAddress(params.serialNo);
    let startDate = moment()
      .add(-180, "days")
      .format("YYYY-MM-DD");
    let endDate = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
    this.setState({
      url: window.location.href,
      sirenId,
      serialNo,
      startDate,
      endDate
    });
    this.props.getTagBatteryInfo(serialNo, startDate, endDate, this);
  }

  serialNoToMacAddress = serialNo => {
    const macAddress = [];
    for (let i = 0; i < serialNo.length; i += 2) {
      macAddress.push(serialNo.substring(i, i + 2));
    }

    let format = "";
    let len = macAddress.length;
    for (let j = 0; j < len; j++) {
      format += macAddress[j];
      if (j !== len - 1) {
        format += ":";
      }
    }
    return format;
  };

  render() {
    return (
      <div className="battery_container">
        <Modal
          isOpen={this.props.loading_charts}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <Button
          basic
          id="return-to-device-list"
          onClick={() => this.returnSockPage()}
        >
          <Icon name="reply" />
          Return to list
        </Button>
        <ReactEcharts
          option={getMacInfoOption(
            this.state.battery,
            this.state.steps,
            this.state.count
          )}
          style={{ height: 400 }}
          lazyUpdate={true}
          onChartReady={this.onChartReady}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  loading_charts: state.patientsListStore.loading_charts
});
const mapDispatchToProp = dispatch => ({
  getTagBatteryInfo: (serialNo, startDate, endDate, self) =>
    dispatch(getTagBatteryInfo(serialNo, startDate, endDate, self))
});
export default connect(
  mapStateToProp,
  mapDispatchToProp
)(tagBatteryChartsEquipment);
