import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import getPatchTrProps from "../tools/reactTablePatch";
import { lowercaseRmSpace } from "../tools/stringProcess";
import "../SupportDashboard/account/NewSite.css";
import "../SupportDashboard/account/SiteList.css";
import {
  getLpnPatients,
  getLpnStaff,
  refreshLpnPatientsList,
  removeLpnForPatient
} from "../action/lpnManagementAction";
import queryString from "query-string";
import Modal from "react-modal";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import { Checkbox, Select } from "antd";
import moment from "moment";
import _ from "lodash";
import UpdateLpnForPatients from "./UpdateLpnForPatients";
import { Auth } from "aws-amplify";
import { Button } from "semantic-ui-react";
import { getlistClinicSites } from "../action/registerAction";
const { Option } = Select;

class LpnPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      selectedPatients: [],
      lpnInfo: {},
      selectedSite: "",
      listSites: [],
      lpn: "",
      showLpnManagement: false,
      userGroups: []
    };
  }

  async componentWillMount() {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    this.setState({
      showLpnManagement:
        groups.includes("admin") || groups.includes("developer"),
      userGroups: groups
    });
    if (this.props.clinicSites.length === 0) {
      this.props.getlistClinicSites();
    }
  }

  componentDidMount() {
    const params = queryString.parse(window.location.href.split("?")[1]);
    const lpn = params.id.replace(" ", "+");
    if (this.props.listSirenStaff.length === 0) {
      this.props.getLpnStaff(lpn, this);
      this.setState({ lpn });
    } else {
      const lpnInfo = _(this.props.listSirenStaff).find({ id: lpn });
      this.setState({ lpnInfo, lpn });
    }
    this.props.getLpnPatients(lpn, this, this.state.selectedSite);
  }

  refresh = patients => {
    this.setState({ modalIsOpen: true });
    const self = this;
    setTimeout(function() {
      self.props.getLpnPatients(self.state.lpn, self, self.state.selectedSite);
    }, 3000);
    // const newPatients = this.props.listLpnPatients.map(p => {
    //   const patient = _(patients).find({ id: p.id });
    //   if (patient) {
    //     return null;
    //   }
    //   return p;
    // });
    // if (!_.isEmpty(this.state.selectedSite)) {
    //   const filterPatients = _.filter(newPatients, {
    //     siteId: this.state.selectedSite
    //   });
    //   this.props.refreshLpnPatientsList(_.compact(filterPatients));
    // } else {
    //   this.props.refreshLpnPatientsList(_.compact(newPatients));
    // }
  };

  onCheckSite = value => {
    this.setState({
      selectedSite: value
    });
    if (value.length === 0) {
      this.props.getLpnPatients(this.state.lpn, this, "");
    } else {
      this.props.getLpnPatients(this.state.lpn, this, value);
    }
  };

  handleCheckAll = e => {
    const checked = e.target.checked;
    let selectedPatients = [];
    if (checked) {
      selectedPatients = this.props.listLpnPatients.map(p => {
        return p.id;
      });
    }
    this.setState({
      selectedPatients
    });
  };
  handleCheck = (e, data) => {
    let selectedPatients = [...this.state.selectedPatients];
    if (e.target.checked) {
      selectedPatients.push(data.original.id);
    } else {
      selectedPatients = selectedPatients.filter(p => p !== data.original.id);
    }

    this.setState({
      selectedPatients
    });
  };

  render() {
    const trProps = getPatchTrProps(({ rowInfo }) => {
      // console.log(rowInfo);
    });
    return (
      <div className="boxlistcontainer">
        {this.state.showLpnManagement ? (
          <div>
            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              style={commonStyles}
              transitionEnterTimeout={10000}
              transitionLeaveTimeout={10000}
            >
              <Progress />
            </Modal>
            <div style={{ textAlign: "left", marginLeft: 5, marginBottom: 10 }}>
              <strong>SiteId: </strong>
              <Select
                // mode="multiple"
                style={{ minWidth: 200, maxWidth: 400 }}
                placeholder="Please select site"
                onChange={this.onCheckSite}
                value={this.state.selectedSite}
              >
                <Option key={0} value={""}>
                  All
                </Option>
                {this.state.listSites.map(site => (
                  <Option key={site.siteId} value={site.siteId}>
                    {site.siteId} - {site.siteName}
                  </Option>
                ))}
              </Select>
              {this.state.userGroups.includes("admin") ? (
                <UpdateLpnForPatients
                  patients={this.state.selectedPatients}
                  lpnInfo={this.state.lpnInfo}
                  site={this.state.selectedSite}
                  refresh={this.refresh.bind(this)}
                />
              ) : null}
            </div>
            <ReactTable
              getTrProps={trProps}
              data={
                this.props.listLpnPatients &&
                this.props.listLpnPatients.map(p => {
                  const serviceE = p.serviceE
                    ? p.serviceE.split("/")[1] === moment().format("YYYY-MM")
                      ? p.serviceE.split("/")[0]
                      : "No"
                    : "No";
                  const totalMinutesSpent = p.lastDMT
                    ? moment().format("YYYY-MM") ===
                      moment(p.lastDMT).format("YYYY-MM")
                      ? p.totalMinutesSpent
                        ? p.totalMinutesSpent
                        : "0"
                      : "0"
                    : "0";
                  const nurse = `${this.state.lpnInfo.firstName} ${this.state.lpnInfo.lastName}`;
                  return {
                    ...p,
                    nurse,
                    serviceE,
                    totalMinutesSpent
                  };
                })
              }
              filterable
              defaultFilterMethod={(filter, row) =>
                lowercaseRmSpace(String(row[filter.id])).includes(
                  lowercaseRmSpace(filter.value)
                )
              }
              columns={[
                {
                  Header: props => {
                    return (
                      <Checkbox
                        onChange={this.handleCheckAll}
                        indeterminate={
                          this.state.selectedPatients.length > 0 &&
                          this.state.selectedPatients.length <
                            this.props.listLpnPatients.length
                        }
                        checked={
                          this.state.selectedPatients.length > 0 &&
                          this.state.selectedPatients.length ===
                            this.props.listLpnPatients.length
                        }
                        name="selectAll"
                        className="billCheck_all"
                      />
                    );
                  },
                  accessor: "",
                  maxWidth: 60,
                  sortable: false,
                  filterable: false,
                  Cell: props => {
                    return (
                      <div onClick={e => e.stopPropagation()}>
                        <Checkbox
                          onChange={e => this.handleCheck(e, props)}
                          checked={this.state.selectedPatients.includes(
                            props.original.id
                          )}
                          name="selectSite"
                        />
                      </div>
                    );
                  }
                },
                {
                  Header: "SiteID",
                  accessor: "siteId",
                  minWidth: 60
                },
                {
                  Header: "SiteName",
                  accessor: "siteName",
                  minWidth: 100
                },
                {
                  Header: "SirenID",
                  accessor: "sirenId",
                  minWidth: 60
                },
                {
                  Header: "First Name",
                  accessor: "firstName"
                },
                {
                  Header: "last Name",
                  accessor: "lastName"
                },
                {
                  Header: "DOB",
                  accessor: "birthday"
                },
                {
                  Header: "Smartphone",
                  accessor: "patientHasSmartPhone",
                  maxWidth: 200,
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "Yes") {
                      return row[filter.id] === "Yes";
                    }
                    if (filter.value === "No") {
                      return row[filter.id] === "No";
                    }
                    if (filter.value === "Caregiver") {
                      return row[filter.id] === "Caregiver";
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <Select
                      className="select_common_style"
                      style={{ width: "100%" }}
                      onChange={onChange}
                      value={filter ? filter.value : "all"}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      optionFilterProp="children"
                    >
                      <Option value="all">All</Option>
                      <Option value="Yes">Yes</Option>
                      <Option value="No">No</Option>
                      <Option value="Caregiver">Caregiver</Option>
                    </Select>
                  )
                },
                {
                  Header: "Nurse",
                  accessor: "nurse"
                },
                {
                  Header: "Last DMT",
                  accessor: "lastDMT"
                },
                {
                  Header: "Service E",
                  accessor: "serviceE",
                  maxWidth: 200,
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "Yes") {
                      return row[filter.id] === "Yes";
                    }
                    if (filter.value === "No") {
                      return row[filter.id] === "No";
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <Select
                      className="select_common_style"
                      style={{ width: "100%" }}
                      onChange={onChange}
                      value={filter ? filter.value : "all"}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      optionFilterProp="children"
                    >
                      <Option value="all">All</Option>
                      <Option value="Yes">Yes</Option>
                      <Option value="No">No</Option>
                    </Select>
                  )
                },
                {
                  Header: "Total Time(mins)",
                  accessor: "totalMinutesSpent"
                },
                {
                  Header: "Days Worn (Last 4 days)",
                  accessor: "last4DaysWorn",
                  maxWidth: 200,
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "0") {
                      return row[filter.id] === 0;
                    }
                    if (filter.value === "1") {
                      return row[filter.id] === 1;
                    }
                    if (filter.value === "2") {
                      return row[filter.id] === 2;
                    }
                    if (filter.value === "3") {
                      return row[filter.id] === 3;
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <Select
                      className="select_common_style"
                      style={{ width: "100%" }}
                      onChange={onChange}
                      value={filter ? filter.value : "all"}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      optionFilterProp="children"
                    >
                      <Option value="all">All</Option>
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                    </Select>
                  )
                },
                {
                  Header: "Special Case",
                  accessor: "specialCase",
                  maxWidth: 200,
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "Yes") {
                      return row[filter.id] === "Yes";
                    }
                    if (filter.value === "No") {
                      return row[filter.id] === "No";
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <Select
                      className="select_common_style"
                      style={{ width: "100%" }}
                      onChange={onChange}
                      value={filter ? filter.value : "all"}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      optionFilterProp="children"
                    >
                      <Option value="all">All</Option>
                      <Option value="Yes">Yes</Option>
                      <Option value="No">No</Option>
                    </Select>
                  )
                },
                {
                  Header: "Remove LPN",
                  sortable: false,
                  filterable: false,
                  show: this.state.userGroups.includes("admin"),
                  Cell: props => {
                    return (
                      <div>
                        <Button
                          onClick={() => {
                            this.props.removeLpnForPatient(
                              [
                                {
                                  id: props.original.id,
                                  LPN: props.original.LPN
                                }
                              ],
                              this
                            );
                          }}
                          size="mini"
                          className="deleteButton"
                        >
                          Remove
                        </Button>
                      </div>
                    );
                  }
                }
              ]}
              defaultPageSize={20}
              defaultSorted={[
                {
                  id: "sirenId",
                  desc: false
                }
              ]}
              className="-striped -highlight"
              minRows={0}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProp = state => ({
  listLpnPatients: state.patientsListStore.listLpnPatients,
  listSirenStaff: state.patientsListStore.listSirenStaff,
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
  getLpnPatients: (lpn, self, site) =>
    dispatch(getLpnPatients(lpn, self, site)),
  getLpnStaff: (lpn, self) => dispatch(getLpnStaff(lpn, self)),
  removeLpnForPatient: (patients, self) =>
    dispatch(removeLpnForPatient(patients, self)),
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  refreshLpnPatientsList: patients => dispatch(refreshLpnPatientsList(patients))
});
export default connect(mapStateToProp, mapDispatchToProp)(LpnPatient);
