import { Select } from "antd";
import _, { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Popup } from "semantic-ui-react";
import { getlistClinicSites } from "../action/registerAction";
import EditSite from "../SupportDashboard/account/EditSite";
import "../SupportDashboard/account/NewSite.css";
import "../SupportDashboard/account/SiteList.css";
import * as EMRs from "../tools/EMRList";
import { formatNumber } from "../tools/phoneFormat";
import getPatchTrProps from "../tools/reactTablePatch";
import RemoveLpnForSite from "./RemoveLpnForSite";
const { Option } = Select;

class LpnSiteList extends Component {
  state = {
    overallSites: [],
    onStaffing: [],
    notOnStaffing: [],
    assignedInOnStaffing: [],
    assignedInNotOnStaffing: [],
    notAssignedInOnStaffing: [],
    notAssignedInNotOnStaffing: [],
    selectIndex: 0,
    displaySiteList: []
  };

  componentDidMount() {
    if (this.props.clinicSites.length === 0) {
      this.props.onLoading(true);
      const { getlistClinicSites } = this.props;
      getlistClinicSites().finally(() => {
        this.props.onLoading(false);
      });
    }
    this.countSites();
  }

  countSites = () => {
    let allSites = _.clone(this.props.clinicSites);
    let primaryLpnObj = allSites.map(p => {
      return {
        ...p,
        primaryLpns: _.filter(this.props.listSirenStaff, function(t) {
          return (
            !_.isEmpty(t.primarySites) &&
            JSON.parse(t.primarySites).includes(p.siteId)
          );
        })
      };
    });
    const allNewSites = primaryLpnObj.map(p => {
      if (p.primaryLpns.length > 0) {
        const primaryLpnIds = p.primaryLpns.map(t => t.id);
        const backupLpns = _.filter(this.props.listSirenStaff, function(t) {
          return (
            !_.isEmpty(t.backupFor) &&
            _.intersection(primaryLpnIds, JSON.parse(t.backupFor)).length > 0
          );
        });
        return {
          ...p,
          backupLpns
        };
      }
      return {
        ...p,
        backupLpns: []
      };
    });
    const onStaffing = _.filter(allNewSites, { onStaffing: "Yes" });
    const notOnStaffing = _.filter(allNewSites, {
      onStaffing: "No"
    });
    const allPrimarySites = _.filter(this.props.listSirenStaff, function(t) {
      return !_.isEmpty(t.primarySites);
    });
    let allAssignedSites = [];
    for (const lpn of allPrimarySites) {
      const site = JSON.parse(lpn.primarySites);
      allAssignedSites = [...allAssignedSites, ...site];
    }
    const assignedSite = _.filter(onStaffing, function(t) {
      return allAssignedSites.includes(t.siteId);
    });
    const notAssignedSite = _.filter(onStaffing, function(t) {
      return !allAssignedSites.includes(t.siteId);
    });
    const assignedSiteInNotOnStaffing = _.filter(notOnStaffing, function(t) {
      return allAssignedSites.includes(t.siteId);
    });
    const notAssignedSiteInNotOnStaffing = _.filter(notOnStaffing, function(t) {
      return !allAssignedSites.includes(t.siteId);
    });
    const series = [
      allNewSites,
      onStaffing,
      notOnStaffing,
      assignedSite,
      notAssignedSite,
      assignedSiteInNotOnStaffing,
      notAssignedSiteInNotOnStaffing
    ];
    this.setState({
      overallSites: allNewSites,
      onStaffing,
      notOnStaffing,
      assignedInOnStaffing: assignedSite,
      notAssignedInOnStaffing: notAssignedSite,
      assignedInNotOnStaffing: assignedSiteInNotOnStaffing,
      notAssignedInNotOnStaffing: notAssignedSiteInNotOnStaffing,
      displaySiteList: series[this.props.selectSiteIndex],
      selectIndex: this.props.selectSiteIndex
    });
  };

  renderSummaryStat() {
    const statData = [
      {
        num: this.state.overallSites.length,
        list: this.state.overallSites,
        name: "Total sites"
      },
      {
        num: this.state.onStaffing.length,
        list: this.state.onStaffing,
        name: "Staffing sites"
      },
      {
        num: this.state.assignedInOnStaffing.length,
        list: this.state.assignedInOnStaffing,
        name: "Assigned staffing sites"
      },
      {
        num: this.state.notAssignedInOnStaffing.length,
        list: this.state.notAssignedInOnStaffing,
        name: "Unassigned staffing sites"
      },
      {
        num: this.state.notOnStaffing.length,
        list: this.state.notOnStaffing,
        name: "Non-staffing sites"
      },
      {
        num: this.state.assignedInNotOnStaffing.length,
        list: this.state.assignedInNotOnStaffing,
        name: "Assigned non-staffing sites"
      },
      {
        num: this.state.notAssignedInNotOnStaffing.length,
        list: this.state.notAssignedInNotOnStaffing,
        name: "Unassigned non-staffing sites"
      }
    ];
    return (
      <div
        className="summary_des_container"
        style={{ whiteSpace: "nowrap", overflowX: "auto", overflowY: "hidden" }}
      >
        {statData.map((data, index) => (
          <div
            className="summary_des_box"
            style={{
              display: "flex",
              backgroundColor:
                this.state.selectIndex === index ? "#4F1974" : "#B892D2",
              cursor: "pointer"
            }}
            key={data.name}
            onClick={() => {
              this.setState({
                selectIndex: index,
                displaySiteList: statData[index].list
              });
            }}
          >
            <div style={{ marginTop: 15, marginLeft: 10 }}>
              <p className="summary_des_number" style={{ color: "#fff" }}>
                {data.num}
              </p>
              <p
                className="summary_des_title"
                style={{ color: "#fff", fontWeight: "bold" }}
              >
                {data.name}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const trProps = getPatchTrProps(({ event: e }) => {
      //WARNING: black magic
      const buttons = e.currentTarget.getElementsByTagName("button");
      for (const button of buttons) {
        if (button.innerHTML === "Edit") {
          button.click();
          break;
        }
      }
    });
    return (
      <div>
        {this.renderSummaryStat()}
        <ReactTable
          getTrProps={trProps}
          data={
            this.state.displaySiteList &&
            this.state.displaySiteList.map(p => {
              const onServicePatients =
                p.onServicePatients && parseInt(p.onServicePatients);
              const activePatients =
                p.activePatients && parseInt(p.activePatients);
              const phone = !isEmpty(p.phone)
                ? formatNumber(p.phone).trim()
                : "";
              const siteAddress =
                (p.address ? p.address : "") +
                (p.city ? ", " + p.city : "") +
                (p.state ? ", " + p.state : "") +
                (p.zip ? " " + p.zip : "");
              const hubContract = p.hubContract
                ? p.hubContract.replace("Wifi", "Wi-Fi")
                : "Wi-Fi";
              return {
                ...p,
                onServicePatients,
                activePatients,
                phone,
                siteAddress,
                hubContract
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              columns: [
                {
                  Header: "Site Id",
                  accessor: "siteId",
                  filterMethod: (filter, row) =>
                    String(row[filter.id]).includes(filter.value)
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Name",
                  accessor: "name",
                  filterMethod: (filter, row) => {
                    let rowfilterfullname = row._original.name
                      ? row[filter.id].toLowerCase()
                      : "";
                    let filterfullname = filter.value.toLowerCase();
                    return rowfilterfullname.includes(filterfullname);
                  }
                }
              ]
            },
            {
              style: { cursor: "pointer" },
              accessor: "EMRSystem",
              Header: "EMR System",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                } else {
                  return row[filter.id] === filter.value;
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  {EMRs.EMRList.map(p => {
                    return (
                      <Option value={p} key={p}>
                        {p}
                      </Option>
                    );
                  })}
                </Select>
              )
            },
            {
              columns: [
                {
                  Header: "Contact",
                  accessor: "contact",
                  filterMethod: (filter, row) => {
                    let rowfilterfullname = row._original.contact
                      ? row[filter.id].toLowerCase()
                      : "";
                    let filterfullname = filter.value.toLowerCase();
                    return rowfilterfullname.includes(filterfullname);
                  }
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Service Day Type",
                  accessor: "serviceDate",
                  filterMethod: (filter, row) => {
                    let rowfilterfullname = row._original.serviceDate
                      ? row[filter.id].toLowerCase()
                      : "";
                    let filterfullname = filter.value.toLowerCase();
                    return rowfilterfullname.includes(filterfullname);
                  }
                }
              ]
            },
            {
              style: { cursor: "pointer" },
              accessor: "onStaffing",
              Header: "Staffing",
              Cell: props => (
                <div>
                  {props.value === "Yes" ? "On Staffing" : "Not On Staffing"}
                </div>
              ),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                return row[filter.id] === filter.value;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="No">Not On Staffing</Option>
                  <Option value="Yes">On Staffing</Option>
                </Select>
              )
            },
            {
              columns: [
                {
                  Header: "On Service Patients",
                  accessor: "onServicePatients",
                  sortable: true,
                  filterMethod: (filter, row) =>
                    String(row[filter.id]).includes(String(filter.value))
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Active Patients",
                  accessor: "activePatients",
                  sortable: true,
                  filterMethod: (filter, row) =>
                    String(row[filter.id]).includes(String(filter.value))
                }
              ]
            },
            {
              Header: "Primary Nurse",
              accessor: "primaryLpns",
              maxWidth: 400,
              Cell: props => {
                let primaryList = [];
                if (props.value) {
                  primaryList = props.value;
                  primaryList = primaryList.map(p => {
                    return `${p.firstName} ${p.lastName}`;
                  });
                }
                return (
                  <Popup
                    trigger={
                      <p className="sr-text-ellipsis">{primaryList.join(",")}</p>
                    }
                  >
                    {primaryList.map((name, index) => (
                      <p key={index}>{name}</p>
                    ))}
                  </Popup>
                );
              },
              filterMethod: (filter, row) =>
                row[filter.id]
                  .map(p => {
                    const lpn = _(this.props.listSirenStaff).find({ id: p.id });
                    if (lpn) {
                      return `${lpn.firstName} ${lpn.lastName}`;
                    }
                    return "";
                  })
                  .join(",")
                  .toLowerCase()
                  .includes(String(filter.value).toLowerCase())
            },
            {
              Header: "Backup Nurse",
              accessor: "backupLpns",
              maxWidth: 400,
              Cell: props => {
                let backUpList = [];
                if (props.value) {
                  backUpList = props.value;
                  backUpList = backUpList.map(p => {
                    return `${p.firstName} ${p.lastName}`;
                  });
                }
                return (
                  <Popup
                    trigger={
                      <p className="sr-text-ellipsis">{backUpList.join(",")}</p>
                    }
                  >
                    {backUpList.map((name, index) => (
                      <p key={index}>{name}</p>
                    ))}
                  </Popup>
                );
              },
              filterMethod: (filter, row) =>
                row[filter.id]
                  .map(p => {
                    const lpn = _(this.props.listSirenStaff).find({ id: p.id });
                    if (lpn) {
                      return `${lpn.firstName} ${lpn.lastName}`;
                    }
                    return "";
                  })
                  .join(",")
                  .toLowerCase()
                  .includes(String(filter.value).toLowerCase())
            },
            {
              columns: [
                {
                  Header: "Remove Nurse",
                  accessor: "",
                  minWidth: 80,
                  sortable: false,
                  filterable: false,
                  show: this.props.userGroups.includes("admin"),
                  Cell: props => {
                    return (
                      <div onClick={e => e.stopPropagation()}>
                        <RemoveLpnForSite
                          siteId={props.original.siteId}
                          size="mini"
                          className="deleteButton"
                        >
                          Remove
                        </RemoveLpnForSite>
                        <EditSite newSite={props.original} />
                      </div>
                    );
                  }
                }
              ]
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "siteId",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  listSirenStaff: state.patientsListStore.listSirenStaff
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites())
});
export default connect(mapStateToProp, mapDispatchToProp)(LpnSiteList);
