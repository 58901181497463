import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
import "./DocMedicalDetails.css";
import { connect } from "react-redux";
import { Control } from "react-keeper";
import Status from "../../medicaldetails/status";
import Calendar1 from "./calendar";
import Calendar2 from "../../medicaldetails/calendarRange";
import Photo from "./Photo";
import { Tabs } from "antd";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { filter, isEmpty } from "lodash";
import moment from "moment";
import queryString from "query-string";
import Modal from "react-modal";
import StarRatingComponent from "react-star-rating-component";
import { getlistStaff } from "../../action/registerAction";
import commonStyles from "../../common/CommonStyles";
import Progress from "../../home/progress";
import Tracker99457 from "../../report/Tracker99457";
import { formatNumber } from "../../tools/phoneFormat";
import EditProfileInfo from "../account/EditProfileInfo2";
import "./DocMedicalDetails.css";
import PatientSym from "./PatientSym";
import SirenNotes from "./SirenNotes";
import { getDoctorDetails } from "../../action/patientAction";
const { TabPane } = Tabs;
class BrokenMedicalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      date: new Date(),
      url: "",
      Height: 356,
      id: "",
      userId: "",
      hubType: "",
      modalIsOpen: false,
      rating: 0,
      sirenId: "",
      timeZone: ""
    };
    this.download = this.download.bind(this);
    this.returnHome = this.returnHome.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  onChangeDateRange = DateRangeDate => {
    this.setState({ DateRangeDate });
    this.setState({ ifOpen: true });
  };
  returnHome() {
    this.state.url.includes("FavoriteList")
      ? Control.go(
          "/sockstatus?FavoriteList/id=" +this.state.id
          + "&userId=" +  this.state.userId + "&timeZone=" + this.state.timeZone + "&hubType=" + this.state.hubType
        )
      : Control.go(
        "/sockstatus?id=" + this.state.id + "&userId=" +  this.state.userId + "&timeZone=" + this.state.timeZone + "&hubType=" + this.state.hubType
      );
  }
  async componentDidMount() {
    const { getDoctorDetails, getlistStaff } = this.props;
    getlistStaff();
    const allParams = window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const id = allParams.split("id=")[1].split("&")[0];
    const userId = params.userId;
    this.setState({ url: window.location.href });
    const DateRangeDate_endDate = window.location.href.includes("endDate")
      ? params.endDate
      : moment().format("YYYY-MM-DD");
    let currentYear = DateRangeDate_endDate.split("-")[0];
    let currentMonth = DateRangeDate_endDate.split("-")[1];
    let StartDate = moment(currentYear + "-" + currentMonth + "-01")
      .add(-6, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
    let timeZone = params.timeZone;
    const hubType = params.hubType;
    getDoctorDetails(id, userId ,this, StartDate, EndDate);
    this.setState({ id,userId, timeZone,hubType });
    window.location.href.includes("support") ||
    window.location.href.includes("admin") ||
    window.location.href.includes("TrialHome")
      ? this.setState({ showAppoint: true })
      : this.setState({ showAppoint: false });
  }
  download() {
    this.setState({ modalIsOpen: true });
    const self = this;
    // html2canvas(document.body, {
    let baseInfoHeight = 0;
    html2canvas(document.getElementById("user-info-container"), {
      useCORS: true
    }).then(function(canvas) {
      baseInfoHeight = canvas.height;
    });
    html2canvas(document.getElementById("user_detail_medical"), {
      useCORS: true,
      allowTaint: false,
      scale: 2
    }).then(function(canvas) {
      html2canvas(document.getElementById("user-info-container"), {
        useCORS: true
      }).then(function(canvasBase) {
        baseInfoHeight = canvasBase.height;
        var pdf = new jsPDF("p", "mm", "a4"); //A4纸，纵向
        var ctx = canvas.getContext("2d"),
          a4w = 190,
          a4h = 277, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
          // imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
          imgHeight = canvas.height, //按A4显示比例换算一页图像的像素高度(+1是为了防止画布大小正好等于图片大小时溢出导致crash)
          renderedHeight = 0;
        pdf.page = 1;
        while (renderedHeight < baseInfoHeight) {
          var page = document.createElement("canvas");
          page.width = canvas.width;
          // page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页
          page.height = baseInfoHeight; //可能内容不足一页
          // page.height = canvas.height; //可能内容不足一页
          //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
          page
            .getContext("2d")
            .putImageData(
              ctx.getImageData(
                0,
                renderedHeight,
                canvas.width,
                Math.min(baseInfoHeight, canvas.height - renderedHeight)
              ),
              0,
              0
            );
          pdf.setFontSize(10);
          pdf.text(204, 294, String(pdf.page)); //print number bottom right
          pdf.page++;
          pdf.addImage(
            page.toDataURL("image/jpeg", 1.0),
            "JPEG",
            10,
            10,
            a4w,
            Math.min(a4h, (a4w * page.height) / page.width)
          ); //添加图像到页面，保留10mm边距
          renderedHeight += baseInfoHeight;
          if (renderedHeight <= baseInfoHeight) pdf.addPage(); //如果后面还有内容，添加一个空页
          // delete page;
        }

        while (renderedHeight < canvas.height) {
          page = document.createElement("canvas");
          page.width = canvas.width;
          // page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页
          page.height = canvas.height - baseInfoHeight; //可能内容不足一页
          // page.height = canvas.height; //可能内容不足一页
          //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
          page
            .getContext("2d")
            .putImageData(
              ctx.getImageData(
                0,
                renderedHeight,
                canvas.width,
                Math.min(imgHeight, canvas.height - renderedHeight)
              ),
              0,
              0
            );
          pdf.setFontSize(10);
          pdf.text(204, 294, String(pdf.page)); //print number bottom right
          pdf.page++;
          pdf.addImage(
            page.toDataURL("image/jpeg", 1.0),
            "JPEG",
            10,
            10,
            a4w,
            a4h
            // Math.min(a4h, (a4w * page.height) / page.width)
          ); //添加图像到页面，保留10mm边距
          renderedHeight += canvas.height - baseInfoHeight;
          if (renderedHeight < canvas.height) pdf.addPage(); //如果后面还有内容，添加一个空页
          // delete page;
        }
        pdf.save("medical-details.pdf");
        self.setState({ modalIsOpen: false });
      });
    });
  }

  async onStarClick(nextValue, prevValue, name) {
    if (this.state.rating === 1) {
      this.setState({ rating: 0 });
      let user = await Auth.currentAuthenticatedUser();
      Auth.userAttributes(user).then(attribute => {
        const favorite = filter(attribute, { Name: "custom:favorite" });
        // console.log("remove favorite: ", favorite);
        if (favorite.length > 0 && isEmpty(favorite[0].Value)) {
          let favoriteValue = favorite[0].Value.split("_");
          // console.log("before remove favoriteValue: ", favoriteValue);
          if (favoriteValue.indexOf(this.state.sirenId) >= 0) {
            favoriteValue.splice(favoriteValue.indexOf(this.state.sirenId), 1);
            let newfavoriteValue = favoriteValue
              .map(item => {
                return item;
              })
              .join("_");
            // console.log("after remove favoriteValue: ", newfavoriteValue);
            Auth.updateUserAttributes(user, {
              "custom:favorite": newfavoriteValue
            })
              .then(data => {
                // console.log("updateUserAttributes: ", data);
              })
              .catch(error => {
                console.log("updateUserAttributes error: ", error);
              });
          }
        }
      });
    } else {
      this.setState({ rating: 1 });
      let user = await Auth.currentAuthenticatedUser();
      Auth.userAttributes(user).then(attribute => {
        const favorite = filter(attribute, { Name: "custom:favorite" });
        // console.log("add favorite: ", favorite);
        if (favorite.length > 0) {
          let favoriteValue = favorite[0].Value.split("_");
          // console.log(
          //   "favorite.length > 0, before add favoriteValue: ",
          //   favoriteValue
          // );
          if (favoriteValue.indexOf(this.state.sirenId) < 0) {
            favoriteValue.push(this.state.sirenId);
            let newfavoriteValue = favoriteValue
              .map(item => {
                return item;
              })
              .join("_");
            // console.log(
            //   "favorite.length > 0, after add favoriteValue: ",
            //   newfavoriteValue
            // );
            Auth.updateUserAttributes(user, {
              "custom:favorite": newfavoriteValue
            })
              .then(data => {
                // console.log("updateUserAttributes: ", data);
              })
              .catch(error => {
                console.log("updateUserAttributes error: ", error);
              });
          }
        } else {
          let favoriteValue = [];
          favoriteValue.push(this.state.sirenId);
          let newfavoriteValue = favoriteValue
            .map(item => {
              return item;
            })
            .join("_");
          // console.log("favorite.length = 0, after add favoriteValue: ", newfavoriteValue);
          Auth.updateUserAttributes(user, {
            "custom:favorite": newfavoriteValue
          })
            .then(data => {
              // console.log("updateUserAttributes: ", data);
            })
            .catch(error => {
              console.log("updateUserAttributes error: ", error);
            });
        }
      });
    }
  }

  getDoctorName = doctorId => {
    let doctorInfo = filter(this.props.listStaff, { email: doctorId });
    if (doctorInfo.length > 0) {
      return (
        (doctorInfo[0].firstName ? doctorInfo[0].firstName : "") +
        " " +
        (doctorInfo[0].lastName ? doctorInfo[0].lastName : "")
      );
    }
  };

  render() {
    const userName =
      this.props.doctorDetails &&
      this.props.doctorDetails.userInfo &&
      !isEmpty(this.props.doctorDetails.userInfo.firstName)
        ? this.props.doctorDetails.userInfo.firstName +
          " " +
          this.props.doctorDetails.userInfo.lastName
        : this.props.doctorDetails && this.props.doctorDetails.givenName
        ? this.props.doctorDetails.givenName
        : "";
    const userBirth =
      this.props.doctorDetails &&
      this.props.doctorDetails.userInfo &&
      !isEmpty(this.props.doctorDetails.userInfo.birthday)
        ? moment(this.props.doctorDetails.userInfo.birthday).format(
            "MM/DD/YYYY"
          )
        : this.props.doctorDetails && this.props.doctorDetails.birthday
        ? moment(this.props.doctorDetails.birthday).format("MM/DD/YYYY")
        : "";
    return (
      <div>
        <div
          className="device-detail-container"
          style={{
            height: 120,
            backgroundColor: "#2D0172",
            display: "flex"
          }}
        >
          <strong style={{ color: "white", fontSize: 16, marginLeft: 45 }}>
            {userName}
          </strong>
          <div style={{ color: "white", fontSize: 16, marginLeft: 20 }}>|</div>
          <div
            style={{
              color: "white",
              fontSize: 14,
              marginLeft: 20,
              paddingTop: 3
            }}
          >
            Site ID:{" "}
            {this.props.doctorDetails && this.props.doctorDetails.siteId}
          </div>
          <div style={{ color: "white", fontSize: 16, marginLeft: 20 }}>|</div>
          <div
            style={{
              color: "white",
              fontSize: 14,
              marginLeft: 20,
              paddingTop: 3
            }}
          >
            Siren ID:{" "}
            {this.props.doctorDetails && this.props.doctorDetails.sirenId}
          </div>
          <div style={{ color: "white", fontSize: 16, marginLeft: 20 }}>|</div>
          <div
            style={{
              color: "white",
              fontSize: 14,
              marginLeft: 20,
              paddingTop: 3
            }}
          >
            DOB: {userBirth}
          </div>
          <div style={{ color: "white", fontSize: 16, marginLeft: 20 }}>|</div>
          <div
            style={{
              color: "white",
              fontSize: 14,
              marginLeft: 20,
              paddingTop: 3
            }}
          >
            Cell:{" "}
            {this.props.doctorDetails && this.props.doctorDetails.phone
              ? formatNumber(this.props.doctorDetails.phone)
              : ""}
          </div>
          <div style={{ color: "white", fontSize: 16, marginLeft: 20 }}>|</div>
          <div
            style={{
              color: "white",
              fontSize: 14,
              marginLeft: 20,
              paddingTop: 3
            }}
          >
            Prescriber:{" "}
            {this.props.doctorDetails && this.props.doctorDetails.doctorId
              ? this.getDoctorName(this.props.doctorDetails.doctorId)
              : ""}
          </div>
          <div style={{ color: "white", fontSize: 16, marginLeft: 20 }}>|</div>
          <EditProfileInfo
            userInfo={this.props.doctorDetails}
          />
        </div>
        <Tabs
          defaultActiveKey="1"
          style={{
            textAlign: "left",
            marginTop: 20
          }}
          tabBarStyle={{ marginLeft: 65 }}
        >
          <TabPane tab="Medical" key="1">
            <div
              className="device-detail-container"
              style={{ textAlign: "center" }}
            >
              <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={commonStyles}
                transitionEnterTimeout={10000}
                transitionLeaveTimeout={10000}
              >
                <Progress />
              </Modal>
              <Button
                basic
                id="return-to-device-list"
                onClick={this.returnHome}
                style={{ marginTop: 70 }}
              >
                <Icon name="reply" />
                Return to list
              </Button>
              <Button
                className="dowButton"
                basic
                onClick={() => this.download()}
              >
                <Icon name="download" />
                Download Report
              </Button>
              <div
                style={{
                  position: "relative",
                  fontSize: 30,
                  float: "left",
                  marginTop: 20
                }}
              >
                <StarRatingComponent
                  name="rate1"
                  starCount={1}
                  value={this.state.rating}
                  onStarClick={this.onStarClick.bind(this)}
                />
              </div>
              <div style={{clear:"both"}}></div>
              <div id="user_detail_medical">
                <div id="user-info-container">
                  <div className="info" style={{ width: "38%" }}>
                    {this.props.doctorDetails.sirenId ? (
                      <SirenNotes sirenId={this.props.doctorDetails.sirenId} />
                    ) : null}
                    <PatientSym />
                  </div>
                  <div
                    className="info"
                    style={{
                      marginRight: "1%",
                      marginLeft: "1%",
                      width: "34%"
                    }}
                  >
                    <div className="title">COMPLIANCE CALENDAR</div>
                    <Calendar1 staff={true} />
                  </div>
                  <div className="info" style={{ width: "24%" }}>
                    <div className="title">FOOT WARNING STATUS</div>
                    <Status staff={true} />
                  </div>
                  <div
                    className="info"
                    style={{
                      marginTop: "2%",
                      marginBottom: "2%",
                      width: "100%",
                      height: 360
                    }}
                  >
                    <div className="title">PHOTOS</div>
                    <Photo staff={true} />
                  </div>
                  <div id="user-info-container4" />
                </div>
                <div id="charts_container_id">
                  <div className="title">PATIENT DATA</div>
                  <Calendar2 />
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="RPM Tracker 99457-8" key="2">
            <Tracker99457 />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  listStaff: state.patientsListStore.listStaff,
});

const mapDispatchToProp = dispatch => ({
  getDoctorDetails: (id,userId, self, startDate, endData) =>
    dispatch(getDoctorDetails(id,userId, self, startDate, endData)),
  getlistStaff: () => dispatch(getlistStaff())
});
export default connect(mapStateToProp, mapDispatchToProp)(BrokenMedicalDetails);
