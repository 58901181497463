import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import "./NewSite.css";

class AdminSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }
  render() {
    const { open } = this.state;
    return (
      <Modal open={open} className="add-box-form-container">
        <Modal.Header>New Admin Account Has Been Created</Modal.Header>
        <Modal.Content>
          <div className="success_box">
            <Icon name="check circle" id="siteIcon" />
            <p className="siteTitle">Congratulations!</p>
            <p className="finishtext">
              <span>UserName: </span>
              <span className="addUnderline">
                &nbsp;{this.props.userName},&nbsp;
              </span>
              <span>password: </span>
              <span className="addUnderline">
                &nbsp;<strong>Siren123!</strong>
              </span>
            </p>
            <p className="staff_log_tip">
              Please have admin log into Siren Admin Dashboard to reset
              password.
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" onClick={this.props.closeAddAdminUser}>
            <Icon name="remove" />
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(AdminSuccess);
