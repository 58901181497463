import { Icon, Input, notification, Radio } from "antd";
import ReactECharts from "echarts-for-react";
import echarts from "echarts/lib/echarts";
import React, { Component } from "react";
import { connect } from "react-redux";
import { markSockData } from "../graphql/API";
import { udpateSockDataMark } from "../action/chartAction";
import {
  getChartDeltaOptions,
  getChartDeltaWashedOptions,
  getChartLeftPositionOptions,
  getChartLeftTempOptions,
  getChartLeftTempWashedOptions,
  getChartRightPositionOptions,
  getChartRightTempOptions,
  getChartRightTempWashedOptions,
  getChartStepsOptions
} from "../constant/chartOptions";

class patientCharts extends Component {
  state = {
    makeMark: false,
    markFoot: null,
    markDuration: [],
    markPosition: [],
    confirmLoading: false
  };

  linkCharts() {
    // the onEvents props will cause rerender, then cause onChartReady call multiple times.
    if (this.hasLinked) {
      return;
    }

    let charts = [
      this.myChart1,
      this.myChart2,
      this.myChart3,
      this.myChart4,
      this.myChart5,
      this.myChart6
    ];
    for (const chart of charts) {
      if (chart) {
        chart.group = "link";
      } else {
        // if chart not ready, dont link them
        return;
      }
    }
    if (this.props.showDiff) {
      charts = [this.myChart1d, this.myChart3d, this.myChart4d];
      for (const chart of charts) {
        if (chart) {
          chart.group = "link";
        } else {
          return;
        }
      }
    }

    this.hasLinked = true;
    echarts.connect("link");
  }

  eventMapLeft = {
    brushEnd: params => this.onBrushSelect("left", params),
    dblclick: params => this.onCleanedChartDblClick("left", params)
  };

  eventMapRight = {
    brushEnd: params => this.onBrushSelect("right", params),
    dblclick: params => this.onCleanedChartDblClick("right", params)
  };

  onCleanedChartDblClick = (foot, params) => {
    // disconnect these two charts, or action will be dispatched to both of them
    this.myChart3d.group = null;
    this.myChart4d.group = null;
    let operateChart, otherChart;
    if (foot === "left") {
      operateChart = this.myChart3d;
      otherChart = this.myChart4d;
    } else {
      operateChart = this.myChart4d;
      otherChart = this.myChart3d;
    }
    // clean the possible brush select
    // otherChart.dispatchAction({
    //   type: 'brush',
    //   command: 'clear',
    //   areas: [],
    // })

    let start, end;
    if (params.componentSubType === "custom") {
      start = params.data[0];
      end = params.data[1];
    } else {
      const coord = params.data.coord;
      const xAxisData = this.myChart3d.getOption().xAxis[0].data;
      start = xAxisData.findIndex(e => e === coord[0][0]);
      end = xAxisData.findIndex(e => e === coord[1][0]);
    }

    // show brush area
    operateChart.dispatchAction({
      type: "brush",
      areas: [
        {
          brushType: "lineX",
          xAxisIndex: 0,
          coordRange: [start, end]
        }
      ]
    });
    // trigger `brushEnd` action
    operateChart.dispatchAction({
      type: "brushEnd",
      brushId: `${foot}foot`,
      areas: [
        {
          brushType: "lineX",
          xAxisIndex: 0,
          coordRange: [start, end]
        }
      ]
    });

    this.myChart3d.group = "link";
    this.myChart4d.group = "link";
  };

  onBrushSelect = (foot, params) => {
    // ignore action caused by connected chart
    if (params.brushId != `${foot}foot`) {
      return;
    }
    let operateChart, otherChart;
    if (foot === "left") {
      operateChart = this.myChart3d;
      otherChart = this.myChart4d;
    } else {
      operateChart = this.myChart4d;
      otherChart = this.myChart3d;
    }

    const areas = params.areas;
    if (areas.length === 0) {
      this.setState({
        makeMark: false
      });
      return;
    }
    const coord = areas[0].coordRange;
    this.setState({
      makeMark: true,
      markFoot: foot,
      markDuration: coord,
      markValue: null
    });

    const chartDom = operateChart.getDom();
    const y = chartDom.offsetTop + chartDom.offsetHeight - 140;
    const x = operateChart.convertToPixel({ xAxisIndex: 0 }, coord[0]);

    this.setState({
      markPosition: [x, y]
    });
  };

  handleMakeMark({ foot, value, range }) {
    const key = Date.now();

    notification.open({
      key,
      message: "mark data",
      description: "sending request...",
      duration: 0,
      icon: <Icon type="loading" />
    });

    const startFrame = this.props.leftFootTempWashed[range[0]];
    const endFrame = this.props.leftFootTempWashed[range[1]];

    markSockData({
      userId: this.props.userId,
      foot: foot,
      mark: value,
      startTime: startFrame.usertime,
      endTime: endFrame.usertime
    })
      .then(() => {
        this.props.udpateSockDataMark(foot, {
          startTime: startFrame.usertime,
          endTime: endFrame.usertime,
          mark: value
        });
        notification.success({
          key,
          message: "mark data",
          description: "Success"
        });
      })
      .catch(() => {
        notification.error({
          key,
          message: "mark data",
          description: "Failed. Try again"
        });
      });
  }

  renderMakeMarkDialog() {
    if (!this.state.makeMark) {
      return;
    }
    const style = {
      fontSize: 12,
      fontWeight: "normal",
      textAlign: "left",
      lineHeight: 1.2,
      position: "absolute",
      left: this.state.markPosition[0],
      top: this.state.markPosition[1],
      zIndex: 10,
      padding: 4,
      backgroundColor: "white"
    };
    const xAxisData = this.myChart3d.getOption().xAxis[0].data;
    return (
      <div style={style}>
        <div>{this.state.markFoot} foot</div>
        <div>
          range: {xAxisData[this.state.markDuration[0]]} -{" "}
          {xAxisData[this.state.markDuration[1]]}
        </div>
        <div>
          mark:{" "}
          <Radio.Group
            value={this.state.markValue}
            onChange={e => {
              this.setState({ markValue: e.target.value });
              this.handleMakeMark({
                foot: this.state.markFoot,
                value: e.target.value,
                range: this.state.markDuration
              });
            }}
          >
            <Radio value={-3}>-3</Radio>
            <Radio value={-2}>-2</Radio>
            <Radio value={3}>3</Radio>
            <Radio value={6}>6</Radio>
          </Radio.Group>
        </div>
        <div>
          input mark:{" "}
          <Input
            style={{ width: 150 }}
            size="small"
            placeholder="press Enter key to commit"
            onPressEnter={e => {
              if (e.target.value) {
                this.handleMakeMark({
                  foot: this.state.markFoot,
                  value: e.target.value,
                  range: this.state.markDuration
                });
                e.target.value = "";
              }
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="charts_container">
        {this.renderMakeMarkDialog()}
        <ReactECharts
          option={getChartDeltaOptions(
            this.props.delta,
            this.props.steps,
            this.props.leftFootTemp,
            this.props.rightFootTemp,
            this.props.leftPosition,
            this.props.rightPosition,
            this.props.min1_or_min30,
            this.props.tempUnit,
            this.props.delta_auto,
            this.props.footStatus
          )}
          onChartReady={chart => {
            this.myChart1 = chart;
            this.linkCharts();
          }}
          showLoading={this.props.loading_charts}
          style={{ width: "100%", height: 2000 / 6 }}
        />
        {this.props.showDiff && (
          <ReactECharts
            option={getChartDeltaWashedOptions(
              this.props.deltaWashed,
              this.props.steps,
              this.props.leftFootTemp,
              this.props.rightFootTemp,
              this.props.leftPosition,
              this.props.rightPosition,
              this.props.min1_or_min30,
              this.props.tempUnit,
              this.props.deltaAutoWashed
            )}
            onChartReady={chart => {
              this.myChart1d = chart;
              this.linkCharts();
            }}
            showLoading={this.props.loading_charts}
            style={{ width: "100%", height: 2000 / 6, marginTop: -80 }}
          />
        )}
        <ReactECharts
          option={getChartStepsOptions(
            this.props.delta,
            this.props.steps,
            this.props.leftFootTemp,
            this.props.rightFootTemp,
            this.props.leftPosition,
            this.props.rightPosition,
            this.props.min1_or_min30,
            this.props.tempUnit
          )}
          onChartReady={chart => {
            this.myChart2 = chart;
            this.linkCharts();
          }}
          showLoading={this.props.loading_charts}
          style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
        />
        <ReactECharts
          option={getChartLeftTempOptions(
            this.props.delta,
            this.props.steps,
            this.props.leftFootTemp,
            this.props.rightFootTemp,
            this.props.leftPosition,
            this.props.rightPosition,
            this.props.min1_or_min30,
            this.props.tempUnit
          )}
          onChartReady={chart => {
            this.myChart3 = chart;
            this.linkCharts();
          }}
          showLoading={this.props.loading_charts}
          style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
        />
        {this.props.showDiff && (
          <ReactECharts
            option={getChartLeftTempWashedOptions(
              this.props.delta,
              this.props.steps,
              this.props.leftFootTempWashed,
              this.props.rightFootTemp,
              this.props.leftPosition,
              this.props.rightPosition,
              this.props.min1_or_min30,
              this.props.tempUnit,
              this.props.leftFootTempMarked
            )}
            onChartReady={chart => {
              // active brush
              chart.dispatchAction({
                type: "takeGlobalCursor",
                key: "brush",
                brushOption: {
                  brushType: "lineX",
                  brushMode: "single"
                }
              });
              this.myChart3d = chart;
              this.linkCharts();
            }}
            onEvents={this.eventMapLeft}
            showLoading={this.props.loading_charts}
            style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
          />
        )}
        <ReactECharts
          option={getChartRightTempOptions(
            this.props.delta,
            this.props.steps,
            this.props.leftFootTemp,
            this.props.rightFootTemp,
            this.props.leftPosition,
            this.props.rightPosition,
            this.props.min1_or_min30,
            this.props.tempUnit
          )}
          onChartReady={chart => {
            this.myChart4 = chart;
            this.linkCharts();
          }}
          showLoading={this.props.loading_charts}
          style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
        />
        {this.props.showDiff && (
          <ReactECharts
            option={getChartRightTempWashedOptions(
              this.props.delta,
              this.props.steps,
              this.props.leftFootTemp,
              this.props.rightFootTempWashed,
              this.props.leftPosition,
              this.props.rightPosition,
              this.props.min1_or_min30,
              this.props.tempUnit,
              this.props.rightFootTempMarked
            )}
            onChartReady={chart => {
              // active brush
              chart.dispatchAction({
                type: "takeGlobalCursor",
                key: "brush",
                brushOption: {
                  brushType: "lineX",
                  brushMode: "single"
                }
              });
              this.myChart4d = chart;
              this.linkCharts();
            }}
            onEvents={this.eventMapRight}
            showLoading={this.props.loading_charts}
            style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
          />
        )}
        <ReactECharts
          option={getChartLeftPositionOptions(
            this.props.delta,
            this.props.steps,
            this.props.leftFootTemp,
            this.props.rightFootTemp,
            this.props.leftPosition,
            this.props.rightPosition,
            this.props.min1_or_min30,
            this.props.tempUnit
          )}
          onChartReady={chart => {
            this.myChart5 = chart;
            this.linkCharts();
          }}
          showLoading={this.props.loading_charts}
          style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
        />
        <ReactECharts
          option={getChartRightPositionOptions(
            this.props.delta,
            this.props.steps,
            this.props.leftFootTemp,
            this.props.rightFootTemp,
            this.props.leftPosition,
            this.props.rightPosition,
            this.props.min1_or_min30,
            this.props.tempUnit
          )}
          onChartReady={chart => {
            this.myChart6 = chart;
            this.linkCharts();
          }}
          showLoading={this.props.loading_charts}
          style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  loading_charts: state.patientsListStore.loading_charts,
  delta: state.patientsListStore.delta,
  delta_auto: state.patientsListStore.delta_auto,
  steps: state.patientsListStore.steps,
  min1_or_min30: state.patientsListStore.min1_or_min30,
  leftFootTemp: state.patientsListStore.leftFootTemp,
  rightFootTemp: state.patientsListStore.rightFootTemp,
  leftPosition: state.patientsListStore.leftPosition,
  rightPosition: state.patientsListStore.rightPosition,
  tempUnit: state.patientsListStore.tempUnit,
  deltaWashed: state.patientsListStore.deltaWashed,
  deltaAutoWashed: state.patientsListStore.deltaAutoWashed,
  leftFootTempWashed: state.patientsListStore.leftFootTempWashed,
  leftFootTempMarked: state.patientsListStore.leftFootTempMarked,
  rightFootTempWashed: state.patientsListStore.rightFootTempWashed,
  rightFootTempMarked: state.patientsListStore.rightFootTempMarked,
  footStatus: state.patientsListStore.footStatus
});

const mapDispatchToProp = dispatch => ({
  udpateSockDataMark: (foot, data) => dispatch(udpateSockDataMark(foot, data))
});

export default connect(mapStateToProp, mapDispatchToProp)(patientCharts);
