import { Select } from "antd";
import { find } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { addGroup } from "../action/addAction";
import { getGroups } from "../action/patientAction";
import ButtonSuccess from "./buttonSuccess";
import "./selectGroup.css";
import { Auth } from "aws-amplify";
class Group extends Component {
  constructor(props) {
    super(props);
    this.submit.bind(this);
    this.state = {
      open: false,
      ngroup: "",
      sgroup: "",
      confirmSuccess: false,
      userGroup: []
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleOpen = async () => {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    const siteInfo = find(this.props.clinicSites, {
      siteId: this.props.item.siteId
    });
    this.setState({
      open: true,
      confirmSuccess: false,
      ngroup:
        siteInfo === undefined
          ? this.props.item.siteId
          : siteInfo.siteId + "-" + siteInfo.name,
      sgroup: this.props.item.siteId,
      userGroup: groups
    });
  };
  close = () => this.setState({ open: false, confirmSuccess: false });
  handleChange(value) {
    this.setState({
      ngroup: value,
      sgroup: value.includes("-") ? value.split("-")[0] : value
    });
  }
  submit() {
    // this.setState({ confirmSuccess: true });
    const { addGroup, getGroups } = this.props;
    const newgroup = {
      id: this.props.item.id,
      group: this.state.sgroup
    };
    addGroup(newgroup, this);
    getGroups();
  }
  render() {
    const { Option } = Select;
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Icon as="i" name="edit" className="edit" onClick={this.handleOpen} />
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>Modify Your Group</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="8">
                  <label>Group</label>
                  <Select
                    className="select_common_style"
                    value={this.state.ngroup}
                    onChange={this.handleChange}
                    style={{ width: "100%" }}
                    placeholder="Please Select"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                    disabled={this.state.userGroup.includes("staff")}
                  >
                    {this.props.clinicSites.map((gr, i) => {
                      return (
                        <Option
                          value={gr.siteId + "-" + gr.name}
                          key={"gr" + i}
                        >
                          {gr.siteId + " - " + gr.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.submit()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Add
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.close()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  listPatients: state.patientsListStore.listPatients,
  filterGroup: state.patientsListStore.filterGroup,
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
  addGroup: (newgroups, self) => dispatch(addGroup(newgroups, self)),
  getGroups: () => dispatch(getGroups())
});
export default connect(mapStateToProp, mapDispatchToProp)(Group);
