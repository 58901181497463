import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import "../BoxManagement/registerbox.css";
import { unRegisteredShoeTag } from "../action/boxAction";

class unregisterShoeTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      regiSuccess: false
    };
  }

  handleOpen = () => {
    this.setState({ open: true, regiSuccess: false });
  };
  close = () => this.setState({ open: false });

  handleOK = () => {};

  submit() {
    const creds = {
      userId: this.props.item.userId,
      tagId: this.props.item.tagId
    };
    this.setState({
      loading: true
    });
    unRegisteredShoeTag(creds)
      .then(res => {
        if (res.status) {
          this.setState({
            loading: false,
            regiSuccess: true
          });
          setTimeout(() => {
            this.setState({
              open: false
            });
            if (typeof this.props.onChange === "function") {
              this.props.onChange(creds);
            }
          }, 500);
        } else {
          this.setState({
            loading: false
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  }

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Button onClick={this.handleOpen} size="mini">
            Unregister
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>
          {this.state.regiSuccess
            ? "UnRegister tag success"
            : `Tag UnRegistration`}
        </Modal.Header>
        <Modal.Content>
          {this.state.regiSuccess ? (
            <div className="regiSuccess">
              <Icon name="check circle" id="regiSuccessIcon" />
              <p className="regiSuccessTitle">Awesome!</p>
              <p className="regiSuccesstext">
                Tag has been successfully unregistered.
              </p>
            </div>
          ) : (
            <div>
              <div className="search_patient_container">
                Are you sure you want to unregister tag: {this.props.item.tagId}
                ?
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          {!this.state.regiSuccess && (
            <Button
              color="green"
              loading={this.state.loading}
              onClick={e => this.submit()}
            >
              <Icon name="checkmark" />
              UnRegister Tag
            </Button>
          )}
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.regiSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({});

export default connect(mapStateToProp, null)(unregisterShoeTag);
