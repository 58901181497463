import { Select, Modal as AntdModal } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import {
  changeAdminStaffStatus,
  listAdminDeletedStaff
} from "../../action/registerAction";
import { lowercaseRmSpace } from "../../tools/stringProcess";
import DeleteAdminStaff from "./DeleteAdminStaff";
import "./NewSite.css";
import "./SiteList.css";
import { Button } from "semantic-ui-react";

const { Option } = Select;
const { confirm } = AntdModal;

class AdminDeletedList extends Component {
  state = {
    loading: false
  };

  componentDidMount = () => {
    if (this.props.listAdminDeletedStaff.length === 0) {
      this.refreshList();
    }
  };

  refreshList = () => {
    const { listAdminDeletedUsers } = this.props;
    this.setState({ loading: true });
    listAdminDeletedUsers().finally(() => {
      this.setState({ loading: false });
    });
  };

  confirmReInstate = (id, firstName, lastName) => {
    confirm({
      title: "Confirming",
      content: (
        <div>
          Are you sure you want to reinstate the admin user{" "}
          <strong>
            {firstName} {lastName}
          </strong>
          ?
        </div>
      ),
      cancelText: "No",
      onCancel: () => {},
      okText: "Yes",
      onOk: () => {
        this.handleReInstateStaff(id, firstName, lastName);
      },
      centered: true
    });
  };

  handleReInstateStaff = (id, firstName, lastName) => {
    const input = {
      id,
      status: "REINSTATE",
      firstName,
      lastName
    };
    this.props.changeAdminStaffStatus(input, this);
  };

  render() {
    const isAdmin = this.props.userGroups.includes("admin");
    return (
      <div className="boxlistcontainer">
        <ReactTable
          loading={this.state.loading}
          data={
            this.props.listAdminDeletedStaff &&
            this.props.listAdminDeletedStaff.map(p => {
              const userName = p.id;
              const userStatus = p.status;
              const createdAt = moment(p.createdAt).format("MM/DD/YYYY");
              const lastLogin = moment(p.updatedAt).format("MM/DD/YYYY");
              const group =
                p.group === "admin"
                  ? "Super User"
                  : p.group === "developer"
                  ? "Admin"
                  : p.group === "support"
                  ? "Support"
                  : p.group === "staff"
                  ? "Siren Staffing"
                  : "Sales";
              const deletedAt = p.deletedAt
                ? moment(p.deletedAt).format("MM/DD/YYYY")
                : "";
              return {
                ...p,
                userName,
                userStatus,
                createdAt,
                lastLogin,
                group,
                deletedAt
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            lowercaseRmSpace(String(row[filter.id])).includes(
              lowercaseRmSpace(filter.value)
            )
          }
          columns={[
            {
              Header: "User",
              accessor: "userName"
            },
            {
              Header: "Group",
              accessor: "group",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Super User") {
                  return row[filter.id] === "Super User";
                }
                if (filter.value === "Admin") {
                  return row[filter.id] === "Admin";
                }
                if (filter.value === "Support") {
                  return row[filter.id] === "Support";
                }
                if (filter.value === "Siren Staffing") {
                  return row[filter.id] === "Siren Staffing";
                }
                if (filter.value === "Sales") {
                  return row[filter.id] === "Sales";
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Super User">Super User</Option>
                  <Option value="Admin">Admin</Option>
                  <Option value="Support">Support</Option>
                  <Option value="Siren Staffing">Siren Staffing</Option>
                  <Option value="Sales">Sales</Option>
                  <Option value="THIRD_PARTY">Third Party</Option>
                </Select>
              )
            },
            {
              Header: "First Name",
              accessor: "firstName",
              maxWidth: 160
            },
            {
              Header: "Last Name",
              accessor: "lastName",
              maxWidth: 160
            },
            {
              Header: "Email",
              accessor: "email"
            },
            {
              Header: "CreatedAt",
              accessor: "createdAt",
              maxWidth: 160,
              filterMethod: (filter, row) =>
                String(row[filter.id]).includes(filter.value)
            },
            {
              Header: "Last Login",
              accessor: "lastLogin",
              maxWidth: 160,
              filterMethod: (filter, row) =>
                String(row[filter.id]).includes(filter.value)
            },
            {
              Header: "Deleted At",
              accessor: "deletedAt",
              maxWidth: 160,
              filterMethod: (filter, row) =>
                String(row[filter.id]).includes(filter.value)
            },
            {
              Header: "Deleted By",
              accessor: "deletedBy",
              maxWidth: 160,
              filterMethod: (filter, row) =>
                String(row[filter.id]).includes(filter.value)
            },
            {
              Header: "Actions",
              accessor: "",
              minWidth: isAdmin ? 200 : 100,
              sortable: false,
              filterable: false,
              show: isAdmin,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    {isAdmin && (
                      <DeleteAdminStaff
                        email={props.original.userName}
                        patientCount={props.original.patientCount}
                      />
                    )}
                    {isAdmin && (
                      <Button
                        size="mini"
                        onClick={() => {
                          this.confirmReInstate(
                            props.original.id,
                            props.original.firstName,
                            props.original.lastName
                          );
                        }}
                      >
                        ReInstate
                      </Button>
                    )}
                  </div>
                );
              }
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "email",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  listAdminStaff: state.patientsListStore.listAdminStaff,
  listAdminDeletedStaff: state.patientsListStore.listAdminDeletedStaff,
  userGroups: state.authGroupStore.session.userGroups
});
const mapDispatchToProp = dispatch => ({
  listAdminDeletedUsers: () => dispatch(listAdminDeletedStaff()),
  changeAdminStaffStatus: (input, self) =>
    dispatch(changeAdminStaffStatus(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(AdminDeletedList);
