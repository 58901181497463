import React from "react";
import { Tabs } from "antd";
import SiteList from "./SiteList";
import SiteDeletedList from "./SiteDeletedList";

/**
 *  Created by shengli.zhang on 5/17/21 5:18 PM
 */
const { TabPane } = Tabs;

export default function SiteManagement(props) {
  return (
    <div className="boxlistcontainer">
      <Tabs style={{ textAlign: "left" }}>
        <TabPane tab="Active" key="1">
          <SiteList />
        </TabPane>
        <TabPane tab="Deleted" key="2">
          <SiteDeletedList />
        </TabPane>
      </Tabs>
    </div>
  )
}
