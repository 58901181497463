import { Button, DatePicker, Icon } from 'antd';
import ReactEcharts from "echarts-for-react";
import moment from 'moment';
import React, { Component } from "react";
import { listPatientMin1SlowStep } from '../../graphql/API';
import zlib from "react-zlib-js";
import Buffer from "react-zlib-js/buffer";
import PatientDetailUrlContext from "./PatientDetailUrlContext";

export default class SlowStep extends Component {

  static contextType = PatientDetailUrlContext;

  constructor(props) {
    super(props)

    this.state = {
      date: moment(),
      loading: false,
      chartData: [],
      chartOption: chartBaseOption
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    this.setState({
      loading: true,
      chartData: []
    })
    const startDate = this.state.date.format('YYYY-MM-DD');
    const endDate = this.state.date.clone().add(1, 'day').format('YYYY-MM-DD');
    listPatientMin1SlowStep(this.context.param.id, this.context.param.userId, startDate, endDate)
      .then(data => {
        const rawDataStr = zlib.inflateSync(new Buffer(data, "base64")).toString();
        if (rawDataStr.length === 0) {
          this.calcChartOption()
          return
        }
        const parsedData = rawDataStr.split('|').map(itemStr => {
          const [usertime, foot, steps] = itemStr.split(',')

          return { usertime: usertime.substring(0, 19), foot, steps: Number(steps) }
        });
        this.setState({
          chartData: parsedData
        }, this.calcChartOption)
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  }

  goPrevDay = () => {
    this.setState({
      date: this.state.date.clone().add(-1, 'day')
    }, this.fetchData)
  }

  goNextDay = () => {
    this.setState({
      date: this.state.date.clone().add(1, 'day')
    }, this.fetchData)
  }

  onDateChange = (date, dateString) => {
    if (!date || date.isSame(this.state.date)) {
      return
    }
    this.setState({
      date
    }, this.fetchData)
  }

  calcChartOption = () => {
    let chartData = [...this.state.chartData];
    let totalSteps = 0;
    if (chartData.length === 0) {
      // nope
    } else {
      totalSteps = chartData.reduce((sum, item) => sum + item.steps, 0)
      const startDate = this.state.date.clone().startOf('days')
      // expend data to a whole day
      const wholeData = Array(24 * 60).fill(0).map((_, i) => ({
        usertime: startDate.clone().add(i, 'minutes').format('YYYY-MM-DDTHH:mm:00')
      }))
      this.state.chartData.forEach(item => {
        const index = moment(item.usertime).diff(startDate, 'minutes')
        const chartItem = wholeData[index]
        if (item.foot === 'left') {
          chartItem.left = item.steps
          const right = chartItem.right || 0
          chartItem.total = chartItem.left + right
        } else if (item.foot === 'right') {
          chartItem.right = item.steps
          const left = chartItem.left || 0
          chartItem.total = chartItem.right + left
        }
      })

      chartData = wholeData.map(item => Object.assign({}, item, {usertime: moment(item.usertime).format("MM/DD HH:mm")}))
    }

    this.setState({
      chartOption: Object.assign({}, chartBaseOption, {
        title: {
          text: `Total Steps: ${totalSteps}`
        },
        dataset: {
          dimensions: ['usertime', 'total', 'left', 'right'],
          source: chartData
        }
      })
    })
  }

  render() {
    return (
      <div>
        <div style={{textAlign: 'center', marginBottom: 20}}>
          <Button onClick={this.goPrevDay} style={{marginRight: 20}}>
            <Icon type="left" />
            Previous day
          </Button>
          <DatePicker value={this.state.date} onChange={this.onDateChange} allowClear={false} />
          <Button onClick={this.goNextDay} style={{marginLeft: 20}}>
            Next day
            <Icon type="right" />
          </Button>
        </div>
        <ReactEcharts
          option={this.state.chartOption}
          style={{ height: 600 }}
          lazyUpdate={true}
          showLoading={this.state.loading}
        />
      </div>
    )
  }

}

const chartBaseOption = {
  title: {
    text: "Total Steps: --"
  },
  legend: {
    data: ["steps", "left", "right"]
  },
  color: ["#529DE1", "#9AC078", "#7472AF"],
  tooltip: {
    trigger: 'axis',
    textStyle: {
      align: "left"
    },
    axisPointer: {
      animation: false
    }
  },
  toolbox: {
    feature: {
      dataZoom: {
        yAxisIndex: "none"
      },
      restore: {},
      saveAsImage: {}
    }
  },
  axisPointer: {
    link: [
      {
        xAxisIndex: 'all'
      }
    ]
  },
  dataZoom: [
    {
      orient: "horizontal",
      show: true,
      realtime: true,
      xAxisIndex: [0, 1, 2]
    },
    {
      type: 'inside',
      realtime: true,
      xAxisIndex: [0, 1, 2]
    }
  ],
  grid: [
    {
      left: 40,
      right: 40,
      height: '20%',
    },
    {
      left: 40,
      right: 40,
      height: '20%',
      top: '40%',
    },
    {
      left: 40,
      right: 40,
      height: '20%',
      top: '70%',
    }
  ],
  dataset: {
    dimensions: ['usertime', 'total', 'left', 'right'],
    source: []
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      axisLine: {
        onZero: false,
        lineStyle: {
          color: "#C9CED1",
        }
      },
      axisLabel: {
        interval: 179,
      }
    },
    {
      gridIndex: 1,
      type: "category",
      boundaryGap: false,
      axisLine: {
        onZero: false,
        lineStyle: {
          color: "#C9CED1",
        }
      },
      axisLabel: {
        interval: 179,
      }
    },
    {
      gridIndex: 2,
      type: "category",
      boundaryGap: false,
      axisLine: {
        onZero: false,
        lineStyle: {
          color: "#C9CED1",
        }
      },
      axisLabel: {
        interval: 179,
      }
    },
  ],
  yAxis: [
    {
      name: 'Total Steps',
      type: "value",
      axisLine: { onZero: true, show: false },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#F1F1F1",
          width: 1,
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    },
    {
      gridIndex: 1,
      name: 'Left Foot',
      type: "value",
      axisLine: { onZero: true, show: false },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#F1F1F1",
          width: 1,
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    },
    {
      gridIndex: 2,
      name: 'Right Foot',
      type: "value",
      axisLine: { onZero: true, show: false },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#F1F1F1",
          width: 1,
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    },
  ],
  series: [
    {
      name: "steps",
      type: "line",
    },
    {
      name: "left",
      type: "line",
      xAxisIndex: 1,
      yAxisIndex: 1,
    },
    {
      name: "right",
      type: "line",
      xAxisIndex: 2,
      yAxisIndex: 2,
    }
  ]
}
