import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import {
  changeRegisterAccountStatus,
  checkSocksAndHub
} from "../action/addAction";
import "../share/button.css";
import ButtonSuccess from "./buttonSuccess";
class Delete extends Component {
  constructor(props) {
    super(props);
    this.handleConfirm.bind(this);
    this.checkPatient.bind(this);
  }
  state = { open: false, confirmSuccess: false };

  show = () => {
    this.setState({ open: true, confirmSuccess: false });
  };
  handleConfirm = async () => {
    let user = await Auth.currentAuthenticatedUser();
    this.setState({ confirmSuccess: true });
    const { changeAccountStatus } = this.props;
    const id = this.props.id;
    const newStatus = {
      id: id,
      title: 'Account Status Deleted',
      status: "DELETED",
      author: `${user.attributes.given_name} ${user.attributes.family_name}`,
      deletedBy: user.username
    };
    changeAccountStatus(newStatus, this.props.userInfo, this).then(() => {
      if (typeof this.props.successCB === 'function') {
        this.props.successCB(id);
      }
    });
  };
  checkPatient = async () => {
    const { checkSocksAndHub } = this.props;
    checkSocksAndHub(this.props.userInfo.sirenId, this);
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });
  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini" className="deleteButton">
            Delete
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <p>Are you sure you want to delete this patient?</p>
              <div>
                <p className="cofirmSiteId">
                  Name:
                  <strong className="cofirmBold">
                    {this.props.userInfo.firstName}{" "}
                    {this.props.userInfo.lastName}
                  </strong>
                </p>
                <p>
                  Phone:<strong className="cofirmBold">{this.props.id}</strong>
                </p>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => {
              this.props.userInfo.userId
                ? this.checkPatient()
                : this.handleConfirm();
            }}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  listPatients: state.patientsListStore.listPatients,
});
const mapDispatchToProp = dispatch => ({
  changeAccountStatus: (newStatus, userInfo, self) =>
    dispatch(changeRegisterAccountStatus(newStatus, userInfo, self)),
  checkSocksAndHub: (sirenId, self) => dispatch(checkSocksAndHub(sirenId, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(Delete);
