import { Auth, Signer } from "aws-amplify";

// arn:aws:execute-api:us-west-2:332266507829:fhauor9j4j/*/$connect

class SharedWebsocket {

  constructor() {
    this.websocketInstance = null

    this.pendingTasks = []

    this.viewPatientListener = {}
    this.accListenerId = 0
  }

  async initial() {
    let user = await Auth.currentAuthenticatedUser();
    const credentials = await Auth.currentCredentials()

    const accessInfo = {
      access_key: credentials.accessKeyId,
      secret_key: credentials.secretAccessKey,
      session_token: credentials.sessionToken,
    }

    // TODO: read from config
    const wssUrl = "wss://fhauor9j4j.execute-api.us-west-2.amazonaws.com/dev"

    const signedUrl = Signer.signUrl(wssUrl, accessInfo)

    // Create WebSocket connection.
    this.websocketInstance = new WebSocket(signedUrl);

    // Connection opened
    this.websocketInstance.addEventListener('open', (event) => {
      this.onConnected(user)
    });

    // Listen for messages
    this.websocketInstance.addEventListener('message', (event) => {
      const { type, payload } = JSON.parse(event.data)
      if (type === 'viewPatient') {
        for (const id in this.viewPatientListener) {
          const fn = this.viewPatientListener[id];
          fn(payload)
        }
      }
    });

    // Connection closed
    this.websocketInstance.addEventListener('close', (event) => {
      console.log('close socket ', event);
      clearInterval(this.beatInterval)
    })
  }

  onConnected(user) {
    const {family_name, given_name} = user.attributes
    // send whoami message, because we can not get user info from connection
    this.websocketInstance.send(JSON.stringify({
      action: 'whoami',
      payload: {
        username: user.username,
        shortname: given_name.substring(0, 1) + family_name.substring(0, 1)
      }
    }))

    // heart beat every 5 mins
    this.beatInterval = setInterval(() => {
      this.websocketInstance.send(JSON.stringify({
        action: 'ping'
      }))
    }, 1000 * 60 * 5)

    // process pending task
    const pendingInteval = setInterval(() => {
      const task = this.pendingTasks.shift()
      if (!task) {
        clearInterval(pendingInteval)
        return
      }
      this.websocketInstance.send(task)
    }, 1000)
  }

  sendViewPatientMsg(userId, type) {
    if (!this.websocketInstance) {
      console.warn('websocket not initial')

      // add to pending task list
      this.pendingTasks.push(JSON.stringify({
        action: 'viewPage',
        payload: {
          type,
          userId,
          page: 'patientDetail'
        }
      }))
      return
    }
    this.websocketInstance.send(JSON.stringify({
      action: 'viewPage',
      payload: {
        type,
        userId,
        page: 'patientDetail'
      }
    }))
  }

  registerViewPatientEvent(fn) {
    if (typeof fn !== 'function') {
      return
    }
    this.accListenerId++
    this.viewPatientListener[this.accListenerId] = fn

    return this.accListenerId
  }

  unregisterViewPatientEvent(id) {
    delete this.viewPatientListener[id]
  }

  getInstance() {
    return this.websocketInstance
  }

}

export default new SharedWebsocket()
