import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { changeStatus } from "../action/sockAction";
import ButtonSuccess from "../home/buttonSuccess";
import "../share/button.css";
import "./Disable.css";
class Disable extends Component {
  constructor(props) {
    super(props);
    this.handleConfirm.bind(this);
  }
  state = { open: false, confirmSuccess: false };

  show = () => this.setState({ open: true, confirmSuccess: false });
  handleConfirm = () => {
    this.setState({ confirmSuccess: true });
    const { changeStatus } = this.props;
    const status = "DISABLED";
    const newstatus = {
      userId: this.props.item.userId,
      status,
      serialNo: this.props.serialNo
    };
    changeStatus(this.props.id, newstatus, this, this.props.sirenId);
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini">
            DISABLED
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <p>Are you sure to set the sock status to DISABLED?</p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  boxList: state.boxListStore.boxList
});
const mapDispatchToProp = dispatch => ({
  changeStatus: (id, newstatus, self, sirenId) =>
    dispatch(changeStatus(id, newstatus, self, sirenId))
});
export default connect(mapStateToProp, mapDispatchToProp)(Disable);
