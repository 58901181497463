import { Modal as AntdModal, Select } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Button, Icon, Popup } from "semantic-ui-react";
import {
  changeAdminStaffStatus,
  getlistClinicSites,
  listAdminUsers
} from "../../action/registerAction";
import getPatchTrProps from "../../tools/reactTablePatch";
import { lowercaseRmSpace } from "../../tools/stringProcess";
import AddAdminUser from "./AddAdminUser";
import ButtonResetPassword from "./ButtonResetPassword";
import EditAdminUser from "./EditAdminUser";
import "./NewSite.css";
import "./SiteList.css";
import { getEMRList } from "../../action/emrAction";
import { Control } from "react-keeper";
import _ from "lodash";

const { Option } = Select;
const { confirm } = AntdModal;

class AdminList extends Component {
  state = {
    loading: false
  };

  componentDidMount = () => {
    if (this.props.EMRSystemList.length === 0) {
      this.props.getEMRList();
    }
    const { getlistClinicSites } = this.props;
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites();
    }
    if (this.props.listAdminStaff.length === 0) {
      this.refreshList();
    }
  };

  refreshList = () => {
    const { listAdminUsers } = this.props;
    this.setState({ loading: true });
    listAdminUsers().finally(() => {
      this.setState({ loading: false });
    });
  };

  confirmDelete = (id, firstName, lastName) => {
    confirm({
      title: "Confirming",
      content: (
        <div>
          Are you sure you want to delete the admin user{" "}
          <strong>
            {firstName} {lastName}
          </strong>
          ?
        </div>
      ),
      cancelText: "No",
      onCancel: () => {},
      okText: "Yes",
      onOk: () => {
        this.handleTempDeleteStaff(id, firstName, lastName);
      },
      centered: true
    });
  };

  gotoDoctorDetailPage = id => {
    Control.go(`/LpnPatient?id=${id}`);
  };

  checkPatientsInLpn = (id, patientCount) => {
    AntdModal.warning({
      title: "Oops",
      content: (
        <div>
          There are <strong>{patientCount}</strong> patients assigned to{" "}
          <strong>{id}</strong>. Patients must be reassigned before the account
          can be deleted.
        </div>
      ),
      // cancelText: "No",
      // onCancel: () => {},
      okText: "OK",
      onOk: () => {
        // this.gotoDoctorDetailPage(id);
      },
      centered: true
    });
  };

  checkBackup = (backupBy, id) => {
    AntdModal.warning({
      title: "Oops",
      content: (
        <div>
          To delete <strong>{id}</strong>, remove user as a backup for{" "}
          <strong>
            {backupBy
              .map(p => {
                return p;
              })
              .join("/")}
          </strong>
          .
        </div>
      ),
      okText: "OK",
      onOk: () => {},
      centered: true
    });
  };

  checkBackupByLpn = id => {
    const adminStaffs = this.props.listAdminStaff.filter(
      item => item.group === "staff"
    );
    const backupBy = adminStaffs.map(p => {
      if (p.backupFor && JSON.parse(p.backupFor).includes(id)) return p.id;
      return null;
    });
    return _.compact(backupBy);
  };

  handleTempDeleteStaff = (id, firstName, lastName) => {
    const input = {
      id,
      status: "DELETED",
      firstName,
      lastName
    };
    this.props.changeAdminStaffStatus(input, this);
  };

  render() {
    const isAdminOrDeveloper =
      this.props.userGroups.includes("admin") ||
      this.props.userGroups.includes("developer") ||
      this.props.userGroups.includes("support");
    const trProps = getPatchTrProps(({ event }) => {
      //WARNING: black magic
      const buttons = event.currentTarget.getElementsByTagName("button");
      for (const button of buttons) {
        if (button.innerHTML === "Edit") {
          button.click();
          break;
        }
      }
    });
    return (
      <div className="boxlistcontainer">
        <div className="add_import_box" style={{ textAlign: "left" }}>
          <AddAdminUser refreshList={this.refreshList} />
        </div>
        <ReactTable
          getTrProps={isAdminOrDeveloper ? trProps : undefined}
          loading={this.state.loading}
          data={
            this.props.listAdminStaff &&
            this.props.listAdminStaff.map(p => {
              const userName = p.id;
              const userStatus = p.status;
              const createdAt = moment(p.createdAt).format("MM/DD/YYYY");
              const lastLogin = moment(p.updatedAt).format("MM/DD/YYYY");
              const group =
                p.group === "admin"
                  ? "Super User"
                  : p.group === "developer"
                  ? "Admin"
                  : p.group === "support"
                  ? "Support"
                  : p.group === "staff"
                  ? "Siren Staffing"
                  : "Sales";

              return {
                ...p,
                userName,
                userStatus,
                createdAt,
                lastLogin,
                group
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            lowercaseRmSpace(String(row[filter.id])).includes(
              lowercaseRmSpace(filter.value)
            )
          }
          columns={[
            {
              Header: "User",
              accessor: "userName"
            },
            {
              Header: "Group",
              accessor: "group",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Super User") {
                  return row[filter.id] === "Super User";
                }
                if (filter.value === "Admin") {
                  return row[filter.id] === "Admin";
                }
                if (filter.value === "Support") {
                  return row[filter.id] === "Support";
                }
                if (filter.value === "Siren Staffing") {
                  return row[filter.id] === "Siren Staffing";
                }
                if (filter.value === "Sales") {
                  return row[filter.id] === "Sales";
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Super User">Super User</Option>
                  <Option value="Admin">Admin</Option>
                  <Option value="Support">Support</Option>
                  <Option value="Siren Staffing">Siren Staffing</Option>
                  <Option value="Sales">Sales</Option>
                  <Option value="THIRD_PARTY">Third Party</Option>
                </Select>
              )
            },
            {
              Header: "First Name",
              accessor: "firstName",
              maxWidth: 160
            },
            {
              Header: "Last Name",
              accessor: "lastName",
              maxWidth: 160
            },
            {
              Header: "Email",
              accessor: "email"
            },
            {
              Header: "Sites",
              accessor: "primarySites",
              maxWidth: 400,
              Cell: props => {
                let siteList = [];
                if (props.value) {
                  siteList = JSON.parse(props.value);
                  siteList = siteList.map(id => {
                    const site = this.props.clinicSites.find(
                      site => site.siteId === id
                    );
                    if (site) {
                      return site.name;
                    } else {
                      return "";
                    }
                  });
                }
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <Popup
                      hoverable
                      trigger={
                        <p className="sr-text-ellipsis">{siteList.join(",")}</p>
                      }
                    >
                      <div style={{ maxHeight: 300, overflowY: "auto" }}>
                        {siteList.map((name, index) => (
                          <p key={index}>{name}</p>
                        ))}
                      </div>
                    </Popup>
                  </div>
                );
              }
            },
            {
              Header: "Backup For",
              accessor: "backupFor",
              sortable: false,
              maxWidth: 400,
              Cell: props => {
                let backUpList = [];
                let hasDeleted = false;
                if (props.value) {
                  backUpList = JSON.parse(props.value);
                  backUpList = backUpList.map(id => {
                    const adminStaff = this.props.listAdminStaff.find(
                      user => user.id === id
                    );
                    if (adminStaff) {
                      return (
                        <span>{`${adminStaff.firstName} ${adminStaff.lastName}`}</span>
                      );
                    } else {
                      hasDeleted = true;
                      return <del>{id}</del>;
                    }
                  });
                }
                if (backUpList.length === 0) {
                  return "";
                }
                return (
                  <Popup
                    trigger={
                      <p className="sr-text-ellipsis">
                        {hasDeleted && (
                          <Icon color="yellow" name="warning sign" />
                        )}
                        {backUpList.reduce((prev, curr) => [prev, ", ", curr])}
                      </p>
                    }
                  >
                    {backUpList.map((name, index) => (
                      <p key={index}>{name}</p>
                    ))}
                  </Popup>
                );
              }
            },
            {
              Header: "CreatedAt",
              accessor: "createdAt",
              maxWidth: 160,
              filterMethod: (filter, row) =>
                String(row[filter.id]).includes(filter.value)
            },
            {
              Header: "Last Login",
              accessor: "lastLogin",
              maxWidth: 160,
              filterMethod: (filter, row) =>
                String(row[filter.id]).includes(filter.value)
            },
            {
              Header: "Actions",
              accessor: "",
              minWidth: 200,
              sortable: false,
              filterable: false,
              show: isAdminOrDeveloper,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <Button
                      size="mini"
                      className="deleteButton"
                      onClick={() => {
                        props.original.patientCount > 0
                          ? this.checkPatientsInLpn(
                              props.original.id,
                              props.original.patientCount
                            )
                          : this.checkBackupByLpn(props.original.id).length > 0
                          ? this.checkBackup(
                              this.checkBackupByLpn(props.original.id),
                              props.original.id
                            )
                          : this.confirmDelete(
                              props.original.id,
                              props.original.firstName,
                              props.original.lastName
                            );
                      }}
                    >
                      Delete
                    </Button>
                    <EditAdminUser adminUser={props.original} />
                    <ButtonResetPassword id={props.original.userName} />
                  </div>
                );
              }
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "email",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  listAdminStaff: state.patientsListStore.listAdminStaff,
  listAdminDeletedStaff: state.patientsListStore.listAdminDeletedStaff,
  clinicSites: state.patientsListStore.clinicSites,
  EMRSystemList: state.patientsListStore.EMRSystemList,
  userGroups: state.authGroupStore.session.userGroups
});
const mapDispatchToProp = dispatch => ({
  listAdminUsers: () => dispatch(listAdminUsers()),
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getEMRList: () => dispatch(getEMRList()),
  changeAdminStaffStatus: (input, self) =>
    dispatch(changeAdminStaffStatus(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(AdminList);
