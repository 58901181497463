// eslint-disable
// this is an auto generated file. This will be overwritten

export const getPhotoReview = `query getPatient($userid: String!, $start: String, $end: String) {
  getPatient(userId: $userid) {
    userId
    photoTask{
      taskId
      taskTime
      status
      type
      imageUrls
    }
    footReports(startDate: $start, endDate: $end) {
      date
      steps
      timeWorn
    }
  }
}
`;

export const docDetail = `query getPatient($id: String!, $userid: String!, $start: String, $end: String) {
  getPatient(id: $id, userId: $userid) {
    id
    accountStatus
    accountBillingStatus
    accountServiceStatus
    authSecondaryContact
    appInitialized
    appInitializedDate
    appVersion
    birthday
    category
    contactName
    contactPhone
    createdAt
    deletedAt
    deletedBy
    doctorId
    diagnosisCodes
    dispensed
    dispensedDay
    dispenseComments
    dispenseType
    dispenseSize
    dispenseColor
    dispenseAddress
    dispenseCity
    dispenseState
    dispenseZip
    enableApp
    enableSms
    enableEmail
    email
    firstName
    footStatusPush
    footSpecific
    footAmputation
    gender
    homePhone
    initialized
    initializedStr
    initializedDate
    lastName
    lastNotification
    LPN
    medicalCondition
    patientId
    patientMBI
    patientResidence
    patientHasSmartPhone
    patientHasWifi
    patientNotes
    phone
    photoSource
    relation
    reminderPush
    reviewStatus
    reviewPhotoStatus
    reviewSymptomStatus
    secondaryEmail
    singleFoot
    siteId
    siteName
    sirenId
    sockStatus
    specialCase
    specialCaseReason
    symptomDetail
    sex
    userId
    userTimezone
    program
    rpmProgramStatus
    rtmProgramStatus
    pcmProgramStatus
    ccmProgramStatus
    ccmDeviceStatus
    pcmDeviceStatus
    rtmDeviceStatus
    rpmDeviceStatus
    socks{
      createdAt
    }
    hubs{
      hubId
    }
    notes{
      id
      timestamp
      author
      note
      title
      authorId
      pinned
    }
    photoTask{
      taskId
      taskTime
      status
      type
      imageUrls
    }
    footStatuses(startDate: $start, endDate: $end){
      overall
      date
      ltoe
      lmts1
      lmts3
      lmts5
      larch
      lheel
      rtoe
      rmts1
      rmts3
      rmts5
      rarch
      rheel
      reviewStatus
      timewornWithoutShoes
      noShoesDetails
    }
    footSymptoms(startDate: $start, endDate: $end){
      reportTime
      symptomType
      footSymptom
      source
    }
    weightScale{
      fat
      fatUnit
      weight
      weightUnit
      localTime
      mac
      source
    }
    reviewTask{
      taskTime
      reviewType
      oldStatus
      newStatus
      program
      device
      date
      reportTime
    }
    patientMedicalInfo{
      footConditions{
        reportTime
        footCondition
        foot
        footArea
        startTime
        endTime
        followUpTime
        status
      }
      footTreatments{
        reportTime
        footTreatment
        foot
        startTime
        endTime
        followUpTime
        status
      }
      footSpecific
      footAmputation
      medicalCondition
    }
  }
}
`;

const footStatusCleaned = `
  footStatusesCleaned(startDate: $start, endDate: $end) {
    overall
    date
    ltoe
    lmts1
    lmts3
    lmts5
    larch
    lheel
    rtoe
    rmts1
    rmts3
    rmts5
    rarch
    rheel
  }
`;
export const getFoot = `query getPatient($id: String!, $userid: String!, $start: String, $end: String) {
  getPatient(id: $id, userId: $userid) {
    userId
    footStatuses(startDate: $start, endDate: $end){
      overall
      date
      ltoe
      lmts1
      lmts3
      lmts5
      larch
      lheel
      rtoe
      rmts1
      rmts3
      rmts5
      rarch
      rheel
      noShoesDetails
      timewornWithoutShoes
    }
    footOverview(userId: $userid, date: $end){
      minuteWorn
      steps
      leftAvg
      rightAvg
      leftAvg7
      rightAvg7
      leftPrevAvg
      rightPrevAvg
      leftPrevAvg7
      rightPrevAvg7
    }
    ${process.env.REACT_APP_ENV === "staging" ? footStatusCleaned : ""}
  }
}`;
export const getSock = `query getPatient($id: String!, $userid: String!) {
  getPatient(id: $id, userId: $userid) {
    userId
    sirenId
    userTimezone
    socks{
      boxId
      registered
      serialNo
      sku
      foot
      battery
      lastSeen
      sensorStatus
      statusDetails
      status
      fwVersion
      brokenTime
      dataLastSeen
    }
    hubs{
      hubId
      authcode
      lastSeen
      hubLastSeen
      fwVersion
      csq
      ccid
      expireDate
      lifecycle
      lifecycleEndTime
      syncTime
      totalFlow
      useageFlow
      sku
    }
    appInfo{
      appVersion
      deviceName
      deviceId
      deviceOS
      devcieOSVersion
      createdAt
      updatedAt
    }
    shoeTags{
      userId
      tagId
      authCode
      mac
      lastSeen
      dataLastSeen
      battery
    }
    pillBottles{
      pillBottleId
      authCode
      mac
      userId
      battery
      lastSeen
      dataLastSeen
    }
  }
}
`;
export const getSockBySirenId = `query getPatientBySirenId($sirenId:Int!){
  getPatientBySirenId(sirenId: $sirenId){
    id
    userId
    userTimezone
    socks{
      serialNo
      boxId
      sku
      fwVersion
      lastSeen
      dataLastSeen
      battery
      brokenTime
      sensorStatus
      statusDetails
      status
      registered
      foot
    }
    hubs{
      hubId
      authcode
      lastSeen
      fwVersion
      hubLastSeen
      csq
      ccid
      expireDate
      lifecycle
      lifecycleEndTime
      syncTime
      totalFlow
      useageFlow
      sku
    }
    appInfo{
      appVersion
      deviceOS
      deviceId
      deviceName
      devcieOSVersion
      createdAt
    }
    shoeTags{
      userId
      tagId
      authCode
      mac
      lastSeen
      dataLastSeen
      battery
    }
    pillBottles{
      userId
      pillBottleId
      authCode
      mac
      lastSeen
      dataLastSeen
      battery
    }
  }
}
`;
export const checkPatientSocksAndHub = `query getPatientBySirenId($sirenId:Int!){
  getPatientBySirenId(sirenId: $sirenId){
    socks{
      serialNo
    }
    hubs{
      hubId
    }
  }
}
`;
// export const listPatients = `query listPatient($filter: ModelPatientFilterInput) {
//   listPatients(filter: $filter) {
//       items {
//         sirenId
//         userTimezone
//         complianceReviewed
//         complianceReviewReq
//         group
//         userId
//         email
//         phone
//         givenName
//         dataLastSeen
//         timeWorn
//         overallStatus
//         sockStatus
//         accountStatus
//       }
//   }
// }
// `;

export const searchGlobalPatients = `query searchPatients($searchStr: String!, $filter: SearchablePatientFilterInput, $sort: SearchablePatientSortInput, $limit: Int,$nextToken: Int) {
  globalSearchPatients(searchStr: $searchStr, filter: $filter, sort: $sort,
    limit: $limit,
    nextToken: $nextToken
  ) {
    items {
        sirenId
        userTimezone
        complianceReviewed
        complianceReviewReq
        accountStatus
        group
        userId
        siteId
        email
        phone
        givenName
        dataLastSeen
        timeWorn
        overallStatus
        sockStatus
        createdAt
        dispensedDay
        hubs{
          hubId
        }
    }
    total
    nextToken
  }
}`;

export const lambdaSearchPatients = `query searchPatients($searchStr: String, $sort: SearchablePatientSortInput, $filter: SearchablePatientFilterInput, $limit: Int, $nextToken: Int) {
  lambdaSearchPatients(searchStr: $searchStr, filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
   items {
        id
        sirenId
        userTimezone
        complianceReviewed
        complianceReviewReq
        accountStatus
        accountServiceStatus
        doctorId
        dispensed
        group
        patientResidence
        userId
        siteId
        siteName
        email
        patientMBI
        phone
        appVersion
        patientId
        clinicIRB
        patientIRB
        dataLastSeen
        appVersion
        timeWorn
        overallStatus
        sockStatus
        firstName
        lastName
        birthday
        createdAt
        dispensedDay
        appInitialized
        appInitializedDate
        hubType
        hubs{
          hubId
        }
        socks{
          createdAt
        }
        LPN
        program
        rpmProgramStatus
        rtmProgramStatus
        pcmProgramStatus
        ccmProgramStatus
        ccmDeviceStatus
        pcmDeviceStatus
        rtmDeviceStatus
        rpmDeviceStatus
      }
    total
    nextToken
  }
}`;

export const lambdaSearchAllPatients = `query searchPatients($searchStr: String, $sort: SearchablePatientSortInput, $filter: SearchablePatientFilterInput, $limit: Int, $nextToken: Int) {
  lambdaSearchPatients(searchStr: $searchStr, filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
   items {
        sirenId
        userTimezone
        userId
        siteId
        siteName
        email
        sockStatus
        patientMBI
        phone
        gender
        createdAt
        dispensedDay
        dispensed
        accountStatus
        patientResidence
        id
        firstName
        lastName
        appVersion
        patientId
        birthday
        doctorId
        contactName
        contactPhone
        initialized
        createdAt
        secondaryEmail
        relation
        medicalCondition
        footSpecific
        footAmputation
        patientNotes
        diagnosisCodes
        initializedStr
        initializedDate
        appInitialized
        accountBillingStatus
        appInitializedDate
        homePhone
        authSecondaryContact
        dispenseComments
        dispenseType
        dispenseSize
        dispenseColor
        dispenseAddress
        dispenseCity
        dispenseState
        dispenseZip
        symptomDetail
        deletedAt
        deletedBy
        program
        rpmProgramStatus
        rtmProgramStatus
        pcmProgramStatus
        ccmProgramStatus
        ccmDeviceStatus
        pcmDeviceStatus
        rtmDeviceStatus
        rpmDeviceStatus
        socks{
          createdAt
        }
        hubs{
          hubId
        }
        patientHasSmartPhone
        patientHasWifi
        enableApp
        enableEmail
        enableSms
        lastNotification
        footStatusPush
        reminderPush
        LPN
        accountServiceStatus
        singleFoot
      }
    total
    nextToken
  }
}`;

export const lambdaSearchSinglePatient = `query searchPatients($searchStr: String, $sort: SearchablePatientSortInput, $filter: SearchablePatientFilterInput, $limit: Int, $nextToken: Int) {
  lambdaSearchPatients(searchStr: $searchStr, filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
   items {
        userId
        appVersion
      }
    total
    nextToken
  }
}`;

export const searchableBoxConnection = `query searchBoxes($filter: SearchableBoxFilterInput, $sort: SearchableBoxSortInput, $limit: Int, $nextToken: Int){
  lambdaSearchBoxes(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
      items {
         id
         authCode
         createdAt
         registered
         raw
         registeredAt
         userId
         sku
         color
         size
         factoryDate
         hubId
         canRegistered
         canRegisteredReason
         socks {
           mac
           sn
           authCode
           foot
         }
      }
      total
      nextToken
   }
}`;

export const lambdaSearchHubs = `query searchHubs($filter: SearchableHubFilterInput, $sort: SearchableHubSortInput, $limit: Int, $nextToken: Int){
  lambdaSearchHubs(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
    items {
      userId
      hubId
      authcode
      lastSeen
      fwVersion
      hubLastSeen
      csq
      ccid
      lifecycle
      lifecycleEndTime
      syncTime
      totalFlow
      useageFlow
      registered
      registeredAt
      createdAt
      sirenId
      name
      sku
      expireDate
      canRegistered
      canRegisteredReason
    }
    total
    nextToken
  }
}`;

export const getExpireHubs = `query searchHubs($filter: SearchableHubFilterInput, $sort: SearchableHubSortInput, $limit: Int, $nextToken: Int){
  lambdaSearchHubs(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
    items {
      ccid
      hubId
      sku
      registered
      lastSeen
      hubLastSeen
      canRegistered
      sirenId
      siteId
      firstName
      lastName
      lastWornDay
      accountServiceStatus
      expireDate
      rpmProgramStatus
      rpmDeviceStatus
    }
    total
    nextToken
  }
}`;

// export const doctorRole = `query listPatient($filter: ModelPatientFilterInput) {
//   listPatients(filter: $filter) {
//       items {
//         sirenId
//         email
//         patientId
//         doctor{
//           doctorId
//           firstName
//           lastName
//         }
//         birthday
//         userTimezone
//         userId
//         givenName
//         timeWorn
//         lastSeen
//         overallStatus
//         reviewStatus
//         appointmented
//         scheduled
//         complianceReviewed
//         group
//       }
//   }
// }
// `;

export const boxPatients = `query listPatient {
  listPatients {
      items {
        sirenId
        userId
        givenName
      }
  }
}
`;
// export const trialPatients = `query listPatient($filter: ModelPatientFilterInput) {
//   listPatients(filter: $filter) {
//       items {
//         sirenId
//         userId
//         userTimezone
//         patientId
//         siteId
//         boxes
//         email
//         phone
//         givenName
//         dataLastSeen
//         steps
//         timeWorn
//         overallStatus
//         sockStatus
//       }
//   }
// }
// `;
export const listRegisteredPatients = `query listRegisteredPatients($filter: ModelRegisteredPatientFilterInput) {
  listRegisteredPatients(filter: $filter) {
    items {
      id
      sirenId
      email
      firstName
      lastName
      patientId
      siteId
      birthday
      doctorId
      phone
      contactName
      contactPhone
      initialized
      createdAt
      secondaryEmail
      relation
      dispensed
      medicalCondition
      patientNotes
      diagnosisCodes
      initializedStr
      homePhone
      authSecondaryContact
      dispenseComments
      dispenseType
      dispenseSize
      dispenseColor
      dispenseAddress
      dispenseCity
      dispenseState
      dispenseZip
      symptomDetail
    }
  }
}
`;
export const getCalendarData = `query getPatient($id: String!, $userId: String!, $startDate: String, $endDate: String,$limit:Int) {
  getPatient(id: $id, userId: $userId) {
    userId
     footStatuses(startDate: $startDate, endDate: $endDate,limit:$limit) {
        date
        overall
        reviewStatus
        minuteWorn
      }
  }
}
`;

export const listPatientMin1 = `query listPatientMin1($id: String!, $userId: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userId){
    min1(userId: $userId, startDate: $startDate, endDate: $endDate)
    min1raw(userId: $userId, startDate: $startDate, endDate: $endDate)
  }
}`;

export const listPatientMin1Diff = `query listPatientMin1($id: String!, $userId: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userId){
    min1(userId: $userId, startDate: $startDate, endDate: $endDate)
    min1raw(userId: $userId, startDate: $startDate, endDate: $endDate)
    min1Washed(userId: $userId, startDate: $startDate, endDate: $endDate)
    min1rawWashed(userId: $userId, startDate: $startDate, endDate: $endDate)
    min1rawMarked(userId: $userId, startDate: $startDate, endDate: $endDate) {
      startTime
      endTime
      foot
      mark
    }
  }
}`;

export const listPatientPillBottleData = `query listPatientPillBottleData($id: String!, $userId: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userId){
    pillBottleData(userId: $userId, startDate: $startDate, endDate: $endDate)
  }
}`;

export const listPatientMin1Tag = `query listPatientMin1Tag($id: String!, $userId: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userId){
    min1tag(userId: $userId, startDate: $startDate, endDate: $endDate)
  }
}`;

export const listPatientMin1SlowStep = `query listPatientMin1SlowStep($id: String!, $userId: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userId){
    min1SlowStep(userId: $userId, startDate: $startDate, endDate: $endDate)
  }
}`;

export const listPatientMin30 = `query listPatientMin30($id: String!, $userId: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userId){
    min30(userId: $userId, startDate: $startDate, endDate: $endDate)
    min30raw(userId: $userId, startDate: $startDate, endDate: $endDate)
  }
}`;

export const listPatientMin30Tag = `query listPatientMin30Tag($id: String!, $userId: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userId){
    min30tag(userId: $userId, startDate: $startDate, endDate: $endDate)
  }
}`;

export const listUserGroups = `query listUserGroups{
  listUserGroups
}`;
export const listClinicSites = `query listClinicSitesNew($status: String) {
  listClinicSitesNew(status: $status) {
    items {
      siteId
      name
      address
      fax
      contact
      phone
      serviceDate
      autoDoctorReport
      city
      state
      zip
      siteNPI
      IRBConsent
      hubContract
      onStaffing
      EMRSystem
      activePatients
      onServicePatients
      deletedAt
      deletedBy
      parentSiteId
      tag
    }
  }
}
`;
export const listTrialClinicSites = `query listTrialClinicSites {
  listTrialClinicSites {
    items {
      siteId
      name
      abbr
    }
  }
}
`;
export const listStaff = `query listStaff($filter: ModelStaffFilterInput, $limit: Int, $nextToken: String) {
  listStaff(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      siteId
      firstName
      lastName
      email
      isPrescribing
      doctorId
      doctorRole
      autoReportSites
      notificationEmail
      doctorNPI
      isAdministrator
      lastLogin
      status
      deletedAt
      deletedBy
      createdAt
    }
    nextToken
  }
}
`;
export const listTrialStaff = `query listTrialStaff {
  listTrialStaff {
    items {
      siteId
      firstName
      lastName
      email
      doctorId
      doctorRole
    }
  }
}
`;
export const patientStaff = `query listStaff($filter: ModelStaffFilterInput) {
  listStaff(filter: $filter) {
    items {
      siteId
      email
      isPrescribing
      doctorId
      firstName
      lastName
    }
  }
}
`;
export const PatientMinuteWorn = `query getPatient($id: String!, $userid: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userid) {
    timeWornByDay(userId: $userid, startDate: $startDate, endDate: $endDate) {
      date
      minuteWorn
    }
  }
}
`;
export const getPillBottleTimeUsage = `query getPatient($id: String!, $userid: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userid) {
    pillBottleTimeUsage(userId: $userid, startDate: $startDate, endDate: $endDate) {
      date
    }
  }
}
`;
export const TagMinuteWorn = `query getPatient($id: String!, $userid: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userid) {
    tagTimeWornByDay(userId: $userid, startDate: $startDate, endDate: $endDate) {
      date
      minuteWorn
    }
  }
}
`;
export const TagWeekWorn = `query getPatient($id: String!, $userid: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userid) {
    tagTimeWornByWeek(userId: $userid, startDate: $startDate, endDate: $endDate) {
      activationDate
      aveDaysWorn
      tagSource
      tagId
    }
  }
}
`;
// export const listBillingPatients = `query listBillingPatients($siteId: String! $year: Int $month:Int) {
//   listBillingPatient(siteId:$siteId, year: $year, month: $month ) {
//     items {
//       userId
//       sirenId
//       billDay
//       givenName
//       siteName
//       createdAt
//       dispensedDay
//       period99454Start
//       period99454End
//       periodStdStart
//       periodStdEnd
//       daysWorn
//       billed99453
//       billed99454
//       billed99457
//       billingKey
//     },
//     nextToken
//   }
// }
// `;

export const listBillingSummary = `query listBillingSummary($siteId: String! $sirenIds: String $year: Int $month:Int) {
  listBillingSummary(siteId:$siteId, sirenIds: $sirenIds, year: $year, month: $month ) {
    items {
      userId
      patientShortName
      sirenId
      givenName
      patientId
      cptCode
      serviceDate
      billDay
      createdAt
      dispensedDay
      period99454Start
      period99454End
      periodStdStart
      periodStdEnd
      daysWorn
      siteName
      yellowDays
      redDays
      birthday
      doctorId
      min30
      doctorName
    },
    nextToken
  }
}
`;
export const searchDoctorReports = `query searchDoctorReports($filter: SearchableDoctorReportFilterInput,
  $sort: SearchableDoctorReportSortInput,
  $limit: Int,
  $nextToken: Int) {
searchDoctorReports(filter: $filter, sort: $sort,
  limit: $limit,
  nextToken: $nextToken) {
  items {
    siteId
    siteName
    reportDay
    to
  }
}
}
`;
export const getMacInfo = `query getMacInfo($mac: String! $startDate: String $endDate: String ) {
  getMacInfo(mac:$mac, startDate: $startDate, endDate: $endDate)
}`;

export const getTagInfo = `query getTagInfo($mac: String! $startDate: String $endDate: String ) {
  getTagInfo(mac:$mac, startDate: $startDate, endDate: $endDate)
}`;

export const getDoctorNpi = `query getDoctorNpi($firstName: String!,$lastName: String!){
  getDoctorNpi(firstName:$firstName, lastName:$lastName)
}`;

export const getSiteNpi = `query getSiteNpi($organizationName: String!){
  getSiteNpi(organizationName:$organizationName)
}`;

export const listInvoicePatients = `query listInvoicePatients($siteIds: [String] $startDate: String! $endDate: String!) {
  listInvoicePatient(siteIds:$siteIds, startDate: $startDate, endDate: $endDate ) {
    items {
      stdDaysWorn
      billingKey
      userId
      month
      sirenId
      birthday
      createdAt
      givenName
      siteId
      doctorId
    },
    nextToken
  }
}`;

export const listRpmTracker = `query listRpmTracker($userId: String!, $startDate: String, $endDate: String){
  listRpmTracker(userId: $userId, startDate: $startDate, endDate: $endDate) {
     items {
       userId
       rpmKey
       serviceDate
       spendTime
       doctorName
       doctorId
       rpmService
       description
       doctorTime
       nurseTime
       medicalCondition
       footSpecific
       footAmputation
       createdAt
       diagnosisCodes
       program
       programTime
       symptoms {
         reportTime
         footSymptom
         detail
         foot
         footArea
       }
       footConditions{
         reportTime
         footCondition
         foot
         footArea
         startTime
         endTime
         followUpTime
         status
       }
       footTreatments{
         reportTime
         footTreatment
         foot
         startTime
         endTime
         followUpTime
         status
       }
       addendums{
         userName
         addendum
         addendumTime
         nurseTime
         doctorTime
       }
     }
  }
}`;

export const listRpmNoteTemplates = `query allRpmNoteTemplate {
  getAllRpmNoteTemplates {
    id
    title
    parentId
    order
  }
}`;

export const listRpmNoteTemplatesDetails = `query allRpmNoteTemplate {
  getAllRpmNoteTemplates {
    id
    title
    parentId
    order
    content
    referTables
    customVar
  }
}`;

export const getCompiledRpmNoteTemplate = `query getCompiledRpmNoteTemplate($tmplId: String!, $patientUserId: String!) {
  getCompiledRpmNoteTemplate(tmplId: $tmplId, patientUserId: $patientUserId)
}`;

export const managementSummaryStat = `query managementSummary($searchStr: String, $filter: SearchablePatientFilterInput) {
  lambdaManagementSummary(searchStr: $searchStr, filter: $filter) {
    overall
    total
    appActivation
    sockDispensed
    registered
  }
}`;

export const listManagementSummary = `query searchPatients($searchStr: String, $sort: SearchablePatientSortInput, $filter: SearchablePatientFilterInput, $limit: Int, $nextToken: Int) {
  lambdaSearchPatients(searchStr: $searchStr, filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      sirenId
      siteName
      email
      firstName
      lastName
      patientId
      siteId
      birthday
      dataLastSeen
      doctorId
      doctorName
      phone
      createdAt
      dispensed
      dispensedDay
      shipmentStatus
      initializedDate
      initializedStr
      appInitialized
      appInitializedDate
      sockStatus
      overallStatus
      userTimezone
      hubType
      hubLastSeen
      hubDataLastSeen
      appLastSeen
      sockRegistered
    }
    total
    nextToken
  }
}
`;
export const listBilling99457Patients = `query listBilling99457Patients($siteId: String! $startDate: String $endDate:String) {
  listBilling99457Patient(siteId:$siteId, startDate: $startDate, endDate: $endDate ) {
    items {
      id
      userId
      siteId
      siteName
      sirenId
      birthday
      billedType
      firstName
      lastName
      sirenId
      patientId
      billingDate
      diagnosisCodes
      billedAt
      initializedDate
      appInitialized
      billedBy
      billedStatus
      givenName
      doctorName
      createdAt
      dispensedDay
      period99454Start
      period99454End
      periodStdStart
      periodStdEnd
      daysWorn
      totalMinutesSpent
      serviceE
      firstStatusDay
      accountBillingStatus
      reportLocation
    },
    nextToken
  }
}
`;
export const listBilling99453Patients = `query listBilling99453Patients($siteId: String!) {
  listBilling99453Patient(siteId: $siteId) {
    items {
      userId
      siteId
      siteName
      billedType
      sirenId
      birthday
      firstName
      patientId
      id
      lastName
      appInitialized
      diagnosisCodes
      sirenId
      billDay
      billed99453
      billed99453By
      billed99453At
      initializedDate
      givenName
      doctorName
      createdAt
      dispensedDay
      daysWorn
      estimated99454StartDay
      estimated99454DaysWorn
      accountStatus
      firstStatusDay
      accountBillingStatus
      rpmDeviceStatus
      rtmDeviceStatus
      rpmProgramStatus
      rtmProgramStatus
      pcmProgramStatus
      ccmProgramStatus
    },
    nextToken
  }
}
`;
export const listBilling99454Patients = `query listBilling99454Patients($siteId: String! $startDate: String $endDate:String) {
  listBilling99454Patient(siteId:$siteId, startDate: $startDate, endDate: $endDate ) {
    items {
      userId
      id
      siteId
      siteName
      billedType
      sirenId
      birthday
      firstName
      lastName
      billDay
      sirenId
      billingDate
      billedAt
      patientId
      initializedDate
      appInitialized
      billedBy
      doctorName
      billedStatus
      givenName
      createdAt
      dispensedDay
      period99454Start
      period99454End
      estimated99454DaysWorn
      estimated99454StartDay
      daysWorn
      diagnosisCodes
      accountBillingStatus
      reportLocation
    },
    nextToken
  }
}
`;

export const verifyBoxInfo = `query verifyBoxInfo($boxId:String! $siteId:String!){
  verifyBoxInfo(boxId: $boxId, siteId:$siteId){
    id,
    authCode,
    registered
  }
}`;

export const checkAllPatientsInSite = `query searchPatients($searchStr: String, $sort: SearchablePatientSortInput, $filter: SearchablePatientFilterInput, $limit: Int, $nextToken: Int) {
  lambdaSearchPatients(searchStr: $searchStr, filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
   items {
        id
      }
    total
    nextToken
  }
}`;

export const listAdminStaff = `query listAdminStaff($status: String){
  listAdminStaff(status: $status){
   items{
     id
     sub
     email
     firstName
     lastName
     phone
     status
     createdAt
     updatedAt
     emailVerified
     phoneVerified
     group
     primarySites
     EMRSystem
     backupFor
     patientLoad
     filterColumns
     filterBackup
     patientCount
     deletedAt
     deletedBy
   }
  }
}`;

export const getSirenStaffingPatients = `query searchPatients($searchStr: String, $sort: SearchablePatientSortInput, $filter: SearchablePatientFilterInput, $limit: Int, $nextToken: Int) {
  lambdaSearchPatients(searchStr: $searchStr, filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sirenId
      siteName
      userTimezone
      complianceReviewed
      complianceReviewReq
      accountStatus
      doctorId
      doctorName
      userId
      siteId
      firstName
      lastName
      birthday
      reviewStatus
      LPN
      last4DaysWorn
      specialCase
      patientHasSmartPhone
      overallStatus
      dataLastSeen
      totalMinutesSpent
      lastDMT
      serviceE
      photoSource
      accountServiceStatus
      followUpTimes
      reviewPhotoStatus
      reviewSymptomStatus
      lastServiceE
      category
      ccmTime
      pcmTime
      rpmTime
      rtmTime
      program
      rpmProgramStatus
      rtmProgramStatus
      pcmProgramStatus
      ccmProgramStatus
      ccmDeviceStatus
      pcmDeviceStatus
      rtmDeviceStatus
      rpmDeviceStatus
    }
    total
    nextToken
  }
}`;

export const getSirenStaffCount = `query getSirenStaffingCount($siteId: String, $lpn: String) {
  getSirenStaffingCount(siteId: $siteId, lpn: $lpn) {
    totalCount
    onboardingCount
    medicalReviewCount
    photoReviewCount
    complianceCount
    specialCaseCount
    symptomCount
    followUpCount
    dataUpdateCount
    noneCount
  }
}`;

export const getLpnStaff = `query getLpnStaff{
  getLpnStaff{
   items{
     id
     email
     firstName
     lastName
     group
     primarySites
     EMRSystem
     backupFor
     patientLoad
     patientCount
   }
  }
}`;

export const getNeedAssignPatients = `query listNeedAssignPatients {
  listNeedAssignPatients {
    items {
      id
      sirenId
      siteName
      userTimezone
      accountStatus
      doctorId
      userId
      siteId
      firstName
      lastName
      birthday
      patientHasSmartPhone
      rpmProgramStatus
      rtmProgramStatus
      pcmProgramStatus
      ccmProgramStatus
    }
  }
}`;

export const getWeightScale = `query getPatient($id: String!, $userid: String!) {
  getPatient(id: $id, userId: $userid) {
    weightScale{
      fat
      fatUnit
      weight
      weightUnit
      localTime
      mac
      source
    }
  }
}
`;

export const getEMRList = `query getEMRList{
  getEMRList{
    items{
      name
      createdAt
      updatedAt
    }
  }
}
`;

export const getSMSDripList = `query listSmsDrip($filter:ModelSmsDripFilterInput, $limit: Int, $nextToken: String){
  listSmsDrip(filter:$filter, limit: $limit, nextToken: $nextToken){
    items{
      id
      firstName
      siteId
      createdAt
      updatedAt
      caseChangeTime
      status
      waitingOnPatient
      userTimezone
      SMS_01_Time
      SMS_02_Time
      SMS_03_Time
      SMS_04_Time
      SMS_05_Time
      SMS_06_Time
      SMS_07_Time
      SMS_08_Time
      SMS_09_Time
    }
    nextToken
  }
}`;

export const getPatientActivity = `query getPatientActivity($patientId: String!, $limit: Int, $nextToken: Int, $yearMonth: String, $category: [PatientActivityCategory]) {
  getPatientActivity(patientId: $patientId, limit: $limit, nextToken: $nextToken, yearMonth: $yearMonth, category: $category) {
    items {
      status
      subject
      description
      loggingType
      callType
      createdDate
      createdBy
    }
    nextToken
  }
}`;

export const listShoeTag = `query listShoeTag($tagId: String){
  listShoeTags(tagId: $tagId){
    items{
      tagId
      mac
      authCode
      createdAt
      updatedAt
      registered
      registeredAt
      userId
      sirenId
    }
  }
}`;

export const listPillBottles = `query listPillBottles($pillBottleId: String){
  listPillBottles(pillBottleId: $pillBottleId){
    items{
      pillBottleId
      mac
      authCode
      createdAt
      updatedAt
      registered
      registeredAt
      userId
      sirenId
    }
  }
}`;

export const listPrescription = `query listPrescription($userId: String) {
  listPrescriptionHistory(userId: $userId) {
    createdAt
    dispenseType
    sockSize
    sockColor
    notes
  }
}`;

export const listCarePlans = `query getCarePlans($userId: String!) {
  getCarePlans(userId: $userId) {
    createdAt
    userId
    name
    author
    authorId
    location
    month
  }
}`;

export const listICD10Codes = `query listICD10Codes {
  listICD10Codes {
    code
    createdBy
    createdAt
  }
}`;

export const listMedicalConditions = `query listMedicalConditions {
  listMedicalConditions {
    condition
    value
    chronicType
    createdBy
    createdAt
  }
}`;

export const listBillingPCMPatients = `query listBillingPCMPatients($siteId: String! $startDate: String $endDate:String) {
  listBillingPCMPatient(siteId:$siteId, startDate: $startDate, endDate: $endDate ) {
    items {
      id
      userId
      siteId
      siteName
      sirenId
      birthday
      billedType
      firstName
      lastName
      sirenId
      patientId
      billingKey
      diagnosisCodes
      billedAt
      initializedDate
      appInitialized
      billedBy
      billedStatus
      givenName
      doctorName
      createdAt
      dispensedDay
      periodStdStart
      periodStdEnd
      totalMinutesSpent
      firstStatusDay
      accountBillingStatus
    },
    nextToken
  }
}
`;
export const listDoctorReviewHistory = `query listDoctorReviewHistory($userId: String!, $limit: Int, $nextToken: String) {
  listDoctorReviewHistory(userId: $userId, limit: $limit, nextToken: $nextToken) {
    items {
      userId
      reviewTime
      endTime
      timeSpent
      createdBy
    }
    total
    nextToken
  }
}`;

export const getSockBySerialNo = `query getSockBySerialNo($serialNo: String!) {
  getSockBySerialNo(serialNo:$serialNo){
    boxId
    userId
    serialNo
    lastSeen
    dataLastSeen
    foot
    battery
    sensorStatus
    status
  }
}`;

export const getPatientBySiteId = `query getPatientBySiteId($siteId:String!){
  getPatientBySiteId(siteId:$siteId){
     items{
        sirenId
        userTimezone
        userId
        siteId
        siteName
        email
        sockStatus
        patientMBI
        phone
        gender
        createdAt
        dispensedDay
        dispensed
        accountStatus
        patientResidence
        id
        firstName
        lastName
        appVersion
        patientId
        birthday
        doctorId
        contactName
        contactPhone
        initialized
        createdAt
        secondaryEmail
        relation
        medicalCondition
        footSpecific
        footAmputation
        patientNotes
        diagnosisCodes
        initializedStr
        initializedDate
        appInitialized
        accountBillingStatus
        appInitializedDate
        homePhone
        authSecondaryContact
        dispenseComments
        dispenseType
        dispenseSize
        dispenseColor
        dispenseAddress
        dispenseCity
        dispenseState
        dispenseZip
        symptomDetail
        deletedAt
        deletedBy
        program
        rpmProgramStatus
        rtmProgramStatus
        pcmProgramStatus
        ccmProgramStatus
        ccmDeviceStatus
        pcmDeviceStatus
        rtmDeviceStatus
        rpmDeviceStatus
        patientHasSmartPhone
        patientHasWifi
        enableApp
        enableEmail
        enableSms
        lastNotification
        footStatusPush
        reminderPush
        LPN
        accountServiceStatus
        singleFoot
     }   
  }
}
`;
