import React, { Component } from "react";
import { Button } from "antd";
import _ from "lodash";
import "./patient-medical.css";
import MedicalPop from "./medicalPop";
import DividLine from "./dividLine";
import AddSymptoms from "./addSymptoms";
import FootSymptomItem from "./FootSymptomItem";

/**
 *  Created by shengli.zhang on 4/30/21 3:17 PM
 */

export default class FootSymptom extends Component {
  state = {
    addAnotherSymptom: false,
    symptomData: this.props.data || []
  };

  render() {
    return (
      <div>
        <div
          style={{
            fontWeight: "bold",
            marginBottom: 10,
            marginTop: 10,
            display: "flex",
            fontSize: 18
          }}
        >
          Symptoms
          <MedicalPop
            title="Symptoms"
            content={
              "Any new or changed symptoms, such as increased pain, redness, swelling, blisters, new openings or ingrown toenail reported by the patient. "
            }
          />
        </div>
        <div>
          {this.state.symptomData.map((data, index) => (
            <FootSymptomItem
              readOnly={this.props.readOnly}
              data={data}
              key={data.reportTime}
              saveAction={data => {
                const nextSymptomData = [...this.state.symptomData];
                nextSymptomData.splice(index, 1, data);
                this.setState({ symptomData: nextSymptomData });
                if (this.props.onSymptomChange) {
                  this.props.onSymptomChange(nextSymptomData);
                }
              }}
              deleteAction={() => {
                const nextSymptomData = [...this.state.symptomData];
                nextSymptomData.splice(index, 1);
                this.setState({ symptomData: nextSymptomData });
                if (this.props.onSymptomChange) {
                  this.props.onSymptomChange(nextSymptomData);
                }
              }}
            />
          ))}
        </div>
        {this.props.readOnly ? null : (
          <>
            <AddSymptoms
              style={{
                marginTop: 8,
                display: this.state.addAnotherSymptom ? "block" : "none"
              }}
              saveAction={data => {
                const nextSymptomData = [...this.state.symptomData, data];
                this.setState({
                  symptomData: nextSymptomData,
                  addAnotherSymptom: false
                });
                if (this.props.onSymptomChange) {
                  this.props.onSymptomChange(nextSymptomData);
                }
              }}
              removeAction={() => {
                this.setState({
                  addAnotherSymptom: false
                });
              }}
            />
            <Button
              type="link"
              style={{
                marginLeft: -16
              }}
              onClick={() => {
                this.setState({
                  addAnotherSymptom: true
                });
              }}
            >
              + Add another symptom
            </Button>
          </>
        )}
        <DividLine />
      </div>
    );
  }
}
