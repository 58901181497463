import React, { Component } from "react";
import { Table } from "semantic-ui-react";

class SocksList extends Component {
  render() {
    return (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>MAC</Table.HeaderCell>
              <Table.HeaderCell>Serial Number</Table.HeaderCell>
              <Table.HeaderCell>Sock Auth Code</Table.HeaderCell>
              <Table.HeaderCell>Foot</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.socks &&
              this.props.socks.map((sock, i) => {
                let randomId = Math.round(Math.random() * 10000);
                let sockDiv = (
                  <Table.Row key={"sock-list" + randomId + i}>
                    <Table.Cell>{sock.mac}</Table.Cell>
                    <Table.Cell>{sock.sn}</Table.Cell>
                    <Table.Cell>{sock.authCode}</Table.Cell>
                    <Table.Cell>{sock.foot}</Table.Cell>
                  </Table.Row>
                );
                return sockDiv;
              })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default SocksList;
