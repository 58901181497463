import { Modal as AntdModal } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import _api from "../graphql/_api";
import {
  caseConversion,
  changeDoctorDetails,
  changeSymtoms
} from "./patientAction";

/**
 *  Created by shengli.zhang on 5/3/21 10:17 PM
 */

export function addFootCondition(input, self) {
  return async dispatch => {
    try {
      // const newEvent = await API.graphql(
      //   graphqlOperation(_api.createFootCondition(), { input })
      // );
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;
        return {
          loadings: newLoadings
        };
      });
      // if (newEvent && newEvent.data.createFootCondition) {
      self.props.updateConditionData(input);
      // }
    } catch (e) {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;

        return {
          loadings: newLoadings
        };
      });
      AntdModal.warning({
        title: "Add Foot Condition Failed",
        content: `${JSON.stringify(e)}`,
        centered: true
      });
    }
  };
}

export function addFootTreatment(input, self) {
  return async dispatch => {
    try {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;
        return {
          loadings: newLoadings
        };
      });
      self.props.updateTreatmentData(input);
    } catch (e) {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;

        return {
          loadings: newLoadings
        };
      });
      AntdModal.warning({
        title: "Add Foot Treatment Failed",
        content: `${JSON.stringify(e)}`,
        centered: true
      });
    }
  };
}

export function addFootSymptom(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.createFootSymptom(), { input })
      );
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;
        return {
          loadings: newLoadings
        };
      });
      console.log("newEvent: ", newEvent);
      if (newEvent && newEvent.data.createFootSymptom) {
        let patientSymptoms = [];
        let patientSymptomsApp = [];
        let docDetails = _.clone(self.props.doctorDetails);
        input.source = "DMT";
        docDetails.footSymptoms.push(input);
        let footSymptomByDMT = _.filter(docDetails.footSymptoms, function(
          item
        ) {
          return item.source === "DMT";
        });
        let footSymptomByApp = _.filter(docDetails.footSymptoms, function(
          item
        ) {
          return item.source !== "DMT";
        });
        _(footSymptomByDMT)
          .groupBy(item => {
            return _.split(item.reportTime, "T")[0];
          })
          .flatMap((value, key) => {
            let symptomType = undefined;
            const symptomTypes = value.map(itemSub => {
              if (!itemSub.symptomType) {
                symptomType = itemSub.footSymptom ? itemSub.footSymptom : "";
                return symptomType;
              } else {
                symptomType = caseConversion(itemSub.symptomType);
                return symptomType;
              }
            });
            patientSymptoms.push({
              reportTime: key,
              symptomType: symptomTypes
                .map(p => {
                  return p;
                })
                .join(", "),
              source: "DMT"
            });
          })
          .uniq()
          .value();

        _(footSymptomByApp)
          .groupBy(item => {
            return _.split(item.reportTime, "T")[0];
          })
          .flatMap((value, key) => {
            let symptomType = undefined;
            const symptomTypes = value.map(itemSub => {
              if (!itemSub.symptomType) {
                symptomType = itemSub.footSymptom ? itemSub.footSymptom : "";
                return symptomType;
              } else {
                symptomType = caseConversion(itemSub.symptomType);
                return symptomType;
              }
            });
            patientSymptomsApp.push({
              reportTime: key,
              symptomType: symptomTypes
                .map(p => {
                  return p;
                })
                .join(", "),
              source: "APP"
            });
          })
          .uniq()
          .value();
        const symptoms = _.orderBy(
          [...patientSymptoms, ...patientSymptomsApp],
          ["reportTime"],
          ["desc"]
        );
        dispatch(
          changeSymtoms({
            footSymptoms: symptoms
          })
        );
        self.props.removeSymptom();
      }
    } catch (e) {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;

        return {
          loadings: newLoadings
        };
      });
      AntdModal.warning({
        title: "Add Foot Symptom Failed",
        content: `${JSON.stringify(e)}`,
        centered: true
      });
    }
  };
}

export function updateFootCondition(input, self) {
  return async dispatch => {
    try {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;

        return {
          loadings: newLoadings,
          visible: false
        };
      });
      self.props.updateConditionData(input);
      // }
    } catch (e) {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;

        return {
          loadings: newLoadings
        };
      });
      AntdModal.warning({
        title: "Update Foot Condition Error",
        content: `${JSON.stringify(e)}`,
        centered: true
      });
    }
  };
}

export function updateFootConditionStatus(input, self, index) {
  return async dispatch => {
    try {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[index] = false;
        return {
          loadings: newLoadings
        };
      });

      let nextConditionData = [];
      if (
        _(self.state.conditionData).find({
          reportTime: input.reportTime
        })
      ) {
        nextConditionData = self.state.conditionData.map(item => {
          if (item.reportTime === input.reportTime) {
            return {
              ...item,
              ...input,
              endTime: input.endTime ? input.endTime : undefined,
              followUpTime: undefined
            };
          }
          return item;
        });
      } else {
        nextConditionData = [...self.state.conditionData, { ...input }];
      }
      console.log("nextConditionData: ", nextConditionData);
      self.setState({
        conditionData: nextConditionData
      });
      if (self.props.onChangeFootConditionData) {
        self.props.onChangeFootConditionData(nextConditionData);
      }
      // }
    } catch (e) {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[index] = false;
        return {
          loadings: newLoadings
        };
      });
      AntdModal.warning({
        title: "Mark Healed Foot Condition Error",
        content: `${JSON.stringify(e)}`,
        centered: true
      });
    }
  };
}

export function updateFootTreatment(input, self) {
  return async dispatch => {
    try {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;

        return {
          loadings: newLoadings,
          visible: false
        };
      });
      self.props.updateTreatmentData(input);
    } catch (e) {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;

        return {
          loadings: newLoadings
        };
      });
      AntdModal.warning({
        title: "Mark Completed Foot Treatment Error",
        content: `${JSON.stringify(e)}`,
        centered: true
      });
    }
  };
}

export function updateFootTreatmentStatus(input, self, index) {
  return async dispatch => {
    try {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[index] = false;
        return {
          loadings: newLoadings
        };
      });

      let nextTreatmentData = [];
      if (
        _(self.state.treatmentData).find({
          reportTime: input.reportTime
        })
      ) {
        nextTreatmentData = self.state.treatmentData.map(item => {
          if (item.reportTime === input.reportTime) {
            return {
              ...item,
              ...input,
              endTime: input.endTime ? input.endTime : undefined,
              followUpTime: undefined
            };
          }
          return item;
        });
      } else {
        nextTreatmentData = [
          ...self.state.treatmentData,
          { ...input, type: "EDIT" }
        ];
      }
      self.setState({ treatmentData: nextTreatmentData });
      if (self.props.onChangeFootTreatmentData) {
        self.props.onChangeFootTreatmentData(nextTreatmentData);
      }
    } catch (e) {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[index] = false;
        return {
          loadings: newLoadings
        };
      });
      AntdModal.warning({
        title: "Mark Completed Foot Treatment Error",
        content: `${JSON.stringify(e)}`,
        centered: true
      });
    }
  };
}
