import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { getFootStatus } from "../action/patientAction";
import "./calendarRange.css";
import "./MedicalDetailsHome.css";
import { DatePicker } from "antd";
import PatientDetailUrlContext from "../MedicalDashboard/medicaldetails/PatientDetailUrlContext";
import ReactEcharts from "echarts-for-react";
import { getWithoutShoesTimeWorn } from "../constant/time-worn-without-shoes-option";

class TimeWornWithOutShoes extends Component {
  static contextType = PatientDetailUrlContext;

  constructor(props) {
    super(props);
    this.state = {
      statusDate: ""
    };
  }

  initData = () => {
    // in case of being repeatly called
    this.oldContext = this.context;

    const { endDate } = this.context.param;
    this.setState({ statusDate: endDate });
    if (!_.isEmpty(endDate)) this.fetchData(endDate);
  };

  fetchData = date => {
    const { id, userId } = this.context.param;
    const { getFootStatus } = this.props;
    getFootStatus(id, userId, date, date);
  };

  // component maybe lazy load, so both of `componentDidMount` & `componentDidUpdate` should be implemented
  componentDidMount() {
    this.initData();
  }

  componentDidUpdate() {
    if (this.oldContext !== this.context) {
      this.oldContext = this.context;

      this.initData();
    }
  }

  goPreDay = () => {
    const prevDate = moment(this.state.statusDate)
      .add(-1, "days")
      .format("YYYY-MM-DD");
    this.setState({ statusDate: prevDate }, this.fetchData(prevDate));
  };

  goNextDay = () => {
    const nextDate = moment(this.state.statusDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
    this.setState({ statusDate: nextDate }, this.fetchData(nextDate));
  };

  onChartReady = echarts => {
    // console.log("echart is ready", echarts);
  };

  render() {
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <Button
            basic
            className="change_calenDay_button"
            onClick={this.goPreDay}
          >
            <Icon name="angle left" />
            Previous day
          </Button>
          <DatePicker
            size="large"
            onChange={date => {
              console.log("date: ", moment(date).format("YYYY-MM-DD"));
              this.setState({
                statusDate: moment(date).format("YYYY-MM-DD")
              });
            }}
            value={moment(this.state.statusDate)}
            format="YYYY-MM-DD"
            style={{ width: "200px" }}
          />
          <Button
            basic
            className="change_calenDay_button"
            onClick={this.goNextDay}
          >
            Next day
            <Icon name="angle right" />
          </Button>
        </div>
        <ReactEcharts
          option={getWithoutShoesTimeWorn(this.props.footStatus)}
          style={{ height: 400 }}
          lazyUpdate={true}
          onChartReady={this.onChartReady}
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  footStatus: state.patientsListStore.footStatus
});
const mapDispatchToProp = dispatch => ({
  getFootStatus: (id, userId, start, end) =>
    dispatch(getFootStatus(id, userId, start, end))
});
export default connect(mapStateToProp, mapDispatchToProp)(TimeWornWithOutShoes);
