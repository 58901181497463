import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { getDeleteStaff } from "../action/registerAction";
import ButtonSuccess from "../home/buttonSuccess";
import "../share/button.css";
import "../SupportDashboard/account/DeleteSite.css";
class DeleteStaff extends Component {
  constructor(props) {
    super(props);
    this.handleConfirm.bind(this);
  }
  state = { open: false, confirmSuccess: false };

  show = () => this.setState({ open: true, confirmSuccess: false });
  handleConfirm = () => {
    // this.setState({ confirmSuccess: true });
    const { getDeleteStaff } = this.props;
    const email = this.props.email;
    getDeleteStaff(email, this);
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });
  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini" className="deleteButton">
            Delete
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <p>Are you sure you want to permanently delete this staff?</p>
              <div>
                <p className="cofirmSiteId">Staff:<strong className="cofirmBold">{this.props.givenName}</strong></p>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  deletedListStaff: state.patientsListStore.deletedListStaff
});
const mapDispatchToProp = dispatch => ({
  getDeleteStaff: (email, self) => dispatch(getDeleteStaff(email, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(DeleteStaff);
