import { API, graphqlOperation } from "aws-amplify";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
/**
 *  Created by shengli.zhang on 4/25/21 1:46 PM
 */

export function createEMR(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.createEMR(), { input })
      );
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log(`create EMR error ${e}`);
    }
  };
}

export function deleteEMR(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteEMR(), { input })
      );
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log(`delete EMR error ${e}`);
    }
  };
}

export function getEMRList() {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(graphqlOperation(_api.getEMRList()));
      dispatch(
        changeEMRListStatus(newEvent.data.getEMRList.items.sort(compareEMR))
      );
    } catch (e) {
      console.log(`getEMRList error ${e}`);
    }
  };
}

function changeEMRListStatus(EMRList) {
  return {
    type: TYPES.EMR_SYSTEM_LIST,
    text: EMRList
  };
}

function compareEMR(a, b) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
}
