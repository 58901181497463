import { Checkbox, message, Popover, Select, Modal } from "antd";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Control } from "react-keeper";
import { connect } from "react-redux";
import {
  generateBillingReport,
  searchBilling99453Patients,
  searchBilling99454Patients,
  updateBilled99453Status,
  updateBilled99454Status
} from "../action/bliMonthyReportAction";
import { getlistClinicSites } from "../action/registerAction";
import { updateReportNeedsReviewTableFilter } from "../reducers/tableFilterReducer";
import ChangeBilled99454 from "./changeBilled99454";
import ReportHeader99453_4 from "./ReportHeader99453_4";
import ReportNeedsReviewBase, { ReviewAllCheckbox } from "./ReportNeedsReviewBase";


const { Option } = Select;

class ReportNeedsReview99453_4 extends ReportNeedsReviewBase {

  cptCode = "99453-4"

  headerClass = ReportHeader99453_4

  componentDidMount() {
    const { getlistClinicSites } = this.props;
    getlistClinicSites();
    if (this.state.siteId.length > 0) {
      this.setState({ modalIsOpen: true });
      const fmtSiteId = this.state.siteId.join("_");
      Promise.all([this.props.searchBilling99454Patients({ siteId: fmtSiteId }), this.props.searchBilling99453Patients({ siteId: fmtSiteId })]).then((values) => {
        this.setState({
          tableData: values.flat()
        });
      }).catch((e) => {
        console.log("searchBillingPatients error: ", e);
      }).finally(() => {
        this.setState({ modalIsOpen: false });
      });
    }
  }

  makeRequestSearchPatient() {
    this.setState({
      selectedPatients: []
    });

    this.setState({ modalIsOpen: true });
    const fmtSiteId = this.state.siteId.join("_");
    Promise.all([this.props.searchBilling99454Patients({ siteId: fmtSiteId }), this.props.searchBilling99453Patients({ siteId: fmtSiteId })]).then((values) => {
      this.setState({
        tableData: values.flat()
      });

      let currentPath = Control.path;
      if (currentPath.includes('?')) {
        currentPath = currentPath.split('?')[0]
      }
      Control.replace(currentPath + "?siteId=" + fmtSiteId);
    }).catch((e) => {
      console.log("searchBillingPatients error: ", e);
    }).finally(() => {
      this.setState({ modalIsOpen: false });
    });
  }

  handleGenerateReport = () => {
    if (this.state.selectedPatients.length === 0) {
      message.info(
        "Please select at least one patient record to download a report."
      );
      return;
    }

    if (
      this.state.selectedPatients.filter(
        i => this.state.tableData[i].billedType === "99454"
      ).length > 25
    ) {
      message.info("For best results, please reduce to 25 records or less.");
      return;
    }

    Modal.confirm({
      title: "Downloading Report",
      content:
        "To download the report, disable your pop-up blocker. It will take a few minutes for us to generate your report. You may continue using the Siren Dashboard and the download will start automatically when it is ready.",
      centered: true,
      onOk: async () => {
        await this.startDownloading();
      },
      onCancel: () => {}
    });
  };

  handleCheckAll = e => {
    const checked = e.target.checked;
    let nextSelected = [...this.state.selectedPatients];

    const { page, pageSize, sortedData } = this.tableInstance.state;
    const currentPageData = sortedData.slice(page * pageSize, (page + 1) * pageSize);
    if (checked) {
      nextSelected = _.union(nextSelected, currentPageData.filter(item => item.billedType === "99454").map(item => item._index))
      // if a 99454 record checked, auto check 99453 record
      const leftRecords = _.difference(this.state.tableData.map((_, i) => i), nextSelected)
      const left99453 = leftRecords.filter(i => {
        const record = this.state.tableData[i]
        return record.billedType === "99453"
      })

      // get selected 99454 sirenId
      const selected99454SirenIds = nextSelected.map(i => this.state.tableData[i]).filter(item => item.billedType === "99454").map(item => item.sirenId)
      // filter 99453 record if there is pared 99454
      const selected99453 = left99453.filter(index => selected99454SirenIds.includes(this.state.tableData[index].sirenId))
      // check 99453
      nextSelected = nextSelected.concat(selected99453)

      if (selected99454SirenIds.length > 25) {
        message.info("For best results, please reduce to 25 records or less.");
        return
      }

      this.setState({
        selectedPatients: nextSelected,
      });
    } else {
      // uncheck all records
      nextSelected = _.difference(nextSelected, currentPageData.map(item => item._index))

      // if a 99453 record has no paired 99454, remove it
      const filterRecords = nextSelected.filter(index => {
        const record = this.state.tableData[index];
        let remain = true;
        if (record.billedType === "99453") {
          const pair99454 = nextSelected.find(i2 => {
            const r2 = this.state.tableData[i2];
            return r2.billedType === "99454" && r2.sirenId === record.sirenId
          });
          if (!pair99454) {
            remain = false;
          }
        }

        return remain;
      })

      this.setState({
        selectedPatients: filterRecords,
      });
    }
  };

  handleCheck = (checked, index) => {
    let selectedPatients = [...this.state.selectedPatients];
    const selectedBillRecord = this.state.tableData[index];
    if (selectedBillRecord.billedType === "99453") {
      message.info("Please select a 99454 record.");
      return;
    }
    if (checked) {
      selectedPatients.push(index);
      // auto check 99453 record
      const possibleIndex = this.state.tableData.findIndex(
        p =>
          p.sirenId === selectedBillRecord.sirenId && p.billedType === "99453"
      );
      if (possibleIndex !== -1 && !selectedPatients.includes(possibleIndex)) {
        selectedPatients.push(possibleIndex);
      }
      if (selectedPatients.filter(i => this.state.tableData[i].billedType === "99454").length > 25) {
        message.info("For best results, please reduce to 25 records or less.");
        return
      }
    } else {
      selectedPatients = selectedPatients.filter(p => p !== index);
      // auto uncheck 99453 record
      const hasOther99454 = selectedPatients.find(i => {
        const p = this.state.tableData[i]
        return p.sirenId === selectedBillRecord.sirenId && p.billedType === "99454"
      });
      if (!hasOther99454) {
        const possibleIndex = this.state.tableData.findIndex(
          p =>
            p.sirenId === selectedBillRecord.sirenId && p.billedType === "99453"
        );
        if (possibleIndex !== -1) {
          selectedPatients = selectedPatients.filter(p => p !== possibleIndex);
        }
      }
    }

    this.setState({
      selectedPatients
    });
  }

  getSelectAll() {
    let selectAll = false;
    if (this.tableInstance) {
      selectAll = true;
      const { page, pageSize, sortedData } = this.tableInstance.state;
      const currentPageData = sortedData.slice(
        page * pageSize,
        (page + 1) * pageSize
      );
      const _99454Data = currentPageData.filter(item => item.billedType === "99454");
      if (_99454Data.length === 0) {
        selectAll = false;
      }
      _99454Data.forEach(row => {
        if (!this.state.selectedPatients.includes(row._index)) {
          selectAll = false;
        }
      });
    }

    return selectAll;
  }

  renderTableProps(selectAll) {
    const data = this.state.tableData &&
      this.state.tableData.map(p => {
        const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
        const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
        const initializedDate = p.initializedDate
          ? p.initializedDate.split("T")[0]
          : "";
        const appInitialized = p.appInitialized ? p.appInitialized : "No";
        const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
        let period;
        if (p.period99454Start) {
          if (p.period99454End) {
            period =
              moment(p.period99454Start).format("MM/DD/YYYY") +
              "~" +
              moment(p.period99454End).format("MM/DD/YYYY");
          } else {
            period = moment(p.period99454Start).format("MM/DD/YYYY");
          }
        } else {
          if (p.period99454End) {
            period = "~" + moment(p.period99454End).format("MM/DD/YYYY");
          } else {
            period = "N/A";
          }
        }
        let periods;
        if (p.period99454Start) {
          if (p.period99454End) {
            periods = p.period99454Start + "~" + p.period99454End;
          } else {
            periods = p.period99454Start;
          }
        } else {
          if (p.period99454End) {
            periods = "~" + p.period99454End;
          } else {
            periods = "N/A";
          }
        }
        let spentTime = 0;
        let doctor = "";
        if (p.rpmInfo && p.rpmInfo.length > 0) {
          for (var i in p.rpmInfo) {
            spentTime =
              spentTime + parseInt(p.rpmInfo[i].spentTime.split(" ")[0]);
          }
          doctor = p.rpmInfo[0].doctorName;
        }
        const diagnosisCodes = p.diagnosisCodes
          ? p.diagnosisCodes.replace(/_/g, ",")
          : "";
        return {
          ...p,
          createdAt,
          sirenId,
          initializedDate,
          dispensedDay,
          spentTime,
          doctor,
          period,
          periods,
          diagnosisCodes,
          appInitialized
        };
      })

    const columns = [
      {
        Header: props => {
          return (
            <Checkbox
              onChange={this.handleCheckAll}
              checked={selectAll}
            />
          );
        },
        accessor: "",
        maxWidth: 60,
        sortable: false,
        filterable: false,
        Cell: props => {
          return (
            <div onClick={e => e.stopPropagation()}>
              {props.original.billedType === "99453" ? (
                <Popover
                  content={
                    <div
                      style={{
                        maxWidth: 400,
                        maxHeight: 200,
                        overflowY: "auto",
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      CPT 99453 record is automatically selected with
                      patient's corresponding CPT 99454 record.
                    </div>
                  }
                  placement="right"
                >
                  <Checkbox
                    onChange={e =>
                      this.handleCheck(e.target.checked, props.index)
                    }
                    checked={this.state.selectedPatients.includes(
                      props.index
                    )}
                    name="selectSite"
                    disabled
                  />
                </Popover>
              ) : (
                <Checkbox
                  onChange={e =>
                    this.handleCheck(e.target.checked, props.index)
                  }
                  checked={this.state.selectedPatients.includes(
                    props.index
                  )}
                  name="selectSite"
                />
              )}
            </div>
          );
        }
      },
      {
        Header: "CPT",
        accessor: "billedType"
      },
      {
        Header: "SiteID",
        accessor: "siteId",
        maxWidth: 60
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        minWidth: 100
      },
      {
        Header: "Doctor",
        accessor: "doctorName",
        minWidth: 100
      },
      {
        Header: "SirenID",
        accessor: "sirenId",
        maxWidth: 60
      },
      {
        Header: "PatientID",
        accessor: "patientId"
      },
      {
        Header: "First Name",
        accessor: "firstName"
      },
      {
        Header: "Last Name",
        accessor: "lastName"
      },
      {
        Header: "Date of Birth",
        accessor: "birthday"
      },
      {
        Header: "ICD-10 Codes",
        accessor: "diagnosisCodes"
      },
      {
        Header: "Account Created",
        accessor: "createdAt"
      },
      // {
      //   Header: "App Activated",
      //   accessor: "appInitialized",
      //   maxWidth: 72,
      //   filterMethod: (filter, row) => {
      //     if (filter.value === "all") {
      //       return true;
      //     }
      //     if (filter.value === "Yes") {
      //       return row[filter.id] === "Yes";
      //     }
      //     return row[filter.id] === "No";
      //   },
      //   Filter: ({ filter, onChange }) => (
      //     <Select
      //       className="select_common_style"
      //       onChange={onChange}
      //       style={{ width: "100%" }}
      //       value={filter ? filter.value : "all"}
      //       filterOption={(input, option) =>
      //         option.props.children
      //           .toLowerCase()
      //           .indexOf(input.toLowerCase()) >= 0
      //       }
      //       showSearch
      //       optionFilterProp="children"
      //     >
      //       <Option value="all">All</Option>
      //       <Option value="Yes">Yes</Option>
      //       <Option value="No">No</Option>
      //     </Select>
      //   )
      // },
      {
        Header: "Socks Dispensed Date",
        accessor: "dispensedDay",
        minWidth: 120,
        Cell: props => {
          return <div>{props.original.dispensedDay}</div>;
        }
      },
      {
        Header: "Recording Period",
        accessor: "periods"
      },
      {
        Header: "Date of Service",
        accessor: "period99454End",
        Cell: props => props.value || "N/A"
      },
      {
        Header: "Days Recorded",
        accessor: "daysWorn"
      },
      {
        Header: "Reviewed",
        accessor: "billed99454",
        sortable: false,
        Filter: () => (
          <ReviewAllCheckbox
            patients={() => {
              let reviewPatients = [];
              if (this.tableInstance) {
                const { page, pageSize, sortedData } = this.tableInstance.state;
                reviewPatients = sortedData.slice(page * pageSize, (page + 1) * pageSize);
              }
              // if a 99453 record has no paired 99454, remove it
              const filterPatients = reviewPatients.filter(p => {
                let remain = true;
                if (p._original.billedType === "99453") {
                  const pair99454 = reviewPatients.find(p2 => {
                    return p2.billedType === "99454" && p2.sirenId === p.sirenId
                  });
                  if (!pair99454) {
                    remain = false;
                  }
                }

                return remain;
              })
              return filterPatients;
            }}
            reviewTask={async p => {
              const input = {
                id: p._original.id,
                userId: p._original.userId,
                siteId: p._original.siteId
              };
              if (p._original.billedType === "99453") {
                Object.assign(input, {
                  billDay: p._original.billDay,
                  billed99453By: this.props.username
                });
                await updateBilled99453Status({ input });
              } else {
                Object.assign(input, {
                  billingDate: p._original.billingDate,
                  billedBy: this.props.username,
                  billedStatus: "BILLED"
                });
                await updateBilled99454Status({ input });
              }
            }}
            onFinish={this.searchPatients}
          />
        ),
        Cell: props => {
          return (
            <div onClick={e => e.stopPropagation()}>
              <ChangeBilled99454
                userInfo={props.original}
                spentTime={props.original.spentTime}
                searchUsers={this.searchPatients}
                period={props.original.period}
                billDay={props.original.billDay}
              />
            </div>
          );
        }
      }
    ]

    return {
      data,
      columns
    }
  }

}

const mapStateToProp = state => ({
  username: state.authGroupStore.profile.loginId,
  clinicSites: state.patientsListStore.clinicSites,
  showGenerateDelay: state.monthlyReportStore.showGenerate99454Delay,
  generateDelay: state.monthlyReportStore.generate99454Delay,
  tableDefaultState: state.tableFilter.report99453_4NeedsReview
});

const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  searchBillingPatients: (input) =>
    dispatch(() => { }),
  searchBilling99453Patients: (billingInputValue) =>
    dispatch(searchBilling99453Patients(billingInputValue)),
  searchBilling99454Patients: (billingInputValue) =>
    dispatch(searchBilling99454Patients(billingInputValue)),
  generateBillingReport: (input) =>
    dispatch(generateBillingReport(input)),
  updateTableDefaultState: (cptCode, state) => dispatch(updateReportNeedsReviewTableFilter(cptCode, state))
});

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(ReportNeedsReview99453_4);
