import { Select } from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { Control } from "react-keeper";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Icon } from "semantic-ui-react";
import { getGroups } from "../action/patientAction";
import { getReportHistory } from "../action/reportAction";
import Progress from "../home/progress";
import "./ReportSite.css";
import ReportMenu from "./reportMenu";
Modal.setAppElement("div");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};

const { Option } = Select;
class ReportHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      siteId: "",
      reportDay: "",
      historyYear: "",
      historyMonth: "",
      historyDay: ""
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.searchReport = this.searchReport.bind(this);
  }
  onSelectChange(value) {
    this.setState({ siteId: value });
    const { getReportHistory } = this.props;
    const reportHistoryFilter = {
      siteId: value,
      reportDay: moment(
        this.state.historyYear +
          "-" +
          this.state.historyMonth +
          "-" +
          this.state.historyDay
      ).format()
    };
    getReportHistory(reportHistoryFilter, this);
    Control.go(
      "/ReportHistory?siteId=" +
        value +
        "&year=" +
        this.state.historyYear +
        "&month=" +
        this.state.historyMonth +
        "&day=" +
        this.state.historyDay
    );
  }
  handleYearChange = event => {
    this.setState({ historyYear: event.target.value });
    if (event.target.value.length === 4) {
      document.getElementById("historyYear").blur();
      document.getElementById("historyMonth").focus();
    }
  };
  handleMonthChange = event => {
    this.setState({ historyMonth: event.target.value });
    if (event.target.value.length === 2) {
      document.getElementById("historyMonth").blur();
      document.getElementById("historyDay").focus();
    }
  };
  handleDayChange = event => {
    this.setState({ historyDay: event.target.value });
    if (event.target.value.length === 2) {
      document.getElementById("historyDay").blur();
    }
  };
  searchReport() {
    const { getReportHistory } = this.props;
    const reportHistoryFilter = {
      siteId: this.state.siteId ? this.state.siteId : "",
      reportDay: moment(
        this.state.historyYear +
          "-" +
          this.state.historyMonth +
          "-" +
          this.state.historyDay
      ).format()
    };
    getReportHistory(reportHistoryFilter, this);
    Control.go(
      "/ReportHistory?siteId=" +
        this.state.siteId +
        "&year=" +
        this.state.historyYear +
        "&month=" +
        this.state.historyMonth +
        "&day=" +
        this.state.historyDay
    );
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const { getGroups, getReportHistory } = this.props;
    getGroups();
    const url = window.location.href.split("?")[1];
    let params = queryString.parse(url);
    const siteId = params.siteId ? params.siteId : "";
    const historyYear = params.year ? params.year : moment().year();
    const historyMonth = params.month ? params.month : moment().month() + 1;
    const historyDay = params.day ? params.day : moment().date() - 3;
    this.setState({
      siteId: siteId,
      historyYear: historyYear,
      historyMonth: historyMonth,
      historyDay: historyDay
    });
    const reportHistoryFilter = {
      siteId: siteId,
      reportDay: moment(
        historyYear + "-" + historyMonth + "-" + historyDay
      ).format()
    };
    getReportHistory(reportHistoryFilter, this);
  }
  render() {
    return (
      <div className="boxlistcontainer">
        <ReportMenu />
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="search_patient_container">
          <span className="search_label">SiteID:</span>
          <Select
            onChange={this.onSelectChange}
            className="bli_Select"
            showSearch
            placeholder="Select SiteId"
            value={this.state.siteId}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.props.filterGroup &&
              this.props.filterGroup.map((group, i) => {
                return (
                  <Option value={group} key={"group" + i}>
                    {group}
                  </Option>
                );
              })}
          </Select>
          <span className="search_label search_time_label">Year:</span>
          <div className="ui input search_time_Box">
            <input
              type="text"
              placeholder="Year"
              onChange={this.handleYearChange}
              id="historyYear"
              maxLength="4"
              value={this.state.historyYear}
            />
          </div>
          <span className="search_label search_time_label">Month:</span>
          <div className="ui input search_time_Box">
            <input
              type="text"
              placeholder="Month"
              onChange={this.handleMonthChange}
              id="historyMonth"
              maxLength="2"
              value={this.state.historyMonth}
            />
          </div>
          <span className="search_label search_time_label">Day:</span>
          <div className="ui input search_time_Box">
            <input
              type="text"
              placeholder="Day"
              onChange={this.handleDayChange}
              id="historyDay"
              maxLength="2"
              value={this.state.historyDay}
            />
          </div>
          <Icon
            name="search"
            className="billing_search_icon"
            onClick={this.searchReport}
          />
        </div>
        <ReactTable
          data={
            this.props.doctorReportHistory &&
            this.props.doctorReportHistory.map(reportHistory => {
              const reportDay = reportHistory.reportDay
                ? reportHistory.reportDay.split("T")[0]
                : "";
              let sendTo = reportHistory.to.split(",");
              if (sendTo) {
                sendTo = sendTo.map(list => {
                  return <div key={`to-${list}`}>{list}</div>;
                });
              }
              return {
                ...reportHistory,
                reportDay,
                sendTo
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              columns: [
                {
                  Header: "Site Id",
                  accessor: "siteId",
                  maxWidth: 100,
                  filterMethod: (filter, row) =>
                    String(row[filter.id]).includes(filter.value)
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Report Day",
                  accessor: "reportDay",
                  maxWidth: 200,
                  filterMethod: (filter, row) =>
                    String(row[filter.id]).includes(filter.value)
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Site Name",
                  accessor: "siteName",
                  filterMethod: (filter, row) => {
                    let rowfilterfullname = row._original.siteName
                      ? row[filter.id].toLowerCase()
                      : "";
                    let filterfullname = filter.value.toLowerCase();
                    return rowfilterfullname.includes(filterfullname);
                  }
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Send To",
                  accessor: "sendTo",
                  filterMethod: (filter, row) => {
                    for (let i = 0; i < row[filter.id].length; i++) {
                      if (
                        String(row[filter.id][i].key)
                          .split("to-")[1]
                          .includes(filter.value)
                      ) {
                        return true;
                      }
                    }
                  }
                }
              ]
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "reportDay",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  doctorReportHistory: state.reportListStore.doctorReportHistory,
  filterGroup: state.patientsListStore.filterGroup
});
const mapDispatchToProp = dispatch => ({
  getGroups: () => dispatch(getGroups()),
  getReportHistory: (reportHistoryFilter, self) =>
    dispatch(getReportHistory(reportHistoryFilter, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(ReportHistory);
