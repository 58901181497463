import React, { Component } from "react";
import { Select, Row, Col, Input, Modal } from "antd";
import { FOOT, SYMPTOMS, SYMPTOMS_AREA } from "../tools/patient-medical";
import _ from "lodash";
import { CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

export default class FootSymptomItem extends Component {
  state = {
    data: {
      ...this.props.data,
      footArea: _.isEmpty(this.props.data.footArea)
        ? []
        : this.props.data.footArea.split(",")
    },
    otherSymptom: null
  };

  onFormComplete = () => {
    if (
      !this.state.data.footSymptom ||
      (this.state.data.footSymptom === "Other" && !this.state.otherSymptom)
    ) {
      return;
    }
    if (this.state.data.footSymptom !== "No new symptoms") {
      if (!this.state.data.foot) {
        return;
      }
      if (this.state.data.footArea.length === 0) {
        return;
      }
    }
    if (typeof this.props.saveAction === "function") {
      this.props.saveAction({
        ...this.state.data,
        footSymptom:
          this.state.data.footSymptom === "Other"
            ? this.state.otherSymptom
            : this.state.data.footSymptom,
        footArea:
          this.state.data.footSymptom === "No new symptoms"
            ? ""
            : this.state.data.footArea.join(","),
        foot:
          this.state.data.footSymptom === "No new symptoms"
            ? ""
            : this.state.data.foot
      });
    }
  };

  handleSelect1 = value => {
    this.setState(
      {
        data: { ...this.state.data, footSymptom: value, detail: "" }
      },
      this.onFormComplete
    );
  };

  handleSelect2 = value => {
    this.setState(
      {
        data: { ...this.state.data, foot: value }
      },
      this.onFormComplete
    );
  };

  handleSelect3 = value => {
    if (value.length === 0) {
      Modal.warning({
        title: "Save Symptoms Failed",
        content: "Please select a area.",
        centered: true
      });
      return;
    }
    this.setState(
      {
        data: { ...this.state.data, footArea: value }
      },
      this.onFormComplete
    );
  };

  onOtherSymptomBlur = event => {
    this.setState(
      {
        data: { ...this.state.data, footSymptom: this.state.otherSymptom },
        otherSymptom: ""
      },
      this.onFormComplete
    );
  };

  renderReadonly() {
    return (
      <Row gutter={[16, 16]}>
        <Col span={this.props.data.detail ? 6 : 8}>
          <Input readOnly value={this.props.data.footSymptom} />
        </Col>
        {this.props.data.detail && (
          <Col span={6}>
            <Input readOnly value={this.props.data.detail} />
          </Col>
        )}
        <Col span={4}>
          <Input readOnly value={this.props.data.foot} />
        </Col>
        <Col span={8}>
          <Select
            mode="multiple"
            disabled
            value={this.state.data.footArea}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    );
  }

  render() {
    if (this.props.readOnly) {
      return this.renderReadonly();
    }
    const isNoSymptom = this.state.data.footSymptom === "No new symptoms";
    let subOptions = null;
    if (this.state.data.footSymptom) {
      const tmp = SYMPTOMS.find(s => s.name === this.state.data.footSymptom);
      if (tmp) {
        subOptions = tmp.options;
      }
    }
    return (
      <Row gutter={[16, 16]}>
        <Col span={subOptions ? 6 : 8}>
          <Select
            value={this.state.data.footSymptom}
            onChange={this.handleSelect1}
            style={{ width: "100%" }}
            placeholder={"Select a symptom"}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            optionFilterProp="children"
          >
            {SYMPTOMS.map(p => p.name).map((p, index) => (
              <Option value={p} key={index}>
                {p}
              </Option>
            ))}
          </Select>
          {this.state.data.footSymptom === "Other" && (
            <Input
              style={{ marginTop: 12 }}
              value={this.state.otherSymptom}
              placeholder="Other Symptom"
              onChange={e => this.setState({ otherSymptom: e.target.value })}
              onBlur={this.onOtherSymptomBlur}
              onPressEnter={this.onOtherSymptomBlur}
            />
          )}
        </Col>
        {subOptions && (
          <Col span={6}>
            <Select
              value={
                _.isEmpty(this.state.data.detail)
                  ? undefined
                  : this.state.data.detail
              }
              placeholder="Optional detail"
              style={{ width: "100%" }}
              onChange={value =>
                this.setState(
                  {
                    data: { ...this.state.data, detail: value }
                  },
                  this.onFormComplete
                )
              }
            >
              {subOptions.map(o => (
                <Option value={o} key={o}>
                  {o}
                </Option>
              ))}
            </Select>
          </Col>
        )}
        <Col span={4}>
          <Select
            value={
              isNoSymptom
                ? ""
                : _.isEmpty(this.state.data.foot)
                ? undefined
                : this.state.data.foot
            }
            onChange={this.handleSelect2}
            style={{ width: "100%" }}
            placeholder={isNoSymptom ? "" : "Select foot"}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            optionFilterProp="children"
            disabled={isNoSymptom}
          >
            {FOOT.map((p, index) => (
              <Option value={p} key={index}>
                {p}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Select
            mode="multiple"
            value={isNoSymptom ? [] : this.state.data.footArea}
            onChange={this.handleSelect3}
            style={{ width: "100%" }}
            placeholder={isNoSymptom ? "" : "Select area"}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            optionFilterProp="children"
            disabled={isNoSymptom}
          >
            {SYMPTOMS_AREA.map((p, index) => (
              <Option value={p} key={index}>
                {p}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <CloseOutlined
            onClick={() => {
              if (typeof this.props.deleteAction === "function") {
                this.props.deleteAction();
              }
            }}
            style={{ marginTop: 8 }}
          />
        </Col>
      </Row>
    );
  }
}
