import { Modal as AntdModal, notification, Select } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Button, Icon, Popup } from "semantic-ui-react";
import {
  changeStaffStatus,
  getlistStaff,
  updateListStaff
} from "../action/registerAction";
import getPatchTrProps, {
  InputEnterKeyTriggerFilterComponent
} from "../tools/reactTablePatch";
import AddStaff from "./AddStaff";
import EditStaff from "./EditStaff";
import ResetStaffPwd from "./ResetStaffPwd";
import "../SupportDashboard/account/SiteList.css";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import Modal from "react-modal";
import { downloadCSV } from "../tools/downloadFile";
import _ from "lodash";
import { getStaffList } from "../graphql/API";

const { Option } = Select;
const { confirm } = AntdModal;

class ActiveStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      listData: []
    };
    this.tableInstance = null;
    this.debounceRequest = false;
    this.nextToken = null;
  }
  componentDidMount() {
    // this.setState({ modalIsOpen: true });
    // this.props.getlistStaff().finally(() => {
    //   this.setState({ modalIsOpen: false });
    // });
  }
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleTempDeleteStaff = email => {
    const input = {
      email,
      status: "DELETED"
    };
    this.props.changeStaffStatus(input, this);
  };

  confirmDelete = (email, fullName) => {
    confirm({
      title: "Confirming",
      content: (
        <div>
          Are you sure you want to delete the staff <strong>{fullName}</strong>?
        </div>
      ),
      cancelText: "No",
      onCancel: () => {},
      okText: "Yes",
      onOk: () => {
        this.handleTempDeleteStaff(email);
      },
      centered: true
    });
  };

  downloadClinicStaff = () => {
    if (this.props.listStaff.length === 0) {
      AntdModal.warning({
        title: "Oops...",
        content: "There are no clinic staff.",
        centered: true
      });
      return;
    }
    try {
      const data = this.props.listStaff.map(p => {
        const fullName =
          p.isPrescribing && !p.firstName.includes("Dr.")
            ? "Dr. " + p.firstName + " " + p.lastName
            : p.firstName + " " + p.lastName;
        const isPrescribing =
          p.isPrescribing === true
            ? "Yes"
            : p.isPrescribing === false
            ? "No"
            : "";
        const notificationEmail = p.notificationEmail
          ? p.notificationEmail
          : p.email;
        const isAdministrator =
          p.isAdministrator && p.isAdministrator === "Yes" ? "Yes" : "No";
        const lastLogin = p.lastLogin
          ? moment(p.lastLogin).format("MM/DD/YYYY HH:mm:ss")
          : "";
        const createdAt = p.createdAt
          ? moment(p.createdAt).format("MM/DD/YYYY HH:mm:ss")
          : "";
        const doctorNPI = p.doctorNPI ? p.doctorNPI : "";
        return `"${p.siteId}\t",${p.email},${notificationEmail},"${fullName}",${isPrescribing},${doctorNPI},${p.doctorRole},"${isAdministrator}",${createdAt},${lastLogin}`;
      });
      data.unshift(
        "Site Id,User Name,Notification Email,Full Name,Prescribing Doctor,NPI,Role,Administrator,Created On,Last Login"
      );

      downloadCSV(
        data.join("\n"),
        `clinic-staffs-${moment().format("YYYYMMDD")}.csv`
      );
    } catch (e) {
      console.log("download staff csv error: ", e);
    }
  };

  getRequestFilter = () => {
    let filter = this.tableInstance.state.filtered.reduce((result, item) => {
      // remove empty condition
      item.value = item.value.trim();
      if (!item.value) {
        return result;
      }
      switch (item.id) {
        case "lastlogin":
        case "createdAt": {
          result[item.id] = {
            beginsWith: item.value
          };
          break;
        }
        case "siteId": {
          result[item.id] = {
            contains: item.value
          };
          break;
        }
        case "fullName": {
          result["s_fullName"] = {
            contains: item.value.toLowerCase()
          };
          break;
        }
        case "doctorNPI": {
          result[item.id] = {
            contains: item.value
          };
          break;
        }
        case "notificationEmail": {
          if (!result.and) {
            result.and = [];
          }
          result.and.push({
            or: [
              {
                notificationEmail: {
                  contains: item.value
                }
              },
              {
                s_email: {
                  contains: item.value.toLowerCase()
                }
              }
            ]
          });
          break;
        }
        case "email": {
          result["s_email"] = {
            contains: item.value.toLowerCase()
          };
          break;
        }
        case "isPrescribing": {
          if (item.value === "all") {
            // NOPE
          } else {
            result[item.id] = {
              eq: item.value === "Yes"
            };
          }
          break;
        }
        case "isAdministrator": {
          result[item.id] = {
            eq: item.value
          };
          break;
        }
        default:
          result[item.id] = {
            eq: item.value
          };
          break;
      }
      return result;
    }, {});

    if (_.isEmpty(filter)) {
      filter = null;
    }

    const combFilter = Object.assign({ status: { ne: "DELETED" } }, filter);

    return combFilter;
  };

  reqPatientList = () => {
    this.debounceReqPatientList();
    if (!this.debounceRequest) {
      this.debounceReqPatientList.flush();
    }
  };

  debounceReqPatientList = _.debounce(() => {
    let filter = this.getRequestFilter();
    // auto request next page data, until data count is over limit or no more data
    const now = Date.now(),
      timeout = 30000;
    let dataCount = 0;

    const dummyRequest = () => {
      this.rawReqPatientList(
        filter,
        this.tableInstance.state.pageSize,
        this.nextToken
      ).then(data => {
        if (Date.now() - now > timeout) {
          return;
        }
        dataCount += data.items.length;
        if (dataCount < this.tableInstance.state.pageSize && data.nextToken) {
          dummyRequest();
        }
      });
    };

    dummyRequest();
  }, 3000);

  rawReqPatientList(filter, limit, nextToken) {
    this.debounceRequest = false;
    this.setState({
      loading: true
    });
    return getStaffList(filter, limit, nextToken)
      .then(data => {
        let listData = [];
        if (this.nextToken === null) {
          listData = data.items;
        } else {
          listData = this.state.listData.concat(data.items);
        }
        this.setState({ listData });
        this.props.updateListStaff(listData);
        this.nextToken = data.nextToken;

        return data;
      })
      .catch(error => {
        notification.error({
          message: "System Error",
          description: JSON.stringify(error),
          duration: 0
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  render() {
    const getPaginationProps = () => {
      return {
        canPrevious: !!this.nextToken,
        canNext: !!this.nextToken
      };
    };
    const isAdminOrDevelper =
      this.props.userGroups.includes("admin") ||
      this.props.userGroups.includes("developer") ||
      this.props.userGroups.includes("support");
    const trProps = getPatchTrProps(({ event }) => {
      //WARNING: black magic
      const buttons = event.currentTarget.getElementsByTagName("button");
      for (const button of buttons) {
        if (button.innerHTML === "Edit") {
          button.click();
          break;
        }
      }
    });
    return (
      <div id="StaffList">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div style={{ display: "flex" }}>
          <div
            className="add_import_box"
            style={{ textAlign: "left", flex: 0.5 }}
          >
            <AddStaff />
          </div>
          <div
            className="add_import_box"
            style={{ textAlign: "right", flex: 0.5 }}
          >
            <Button size="small" onClick={this.downloadClinicStaff}>
              <Icon name="download" />
              Download CSV
            </Button>
          </div>
        </div>
        <ReactTable
          className="-striped -highlight"
          getTrProps={isAdminOrDevelper ? trProps : undefined}
          filterable
          loading={this.state.loading}
          sortable={false}
          data={
            this.props.listStaff &&
            this.props.listStaff.map(p => {
              const fullName =
                p.isPrescribing && !p.firstName.includes("Dr.")
                  ? "Dr. " + p.firstName + " " + p.lastName
                  : p.firstName + " " + p.lastName;
              const isPrescribing =
                p.isPrescribing === true
                  ? "Yes"
                  : p.isPrescribing === false
                  ? "No"
                  : "";
              const notificationEmail = p.notificationEmail
                ? p.notificationEmail
                : p.email;
              const isAdministrator =
                p.isAdministrator && p.isAdministrator === "Yes" ? "Yes" : "No";
              const lastLogin = p.lastLogin
                ? moment(p.lastLogin).format("YYYY-MM-DD HH:mm:ss")
                : "";
              const createdAt = p.createdAt
                ? moment(p.createdAt).format("YYYY-MM-DD HH:mm:ss")
                : "";
              return {
                ...p,
                fullName,
                notificationEmail,
                isPrescribing,
                isAdministrator,
                lastLogin,
                createdAt
              };
            })
          }
          // defaultFilterMethod={(filter, row) => {
          //   if (!row[filter.id]) {
          //     return false;
          //   }
          //   return row[filter.id]
          //       .toString()
          //       .toLowerCase()
          //       .includes(filter.value.toLowerCase());
          // }}
          FilterComponent={InputEnterKeyTriggerFilterComponent({
            onChange: () => {
              this.nextToken = null;
              this.debounceRequest = true;
            },
            onPressEnter: () => {
              this.nextToken = null;
              this.debounceRequest = false;
            }
          })}
          manual
          showPageJump={false}
          getPaginationProps={getPaginationProps}
          nextText="Load More"
          onPageSizeChange={pageSize => {
            // fetch data from first page
            this.nextToken = null;
          }}
          pages={100}
          onFetchData={(_, instance) => {
            // there are some other callbacks, like onPageChange onPageSizeChange etc,
            // we don't care them. this callback is enough
            this.tableInstance = instance;

            this.reqPatientList();
          }}
          columns={[
            {
              Header: "Site Id",
              accessor: "siteId",
              Cell: props => (
                <Popup
                  trigger={<p className="sr-text-ellipsis">{props.value}</p>}
                >
                  {props.value.split("-").map((name, index) => (
                    <p key={index}>{name}</p>
                  ))}
                </Popup>
              )
            },
            {
              Header: "User Name",
              accessor: "email"
            },
            {
              Header: "Notification Email",
              accessor: "notificationEmail"
            },
            {
              Header: "Full Name",
              accessor: "fullName",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.fullName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Prescribing Doctor",
              accessor: "isPrescribing",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Yes") {
                  return row[filter.id].includes("Yes");
                }
                if (filter.value === "No") {
                  return row[filter.id] === "No";
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                  onSelect={() => {
                    this.nextToken = null;
                    this.debounceRequest = false;
                  }}
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "NPI",
              accessor: "doctorNPI",
              sortable: false
            },
            {
              Header: "Role",
              accessor: "doctorRole",
              sortable: false,
              filterable: false
            },
            {
              Header: "Administrator",
              accessor: "isAdministrator",
              sortable: false
            },
            {
              Header: "Created On",
              accessor: "createdAt"
            },
            {
              Header: "Last Login",
              accessor: "lastLogin"
            },
            {
              Header: "Actions",
              accessor: "",
              sortable: false,
              minWidth: 250,
              filterable: false,
              show: isAdminOrDevelper,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <Button
                      size="mini"
                      className="deleteButton"
                      onClick={() => {
                        this.confirmDelete(
                          props.original.email,
                          props.original.fullName
                        );
                      }}
                    >
                      Delete
                    </Button>
                    <EditStaff newStaff={props.original} />
                    <ResetStaffPwd id={props.original.email} />
                  </div>
                );
              }
            }
          ]}
          minRows={0}
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  listStaff: state.patientsListStore.listStaff,
  deletedListStaff: state.patientsListStore.deletedListStaff,
  userGroups: state.authGroupStore.session.userGroups
});
const mapDispatchToProp = dispatch => ({
  getlistStaff: () => dispatch(getlistStaff()),
  updateListStaff: listStaff => dispatch(updateListStaff(listStaff)),
  changeStaffStatus: (input, self) => dispatch(changeStaffStatus(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(ActiveStaff);
