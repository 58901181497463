import { Collapse } from "antd";
import React from 'react';
import App_Icon from "../../../img/app_loc.png";
import { formatNumber } from "../../../tools/phoneFormat";
import NewAccountStep from "./NewAccountStep";

const CREATE_SUCCESS = require("../../../img/create_success.png");
const LOGIN_APP = require("../../../img/login_app.png");
const NEW_PATIENT = require("../../../img/new_patient.png");


const { Panel } = Collapse;

export default class NewAccountStep6 extends NewAccountStep {

	static Name = 'Complete'

	state = {
		collapse: []
	}

	changeCollapse = event => {
    this.setState({ collapse: event });
  }

	render() {
		return (
			<div style={{width: 800, margin: 'auto', marginTop: 40}}>
				<div style={{textAlign: 'center', marginBottom: 20}}>
					<h2><b>New Patient Setup Complete</b></h2>
					<img
						src={CREATE_SUCCESS}
						style={{ width: 100, height: 100, marginTop: 20, marginBottom: 20 }}
					/>
					<h3><strong>Patient account has been successfully created.</strong></h3>
				</div>
				<Collapse
					style={{display: 'none'}}
					expandIconPosition={"right"}
					onChange={this.changeCollapse}
				>
					<Panel
						header={
							<>
								<p className="collapse_panel_title">Siren Mobile App Setup</p>
								<div className="collapse_panel_seRow">
									<strong style={{marginRight: 8}}>Username:</strong>
									{formatNumber(this.props.phone.replace(/[\(\)\-\s]+/g, ''))}
									<strong style={{marginLeft: 100, marginRight: 8}}>
										Temporary Password:
									</strong>
									Sirencare
								</div>
								<p
									style={{
										marginBottom: 20,
										display: this.state.collapse.includes("1") ? 'none' : 'block'
									}}
								>
									<b><i>Click here for steps to download and log into the Siren App.</i></b>
								</p>
							</>
						}
						key="1"
					>
						<div
							style={{ marginBottom: 20 }}
						>
							<p className="collapse_panel_dis">
								Follow the steps below to download the Siren App. 
							</p>
							<p className="collapse_panel_dis">
								The Siren App allows patients to monitor their daily foot status
								and temperature alerts. Patients can use the Siren App to upload
								photos and receive messages from their doctor.
							</p>
							<div style={{width: '70%', float: 'left'}}>
								<p>
									<b><i>Step 1: Scan the QR Code</i></b>
									<br/>
									Open the phone’s camera to scan the QR code. This will
									direct you to the app store.
								</p>
								<img src={App_Icon} style={{width: 100, margin: 20}} />
								<p style={{ marginTop: 30 }}>
									<b><i>Step 2: Download the Siren app</i></b>
									<br/>
									Install the app on the patient’s phone.
								</p>
								<p style={{ marginTop: 30 }}>
									<b><i>Step 3: Log in to the app</i></b>
									<br/>
									Enter the credentials above and create a new password.
								</p>
							</div>
							<img
								src={LOGIN_APP}
								alt=""
								style={{
									width: 184,
									height: 400,
									float: 'right',
									border: '1px solid #ddd'
								}}
							/>
							<div style={{ clear: "both" }} />
						</div>
					</Panel>
					<Panel
						header={
							<>
								<p className="collapse_panel_title">Patient Reminders</p>
								<div className="collapse_panel_seRow">
									<strong>
										Action: Save Siren’s Support number: (415) 769 - 3987
									</strong>
								</div>
								<p
									style={{
										marginBottom: 20,
										display: this.state.collapse.includes("2") ? 'none' : 'block'
									}}
								>
									<b><i>Click here for a quick summary of patient reminders.</i></b>
								</p>
							</>
						}
						key="2"
					>
						<div style={{ marginBottom: 20 }} >
							<p>
								The Siren Socks and Foot Monitoring System is designed to fit
								seamlessly into patient’s everyday life. 
							</p>
							<div>
								<ul>
									<li className="step_li_new">
										Siren Socks should be worn and changed daily
									</li>
									<li className="step_li_new">
										Care Instructions: Machine Wash on Cold and Air Dry
									</li>
									<li className="step_li_new">
										The Siren App is a companion platform that allows patients
										to monitor their daily foot health
									</li>
									<li className="step_li_new">
										Make sure to setup the Siren Hub to ensure all patient data
										is uploaded automatically
									</li>
								</ul>
							</div>
						</div>
					</Panel>
					<Panel
						header={
							<>
								<p className="collapse_panel_title">Manage New Patients</p>
								<div style={{ marginBottom: 20 }} >
									All new patients can be found in
									<strong> Account Activation Status </strong>
									under the <strong>New Patient</strong> dropdown.
								</div>
								<p
									style={{
										marginBottom: 20,
										display: this.state.collapse.includes("3") ? 'none' : 'block'
									}}
								>
									<b><i>Click here for instructions on where to find new patients.</i></b>
								</p>
							</>
						}
						key="3"
					>
						<div
							style={{ marginBottom: 20 }}
						>
							<img
								src={NEW_PATIENT}
								alt=""
								style={{ width: '100%', marginTop: 20 }}
								className="img_border"
							/>
						</div>
					</Panel>
				</Collapse>
			</div>
		)
	}

}