import { API, graphqlOperation } from "aws-amplify";
import moment from "moment";
import zlib from "react-zlib-js";
import Buffer from "react-zlib-js/buffer";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
import { changeLoadingChartsState } from "./patientAction";

export function changeRenderDate(self, id, userId, dateRender) {
  return async dispatch => {
    dispatch(changeLoadingChartsState(true));
    let time1 = dateRender[0] !== null ? dateRender[0] : undefined;
    let time2 = dateRender[1] !== null ? dateRender[1] : undefined;
    let rangetime1 = moment(time1).format("YYYY-MM-DD");
    let rangetime2 = moment(time2)
      .add(1, "days")
      .format("YYYY-MM-DD");
    let dateTime = {
      id,
      userId: userId,
      startDate: rangetime1,
      endDate: rangetime2
    };
    let DELAY = moment(rangetime2) - moment(rangetime1);

    dispatch(changeSockChartData({
      leftFootTemp: [],
      rightFootTemp: [],
      leftFootPosition: [],
      rightFootPosition: [],
      delta: [],
      steps: [],
    }))

    if (self.state.showDiff) {
      dispatch(changeDeltaStatusWashed([]))
      dispatch(changeLeftFootTempStatusWashed([]));
      dispatch(changeRightFootTempStatusWashed([]));
      dispatch(changeLeftFootTempStatusMarked([]));
      dispatch(changeRightFootTempStatusMarked([]));
    }
    const isMin1 = DELAY <= self.DELAY;
    try {
      const newEvent = await API.graphql(
        graphqlOperation(
          !isMin1 ? _api.listPatientMin30() : (self.state.showDiff ? _api.listPatientMin1Diff() : _api.listPatientMin1()),
          dateTime
        )
      );

      const [leftFootData, rightFootData] = parseMinRawData(isMin1 ? newEvent.data.getPatient.min1raw : newEvent.data.getPatient.min30raw, isMin1, rangetime1, rangetime2);

      const deltaData = parsePairMinData(isMin1 ? newEvent.data.getPatient.min1 : newEvent.data.getPatient.min30, isMin1, rangetime1, rangetime2);

      const deltaOverflow = deltaData.find(d => {
        if (!d.dtoe) {
          return false
        }
        return Math.abs(d.dtoe) > 6 || Math.abs(d.dmts1) > 6 || Math.abs(d.dmts3) > 6 || Math.abs(d.dmts5) > 6 || Math.abs(d.darch) > 6 || Math.abs(d.dheel) > 6
      })

      // merge steps
      const steps = deltaData.map((d, index) => {
        let item = {
          usertime: d.usertime,
          steps: d.steps
        }
        let leftStep = leftFootData[index].steps
        if (leftStep) {
          item.steps = leftStep
        }
        let rightStep = rightFootData[index].steps
        if (rightStep) {
          item.steps = rightStep
        }
        if (item.steps && item.steps > 160) {
          if ((isMin1 && item.steps > 160) || (!isMin1 && item.steps > 1500)) {
            item.steps = 0
          }
        }

        return item
      })

      dispatch(changeSockChartData({
        'min1_or_30': isMin1 ? 'min1' : 'min30',
        leftFootTemp: leftFootData.map(d => ({
          usertime: d.usertime,
          toe: d.toe,
          mts1: d.mts1,
          mts3: d.mts3,
          mts5: d.mts5,
          arch: d.arch,
          heel: d.heel,
          source: d.source,
          mac: d.mac,
          lag: d.lag
        })),
        rightFootTemp: rightFootData.map(d => ({
          usertime: d.usertime,
          toe: d.toe,
          mts1: d.mts1,
          mts3: d.mts3,
          mts5: d.mts5,
          arch: d.arch,
          heel: d.heel,
          source: d.source,
          mac: d.mac,
          lag: d.lag
        })),
        leftFootPosition: leftFootData.map(d => ({
          usertime: d.usertime,
          gsensorx: d.gsensorx,
          gsensory: d.gsensory,
          gsensorz: d.gsensorz
        })),
        rightFootPosition: rightFootData.map(d => ({
          usertime: d.usertime,
          gsensorx: d.gsensorx,
          gsensory: d.gsensory,
          gsensorz: d.gsensorz
        })),
        delta: deltaData.map(d => ({
          usertime: d.usertime,
          dtoe: d.dtoe,
          dmts1: d.dmts1,
          dmts3: d.dmts3,
          dmts5: d.dmts5,
          darch: d.darch,
          dheel: d.dheel
        })),
        deltaAuto: !!deltaOverflow,
        steps,
      }))

      if (self.state.showDiff) {
        processMin1RawWashedData(newEvent.data.getPatient.min1rawWashed, dispatch, rangetime1)
        dispatch(changeLeftFootTempStatusMarked(newEvent.data.getPatient.min1rawMarked.filter(e => e.foot === 'left')));
        dispatch(changeRightFootTempStatusMarked(newEvent.data.getPatient.min1rawMarked.filter(e => e.foot === 'right')));

        const deltaDataWashed = parsePairMinDataWashed(newEvent.data.getPatient.min1Washed, isMin1, rangetime1, rangetime2);

        dispatch(changeDeltaStatusWashed(deltaDataWashed.map(d => ({
          usertime: d.usertime,
          dtoe: d.dtoe,
          dmts1: d.dmts1,
          dmts3: d.dmts3,
          dmts5: d.dmts5,
          darch: d.darch,
          dheel: d.dheel
        }))));

        const deltaWashedOverflow = deltaDataWashed.find(d => {
          if (!d.dtoe) {
            return false
          }
          return Math.abs(d.dtoe) > 6 || Math.abs(d.dmts1) > 6 || Math.abs(d.dmts3) > 6 || Math.abs(d.dmts5) > 6 || Math.abs(d.darch) > 6 || Math.abs(d.dheel) > 6
        })
        dispatch(changeDeltaAutoStatusWashed(!!deltaWashedOverflow));
      }

      dispatch(changeLoadingChartsState(false));
    } catch (err) {
      dispatch(changeLoadingChartsState(false));
      console.log(
        DELAY > self.DELAY ? "listPatientMin30" : "listPatientMin1 error: ",
        err
      );
    }
  };
}

function parsePairMinData(rawData, isMin1, startDateStr, endDateStr) {
  const rawDataStr = zlib
    .inflateSync(new Buffer(JSON.parse(rawData), "base64"))
    .toString();

  const itemKeys = ["usertime", "ltoe", "lmts1", "lmts3", "lmts5", "larch", "lheel", "lgsensorx", "lgsensory", "lgsensorz",
    "rtoe", "rmts1", "rmts3", "rmts5", "rarch", "rheel", "rgsensorx", "rgsensory", "rgsensorz", "steps"]

  const parsedData = rawDataStr.split('|').map(itemStr => {
    const rawItem = itemStr.split(',').reduce((sum, value, index) => {
      sum[itemKeys[index]] = index == 0 ? value : parseFloat(value);
      return sum;
    }, {});

    // 'yyyy-MM-ddTHH:mm:ss'
    if (rawItem.usertime) {
      rawItem.usertime = rawItem.usertime.substring(0, 19);
    }

    return rawItem;
  }).map(item => ({
    usertime: item.usertime,
    dtoe: Number.prototype.toFixed.call(item.ltoe - item.rtoe, 2),
    dmts1: Number.prototype.toFixed.call(item.lmts1 - item.rmts1, 2),
    dmts3: Number.prototype.toFixed.call(item.lmts3 - item.rmts3, 2),
    dmts5: Number.prototype.toFixed.call(item.lmts5 - item.rmts5, 2),
    darch: Number.prototype.toFixed.call(item.larch - item.rarch, 2),
    dheel: Number.prototype.toFixed.call(item.lheel - item.rheel, 2),
    steps: item.steps
  }));

  // fulfill the time gap.
  const startDate = moment(startDateStr);
  const days = isMin1 ? 1 : moment(endDateStr).diff(startDate, 'days');
  const gap = isMin1 ? 1 : 30;

  let emptyTimeGaps = Array(24 * 60 * days / gap).fill(0).map((_, i) => ({
    usertime: startDate.clone().add(i * gap, 'minutes').format('YYYY-MM-DDTHH:mm:00')
  }))

  parsedData.forEach(item => {
    const index = moment(item.usertime).diff(startDate, 'minutes') / gap
    emptyTimeGaps[index] = item;
  })

  return emptyTimeGaps;
}

function parseMinRawData(rawData, isMin1, startDateStr, endDateStr) {
  const rawDataStr = zlib
    .inflateSync(new Buffer(JSON.parse(rawData), "base64"))
    .toString();

  const itemKeys = ["usertime", "foot", "toe", "mts1", "mts3", "mts5", "arch", "heel", "gsensorx", "gsensory", "gsensorz", "steps"];
  if (isMin1) {
    itemKeys.push("source", "mac", "lag");
  }

  const parsedData = rawDataStr.split('|').map(itemStr => {
    const rawItem = itemStr.split(',').reduce((sum, value, index) => {
      sum[itemKeys[index]] = value;
      return sum;
    }, {});

    // 'YYYY-MM-DDTHH:mm:ss'
    if (rawItem.usertime.endsWith('Z')) {
      rawItem.usertime = rawItem.usertime.substring(0, rawItem.usertime.length - 1);
    }

    return rawItem;
  });

  // fulfill the time gap.
  const startDate = moment(startDateStr);
  const days = isMin1 ? 1 : moment(endDateStr).diff(startDate, 'days');
  const gap = isMin1 ? 1 : 30;

  let emptyLeftTimeGaps = Array(24 * 60 * days / gap).fill(0).map((_, i) => ({
    usertime: startDate.clone().add(i * gap, 'minutes').format('YYYY-MM-DDTHH:mm:00')
  }))
  let emptyRightTimeGaps = Array.from(emptyLeftTimeGaps)
  parsedData.forEach(item => {
    const index = moment(item.usertime).diff(startDate, 'minutes') / gap
    if (item.foot === "left") {
      emptyLeftTimeGaps[index] = item;
    } else {
      emptyRightTimeGaps[index] = item;
    }
  });

  return [emptyLeftTimeGaps, emptyRightTimeGaps]
}

function parsePairMinDataWashed(rawData, isMin1, startDateStr, endDateStr) {
  const rawDataStr = zlib
    .inflateSync(new Buffer(rawData, "base64"))
    .toString();

  const itemKeys = ["usertime", "ltoe", "lmts1", "lmts3", "lmts5", "larch", "lheel", "lgsensorx", "lgsensory", "lgsensorz",
    "rtoe", "rmts1", "rmts3", "rmts5", "rarch", "rheel", "rgsensorx", "rgsensory", "rgsensorz", "steps"]

  const parsedData = rawDataStr.split('|').map(itemStr => {
    const rawItem = itemStr.split(',').reduce((sum, value, index) => {
      sum[itemKeys[index]] = index == 0 ? value : parseFloat(value);
      return sum;
    }, {});

    // 'yyyy-MM-dd HH:mm:ss'
    if (rawItem.usertime) {
      rawItem.usertime = moment(rawItem.usertime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
    }

    return rawItem;
  }).map(item => ({
    usertime: item.usertime,
    dtoe: Number.prototype.toFixed.call(item.ltoe - item.rtoe, 2),
    dmts1: Number.prototype.toFixed.call(item.lmts1 - item.rmts1, 2),
    dmts3: Number.prototype.toFixed.call(item.lmts3 - item.rmts3, 2),
    dmts5: Number.prototype.toFixed.call(item.lmts5 - item.rmts5, 2),
    darch: Number.prototype.toFixed.call(item.larch - item.rarch, 2),
    dheel: Number.prototype.toFixed.call(item.lheel - item.rheel, 2),
    steps: item.steps
  }));

  // fulfill the time gap.
  const startDate = moment(startDateStr);
  const days = isMin1 ? 1 : moment(endDateStr).diff(startDate, 'days');
  const gap = isMin1 ? 1 : 30;

  let emptyTimeGaps = Array(24 * 60 * days / gap).fill(0).map((_, i) => ({
    usertime: startDate.clone().add(i * gap, 'minutes').format('YYYY-MM-DDTHH:mm:00')
  }))

  parsedData.forEach(item => {
    const index = moment(item.usertime).diff(startDate, 'minutes') / gap
    emptyTimeGaps[index] = item;
  })

  return emptyTimeGaps;
}

function processMin1RawWashedData(dataStr, dispatch, startDateStr) {
  dataStr = JSON.parse(dataStr);
  const rawDataStr = zlib
    .inflateSync(new Buffer(dataStr, "base64"))
    .toString();

  // rawDataFormat: "1,2,3,4|2,4,32,1|2,3,41,1"
  const itemKeys = ["usertime", "foot", "toe", "mts1", "mts3", "mts5", "arch", "heel", "gsensorx", "gsensory", "gsensorz", "steps", "source", "mark"];
  const rawData = rawDataStr.split('|').map(itemStr => {
    const rawItem = itemStr.split(',').reduce((sum, value, index) => {
      sum[itemKeys[index]] = value;
      return sum;
    }, {});

    // 'yyyy-MM-ddTHH:mm:ss'
    if (rawItem.usertime) {
      rawItem.usertime = rawItem.usertime.substring(0, 19);
    }

    return rawItem;
  });

  // use the same var name as in `getMinrawData`
  const leftFootTempRaw = rawData.filter(item => item.foot === 'left').map(item => ({
    usertime: item.usertime,
    toe: item.toe,
    mts1: item.mts1,
    mts3: item.mts3,
    mts5: item.mts5,
    arch: item.arch,
    heel: item.heel,
    source: item.source,
    mark: item.mark
  }));

  // fulfill the time gap
  const emptyLeftFootTempRaw = Array(24 * 60).fill(0).map((_, i) => ({
    usertime: `${startDateStr}T${Math.floor(i / 60).toString().padStart(2, '0')}:${Math.floor(i % 60).toString().padStart(2, '0')}:00`
  }))

  leftFootTempRaw.forEach(item => {
    const hour = parseInt(item.usertime.substring(11, 13));
    const min = parseInt(item.usertime.substring(14, 16));
    emptyLeftFootTempRaw[hour * 60 + min] = item;
  })

  dispatch(changeLeftFootTempStatusWashed(emptyLeftFootTempRaw));

  const rightFootTempRaw = rawData.filter(item => item.foot === 'right').map(item => ({
    usertime: item.usertime,
    toe: item.toe,
    mts1: item.mts1,
    mts3: item.mts3,
    mts5: item.mts5,
    arch: item.arch,
    heel: item.heel,
    source: item.source,
    mark: item.mark
  }));

  // fulfill the time gap
  const emptyRightFootTempRaw = Array(24 * 60).fill(0).map((_, i) => ({
    usertime: `${startDateStr}T${Math.floor(i / 60).toString().padStart(2, '0')}:${Math.floor(i % 60).toString().padStart(2, '0')}:00`
  }))

  rightFootTempRaw.forEach(item => {
    const hour = parseInt(item.usertime.substring(11, 13));
    const min = parseInt(item.usertime.substring(14, 16));
    emptyRightFootTempRaw[hour * 60 + min] = item;
  })

  dispatch(changeRightFootTempStatusWashed(emptyRightFootTempRaw));
}

function changeSockChartData(data) {
  return {
    type: TYPES.SOCK_CHART_DATA,
    payload: data
  }
}

function changeDeltaAutoStatusWashed(deltaAuto) {
  return {
    type: TYPES.DELTA_AUTO_WASHED,
    text: deltaAuto
  };
}

function changeDeltaStatusWashed(deltaData) {
  return {
    type: TYPES.DELTA_WASHED,
    text: deltaData
  };
}

function changeLeftFootTempStatusWashed(data) {
  return {
    type: TYPES.LEFT_FOOT_TEMP_WASHED,
    text: data
  };
}

function changeRightFootTempStatusWashed(data) {
  return {
    type: TYPES.RIGHT_FOOT_TEMP_WASHED,
    text: data
  };
}

function changeLeftFootTempStatusMarked(data) {
  return {
    type: TYPES.LEFT_FOOT_TEMP_MARKED,
    text: data
  };
}

function changeRightFootTempStatusMarked(data) {
  return {
    type: TYPES.RIGHT_FOOT_TEMP_MARKED,
    text: data
  };
}

export function changeTempUnitState(tempUnit) {
  return {
    type: TYPES.TEMP_UNIT,
    text: tempUnit
  };
}

export function celToFahrenheit(temp) {
  return Number.parseFloat(toFloat(temp * 1.8 + 32));
}

export function celToFahrenheitDelta(temp) {
  return Number.parseFloat(toFloat(temp * 1.8));
}

export var toFloat = function(value) {
  value = Math.round(parseFloat(value) * 10) / 10;

  if (value.toString().indexOf(".") < 0) {
    value = value.toString() + ".0";
  } else value = value.toString();

  return value;
};

export function updateUserGroups(groups) {
  return dispatch => {
    dispatch(updateUserGroupsState(groups));
  };
}

export function clearUserGroup(groups) {
  return dispatch => {
    dispatch(updateUserGroupsState(groups));
  };
}

export function updateUserGroupsState(groups) {
  return {
    type: TYPES.USER_GROUPS,
    text: groups
  };
}

export function udpateSockDataMark(foot, data) {
  return (dispatch, getState) => {
    if (foot === 'left') {
      const nextData = getState().patientsListStore.leftFootTempMarked
      dispatch({
        type: TYPES.LEFT_FOOT_TEMP_MARKED,
        text: [...nextData, data]
      })
    } else {
      const nextData = getState().patientsListStore.rightFootTempMarked
      dispatch({
        type: TYPES.RIGHT_FOOT_TEMP_MARKED,
        text: [...nextData, data]
      })
    }
  }
}
