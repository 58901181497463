import React, { Component } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ButtonSuccess from "../home/buttonSuccess";
import "react-tabs/style/react-tabs.css";
import moment from "moment";
import { deleteMonitoringTracker } from "../action/ServiceAction";
import _ from "lodash";
class deleteRpmTracker extends Component {
  constructor(props) {
    super(props);
    this.handleConfirm.bind(this);
  }
  state = { open: false, confirmSuccess: false };

  show = () => {
    this.setState({ open: true, confirmSuccess: false });
  };
  checkPatientMedicalInfo = patientMedicalInfo => {
    return (
      !patientMedicalInfo.footConditions &&
      !patientMedicalInfo.footTreatments &&
      !patientMedicalInfo.medicalCondition &&
      !patientMedicalInfo.footSpecific &&
      !patientMedicalInfo.footAmputation
    );
  };
  handleConfirm = async () => {
    const { deleteMonitoringTracker } = this.props;
    let updatePatientMedicalInfoVersion = false;
    // const maxServiceInfo = _.maxBy(this.props.npmService, "rpmKey");
    const allServiceInfo = _(this.props.npmService)
      .sortBy(["serviceDate", "createdAt"])
      .value();
    const maxServiceInfo =
      allServiceInfo.length > 0 && allServiceInfo[allServiceInfo.length - 1];
    if (
      moment(this.props.serviceInfo.serviceDate).format("YYYY-MM") ===
        moment().format("YYYY-MM") &&
      maxServiceInfo.rpmKey === this.props.serviceInfo.rpmKey &&
      !this.checkPatientMedicalInfo(this.props.serviceInfo)
    ) {
      updatePatientMedicalInfoVersion = true;
    }
    const input = {
      userId: this.props.serviceInfo.userId,
      rpmKey: this.props.serviceInfo.rpmKey,
      currentMonth: moment().format("YYYY-MM")
    };
    if (updatePatientMedicalInfoVersion) {
      Object.assign(input, { updatePatientMedicalInfoVersion });
    }
    deleteMonitoringTracker(input, this).then(() => {
      this.props.loadNewEditValue();
    });
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });
  render() {
    return (
      <Modal
        trigger={
          <Button
            size="mini"
            onClick={this.show}
            className="deleteButton"
            disabled={this.props.staffId !== this.props.serviceInfo.doctorId}
            style={{ display: "none" }}
          >
            Delete
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <p>
                Are you sure you want to permanently delete this DMT entry?
              </p>
              <div>
                <p className="cofirmSiteId">
                  Date:
                  <strong className="cofirmBold">
                    {moment(this.props.serviceInfo.serviceDate).format(
                      "MM/DD/YYYY"
                    )}
                  </strong>
                </p>
                <p>
                  Spent Time:
                  <strong className="cofirmBold">
                    {this.props.serviceInfo.spendTime} mins
                  </strong>
                </p>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  npmService: state.patientsListStore.npmService
});
const mapDispatchToProp = dispatch => ({
  deleteMonitoringTracker: (input, self) =>
    dispatch(deleteMonitoringTracker(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(deleteRpmTracker);
