import { DatePicker } from "antd";
import FileSaver from "file-saver";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Files from "react-files";
import ReactJson from "react-json-view";
import Modal from "react-modal";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  Icon,
  Input,
  Label,
  Segment,
  Table
} from "semantic-ui-react";
import { searchPatient, testSimulateData } from "../action/SimulateAction";
import Progress from "../home/progress";
import { limitNumberInput } from "../tools/numberFormat";
import "./simulateSock.css";
import message from "../tools/message-info";

const { RangePicker } = DatePicker;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};

class SimulateSock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      searchSirenId: null,
      patient: null,
      patientInfo: null,
      simulateSock: "",
      leftConfig: {
        toe: { mean: "33", variance: 1 },
        mts1: { mean: "33", variance: 1 },
        mts3: { mean: "33", variance: 1 },
        mts5: { mean: "33", variance: 1 },
        arch: { mean: "33", variance: 1 },
        heel: { mean: "33", variance: 1 }
      },
      rightConfig: {
        toe: { mean: "33", variance: 1 },
        mts1: { mean: "33", variance: 1 },
        mts3: { mean: "33", variance: 1 },
        mts5: { mean: "33", variance: 1 },
        arch: { mean: "33", variance: 1 },
        heel: { mean: "33", variance: 1 }
      },
      startTime: moment().format("YYYY-MM-DDT08:00:00"),
      endTime: moment().format("YYYY-MM-DDT12:00:00"),
      userId: "",
      jsonConfig: []
    };
    this.changeLeftPointValue = this.changeLeftPointValue.bind(this);
    this.changeRightPointValue = this.changeRightPointValue.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.fileReader = new FileReader();
    this.fileReader.onload = event => {
      let jsonParse = JSON.parse(event.target.result);

      this.setState(
        {
          jsonConfig: jsonParse,
          userId: jsonParse[0].userId,
          searchSirenId: jsonParse[0].sirenId,
          startTime: moment(jsonParse[0].startTime).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          endTime: moment(jsonParse[0].endTime).format("YYYY-MM-DDTHH:mm:ss"),
          leftConfig:
            jsonParse[0].foot === "left"
              ? jsonParse[0].hasOwnProperty("conf")
                ? jsonParse[0].conf
                : {}
              : jsonParse[1].hasOwnProperty("conf")
              ? jsonParse[1].conf
              : {},
          rightConfig:
            jsonParse[0].foot === "right"
              ? jsonParse[0].hasOwnProperty("conf")
                ? jsonParse[0].conf
                : {}
              : jsonParse[1].hasOwnProperty("conf")
              ? jsonParse[1].conf
              : {}
        },
        () => {
          console.log(this.state.jsonConfig);
        }
      );
    };
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  onChangeTime(value, dateString) {
    this.setState({
      startTime: moment(dateString[0]).format("YYYY-MM-DDTHH:mm:ss"),
      endTime: moment(dateString[1]).format("YYYY-MM-DDTHH:mm:ss")
    });
    if (this.state.jsonConfig.length === 2) {
      let newConfig = this.state.jsonConfig;
      newConfig[0].startTime = moment(dateString[0]).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      newConfig[0].endTime = moment(dateString[1]).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      newConfig[1].startTime = moment(dateString[0]).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      newConfig[1].endTime = moment(dateString[1]).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      this.setState({ jsonConfig: newConfig });
    }
  }
  changeLeftPointValue(Input, value) {
    let leftInput = this.state.leftConfig;
    let leftValue = {};
    if (!isEmpty(value)) {
      leftValue.mean = value;
      leftValue.variance = 1;
      leftInput[Input] = leftValue;
      this.setState({ leftConfig: leftInput });
    } else {
      delete leftInput[Input];
      this.setState({ leftConfig: leftInput });
    }
  }

  changeRightPointValue(Input, value) {
    let rightInput = this.state.rightConfig;
    let rightValue = {};
    if (!isEmpty(value)) {
      rightValue.mean = value;
      rightValue.variance = 1;
      rightInput[Input] = rightValue;
      this.setState({ rightConfig: rightInput });
    } else {
      delete rightInput[Input];
      this.setState({ rightConfig: rightInput });
    }
  }

  searchSirenIdChanged = event => {
    this.setState({ searchSirenId: event.target.value });
  };

  searchPatient = () => {
    const { searchPatient } = this.props;
    searchPatient(this, this.state.searchSirenId);
  };
  getMAC() {
    let hexDigits = "0123456789ABCDEF";
    let macAddress = "";
    for (var i = 0; i < 6; i++) {
      macAddress += hexDigits.charAt(Math.round(Math.random() * 15));
      macAddress += hexDigits.charAt(Math.round(Math.random() * 15));
      if (i !== 5) macAddress += ":";
    }

    return macAddress;
  }

  handleOK = () => {
    let jsonConfig = [];
    let leftFoot = {};
    let rightFoot = {};
    if (isEmpty(this.state.userId)) {
      message.info("no userId");
      return;
    }
    if (isEmpty(this.state.startTime) || isEmpty(this.state.endTime)) {
      message.info("please select time.");
      return;
    }
    leftFoot.userId = this.state.userId;
    leftFoot.sirenId = isEmpty(this.state.searchSirenId)
      ? this.state.patientInfo.sirenId
      : this.state.searchSirenId;
    leftFoot.foot = "left";
    leftFoot.mac = this.getMAC();
    leftFoot.startTime = this.state.startTime;
    leftFoot.endTime = this.state.endTime;
    if (!isEmpty(this.state.leftConfig)) {
      leftFoot.conf = this.state.leftConfig;
    }
    jsonConfig.push(leftFoot);
    rightFoot.userId = this.state.userId;
    rightFoot.sirenId = isEmpty(this.state.searchSirenId)
      ? this.state.patientInfo.sirenId
      : this.state.searchSirenId;
    rightFoot.foot = "right";
    rightFoot.mac = this.getMAC();
    rightFoot.startTime = this.state.startTime;
    rightFoot.endTime = this.state.endTime;
    if (!isEmpty(this.state.rightConfig)) {
      rightFoot.conf = this.state.rightConfig;
    }
    jsonConfig.push(rightFoot);
    this.setState({ jsonConfig });
  };

  handleUploadConfig = () => {
    const { testSimulateData } = this.props;
    if (this.state.jsonConfig.length > 0) {
      testSimulateData(JSON.stringify(this.state.jsonConfig), this);
    }
  };

  handleDownloadConfig = () => {
    let blob = new Blob([JSON.stringify(this.state.jsonConfig)], {
      type: "text/plain;charset=utf-8"
    });
    FileSaver.saveAs(
      blob,
      `${this.state.patientInfo &&
        this.state.patientInfo.sirenId} ${moment().format(
        "YYYY-MM-DDTHH:mm:ss"
      )}.txt`
    );
  };

  render() {
    return (
      <div className="device-list-container">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="simulate_sock_left_box">
          <div className="search_patient_container">
            <span className="search_label search_email_label">SirenId:</span>
            <Input
              placeholder="Search"
              type="text"
              onChange={this.searchSirenIdChanged.bind(this)}
              onBlur={event => this.searchPatient(event)}
              value={this.state.searchSirenId || ""}
            />
            <Icon
              name="search"
              size="small"
              className="search_icon"
              onClick={this.searchPatient}
            />
          </div>
          <div className="userid_container_box">
            <span>
              <strong>UserId: </strong>
              {this.state.userId}
            </span>
          </div>
          <div className="time_picker_box">
            <span className="time_picker_label">Time Range:</span>
            <RangePicker
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [
                  moment("08:00:00", "HH:mm:ss"),
                  moment("12:00:00", "HH:mm:ss")
                ]
              }}
              allowClear={false}
              value={[moment(this.state.startTime), moment(this.state.endTime)]}
              onChange={this.onChangeTime}
              format="YYYY-MM-DDTHH:mm:ss"
            />
          </div>
          <div className="left_foot_table">
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Segment className="Segment_simulate">
                    <div className="seg-title">
                      <Label as="a" color="red" ribbon>
                        Left Foot:
                      </Label>
                    </div>
                    <Table celled className="result-table">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>toe</Table.HeaderCell>
                          <Table.HeaderCell>mts1</Table.HeaderCell>
                          <Table.HeaderCell>mts3</Table.HeaderCell>
                          <Table.HeaderCell>mts5</Table.HeaderCell>
                          <Table.HeaderCell>arch</Table.HeaderCell>
                          <Table.HeaderCell>heel</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.leftConfig.toe &&
                                  this.state.leftConfig.toe.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeLeftPointValue(
                                  "toe",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.leftConfig.mts1 &&
                                  this.state.leftConfig.mts1.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeLeftPointValue(
                                  "mts1",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.leftConfig.mts3 &&
                                  this.state.leftConfig.mts3.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeLeftPointValue(
                                  "mts3",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.leftConfig.mts5 &&
                                  this.state.leftConfig.mts5.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeLeftPointValue(
                                  "mts5",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.leftConfig.arch &&
                                  this.state.leftConfig.arch.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeLeftPointValue(
                                  "arch",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.leftConfig.heel &&
                                  this.state.leftConfig.heel.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeLeftPointValue(
                                  "heel",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div className="right_foot_table">
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Segment className="Segment_simulate">
                    <div className="seg-title">
                      <Label as="a" color="red" ribbon>
                        Right Foot:
                      </Label>
                    </div>
                    <Table celled className="result-table">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>toe</Table.HeaderCell>
                          <Table.HeaderCell>mts1</Table.HeaderCell>
                          <Table.HeaderCell>mts3</Table.HeaderCell>
                          <Table.HeaderCell>mts5</Table.HeaderCell>
                          <Table.HeaderCell>arch</Table.HeaderCell>
                          <Table.HeaderCell>heel</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.rightConfig.toe &&
                                  this.state.rightConfig.toe.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeRightPointValue(
                                  "toe",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.rightConfig.mts1 &&
                                  this.state.rightConfig.mts1.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeRightPointValue(
                                  "mts1",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.rightConfig.mts3 &&
                                  this.state.rightConfig.mts3.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeRightPointValue(
                                  "mts3",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.rightConfig.mts5 &&
                                  this.state.rightConfig.mts5.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeRightPointValue(
                                  "mts5",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.rightConfig.arch &&
                                  this.state.rightConfig.arch.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeRightPointValue(
                                  "arch",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              value={
                                (this.state.rightConfig.heel &&
                                  this.state.rightConfig.heel.mean) ||
                                ""
                              }
                              type="text"
                              className="point_input"
                              onChange={(e, data) => {
                                this.changeRightPointValue(
                                  "heel",
                                  limitNumberInput(e.target.value)
                                );
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div className="simulate_button_box">
            <Button
              onClick={() => this.handleUploadConfig()}
              className="simulate_ok_button simulate_upload_button"
            >
              Submit
            </Button>
          </div>
        </div>
        {/*<div className="simulate_sock_center_box">*/}
        {/*  <Icon*/}
        {/*    name="long arrow alternate right"*/}
        {/*    size="large"*/}
        {/*    className="right_icon"*/}
        {/*    onClick={() => this.handleOK()}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="simulate_sock_right_box">
          <div className="simulate_sock_import_btn">
            <span style={{ marginRight: 20, fontSize: 16 }}>
              Import local config file:{" "}
            </span>
            <Button className="point_btn">
              <Files
                className="files-dropzone"
                onChange={file => {
                  this.fileReader.readAsText(file[0]);
                }}
                onError={err => console.log("import file error: ", err)}
                accepts={[".txt", ".json"]}
                multiple
                maxFiles={3}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                Import
              </Files>
            </Button>
          </div>
          <div className="simulate_sock_import_btn">
            <span style={{ marginRight: 20, fontSize: 16 }}>
              Download config file:{" "}
            </span>
            <Button
              className="point_btn"
              onClick={() => this.handleDownloadConfig()}
            >
              Download
            </Button>
          </div>
          <div className="simulate_sock_text">
            <ReactJson
              src={this.state.jsonConfig}
              name={false}
              onEdit={v => {
                this.setState({ jsonConfig: v.updated_src });
              }}
              // onAdd={add => {
              //   console.log("add: ", add);s
              // }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  currentBoxInfo: state.boxInfoStore.currentBoxInfo
});
const mapDispatchToProp = dispatch => ({
  searchPatient: (self, sirenId) => dispatch(searchPatient(self, sirenId)),
  testSimulateData: (jsonData, self) =>
    dispatch(testSimulateData(jsonData, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(SimulateSock);
