import { Select } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { curMonthPatient99457 } from "../action/bliMonthyReportAction";
import { getlistClinicSites } from "../action/registerAction";
import { updateReportCurrentMonthTableFilter } from "../reducers/tableFilterReducer";
import ReportCurrentMonthBase from "./ReportCurrentMonthBase";
import ReportHeader99457_8 from "./ReportHeader99457_8";

const { Option } = Select;

class ReportCurrentMonth99457_8 extends ReportCurrentMonthBase {

  cptCode = "99457-8";

  headerClass = ReportHeader99457_8

  renderTableProps() {
    const data = this.state.tableData.map(p => {
      const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
      const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
      const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
      const billingYear = this.state.billingYear;
      const billingMonth = moment().format("MM/YYYY");
      const billingDate = p.billingDate
        ? moment(p.billingDate).format("MM/YYYY")
        : "";

      const endDay = parseInt(
        moment()
          .endOf("month")
          .format("DD")
      );
      const curDay = parseInt(moment().format("DD"));
      const daysRemaining = endDay - curDay;
      const spentTime = p.totalMinutesSpent ? p.totalMinutesSpent : 0;
      const Eligible = spentTime >= 20 ? "Yes" : "No";
      const initializedDate = p.initializedDate
        ? p.initializedDate.split("T")[0]
        : "";
      const appInitialized = p.appInitialized ? p.appInitialized : "No";
      const diagnosisCodes = p.diagnosisCodes
        ? p.diagnosisCodes.replace(/_/g, ",")
        : "";
      const serviceE = p.serviceE ? p.serviceE : "No";
      return {
        ...p,
        createdAt,
        sirenId,
        dispensedDay,
        billingYear,
        billingDate,
        billingMonth,
        spentTime,
        Eligible,
        initializedDate,
        daysRemaining,
        diagnosisCodes,
        appInitialized,
        serviceE
      };
    })

    const columns = [
      {
        Header: "SiteID",
        accessor: "siteId",
        maxWidth: 60,
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        minWidth: 100,
      },
      {
        Header: "Doctor",
        accessor: "doctorName",
        minWidth: 100,
      },
      {
        Header: "SirenID",
        accessor: "sirenId",
        maxWidth: 60,
      },
      {
        Header: "PatientID",
        accessor: "patientId",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Date of Birth",
        accessor: "birthday",
      },
      {
        Header: "ICD-10 Codes",
        accessor: "diagnosisCodes",
      },
      {
        Header: "Account Created",
        accessor: "createdAt",
      },
      // {
      //   Header: "App Activated",
      //   accessor: "appInitialized",
      //   maxWidth: 72,
      //   filterMethod: (filter, row) => {
      //     if (filter.value === "all") {
      //       return true;
      //     }
      //     if (filter.value === "Yes") {
      //       return row[filter.id] === "Yes";
      //     }
      //     return row[filter.id] === "No";
      //   },
      //   Filter: ({ filter, onChange }) => (
      //     <Select
      //       className="select_common_style"
      //       onChange={onChange}
      //       style={{ width: "100%" }}
      //       value={filter ? filter.value : "all"}
      //       filterOption={(input, option) =>
      //         option.props.children
      //           .toLowerCase()
      //           .indexOf(input.toLowerCase()) >= 0
      //       }
      //       showSearch
      //       optionFilterProp="children"
      //     >
      //       <Option value="all">All</Option>
      //       <Option value="Yes">Yes</Option>
      //       <Option value="No">No</Option>
      //     </Select>
      //   )
      // },
      {
        Header: "Socks Dispensed Date",
        accessor: "dispensedDay",
        minWidth: 120,
      },
      // {
      //   Header: "Initial Data Transmission",
      //   accessor: "firstStatusDay",
      //   minWidth: 120,
      // },
      {
        Header: "Monitoring Month",
        accessor: "billingMonth",
      },
      {
        Header: "Total Time (mins)",
        accessor: "spentTime",
      },
      {
        Header: "Service E",
        accessor: "serviceE",
        maxWidth: 100,
        Filter: ({ filter, onChange }) => (
          <Select
            className="select_common_style"
            onChange={onChange}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
            showSearch
            optionFilterProp="children"
          >
            <Option value="">All</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
        )
      },
      {
        Header: "Days Remaining",
        accessor: "daysRemaining",
      }
    ]

    return { data, columns }
  }

}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  tableDefaultState: state.tableFilter.report99457_8CurrentMonth
});

const mapDispatchToProp = dispatch => ({
  searchCurrentMonthPatients: (billingInputValue) =>
    dispatch(curMonthPatient99457(billingInputValue)),
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  updateTableDefaultState: (cptCode, state) => dispatch(updateReportCurrentMonthTableFilter(cptCode, state))
});

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(ReportCurrentMonth99457_8);
