import _ from "lodash";
import React, { Component } from "react";
import { Icon, Modal, Button } from "semantic-ui-react";
import { updatePatientSingleFoot } from "../graphql/API";
import "../share/button.css";
import ButtonSuccess from "./buttonSuccess";
import { message, Radio, Button as AntdButton } from "antd";
import { uppercaseFirstStr } from "../tools/stringProcess";

export default class SingleFoot extends Component {

	state = {
		open: false,
		confirmSuccess: false,
		singleFoot: this.props.value || 'two'
	}

  open = () => {
    this.setState({
      open: true,
      confirmSuccess: false
    });
  };

  onChange = e => {
    this.setState({
      singleFoot: e.target.value
    });
  };

  handleCancel = () => {
    this.setState({
      open: false,
      confirmSuccess: false
    });
  };

  handleConfirm = () => {
    if (this.props.value === this.state.singleFoot) {
      this.setState({
        open: false,
        confirmSuccess: true
      });
      return;
    }
    updatePatientSingleFoot(this.props.id, this.state.singleFoot)
      .then(data => {
        this.setState({
          confirmSuccess: true
        });
        setTimeout(() => {
          this.setState({
            open: false
          });
          if (typeof this.props.onChange === "function") {
            this.props.onChange(this.state.singleFoot);
          }
        }, 500);
      })
      .catch(e => {
        message.error(JSON.stringify(e));
      });
  };

  render() {
    return (
      <Modal
        trigger={
          <AntdButton onClick={this.open} type={"link"}>
            {uppercaseFirstStr(this.props.value || "N/A")}
          </AntdButton>
        }
        open={this.state.open}
      >
        <Modal.Header>Mark Single Foot</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <form style={{ height: 80 }}>
              <Radio.Group
                onChange={this.onChange}
                value={this.state.singleFoot}
              >
                <Radio value="left">Left</Radio>
                <Radio value="right">Right</Radio>
                <Radio value="two">N/A</Radio>
              </Radio.Group>
            </form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={this.handleConfirm}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={this.handleCancel}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
