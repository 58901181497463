import { isEmpty } from "lodash";
import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { registerSock } from "../action/boxAction";
import { registeredPatientListAPI } from "../graphql/API";
import { message, notification } from "antd";
import "./registerbox.css";
import { createNote } from "../action/DoctorNoteAction";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";

class Registerbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchSirenId: "",
      loadingPatient: false,
      patient: [],
      regiSuccess: false,
      loadingRegister: false
    };
  }

  handleOpen = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  searchPatient = () => {
    if (isEmpty(this.state.searchSirenId)) {
      message.warning("please input sirenId");
      return;
    }

    this.setState({
      loadingPatient: true,
      patient: []
    });
    registeredPatientListAPI({
      sirenId: {
        eq: this.state.searchSirenId
      }
    })
      .then(res => {
        this.setState({
          loadingPatient: false
        });
        if (res.data.lambdaSearchPatients.total > 0) {
          this.setState({
            patient: res.data.lambdaSearchPatients.items
          });
        }
      })
      .catch(error => {
        this.setState({
          loadingPatient: false
        });
        // graphQL error
        if (error.errors) {
          notification.error({
            message: "System Error",
            description: error.errors[0].message
          });
        }
      });
  };

  submit = async value => {
    const user = await Auth.currentAuthenticatedUser();
    this.setState({
      loadingRegister: true
    });

    const creds = {
      boxId: this.props.item.id,
      authCode: this.props.item.authCode,
      userId: value.userId
    };
    registerSock(creds)
      .then(res => {
        this.setState({
          loadingRegister: false
        });
        if (res.success) {
          this.setState({
            regiSuccess: true
          });
          const input = {
            id: value.id,
            author: `${user.username}`,
            authorId: `${user.username}`,
            title: isEmpty(value.rpmDeviceStatus)
              ? "New Device Registered: Siren Socks, Device Status = Paused"
              : "Replacement Device Registered: Siren Socks",
            note: `Registered Box ${this.props.item.id}`
          };
          this.props.createNote(input);
          // automatically close after success animation
          setTimeout(() => {
            this.setState({
              open: false
            });
            if (typeof this.props.onSuccess === "function") {
              this.props.onSuccess(this.props.item.id);
            }
          }, 500);
        } else {
          let desc = null;
          if (res.msg) {
            desc = res.msg;
          } else if (res.error) {
            if (res.error.msg) {
              desc = res.error.msg;
            } else if (res.error instanceof Error) {
              desc = res.error.message;
            } else {
              desc = JSON.stringify(res.error);
            }
          } else {
            desc = JSON.stringify(res);
          }
          notification.error({
            message: "System Error",
            description: desc
          });
        }
      })
      .catch(error => {
        this.setState({
          loadingRegister: false
        });
        if (error instanceof Error) {
          return notification.error({
            message: "System Error",
            description: error.message
          });
        }
        // graphQL error
        if (error.errors) {
          notification.error({
            message: "System Error",
            description: error.errors[0].message
          });
        }
      });
  };

  searchSirenIdChanged = event => {
    this.setState({ searchSirenId: event.target.value });
  };

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={<Button onClick={this.handleOpen}>Register</Button>}
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>
          {this.state.regiSuccess
            ? "Register the box success"
            : `Siren Box Registration`}
        </Modal.Header>
        <Modal.Content>
          {this.state.regiSuccess ? (
            <div className="regiSuccess">
              <Icon name="check circle" id="regiSuccessIcon" />
              <p className="regiSuccessTitle">Awesome!</p>
              <p className="regiSuccesstext">
                Box has been successfully registered.
              </p>
            </div>
          ) : (
            <div>
              <div className="search_patient_container">
                <span className="search_label search_email_label">
                  SirenId:
                </span>
                <Input
                  placeholder="Search"
                  type="number"
                  onChange={this.searchSirenIdChanged}
                  onKeyPress={e => {
                    if (e.key === "Enter") {
                      this.searchPatient();
                    }
                  }}
                  value={this.state.searchSirenId || ""}
                />
                <Icon
                  name="search"
                  size="large"
                  className="search_icon"
                  onClick={this.searchPatient}
                />
              </div>
              <ReactTable
                loading={this.state.loadingPatient}
                data={this.state.patient}
                columns={[
                  {
                    Header: "SirenID",
                    accessor: "sirenId"
                  },
                  {
                    Header: "Full Name",
                    accessor: "fullName",
                    Cell: props => {
                      return `${props.original.firstName} ${props.original.lastName}`;
                    }
                  },
                  {
                    Header: "Actions",
                    sortable: false,
                    filterable: false,
                    Cell: props => {
                      return (
                        <div>
                          <Button
                            loading={this.state.loadingRegister}
                            color="green"
                            onClick={e => this.submit(props.original)}
                          >
                            <Icon name="checkmark" />
                            Register Box
                          </Button>
                        </div>
                      );
                    }
                  }
                ]}
                defaultSorted={[{ id: "sirenId", desc: false }]}
                defaultPageSize={10}
                className="-striped -highlight"
                minRows={0}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.regiSuccess ? "Close" : "Cancel"}
          </Button>
          {/* <Button color="green" onClick={e => this.submit()}>
            <Icon name="checkmark" />
            Add
          </Button> */}
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({
  createNote: input => dispatch(createNote(input))
});
export default connect(mapStateToProp, mapDispatchToProp)(Registerbox);
