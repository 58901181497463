import { Select, Checkbox, Modal as AntdModal, Popover, Divider } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import _ from "lodash";
import React, { Component } from "react";
import "react-dropdown/style.css";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Button, Icon } from "semantic-ui-react";
import commonStyles from "../common/CommonStyles";
import "../share/common.css";
import "../report/biMonthly.css";
import "../report/Tracker99457.css";
import getPatchTrProps from "../tools/reactTablePatch";
import Files from "react-files";
import { Auth, Storage } from "aws-amplify";
import Progress from "../home/progress";
import { createCarePlan, listCarePlan } from "../action/carePlanAction";
import queryString from "query-string";
import { listAdminUsers } from "../action/registerAction";
import DocumentPreview from "./document-preview";

Modal.setAppElement("div");
const { Option } = Select;
class ListDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      startDate: moment().format("YYYY-MM"),
      modalIsOpen: false,
      percent: 0,
      selectFiles: [],
      authorId: "",
      userId: ""
    };
    this.reviewDocument = false;
  }

  onFileChange = async files => {
    try {
      this.openModal();
      const file = files[0];
      await Storage.put(`CarePlan/${file.name}`, file, {
        level: "private",
        identityId: this.props.doctorDetails.userId,
        progressCallback(progress) {
          this.setState({ percent: (progress.loaded / progress.total) * 100 });
        }
      });
      const staffInfo = _(this.props.listAdminStaff).find({
        id: this.state.authorId
      });
      const input = {
        userId: this.state.userId,
        author: staffInfo ? `${staffInfo.firstName} ${staffInfo.lastName}` : "",
        authorId: this.state.authorId,
        location: `CarePlan/${file.name}`,
        month:
          this.state.year +
          "-" +
          (this.state.month < 10 ? "0" + this.state.month : this.state.month),
        name: file.name
      };
      this.props.createCarePlan(input, this);
      this.refs.files.removeFiles();
    } catch (e) {
      console.log("on file change error: ", JSON.stringify(e));
    }
  };

  async componentDidMount() {
    let user = await Auth.currentAuthenticatedUser();
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const userId = params.userId;
    this.setState({ userId, authorId: user.username });
    this.searchFiles(userId, this.state.year, this.state.month);
  }

  searchFiles = (userId, year, month) => {
    const start = year + "-" + (month < 10 ? "0" + month : month) + "-01";
    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(start)
      .add(1, "month")
      .format("YYYY-MM-DD");
    this.props.listCarePlan(this, userId);
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !this.reviewDocument;
  }

  handleMonthChange = value => {
    this.setState({
      month: parseInt(value)
    });
    this.searchFiles(this.state.userId, this.state.year, parseInt(value));
  };

  handleYearChange = value => {
    this.setState({
      year: value
    });
    this.searchFiles(this.state.userId, parseInt(value), this.state.month);
  };

  downloadServices = async () => {
    if (this.state.selectFiles.length === 0) {
      AntdModal.warning({
        title: "Warning",
        content: "Please select at least one care plan to download.",
        centered: true
      });
      return;
    }
    for (const file of this.state.selectFiles) {
      Storage.get(file.location, {
        identityId: this.props.doctorDetails.userId,
        level: "private"
      })
        .then(data => {
          window.open(data);
        })
        .catch(err => {
          console.log("download pdf error: ", err);
        });
    }
  };

  handleCheckAll(checked) {
    let selectFiles = [];
    if (checked) {
      selectFiles = this.props.listCarePlans;
    }
    console.log("all: ", selectFiles);
    this.setState({
      selectFiles
    });
  }

  handleCheck(checked, service) {
    let selectFiles = [...this.state.selectFiles];
    if (checked) {
      selectFiles.push(service);
    } else {
      selectFiles = selectFiles.filter(p => p.createdAt !== service.createdAt);
    }

    console.log("selectFiles: ", selectFiles);
    this.setState({
      selectFiles
    });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const trProps = getPatchTrProps(({ rowInfo, event: e }) => {
      const buttons = e.currentTarget.getElementsByTagName("button");
      for (const button of buttons) {
        if (button.innerHTML === "Preview") {
          button.click();
          return;
        }
      }
    });
    return (
      <div className="device-list-container">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="search_patient_container" style={{ display: "flex" }}>
          <Button type="link" onClick={this.downloadServices}>
            <Icon name="download" />
            Download Document
          </Button>
          <Button
            style={{
              position: "absolute",
              top: 58,
              right: 0,
              color: "#fff",
              backgroundColor: "#7C35AF",
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 12,
              paddingBottom: 12,
              marginRight: 0
            }}
          >
            <Files
              ref="files"
              className="files-dropzone"
              onChange={this.onFileChange}
              onError={err => console.log("import file error: ", err)}
              accepts={[".pdf"]}
              multiple={false}
              maxFiles={1}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <Icon name="upload" />
              Upload Document
            </Files>
          </Button>
        </div>

        <ReactTable
          ref={ref => {
            if (ref) {
              this.tableInstance = ref;
            }
          }}
          getTrProps={trProps}
          data={
            this.props.listCarePlans &&
            this.props.listCarePlans.map(p => {
              const uploadDate = moment(p.createdAt).format("MM/DD/YYYY");
              return {
                ...p,
                uploadDate
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) => {
            if (!row[filter.id]) {
              return false;
            }
            return row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          }}
          columns={[
            {
              columns: [
                {
                  Header: props => {
                    return (
                      <Checkbox
                        onChange={e => this.handleCheckAll(e.target.checked)}
                        indeterminate={
                          this.state.selectFiles.length > 0 &&
                          this.state.selectFiles.length <
                            this.props.listCarePlans.length
                        }
                        checked={
                          this.state.selectFiles.length > 0 &&
                          this.state.selectFiles.length ===
                            this.props.listCarePlans.length
                        }
                        name="selectAll"
                        className="check_all"
                      />
                    );
                  },
                  accessor: "",
                  maxWidth: 60,
                  sortable: false,
                  style: {
                    padding: 0,
                    border: 0,
                    paddingTop: "15px",
                    paddingBottom: "15px"
                  },
                  filterable: false,
                  Cell: props => {
                    return (
                      <div onClick={e => e.stopPropagation()}>
                        <Checkbox
                          onChange={e => {
                            this.handleCheck(e.target.checked, props.original);
                          }}
                          checked={
                            !!this.state.selectFiles.find(
                              item =>
                                item.createdAt === props.original.createdAt
                            )
                          }
                          name="selectSite"
                        />
                      </div>
                    );
                  }
                }
              ]
            },
            {
              Header: "Upload Date",
              accessor: "uploadDate",
              width: 200,
              style: {
                padding: 0,
                border: 0,
                paddingTop: "15px",
                paddingBottom: "15px"
              }
            },
            {
              Header: "Author",
              accessor: "author",
              width: 300,
              style: {
                padding: 0,
                border: 0,
                paddingTop: "15px"
              }
            },
            {
              Header: "Name",
              accessor: "name",
              style: {
                padding: 0,
                border: 0,
                paddingTop: "15px"
              },
              Cell: props => {
                return (
                  <div>
                    {props.original.name}
                    <DocumentPreview
                      carePlanInfo={props.original}
                      reviewDocument={reviewDocument => {
                        this.reviewDocument = reviewDocument;
                      }}
                    />
                  </div>
                );
              }
            }
          ]}
          defaultSorted={[{ id: "serviceDate", desc: false }]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
          showPaginationTop={false}
          style={{ textAlign: "center" }}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  listCarePlans: state.patientsListStore.listCarePlan,
  listAdminStaff: state.patientsListStore.listAdminStaff
});

const mapDispatchToProp = dispatch => ({
  listCarePlan: (self, userId) => dispatch(listCarePlan(self, userId)),
  createCarePlan: (input, self) => dispatch(createCarePlan(input, self)),
  listAdminUsers: () => dispatch(listAdminUsers())
});
export default connect(mapStateToProp, mapDispatchToProp)(ListDocuments);
