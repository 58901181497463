import { Modal as AntdModal } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
import {
  changeLoadingChartsState,
  changePatientsListState
} from "./patientAction";
import { listICD10Codes, listMedicalConditions } from "./userGroupAction";

export function getSendMess(information, self) {
  return async dispatch => {
    try {
      await API.graphql(
        graphqlOperation(_api.sendNotification(), { input: information })
      );
      self.setState({ modalIsOpen: false, open: false });
    } catch (err) {
      console.log("getAddSend exception: ", err);
    }
  };
}
export function addGroup(newgroup, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.setUserGroup(), { input: newgroup })
      );
      if (newEvent) {
        self.setState({ open: false });
        // getPatientsList(dispatch);
        let _listPatients = _.clone(self.props.listPatients);
        let listPatientNew = _.find(_listPatients, { id: newgroup.id });
        if (listPatientNew !== undefined) {
          listPatientNew.group = newgroup.group;
          listPatientNew.siteId = newgroup.group;
          dispatch(changePatientsListState(_listPatients));
        }
      }
    } catch (err) {
      console.log("addGroup exception: ", err);
    }
  };
}
export function getAddTrialPatient(addValue, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateTrialPatient(), { input: addValue })
      );
      if (newEvent) {
        getTrialList(dispatch);
        self.setState({ open: false });
      }
    } catch (err) {
      console.log("getAddTrialPatient exception: ", err);
    }
  };
}
async function getTrialList(dispatch) {
  const newEvent = await API.graphql(
    graphqlOperation(_api.searchPatientsConnection(), {
      filter: {
        group: {
          eq: "TRIAL_SILENCE"
        },
        initializedStr: {
          eq: "Yes"
        }
      },
      limit: 1000
    })
  );
  if (newEvent) {
    dispatch(changeTrialListState(newEvent.data.lambdaSearchPatients.items));
  }
}
function changeTrialListState(trialuser) {
  return {
    type: TYPES.TRIAL_USER,
    text: trialuser
  };
}
export function addComplianceReviewReq(reviewReq, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.setComplianceReviewReq(), { input: reviewReq })
      );
      if (newEvent) {
        self.setState({ open: false });
        // getPatientsList(dispatch);
      }
    } catch (err) {
      console.log("addComplianceReviewReq exception: ", err);
    }
  };
}

export function getAppLink(information, self) {
  return async dispatch => {
    try {
      await API.graphql(
        graphqlOperation(_api.sendAppLink(), { input: information })
      );
      self.setState({ modalIsOpen: false, open: false });
    } catch (err) {
      console.log("getAppLink exception: ", err);
    }
  };
}
export function changeAccountStatus(newStatus, userInfo, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAccountStatus(), { input: newStatus })
      );
      if (newEvent) {
        self.setState({ open: false });
        userInfo.accountStatus = newStatus.status;
        const newPatientInfo = _.clone(self.props.listPatients);
        _.remove(newPatientInfo, {
          id: newStatus.id
        });
        newPatientInfo.push(userInfo);
        dispatch(changePatientsListState(newPatientInfo));
      }
    } catch (err) {
      console.log("changeAccountStatus exception: ", err);
    }
  };
}
export function changeDeviceStatus(newStatus, userInfo, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateDeviceStatus(), { input: newStatus })
      );
      console.log(`update device status success: ${newEvent}`);
      if (newEvent) {
        self.setState({ open: false });
        userInfo[`${newStatus.program.toLowerCase()}DeviceStatus`] =
          newStatus.status;
        const newPatientInfo = _.clone(self.props.listPatients);
        _.remove(newPatientInfo, {
          id: newStatus.id
        });
        newPatientInfo.push(userInfo);
        dispatch(changePatientsListState(newPatientInfo));
      }
    } catch (err) {
      console.log("changeDeviceStatus exception: ", err);
    }
  };
}
export function changeServiceStatus(newStatus, userInfo, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateServiceStatus(), { input: newStatus })
      );
      if (newEvent) {
        self.setState({ open: false, confirmSuccess: false });
        userInfo.accountServiceStatus = newStatus.accountServiceStatus;
        const newPatientInfo = _.clone(self.props.listPatients);
        _.remove(newPatientInfo, {
          id: newStatus.id
        });
        newPatientInfo.push(userInfo);
        dispatch(changePatientsListState(newPatientInfo));
      }
    } catch (err) {
      console.log("changeServiceStatus exception: ", err);
    }
  };
}

export function changeAccountBillingStatus(newStatus, userInfo, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAccountBillingStatus(), {
          input: newStatus
        })
      );
      if (newEvent) {
        self.setState({ open: false, confirmSuccess: false });
        userInfo.accountBillingStatus = newStatus.accountBillingStatus;
        const newPatientInfo = _.clone(self.props.listPatients);
        _.remove(newPatientInfo, {
          id: newStatus.id
        });
        newPatientInfo.push(userInfo);
        dispatch(changePatientsListState(newPatientInfo));
      }
    } catch (err) {
      console.log("changeServiceStatus exception: ", err);
    }
  };
}

export function changeSpecialCaseStatus(newStatus) {
  return API.graphql(
    graphqlOperation(_api.updateSpecialCaseStatus(), { input: newStatus })
  );
}

export function changeRegisterAccountStatus(newStatus, userInfo, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAccountStatus(), { input: newStatus })
      );
      if (newEvent) {
        self.setState({ open: false });
        userInfo.accountStatus = newStatus.status;
        const newPatientInfo = _.clone(self.props.listPatients);
        _.remove(newPatientInfo, {
          id: newStatus.id
        });
        dispatch(changePatientsListState(newPatientInfo));
      }
    } catch (err) {
      console.log("changeAccountStatus exception: ", err);
    }
  };
}

export function checkSocksAndHub(sirenId, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.checkPatientSocksAndHub(), { sirenId })
      );
      if (newEvent) {
        const patient = newEvent.data.getPatientBySirenId;
        if (patient.socks.length > 0 && patient.hubs.length > 0) {
          AntdModal.warn({
            title: "Delete Patient Failed",
            content:
              "The user still has unbound socks and hubs, please unbind them first.",
            centered: true
          });
        } else if (patient.socks.length > 0) {
          AntdModal.warn({
            title: "Delete Patient Failed",
            content:
              "The user still has unbound socks, please unbind them first.",
            centered: true
          });
        } else if (patient.hubs.length > 0) {
          AntdModal.warn({
            title: "Delete Patient Failed",
            content:
              "The user still has unbound hubs, please unbind them first.",
            centered: true
          });
        } else {
          self.handleConfirm();
        }
      } else {
        AntdModal.warn({
          title: "Delete Patient Failed",
          content: `SirenId: ${sirenId} not exist`,
          centered: true
        });
      }
    } catch (e) {
      console.log(`checkSocksAndHub error: ${e}`);
    }
  };
}

export function changeDeletedAccountStatus(newStatus) {
  return API.graphql(
    graphqlOperation(_api.updateAccountStatus(), { input: newStatus })
  );
}

export function sendDocReport(sendValue, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.sendDoctorReport(), { input: sendValue })
      );
      if (newEvent) {
        self.setState({
          open: false,
          siteIds: [],
          type: "TEST",
          doctorEmails: []
        });
      }
    } catch (err) {
      console.log("sendDocReport exception: ", err);
    }
  };
}

export function updateDispensed(input, self) {
  return async dispatch => {
    try {
      dispatch(changeLoadingChartsState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateNewRegisterPatient(), { input })
      );
      dispatch(changeLoadingChartsState(false));
    } catch (e) {
      dispatch(changeLoadingChartsState(false));
      console.log("updateDispensed error: ", e);
    }
  };
}

export function changePatientIRB(editInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.UpdateNewPatient(), { input: editInput })
      );
      if (newEvent) {
        let newPatientsList = _.clone(self.props.listPatients);
        let patientInfo = _.filter(newPatientsList, { id: editInput.id });
        if (patientInfo.length > 0) {
          patientInfo[0].patientIRB = editInput.patientIRB;
        }
        dispatch(changePatientsListState(newPatientsList));
      }
    } catch (e) {
      console.log("changePatientIRB error: ", e);
    }
  };
}

export function changeNewPatient(editInput) {
  return async dispatch => {
    try {
      dispatch(changeLoadingChartsState(true));
      await API.graphql(
        graphqlOperation(_api.UpdateNewPatient(), { input: editInput })
      );
      dispatch(changeLoadingChartsState(false));

      dispatch(listICD10Codes);
      dispatch(listMedicalConditions);

      return editInput;
    } catch (e) {
      dispatch(changeLoadingChartsState(false));
      console.log("changeNewPatient error: ", e);
    }
  };
}

export function getAppointmented(inputData, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.setAppointmented(), { input: inputData })
      );
      if (newEvent) {
        self.setState({
          comment: "",
          value: "",
          showSuccessAlert: true,
          showFailedAlert: false
        });
        self.openNotificationWithIcon(
          "success",
          "Your message was sent successfully."
        );
        document.dispatchEvent(new Event("refreshMedicalDetail"));
      } else {
        self.openNotificationWithIcon("error", "Your message failed to send.");
      }
    } catch (err) {
      self.openNotificationWithIcon("error", "Your message failed to send.");
      self.setState({ showSuccessAlert: false, showFailedAlert: true });
      console.log("getAppointmented error: ", err);
    }
  };
}

export function dismissReviewTask(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.dismissReviewTask(), { input })
      );
      if (newEvent) {
        self.setState({
          comment: "",
          value: "",
          showSuccessAlert: true,
          showFailedAlert: false
        });
        self.openNotificationWithIcon(
          "success",
          "Your message was sent successfully."
        );
        document.dispatchEvent(new Event("refreshMedicalDetail"));
      } else {
        self.openNotificationWithIcon("error", "Your message failed to send.");
      }
    } catch (e) {
      self.openNotificationWithIcon("error", "Your message failed to send.");
      self.setState({ showSuccessAlert: false, showFailedAlert: true });
      console.log("dismissReviewTask error: ", e);
    }
  };
}
