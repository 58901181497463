import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { filter } from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { Control } from "react-keeper";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Button, Checkbox, Icon } from "semantic-ui-react";
import { getBillingList } from "../action/bliMonthyReportAction";
import aws_exports from "../aws-exports-siren";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import DetailsReoprt from "./detailsReport";
import "./userReportTable.css";
class userReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      date: new Date(),
      siteId: "",
      sirenIds: "",
      billingYear: new Date().getFullYear(),
      billingMonth: new Date().getMonth(),
      reportPeriod: new Date().getDate() <= 15 ? "firstHalf" : "SecondHalf",
      loadPage: 0,
      passBillingData: [],
      modalIsOpen: false,
      pageCount: 0,
      report99454: []
    };
    this.returnHome = this.returnHome.bind(this);
    this.loadMorePage = this.loadMorePage.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  loadMorePage(isVisible) {
    if (
      this.state.loadPage < this.props.billingTableList.length &&
      this.props.billingTableList.length > 0 &&
      isVisible === true
    ) {
      this.state.passBillingData.push(
        this.props.billingTableList[this.state.loadPage]
      );
      this.setState({
        loadPage: this.state.loadPage + 1
      });
    }
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  async downloadFromBackend() {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const url = window.location.href.split("?")[1];
      let params = queryString.parse(url);
      const siteId = params.siteId;
      const year = params.year;
      const month = params.month - 1;
      const endpointInfo = filter(aws_exports.aws_cloud_logic_custom, {
        name: "dev-pdf-exporter"
      });
      const pdfEndpoint = endpointInfo[0].endpoint;
      const downloadUrl = `${pdfEndpoint}/admin/pdf?siteId=${siteId}&month=${month}&year=${year}&token=${token}`;
      window.open(downloadUrl);
    } catch (e) {
      console.log("download pdf error: ", e);
    }
  }

  /**
   * Export Report PDF
   * @param tableLength table length
   * @param total charts length
   */
  download(tableLength, total) {
    let reportTableHeight = 0;
    let bodyHeight = 0;
    this.setState({ modalIsOpen: true });
    const self = this;
    html2canvas(document.getElementById("userReportTable"), {
      useCORS: true,
      logging: false,
      scale: 2
    }).then(function(canvas) {
      html2canvas(document.getElementById("userReportTable_report"), {
        useCORS: true,
        logging: false,
        scale: 2
      }).then(function(canvasTable) {
        html2canvas(document.getElementById("user_report_tbody"), {
          useCORS: true,
          logging: false,
          scale: 2
        }).then(function(canvasBody) {
          html2canvas(
            document.getElementById("detail_report_dowload_container"),
            {
              useCORS: true,
              logging: false,
              scale: 2
            }
          ).then(function(canvasCharts) {
            bodyHeight = canvasBody.height;
            reportTableHeight = canvasTable.height;
            var pdf = new jsPDF("p", "mm", "a4"); //A4纸，纵向
            var ctx = canvas.getContext("2d"),
              a4w = 190,
              a4h = 277, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
              // imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
              imgHeight = Math.ceil(canvasCharts.height / total), //按A4显示比例换算一页图像的像素高度(+1是为了防止画布大小正好等于图片大小时溢出导致crash)
              // imgTableHeight =
              //   reportTableHeight -
              //   bodyHeight +
              //   (bodyHeight * 20) / tableLength, //按A4显示比例换算一页图像的像素高度(+1是为了防止画布大小正好等于图片大小时溢出导致crash)

              imgTableHeight =
                reportTableHeight -
                bodyHeight +
                (bodyHeight *
                  Math.floor(
                    (((canvas.width * 277) / 190 -
                      reportTableHeight +
                      bodyHeight) *
                      tableLength) /
                      bodyHeight
                  )) /
                  tableLength,
              renderedHeight = 0,
              renderTableHeight = 0;
            ctx.mozImageSmoothingEnabled = false;
            ctx.webkitImageSmoothingEnabled = false;
            ctx.msImageSmoothingEnabled = false;
            ctx.imageSmoothingEnabled = false;
            ctx.scale(2, 2);

            pdf.page = 1;
            while (renderTableHeight < reportTableHeight) {
              // 这个-1时因为有时
              if (pdf.page > 1) {
                imgTableHeight =
                  (bodyHeight *
                    Math.floor(
                      (((canvas.width * 277) / 190) * tableLength) / bodyHeight
                    )) /
                  tableLength;
              }
              var page = document.createElement("canvas");
              page.width = canvas.width;
              page.height = Math.min(
                imgTableHeight,
                reportTableHeight - renderTableHeight
              ); // 可能内容不足一页

              // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
              page
                .getContext("2d")
                .putImageData(
                  ctx.getImageData(
                    0,
                    pdf.page > 1 ? renderTableHeight - 3 : renderTableHeight,
                    canvas.width,
                    pdf.page > 1
                      ? Math.min(
                          imgTableHeight,
                          reportTableHeight - renderTableHeight
                        ) + 3
                      : Math.min(
                          imgTableHeight,
                          reportTableHeight - renderTableHeight
                        )
                  ),
                  0,
                  0
                );
              pdf.setFontSize(10);
              pdf.text(204, 294, String(pdf.page)); //print number bottom right
              pdf.page++;
              pdf.addImage(
                page.toDataURL("image/jpeg", 1.0),
                "JPEG",
                10,
                10,
                a4w,
                (a4w * page.height) / page.width
              ); // 添加图像到页面
              // console.log(page.height, page.width, Math.min(a4h, a4w * page.height / page.width))

              renderTableHeight += imgTableHeight;
              if (renderTableHeight < reportTableHeight) {
                pdf.addPage(); // 如果后面还有内容，添加一个空页
              }
            }

            pdf.addPage();

            reportTableHeight += 70;
            // while (renderedHeight < canvas.height - reportTableHeight) {
            let printChart = 1;
            while (renderedHeight < canvasCharts.height) {
              // 这个-1时因为有时
              page = document.createElement("canvas");
              page.width = canvas.width;
              page.height =
                printChart === total
                  ? canvasCharts.height - renderedHeight - 70
                  : Math.min(imgHeight, canvasCharts.height - renderedHeight); // 可能内容不足一页

              // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
              page
                .getContext("2d")
                .putImageData(
                  ctx.getImageData(
                    0,
                    renderedHeight + reportTableHeight,
                    canvas.width,
                    printChart === total
                      ? canvasCharts.height - renderedHeight - 70
                      : Math.min(
                          imgHeight,
                          canvasCharts.height - renderedHeight
                        )
                  ),
                  0,
                  0
                );
              pdf.setFontSize(10);
              pdf.text(204, 294, String(pdf.page)); //print number bottom right
              pdf.page++;
              printChart++;
              pdf.addImage(
                page.toDataURL("image/jpeg", 1.0),
                "JPEG",
                10,
                10,
                a4w,
                a4h
                // Math.min(a4h, (a4w * page.height) / page.width)
              ); // 添加图像到页面
              // console.log(page.height, page.width, Math.min(a4h, a4w * page.height / page.width))

              renderedHeight += imgHeight;
              if (renderedHeight < canvasCharts.height) {
                pdf.addPage(); // 如果后面还有内容，添加一个空页
              }
            }
            let fileName =
              "Siren_MonthlyReport_" +
              self.state.siteId +
              "_" +
              moment(self.state.billingMonth, "M").format("MMM") +
              self.state.billingYear;
            pdf.save(`${fileName}.pdf`);
            self.setState({ modalIsOpen: false });
          });
        });
      });
    });
  }

  returnHome() {
    Control.go(
      "/BiMonthly?siteId=" +
        this.state.siteId +
        "&year=" +
        this.state.billingYear +
        "&month=" +
        this.state.billingMonth +
        "&reportPeriod=" +
        this.state.reportPeriod
    );
    this.setState({ passBillingData: [] });
  }
  async componentDidMount() {
    document.body.scrollTop = 0;
    if (window.location.href.includes("siteId")) {
      const { getBillingList } = this.props;
      const url = window.location.href.split("?")[1];
      let params = queryString.parse(url);
      const siteId = params.siteId;
      const sirenIDList = params.sirenIds;
      const year = params.year;
      const month = params.month - 1;
      const reportPeriod = params.reportPeriod;
      this.setState({
        siteId: siteId,
        billingYear: year,
        billingMonth: month + 1,
        sirenIds: sirenIDList,
        reportPeriod: reportPeriod
      });
      const listBillingValue = {
        siteId: siteId,
        sirenIds: sirenIDList,
        year: year,
        month: month,
        reportPeriod: reportPeriod
      };
      getBillingList(listBillingValue, this);
    }
  }
  render() {
    const today = moment().format("MMMM D, YYYY");
    const month = moment(this.state.billingMonth, "M").format("MMM");
    const endOfMonth = moment(
      this.state.billingYear + "-" + this.state.billingMonth
    )
      .endOf("month")
      .format("DD");
    // let filterBillingChart = this.props.billingTableList.filter(function(n) {
    //   return n.cptCode === "99454";
    // });
    return (
      <div className="docReport-container">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <Button basic id="return-to-device-list" onClick={this.returnHome}>
          <Icon name="reply" />
          Return to list
        </Button>
        <Button
          className="dowButton"
          basic
          // onClick={() =>
          //   this.download(
          //     this.props.billingTableList.length,
          //     filterBillingChart.length
          //   )
          // }
          onClick={() => this.downloadFromBackend()}
        >
          <Icon name="download" />
          Download Report
        </Button>
        <div id="userReportTable">
          <div id="userReportTable_report">
            <div className="report_table_box">
              <div className="user_report_title" style={{ fontSize: 8 }}>
                The information provided is only intended to provide information
                concerning usage of Siren products. Siren does not guarantee
                coverage of payment for any products. The ultimate
                responsibility for proper coding, satisfying reimbursement
                requirements, and obtaining reimbursement remains with the
                provider. Coding and coverage policies and guidelines are
                complex, can vary from one carrier or region to another, and are
                updated frequently. Providers should check with their local
                carriers or intermediaries often and should consult with
                counsel, a reimbursement specialist for coding, coverage or
                billing questions. Use of Siren products is subject to the Siren
                Terms of Use (
                <a href={"https://siren.care/terms-of-service"}>
                  https://siren.care/terms-of-service
                </a>
                ) and Privacy Policy (
                <a href={"https://siren.care/privacy-policy"}>
                  https://siren.care/privacy-policy
                </a>
                ).
              </div>
              <div className="user_report_title" style={{ marginTop: 40 }}>
                <strong>Summary</strong>
              </div>
              <div className="user_report_title">
                <strong>Report generated: </strong>
                {today}
              </div>
              <div className="user_report_title">
                <strong>
                  Site: {this.state.siteId}
                  {this.props.billingTableList.length > 0
                    ? " - " + this.props.billingTableList[0].siteName
                    : ""}
                </strong>
              </div>
              <div className="user_report_title_header">
                <strong>
                  Monthly User Reports: {month}{" "}
                  {this.state.reportPeriod === "firstHalf"
                    ? "1-15"
                    : this.state.reportPeriod === "SecondHalf"
                    ? "16-" + endOfMonth
                    : "1-" + endOfMonth}{" "}
                  , {this.state.billingYear}
                </strong>
              </div>
            </div>
            <table className="report_table">
              <thead>
                <tr>
                  <th className="user_report_table_th_first">
                    Siren ID + Patient Name
                  </th>
                  <th className="user_report_table_th">Doctor</th>
                  <th className="user_report_table_th">CPT Code</th>
                  <th className="user_report_table_th user_report_table_th_four">
                    Notes
                  </th>
                  <th className="user_report_table_th">Date of Service</th>
                  <th className="user_report_table_th">Report Page</th>
                  <th className="user_report_table_th">Billed</th>
                </tr>
              </thead>
              <tbody id="user_report_tbody">
                {this.props.billingTableList_99454 &&
                  this.props.billingTableList_99454.map((list, i) => {
                    const createdAt = list.createdAt
                      ? list.createdAt.split("T")[0]
                      : "";
                    const dispensedDay = list.dispensedDay
                      ? list.dispensedDay
                      : createdAt;
                    return (
                      <tr key={"list" + i}>
                        <td
                          className={
                            i < this.props.billingTableList_99454.length - 1 &&
                            list.sirenId ===
                              this.props.billingTableList_99454[i + 1].sirenId
                              ? "user_report_table_td"
                              : "user_report_table_td_solid"
                          }
                        >
                          {list.sirenId} - {list.givenName}
                        </td>
                        <td
                          className={
                            i < this.props.billingTableList_99454.length - 1 &&
                            list.sirenId ===
                              this.props.billingTableList_99454[i + 1].sirenId
                              ? "user_report_table_td"
                              : "user_report_table_td_solid"
                          }
                        >
                          {list.doctorName}
                        </td>
                        <td
                          className={
                            i < this.props.billingTableList_99454.length - 1 &&
                            list.sirenId ===
                              this.props.billingTableList_99454[i + 1].sirenId
                              ? "user_report_table_td"
                              : "user_report_table_td_solid"
                          }
                        >
                          {list.cptCode === "99457" ? "99457-8" : list.cptCode}
                        </td>
                        {list.cptCode === "99453" ? (
                          <td
                            className={
                              i <
                                this.props.billingTableList_99454.length - 1 &&
                              list.sirenId ===
                                this.props.billingTableList_99454[i + 1].sirenId
                                ? "user_report_table_td"
                                : "user_report_table_td_solid"
                            }
                          >
                            <div>
                              Set-up and patient education on the use of Siren
                              Socks and Monitoring System.
                            </div>
                            <div className="claim_details_box">
                              <div>
                                Date dispensed:
                                <strong className="claim_strong">
                                  {dispensedDay
                                    ? moment(dispensedDay).format("M/D/YYYY")
                                    : ""}
                                </strong>
                              </div>
                              <div>
                                16th day of wear:
                                <strong className="claim_strong">
                                  {list.billDay
                                    ? moment(list.billDay).format("M/D/YYYY")
                                    : ""}
                                </strong>
                              </div>
                            </div>
                          </td>
                        ) : list.cptCode === "99454" ? (
                          list.period99454Start && list.period99454End ? (
                            <td
                              className={
                                i <
                                  this.props.billingTableList_99454.length -
                                    1 &&
                                list.sirenId ===
                                  this.props.billingTableList_99454[i + 1]
                                    .sirenId
                                  ? "user_report_table_td"
                                  : "user_report_table_td_solid"
                              }
                            >
                              <div>
                                Remote data collection for Siren Socks, 30days.
                              </div>
                              <div className="claim_details_box">
                                <div>
                                  First day of 30-day period:
                                  <strong className="claim_strong">
                                    {list.period99454Start
                                      ? moment(list.period99454Start).format(
                                          "M/D/YYYY"
                                        )
                                      : ""}
                                  </strong>
                                </div>
                                <div>
                                  Last day of 30-day period:
                                  <strong className="claim_strong">
                                    {list.period99454End
                                      ? moment(list.period99454End).format(
                                          "M/D/YYYY"
                                        )
                                      : ""}
                                  </strong>
                                </div>
                                <div>
                                  Days worn during period:
                                  <strong className="claim_strong">
                                    {list.daysWorn}
                                  </strong>
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td
                              className={
                                i <
                                  this.props.billingTableList_99454.length -
                                    1 &&
                                list.sirenId ===
                                  this.props.billingTableList_99454[i + 1]
                                    .sirenId
                                  ? "user_report_table_td"
                                  : "user_report_table_td_solid"
                              }
                            >
                              <div>
                                Patient did not meet criteria for 99454.
                              </div>
                            </td>
                          )
                        ) : list.cptCode === "99457" ? (
                          <td
                            className={
                              i <
                                this.props.billingTableList_99454.length - 1 &&
                              list.sirenId ===
                                this.props.billingTableList_99454[i + 1].sirenId
                                ? "user_report_table_td"
                                : "user_report_table_td_solid"
                            }
                          >
                            <div>
                              Remote physiologic monitoring(see RPM Tracker
                              Form)
                            </div>
                            <div className="claim_details_box">
                              <div>
                                First day of 30-day period:
                                <strong className="claim_strong">
                                  {list.periodStdStart
                                    ? moment(list.periodStdStart).format(
                                        "M/D/YYYY"
                                      )
                                    : ""}
                                </strong>
                              </div>
                              <div>
                                Last day of 30-day period:
                                <strong className="claim_strong">
                                  {list.periodStdEnd
                                    ? moment(list.periodStdEnd).format(
                                        "M/D/YYYY"
                                      )
                                    : ""}
                                </strong>
                              </div>
                            </div>
                          </td>
                        ) : null}
                        <td
                          className={
                            i < this.props.billingTableList_99454.length - 1 &&
                            list.sirenId ===
                              this.props.billingTableList_99454[i + 1].sirenId
                              ? "user_report_table_td"
                              : "user_report_table_td_solid"
                          }
                        >
                          {moment(list.serviceDate).format("M/D/YYYY")}
                        </td>
                        <td
                          className={
                            i < this.props.billingTableList_99454.length - 1 &&
                            list.sirenId ===
                              this.props.billingTableList_99454[i + 1].sirenId
                              ? "user_report_table_td"
                              : "user_report_table_td_solid"
                          }
                        >
                          {list.cptCode === "99454" &&
                          this.state.pageCount > 0 &&
                          this.state.report99454.indexOf(list.sirenId) >= 0
                            ? "Page " +
                              (parseInt(this.state.pageCount) +
                                this.state.report99454.indexOf(list.sirenId))
                            : "N/A"}
                        </td>
                        <td
                          className={
                            i < this.props.billingTableList_99454.length - 1 &&
                            list.sirenId ===
                              this.props.billingTableList_99454[i + 1].sirenId
                              ? "user_report_table_td"
                              : "user_report_table_td_solid"
                          }
                          style={{ position: "relative" }}
                        >
                          <Checkbox name="selectSite" />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <DetailsReoprt billingTableList={this.props.billingTableList} />
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  billingTableList_99454: state.monthlyReportStore.billingTableList_99454,
  billingTableList: state.monthlyReportStore.billingTableList
});

const mapDispatchToProp = dispatch => ({
  getBillingList: (listBillingValue, self) =>
    dispatch(getBillingList(listBillingValue, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(userReportTable);
