import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import moment from "moment";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
import { Modal as AntdModal } from "antd";
import { changeUserBoxLIstState } from "./boxAction";
export function changeStatus(id, newstatus, self, sirenId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateSockStatus(), { input: newstatus })
      );
      if (newEvent) {
        _.isEmpty(id)
          ? getSockHomeStatus(sirenId, dispatch)
          : getSockStatus(id, newstatus.userId, dispatch);
        self.setState({ open: false });
      }
    } catch (err) {
      self.setState({ open: false });
      console.log("changeStatus exception: ", err);
    }
  };
}

export function updateSensorStatus(id, newstatus, self, sirenId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateSensorStatus(), { input: newstatus })
      );
      self.setState({ open: false });
      if (newEvent) {
        sirenId
          ? getSockHomeStatus(sirenId, dispatch)
          : getSockStatus(id, newstatus.userId, dispatch);
      }
    } catch (err) {
      self.setState({ open: false });
      console.log("changeSensorStatus exception: ", err);
    }
  };
}

async function getSockHomeStatus(sirenId, dispatch) {
  try {
    const patient = await API.graphql(
      graphqlOperation(_api.getSockBySirenId(), { sirenId })
    );
    if (patient) {
      patient.data.getPatientBySirenId.socks = patient.data.getPatientBySirenId.socks.sort(
        compareRegistered
      );
      if (
        patient.data.getPatientBySirenId.hasOwnProperty("appInfo") &&
        patient.data.getPatientBySirenId.appInfo.length > 0
      ) {
        patient.data.getPatientBySirenId.appInfo = patient.data.getPatientBySirenId.appInfo.sort(
          function(a, b) {
            return new moment(b.createdAt) - new moment(a.createdAt);
          }
        );
        dispatch(changeSockHomeStatus(patient.data.getPatientBySirenId));
      } else {
        dispatch(changeSockHomeStatus(patient.data.getPatientBySirenId));
      }
    }
  } catch (err) {
    console.log("getSockHomeStatus error: ", err);
  }
}
function changeSockHomeStatus(sockHomeStatus) {
  return {
    type: TYPES.SOCK_HOME_STATUS,
    text: sockHomeStatus
  };
}
function compareRegistered(a, b) {
  const akey = a.registered + a.boxId;
  const bkey = b.registered + b.boxId;
  if (akey > bkey) return -1;
  if (akey > bkey) return 1;
  return 0;
}
async function getSockStatus(id, userid, dispatch) {
  try {
    const patient = await API.graphql(
      graphqlOperation(_api.getSock(), { id, userid })
    );
    if (patient) {
      patient.data.getPatient.socks = patient.data.getPatient.socks.sort(
        compareRegistered
      );
      if (
        patient.data.getPatient.hasOwnProperty("appInfo") &&
        patient.data.getPatient.appInfo.length > 0
      ) {
        patient.data.getPatient.appInfo = patient.data.getPatient.appInfo.sort(
          function(a, b) {
            return new moment(b.createdAt) - new moment(a.createdAt);
          }
        );
        dispatch(changeSockStatus(patient.data.getPatient));
      } else {
        dispatch(changeSockStatus(patient.data.getPatient));
      }
      // dispatch(changeSockStatus(patient.data.getPatient));
    }
  } catch (err) {
    console.log("getSockStatus error: ", err);
  }
}
function changeSockStatus(sockStatus) {
  return {
    type: TYPES.SOCK_STATUS,
    text: sockStatus
  };
}
export function unlinkSock(userId, sockId, self, id) {
  return async dispatch => {
    const myInit = {
      queryStringParameters: {
        sock: sockId,
        userId: userId
      }
    };
    API.get("dev-trial-dashboard", "/dashboard/unregisterSock", myInit)
      .then(response => {
        self.setState({ open: false });
        self.props.sirenId
          ? getSockHomeStatus(self.props.sirenId, dispatch)
          : getSockStatus(id, userId, dispatch);
      })
      .catch(error => {
        console.log(error.response);
      });
  };
}

export function unlinkBox(userId, boxId, id) {
  return dispatch => {
    const myInit = {
      queryStringParameters: {
        box: boxId,
        userId: userId
      }
    };
    return API.get(
      "dev-trial-dashboard",
      "/dashboard/unregisterBox",
      myInit
    ).then(response => {
      getSockStatus(id, userId, dispatch);

      return response;
    });
  };
}

export function unlinkHub(userId, serial, self, id) {
  return async dispatch => {
    const myInit = {
      queryStringParameters: {
        serial: serial,
        userId: userId
      }
    };
    API.get("dev-iot-register-api", "/admin/unregisterHubAdmin", myInit)
      .then(response => {
        self.setState({ open: false });
        getSockStatus(id, userId, dispatch);
      })
      .catch(error => {
        console.log(error.response);
      });
  };
}
export function getrRemoteConnect(inputValue, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.hubRemoteConnect(), inputValue)
      );
      if (newEvent) {
        self.setState({
          server: "",
          port: "",
          name: "",
          status: "",
          open: false
        });
      }
    } catch (err) {
      console.log("remoteConnect error: ", err);
    }
  };
}

export function updateSockDFU(userId, serialNo, serialDFU) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateSockDFU(), {
          input: {
            userId,
            serialNo,
            serialDFU
          }
        })
      );
      if (newEvent) {
        setDfuSock(
          `Sock ${serialNo} will ${
            serialDFU === "NO" ? "not" : ""
          } upgrade Firmware when connected by app.`
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  };
}

function setDfuSock(content) {
  AntdModal.info({
    title: "Dfu Sock",
    content,
    centered: true
  });
}
