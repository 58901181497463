import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import ReactTable from "react-table";
import _ from "lodash";
import { Modal as AntdModal, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  removeLpnForPatient,
  updateLpnForPatients
} from "../action/lpnManagementAction";

/**
 *  Created by shengli.zhang on 4/2/21 4:35 PM
 */
const { Option } = Select;
const { confirm } = AntdModal;
class UpdateLpnForPatients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      patient: [],
      lpnInfo: {},
      sites: [],
      lpnList: [],
      selectedLpn: "",
      disableSubmit: false
    };
  }

  showConfirm = (value, lpnInfo, patientLoad, patientCount) => {
    confirm({
      title: "Oops",
      content: (
        <div>
          <strong>
            {lpnInfo.firstName} {lpnInfo.lastName}
          </strong>{" "}
          can be assigned <strong>{patientLoad}</strong> patients. Actually,{" "}
          <strong>{patientCount}</strong> patients were assigned.
          <br /> Are you sure you want to continue the assignment?
        </div>
      ),
      cancelText: "Cancel",
      onCancel: () => {
        this.setState({ selectedLpn: "" });
      },
      okText: "Sure",
      onOk: () => {
        const patient = this.state.patient.map(p => {
          return {
            ...p,
            lpn: lpnInfo ? `${lpnInfo.firstName} ${lpnInfo.lastName}` : ""
          };
        });
        this.setState({
          selectedLpn: value,
          patient
        });
      },
      centered: true
    });
  };

  handleOpen = () => {
    if (this.props.patients.length > 0) {
      const patient = this.props.listLpnPatients.map(p => {
        if (this.props.patients.includes(p.id)) {
          return p;
        }
        return null;
      });
      const sites = _.uniqBy(_.compact(patient), "siteId").map(p => {
        return p.siteId;
      });
      if (sites.length > 1) {
        AntdModal.warning({
          title: "Oops",
          content: `You can only choose one site to update LPN.`,
          centered: true
        });
        return;
      }
      const lpnList = this.props.listSirenStaff.map(t => {
        if (t.primarySites && !_.isEmpty(t.primarySites)) {
          const primarySite = JSON.parse(t.primarySites);
          if (
            primarySite.includes(sites[0]) &&
            t.id !== this.props.lpnInfo.id
          ) {
            return t;
          }
        }
        return null;
      });
      this.setState({
        patient: _.compact(patient),
        sites,
        open: true,
        lpnInfo: this.props.lpnInfo,
        lpnList: _.compact(lpnList)
      });
    } else {
      AntdModal.warning({
        title: "Oops",
        content: `You need to select at least one patient to update the LPN.`,
        centered: true
      });
    }
  };

  close = () => {
    this.setState({
      open: false,
      patient: [],
      lpnInfo: {},
      sites: [],
      lpnList: [],
      selectedLpn: "",
      disableSubmit: false
    });
  };

  showRemoveLpnWarn = () => {
    confirm({
      title: "Remove LPN from Patient",
      content: "Are you sure you want to remove LPN from these patients?",
      cancelText: "No",
      onCancel: () => {},
      okText: "Yes",
      onOk: () => {
        const patients = this.props.patients.map(p => {
          return { id: p };
        });
        this.setState({ disableSubmit: true });
        this.props.removeLpnForPatient(patients, this).finally(() => {
          this.props.refresh(this.props.patients);
          this.close();
        });
      },
      centered: true
    });
  };

  submit = () => {
    //update lpn for patients
    //this.props.patients: all patients with id ---[id1, id2, id2, ....]
    //this.state.selectLpn: update to LPN ---save to dynamodb
    if (_.isEmpty(this.state.selectedLpn)) {
      this.showRemoveLpnWarn();
    } else {
      const input = {
        patients: this.props.patients,
        LPN: this.state.selectedLpn
      };
      this.props.updateLpnForPatients(input, this).finally(() => {
        this.props.refresh(this.props.patients);
        this.close();
      });
    }
  };

  onCheckLpn = value => {
    if (_.isEmpty(value)) {
      const patient = this.state.patient.map(p => {
        return {
          ...p,
          lpn: ""
        };
      });
      this.setState({
        selectedLpn: "",
        patient
      });
    } else {
      const lpnInfo = _(this.props.listSirenStaff).find({ id: value });
      const patientLoad = lpnInfo.patientLoad;
      const patientCount = lpnInfo.patientCount + this.state.patient.length;
      if (patientCount <= parseInt(patientLoad)) {
        const patient = this.state.patient.map(p => {
          return {
            ...p,
            lpn: lpnInfo ? `${lpnInfo.firstName} ${lpnInfo.lastName}` : ""
          };
        });
        this.setState({
          selectedLpn: value,
          patient
        });
      } else {
        this.showConfirm(value, lpnInfo, patientLoad, patientCount);
      }
    }
  };

  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.handleOpen} style={{ marginLeft: 20 }}>
            Preview
          </Button>
        }
        open={this.state.open}
        className="add-box-form-container"
      >
        <Modal.Header>
          Edit Siren Staffing
          <CloseOutlined
            style={{ float: "right" }}
            onClick={e => this.close()}
          />
        </Modal.Header>
        <Modal.Content>
          <div>
            <div className="search_patient_container">
              <span className="search_label search_email_label">
                Update Siren Staff from:{" "}
                <strong>{`${this.state.lpnInfo.firstName} ${this.state.lpnInfo.lastName}`}</strong>{" "}
                to
              </span>
              <Select
                // mode="multiple"
                style={{ minWidth: 200, maxWidth: 400 }}
                placeholder="Please select LPN"
                onChange={this.onCheckLpn}
                value={this.state.selectedLpn}
              >
                <Option key={0} value={""}>
                  None
                </Option>
                {this.state.lpnList.map(lpn => (
                  <Option key={lpn.id} value={lpn.id}>
                    {lpn.firstName} {lpn.lastName}
                  </Option>
                ))}
              </Select>
            </div>
            <ReactTable
              data={
                this.state.patient &&
                this.state.patient.map(p => {
                  const givenName = `${p.firstName} ${p.lastName}`;
                  const LPNInfo = _(this.props.listSirenStaff).find({
                    id: p.LPN
                  });
                  const LPN = `${LPNInfo.firstName} ${LPNInfo.lastName}`;
                  return {
                    ...p,
                    givenName,
                    LPN
                  };
                })
              }
              columns={[
                {
                  Header: "SirenId",
                  accessor: "sirenId",
                  filterMethod: (filter, row) => {
                    return String(row[filter.id]).includes(filter.value);
                  }
                },
                {
                  Header: "SiteId",
                  accessor: "siteId"
                },
                {
                  Header: "Full Name",
                  accessor: "givenName",
                  filterMethod: (filter, row) => {
                    let rowfilterfullname = row._original.givenName
                      ? row[filter.id].toLowerCase()
                      : "";
                    let filterfullname = filter.value.toLowerCase();
                    return rowfilterfullname.includes(filterfullname);
                  }
                },
                {
                  Header: "From Nurse",
                  accessor: "LPN"
                },
                {
                  Header: "To Nurse",
                  accessor: "lpn"
                }
              ]}
              filterable
              defaultSorted={[{ id: "sirenId", desc: false }]}
              defaultPageSize={10}
              className="-striped -highlight"
              minRows={0}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.regiSuccess ? "Close" : "Cancel"}
          </Button>
          <Button
            color="green"
            onClick={e => this.submit()}
            disabled={this.state.disableSubmit}
          >
            <Icon name="checkmark" />
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  listLpnPatients: state.patientsListStore.listLpnPatients,
  listSirenStaff: state.patientsListStore.listSirenStaff
});
const mapDispatchToProp = dispatch => ({
  updateLpnForPatients: (input, self) =>
    dispatch(updateLpnForPatients(input, self)),
  removeLpnForPatient: (patients, self) =>
    dispatch(removeLpnForPatient(patients, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(UpdateLpnForPatients);
