import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import {
  getImageFromS3,
  getPhotoReviews,
  updatePhotoState
} from "../action/patientAction";
// import { Modal, Button, Icon } from "semantic-ui-react";
import "./ViewPhoto.css";
class ViewPhoto extends Component {
  constructor(props) {
    super(props);
    this.handleRadio = this.handleRadio.bind(this);
    this.state = {
      radioGroup: {
        state1: false,
        state2: false
      },
      leftImageUrl: "",
      rightImageUrl: "",
      retake: false,
      resolve: false,
      taskTime: "",
      open: false,
      photoReviewStatus: this.props.status,
      comments: ""
    };
  }

  componentDidMount() {
    if (this.state.photoReviewStatus === "RESOLVED") {
      this.setState({ resolve: true, retake: false });
    } else if (
      this.state.photoReviewStatus === "PHOTO_UPLOADED" ||
      this.state.photoReviewStatus === "NEW"
    ) {
      this.setState({ resolve: false, retake: false });
    } else {
      this.setState({ resolve: false, retake: true });
    }
  }

  changeComments = event => {
    this.setState({ comments: event.target.value });
  };

  handleRadio(event) {
    let obj = Object.assign(this.state.radioGroup);
    obj[event.target.value] = event.target.checked;
    this.setState({ radioGroup: obj });
  }

  handleRetakeRadio = () => {
    this.setState({ retake: true, resolve: false });
  };

  handleResolveRadio = () => {
    this.setState({ resolve: true, retake: false });
  };

  handleOpen = () => {
    const { getPhotoReview, getImageFromS3 } = this.props;
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    const inputData = {
      userid: this.props.userId,
      start: moment(startDate).format("YYYY-MM-DD"),
      end: moment(endDate).format("YYYY-MM-DD")
    };
    getImageFromS3(this, this.props.userId);
    getPhotoReview(inputData, this);
  };
  close = () => this.setState({ open: false });
  submit = () => {
    const { updatePhotoState } = this.props;
    const input = {
      userId: this.props.userId,
      taskTime: this.state.taskTime,
      status: this.state.resolve ? "RESOLVED" : "REOPEN",
      comment: this.state.comments
    };
    if (this.state.retake || this.state.resolve) {
      updatePhotoState(input, this);
    }
  };

  render() {
    return (
      <Modal
        trigger={
          <Button size="mini" onClick={this.handleOpen}>
            {this.state.photoReviewStatus === "PHOTO_UPLOADED" ||
            this.state.photoReviewStatus === "NEW"
              ? "View Photos"
              : this.state.photoReviewStatus === "RESOLVED"
              ? "Medical Review"
              : "Retake"}
          </Button>
        }
        open={this.state.open}
        className="reset-image"
      >
        <Modal.Content>
          <div>
            <div className="ImageBox">
              <div className="leftImageBox">
                <Image
                  className="viewImage"
                  src={this.state.leftImageUrl}
                  alt=""
                />
              </div>
              <div className="rightImageBox">
                <Image
                  className="viewImage"
                  src={this.state.rightImageUrl}
                  alt=""
                />
              </div>
              <div style={{ clear: "both" }} />
            </div>
            <div className="radio">
              <input
                type="radio"
                name="radioRetake"
                value="state1"
                checked={this.state.retake}
                onChange={this.handleRetakeRadio.bind(this)}
              />
              <label className="radioLabel">Retake Photo</label>
            </div>
            <div className="radio">
              <input
                type="radio"
                name="radioResolve"
                value="state2"
                checked={this.state.resolve}
                onChange={this.handleResolveRadio.bind(this)}
              />
              <label className="radioLabel">Medical Review</label>
            </div>
            {this.state.retake ? (
              <label className="commentLabel">
                Comments:
                <textarea
                  className="commentInput"
                  value={this.state.comments}
                  onChange={this.changeComments}
                />
              </label>
            ) : null}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <button onClick={e => this.submit()} className="submitbutton">
            Submit
          </button>
          <button onClick={e => this.close()} className="closebutton">
            <Icon name="remove" className="closeicon" />
          </button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({
  getPhotoReview: (inputDate, self) =>
    dispatch(getPhotoReviews(inputDate, self)),
  updatePhotoState: (input, self) => dispatch(updatePhotoState(input, self)),
  getImageFromS3: (self, userId) => dispatch(getImageFromS3(self, userId))
});
export default connect(mapStateToProp, mapDispatchToProp)(ViewPhoto);
