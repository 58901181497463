import React, { Component } from "react";
import Slider from "react-slick";
import Zmage from "react-zmage";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getFootPhotos } from "../../action/patientAction";
import { Empty } from 'antd';
import { Loader } from 'semantic-ui-react'
import "./Photo.css";
import PatientDetailUrlContext from "./PatientDetailUrlContext";

export default class PhotoGallery extends Component {

  static contextType = PatientDetailUrlContext;

  state = {
    images: [],
    index: 0,
    loading: true
  }

  componentDidMount() {
    this.lazyLoadPhotos()
    this.oldContext = this.context
  }

  componentDidUpdate() {
    if (this.oldContext !== this.context) {
      if (this.oldContext.param.userId !== this.context.param.userId) {
        this.lazyLoadPhotos()
      }
      this.oldContext = this.context
    }
  }

  lazyLoadPhotos = () => {
    this.setState({
      loading: true
    })
    getFootPhotos(this.context.param.userId)
      .then(images => this.setState({images}))
      .finally(() => this.setState({ loading: false }))
  }

  getImageList() {
    return this.state.images.map(item => ({
      src: item.uri,
      alt: item.date,
      text: item.date
    }))
  }

  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow:
        this.state.images.length >= 6 ? 6 : this.state.images.length,
      slidesToScroll: 1
    };
    return (
      <div className="photoBox" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      {
        this.state.loading ? (<Loader active inline='centered'>Loading</Loader>) : (
          this.state.images.length ? (
            <div style={{width: "100%"}}>
            <Slider {...settings}>
              {this.state.images.map((img, i) => (
                <div
                  className={
                    this.state.images.length >= 2 ? "swiperBox" : "swiperBoxNew"
                  }
                  key={"img" + i}
                >
                  <p className="swiperP">Updated:{img.date}</p>
                  <div className="totalbox">
                    <Zmage
                      crossOrigin="anonymous"
                      src={img.uri}
                      alt={"no img"}
                      className="swiperimg"
                      set={this.getImageList()}
                      defaultPage={i}
                    />
                  </div>
                </div>
              ))}
            </Slider>
            </div>
          ) : (
            <Empty description="No photos uploaded" />
          )
        )
      }
      </div>
    );
  }
}
