import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
import { Modal as AntdModal } from "antd";
import moment from "moment";
import { isToday } from "../constant/TimeConversion";
import zlib from "react-zlib-js";
import Buffer from "react-zlib-js/buffer";
/**
 *  Created by shengli.zhang on 12/27/21 3:41 PM
 */

export function listPillBottles(self, pillBottleId) {
  return async dispatch => {
    try {
      self.setState({ open: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.listPillBottles())
      );
      dispatch(listPillBottleChanged(newEvent.data.listPillBottles.items));
      self.setState({ open: false });
    } catch (e) {
      self.setState({ open: false });
      console.log(`get list pill bottles error: ${e}`);
    }
  };
}

export function updateListPillBottle(listPillBottle) {
  return dispatch => {
    dispatch(listPillBottleChanged(listPillBottle));
  };
}

function listPillBottleChanged(listPillBottles) {
  return {
    type: TYPES.PILL_BOTTLE_LIST,
    text: listPillBottles
  };
}

export function addPillBottle(input, self) {
  return async dispatch => {
    try {
      self.setState({ disableCreate: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.createPillBottle(), { input })
      );
      if (newEvent && newEvent.data.createPillBottle.status) {
        self.setState({
          open: false,
          mac: "",
          authCode: "",
          disableCreate: false
        });
        self.props.refreshPillBottleList();
      } else {
        self.setState({
          disableCreate: false
        });
        AntdModal.warning({
          title: "Add Pill Bottle Failed",
          content: JSON.stringify(newEvent),
          centered: true
        });
      }
    } catch (e) {
      self.setState({
        disableCreate: false
      });
      AntdModal.warning({
        title: "Add Pill Bottle Failed",
        content: `${JSON.stringify(e)}`,
        centered: true
      });
    }
  };
}

export function listPatientPillBottleData(self, id, userId, dateRender) {
  return async dispatch => {
    try {
      self.openModal();
      let start = dateRender ? dateRender[0] : null;
      let end = dateRender ? dateRender[1] : null;
      let startDate = moment(start).format("YYYY-MM-DD");
      let endDate = moment(end)
        .add(1, "days")
        .format("YYYY-MM-DD");
      let params = {
        id,
        userId,
        startDate,
        endDate
      };
      let DELAY = moment(endDate) - moment(startDate);
      let reg = new RegExp('"', "g");
      convertPositionPillBottle([], dispatch, DELAY, self, startDate);
      const newEvent = await API.graphql(
        graphqlOperation(_api.listPatientPillBottleData(), params)
      );
      self.closeModal();
      const deflateDate = newEvent.data.getPatient.pillBottleData.replace(
        reg,
        ""
      );
      const inflateData = zlib
        .inflateSync(new Buffer(deflateDate, "base64"))
        .toString();
      let positionPillBottle = [];
      _.forEach(inflateData.split("|"), function(item) {
        let keyDataRaw = [
          "usertime",
          "gsensorx",
          "gsensory",
          "gsensorz",
          "onoff"
        ];

        let pillBottleData = {};

        item.split(",").forEach((subItem, index) => {
          pillBottleData[keyDataRaw[index]] = subItem;
        });

        let objPositionPillBottle = {};

        Object.assign(objPositionPillBottle, {
          usertime: pillBottleData.usertime,
          gsensorx: pillBottleData.gsensorx,
          gsensory: pillBottleData.gsensory,
          gsensorz: pillBottleData.gsensorz,
          onoff: pillBottleData.onoff
        });
        positionPillBottle.push(objPositionPillBottle);
      });
      convertPositionPillBottle(
        positionPillBottle,
        dispatch,
        DELAY,
        self,
        startDate
      );
    } catch (e) {
      self.closeModal();
      console.log("get pill bottle data error: ", e);
    }
  };
}

function convertPositionPillBottle(
  positionPillBottle,
  dispatch,
  delay,
  self,
  startTime
) {
  let arrayB = [];
  let DEFAULT_INTEVER = 300 * 1000;
  for (let i = 0; i < positionPillBottle.length; i++) {
    if (i === 0) {
      if (
        moment(positionPillBottle[i].usertime.replace("Z", "")) -
          moment(startTime) <
        2 * DEFAULT_INTEVER
      ) {
        let obj = {
          usertime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
          gsensorx: "-",
          gsensory: "-",
          gsensorz: "-",
          onoff: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(positionPillBottle[i].usertime.replace("Z", "")) -
            moment(startTime)) /
            DEFAULT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startTime) + DEFAULT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            gsensorx: "-",
            gsensory: "-",
            gsensorz: "-",
            onoff: "-"
          };
          arrayB.push(obj);
        }
      }
      arrayB.push(positionPillBottle[0]);
    } else {
      if (
        moment(positionPillBottle[i].usertime) -
          moment(positionPillBottle[i - 1].usertime) <
        2 * DEFAULT_INTEVER
      ) {
        arrayB.push(positionPillBottle[i]);
      } else {
        let intever = Math.floor(
          (moment(positionPillBottle[i].usertime) -
            moment(positionPillBottle[i - 1].usertime)) /
            DEFAULT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(positionPillBottle[i - 1].usertime.replace("Z", "")) +
                DEFAULT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            gsensorx: "-",
            gsensory: "-",
            gsensorz: "-",
            onoff: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(positionPillBottle[i]);
      }
    }
  }
  if (positionPillBottle.length > 0) {
    let inteverSub =
      Math.floor(
        (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
          moment(
            positionPillBottle[positionPillBottle.length - 1].usertime.replace(
              "Z",
              ""
            )
          )
      ) / DEFAULT_INTEVER;
    for (let j = 0; j < inteverSub - 1; j++) {
      let obj = {
        usertime: moment(
          moment(
            positionPillBottle[positionPillBottle.length - 1].usertime.replace(
              "Z",
              ""
            )
          ) +
            DEFAULT_INTEVER * j
        ).format("YYYY-MM-DDTHH:mm:ss"),
        gsensorx: "-",
        gsensory: "-",
        gsensorz: "-",
        onoff: "-"
      };
      arrayB.push(obj);
    }
    dispatch(changePositionPillBottleStatus(arrayB));
  } else {
    let inteverSub =
      Math.floor(
        (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
          moment(startTime + "00:00:00")
      ) / DEFAULT_INTEVER;
    for (let j = 0; j < inteverSub - 1; j++) {
      let obj = {
        usertime: moment(
          moment(startTime + "00:00:00") + DEFAULT_INTEVER * j
        ).format("YYYY-MM-DDTHH:mm:ss"),
        gsensorx: "-",
        gsensory: "-",
        gsensorz: "-",
        onoff: "-"
      };
      arrayB.push(obj);
    }
    dispatch(changePositionPillBottleStatus(arrayB));
  }
}

function changePositionPillBottleStatus(position) {
  return {
    type: TYPES.POSITION_PILL_BOTTLE,
    text: position
  };
}

export function showClosePillBottleData() {
  return {
    type: TYPES.SHOW_CLOSE_PILL_BOTTLE_DATA
  };
}

export function getPillBottleDateWorn(
  id,
  userid,
  startDate,
  endDate,
  self,
  needClear
) {
  return async dispatch => {
    try {
      if (needClear) {
        dispatch(changePillBottleDateWorn([]));
      }
      const newEvent = await API.graphql(
        graphqlOperation(_api.getPillBottleTimeUsage(), {
          id,
          userid,
          startDate,
          endDate
        })
      );
      if (newEvent) {
        let date_worn = _.clone(self.props.pillBottleDateWorn);
        const history_worn_date = date_worn.map(p => {
          return p.date;
        });
        if (newEvent.data.getPatient.pillBottleTimeUsage.length > 0) {
          _.forEach(newEvent.data.getPatient.pillBottleTimeUsage, function(
            item
          ) {
            if (!history_worn_date.includes(item.date)) {
              date_worn.push(item);
            }
          });
        }
        dispatch(
          changePillBottleDateWorn(_.orderBy(date_worn, ["date"], ["asc"]))
        );
      }
    } catch (e) {
      console.log("getPillBottleDateWorn error: ", e);
    }
  };
}

function changePillBottleDateWorn(pillBottleDateWorn) {
  return {
    type: TYPES.PILL_BOTTLE_DATE_WORN,
    text: pillBottleDateWorn
  };
}
