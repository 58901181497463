import React, { memo } from "react";
/**
 *  Created by shengli.zhang on 3/9/21 11:13 AM
 */

function compareEMR(a, b) {
  if (a.toLowerCase() < b.toLowerCase()) return -1;
  if (a.toLowerCase() > b.toLowerCase()) return 1;
  return 0;
}

export const EMRList = [
  "TalkTextMD",
  "RXNT",
  "DrChrono EHR",
  "Kareo",
  "Compulink",
  "PrognoCIS",
  "CareCloud",
  "Benchmark Systems",
  "AllegianceMD",
  "ChartLogic",
  "Medics EHR",
  "Sevocity",
  "WRS Health",
  "Claimocity",
  "Exscribe",
  "PracticeSuite",
  "NueMD",
  "Praxis",
  "Cerner",
  "Power Diary",
  "Intergy Greenway Health",
  "75Health",
  "Office Ally",
  "CureMD",
  "e-MDs",
  "PracticeEHR",
  "Treat",
  "Practice Mate",
  "Medisoft Clinical",
  "ReLiMed",
  "Insta",
  "TM3",
  "Teladoc",
  "Meditech EHR",
  "Medesk",
  "Chiron Health",
  "Simple Interact",
  "McKesson Practice Choice",
  "FollowMyHealth",
  "ChartPertfect EHR",
  "Harris CareTracker",
  "Forcura",
  "Cliniko",
  "Padekal",
  "DrFirst Rcopia",
  "SpringCharts EMR",
  "InputHealth",
  "MedWorxs Evolution",
  "eThomas",
  "CyChart",
  "Uniwide",
  "WEBeDoctor",
  "Oscar EMR",
  "Practice Partner",
  "DOX Podiatry",
  "Medfiles OHS",
  "Meva",
  "OpenMRS",
  "Absolute EMR",
  "American Well",
  "OpenPM",
  "Medfusion",
  "NHCircle",
  "Medrium",
  "Dociss",
  "SammyEHR",
  "Meddbase",
  "Perfect Care EHR",
  "Patient Chart Manager EHR",
  "Agastha EHR",
  "DriCloud",
  "eHealthFlex",
  "Enablemypractice",
  "Intuitive Medical Documents(IMD)",
  "Mediportal",
  "QNotes",
  "Triarq Complete",
  "Unicharts",
  "Module MD",
  "Healthland Centriq",
  "MDoffice Manager",
  "Medlium",
  "Opal-EHR",
  "Physimed EMR",
  "Practice 1st",
  "Practice Tracker",
  "Q.D.Clinical EMR",
  "RelayClinical EHR",
  "Simplify ASC",
  "TCPL HMS",
  "TrakCare",
  "Win Apps",
  "Athena",
  "Practice Fusion",
  "Allscripts",
  "ECW",
  "Epic",
  "TalkEHR",
  "TRAKnet",
  "Advanced MD",
  "NextGen",
  "Salesforce",
  "No EMR"
].sort(compareEMR);
