import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "semantic-ui-react";
import "../MedicalDashboard/medicaldetails/PatientInfo.css";
import "../MedicalDashboard/medicaldetails/SirenNotes.css";
import queryString from "query-string";
import moment from "moment";
import { getTagWeekWorn } from "../action/tagAction";
import _ from "lodash";
import TagSource from "./tagSourceEdit";
class TagActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      userId: "",
      id: ""
    };
  }
  componentDidMount() {
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const userId = params.userId;
    const id = params.id.replace(" ", "+");
    this.setState({ userId, id });
    const startDate = moment()
      .add(-7, "days")
      .format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    this.props.getTagWeekWorn(id, userId, startDate, endDate, this);
  }
  render() {
    return (
      <div
        className="sym_box"
        style={{
          marginLeft: 60,
          height: 420
        }}
      >
        <div className="title" style={{ textAlign: "center", height: 30 }}>
          OVERVIEW
        </div>
        <div
          className="patientContent"
          style={{
            boxShadow: "1px 1px 4px 0 rgba(0, 0, 0, 0.15)",
            borderRadius: "5px"
          }}
        >
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{ width: 150, textAlign: "center", height: 50 }}
                >
                  TAG SOURCE
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center", width: 170 }}>
                  REGISTRATION DATE
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center", width: 220 }}>
                  AVERAGE DAYS WORN
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body
              style={{
                height: this.props.tagTimeWornByWeek.length > 0 ? 338 : 0
              }}
            >
              {this.props.tagTimeWornByWeek.length > 0 &&
                this.props.tagTimeWornByWeek.map(item => {
                  return (
                    <Table.Row key={"tag-activation-list"}>
                      <Table.Cell style={{ textAlign: "Center" }}>
                        <div style={{ display: "flex", paddingLeft: 30 }}>
                          {item.tagSource}
                          <TagSource
                            tagSource={item.tagSource}
                            tagId={item.tagId}
                          />
                        </div>
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "Center" }}>
                        {item.activationDate}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "Center" }}>
                        {`${item.aveDaysWorn} of 7 days`}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  tagTimeWornByWeek: state.patientsListStore.tagTimeWornByWeek
});

const mapDispatchToProp = dispatch => ({
  getTagWeekWorn: (id, userid, startDate, endDate, self) =>
    dispatch(getTagWeekWorn(id, userid, startDate, endDate, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(TagActivation);
