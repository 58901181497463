import React, { Component } from "react";
import { connect } from "react-redux";
import { getChartPositionOptions } from "./pillBottleChartOptions";
import ReactEcharts from "echarts-for-react";

class pillBottleCharts extends Component {
  onChartReady = echarts => {
    // console.log("echart is ready", echarts);
  };

  render() {
    return (
      <div className="charts_container">
        <ReactEcharts
          option={getChartPositionOptions(
            this.props.positionPillBottle,
            this.props.showClosePillBottle
          )}
          style={{ height: 400 }}
          lazyUpdate={true}
          onChartReady={this.onChartReady}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  positionPillBottle: state.patientsListStore.positionPillBottle,
  showClosePillBottle: state.patientsListStore.showClosePillBottle
});
export default connect(mapStateToProp, null)(pillBottleCharts);
