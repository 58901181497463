import React from "react";
import { Tabs } from "antd";
import AdminList from "./AdminList";
import AdminDeletedList from "./AdminDeletedList";

/**
 *  Created by shengli.zhang on 5/17/21 5:18 PM
 */
const { TabPane } = Tabs;
export default function AdminUserManagement(props) {

  return (
    <div className="boxlistcontainer">
      <Tabs style={{ textAlign: "left" }}>
        <TabPane tab="Active" key="1">
          <AdminList />
        </TabPane>
        <TabPane tab="Deleted" key="2">
          <AdminDeletedList />
        </TabPane>
      </Tabs>
    </div>
  )

}

