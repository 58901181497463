import React, { Component } from "react";
import { Popup } from "semantic-ui-react";
import moment from "moment";
import ReviewTaskFootSvg from "./reviewTaskFootSvg";
/**
 *  Created by shengli.zhang on 4/30/21 2:11 PM
 */

export default class ReviewTaskDataUpdatePop extends Component {
  render() {
    return (
      <div>
        <Popup
          trigger={
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ width: 40 }}>
                  <strong>{moment(this.props.taskTime).format("M/D")}</strong>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <div style={{ fontSize: 15 }}>
                    <strong>{this.props.status}</strong>
                  </div>
                  {this.props.diff.map((s, index) => {
                    return (
                      <div style={{ fontSize: 14 }} key={index}>
                        {s.old} to {s.new}. {s.foot} {s.key}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          }
          position="bottom center"
          hoverable
          flowing
          style={{ backgroundColor: "#F1F1F1", width: 450 }}
        >
          <Popup.Header
            style={{
              marginTop: 5,
              fontSize: 16
            }}
          >
            Foot status updated
          </Popup.Header>
          <Popup.Content>
            <div style={{ display: "flex", marginTop: 5 }}>
              <div>
                <div style={{ textAlign: "center" }}>
                  Reported on {moment(this.props.oldStatusDate).format("M/D")}
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    textAlign: "center",
                    marginTop: 5
                  }}
                >
                  <div className="title" style={{ fontSize: 6 }}>
                    FOOT WARNING STATUS
                  </div>
                  <ReviewTaskFootSvg footStatus={this.props.oldStatus} />
                  <div className="footTitle_box">
                    <div
                      className="right_footTitle"
                      style={{ fontSize: 12, marginLeft: "10%" }}
                    >
                      Bottom Right
                    </div>
                    <div
                      className="left_footTitle"
                      style={{ fontSize: 12, marginRight: "14%" }}
                    >
                      Bottom Left
                    </div>
                    <div style={{ clear: "both" }} />
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: 10 }}>
                <div style={{ textAlign: "center" }}>
                  Updated on {moment(this.props.newStatusDate).format("M/D")}
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    textAlign: "center",
                    marginTop: 5
                  }}
                >
                  <div className="title" style={{ fontSize: 6 }}>
                    FOOT WARNING STATUS
                  </div>
                  <ReviewTaskFootSvg footStatus={this.props.newStatus} />
                  <div className="footTitle_box">
                    <div
                      className="right_footTitle"
                      style={{ fontSize: 12, marginLeft: "10%" }}
                    >
                      Bottom Right
                    </div>
                    <div
                      className="left_footTitle"
                      style={{ fontSize: 12, marginRight: "14%" }}
                    >
                      Bottom Left
                    </div>
                    <div style={{ clear: "both" }} />
                  </div>
                </div>
              </div>
            </div>
          </Popup.Content>
        </Popup>
      </div>
    );
  }
}
