import _ from "lodash";
import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Checkbox } from "semantic-ui-react";
import {
  changeDoctorSelect,
  changeReportList,
  getReportStaffList,
  resetAllReport,
  saveReport,
  updateAutoDoctorReport
} from "../action/doctorReportAction";
import { getlistClinicSites, updateSiteId } from "../action/registerAction";
import Progress from "../home/progress";
import "./ReportSite.css";
import ReportMenu from "./reportMenu";
Modal.setAppElement("div");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};
class ReportSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
    this.handleSave = this.handleSave.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleSave() {
    const { saveReport } = this.props;
    saveReport(this.props.reportList, this);
  }

  handleSiteCheck(siteId) {
    const { changeReportList } = this.props;
    changeReportList(this, siteId);
  }

  handleAutoReportChanged(siteId, serviceDate, autoDoctorReport) {
    const updateSiteInput = {
      input: {
        siteId,
        serviceDate: _.isEmpty(serviceDate) ? "THE_16TH_DAY" : serviceDate,
        autoDoctorReport
      }
    };
    const { updateSiteId } = this.props;
    updateSiteId(updateSiteInput, this);
  }

  handleDocCheck(siteId, staff) {
    const { changeDoctorSelect } = this.props;
    changeDoctorSelect(this, siteId, staff.email);
  }
  componentDidMount() {
    const { getlistClinicSites, getlistStaff } = this.props;
    getlistClinicSites();
    getlistStaff();
  }
  render() {
    const { updateAutoDoctorReport } = this.props;
    return (
      <div className="boxlistcontainer">
        <ReportMenu />
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <ReactTable
          data={
            this.props.clinicSites &&
            this.props.clinicSites.map(p => {
              return {
                ...p
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              columns: [
                {
                  Header: "Site Id",
                  accessor: "siteId",
                  filterMethod: (filter, row) =>
                    String(row[filter.id]).includes(filter.value)
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Auto Report",
                  accessor: "autoDoctorReport",
                  sortable: false,
                  filterable: false,
                  Cell: props => {
                    return (
                      <Checkbox
                        onChange={(e, data) =>
                          this.handleAutoReportChanged(
                            props.original.siteId,
                            props.original.serviceDate,
                            !(props.original.autoDoctorReport === true)
                          )
                        }
                        checked={props.original.autoDoctorReport === true}
                      />
                    );
                  }
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Name",
                  accessor: "name",
                  filterMethod: (filter, row) => {
                    let rowfilterfullname = row._original.name
                      ? row[filter.id].toLowerCase()
                      : "";
                    let filterfullname = filter.value.toLowerCase();
                    return rowfilterfullname.includes(filterfullname);
                  }
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Doctor",
                  style: { overflow: "auto", textAlign: "left" },
                  accessor: "",
                  sortable: false,
                  filterable: false,
                  Cell: props => {
                    return (
                      <div>
                        {/*<Checkbox*/}
                        {/*  onChange={(e, data) =>*/}
                        {/*    this.handleSiteCheck(props.original.siteId)*/}
                        {/*  }*/}
                        {/*  className="check_all_box"*/}
                        {/*  checked={this.props.reportList.hasOwnProperty(*/}
                        {/*    props.original.siteId*/}
                        {/*  )}*/}
                        {/*  name="selectSite"*/}
                        {/*  label="All"*/}
                        {/*/>*/}
                        <div className="checkbox_line" />
                        {this.props.listStaff.map((staff, i) => {
                          if (
                            staff.siteId
                              .split("-")
                              .indexOf(props.original.siteId) !== -1
                          ) {
                            const idName = "Setting_Checkbox" + i;
                            return (
                              <div key={"staff-" + i}>
                                <Checkbox
                                  id={idName + props.row._index}
                                  className="check_group_box"
                                  checked={
                                    !!(
                                      this.props.reportList.hasOwnProperty(
                                        props.original.siteId
                                      ) &&
                                      this.props.reportList[
                                        props.original.siteId
                                      ].includes(staff.email)
                                    )
                                  }
                                  onChange={(e, data) => {
                                    if (
                                      !!(
                                        this.props.reportList.hasOwnProperty(
                                          props.original.siteId
                                        ) &&
                                        this.props.reportList[
                                          props.original.siteId
                                        ].includes(staff.email)
                                      )
                                    ) {
                                      updateAutoDoctorReport(
                                        "REMOVE",
                                        props.original.siteId,
                                        staff.email,
                                        this
                                      );
                                    } else {
                                      updateAutoDoctorReport(
                                        "ADD",
                                        props.original.siteId,
                                        staff.email,
                                        this
                                      );
                                    }
                                    // this.handleDocCheck(
                                    //   props.original.siteId,
                                    //   staff.email
                                    // );
                                  }}
                                  name="selectDoc"
                                  label={
                                    staff.isPrescribing
                                      ? `Dr.  ${staff.firstName} ${staff.lastName}`
                                      : `${staff.firstName} ${staff.lastName}`
                                  }
                                />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    );
                  }
                }
              ]
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "siteId",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
        {/*<div className="add_import_box" style={{ textAlign: "center" }}>*/}
        {/*  <Button size="small" onClick={this.handleSave}>*/}
        {/*    Save*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>
    );
  }
}
const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  listStaff: state.patientsListStore.listStaff,
  reportList: state.reportListStore.reportList
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getlistStaff: () => dispatch(getReportStaffList()),
  resetAllReport: () => dispatch(resetAllReport()),
  changeReportList: (self, siteId) => dispatch(changeReportList(self, siteId)),
  changeDoctorSelect: (self, siteId, doctorId) =>
    dispatch(changeDoctorSelect(self, siteId, doctorId)),
  saveReport: (reportList, self) => dispatch(saveReport(reportList, self)),
  updateSiteId: (updateSiteInput, self) =>
    dispatch(updateSiteId(updateSiteInput, self)),
  updateAutoDoctorReport: (action, siteId, doctorId, self) =>
    dispatch(updateAutoDoctorReport(action, siteId, doctorId, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(ReportSetting);
