export const PATIENTS_LIST = "patients_list";
export const PATIENTS_STATUS = "patients_status";
export const SOCK_STATUS = "sock_status";
export const SOCK_HOME_STATUS = "sock_home_status";
export const PATIENTS_STATUS_DETAIL = "patients_status_detail";
export const AUTH_GROUP = "auth_group";
export const USER_BOX_LIST_NEW = "user_box_list_new";
export const BOX_LIST_NEW = "box_list_new";
export const DOCTOR_DETAILS = "doctor_details";
export const INITIALIZED_NOTE = "initialized_note";
export const SOCK_CHART_DATA = "sock_chart_data";
export const DELTA_WASHED = "delta_washed";
export const DELTA_AUTO_WASHED = "delta_auto_washed";
export const LEFT_FOOT_TEMP_WASHED = "left_foot_temp_washed";
export const RIGHT_FOOT_TEMP_WASHED = "right_foot_temp_washed";
export const LEFT_FOOT_TEMP_MARKED = "left_foot_temp_marked";
export const RIGHT_FOOT_TEMP_MARKED = "right_foot_temp_marked";
export const STEPS_TAG = "steps_tag";
export const POSITION_TAG = "position_tag";
export const POSITION_PILL_BOTTLE = "position_pill_bottle";
export const SHOW_CLOSE_PILL_BOTTLE_DATA = "show_close_pill_bottle_data";
export const TEMP_UNIT = "temp_unit";
export const MIN1_OR_MIN30_TAG = "min1_or_min30_tag";
export const DELTA_AUTO = "delta_auto";
export const LOADING_CHARTS = "loading_charts";
export const CHOOSE_USER = "choose_user";
export const TRIAL_USER = "trial_user";
export const CALENDAR_DATA = "calendar_data";
export const TIME_ZONE = "time_zone";
export const FOOT_STATUS = "foot_status";
export const FOOT_SYMTOMS = "foot_symtoms";
export const WEIGHT_SCALE = "weight_scale";
export const TREAT_MENT = "treat_ment";
export const FILTER_GROUP = "filter_group";
export const USER_GROUPS = "user_groups";
export const USER_PROFILE = "user_profile";
export const USER_SESSION = "user_session";
export const CLINIC_SITES = "clinic_sites";
export const CLINIC_SITES_DELETED = "clinic_sites_deleted";
export const LIST_STAFF = "list_staff";
export const DELETED_LIST_STAFF = "deleted_list_staff";
export const COMPLIANCE_LIST = "compliance_list";
export const FOOT_DATA = "foot_data";
export const CURRENT_BOX_INFO = "current_box_info";
export const REPLACE_BOX_INFO = "replace_box_info";
export const DOCTOR_REPORT_LIST = "doctor_report_list";
export const RESET_ALL_REPORT = "reset_all_report";
export const BLI_SIRENID_REPORT = "bli_sirenid_report";
export const RESET_ALL_SIRENID = "reset_all_sirenid";
export const SEARCH_PATIENTS_LIST = "search_patient_list";
export const BILLING_TABLE_LIST = "billing_table_list";
export const BILLING_TABLE_LIST_99454 = "billing_table_list_99454";
export const REPORT_MANUAL_LIST = "report_manual_list";
export const DOCTOR_REPORT_HISTORT = "doctor_report_history";
export const LOG_KEYS = "log_keys";
export const INVOICE_PATIENT = "invoice_patient";
export const SEL_RIGHT_TOE = "sel_right_toe";
export const SEL_RIGHT_MTS = "sel_right_mts";
export const SEL_RIGHT_ARCH = "sel_right_arch";
export const SEL_RIGHT_HEEL = "sel_right_heel";
export const SEL_LEFT_TOE = "sel_left_toe";
export const SEL_LEFT_MTS = "sel_left_mts";
export const SEL_LEFT_ARCH = "sel_left_arch";
export const SEL_LEFT_HEEL = "sel_left_heel";
export const NPM_SERVICE = "npm_service";
export const FILTER_NPM_SERVICE = "filter_npm_service";
export const MODAL_STATE = "modal_state";
export const ALL_CLINIC_SITES = "all_clinic_sites";
export const SEL_SIRENID_REPORT = "sel_sirenid_report";
export const BILLING_99457_Billed_PATIENTS = "billing_99457_billed_patients";
export const BILLING_PCM_Billed_PATIENTS = "billing_pcm_billed_patients";
export const BILLING_99457_Unbilled_PATIENTS =
  "billing_99457_unbilled_patients";
export const BILLING_PCM_Unbilled_PATIENTS = "billing_pcm_unbilled_patients";
export const COUNT_99457_UNBILLED = "count_99457_unbilled";
export const COUNT_PCM_UNBILLED = "count_pcm_unbilled";
export const BILLING_99454_Unbilled_PATIENTS =
  "billing_99454_unbilled_patients";
export const SHOW_GENERATE_99457_DELAY = "show_generate_99457_delay";
export const SHOW_GENERATE_PCM_DELAY = "show_generate_pcm_delay";
export const GENERATE_99457_DELAY = "generate_99457_delay";
export const GENERATE_PCM_DELAY = "generate_pcm_delay";
export const CURMONTH_PATIENTS_99457 = "curmonth_patient_99457";
export const CURMONTH_PATIENTS_PCM = "curmonth_patient_pcm";
export const BILLING_99454_Billed_PATIENTS = "billing_99454_billed_patients";
export const COUNT_99454_UNBILLED = "count_99454_unbilled";
export const BILLING_99453_Billed_PATIENTS = "billing_99453_billed_patients";
export const BILLING_99453_Unbilled_PATIENTS =
  "billing_99453_unbilled_patients";
export const COUNT_99453_UNBILLED = "count_99453_unbilled";
export const SHOW_GENERATE_99454_DELAY = "show_generate_99454_delay";
export const SHOW_GENERATE_VA_DELAY = "show_generate_va_delay";
export const GENERATE_99454_DELAY = "generate_99454_delay";
export const GENERATE_VA_DELAY = "generate_VA_delay";
export const CURMONTH_PATIENTS_99453 = "curmonth_patient_99453";
export const HUB_LIST = "hub_list";
export const LIST_ADMIN_STAFF = "list_admin_staff";
export const LIST_ADMIN_DELETED_STAFF = "list_admin_deleted_staff";
export const LIST_SIREN_PATIENT = "list_siren_patients";
export const UPDATE_LPN_FILTER = "UPDATE_LPN_FILTER";
export const LIST_SIREN_STAFF = "list_siren_staff";
export const LIST_LPN_PATIENTS = "list_lpn_patients";
export const LIST_NEED_ASSIGNED_PATIENTS = "list_need_assigned_patients";
export const LIST_NOT_ON_SERVICE_PATIENTS = "list_not_on_service_patients";
export const SHOW_LPN_CHARTS = "show_lpn_charts";
export const NEED_ASSIGNED_COUNT = "need_assigned_count";
export const EMR_SYSTEM_LIST = "emr_system_list";
export const RPM_NOTE_TEMPLATES = "rpm_note_templates";
export const SHOE_TAG_LIST = "shoe_tag_list";
export const PILL_BOTTLE_LIST = "pill_bottle_list";
export const TAG_TIME_WORN = "tag_time_worn";
export const PILL_BOTTLE_DATE_WORN = "pill_bottle_date_worn";
export const TAG_TIME_WORN_BY_WEEK = "tag_time_worn_by_week";
export const UPDATE_ENV = "update_env";
export const LIST_CARE_PLAN = "list_care_plan";
export const LIST_ICD_10_CODES = "list_ICD_10_codes";
export const LIST_MEDICAL_CONDITIONS = "list_medical_conditions";
export const TABLE_FILTER_REPORT_99453_4_NEEDS_REVIEW =
  "TABLE_FILTER_REPORT_99453_4_NEEDS_REVIEW";
export const TABLE_FILTER_REPORT_99457_8_NEEDS_REVIEW =
  "TABLE_FILTER_REPORT_99457_8_NEEDS_REVIEW";
export const TABLE_FILTER_REPORT_99426_7_NEEDS_REVIEW =
  "TABLE_FILTER_REPORT_99426_7_NEEDS_REVIEW";
export const TABLE_FILTER_REPORT_99453_4_REVIEWED =
  "TABLE_FILTER_REPORT_99453_4_REVIEWED";
export const TABLE_FILTER_REPORT_99457_8_REVIEWED =
  "TABLE_FILTER_REPORT_99457_8_REVIEWED";
export const TABLE_FILTER_REPORT_99426_7_REVIEWED =
  "TABLE_FILTER_REPORT_99426_7_REVIEWED";
export const TABLE_FILTER_REPORT_99453_4_CURRENT_MONTH =
  "TABLE_FILTER_REPORT_99453_4_CURRENT_MONTH";
export const TABLE_FILTER_REPORT_99457_8_CURRENT_MONTH =
  "TABLE_FILTER_REPORT_99457_8_CURRENT_MONTH";
export const TABLE_FILTER_REPORT_99426_7_CURRENT_MONTH =
  "TABLE_FILTER_REPORT_99426_7_CURRENT_MONTH";
export const TABLE_FILTER_SIREN_STAFFING = "TABLE_FILTER_SIREN_STAFFING";
export const DMT_MODAL_STATUS = "DMT_MODAL_STATUS";
