import { message } from "antd";
import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { changeDeletedAccountStatus } from "../action/addAction";
import "../share/button.css";
import ButtonSuccess from "./buttonSuccess";

export default class recoveryPatient extends Component {

  state = { open: false, confirmSuccess: false };

  show = () => this.setState({ open: true, confirmSuccess: false });

  handleConfirm = async () => {
    let user = await Auth.currentAuthenticatedUser();
    this.setState({ confirmSuccess: true });
    const id = this.props.id;
    const newStatus = {
      id: id,
      title: 'Account Status Recovery',
      status: "RECOVERY",
      author: `${user.attributes.given_name} ${user.attributes.family_name}`,
      pausedBy: user.username
    };
    changeDeletedAccountStatus(newStatus).then(() => {
      this.setState({
        open: false
      });
      if (this.props.onRecovery) {
        this.props.onRecovery(id);
      }
    }).catch(error => {
      message.error(error.message)
    });
  };

  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini" className="resetButton">
            Reinstate
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header>
          Recovery Patient Confirmation
        </Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <p>Are you sure to recovery the new patient: {this.props.id}?</p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
