import { Button, Form, Input, Modal, Row, Table } from 'antd';
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  listICD10Codes,
  createICD10Code,
  deleteICD10Code
} from "../../../action/userGroupAction";

const { confirm } = Modal;
const { Column } = Table;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

function defaultSorter(a, b) {
  return String.prototype.localeCompare.apply(a, b)
}

function defaultFilter(value, record) {
  return record.address.indexOf(value) === 0
}


class ICD10CodesManager extends Component {

  state = {
    loading: false,
    openAddModal: false,
    code: null
  }

  addCode = () => {
    this.setState({
      loading: true
    })
    this.props.createICD10Code(this.state.code.trim())
      .then(() => {
        this.setState({
          code: null,
          openAddModal: false
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  }

  deleteCode = (record) => {
    confirm({
      title: `Are you sure you want to permanently delete code "${record.code}"?`,
      onOk: () => {
        this.setState({
          loading: true
        })
        this.props.deleteICD10Code(record.code)
          .finally(() => {
            this.setState({
              loading: false
            })
          })
      }
    });
  }

  render() {
    return (
      <div style={{ textAlign: 'left' }}>
        <div style={{ marginBottom: 16 }}>
          <Button type="primary" onClick={() => { this.setState({ openAddModal: true }) }}>
            Add code
          </Button>
        </div>
        <Modal
          title="Add ICD 10 Code"
          visible={this.state.openAddModal}
          onOk={this.addCode}
          onCancel={() => { this.setState({ openAddModal: false }) }}
        >
          <Form {...formItemLayout}>
            <Row gutter={[16, 16]}>
              <Form.Item
                label="Code"
                required
                className='form-item'
              >
                <Input
                  maxLength={50}
                  value={this.state.code}
                  onChange={e => {
                    this.setState({ code: e.target.value.replace('_', '-') })
                  }}
                />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <Table dataSource={this.props.ICD10Codes} loading={this.state.loading} rowKey="code">
          <Column title="Code" dataIndex="code" onFilter={defaultFilter} sorter={defaultSorter} />
          <Column title="Created by" dataIndex="createdBy" />
          <Column title="Created date" dataIndex="createdAt" />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <a onClick={() => this.deleteCode(record)}>Delete</a>
              </span>
            )}
          />
        </Table>
      </div>
    );
  }
}

const mapStateToProp = state => ({
  ICD10Codes: state.app.ICD10Codes
});
const mapDispatchToProp = dispatch => ({
  listICD10Codes: () => dispatch(listICD10Codes),
  createICD10Code: (code) => dispatch(createICD10Code(code)),
  deleteICD10Code: (code) => dispatch(deleteICD10Code(code))
});

export default connect(mapStateToProp, mapDispatchToProp)(ICD10CodesManager);
