import { message } from "antd";
import React, { Component } from "react";
import { Button, Form, Icon, Input, Modal } from "semantic-ui-react";
import { resetPasswordAdmin } from "../../graphql/API";
import ButtonSuccess from "../../home/buttonSuccess";
import "../../staff/ResetStaffPwd.css";

function checkPassword(text) {
  if (text.length < 8) {
    return 'Minimum length is 8'
  }
  if (text.search(/[0-9]/) === -1) {
    return 'Require numbers'
  }
  if (text.search(/[a-z]/) === -1) {
    return 'Require lowercase letters'
  }
  if (text.search(/[A-Z]/) === -1) {
    return 'Require uppercase letters'
  }
  if (text.search(/[^a-zA-Z0-9]+/) === -1) {
    return 'Require special character'
  }
  return ''
}

export default class ButtonResetPassword extends Component {

  state = {
    open: false,
    confirmSuccess: false,
    showPassword: true,
    newPwd: "",
    invalidMsg: "",
    loading: false
  }

  handleInputChangePwd = event => {
    this.setState({
      newPwd: event.target.value
    })
  }

  onOpen = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      showPassword: true,
      newPwd: "",
      invalidMsg: "",
      loading: false
    })
  }

  onClose = () => {
    this.setState({
      open: false
    })
  }

  handleConfirm = () => {
    const errMsg = checkPassword(this.state.newPwd)
    if (errMsg) {
      this.setState({
        invalidMsg: errMsg
      })
      return
    }
    const newPwdInput = {
      input: {
        id: this.props.id,
        password: this.state.newPwd
      }
    }
    this.setState({
      invalidMsg: '',
      loading: true
    })
    resetPasswordAdmin(newPwdInput).then(() => {
      this.setState({
        confirmSuccess: true
      })
      setTimeout(() => {
        this.onClose()
      }, 300)
    }).catch((err) => {
      if (typeof err === 'string') {
        message.error(err)
      } else {
        message.error(JSON.stringify(err))
      }
    }).finally(() => {
      this.setState({
        loading: false
      })
    })
  }

  render() {
    return (
      <Modal
        onClose={this.onClose}
        onOpen={this.onOpen}
        open={this.state.open}
        className="resetPwd_Modal"
        trigger={
          <Button size="mini">
            Reset Password
          </Button>
        }
      >
        <Modal.Header>Reset Password</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="newSite_box" loading={this.state.loading}>
              <div className="resetTitleBox">
                <p className="resetTip">
                  Must be 8 or more characters. Use letters, numbers, and symbols
                </p>
                <div className="totalBox">
                  <div className="resetPwd_Box">
                    <p className="resetPwd_Label">New Password</p>
                    <Input
                      placeholder="New Password"
                      className="resetPwd_input"
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.handleInputChangePwd}
                    />
                    <div className="clearFloat" />
                    <Icon
                      as="i"
                      name={this.state.showPassword ? "eye slash" : "eye"}
                      onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                      className="resetPwd_eyeIcon"
                    />
                  </div>
                  <div className="resetPwd_Tip_Box">
                    <Icon
                      as="i"
                      name="check"
                      className="resetPwd_checkIcon"
                      style={{
                        color: "red",
                        visibility: this.state.invalidMsg ? "visible" : "hidden"
                      }}
                    />
                    <div className="clearFloat" />
                  </div>
                  <div
                    className="resetPwd_error_Tip"
                    style={{
                      color: "red",
                      visibility: this.state.invalidMsg ? "visible" : "hidden"
                    }}
                  >
                    {this.state.invalidMsg}
                  </div>
                  <div className="clearFloat" />
                </div>
              </div>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={this.handleConfirm}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={this.onClose}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
