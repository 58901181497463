import { Select } from "antd";
import _ from "lodash";
import QRCode from "qrcode.react";
import React, { Component } from "react";
// import moment from "moment";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Icon, Popup } from "semantic-ui-react";
import { getPatientByUserId } from "../action/boxAction";
import Progress from "../home/progress";
import AddBoxItem from "./AddBoxItem";
import "./BoxList.css";
// import EditBoxItemForm from "./EditBoxItemForm";
// import DeleteBoxItemDialog from "./DeleteBoxItemDialog";
import RegisterBox from "./registerbox";
import SocksList from "./SocksList";
import { Control } from "react-keeper";
import { searchBoxList } from "../graphql/API";
import {
  InputEnterKeyTriggerFilterComponent,
  transformBoxTableParams
} from "../tools/reactTablePatch";
import DateInput from "../common/DateInput";
import HubStatus from "./HubStatus";
import BoxStatus from "./BoxStatus";

const { Option } = Select;

Modal.setAppElement("div");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};
class BoxList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      loading: false,
      data: [],
      totalSize: 0
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.tableInstance = null;
    this.debounceRequest = false;
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  dateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
        defaultValue={filter ? filter.value : null}
        onPressEnter={value => {
          this.debounceRequest = false;
          return onChange(value);
        }}
        onChange={value => {
          this.debounceRequest = true;
          return onChange(value);
        }}
      />
    );
  };

  makeInstanceRequest() {
    setTimeout(this.reqPatientList.flush, 100);
  }

  makeSearchBox = () => {
    const isThirdParty =
      this.props.userGroups.length === 1 &&
      this.props.userGroups.includes("third_party");
    if (isThirdParty) {
      return;
    }
    if (this.debounceRequest) {
      this.debounceSearchBox();
    } else {
      this.debounceSearchBox();
      this.debounceSearchBox.flush();
    }
  };

  debounceSearchBox = _.debounce(() => {
    this.rawSearchBox();
  }, 3000);

  rawSearchBox = () => {
    this.debounceRequest = false;

    this.setState({
      loading: true
    });

    const { filter, sort, pagination } = transformBoxTableParams(
      this.tableInstance
    );
    searchBoxList(filter, sort, pagination)
      .then(data => {
        const target = data.data.lambdaSearchBoxes;
        this.setState({
          data: target.items,
          totalSize: target.total
        });
      })
      .catch(e => {
        console.log("searchBox error:", e);
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  gotoStatisticsPage(userId) {
    this.props.getPatientByUserId(userId, this);
  }

  render() {
    return (
      <div className="boxlistcontainer">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div>
          <div className="add_import_box" style={{ float: "right" }}>
            <AddBoxItem
              onClose={addBox => {
                if (addBox) {
                  this.makeSearchBox();
                }
              }}
            />
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <ReactTable
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "registeredAt",
              desc: true
            }
          ]}
          className="-striped -highlight"
          loading={this.state.loading}
          minRows={0}
          pages={
            !this.tableInstance
              ? -1
              : Math.ceil(
                  this.state.totalSize / this.tableInstance.state.pageSize
                )
          }
          manual
          filterable
          FilterComponent={InputEnterKeyTriggerFilterComponent({
            onChange: () => {
              this.debounceRequest = true;
            },
            onPressEnter: () => {
              this.debounceRequest = false;
            }
          })}
          onFetchData={(_, instance) => {
            this.tableInstance = instance;

            this.makeSearchBox();
          }}
          data={this.state.data}
          columns={[
            {
              Header: "Box ID",
              accessor: "id"
            },
            {
              Header: "Auth Code",
              accessor: "authCode",
              sortable: false,
              filterable: false
            },
            {
              Header: "Socks",
              accessor: "socks",
              sortable: false,
              Cell: props => {
                return (
                  <div className="boxlistdiv">
                    {props.original.socks.length}
                    <Popup
                      trigger={
                        <Icon as="i" name="eye" className="view-more-eye" />
                      }
                      position="right center"
                      hoverable
                      flowing
                    >
                      <Popup.Header>Sock List</Popup.Header>
                      <Popup.Content>
                        <SocksList socks={props.original.socks} />
                      </Popup.Content>
                    </Popup>
                  </div>
                );
              }
            },
            {
              Header: "Registration Status",
              accessor: "registered",
              Filter: ({ filter, onChange }) => (
                <Select
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <Option value="all">All</Option>
                  <Option value={"true"}>Yes</Option>
                  <Option value={"false"}>No</Option>
                </Select>
              ),
              Cell: props => <span>{props.value ? "Yes" : "No"}</span>
            },
            {
              Header: "Registered By",
              accessor: "userId",
              sortable: false,
              Cell: props => {
                return !props.original.registered ? null : (
                  <span
                    style={{ cursor: "pointer" }}
                    className="spanonclick"
                    onClick={() => this.gotoStatisticsPage(props.value)}
                  >
                    {props.value}
                  </span>
                );
              }
            },
            {
              Header: "Registration Date",
              accessor: "registeredAt",
              Filter: this.dateInputComponent,
              Cell: props => (props.value ? props.value.substring(0, 10) : "")
            },
            {
              Header: "Manufacture Date",
              accessor: "createdAt",
              Filter: this.dateInputComponent,
              Cell: props =>
                props.original.factoryDate
                  ? props.original.factoryDate.substring(0, 10)
                  : props.value
                  ? props.value.substring(0, 10)
                  : ""
            },
            {
              Header: "SKU",
              accessor: "sku"
            },
            {
              Header: "Color",
              accessor: "color",
              Filter: ({ filter, onChange }) => (
                <Select
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <Option value="all">All</Option>
                  <Option value="white">White</Option>
                  <Option value="black">Black</Option>
                </Select>
              ),
              Cell: props => {
                if (props.value) {
                  return (
                    props.value.charAt(0).toUpperCase() + props.value.slice(1)
                  );
                }
              }
            },
            {
              Header: "Size",
              accessor: "size",
              Filter: ({ filter, onChange }) => (
                <Select
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <Option value="all">All</Option>
                  <Option value="S">S</Option>
                  <Option value="M">M</Option>
                  <Option value="L">L</Option>
                </Select>
              )
            },
            {
              Header: "On Service",
              accessor: "canRegistered",
              sortable: false,
              Cell: props => {
                return (
                  <BoxStatus
                    id={props.original.id}
                    canRegistered={props.original.canRegistered || "Yes"}
                    canRegisteredReason={props.original.canRegisteredReason}
                    onChange={result => {
                      const nextListData = [...this.state.data];
                      const item = nextListData.find(
                        item => item.id === result.id
                      );
                      item.canRegistered = result.canRegistered;
                      item.canRegisteredReason = result.canRegisteredReason;

                      this.setState({
                        data: nextListData
                      });
                    }}
                  />
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "Actions",
              accessor: "",
              sortable: false,
              filterable: false,
              Cell: props => {
                return (
                  <div className="boxlistdiv">
                    {props.original.registered ||
                    props.original.canRegistered === "No" ? null : (
                      <RegisterBox
                        item={props.original}
                        onSuccess={boxId => {
                          // update registerd status
                          const nextData = [...this.state.data];
                          const boxItem = nextData.find(e => e.id === boxId);
                          boxItem.registered = true;

                          this.setState({
                            data: nextData
                          });
                        }}
                      />
                    )}
                  </div>
                );
              }
            },
            {
              Header: "QR Code",
              accessor: "",
              sortable: false,
              filterable: false,
              Cell: props => {
                return (
                  <div className="boxlistdiv">
                    <Popup
                      trigger={
                        <Icon as="i" name="qrcode" className="view-more-eye" />
                      }
                      position="right center"
                      hoverable
                      flowing
                    >
                      <Popup.Header>
                        QR Code for {props.original.id}
                      </Popup.Header>
                      <Popup.Content>
                        <QRCode
                          value={
                            props.original.id + "&" + props.original.authCode
                          }
                          size={128}
                          bgColor={"#ffffff"}
                          fgColor={"#000000"}
                          level={"L"}
                        />
                      </Popup.Content>
                    </Popup>
                  </div>
                );
              }
            }
          ]}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  userGroups: state.authGroupStore.session.userGroups
});

const mapDispatchToProp = dispatch => ({
  getPatientByUserId: (userId, self) =>
    dispatch(getPatientByUserId(userId, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(BoxList);
