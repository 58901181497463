import { Select, Tag, Popover, Divider } from "antd";
import _, { find, isEmpty } from "lodash";
import moment from "moment";
import moment_tz from "moment-timezone";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import { connect } from "react-redux";
import ReactTable from "react-table";
import {
  changeNotificationState,
  updateNewPatientNotification,
  updatePatientPushSuc
} from "../action/notificationAction";
import { getlistClinicSites } from "../action/registerAction";
import DateInput from "../common/DateInput";
import { registeredPatientListAPI } from "../graphql/API";
import { getPatientHubType } from "../tools/hubType";
import { formatNumber } from "../tools/phoneFormat";
import getPatchTrProps, {
  DateFormat,
  InputEnterKeyTriggerFilterComponent,
  transformPatientTableParams
} from "../tools/reactTablePatch";
import { convertRelation } from "../tools/relationConvert";
import SingleFoot from "./SingleFoot";
import AllNotificationStatus from "./AllNotificationStatus";
import AppLink from "./appLink";
import DeleteNewPatient from "./deleteNewPatient";
import "./DeviceList.css";
import EditPatient from "../MedicalDashboard/account/EditProfileInfo2";
import FootStatusPushStatus from "./footStatusPushStatus";
import ReminderPushStatus from "./reminderPushStatus";
import ReNewPatientPwd from "./ReNewPatientPwd";
import ChangeBillingStatus from "./changeBillingStatus";
import RpmDeviceStatus from "./RpmDeviceStatus";
import RtmDeviceStatus from "./RtmDeviceStatus";
import CircleColor from "./circle-color";
import DeviceStatusDesc from "../tools/device-status-desc";
import ProgramStatusDesc from "../tools/program-status-desc";
import {
  SortAscendingOutlined,
  SortDescendingOutlined
} from "@ant-design/icons";
import { DeviceOptions, ProgramOptions } from "../constant/programDeviceStatus";

const SecRela = [
  { rela: "Spouse/Partner", key: "1" },
  { rela: "Sibling", key: "2" },
  { rela: "Child", key: "3" },
  { rela: "Niece/Nephew", key: "4" },
  { rela: "Grandchild", key: "5" },
  { rela: "Caregiver", key: "6" },
  { rela: "Other", key: "7" }
];
const COLOR = {
  PENDING: "#F1BE0A",
  Pending: "#F1BE0A",
  ON_SERVICE: "#73D1AE",
  NOT_ON_SERVICE: "#6F7381",
  ACTIVE: "#73D1AE",
  IDLE: "#AEC3BB",
  PAUSED: "#F1BE0A"
};

class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      totalNum: 0,
      error: null,
      rpmSort: null,
      rtmSort: null,
      pcmSort: null,
      ccmSort: null,
      socksSort: null,
      moveSort: null
    };

    this.tableInstance = null;
  }

  componentDidMount() {
    const { getlistClinicSites } = this.props;
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites();
    }
  }

  // wrapper a function if it should trigger request instantly
  reqShouldInstantWrapper = (shouldInstant, onEvent) => {
    return value => {
      this.makeRequestInstant = shouldInstant;
      console.log("trigger:", shouldInstant);
      return onEvent(value);
    };
  };

  reqPatientList = _.debounce(() => {
    const { filter, sort, pagination } = transformPatientTableParams(
      this.tableInstance,
      (filter, key, value) => {
        if (key === "onStaffing") {
          if (value === "all") {
            delete filter[key];
          } else {
            const sites = _.filter(this.props.clinicSites, {
              onStaffing: value
            });
            const siteIds = sites.map(p => {
              return p.siteId;
            });
            const filterSite = {
              siteId: {
                match: siteIds.join(" ")
              }
            };
            if (filter.and) {
              filter.and.push(filterSite);
            } else {
              filter.and = [filterSite];
            }
            delete filter[key];
          }
        } else if (key === "accountBillingStatus") {
          const filterAnd = filter.and ? filter.and : [];
          if (value === "all") {
            delete filter[key];
          } else if (value === "Active") {
            delete filter[key];
            filter["and"] = [
              ...filterAnd,
              { accountBillingStatus: { ne: "Not active" } }
            ];
          } else {
            delete filter[key];
            filter["and"] = [
              ...filterAnd,
              { accountBillingStatus: { eq: "Not active" } }
            ];
          }
        } else if (key === "enableNotification") {
          const filterAnd = filter.and ? filter.and : [];
          if (value === "all") {
          } else if (value === "Yes") {
            filter["and"] = [
              ...filterAnd,
              {
                or: [
                  { enableApp: { eq: true } },
                  { enableSms: { eq: true } },
                  { enableEmail: { eq: true } }
                ]
              }
            ];
          } else {
            filter["and"] = [
              ...filterAnd,
              {
                and: [
                  { enableApp: { ne: true } },
                  { enableSms: { ne: true } },
                  { enableEmail: { ne: true } }
                ]
              }
            ];
          }
          delete filter[key];
        } else if (key === "enableFootStatusPush") {
          const filterAnd = filter.and ? filter.and : [];
          if (value === "all") {
          } else if (value === "Yes") {
            filter["and"] = [
              ...filterAnd,
              {
                and: [
                  {
                    or: [
                      { enableApp: { eq: true } },
                      { enableSms: { eq: true } },
                      { enableEmail: { eq: true } }
                    ]
                  },
                  {
                    footStatusPush: { ne: "No" }
                  }
                ]
              }
            ];
          } else {
            filter["and"] = [
              ...filterAnd,
              {
                or: [
                  {
                    and: [
                      { enableApp: { ne: true } },
                      { enableSms: { ne: true } },
                      { enableEmail: { ne: true } }
                    ]
                  },
                  {
                    footStatusPush: { eq: "No" }
                  }
                ]
              }
            ];
          }
          delete filter[key];
        } else if (key === "enableReminderPush") {
          const filterAnd = filter.and ? filter.and : [];
          if (value === "all") {
          } else if (value === "Yes") {
            filter["and"] = [
              ...filterAnd,
              {
                and: [
                  {
                    or: [
                      { enableApp: { eq: true } },
                      { enableSms: { eq: true } },
                      { enableEmail: { eq: true } }
                    ]
                  },
                  {
                    reminderPush: { ne: "No" }
                  }
                ]
              }
            ];
          } else {
            filter["and"] = [
              ...filterAnd,
              {
                or: [
                  {
                    and: [
                      { enableApp: { ne: true } },
                      { enableSms: { ne: true } },
                      { enableEmail: { ne: true } }
                    ]
                  },
                  {
                    reminderPush: { eq: "No" }
                  }
                ]
              }
            ];
          }
          delete filter[key];
        }
      }
    );

    this.rawReqPatientList(filter, sort, pagination);
  }, 1200);

  rawReqPatientList(filter, sort, pagination) {
    this.setState({
      loading: true,
      error: null
    });

    registeredPatientListAPI(filter, sort, pagination)
      .then(data => {
        let patiens = data.data.lambdaSearchPatients.items;
        let totalSize = data.data.lambdaSearchPatients.total;

        this.setState({
          data: patiens,
          totalNum: totalSize
        });
      })
      .catch(error => {
        this.setState({
          error: error
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  gotoStatisticsPage(id, userId, userTimezone, hubType) {
    Control.go(
      "/sockstatus?NewPatients/id=" +
        id +
        "&userId=" +
        userId +
        "&timeZone=" +
        userTimezone +
        "&hubType=" +
        hubType
    );
  }

  dateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
        defaultValue={filter ? filter.value : null}
        onPressEnter={this.reqShouldInstantWrapper(true, onChange)}
        onChange={this.reqShouldInstantWrapper(false, onChange)}
      />
    );
  };

  programSort = program => {
    const nextSort = {
      asc: "desc",
      desc: null
    };
    let rpmSort = null,
      rtmSort = null,
      pcmSort = null,
      ccmSort = null;
    switch (program) {
      case "RPM":
        rpmSort = !this.state.rpmSort ? "asc" : nextSort[this.state.rpmSort];
        break;
      case "RTM":
        rtmSort = !this.state.rtmSort ? "asc" : nextSort[this.state.rtmSort];
        break;
      case "PCM":
        pcmSort = !this.state.pcmSort ? "asc" : nextSort[this.state.pcmSort];
        break;
      case "CCM":
        ccmSort = !this.state.ccmSort ? "asc" : nextSort[this.state.ccmSort];
        break;
      default:
        break;
    }
    this.setState(
      {
        rpmSort,
        rtmSort,
        pcmSort,
        ccmSort
      },
      () => {
        this.tableInstance.sortColumn({
          id: `${program.toLowerCase()}ProgramStatus`
        });
      }
    );
  };

  deviceSort = device => {
    const deviceName2Code = {
      socks: "rpm",
      move: "rtm"
    };
    const nextSort = {
      asc: "desc",
      desc: null
    };
    let socksSort = null,
      moveSort = null;
    switch (device) {
      case "Socks":
        socksSort = !this.state.socksSort
          ? "asc"
          : nextSort[this.state.socksSort];
        break;
      case "Move":
        moveSort = !this.state.moveSort ? "asc" : nextSort[this.state.moveSort];
        break;
      default:
        break;
    }
    this.setState(
      {
        socksSort,
        moveSort
      },
      () => {
        this.tableInstance.sortColumn({
          id: `${deviceName2Code[device.toLowerCase()]}DeviceStatus`
        });
      }
    );
  };

  render() {
    const isAdminOrDeveloper =
      this.props.userGroups.includes("admin") ||
      this.props.userGroups.includes("developer") ||
      this.props.userGroups.includes("support");
    const { updateNotification, changeNotificationState } = this.props;
    const { Option } = Select;
    const trProps = getPatchTrProps(({ event }) => {
      //WARNING: black magic
      const buttons = event.currentTarget.getElementsByTagName("button");
      for (const button of buttons) {
        if (button.innerHTML === "Edit") {
          button.click();
          break;
        }
      }
    });
    return (
      <div className="device-list-container">
        {/*{this.state.error && (*/}
        {/*  <Alert*/}
        {/*    message={JSON.stringify(this.state.error)}*/}
        {/*    type="error"*/}
        {/*    closable*/}
        {/*  />*/}
        {/*)}*/}
        <ReactTable
          id="newPatientsTable"
          className="-striped -highlight"
          getTrProps={trProps}
          loading={this.state.loading}
          manual
          pages={
            !this.tableInstance
              ? -1
              : Math.ceil(
                  this.state.totalNum / this.tableInstance.state.pageSize
                )
          }
          // onSortedChange={() => {
          //   this.makeRequestInstant = true;
          // }}
          onSortedChange={newSorted => {
            if (!newSorted[0].id.endsWith("ProgramStatus")) {
              this.setState({
                rpmSort: null,
                rtmSort: null,
                pcmSort: null,
                ccmSort: null
              });
            }
            if (!newSorted[0].id.endsWith("DeviceStatus")) {
              this.setState({
                socksSort: null,
                moveSort: null
              });
            }
          }}
          data={this.state.data}
          onFetchData={(_, instance) => {
            // there are some other callbacks, like onPageChange onPageSizeChange etc,
            // we don't care them. this callback is enough
            this.tableInstance = instance;

            this.reqPatientList();
            if (this.makeRequestInstant) {
              this.reqPatientList.flush();
            }
          }}
          defaultPageSize={20}
          defaultSorted={[{ id: "createdAt", desc: true }]}
          defaultFiltered={[
            {
              id: "initialized",
              value: "No"
            }
          ]}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          filterable
          FilterComponent={InputEnterKeyTriggerFilterComponent({
            onChange: () => {
              this.makeRequestInstant = false;
            },
            onPressEnter: () => {
              this.makeRequestInstant = true;
            }
          })}
          minRows={0}
          resolveData={data =>
            data.map(p => {
              const email = p.email && p.email.includes("@") ? p.email : "";
              const primaryKey = p.id;
              const dispensedDay =
                p.dispenseType && p.dispensedDay ? p.dispensedDay : "";
              const siteInfo = find(this.props.clinicSites, {
                siteId: p.siteId
              });
              const onStaffing = siteInfo
                ? siteInfo.onStaffing === "Yes"
                  ? "On Staffing"
                  : "Not On Staffing"
                : "";
              const accountStatus = !isEmpty(p.accountStatus)
                ? p.accountStatus
                : "ACTIVE";
              const hubType = p.hubs ? getPatientHubType(p.hubs) : "";
              const relationship = p.relation
                ? convertRelation(p.relation)
                : "";
              const enableNotification =
                p.enableApp || p.enableSms || p.enableEmail;
              const enableFootStatusPush = enableNotification
                ? p.footStatusPush
                  ? p.footStatusPush === "Yes"
                  : enableNotification
                : enableNotification;
              const enableReminderPush = enableNotification
                ? p.reminderPush
                  ? p.reminderPush === "Yes"
                  : enableNotification
                : enableNotification;
              const accountBillingStatus = p.accountBillingStatus
                ? p.accountBillingStatus
                : "Active";
              return {
                ...p,
                email,
                primaryKey,
                dispensedDay,
                accountStatus,
                hubType,
                relationship,
                enableNotification,
                enableFootStatusPush,
                enableReminderPush,
                onStaffing,
                accountBillingStatus
              };
            })
          }
          columns={[
            {
              Header: "SiteId",
              accessor: "siteId",
              maxWidth: 70,
              sortable: false
            },
            {
              Header: "SiteName",
              accessor: "siteName"
            },
            {
              Header: "SirenId",
              accessor: "sirenId",
              maxWidth: 70
            },
            {
              Header: "First Name",
              accessor: "firstName"
            },
            {
              Header: "Last Name",
              accessor: "lastName"
            },
            {
              Header: "Phone",
              accessor: "phone",
              minWidth: 140,
              Cell: props => {
                if (props.value) {
                  return formatNumber(props.value);
                }
              }
            },
            {
              Header: "Email",
              accessor: "email",
              minWidth: 180
            },
            {
              Header: "Account Created",
              accessor: "createdAt",
              Filter: this.dateInputComponent,
              Cell: props => {
                if (props.value) {
                  return moment_tz
                    .tz(props.value, props.original.userTimezone)
                    .format(DateFormat);
                }
              }
            },
            {
              Header: "Socks Dispensed",
              accessor: "dispensed",
              sortable: false,
              filterable: false,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              ),
              Cell: props => {
                return props.value || "No";
              }
            },
            // {
            //   Header: "Dispensed Date",
            //   accessor: "dispensedDay",
            //   filterMethod: (filter, row) => {
            //     return row[filter.id] && row[filter.id].includes(filter.value);
            //   }
            // },
            // {
            //   Header: "Shipment Status",
            //   accessor: "shipmentStatus",
            //   filterMethod: (filter, row) => {
            //     return row[filter.id] && row[filter.id].includes(filter.value);
            //   }
            // },
            {
              Header: "App Activated",
              accessor: "appInitialized",
              maxWidth: 72,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              ),
              Cell: props => {
                return props.value || "No";
              }
            },
            {
              Header: "App Activation Date",
              accessor: "appInitializedDate",
              Filter: this.dateInputComponent,
              Cell: props => {
                if (props.value) {
                  moment(props.value).format(DateFormat);
                }
              }
            },
            {
              style: { cursor: "pointer" },
              Header: "Hub Type",
              accessor: "hubType",
              filterable: false,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <span
                      className="spanonclick"
                      onClick={() =>
                        this.gotoStatisticsPage(
                          props.original.id,
                          props.original.userId,
                          props.original.userTimezone,
                          props.original.hubType
                        )
                      }
                    >
                      {props.original.hubType}
                    </span>
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Wifi">Wifi</Option>
                  <Option value="2G">2G</Option>
                  <Option value="4G">4G</Option>
                  <Option value="Wifi, 2G">Wifi, 2G</Option>
                  <Option value="Wifi, 4G">Wifi, 4G</Option>
                  <Option value="2G, 4G">2G, 4G</Option>
                  <Option value="Wifi, 2G, 4G">Wifi, 2G, 4G</Option>
                </Select>
              )
            },
            {
              Header: "Smartphone",
              accessor: "patientHasSmartPhone",
              maxWidth: 72,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                  <Option value="Caregiver">Caregiver</Option>
                </Select>
              )
            },
            {
              Header: "Wifi",
              accessor: "patientHasWifi",
              maxWidth: 72,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "Secondary Contact Name",
              accessor: "contactName"
            },
            {
              Header: "Secondary Contact Phone",
              accessor: "contactPhone",
              Cell: props => {
                if (props.value) {
                  return formatNumber(props.value);
                }
              }
            },
            {
              Header: "Secondary Contact Email",
              accessor: "secondaryEmail"
            },
            {
              Header: "Relationship To Patient",
              accessor: "relation",
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  {SecRela.map((si, i) => {
                    return (
                      <Option value={si.rela} key={"si" + i}>
                        {si.rela}
                      </Option>
                    );
                  })}
                </Select>
              )
            },
            {
              Header: "Single Foot",
              accessor: "singleFoot",
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="left">Left</Option>
                  <Option value="right">Right</Option>
                  <Option value="N/A">N/A</Option>
                </Select>
              ),
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <SingleFoot
                      value={props.value}
                      id={props.original.id}
                      onChange={() => {
                        // refresh table
                        this.tableInstance.fireFetchData();
                      }}
                    />
                  </div>
                );
              }
            },
            {
              style: { cursor: "pointer" },
              accessor: "onStaffing",
              Header: "Staffing",
              minWidth: 120,
              sortable: false,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="No">Not On Staffing</Option>
                  <Option value="Yes">On Staffing</Option>
                </Select>
              )
            },
            {
              style: {
                cursor: "pointer",
                textAlign: "left",
                paddingLeft: 10
              },
              Header: (
                <>
                  <Popover placement="top" content={<ProgramStatusDesc />}>
                    Program Status
                  </Popover>
                  <div
                    style={{ cursor: "pointer", marginTop: 6 }}
                    title="sort by program status"
                  >
                    {["RPM", "RTM", "PCM", "CCM"].map((program, index) => {
                      const stateKey = `${program.toLowerCase()}Sort`;
                      return (
                        <React.Fragment key={program}>
                          {index ? <Divider type="vertical" /> : null}
                          <span onClick={() => this.programSort(program)}>
                            {program}
                            <SortAscendingOutlined
                              style={{
                                display:
                                  this.state[stateKey] === "asc"
                                    ? "inline-block"
                                    : "none"
                              }}
                            />
                            <SortDescendingOutlined
                              style={{
                                display:
                                  this.state[stateKey] === "desc"
                                    ? "inline-block"
                                    : "none"
                              }}
                            />
                          </span>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              ),
              accessor: "programStatus",
              minWidth: 220,
              sortable: false,
              Cell: props => {
                return (
                  <div>
                    {props.original.rpmProgramStatus &&
                      props.original.rpmProgramStatus !== "" && (
                        <Tag
                          color={COLOR[props.original.rpmProgramStatus]}
                          key={"RPM"}
                        >
                          {"RPM"}
                        </Tag>
                      )}
                    {props.original.rtmProgramStatus &&
                      props.original.rtmProgramStatus !== "" && (
                        <Tag
                          color={COLOR[props.original.rtmProgramStatus]}
                          key={"RTM"}
                        >
                          {"RTM"}
                        </Tag>
                      )}
                    {props.original.pcmProgramStatus &&
                      props.original.pcmProgramStatus !== "" && (
                        <Tag
                          color={COLOR[props.original.pcmProgramStatus]}
                          key={"PCM"}
                        >
                          {"PCM"}
                        </Tag>
                      )}
                    {props.original.ccmProgramStatus &&
                      props.original.ccmProgramStatus !== "" && (
                        <Tag
                          color={COLOR[props.original.ccmProgramStatus]}
                          key={"CCM"}
                        >
                          {"CCM"}
                        </Tag>
                      )}
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  mode="multiple"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter && filter.value}
                  placeholder="All"
                  showSearch
                  optionLabelProp="label"
                >
                  {ProgramOptions.map(e => (
                    <Option
                      value={e.value}
                      key={e.value}
                      label={<Tag color={e.color}>{e.tagText}</Tag>}
                    >
                      <CircleColor color={e.color} />
                      {e.text}
                    </Option>
                  ))}
                </Select>
              )
            },
            {
              style: {
                textAlign: "left",
                paddingLeft: 15
              },
              Header: (
                <>
                  <Popover placement="top" content={<DeviceStatusDesc />}>
                    Device Status
                  </Popover>
                  <div
                    style={{ cursor: "pointer", marginTop: 6 }}
                    title="sort by device status"
                  >
                    {["Socks", "Move"].map((device, index) => {
                      const stateKey = `${device.toLowerCase()}Sort`;
                      return (
                        <React.Fragment key={device}>
                          {index ? <Divider type="vertical" /> : null}
                          <span onClick={() => this.deviceSort(device)}>
                            {device}
                            <SortAscendingOutlined
                              style={{
                                display:
                                  this.state[stateKey] === "asc"
                                    ? "inline-block"
                                    : "none"
                              }}
                            />
                            <SortDescendingOutlined
                              style={{
                                display:
                                  this.state[stateKey] === "desc"
                                    ? "inline-block"
                                    : "none"
                              }}
                            />
                          </span>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              ),
              accessor: "deviceStatus",
              minWidth: 200,
              sortable: false,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    {props.original.rpmDeviceStatus && (
                      <RpmDeviceStatus
                        deviceStatus={props.original.rpmDeviceStatus}
                        userId={props.original.userId}
                        id={props.original.id}
                        userInfo={props.original}
                        onChange={status => {
                          let patientData = [...this.state.data];
                          patientData[props.index].rpmDeviceStatus = status;
                          this.setState({ data: patientData });
                        }}
                      />
                    )}
                    {props.original.rtmDeviceStatus && (
                      <RtmDeviceStatus
                        deviceStatus={props.original.rtmDeviceStatus}
                        userId={props.original.userId}
                        id={props.original.id}
                        userInfo={props.original}
                        onChange={status => {
                          let patientData = [...this.state.data];
                          patientData[props.index].rtmDeviceStatus = status;
                          this.setState({ data: patientData });
                        }}
                      />
                    )}
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  mode="multiple"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter && filter.value}
                  showSearch
                  placeholder="All"
                  optionLabelProp="label"
                >
                  {DeviceOptions.map(e => (
                    <Option
                      value={e.value}
                      key={e.value}
                      label={<Tag color={e.color}>{e.tagText}</Tag>}
                    >
                      <CircleColor color={e.color} />
                      {e.text}
                    </Option>
                  ))}
                </Select>
              )
            },
            {
              style: { cursor: "pointer" },
              Header: "Billing",
              accessor: "accountBillingStatus",
              minWidth: 130,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <ChangeBillingStatus
                      userId={props.original.userId}
                      accountBillingStatus={props.original.accountBillingStatus}
                      id={props.original.id}
                      userInfo={props.original}
                      onChange={status => {
                        let patientData = [...this.state.data];
                        patientData[props.index].accountBillingStatus = status;
                        this.setState({ data: patientData });
                      }}
                    />
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Active">Active</Option>
                  <Option value="Not active">Not active</Option>
                </Select>
              )
            },
            {
              style: { cursor: "pointer" },
              accessor: "enableNotification",
              Header: "Notifications",
              filterable: true,
              sortable: false,
              show:
                isAdminOrDeveloper || this.props.userGroups.includes("staff"),
              maxWidth: 200,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <AllNotificationStatus
                      enableNotification={props.original.enableNotification}
                      userId={props.original.userId}
                      id={props.original.id}
                      userInfo={props.original}
                      onChange={input => {
                        this.props.updatePatientPushSuc(this, input);
                      }}
                    />
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">ON</Option>
                  <Option value="No">OFF</Option>
                </Select>
              )
            },
            {
              style: { cursor: "pointer" },
              accessor: "enableFootStatusPush",
              Header: "Foot Status Push",
              filterable: true,
              sortable: false,
              show:
                isAdminOrDeveloper || this.props.userGroups.includes("staff"),
              maxWidth: 200,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <FootStatusPushStatus
                      enableFootStatusPush={props.original.enableFootStatusPush}
                      userInfo={props.original}
                      onChange={input => {
                        this.props.updatePatientPushSuc(this, input);
                      }}
                    />
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">ON</Option>
                  <Option value="No">OFF</Option>
                </Select>
              )
            },
            {
              style: { cursor: "pointer" },
              accessor: "enableReminderPush",
              Header: "Reminder Push",
              filterable: true,
              sortable: false,
              show:
                isAdminOrDeveloper || this.props.userGroups.includes("staff"),
              maxWidth: 200,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <ReminderPushStatus
                      enableReminderPush={props.original.enableReminderPush}
                      userInfo={props.original}
                      onChange={input => {
                        this.props.updatePatientPushSuc(this, input);
                      }}
                    />
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">ON</Option>
                  <Option value="No">OFF</Option>
                </Select>
              )
            },
            {
              Header: "Actions",
              sortable: false,
              filterable: false,
              minWidth: 386,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <EditPatient
                      editNewPatient
                      userInfo={props.original}
                      changeSuccess={patient => {
                        const nextData = [...this.state.data];
                        for (let index = 0; index < nextData.length; index++) {
                          const element = nextData[index];
                          if (element.id === patient.id) {
                            nextData.splice(index, 1, patient);
                            break;
                          }
                        }
                        this.setState({
                          data: nextData
                        });
                      }}
                    />
                    {isAdminOrDeveloper && (
                      <DeleteNewPatient
                        id={props.original.primaryKey}
                        userInfo={props.original}
                        successCB={id => {
                          const nextData = this.state.data.filter(
                            p => p.id !== id
                          );
                          this.setState({
                            data: nextData
                          });
                        }}
                      />
                    )}
                    <AppLink
                      email={props.original.email}
                      phone={props.original.phone}
                    />
                    <ReNewPatientPwd id={props.original.primaryKey} />
                  </div>
                );
              }
            }
          ]}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  userGroups: state.authGroupStore.session.userGroups
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  updateNotification: (editInput, self, checked, sirenId, type) =>
    dispatch(
      updateNewPatientNotification(editInput, self, checked, sirenId, type)
    ),
  changeNotificationState: (self, id, checked) =>
    dispatch(changeNotificationState(self, id, checked)),
  updatePatientPushSuc: (self, input) =>
    dispatch(updatePatientPushSuc(self, input))
});
export default connect(mapStateToProp, mapDispatchToProp)(DeviceList);
