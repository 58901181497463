import zlib from "react-zlib-js";
import Buffer from "react-zlib-js/buffer";
import _ from "lodash";
import moment from "moment";

export function parserReportChartData(min30, startDate, endDate) {
  let reg = new RegExp('"', "g");
  let deflateDateRaw = min30.replace(reg, "");
  const inflateDataRaw = zlib
    .inflateSync(new Buffer(deflateDateRaw, "base64"))
    .toString();
  // console.log(inflateDataRaw);
  let leftFootTempRaw = [];
  let rightFootTempRaw = [];
  let leftPositionRaw = [];
  let rightPositionRaw = [];
  let leftStepsRaw = [];
  let rightStepsRaw = [];

  _.forEach(inflateDataRaw.split("|"), function(item) {
    let keyDataRaw = [
      "usertime",
      "foot",
      "toe",
      "mts1",
      "mts3",
      "mts5",
      "arch",
      "heel",
      "gsensorx",
      "gsensory",
      "gsensorz",
      "steps"
    ];
    let objDataRaw = {};

    item.split(",").forEach((subItem, index) => {
      objDataRaw[keyDataRaw[index]] = subItem;
    });
    let objLeftTempRaw = {};
    let objRightTempRaw = {};
    let objLeftPositionRaw = {};
    let objRightPositionRaw = {};
    let objLeftSteps = {};
    let objRightSteps = {};

    if (objDataRaw.foot === "left") {
      Object.assign(objLeftTempRaw, {
        usertime: objDataRaw.usertime,
        toe: objDataRaw.toe,
        mts1: objDataRaw.mts1,
        mts3: objDataRaw.mts3,
        mts5: objDataRaw.mts5,
        arch: objDataRaw.arch,
        heel: objDataRaw.heel
      });
      leftFootTempRaw.push(objLeftTempRaw);

      Object.assign(objLeftPositionRaw, {
        usertime: objDataRaw.usertime,
        gsensorx: objDataRaw.gsensorx,
        gsensory: objDataRaw.gsensory,
        gsensorz: objDataRaw.gsensorz
      });
      leftPositionRaw.push(objLeftPositionRaw);

      Object.assign(objLeftSteps, {
        usertime: objDataRaw.usertime,
        steps: objDataRaw.steps
      });
      leftStepsRaw.push(objLeftSteps);
    } else {
      Object.assign(objRightTempRaw, {
        usertime: objDataRaw.usertime,
        toe: objDataRaw.toe,
        mts1: objDataRaw.mts1,
        mts3: objDataRaw.mts3,
        mts5: objDataRaw.mts5,
        arch: objDataRaw.arch,
        heel: objDataRaw.heel
      });
      rightFootTempRaw.push(objRightTempRaw);

      Object.assign(objRightPositionRaw, {
        usertime: objDataRaw.usertime,
        gsensorx: objDataRaw.gsensorx,
        gsensory: objDataRaw.gsensory,
        gsensorz: objDataRaw.gsensorz
      });
      rightPositionRaw.push(objRightPositionRaw);

      Object.assign(objRightSteps, {
        usertime: objDataRaw.usertime,
        steps: objDataRaw.steps
      });
      rightStepsRaw.push(objRightSteps);
    }
  });
  let leftTemp = convertTempData(leftFootTempRaw, startDate, endDate);
  let rightTemp = convertTempData(rightFootTempRaw, startDate, endDate);
  let leftPosition = convertGsensorData(leftPositionRaw, startDate, endDate);
  let rightPosition = convertGsensorData(rightPositionRaw, startDate, endDate);
  let steps = null;
  if (leftStepsRaw.length > rightStepsRaw) {
    steps = convertStepData(leftStepsRaw, startDate, endDate);
  } else {
    steps = convertStepData(rightStepsRaw, startDate, endDate);
  }
  let delta = [];
  _.forEach(leftTemp, function(item, index) {
    let objDelta = {};
    if (
      item === undefined ||
      item.toe === "-" ||
      rightTemp[index] === undefined ||
      rightTemp[index].toe === "-"
    ) {
      objDelta.usertime = item.usertime;
      objDelta.dtoe = "-";
      objDelta.dmts1 = "-";
      objDelta.dmts3 = "-";
      objDelta.dmts5 = "-";
      objDelta.darch = "-";
      objDelta.dheel = "-";
    } else {
      objDelta.usertime = item.usertime;
      objDelta.dtoe = item.toe - rightTemp[index].toe;
      objDelta.dmts1 = item.mts1 - rightTemp[index].mts1;
      objDelta.dmts3 = item.mts3 - rightTemp[index].mts3;
      objDelta.dmts5 = item.mts5 - rightTemp[index].mts5;
      objDelta.darch = item.arch - rightTemp[index].arch;
      objDelta.dheel = item.heel - rightTemp[index].heel;
    }
    delta.push(objDelta);
  });
  return { delta, steps, leftTemp, rightTemp, leftPosition, rightPosition };
}

function convertTempData(tempData, startDate, endDate) {
  let DEFATLT_INTEVER = 30 * 60 * 1000;
  let arrayB = [];
  for (let i = 0; i < tempData.length; i++) {
    if (i === 0) {
      if (
        moment(tempData[i].usertime.replace("Z", "")) - moment(startDate) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
          toe: "-",
          mts1: "-",
          mts3: "-",
          mts5: "-",
          arch: "-",
          heel: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(tempData[i].usertime.replace("Z", "")) - moment(startDate)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startDate) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            toe: "-",
            mts1: "-",
            mts3: "-",
            mts5: "-",
            arch: "-",
            heel: "-"
          };

          arrayB.push(obj);
        }
      }
      arrayB.push(tempData[0]);
    } else {
      if (
        moment(tempData[i].usertime) - moment(tempData[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(tempData[i]);
      } else {
        let intever = Math.floor(
          (moment(tempData[i].usertime) - moment(tempData[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(tempData[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            toe: "-",
            mts1: "-",
            mts3: "-",
            mts5: "-",
            arch: "-",
            heel: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(tempData[i]);
      }
    }
  }

  if (tempData.length > 0) {
    let inteverSub =
      Math.floor(
        moment(endDate + "T23:59:59") -
          moment(tempData[tempData.length - 1].usertime.replace("Z", ""))
      ) / DEFATLT_INTEVER;
    if (inteverSub > 0) {
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(tempData[tempData.length - 1].usertime.replace("Z", "")) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          toe: "-",
          mts1: "-",
          mts3: "-",
          mts5: "-",
          arch: "-",
          heel: "-"
        };
        arrayB.push(obj);
      }
    }
  }
  return arrayB;
}

function convertGsensorData(gsensorData, startDate, endDate) {
  let DEFATLT_INTEVER = 30 * 60 * 1000;
  let arrayB = [];
  for (let i = 0; i < gsensorData.length; i++) {
    if (i === 0) {
      if (
        moment(gsensorData[i].usertime.replace("Z", "")) - moment(startDate) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
          gsensorx: "-",
          gsensory: "-",
          gsensorz: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(gsensorData[i].usertime.replace("Z", "")) -
            moment(startDate)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startDate) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            gsensorx: "-",
            gsensory: "-",
            gsensorz: "-"
          };

          arrayB.push(obj);
        }
      }
      arrayB.push(gsensorData[0]);
    } else {
      if (
        moment(gsensorData[i].usertime) - moment(gsensorData[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(gsensorData[i]);
      } else {
        let intever = Math.floor(
          (moment(gsensorData[i].usertime) -
            moment(gsensorData[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(gsensorData[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            gsensorx: "-",
            gsensory: "-",
            gsensorz: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(gsensorData[i]);
      }
    }
  }

  if (gsensorData.length > 0) {
    let inteverSub =
      Math.floor(
        moment(endDate + "T23:59:59") -
          moment(gsensorData[gsensorData.length - 1].usertime.replace("Z", ""))
      ) / DEFATLT_INTEVER;
    if (inteverSub > 0) {
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(
              gsensorData[gsensorData.length - 1].usertime.replace("Z", "")
            ) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          gsensorx: "-",
          gsensory: "-",
          gsensorz: "-"
        };
        arrayB.push(obj);
      }
    }
  }
  return arrayB;
}

function deduplication(arr) {
  let newArr = [];
  let end;
  arr.sort();
  end = arr[0];
  newArr.push(arr[0]);
  for (let i = 1; i < arr.length; i++) {
    if (arr[i].usertime !== end.usertime) {
      newArr.push(arr[i]);
      end = arr[i];
    }
  }
  return newArr;
}

function convertStepData(stepsData, startDate, endDate) {
  let newSteps = [];
  let newStepsData = [];
  _.forEach(stepsData, function(item) {
    if (item.steps > 1500) {
      newSteps.push({ usertime: item.usertime, steps: 0 });
    } else {
      newSteps.push(item);
    }
  });
  newStepsData = deduplication(
    newSteps.sort(function(a, b) {
      return new moment(a.usertime) - new moment(b.usertime);
    })
  );

  let DEFATLT_INTEVER = 30 * 60 * 1000;
  let arrayB = [];
  for (let i = 0; i < newStepsData.length; i++) {
    if (i === 0) {
      if (
        moment(newStepsData[i].usertime.replace("Z", "")) - moment(startDate) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
          steps: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(newStepsData[i].usertime.replace("Z", "")) -
            moment(startDate)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startDate) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            steps: "-"
          };

          arrayB.push(obj);
        }
      }
      arrayB.push(newStepsData[0]);
    } else {
      if (
        moment(newStepsData[i].usertime) -
          moment(newStepsData[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(newStepsData[i]);
      } else {
        let intever = Math.floor(
          (moment(newStepsData[i].usertime) -
            moment(newStepsData[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(newStepsData[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            steps: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(newStepsData[i]);
      }
    }
  }

  if (newStepsData.length > 0) {
    let inteverSub =
      Math.floor(
        moment(endDate + "T23:59:59") -
          moment(
            newStepsData[newStepsData.length - 1].usertime.replace("Z", "")
          )
      ) / DEFATLT_INTEVER;
    if (inteverSub > 0) {
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(
              newStepsData[newStepsData.length - 1].usertime.replace("Z", "")
            ) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          steps: "-"
        };
        arrayB.push(obj);
      }
    }
  }
  return arrayB;
}
