import React from "react";
import CircleColor from "../home/circle-color";

const circle_status_icon = color => {
  return {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    margin: "auto",
    verticalAlign: "middle",
    display: "inlineBlock",
    backgroundColor: color
  };
};

export default function ProgramStatusDesc() {
  return (
    <div
      style={{
        maxWidth: 500,
        maxHeight: 600,
        overflowY: "auto",
        paddingLeft: 10,
        paddingRight: 30,
        paddingBottom: 10,
        paddingTop: 10
      }}
    >
      <div style={{ fontWeight: "bold", fontSize: 16 }}>Program Status</div>
      <div style={{ display: "flex", marginTop: 20 }}>
        <div>
          <CircleColor color={"#73D1AE"} />
        </div>{" "}
        <div>
          <strong>On Service</strong> - Patient enrolled
        </div>
      </div>
      <div style={{ display: "flex", marginTop: 20 }}>
        <div>
          <CircleColor color={"#F1BE0A"} />
        </div>{" "}
        <div>
          <strong>Pending</strong> - Pending enrollment
        </div>
      </div>
      <div style={{ display: "flex", marginTop: 20 }}>
        <div>
          <CircleColor color={"#6F7381"} />
        </div>{" "}
        <div>
          <strong>Off Service</strong> - Patient not enrolled
        </div>
      </div>
    </div>
  );
}
