import React, { Component } from "react";
import { connect } from "react-redux";
import FootSvg from "./footSvg";
import "./status.css";
import PatientDetailUrlContext from "../MedicalDashboard/medicaldetails/PatientDetailUrlContext";

class FootStatus extends Component {

  static contextType = PatientDetailUrlContext;

  render() {
    return (
      <div
        className="status_userbox1"
        style={{
          cursor: 'default',
          width: this.props.Developer ? "44%" : this.props.staff ? "100%" : null
        }}
      >
        <div className="footTitle">
          <span className="box1header2">
            Updated:{this.context.param.endDate}
          </span>
        </div>
        <div style={{ height: 406, position: "relative" }}>
          <FootSvg footStatus={this.props.footStatus} />
          {process.env.REACT_APP_ENV === "staging" && (
            <div
              style={{
                width: "30%",
                position: "absolute",
                right: 0,
                bottom: 0
              }}
            >
              <FootSvg footStatus={this.props.cleanedStatus} />
            </div>
          )}
          <div className="footTitle_box">
            <div className="right_footTitle">Bottom Right</div>
            <div className="left_footTitle">Bottom Left</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProp = state => ({
  footStatus: state.patientsListStore.footStatus,
  cleanedStatus: state.patientsListStore.cleanedStatus,
});

export default connect(mapStateToProp, null)(FootStatus);
