import { changeLoadingChartsState } from "./patientAction";
import _api from "../graphql/_api";
import { graphqlOperation, API } from "aws-amplify";
import _ from "lodash";

export function getMacAddrInfo(mac, startDate, endDate, self) {
  return async dispatch => {
    try {
      let battery = [];
      let steps = [];
      let count = [];
      dispatch(changeLoadingChartsState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.getMacInfo(), { mac, startDate, endDate })
      );
      if (newEvent) {
        _.forEach(JSON.parse(newEvent.data.getMacInfo), function(item) {
          battery.push({
            usertime: item.usertime.substring(0, 10),
            battery: item.battery
          });
          steps.push({
            usertime: item.usertime.substring(0, 10),
            steps: item.steps
          });
          count.push({
            usertime: item.usertime.substring(0, 10),
            count: item.count
          });
        });
        self.setState({ battery, steps, count });
        dispatch(changeLoadingChartsState(false));
      }
    } catch (e) {
      dispatch(changeLoadingChartsState(false));
      console.log("getMacAddrInfo error: ", e);
    }
  };
}

export function getTagBatteryInfo(mac, startDate, endDate, self) {
  return async dispatch => {
    try {
      let battery = [];
      let steps = [];
      let count = [];
      dispatch(changeLoadingChartsState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.getTagInfo(), { mac, startDate, endDate })
      );
      if (newEvent) {
        _.forEach(JSON.parse(newEvent.data.getTagInfo), function(item) {
          battery.push({
            usertime: item.usertime.substring(0, 10),
            battery: item.battery
          });
          steps.push({
            usertime: item.usertime.substring(0, 10),
            steps: item.steps
          });
          count.push({
            usertime: item.usertime.substring(0, 10),
            count: item.count
          });
        });
        self.setState({ battery, steps, count });
        dispatch(changeLoadingChartsState(false));
      }
    } catch (e) {
      dispatch(changeLoadingChartsState(false));
      console.log("getMacAddrInfo error: ", e);
    }
  };
}
