import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Icon, Modal, TextArea } from "semantic-ui-react";
import "../share/button.css";
import "./AccountStatus.css";
import ButtonSuccess from "./buttonSuccess";
import _ from "lodash";
import moment from "moment";
import { DatePicker, Switch } from "antd";
import {
  updateNewPatientNotification,
  updatePatientPushSuc
} from "../action/notificationAction";

class AllNotificationStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      note: "",
      endDate: ""
    };
    this.handleConfirm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  show = () => this.setState({ open: true, confirmSuccess: false });
  handleConfirm = async () => {
    let user = await Auth.currentAuthenticatedUser();
    this.setState({ confirmSuccess: true });

    let lastNotification = [];
    let enableNotification =
      this.props.userInfo.enableApp ||
      this.props.userInfo.enableSms ||
      this.props.userInfo.enableEmail;
    if (enableNotification) {
      if (this.props.userInfo.enableApp) {
        lastNotification.push("App");
      }
      if (this.props.userInfo.enableSms) {
        lastNotification.push("SMS");
      }
      if (this.props.userInfo.enableEmail) {
        lastNotification.push("Email");
      }
    } else {
      if (this.props.userInfo.lastNotification) {
        lastNotification = this.props.userInfo.lastNotification.split("-");
      }
    }
    let editInput = null;
    if (enableNotification) {
      editInput = {
        id: this.props.userInfo.id,
        enableApp: false,
        enableEmail: false,
        enableSms: false,
        lastNotification: lastNotification
          .map(t => {
            return t;
          })
          .join("-")
      };
    } else {
      editInput = {
        id: this.props.userInfo.id,
        enableApp:
          lastNotification.length === 0
            ? true
            : lastNotification.includes("App"),
        enableSms:
          lastNotification.length === 0
            ? false
            : lastNotification.includes("SMS"),
        enableEmail:
          lastNotification.length === 0
            ? false
            : lastNotification.includes("Email")
      };
    }

    Object.assign(editInput, {
      author: `${user.attributes.given_name} ${user.attributes.family_name}`
    });
    Object.assign(editInput, {
      authorId: `${user.username}`
    });
    Object.assign(editInput, { note: this.state.note });
    Object.assign(editInput, { allNotificationEndDate: this.state.endDate });
    if (enableNotification) {
      if (_.isEmpty(this.state.endDate)) {
        Object.assign(editInput, { title: "Notifications Turned Off" });
      } else {
        Object.assign(editInput, {
          title: `Notifications Turned Off, ends ${moment(
            this.state.endDate
          ).format("MM/DD/YYYY")} `
        });
      }
    } else {
      Object.assign(editInput, { title: "Notifications Turned On" });
    }
    this.props
      .updateNotification(
        editInput,
        this,
        enableNotification,
        this.props.userInfo.sirenId,
        "Notifications"
      )
      .then(() => {
        if (typeof this.props.onChange === "function") {
          this.props.onChange(editInput);
        }
      });
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  handleInputChange(value) {
    this.setState({ note: value });
  }

  handleEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Switch
            checked={this.props.enableNotification}
            style={{
              marginTop: 5,
              marginRight: 10
            }}
          />
        }
        onClose={this.handleCancel}
        onOpen={this.show}
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>{"Notification Update"}</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <label style={{ marginBottom: 10, fontSize: 14 }}>
                    {this.props.enableNotification
                      ? `All Notification OFF for SirenId ${this.props.userInfo.sirenId}.`
                      : `Notification ON for SirenId ${this.props.userInfo.sirenId}.`}
                  </label>
                  {this.props.enableNotification ? (
                    _.isEmpty(this.state.endDate) ? (
                      <div>
                        <p
                          className="inputLable"
                          style={{ fontWeight: "bold", fontSize: 14 }}
                        >
                          End Date
                        </p>
                        <DatePicker
                          className="inputDateContent"
                          style={{ marginBottom: 10 }}
                          format={"MM/DD/YYYY"}
                          onChange={this.handleEndDateChange}
                          allowClear={false}
                        />
                      </div>
                    ) : (
                      <div>
                        <p
                          className="inputLable"
                          style={{ fontWeight: "bold", fontSize: 14 }}
                        >
                          End Date
                        </p>
                        <DatePicker
                          className="inputDateContent"
                          format={"MM/DD/YYYY"}
                          style={{ marginBottom: 10 }}
                          onChange={this.handleEndDateChange}
                          allowClear={false}
                          value={moment(this.state.endDate, "MM/DD/YYYY")}
                        />
                      </div>
                    )
                  ) : null}
                  <TextArea
                    className="note_comments"
                    onChange={(e, data) => {
                      this.handleInputChange(e.target.value);
                    }}
                    placeholder="Reason for notification change"
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  listPatients: state.patientsListStore.listPatients
});
const mapDispatchToProp = dispatch => ({
  updateNotification: (editInput, self, checked, sirenId, type) =>
    dispatch(
      updateNewPatientNotification(editInput, self, checked, sirenId, type)
    )
});
export default connect(
  mapStateToProp,
  mapDispatchToProp
)(AllNotificationStatus);
