import moment from "moment/moment";
import { parserReportChartData } from "../action/reportChartAction";
import { celToFahrenheit, celToFahrenheitDelta } from "../action/chartAction";
export function getChartOptionsReport(min30, startDate, endDate) {
  let { delta, steps, leftTemp, rightTemp } = parserReportChartData(
    min30,
    startDate,
    endDate
  );
  //usertime
  let dateList = delta.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime
      ? moment(item.usertime.replace("Z", "")).format("MM/DD HH:mm")
      : "-";
  });

  //delta
  let dToe = delta.map(function(item) {
    return celToFahrenheitDelta(item.dtoe);
  });
  let dmts1 = delta.map(function(item) {
    return celToFahrenheitDelta(item.dmts1);
  });
  let dmts3 = delta.map(function(item) {
    return celToFahrenheitDelta(item.dmts3);
  });
  let dmts5 = delta.map(function(item) {
    return celToFahrenheitDelta(item.dmts5);
  });
  let darch = delta.map(function(item) {
    return celToFahrenheitDelta(item.darch);
  });
  let dheel = delta.map(function(item) {
    return celToFahrenheitDelta(item.dheel);
  });

  //steps
  let stepData = steps.map(function(item) {
    return item.steps;
  });

  //left temperature
  let lToe = leftTemp.map(function(item) {
    return celToFahrenheit(item.toe);
  });
  let lMts1 = leftTemp.map(function(item) {
    return celToFahrenheit(item.mts1);
  });
  let lMts3 = leftTemp.map(function(item) {
    return celToFahrenheit(item.mts3);
  });
  let lMts5 = leftTemp.map(function(item) {
    return celToFahrenheit(item.mts5);
  });
  let lArch = leftTemp.map(function(item) {
    return celToFahrenheit(item.arch);
  });
  let lHeel = leftTemp.map(function(item) {
    return celToFahrenheit(item.heel);
  });

  //right temperature
  let rToe = rightTemp.map(function(item) {
    return celToFahrenheit(item.toe);
  });
  let rMts1 = rightTemp.map(function(item) {
    return celToFahrenheit(item.mts1);
  });
  let rMts3 = rightTemp.map(function(item) {
    return celToFahrenheit(item.mts3);
  });
  let rMts5 = rightTemp.map(function(item) {
    return celToFahrenheit(item.mts5);
  });
  let rArch = rightTemp.map(function(item) {
    return celToFahrenheit(item.arch);
  });
  let rHeel = rightTemp.map(function(item) {
    return celToFahrenheit(item.heel);
  });

  let deltaColor = [
    "#4E9FE4",
    "#9AC177",
    "#AC89C4",
    "#EE8C8E",
    "#F9D463",
    "#F2B456"
  ];

  let stepColor = ["#706DAB"];

  let lTempColor = [
    "#4E9FE4",
    "#9AC177",
    "#AC89C4",
    "#EE8C8E",
    "#F9D463",
    "#F2B456"
  ];

  let rTempColor = [
    "#4E9FE4",
    "#9AC177",
    "#AC89C4",
    "#EE8C8E",
    "#F9D463",
    "#F2B456"
  ];
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross"
      }
    },
    color: [...deltaColor, ...stepColor, ...lTempColor, ...rTempColor],
    legend: [
      {
        data: ["dtoe", "dmts1", "dmts3", "dmts5", "darch", "dheel"],
        top: "1%",
        left: 50,
        x: "left"
      },
      {
        data: ["steps"],
        top: "26%",
        left: 50,
        x: "left"
      },
      {
        data: ["ltoe", "lmts1", "lmts3", "lmts5", "larch", "lheel"],
        top: "51%",
        left: 50,
        x: "left"
      },
      {
        data: ["rtoe", "rmts1", "rmts3", "rmts5", "rarch", "rheel"],
        top: "76%",
        left: 50,
        x: "left"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        show: false,
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    axisPointer: {
      //show all data by one line
      link: { xAxisIndex: "" }
    },
    grid: [
      {
        left: 40,
        right: 40,
        height: "17%",
        top: "3%",
        tooltip: {
          trigger: "axis"
        }
      },
      {
        left: 40,
        right: 40,
        top: "28%",
        height: "17%",
        tooltip: {
          trigger: "axis"
        }
      },
      {
        left: 40,
        right: 40,
        top: "53%",
        height: "17%",
        tooltip: {
          trigger: "axis"
        }
      },
      {
        left: 40,
        right: 40,
        top: "78%",
        height: "17%",
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        position: "bottom",
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        axisLabel: {
          interval: 200,
          textStyle: {
            color: "#000"
          }
        }
      },
      {
        gridIndex: 1,
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        position: "bottom",
        axisLabel: {
          interval: 200,
          textStyle: {
            color: "#000"
          }
        }
      },
      {
        gridIndex: 2,
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        position: "bottom",
        axisLabel: {
          interval: 200,
          textStyle: {
            color: "#000"
          }
        }
      },
      {
        gridIndex: 3,
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        position: "bottom",
        axisLabel: {
          interval: 200,
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "Delta(left-right)",
        type: "value",
        axisLine: { onZero: true, show: false },
        // min: -15,
        // max: 15,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [10, 0, 10, 60]
        },
        min: -10,
        max: 10
      },
      {
        gridIndex: 1,
        name: "Steps",
        type: "value",
        max: 1500,
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [10, 10, 10, 0]
        }
      },
      {
        gridIndex: 2,
        name: "Left Foot Temperature",
        type: "value",
        axisLine: { onZero: true, show: false },
        inverse: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        min: celToFahrenheit(20),
        max: celToFahrenheit(45),
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [10, 0, 10, 105]
        }
      },
      {
        gridIndex: 3,
        name: "Right Foot Temperature",
        type: "value",
        axisLine: { onZero: true, show: false },
        inverse: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        min: celToFahrenheit(20),
        max: celToFahrenheit(45),
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [10, 0, 10, 110]
        }
      }
    ],
    series: [
      {
        name: "dtoe",
        type: "line",
        symbolSize: 8,
        xAxisIndex: 0,
        yAxisIndex: 0,
        hoverAnimation: false,
        data: dToe,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4E9FE4"
            }
          }
        },
        markLine: {
          symbol: "none",
          data: [
            {
              yAxis: 4,
              lineStyle: {
                color: "#a88ac3",
                width: 2,
                type: "dashed"
              }
            },
            {
              yAxis: -4,
              lineStyle: {
                color: "#a88ac3",
                width: 2,
                type: "dashed"
              }
            },
            {
              yAxis: 0,
              lineStyle: {
                color: "#748390",
                width: 2,
                type: "dashed"
              }
            }
          ]
        }
      },
      {
        name: "dmts1",
        type: "line",
        symbolSize: 8,
        xAxisIndex: 0,
        yAxisIndex: 0,
        hoverAnimation: false,
        data: dmts1,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#9AC177"
            }
          }
        }
      },
      {
        name: "dmts3",
        type: "line",
        symbolSize: 8,
        xAxisIndex: 0,
        yAxisIndex: 0,
        hoverAnimation: false,
        data: dmts3,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#AC89C4"
            }
          }
        }
      },
      {
        name: "dmts5",
        type: "line",
        symbolSize: 8,
        xAxisIndex: 0,
        yAxisIndex: 0,
        hoverAnimation: false,
        data: dmts5,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#EE8C8E"
            }
          }
        }
      },
      {
        name: "darch",
        type: "line",
        symbolSize: 8,
        xAxisIndex: 0,
        yAxisIndex: 0,
        hoverAnimation: false,
        data: darch,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#F9D463"
            }
          }
        }
      },
      {
        name: "dheel",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: dheel,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#F2B456"
            }
          }
        }
      },
      {
        name: "steps",
        type: "line",
        xAxisIndex: 1,
        yAxisIndex: 1,
        symbolSize: 8,
        hoverAnimation: false,
        data: stepData,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#706DAB"
            }
          }
        }
      },
      {
        name: "ltoe",
        type: "line",
        xAxisIndex: 2,
        yAxisIndex: 2,
        symbolSize: 8,
        hoverAnimation: false,
        data: lToe,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4E9FE4"
            }
          }
        }
      },
      {
        name: "lmts1",
        type: "line",
        xAxisIndex: 2,
        yAxisIndex: 2,
        symbolSize: 8,
        hoverAnimation: false,
        data: lMts1,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#9AC177"
            }
          }
        }
      },
      {
        name: "lmts3",
        type: "line",
        xAxisIndex: 2,
        yAxisIndex: 2,
        symbolSize: 8,
        hoverAnimation: false,
        data: lMts3,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ac89c4"
            }
          }
        }
      },
      {
        name: "lmts5",
        type: "line",
        xAxisIndex: 2,
        yAxisIndex: 2,
        symbolSize: 8,
        hoverAnimation: false,
        data: lMts5,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ee8c8e"
            }
          }
        }
      },
      {
        name: "larch",
        type: "line",
        xAxisIndex: 2,
        yAxisIndex: 2,
        symbolSize: 8,
        hoverAnimation: false,
        data: lArch,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#f9d463"
            }
          }
        }
      },
      {
        name: "lheel",
        type: "line",
        xAxisIndex: 2,
        yAxisIndex: 2,
        symbolSize: 8,
        hoverAnimation: false,
        data: lHeel,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#f2b456"
            }
          }
        }
      },
      {
        name: "rtoe",
        type: "line",
        xAxisIndex: 3,
        yAxisIndex: 3,
        symbolSize: 8,
        hoverAnimation: false,
        data: rToe,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4E9FE4"
            }
          }
        }
      },

      {
        name: "rmts1",
        type: "line",
        xAxisIndex: 3,
        yAxisIndex: 3,
        symbolSize: 8,
        hoverAnimation: false,
        data: rMts1,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#9AC177"
            }
          }
        }
      },
      {
        name: "rmts3",
        type: "line",
        xAxisIndex: 3,
        yAxisIndex: 3,
        symbolSize: 8,
        hoverAnimation: false,
        data: rMts3,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ac89c4"
            }
          }
        }
      },
      {
        name: "rmts5",
        type: "line",
        xAxisIndex: 3,
        yAxisIndex: 3,
        symbolSize: 8,
        hoverAnimation: false,
        data: rMts5,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ee8c8e"
            }
          }
        }
      },
      {
        name: "rarch",
        type: "line",
        xAxisIndex: 3,
        yAxisIndex: 3,
        symbolSize: 8,
        hoverAnimation: false,
        data: rArch,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#f9d463"
            }
          }
        }
      },
      {
        name: "rheel",
        type: "line",
        xAxisIndex: 3,
        yAxisIndex: 3,
        symbolSize: 8,
        hoverAnimation: false,
        data: rHeel,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#f2b456"
            }
          }
        }
      }
    ]
  };
  return option;
}
