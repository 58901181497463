import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import PillBottleCharts from "./pillBottleCharts";
import "../medicaldetails/calendarRange.css";
import "../medicaldetails/MedicalDetailsHome.css";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import Modal from "react-modal";
import {
  getPillBottleDateWorn,
  listPatientPillBottleData,
  showClosePillBottleData
} from "../action/pillBottleAction";
import { Switch } from "antd";
import _ from "lodash";
class calendarPillBottle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      DateRangeDate: [],
      activeYear: "",
      startDate: "",
      activeMonth: "",
      userId: "",
      id: "",
      modalIsOpen: false,
      rating: 0,
      ifOpen: false
    };
    this.DELAY = 24 * 60 * 60 * 1000;
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  goPreDay = () => {
    const preDay = [
      new Date(moment(this.state.DateRangeDate[0]).add(-1, "days")),
      new Date(moment(this.state.DateRangeDate[0]).add(-1, "days"))
    ];
    this.setState({ DateRangeDate: preDay });
    const { getPillBottleData } = this.props;
    getPillBottleData(this, this.state.id, this.state.userId, preDay);
  };
  goNextDay = () => {
    const NextDay = [
      new Date(moment(this.state.DateRangeDate[1]).add(1, "days")),
      new Date(moment(this.state.DateRangeDate[1]).add(1, "days"))
    ];
    this.setState({ DateRangeDate: NextDay });
    const { getPillBottleData } = this.props;
    getPillBottleData(this, this.state.id, this.state.userId, NextDay);
  };
  onChangeDateRange = DateRangeDate => {
    const { getPillBottleData } = this.props;
    this.setState({ DateRangeDate, ifOpen: true });
    getPillBottleData(this, this.state.id, this.state.userId, DateRangeDate);
  };
  componentDidMount() {
    const { getPillBottleData } = this.props;
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const userId = params.userId;
    const id = params.id.replace(" ", "+");
    let startDate = moment(params.startDate);
    if (startDate.isValid()) {
      startDate = startDate.toDate();
    } else {
      startDate = new Date();
    }
    let endDate = moment(params.endDate);
    if (endDate.isValid()) {
      endDate = endDate.toDate();
    } else {
      endDate = new Date();
    }
    this.setState({
      DateRangeDate: [startDate, endDate],
      userId,
      id
    });
    getPillBottleData(this, id, userId, [startDate, endDate]);
    const startTimeWorn = moment(moment(startDate).format("YYYY-MM-01"))
      .add(-6, "day")
      .format("YYYY-MM-DD");
    const endTimeWorn = moment(moment(startDate).format("YYYY-MM-01"))
      .add(36, "day")
      .format("YYYY-MM-DD");
    this.props.getPillBottleDateWorn(
      id,
      userId,
      startTimeWorn,
      endTimeWorn,
      this,
      true
    );
  }

  onSwitchChange = checked => {
    this.props.showClosePillBottleData();
  };

  turnChange = ({ activeStartDate }) => {
    const startDate = moment(activeStartDate)
      .add(-6, "day")
      .format("YYYY-MM-DD");
    const endDate = moment(activeStartDate)
      .add(36, "day")
      .format("YYYY-MM-DD");
    this.props.getPillBottleDateWorn(
      this.state.id,
      this.state.userId,
      startDate,
      endDate,
      this
    );
  };

  dateRangeClassName = () => ({ date, view }) => {
    let getDate = moment(date).format("YYYY-MM-DD");
    if (
      this.props.pillBottleDateWorn &&
      this.props.pillBottleDateWorn.length > 0
    ) {
      let markTimeWorn = "";
      _.forEach(this.props.pillBottleDateWorn, function(item) {
        if (item.date === getDate) {
          markTimeWorn = "timeMore";
        }
      });
      if (markTimeWorn !== "") {
        return markTimeWorn;
      }
    }
  };

  render() {
    return (
      <div id="user-info-container3">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div>
          <div className="urlbox">
            <Button
              basic
              className="change_calenDay_button"
              onClick={this.goPreDay}
            >
              <Icon name="angle left" />
              Previous day
            </Button>
            <DateRangePicker
              onChange={this.onChangeDateRange}
              value={[this.state.DateRangeDate[0], this.state.DateRangeDate[1]]}
              clearIcon=""
              minDetail="month"
              locale="en"
              isOpen={this.state.ifOpen}
              tileClassName={this.dateRangeClassName()}
              onActiveDateChange={this.turnChange}
            />
            <Button
              basic
              className="change_calenDay_button"
              onClick={this.goNextDay}
            >
              Next day
              <Icon name="angle right" />
            </Button>
          </div>
          <div style={{ float: "right", marginTop: -50, display: "flex" }}>
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={this.props.showClosePillBottle}
              onChange={this.onSwitchChange}
            />
          </div>
        </div>
        <PillBottleCharts />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  showClosePillBottle: state.patientsListStore.showClosePillBottle,
  pillBottleDateWorn: state.patientsListStore.pillBottleDateWorn
});
const mapDispatchToProp = dispatch => ({
  getPillBottleData: (self, id, userId, dateRange) =>
    dispatch(listPatientPillBottleData(self, id, userId, dateRange)),
  showClosePillBottleData: () => dispatch(showClosePillBottleData()),
  getPillBottleDateWorn: (id, userId, startDate, endDate, self, needClear) =>
    dispatch(
      getPillBottleDateWorn(id, userId, startDate, endDate, self, needClear)
    )
});
export default connect(mapStateToProp, mapDispatchToProp)(calendarPillBottle);
