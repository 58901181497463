import mixpanel from "mixpanel-browser";
/**
 *  Created by shengli.zhang on 4/20/21 3:18 PM
 */

mixpanel.init("8d6b140a8c2907a6b2b46626a92b5683");

const actions = {
  identify: id => {
    mixpanel.identify(id);
  },
  alias: id => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: props => {
      mixpanel.people.set(props);
    }
  }
};

export let Mixpanel = actions;
