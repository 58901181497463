import { message } from "antd";
import React, { Component } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { deleteNewPatients } from "../action/patientAction";
import "../share/button.css";
import ButtonSuccess from "./buttonSuccess";

export default class deletePatientForever extends Component {

  state = { open: false, confirmSuccess: false };

  show = () => this.setState({ open: true, confirmSuccess: false });

  handleConfirm = () => {
    this.setState({ confirmSuccess: true });
    const id = this.props.id;
    deleteNewPatients(id).then(() => {
      this.setState({
        open: false
      });
      if (this.props.onDeleted) {
        this.props.onDeleted(id);
      }
    }).catch((error) => {
      message.error(error.message)
    });
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });
  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini" className="deleteButton">
            Delete
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <p>Are you sure you want to permanently delete this patient?</p>
              <div>
                <p className="cofirmSiteId">Name:<strong className="cofirmBold">{this.props.userInfo.firstName} {this.props.userInfo.lastName}</strong></p>
                <p>Phone:<strong className="cofirmBold">{this.props.id}</strong></p>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
