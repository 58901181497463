import _ from "lodash";
/**
 *  Created by shengli.zhang on 4/4/21 6:16 PM
 */

export function assignLpn(allPatient, allLpns) {
  const lpnList = allLpns.map(p => {
    const rate = parseFloat(p.patientCount / p.patientLoad);
    return {
      ...p,
      rate
    };
  });

  for (const patient of allPatient) {
    const siteId = patient.siteId;
    //get all LPNs that can be assigned by this patient
    const assignLPNs = _.filter(lpnList, function(t) {
      return (
        JSON.parse(t.primarySites).includes(siteId) &&
        t.patientCount < parseInt(t.patientLoad)
      );
    });
    //get the minimum rate of the LPN
    const assignLPN = _.minBy(assignLPNs, "rate");
    if (assignLPN) {
      //recalculate the patientCount and rate of this assigned LPN
      assignLPN.patientCount++;
      assignLPN.rate = assignLPN.patientCount / assignLPN.patientLoad;
      //save this patient to the assigned LPN, and conveniently to see which patients are assigned to each LPN
      if (assignLPN.patients) {
        assignLPN.patients.push({
          sirenId: patient.sirenId,
          userId: patient.userId,
          siteId: patient.siteId,
          LPN: assignLPN.id,
          id: patient.id
        });
      } else {
        assignLPN.patients = [
          {
            sirenId: patient.sirenId,
            userId: patient.userId,
            siteId: patient.siteId,
            LPN: assignLPN.id,
            id: patient.id
          }
        ];
      }
    }
  }
  let res = [];
  for (const assignedPatients of lpnList) {
    if (assignedPatients.patients) {
      res = [...res, ...assignedPatients.patients];
    }
  }
  return { res, lpnList };
}
