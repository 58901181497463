import { Select } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { curMonthPatientPCM } from "../action/bliMonthyReportAction";
import { getlistClinicSites } from "../action/registerAction";
import { updateReportCurrentMonthTableFilter } from "../reducers/tableFilterReducer";
import ReportCurrentMonthBase from "./ReportCurrentMonthBase";
import ReportHeader99426_7 from "./ReportHeader99426_7";

const { Option } = Select;

class ReportCurrentMonth99426_7 extends ReportCurrentMonthBase {

  cptCode = "99426-7"

  headerClass = ReportHeader99426_7

  renderTableProps() {
    const data = this.state.tableData.map(p => {
      const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
      const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
      const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
      const billingYear = this.state.billingYear;
      const billingMonth = moment().format("MM/YYYY");
      const endDay = parseInt(
        moment()
          .endOf("month")
          .format("DD")
      );
      const curDay = parseInt(moment().format("DD"));
      const daysRemaining = endDay - curDay;
      const spentTime = p.totalMinutesSpent ? p.totalMinutesSpent : 0;
      const Eligible = spentTime >= 30 ? "Yes" : "No";
      const appInitialized = p.appInitialized ? p.appInitialized : "No";
      const diagnosisCodes = p.diagnosisCodes
        ? p.diagnosisCodes.replace(/_/g, ",")
        : "";
      return {
        ...p,
        createdAt,
        sirenId,
        dispensedDay,
        billingYear,
        billingMonth,
        spentTime,
        Eligible,
        daysRemaining,
        diagnosisCodes,
        appInitialized
      };
    })

    const columns = [
      {
        Header: "SiteID",
        accessor: "siteId",
        maxWidth: 60,
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        minWidth: 100,
      },
      {
        Header: "Doctor",
        accessor: "doctorName",
        minWidth: 100,
      },
      {
        Header: "SirenID",
        accessor: "sirenId",
        maxWidth: 60,
      },
      {
        Header: "PatientID",
        accessor: "patientId",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Date of Birth",
        accessor: "birthday",
      },
      {
        Header: "ICD-10 Codes",
        accessor: "diagnosisCodes",
      },
      {
        Header: "Account Created",
        accessor: "createdAt",
      },
      {
        Header: "App Activated",
        accessor: "appInitialized",
        maxWidth: 72,
        Filter: ({ filter, onChange }) => (
          <Select
            className="select_common_style"
            onChange={onChange}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
            showSearch
            optionFilterProp="children"
          >
            <Option value="">All</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
        )
      },
      {
        Header: "Monitoring Month",
        accessor: "billingMonth",
      },
      {
        Header: "Total Time (mins)",
        accessor: "spentTime",
      },
      {
        Header: "Days Remaining",
        accessor: "daysRemaining",
      }
    ]

    return { data, columns }
  }

}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  tableDefaultState: state.tableFilter.report99426_7CurrentMonth,
});

const mapDispatchToProp = dispatch => ({
  searchCurrentMonthPatients: (billingInputValue) =>
    dispatch(curMonthPatientPCM(billingInputValue)),
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  updateTableDefaultState: (cptCode, state) => dispatch(updateReportCurrentMonthTableFilter(cptCode, state)),
});

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(ReportCurrentMonth99426_7);
