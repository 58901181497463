import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { getDeleteSite } from "../../action/registerAction";
import ButtonSuccess from "../../home/buttonSuccess";
import "../../share/button.css";
import "./DeleteSite.css";
class DeleteSite extends Component {
  constructor(props) {
    super(props);
    this.handleConfirm.bind(this);
  }
  state = { open: false, confirmSuccess: false };

  show = () => this.setState({ open: true, confirmSuccess: false });
  handleConfirm = () => {
    this.setState({ confirmSuccess: true });
    const { getDeleteSite } = this.props;
    const siteId = this.props.siteItem;
    getDeleteSite(siteId, this);
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });
  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini" className="deleteButton">
            Delete
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <p>Are you sure you want to permanently delete this site?</p>
              <div>
                <p className="cofirmSiteId">
                  SiteID:
                  <strong className="cofirmBold">{this.props.siteItem}</strong>
                </p>
                <p>
                  SiteName:
                  <strong className="cofirmBold">{this.props.siteName}</strong>
                </p>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  listStaff: state.patientsListStore.listStaff
});
const mapDispatchToProp = dispatch => ({
  getDeleteSite: (siteId, self) => dispatch(getDeleteSite(siteId, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(DeleteSite);
