import React, { Component } from "react";
import { connect } from "react-redux";
import {
  changeCalendarData,
  fetchCalendarDate,
} from "../action/patientAction";
import { changeRenderDate } from "../action/chartAction";
import moment from "moment";
import DetailsReportCharts from "../chart/reportCharts";
class reportCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      DateRangeDate: [],
      ifOpen: false,
      activeYear: "",
      startDate: "",
      activeMonth: "",
      userId: ""
    };
    this.DELAY = 24 * 60 * 60 * 1000;
  }

  onChangeDateRange = DateRangeDate => {
    const { changeRenderDate, fetchCalendarDate } = this.props;
    this.setState({ DateRangeDate });
    this.setState({ ifOpen: true });
    changeRenderDate(this, this.state.userId, DateRangeDate);
    fetchCalendarDate(new Date(DateRangeDate[1]));
  };
  async componentDidMount() {
    // const { changeRenderDate, fetchCalendarDate } = this.props;
    // const id = this.props.userId;
    // this.setState({ userId: id });
    // const StartDate = this.props.StartDate;
    // const EndDate = this.props.EndDate;
    // const DateRangeDate_startDate = StartDate
    //   ? StartDate
    //   : moment().format("YYYY-MM-DD");
    // const DateRangeDate_endDate = EndDate
    //   ? EndDate
    //   : moment().format("YYYY-MM-DD");
    // this.setState({
    //   DateRangeDate: [
    //     new Date(moment(DateRangeDate_startDate).format("MM/DD/YYYY")),
    //     new Date(moment(DateRangeDate_endDate).format("MM/DD/YYYY"))
    //   ],
    //   userId: id
    // });
    // changeRenderDate(this, id, [
    //   new Date(moment(DateRangeDate_startDate).format("MM/DD/YYYY")),
    //   new Date(moment(DateRangeDate_endDate).format("MM/DD/YYYY"))
    // ]);
    // fetchCalendarDate(
    //   new Date(moment(DateRangeDate_endDate).format("MM/DD/YYYY"))
    // );
    // this.getCurrentMonthCalendarDate(
    //   id,
    //   DateRangeDate_startDate,
    //   DateRangeDate_endDate
    // );
  }
  getCurrentMonthCalendarDate = (
    id,
    DateRangeDate_startDate,
    DateRangeDate_endDate
  ) => {
    const { changeCalendarData } = this.props;
    let StartDate = moment(DateRangeDate_startDate)
      .startOf("month")
      .add(-6, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment(DateRangeDate_endDate)
      .endOf("month")
      .add(35, "days")
      .format("YYYY-MM-DD");
    changeCalendarData(id, StartDate, EndDate);
  };
  turnChange = ({ activeStartDate, view }) => {
    const { changeCalendarData } = this.props;
    let StartDate = moment(activeStartDate)
      .add(-6, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment(activeStartDate)
      .add(35, "days")
      .format("YYYY-MM-DD");
    changeCalendarData(this.state.userId, StartDate, EndDate);
  };
  render() {
    // const daterangeClassName = ({ date, view }) => {
    //   let getDate = moment(date).format("YYYY-MM-DD");
    //   let activemonth = new Date().getMonth() + 1;
    //   if (
    //     this.props.calendardata &&
    //     this.props.calendardata.hasOwnProperty("footStatuses")
    //   ) {
    //     let markStatus = "";
    //     let markTimeWorn = "";
    //     _.forEach(this.props.calendardata.footStatuses, function(footStatus) {
    //       if (footStatus.date === getDate) {
    //         if (footStatus.overall === "NORMAL") {
    //           if (parseInt(footStatus.date.split("-")[1]) === activemonth) {
    //             markStatus = "normalmetabase2";
    //           } else {
    //             markStatus = "normalmetabase";
    //           }
    //         } else if (footStatus.overall === "YELLOW") {
    //           if (parseInt(footStatus.date.split("-")[1]) === activemonth) {
    //             markStatus = "yellowmetabase2";
    //           } else {
    //             markStatus = "yellowmetabase";
    //           }
    //         } else if (footStatus.overall === "RED") {
    //           if (parseInt(footStatus.date.split("-")[1]) === activemonth) {
    //             markStatus = "redmetabase2";
    //           } else {
    //             markStatus = "redmetabase";
    //           }
    //         }
    //       }
    //     });
    //     if (markStatus !== "") {
    //       return markStatus;
    //     }
    //
    //     _.forEach(this.props.calendardata.timeWornByDay, function(item) {
    //       if (item.date.split("T")[0] === getDate && item.minuteWorn >= 60) {
    //         return (markTimeWorn = getDate);
    //       }
    //     });
    //     if (markTimeWorn !== "") {
    //       return "timeMore";
    //     }
    //   }
    // };
    return (
      <div className="details-report-calendar">
        {/*<div>*/}
        {/*  <div className="urlbox">*/}
        {/*    <DateRangePicker*/}
        {/*      onChange={this.onChangeDateRange}*/}
        {/*      value={[this.state.DateRangeDate[0], this.state.DateRangeDate[1]]}*/}
        {/*      tileClassName={daterangeClassName}*/}
        {/*      clearIcon=""*/}
        {/*      minDetail="month"*/}
        {/*      locale="en"*/}
        {/*      isOpen={this.state.ifOpen}*/}
        {/*      onActiveDateChange={this.turnChange}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*{this.state.userId ? (*/}
        <DetailsReportCharts
          min30={this.props.min30}
          StartDate={this.props.StartDate}
          EndDate={this.props.EndDate}
        />
        {/*) : (*/}
        {/*  <div className="complianceBlank" />*/}
        {/*)}*/}
      </div>
    );
  }
}
const mapStateToProp = state => ({
  medicalStatus: state.patientsListStore.medicalStatus,
  calendardata: state.patientsListStore.calendardata,
  delta: state.patientsListStore.delta,
});
const mapDispatchToProp = dispatch => ({
  changeCalendarData: (userId, startDate, endDate) =>
    dispatch(changeCalendarData(userId, startDate, endDate)),
  fetchCalendarDate: changeFootDate =>
    dispatch(fetchCalendarDate(changeFootDate)),
  changeRenderDate: (self, userId, dateRender) =>
    dispatch(changeRenderDate(self, userId, dateRender))
});
export default connect(mapStateToProp, mapDispatchToProp)(reportCalendar);
