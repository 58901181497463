import { DatePicker, Modal as AntdModal, notification, Select } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { Button, Icon, Input } from "semantic-ui-react";
import { updateProgramStatus } from "../action/programStatusAction";
import moment from "moment";

const { Option } = Select;
const dateFormat = "ddd, Do MMM YYYY";
export default class ProgramStatus extends Component {
  state = {
    program: "",
    programStatus: "",
    sirenId: "",
    loading: false,
    endDate: moment().format("YYYY-MM-DD")
  };

  changeProgram = value => {
    this.setState({ program: value });
  };

  changeProgramStatus = value => {
    this.setState({ programStatus: value });
  };

  onChangeSirenId = event => {
    this.setState({ sirenId: event.target.value });
  };

  clear = () => {
    this.setState({ sirenId: "", program: "", programStatus: "" });
  };

  submit = () => {
    if (_.isEmpty(this.state.sirenId)) {
      this.warning(`SirenId can't be empty.`);
      return;
    }
    if (_.isEmpty(this.state.program)) {
      this.warning(`Program can't be empty.`);
      return;
    }
    if (_.isEmpty(this.state.programStatus)) {
      this.warning(`Program Status can't be empty.`);
      return;
    }

    const UpdateProgramStatusInput = {
      input: {
        sirenId: parseInt(this.state.sirenId),
        program: this.state.program,
        programStatus: this.state.programStatus
      }
    };
    if (this.state.programStatus === "NOT_ENROLLED") {
      if (_.isEmpty(this.state.endDate)) {
        AntdModal.warning({
          title: "EndDate Error",
          content: "Please select endDate.",
          centered: true
        });
        return;
      }
      Object.assign(UpdateProgramStatusInput.input, {
        endDate: moment(this.state.endDate).format("MM/DD/YYYY")
      });
    }
    updateProgramStatus(UpdateProgramStatusInput)
      .then(() => {
        notification.success({
          message: "Set program status successfully"
        });
      })
      .catch(e => {
        notification.error({
          message: "System Error",
          description: JSON.stringify(e)
        });
      });
  };

  warning = content => {
    AntdModal.warning({
      title: "Warning",
      content,
      centered: true
    });
  };

  onDateChanged = date => {
    if (date) {
      this.setState({ endDate: moment(date).format("YYYY-MM-DD") });
    }
  };

  render() {
    return (
      <div className="device-list-container">
        <div
          style={{
            textAlign: "left",
            marginTop: 10,
            fontWeight: "bold",
            marginRight: 10,
            marginLeft: 100
          }}
        >
          SirenId:
        </div>
        <Input
          style={{ width: 250, float: "left", marginTop: 5, marginLeft: 100 }}
          value={this.state.sirenId}
          placeholder="Ex.288"
          onChange={this.onChangeSirenId}
        />
        <div
          style={{
            textAlign: "left",
            marginTop: 60,
            fontWeight: "bold",
            marginRight: 10,
            width: 250,
            marginLeft: 100
          }}
        >
          Program:
        </div>
        <Select
          style={{ float: "left", marginTop: 5, marginLeft: 100 }}
          className="selectStyle"
          onChange={this.changeProgram}
          value={this.state.program}
          showSearch
          optionFilterProp="children"
        >
          <Option value="" />
          <Option value="CCM">CCM</Option>
          <Option value="PCM">PCM</Option>
          <Option value="RPM">RPM</Option>
          <Option value="RTM">RTM</Option>
        </Select>
        <div
          style={{
            textAlign: "left",
            marginTop: 60,
            fontWeight: "bold",
            marginRight: 10,
            width: 250,
            marginLeft: 100
          }}
        >
          Program Status:
        </div>
        <Select
          style={{ float: "left", marginTop: 5, marginLeft: 100 }}
          className="selectStyle"
          onChange={this.changeProgramStatus}
          value={this.state.programStatus}
          showSearch
          optionFilterProp="children"
        >
          <Option value="" />
          <Option value="PENDING">Pending</Option>
          <Option value="ENROLLED">Enrolled</Option>
          <Option value="NOT_ENROLLED">Not Enrolled</Option>
        </Select>
        <div
          style={{
            marginTop: 60,
            display:
              this.state.programStatus === "NOT_ENROLLED" ? "block" : "none"
          }}
        >
          <div
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginRight: 10,
              marginLeft: 100
            }}
          >
            EndDate:
          </div>
          <DatePicker
            onChange={this.onDateChanged}
            value={moment(this.state.endDate)}
            format={dateFormat}
            style={{
              width: 250,
              marginTop: 10,
              float: "left",
              marginLeft: 100
            }}
            allowClear={false}
          />
        </div>
        <div
          style={{
            textAlign: "left",
            marginTop: 80,
            fontWeight: "bold",
            marginRight: 10,
            width: 250,
            marginLeft: 120
          }}
        >
          <Button
            loading={this.state.loading}
            color="green"
            onClick={this.submit}
          >
            <Icon name="checkmark" />
            OK
          </Button>
          <Button color="red" style={{ marginLeft: 20 }} onClick={this.clear}>
            <Icon name="remove" />
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
