import {
	InfoCircleOutlined
} from '@ant-design/icons';
import { Button, notification, Popconfirm, Popover, Select } from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import moment_tz from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import DateInput from "../../../common/DateInput";
import { getSMSDripListAPI } from "../../../graphql/API";
import { downloadCSV } from "../../../tools/downloadFile";
import {
	InputEnterKeyTriggerFilterComponent
} from "../../../tools/reactTablePatch";
import "./sms-drip.css";

const { Option } = Select;

const DateFormat = "YYYY-MM-DD HH:mm:ss";

const StatusEnum = ['SMS_01', 'SMS_02', 'SMS_03', 'SMS_04', 'SMS_05', 'SMS_06', 'SMS_07', 'SMS_08', 'SMS_09', 'SMS_ABORT', 'SMS_FINISH']

class SmsDrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      listData: [],
			downloading: false
    };

    // table state is not full controlled. filter, sort is controlled by table itself
    this.tableInstance = null;
    this.debounceRequest = false;
		this.nextToken = null;
  }

  reqPatientList = () => {
    this.debounceReqPatientList();
    if (!this.debounceRequest) {
      this.debounceReqPatientList.flush();
    }
  }

	getRequestFilter = () => {
		let filter = this.tableInstance.state.filtered.reduce((result, item) => {
			// remove empty condition
			item.value = item.value.trim();
			if (!item.value) {
				return result;
			}
			switch (item.id) {
				case "updatedAt":
				case "createdAt": {
					result[item.id] = {
						beginsWith: item.value
					};
					break;
				}
				case "firstName": {
					result[item.id] = {
						contains: item.value
					};
					break;
				}
				case "status": {
					if (item.value ==="all") {
						break;
					}
				}
				default:
					result[item.id] = {
						eq: item.value
					};
					break;
			}
			return result;
		}, {})
		if (_.isEmpty(filter)) {
			filter = null;
		}

		return filter
	}

  debounceReqPatientList = _.debounce(() => {
		let filter = this.getRequestFilter();

		// auto request next page data, until data count is over limit or no more data
		const now = Date.now(), timeout = 30000;
		let dataCount = 0;

		const dummyRequest = () => {
			this.rawReqPatientList(filter, this.tableInstance.state.pageSize, this.nextToken)
				.then(data => {
					if (Date.now() - now > timeout) {
						return;
					}
					dataCount += data.items.length;
					if (dataCount < this.tableInstance.state.pageSize && data.nextToken) {
						dummyRequest();
					}
				});
		}

		dummyRequest();
  }, 3000);

  rawReqPatientList(filter, limit, nextToken) {
    this.debounceRequest = false;
    this.setState({
      loading: true
    });

    return getSMSDripListAPI(filter, limit, nextToken)
      .then(data => {
				if (this.nextToken === null) {
					// initial state
					this.setState({
						listData: data.items
					})
				} else {
					this.setState({
						listData: this.state.listData.concat(data.items)
					})
				}
        this.nextToken = data.nextToken

				return data
      })
      .catch(error => {
        notification.error({
					message: 'System Error',
					description: JSON.stringify(error),
					duration: 0
				})
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

	downloadData = () => {
		const filter = this.getRequestFilter()
		const limit = 500
		let nextToken = null

		let totalResult = []

		const downloadPromise = new Promise((resolve, reject) => {
			let dummyRequest = () => {
				getSMSDripListAPI(filter, limit, nextToken)
					.then(data => {
						nextToken = data.nextToken
						totalResult = totalResult.concat(data.items)
						if (nextToken === null) {
							return resolve(totalResult)
						}

						dummyRequest()
					})
					.catch(error => {
						reject(error)
					})
			}
			dummyRequest()
		})

		this.setState({
			downloading: true
		})
		downloadPromise.then(data => {
			if (data.length === 0) {
				notification.info({
					title: 'no data to download'
				})
				return
			}
			data = data.map(sms => `${sms.id},${sms.SMS_01_Time},${sms.createdAt},${sms.firstName},"${sms.siteId}\t",${sms.status},${sms.updatedAt},${sms.userTimezone},${sms.caseChangeTime},${sms.SMS_02_Time},${sms.waitingOnPatient},${sms.SMS_03_Time},${sms.SMS_04_Time},${sms.SMS_05_Time},${sms.SMS_06_Time},${sms.SMS_07_Time},${sms.SMS_08_Time},${sms.SMS_09_Time}`);
      data.unshift('id,SMS_01_Time,createdAt,firstName,siteId,status,updatedAt,userTimezone,caseChangeTime,SMS_02_Time,waitingOnPatient,SMS_03_Time,SMS_04_Time,SMS_05_Time,SMS_06_Time,SMS_07_Time,SMS_08_Time,SMS_09_Time')

      downloadCSV(data.join('\n'), `SMS-drip-${new Date().toISOString()}.csv`)
		}).catch(error => {
			notification.error({
				message: 'System Error',
				description: JSON.stringify(error),
				duration: 0
			})
		}).finally(() => {
			this.setState({
				downloading: false
			})
		})
	}

  dateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
				style={{textAlign: 'center'}}
        defaultValue={filter ? filter.value : null}
        onPressEnter={value => {
          this.debounceRequest = false;
					this.nextToken = null;
          return onChange(value);
        }}
        onChange={value => {
          this.debounceRequest = true;
					this.nextToken = null;
          return onChange(value);
        }}
      />
    );
  };

  render() {
		const getPaginationProps = () => {
			return {
				canPrevious: !!this.nextToken,
				canNext: !!this.nextToken
			}
		}
    return (
      <div id="SMSDripList">
				<div style={{textAlign: 'left', marginBottom: 12}}>
					<Popconfirm
						title="Are you sure to download all table data?"
						onConfirm={this.downloadData}
						okText="Yes"
						cancelText="No"
					>
						<Button loading={this.state.downloading} title="download all filtered table data">Export Data</Button>
					</Popconfirm>
				</div>
				<ReactTable
					className="-striped -highlight"
					loading={this.state.loading}
					filterable
					sortable={false}
					FilterComponent={InputEnterKeyTriggerFilterComponent({
						onChange: () => {
							this.nextToken = null;
							this.debounceRequest = true;
						},
						onPressEnter: () => {
							this.nextToken = null;
							this.debounceRequest = false;
						}
					})}
					manual
					showPageJump={false}
					getPaginationProps={getPaginationProps}
					nextText="Load More"
					onPageSizeChange={(pageSize) => {
						// fetch data from first page
						this.nextToken = null
					}}
					pages={100}
					data={this.state.listData}
					onFetchData={(_, instance) => {
						// there are some other callbacks, like onPageChange onPageSizeChange etc,
						// we don't care them. this callback is enough
						this.tableInstance = instance;

						this.reqPatientList();
					}}
					columns={[
						{
							Header: "Id",
							accessor: "id"
						},
						{
							Header: "SiteId",
							accessor: "siteId"
						},
						{
							Header: "First Name",
							accessor: "firstName"
						},
						{
							Header: "Status",
							accessor: "status",
							Filter: ({ filter, onChange }) => (
                <Select
									style={{width: '100%'}}
                  onChange={value => {
										this.nextToken = null;
										this.debounceRequest = false;

										return onChange(value)
									}}
                  value={filter ? filter.value : "all"}
                >
                  <Option value="all">All</Option>
									{
										StatusEnum.map(s => (<Option value={s} key={s}>{s}</Option>))
									}
                </Select>
              ),
							Cell: props => {
								const popContent = []
								for (let index = 1; index <= 9; index++) {
									const key = `SMS_0${index}_Time`
									const value = props.original[key]
									if (!value) {
										break
									}
									popContent.push(
										<p key={key}>{key} {moment_tz.tz(value, props.original.userTimezone).format(DateFormat)}</p>
									)
								}
								return (
									<Popover content={popContent} title="SMS history">
										{props.value}
										<InfoCircleOutlined style={{marginLeft: 8, fontWeight: 'bold'}}/>
									</Popover>
								)
							}
						},
						{
							Header: "userTimezone",
							accessor: "userTimezone"
						},
						{
							Header: "Updated Date",
							accessor: "updatedAt",
							minWidth: 100,
							Filter: this.dateInputComponent,
							Cell: props => {
								if (!props.value) {
									return
								}
								return moment_tz.tz(props.value, props.original.userTimezone).format(DateFormat)
							}
						},
						{
							Header: "caseChangeTime",
							accessor: "caseChangeTime"
						},
						{
							Header: "waitingOnPatient",
							accessor: "waitingOnPatient"
						},
						{
							Header: "Created Date",
							accessor: "createdAt",
							minWidth: 100,
							Filter: this.dateInputComponent,
							Cell: props => {
								if (!props.value) {
									return
								}
								return moment_tz.tz(props.value, props.original.userTimezone).format(DateFormat)
							}
						},
					]}
					minRows={0}
				/>
      </div>
    );
  }
}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
});
export default connect(mapStateToProp, mapDispatchToProp)(SmsDrip);
