import * as TYPES from "../types/types";

const initialState = {
  boxList: [],
};

export default function boxList(state = initialState, action) {
  switch (action.type) {
    case TYPES.BOX_LIST_NEW:
      return {
        ...state,
        boxList: action.text
      };
    default:
      return state;
  }
}
