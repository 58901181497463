import { Auth } from "aws-amplify";
import * as TYPES from "../types/types";
import { API, graphqlOperation } from "aws-amplify";
import _api from "../graphql/_api";

export function shipAuthData(authData) {
  return function (dispatch) {
    const {attributes, username, signInUserSession} = authData;

    let profile = {
      loginId: username,
      firstName: attributes["given_name"],
      lastName: attributes["family_name"],
      email: attributes["email"],
      phone: attributes["phone_number"],
      siteId: attributes["custom:siteId"],
      tempUnit: attributes["custom:temp_unit"],
    };

    dispatch({
      type: TYPES.USER_PROFILE,
      profile
    });

    const payload = signInUserSession.idToken.payload;
    dispatch({
      type: TYPES.USER_SESSION,
      session: {
        userGroups: payload["cognito:groups"]
          ? payload["cognito:groups"]
          : [],
        favorites: payload["custom:favorite"]
          ? payload["custom:favorite"].split("_")
          : [],
        emailVerified: payload["email_verified"]
      }
    });

    dispatch({
      type: TYPES.AUTH_GROUP,
      text: payload["cognito:groups"] && payload["cognito:groups"][0]
    });
  }
}

export async function updateUserProfile(dispatch) {
  let user = await Auth.currentAuthenticatedUser();

  let profile = {
    loginId: user.username,
    firstName: user.attributes["given_name"],
    lastName: user.attributes["family_name"],
    email: user.attributes["email"],
    phone: user.attributes["phone_number"],
    siteId: user.attributes["custom:siteId"],
    tempUnit: user.attributes["custom:temp_unit"],
  };

  dispatch({
    type: TYPES.USER_PROFILE,
    profile
  });
}

export async function fullfilSession(dispatch) {
  const session = await Auth.currentSession();
  const token = session.getIdToken();
  dispatch({
    type: TYPES.USER_SESSION,
    session: {
      userGroups: token.payload["cognito:groups"]
        ? token.payload["cognito:groups"]
        : [],
      favorites: token.payload["custom:favorite"]
        ? token.payload["custom:favorite"].split("_")
        : [],
      emailVerified: token.payload["email_verified"]
    }
  });
}

export function updateEnv(env) {
  return dispatch => {
    dispatch(updateEnvStatus(env));
  };
}

function updateEnvStatus(env) {
  return {
    type: TYPES.UPDATE_ENV,
    text: env
  };
}

export async function listICD10Codes(dispatch) {
  const result = await API.graphql(
    graphqlOperation(_api.listICD10Codes())
  );

  dispatch({
    type: TYPES.LIST_ICD_10_CODES,
    payload: result.data.listICD10Codes.sort((a, b) => a.code.localeCompare(b.code))
  })
}

export function createICD10Code(code) {
  return async function(dispatch) {
    await API.graphql(
      graphqlOperation(_api.createICD10Code(), {
        code
      })
    );

    dispatch(listICD10Codes);
  }
}

export function deleteICD10Code(code) {
  return async function(dispatch) {
    await API.graphql(
      graphqlOperation(_api.deleteICD10Code(), {
        code
      })
    );

    dispatch(listICD10Codes);
  }
}

export async function listMedicalConditions(dispatch) {
  const result = await API.graphql(
    graphqlOperation(_api.listMedicalConditions())
  );

  dispatch({
    type: TYPES.LIST_MEDICAL_CONDITIONS,
    payload: result.data.listMedicalConditions.sort((a, b) => a.condition.localeCompare(b.condition))
  })
}

export function createMedicalCondition(condition, value, chronicType) {
  return async function(dispatch) {
    await API.graphql(
      graphqlOperation(_api.createMedicalCondition(), {
        condition,
        value: value || condition.replace(',', '_'),
        chronicType,
      })
    );

    dispatch(listMedicalConditions);
  }
}

export function editMedicalCondition(condition, value, chronicType) {
  return async function(dispatch) {
    await API.graphql(
      graphqlOperation(_api.editMedicalCondition(), {
        condition,
        value: value || condition.replace(',', '_'),
        chronicType,
      })
    );

    dispatch(listMedicalConditions);
  }
}

export function deleteMedicalCondition(condition) {
  return async function(dispatch) {
    await API.graphql(
      graphqlOperation(_api.deleteMedicalCondition(), {
        condition
      })
    );

    dispatch(listMedicalConditions);
  }
}
