import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../SupportDashboard/account/SiteList.css";
import { Select, Input, Modal as AntdModal } from "antd";
import { listShoeTags, updateListShoeTag } from "../action/shoeTagAction";
import Modal from "react-modal";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import "antd/dist/antd.css";
import AddShoeTag from "./AddShoeTag";
import RegisteredShoeTag from "./registerShoeTag";
import UnRegisteredShoeTag from "./unregisterShoeTag";
import moment from "moment";
import _ from "lodash";
class ShoeTagManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.props.listShoeTags(this);
  }

  refreshPage = () => {
    this.props.listShoeTags(this);
  };

  render() {
    const { Option } = Select;
    return (
      <div className="boxlistcontainer">
        <Modal
          isOpen={this.state.open}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="add_import_box" style={{ textAlign: "right" }}>
          <AddShoeTag
            refreshShoeTagList={() => {
              this.refreshPage();
            }}
          />
        </div>
        <ReactTable
          data={this.props.shoeTagList.map(p => {
            const registered = p.registered
              ? p.registered === "YES"
                ? "Yes"
                : "No"
              : "No";
            const createdAt = p.createdAt
              ? moment(p.createdAt).format("MM/DD/YYYY HH:mm")
              : "";
            const registeredAt = p.registeredAt
              ? moment(p.registeredAt).format("MM/DD/YYYY HH:mm")
              : "";
            return {
              ...p,
              registered,
              createdAt,
              registeredAt
            };
          })}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Siren Move Id",
              accessor: "tagId",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.tagId
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Tag",
              accessor: "mac",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.mac
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Auth Code",
              accessor: "authCode",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.authCode
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              style: { cursor: "pointer" },
              accessor: "registered",
              Header: "Registration Status",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                } else {
                  return row[filter.id] === filter.value;
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value={"Yes"}>Yes</Option>
                  <Option value={"No"}>No</Option>
                </Select>
              )
            },
            {
              Header: "Registered to",
              accessor: "sirenId",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.sirenId
                  ? String(row[filter.id]).toLowerCase()
                  : "";
                let filterfullname = String(filter.value).toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Registration Date",
              accessor: "registeredAt",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.registeredAt
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Manufacture Date",
              accessor: "createdAt",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.createdAt
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Actions",
              accessor: "",
              minWidth: 110,
              sortable: false,
              filterable: false,
              Cell: props => {
                return (
                  <div>
                    {props.original.registered === "Yes" ? (
                      <UnRegisteredShoeTag
                        item={props.original}
                        onChange={result => {
                          const nextListData = _.clone(this.props.shoeTagList);
                          const item = _(nextListData).find({
                            tagId: result.tagId
                          });
                          item.registered = "NO";
                          item.userId = "";
                          this.props.updateListShoeTag(nextListData);
                        }}
                      />
                    ) : (
                      <RegisteredShoeTag
                        item={props.original}
                        onChange={result => {
                          const nextListData = _.clone(this.props.shoeTagList);
                          const item = _(nextListData).find({
                            tagId: result.tagId
                          });
                          item.registered = "YES";
                          item.userId = result.userId;
                          this.props.updateListShoeTag(nextListData);
                        }}
                      />
                    )}
                  </div>
                );
              }
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "tagId",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  shoeTagList: state.patientsListStore.shoeTagList
});
const mapDispatchToProp = dispatch => ({
  listShoeTags: (self, tagId) => dispatch(listShoeTags(self, tagId)),
  updateListShoeTag: listShoeTag => dispatch(updateListShoeTag(listShoeTag))
});
export default connect(mapStateToProp, mapDispatchToProp)(ShoeTagManagement);
