import Amplify from "aws-amplify";
import aws_exports from "../aws-exports-siren";
import {
  createClinicSiteAccount,
  createRpmTracker,
  createSirenNote,
  createTrialDoctor,
  createTrialSiteAccount,
  deleteClinicSite,
  deleteDoctor,
  deleteRegisteredPatient,
  deleteRpmTracker,
  deleteSirenNote,
  deleteTrialDoctor,
  deleteTrialSite,
  generateBillingReport,
  registerAccount,
  registerAdminAccount,
  registerDoctorAccount,
  remoteConnect,
  resetAdminPassword,
  resetPatientPassword,
  resetStaffPassword,
  sendAppLink,
  sendDoctorReport,
  sendNotification,
  sendSimulateData,
  sendTestTask,
  setComplianceReviewReq,
  setUserGroup,
  updateAccountStatus,
  updateAutoDoctorReport,
  updateAutoDoctorReportItem,
  updateBilled99453Status,
  updateBilled99454Status,
  updateBilled99457Status,
  updateBilledPatient,
  updateBillingStatus,
  updateClinicSite,
  updateDoctor,
  UpdateNewPatient,
  updateNewRegisterPatient,
  updateRpmTracker,
  updateServiceStatus,
  updateSirenNote,
  updateSockDFU,
  updateSockStatus,
  updateTrialPatient,
  upgradeApp,
  updateAdminStaff,
  deleteAdminStaff,
  updateSensorStatus,
  updateSpecialCaseStatus,
  updatePatientNotification,
  setAppointmented,
  updateLpnForPatients,
  assignLpnForPatients,
  removeLpnForPatients,
  createEMR,
  deleteEMR,
  createFootSymptom,
  createFootCondition,
  createFootTreatment,
  updateFootCondition,
  updateFootTreatment,
  createRpmNoteTemplate,
  updateRpmNoteTemplate,
  deleteRpmNoteTemplate,
  updateAddendum,
  updateHubStatus,
  updateSockBox,
  createShoeTag,
  updateAdminStaffFilter,
  updateAccountBillingStatus,
  dismissReviewTask,
  markSockData,
  updateBoxStatus,
  updateTagSource,
  createPrescription,
  updateDeviceStatus,
  updateProgramStatus,
  updatePatientSingleFoot,
  createPillBottle,
  createCarePlan,
  deleteCarePlan,
  createICD10Code,
  deleteICD10Code,
  createMedicalCondition,
  editMedicalCondition,
  deleteMedicalCondition,
  updateBilledPCMStatus,
  createDoctorReviewHistory,
  generateVaZipReport
} from "./mutations";
import {
  boxPatients,
  checkPatientSocksAndHub,
  docDetail,
  getCalendarData,
  getCalendarData2,
  getDoctorNpi,
  getFoot,
  getMacInfo,
  getTagInfo,
  getPhotoReview,
  getSiteNpi,
  getSock,
  getSockBySirenId,
  getExpireHubs,
  lambdaSearchHubs,
  lambdaSearchAllPatients,
  lambdaSearchPatients,
  listBilling99453Patients,
  listBilling99454Patients,
  listBilling99457Patients,
  // listBillingPatients,
  listBillingSummary,
  listClinicSites,
  listInvoicePatients,
  listManagementSummary,
  managementSummaryStat,
  listPatientMin1,
  listPatientMin1Diff,
  listPatientMin30,
  listPatientMin1SlowStep,
  listRpmTracker,
  listRpmNoteTemplates,
  listRpmNoteTemplatesDetails,
  getCompiledRpmNoteTemplate,
  listStaff,
  listTrialClinicSites,
  listTrialStaff,
  listUserGroups,
  PatientMinuteWorn,
  patientStaff,
  searchableBoxConnection,
  searchDoctorReports,
  searchGlobalPatients,
  verifyBoxInfo,
  checkAllPatientsInSite,
  lambdaSearchSinglePatient,
  listAdminStaff,
  getSirenStaffingPatients,
  getSirenStaffCount,
  getLpnStaff,
  getNeedAssignPatients,
  getWeightScale,
  getEMRList,
  getSMSDripList,
  getPatientActivity,
  listShoeTag,
  listPatientMin1Tag,
  listPatientMin30Tag,
  TagMinuteWorn,
  TagWeekWorn,
  listPrescription,
  listPillBottles,
  listPatientPillBottleData,
  getPillBottleTimeUsage,
  listCarePlans,
  listICD10Codes,
  listMedicalConditions,
  listBillingPCMPatients,
  listDoctorReviewHistory,
  getSockBySerialNo,
  getPatientBySiteId
} from "./queries";
import { onReportGenerated } from "./subscriptions";
//If only use one aws_config, set this to false
const API_SPLIT = true;

const awsAppsync = aws_exports.aws_appsync_graphqlEndpoint;

//shared graphql
const sharedConfig = () => {
  aws_exports.aws_appsync_graphqlEndpoint =
    aws_exports.aws_appsync_shared_graphqlEndpoint;
  return Amplify.configure(aws_exports);
};

const awsConfig = () => {
  aws_exports.aws_appsync_graphqlEndpoint = awsAppsync;
  return Amplify.configure(aws_exports);
};

//call graphql api
const _api = {
  //search patient
  searchPatientsConnection: () => {
    if (API_SPLIT) sharedConfig();
    // return searchPatientsConnection;
    return lambdaSearchPatients;
  },
  checkAllPatientsInSite: () => {
    if (API_SPLIT) sharedConfig();
    // return searchPatientsConnection;
    return checkAllPatientsInSite;
  },
  //get patient info
  // searchpatientInfo: () => {
  //   if (API_SPLIT) sharedConfig();
  //   return searchPatientInfo;
  // },
  //get patient data with 1 min interval
  listPatientMin1: () => {
    if (API_SPLIT) sharedConfig();
    return listPatientMin1;
  },
  listPatientMin1Diff: () => {
    if (API_SPLIT) sharedConfig();
    return listPatientMin1Diff;
  },
  listPatientMin1Tag: () => {
    if (API_SPLIT) sharedConfig();
    return listPatientMin1Tag;
  },
  listPatientPillBottleData: () => {
    if (API_SPLIT) sharedConfig();
    return listPatientPillBottleData;
  },
  listPatientMin1SlowStep: () => {
    if (API_SPLIT) sharedConfig();
    return listPatientMin1SlowStep;
  },
  //get sock battery data
  getMacInfo: () => {
    if (API_SPLIT) sharedConfig();
    return getMacInfo;
  },
  //get tag battery data
  getTagInfo: () => {
    if (API_SPLIT) sharedConfig();
    return getTagInfo;
  },
  //get patient data with 30 min interval
  listPatientMin30: () => {
    if (API_SPLIT) sharedConfig();
    return listPatientMin30;
  },
  listPatientMin30Tag: () => {
    if (API_SPLIT) sharedConfig();
    return listPatientMin30Tag;
  },
  //get all patient groups
  listUserGroups: () => {
    if (API_SPLIT) awsConfig();
    return listUserGroups;
  },
  //get all sites
  listClinicSites: () => {
    if (API_SPLIT) sharedConfig();
    return listClinicSites;
  },
  //get all trial sites
  listTrialClinicSites: () => {
    if (API_SPLIT) awsConfig();
    return listTrialClinicSites;
  },
  //get all Staff
  listStaff: () => {
    if (API_SPLIT) sharedConfig();
    return listStaff;
  },
  //get all trial Staff
  listTrialStaff: () => {
    if (API_SPLIT) awsConfig();
    return listTrialStaff;
  },
  //get Prescribing Staff when create patient
  patientStaff: () => {
    if (API_SPLIT) sharedConfig();
    return patientStaff;
  },
  //get patient worn info
  PatientMinuteWorn: () => {
    if (API_SPLIT) sharedConfig();
    return PatientMinuteWorn;
  },
  TagMinuteWorn: () => {
    if (API_SPLIT) sharedConfig();
    return TagMinuteWorn;
  },
  TagWeekWorn: () => {
    if (API_SPLIT) sharedConfig();
    return TagWeekWorn;
  },
  getPillBottleTimeUsage: () => {
    if (API_SPLIT) sharedConfig();
    return getPillBottleTimeUsage;
  },
  //get one day status and month worn info
  getCalendarData: () => {
    if (API_SPLIT) sharedConfig();
    return getCalendarData;
  },
  //get all registered patients
  listRegisteredPatients: () => {
    if (API_SPLIT) sharedConfig();
    // return listRegisteredPatients;
    return lambdaSearchAllPatients;
  },
  getSirenStaffingPatients: () => {
    if (API_SPLIT) sharedConfig();
    return getSirenStaffingPatients;
  },
  getSinglePatient: () => {
    if (API_SPLIT) sharedConfig();
    return lambdaSearchSinglePatient;
  },
  //get all registered box user
  boxPatients: () => {
    if (API_SPLIT) awsConfig();
    return boxPatients;
  },
  //get sock/hub/app info
  getSock: () => {
    if (API_SPLIT) sharedConfig();
    return getSock;
  },
  //get sock/hub/app info
  getSockBySirenId: () => {
    if (API_SPLIT) sharedConfig();
    return getSockBySirenId;
  },
  //get foot status
  getFoot: () => {
    if (API_SPLIT) sharedConfig();
    return getFoot;
  },
  //get patient info
  docDetail: () => {
    if (API_SPLIT) sharedConfig();
    return docDetail;
  },
  //get uploaded photo
  getPhotoReview: () => {
    if (API_SPLIT) awsConfig();
    return getPhotoReview;
  },
  //get all report
  searchDoctorReports: () => {
    if (API_SPLIT) sharedConfig();
    return searchDoctorReports;
  },
  //get all registered patients
  listManagementSummary: () => {
    if (API_SPLIT) sharedConfig();
    return listManagementSummary;
  },
  managementSummaryStat: () => {
    if (API_SPLIT) sharedConfig();
    return managementSummaryStat;
  },
  //send listBilling99457Patients Patients data
  listBilling99457Patients: () => {
    if (API_SPLIT) sharedConfig();
    return listBilling99457Patients;
  },
  //send listBillingPCMPatients Patients data
  listBillingPCMPatients: () => {
    if (API_SPLIT) sharedConfig();
    return listBillingPCMPatients;
  },
  //send listBilling99453Patients Patients data
  listBilling99453Patients: () => {
    if (API_SPLIT) sharedConfig();
    return listBilling99453Patients;
  },
  //send listBilling99454Patients Patients data
  listBilling99454Patients: () => {
    if (API_SPLIT) sharedConfig();
    return listBilling99454Patients;
  },
  listHubs: () => {
    if (API_SPLIT) sharedConfig();
    return lambdaSearchHubs;
  },
  getExpireHubs: () => {
    if (API_SPLIT) sharedConfig();
    return getExpireHubs;
  },
  getSMSDripList: () => {
    if (API_SPLIT) sharedConfig();
    return getSMSDripList;
  },
  getPatientActivity: () => {
    if (API_SPLIT) sharedConfig();
    return getPatientActivity;
  },
  /**
   * mutation
   */
  //send report to doctor
  sendDoctorReport: () => {
    if (API_SPLIT) awsConfig();
    return sendDoctorReport;
  },
  //reset admin password, reset to a temporary password
  resetAdminPassword: () => {
    if (API_SPLIT) sharedConfig();
    return resetAdminPassword;
  },
  //reset staff password, reset to a temporary password
  resetStaffPassword: () => {
    if (API_SPLIT) sharedConfig();
    return resetStaffPassword;
  },
  //reset patient password, reset to a temporary password
  resetPatientPassword: () => {
    if (API_SPLIT) sharedConfig();
    return resetPatientPassword;
  },
  //update patient account status
  updateAccountStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateAccountStatus;
  },
  //update device status
  updateDeviceStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateDeviceStatus;
  },
  //update patient service status
  updateServiceStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateServiceStatus;
  },
  //update account billing status
  updateAccountBillingStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateAccountBillingStatus;
  },
  //updateSpecialCaseStatus
  updateSpecialCaseStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateSpecialCaseStatus;
  },
  //send app link to patient
  sendAppLink: () => {
    if (API_SPLIT) awsConfig();
    return sendAppLink;
  },
  //delete trial doctor
  deleteTrialDoctor: () => {
    if (API_SPLIT) awsConfig();
    return deleteTrialDoctor;
  },
  //delete registered patient
  deleteRegisteredPatient: () => {
    if (API_SPLIT) sharedConfig();
    return deleteRegisteredPatient;
  },
  //delete doctor
  deleteDoctor: () => {
    if (API_SPLIT) sharedConfig();
    return deleteDoctor;
  },
  //delete trial site
  deleteTrialSite: () => {
    if (API_SPLIT) awsConfig();
    return deleteTrialSite;
  },
  //delete site
  deleteClinicSite: () => {
    if (API_SPLIT) sharedConfig();
    return deleteClinicSite;
  },
  //create trial site
  createTrialSiteAccount: () => {
    if (API_SPLIT) awsConfig();
    return createTrialSiteAccount;
  },
  //create site
  createClinicSiteAccount: () => {
    if (API_SPLIT) sharedConfig();
    return createClinicSiteAccount;
  },
  //update site
  updateClinicSite: () => {
    if (API_SPLIT) sharedConfig();
    return updateClinicSite;
  },
  //send message(notification) to patient
  sendTestTask: () => {
    if (API_SPLIT) awsConfig();
    return sendTestTask;
  },
  //create trial staff
  createTrialDoctor: () => {
    if (API_SPLIT) awsConfig();
    return createTrialDoctor;
  },
  //edit doctor info
  updateDoctor: () => {
    if (API_SPLIT) sharedConfig();
    return updateDoctor;
  },
  //create staff
  registerDoctorAccount: () => {
    if (API_SPLIT) sharedConfig();
    return registerDoctorAccount;
  },
  //create admin
  registerAdminAccount: () => {
    if (API_SPLIT) sharedConfig();
    return registerAdminAccount;
  },
  //create patient
  registerAccount: () => {
    if (API_SPLIT) sharedConfig();
    return registerAccount;
  },
  //set compliance review
  setComplianceReviewReq: () => {
    if (API_SPLIT) sharedConfig();
    return setComplianceReviewReq;
  },
  //delete Siren note
  deleteSirenNote: () => {
    if (API_SPLIT) sharedConfig();
    return deleteSirenNote;
  },
  //edit Siren note
  updateSirenNote: () => {
    if (API_SPLIT) sharedConfig();
    return updateSirenNote;
  },
  //create siren note
  createSirenNote: () => {
    if (API_SPLIT) sharedConfig();
    return createSirenNote;
  },
  //edit trial patient info
  updateTrialPatient: () => {
    if (API_SPLIT) awsConfig();
    return updateTrialPatient;
  },
  //send notification to patient
  sendNotification: () => {
    if (API_SPLIT) awsConfig();
    return sendNotification;
  },
  //edit user group
  setUserGroup: () => {
    if (API_SPLIT) sharedConfig();
    return setUserGroup;
  },
  //change sock status(OK、WARN、DISABLE)
  updateSockStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateSockStatus;
  },
  //change sensor status(OK)
  updateSensorStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateSensorStatus;
  },
  //update new registered patient Dispensed or not
  updateNewRegisterPatient: () => {
    if (API_SPLIT) sharedConfig();
    return updateNewRegisterPatient;
  },
  //get box list
  searchableBoxConnection: () => {
    if (API_SPLIT) sharedConfig();
    return searchableBoxConnection;
  },
  //send simulate data
  sendSimulateData: () => {
    if (API_SPLIT) awsConfig();
    return sendSimulateData;
  },
  //send listBilling Patients data
  // listBillingPatients: () => {
  //   if (API_SPLIT) sharedConfig();
  //   return listBillingPatients;
  // },
  //change dispensedDay or setupBilled
  updateBilledPatient: () => {
    if (API_SPLIT) sharedConfig();
    return updateBilledPatient;
  },
  //change new patient info
  UpdateNewPatient: () => {
    if (API_SPLIT) sharedConfig();
    return UpdateNewPatient;
  },
  //get patient info of UserReportTable
  listBillingSummary: () => {
    if (API_SPLIT) sharedConfig();
    return listBillingSummary;
  },
  //update auto doctor report
  updateAutoDoctorReport: () => {
    if (API_SPLIT) sharedConfig();
    return updateAutoDoctorReport;
  },
  updateBillingStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateBillingStatus;
  },
  updateAutoDoctorReportItem: () => {
    if (API_SPLIT) sharedConfig();
    return updateAutoDoctorReportItem;
  },
  searchGlobalPatients: () => {
    if (API_SPLIT) sharedConfig();
    return searchGlobalPatients;
  },
  listInvoicePatients: () => {
    if (API_SPLIT) sharedConfig();
    return listInvoicePatients;
  },
  createRpmTracker: () => {
    if (API_SPLIT) sharedConfig();
    return createRpmTracker;
  },
  listRpmTracker: () => {
    if (API_SPLIT) sharedConfig();
    return listRpmTracker;
  },
  updateRpmTracker: () => {
    if (API_SPLIT) sharedConfig();
    return updateRpmTracker;
  },
  updateAddendum: () => {
    if (API_SPLIT) sharedConfig();
    return updateAddendum;
  },
  listRpmNoteTemplates: () => {
    if (API_SPLIT) sharedConfig();
    return listRpmNoteTemplates;
  },
  listRpmNoteTemplatesDetails: () => {
    if (API_SPLIT) sharedConfig();
    return listRpmNoteTemplatesDetails;
  },
  createRpmNoteTemplate: () => {
    if (API_SPLIT) sharedConfig();
    return createRpmNoteTemplate;
  },
  updateRpmNoteTemplate: () => {
    if (API_SPLIT) sharedConfig();
    return updateRpmNoteTemplate;
  },
  deleteRpmNoteTemplate: () => {
    if (API_SPLIT) sharedConfig();
    return deleteRpmNoteTemplate;
  },
  getCompiledRpmNoteTemplate: () => {
    if (API_SPLIT) sharedConfig();
    return getCompiledRpmNoteTemplate;
  },
  getDoctorNpi: () => {
    if (API_SPLIT) sharedConfig();
    return getDoctorNpi;
  },
  getSiteNpi: () => {
    if (API_SPLIT) sharedConfig();
    return getSiteNpi;
  },
  hubRemoteConnect: () => {
    if (API_SPLIT) sharedConfig();
    return remoteConnect;
  },
  generateBillingReport: () => {
    if (API_SPLIT) sharedConfig();
    return generateBillingReport;
  },
  generateVaZipReport: () => {
    if (API_SPLIT) sharedConfig();
    return generateVaZipReport;
  },
  updateBilled99457Status: () => {
    if (API_SPLIT) sharedConfig();
    return updateBilled99457Status;
  },
  updateBilledPCMStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateBilledPCMStatus;
  },
  updateBilled99454Status: () => {
    if (API_SPLIT) sharedConfig();
    return updateBilled99454Status;
  },
  updateBilled99453Status: () => {
    if (API_SPLIT) sharedConfig();
    return updateBilled99453Status;
  },
  updateSockDFU: () => {
    if (API_SPLIT) sharedConfig();
    return updateSockDFU;
  },
  subscribeBillingReport: () => {
    if (API_SPLIT) sharedConfig();
    return onReportGenerated;
  },
  deleteRpmTracker: () => {
    if (API_SPLIT) sharedConfig();
    return deleteRpmTracker;
  },
  verifyBoxInfo: () => {
    if (API_SPLIT) sharedConfig();
    return verifyBoxInfo;
  },
  checkPatientSocksAndHub: () => {
    if (API_SPLIT) sharedConfig();
    return checkPatientSocksAndHub;
  },
  upgradeAppNotification: () => {
    if (API_SPLIT) sharedConfig();
    return upgradeApp;
  },
  listAdminUsers: () => {
    if (API_SPLIT) sharedConfig();
    return listAdminStaff;
  },
  updateAdminUser: () => {
    if (API_SPLIT) sharedConfig();
    return updateAdminStaff;
  },
  updateAdminStaffFilter: () => {
    if (API_SPLIT) sharedConfig();
    return updateAdminStaffFilter;
  },
  deleteAdminDoctor: () => {
    if (API_SPLIT) sharedConfig();
    return deleteAdminStaff;
  },
  getSirenStaffCount: () => {
    if (API_SPLIT) sharedConfig();
    return getSirenStaffCount;
  },
  updatePatientNotification: () => {
    if (API_SPLIT) sharedConfig();
    return updatePatientNotification;
  },
  setAppointmented: () => {
    if (API_SPLIT) sharedConfig();
    return setAppointmented;
  },
  dismissReviewTask: () => {
    if (API_SPLIT) sharedConfig();
    return dismissReviewTask;
  },
  getLpnStaff: () => {
    if (API_SPLIT) sharedConfig();
    return getLpnStaff;
  },
  updateLpnForPatients: () => {
    if (API_SPLIT) sharedConfig();
    return updateLpnForPatients;
  },
  getNeedAssignPatients: () => {
    if (API_SPLIT) sharedConfig();
    return getNeedAssignPatients;
  },
  assignLpnForPatients: () => {
    if (API_SPLIT) sharedConfig();
    return assignLpnForPatients;
  },
  updateProgramStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateProgramStatus;
  },
  removeLpnForPatients: () => {
    if (API_SPLIT) sharedConfig();
    return removeLpnForPatients;
  },
  getWeightScale: () => {
    if (API_SPLIT) sharedConfig();
    return getWeightScale;
  },
  getEMRList: () => {
    if (API_SPLIT) sharedConfig();
    return getEMRList;
  },
  createEMR: () => {
    if (API_SPLIT) sharedConfig();
    return createEMR;
  },
  deleteEMR: () => {
    if (API_SPLIT) sharedConfig();
    return deleteEMR;
  },
  createFootSymptom: () => {
    if (API_SPLIT) sharedConfig();
    return createFootSymptom;
  },
  createFootCondition: () => {
    if (API_SPLIT) sharedConfig();
    return createFootCondition;
  },
  updateFootCondition: () => {
    if (API_SPLIT) sharedConfig();
    return updateFootCondition;
  },
  createFootTreatment: () => {
    if (API_SPLIT) sharedConfig();
    return createFootTreatment;
  },
  updateFootTreatment: () => {
    if (API_SPLIT) sharedConfig();
    return updateFootTreatment;
  },
  updateHubStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateHubStatus;
  },
  updateBoxStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateBoxStatus;
  },
  updateTagSource: () => {
    if (API_SPLIT) sharedConfig();
    return updateTagSource;
  },
  updateSockBox: () => {
    if (API_SPLIT) sharedConfig();
    return updateSockBox;
  },
  listShoeTag: () => {
    if (API_SPLIT) sharedConfig();
    return listShoeTag;
  },
  listPillBottles: () => {
    if (API_SPLIT) sharedConfig();
    return listPillBottles;
  },
  createShoeTag: () => {
    if (API_SPLIT) sharedConfig();
    return createShoeTag;
  },
  createPillBottle: () => {
    if (API_SPLIT) sharedConfig();
    return createPillBottle;
  },
  markSockData: () => {
    if (API_SPLIT) sharedConfig();
    return markSockData;
  },
  listPrescription: () => {
    if (API_SPLIT) sharedConfig();
    return listPrescription;
  },
  createPrescription: () => {
    if (API_SPLIT) sharedConfig();
    return createPrescription;
  },
  updatePatientSingleFoot: () => {
    if (API_SPLIT) sharedConfig();
    return updatePatientSingleFoot;
  },
  listCarePlan: () => {
    if (API_SPLIT) sharedConfig();
    return listCarePlans;
  },
  createCarePlan: () => {
    if (API_SPLIT) sharedConfig();
    return createCarePlan;
  },
  deleteCarePlan: () => {
    if (API_SPLIT) sharedConfig();
    return deleteCarePlan;
  },
  listICD10Codes: () => {
    if (API_SPLIT) sharedConfig();
    return listICD10Codes;
  },
  createICD10Code: () => {
    if (API_SPLIT) sharedConfig();
    return createICD10Code;
  },
  deleteICD10Code: () => {
    if (API_SPLIT) sharedConfig();
    return deleteICD10Code;
  },
  listMedicalConditions: () => {
    if (API_SPLIT) sharedConfig();
    return listMedicalConditions;
  },
  createMedicalCondition: () => {
    if (API_SPLIT) sharedConfig();
    return createMedicalCondition;
  },
  editMedicalCondition: () => {
    if (API_SPLIT) sharedConfig();
    return editMedicalCondition;
  },
  deleteMedicalCondition: () => {
    if (API_SPLIT) sharedConfig();
    return deleteMedicalCondition;
  },
  listStopwatchLogs: () => {
    if (API_SPLIT) sharedConfig();
    return listDoctorReviewHistory;
  },
  createStopwatchLog: () => {
    if (API_SPLIT) sharedConfig();
    return createDoctorReviewHistory;
  },
  getSockBySerialNo: () => {
    if (API_SPLIT) sharedConfig();
    return getSockBySerialNo;
  },
  getPatientBySiteId: () => {
    if (API_SPLIT) sharedConfig();
    return getPatientBySiteId;
  }
};

export default _api;
