import React, { Component } from "react";
import { connect } from "react-redux";
import "../SupportDashboard/account/NewSite.css";
import "../SupportDashboard/account/SiteList.css";
import ReactEcharts from "echarts-for-react";
import { getLpnInfoOption } from "../constant/lpnChartOption";
import { Control } from "react-keeper";
import "./lpn-management.css";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Popup } from "semantic-ui-react";
import { getSiteInfoOption } from "../constant/siteAssignChartOption";

class LpnChart extends Component {
  onChartClick = params => {
    const lpnInfo = this.props.listSirenStaff[params.dataIndex];
    this.gotoDoctorDetailPage(lpnInfo.id);
  };

  onSiteChartClick = params => {
    const selectSiteIndex = this.convertSelectSiteIndex(params.name);
    this.props.refreshActiveKey("3", selectSiteIndex);
  };

  convertSelectSiteIndex = name => {
    switch (name) {
      case "Staffing sites":
        return 1;
      case "Assigned staffing sites":
        return 2;
      case "Unassigned staffing sites":
        return 3;
      case "Non-staffing sites":
        return 4;
      case "Assigned non-staffing sites":
        return 5;
      case "Unassigned non-staffing sites":
        return 6;
      default:
        return 0;
    }
  };

  gotoDoctorDetailPage = id => {
    Control.go(`/LpnPatient?id=${id}`);
  };

  render() {
    const onEvents = {
      click: this.onChartClick
    };
    const onSiteEvent = {
      click: this.onSiteChartClick
    };
    return (
      <div>
        <div className="lpn-list-board">
          <ReactEcharts
            option={getLpnInfoOption(this.props.listSirenStaff)}
            style={{ height: 500 }}
            lazyUpdate={true}
            onEvents={onEvents}
          />
          <Popup
            trigger={
              <QuestionCircleFilled
                style={{
                  color: "#2D0172",
                  fontSize: 20,
                  position: "absolute",
                  marginTop: -480,
                  marginLeft: "46%"
                }}
              />
            }
            position="bottom right"
            hoverable
            flowing
          >
            <Popup.Header
              style={{ marginLeft: 10, marginTop: 20, marginBottom: 15 }}
            >
              LPN Assigned Status
            </Popup.Header>
            <Popup.Content>
              <div className="popBox">
                <div className="popContainer">
                  <p className="popTitle">Good: 0 - 60%</p>
                  <p className="popContent">
                    The number of patients assigned LPN is less than 60 percent.
                  </p>
                </div>
                <div className="popContainer">
                  <p className="popTitle">Warn: 60% - 80%</p>
                  <p className="popContent">
                    The number of patients assigned LPN is more than 60 percent,
                    but less than 80 percent.
                  </p>
                </div>
                <div className="popContainer">
                  <p className="popTitle">Bad: 80% - 100%</p>
                  <p className="popContent">
                    The number of patients assigned LPN is more than 80 percent.
                  </p>
                </div>
              </div>
            </Popup.Content>
          </Popup>
        </div>
        {/*<div className="flex-info">*/}
        {/*<div className="need-assign-info"></div>*/}
        <div className="site-assign-info" style={{ marginTop: 10 }}>
          <ReactEcharts
            option={getSiteInfoOption(
              this.props.clinicSites,
              this.props.listSirenStaff
            )}
            style={{ height: 400 }}
            lazyUpdate={true}
            onEvents={onSiteEvent}
          />
        </div>
        {/*</div>*/}
      </div>
    );
  }
}

const mapStateToProp = state => ({
  listSirenStaff: state.patientsListStore.listSirenStaff,
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(LpnChart);
