import React, { Component } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { connect } from "react-redux";
import "./weight-scale.css";
import moment from "moment";
import ReactTable from "react-table";
import ReactEcharts from "echarts-for-react";
import { getWeightInfoOption } from "./weightChartOption";
import { getWeightScaleInfo } from "../action/patientAction";
import _ from "lodash";
/**
 *  Created by shengli.zhang on 4/23/21 11:19 AM
 */

class WeightScale extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const self = this;
    this.intervalId = setInterval(function() {
      self.props.getWeightScaleInfo(self.props.id, self.props.userId, self);
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    return (
      <div className="photoBox">
        <div className="weight-flex-info">
          <div className="weight-table-info">
            <ReactTable
              style={{ width: "100%" }}
              filterable={true}
              defaultFilterMethod={(filter, row) => {
                return String(row[filter.id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase());
              }}
              data={
                this.props.weightScale &&
                _.orderBy(this.props.weightScale, ["localTime"], ["desc"]).map(
                  p => {
                    const fat = parseInt(p.fat);
                    const weight = parseFloat(p.weight);
                    const localTime = moment(p.localTime).format(
                      "MM/DD/YY HH:mm"
                    );
                    return {
                      ...p,
                      fat,
                      weight,
                      localTime
                    };
                  }
                )
              }
              columns={[
                {
                  columns: [
                    {
                      Header: "Time Stamp",
                      accessor: "localTime"
                    },
                    {
                      Header: "Weight",
                      accessor: "weight"
                    },
                    {
                      Header: "Weight Unit",
                      accessor: "weightUnit"
                    }
                  ]
                }
              ]}
              showPagination={false}
              defaultPageSize={50}
              minRows={0}
            />
          </div>
          <div className="weight-chart-info">
            <ReactEcharts
              option={getWeightInfoOption(this.props.weightScale)}
              style={{ height: 330 }}
              lazyUpdate={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  weightScale: state.patientsListStore.weightScale
});
const mapDispatchToProp = dispatch => ({
  getWeightScaleInfo: (id, userId, self) =>
    dispatch(getWeightScaleInfo(id, userId, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(WeightScale);
