import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import TagCharts from "./tagCharts";
import "../medicaldetails/calendarRange.css";
import "../medicaldetails/MedicalDetailsHome.css";
import { getTagData, getTagMinuteWorn } from "../action/tagAction";
import _, { filter, isEmpty } from "lodash";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import Modal from "react-modal";
import { Auth } from "aws-amplify";
class calendarTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      DateRangeDate: [],
      ifOpen: !!this.props.complianceId,
      activeYear: "",
      startDate: "",
      activeMonth: "",
      userId: "",
      id: "",
      modalIsOpen: false,
      rating: 0
    };
    this.DELAY = 24 * 60 * 60 * 1000;
    this.goPreDay = this.goPreDay.bind(this);
    this.goNextDay = this.goNextDay.bind(this);
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  goPreDay() {
    const preDay = [
      new Date(moment(this.state.DateRangeDate[0]).add(-1, "days")),
      new Date(moment(this.state.DateRangeDate[0]).add(-1, "days"))
    ];
    this.setState({ DateRangeDate: preDay });
    const { getTagData } = this.props;
    getTagData(this, this.state.id, this.state.userId, preDay);
  }
  goNextDay() {
    const NextDay = [
      new Date(moment(this.state.DateRangeDate[1]).add(1, "days")),
      new Date(moment(this.state.DateRangeDate[1]).add(1, "days"))
    ];
    this.setState({ DateRangeDate: NextDay });
    const { getTagData } = this.props;
    getTagData(this, this.state.id, this.state.userId, NextDay);
  }
  onChangeDateRange = DateRangeDate => {
    const { getTagData } = this.props;
    this.setState({ DateRangeDate });
    this.setState({ ifOpen: true });
    getTagData(this, this.state.id, this.state.userId, DateRangeDate);
  };
  componentDidMount() {
    const { getTagData } = this.props;
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const userId = params.userId;
    const id = params.id.replace(" ", "+");
    let startDate = moment(params.startDate);
    if (startDate.isValid()) {
      startDate = startDate.toDate();
    } else {
      startDate = new Date();
    }
    let endDate = moment(params.endDate);
    if (endDate.isValid()) {
      endDate = endDate.toDate();
    } else {
      endDate = new Date();
    }
    this.setState({
      DateRangeDate: [startDate, endDate],
      userId,
      id
    });
    getTagData(this, id, userId, [startDate, endDate]);
    const startTimeWorn = moment(moment(startDate).format("YYYY-MM-01"))
      .add(-6, "day")
      .format("YYYY-MM-DD");
    const endTimeWorn = moment(moment(startDate).format("YYYY-MM-01"))
      .add(36, "day")
      .format("YYYY-MM-DD");
    this.props.getTagMinuteWorn(
      id,
      userId,
      startTimeWorn,
      endTimeWorn,
      this,
      true
    );
  }

  turnChange = ({ activeStartDate }) => {
    const startDate = moment(activeStartDate)
      .add(-6, "day")
      .format("YYYY-MM-DD");
    const endDate = moment(activeStartDate)
      .add(36, "day")
      .format("YYYY-MM-DD");
    this.props.getTagMinuteWorn(
      this.state.id,
      this.state.userId,
      startDate,
      endDate,
      this
    );
  };

  dateRangeClassName = () => ({ date, view }) => {
    let getDate = moment(date).format("YYYY-MM-DD");
    if (this.props.tagTimeWorn && this.props.tagTimeWorn.length > 0) {
      let markTimeWorn = "";
      _.forEach(this.props.tagTimeWorn, function(item) {
        if (item.date === getDate && item.minuteWorn >= 30) {
          markTimeWorn = "timeMore";
        }
      });
      if (markTimeWorn !== "") {
        return markTimeWorn;
      }
    }
  };

  onStarClick = async () => {
    if (this.state.rating === 1) {
      this.setState({ rating: 0 });
      let user = await Auth.currentAuthenticatedUser();
      Auth.userAttributes(user).then(attribute => {
        const favorite = filter(attribute, { Name: "custom:favorite" });
        // console.log("remove favorite: ", favorite);
        if (favorite.length > 0 && !isEmpty(favorite[0].Value)) {
          let favoriteValue = favorite[0].Value.split("_");
          // console.log("before remove favoriteValue: ", favoriteValue);
          if (favoriteValue.indexOf(this.state.sirenId) >= 0) {
            favoriteValue.splice(favoriteValue.indexOf(this.state.sirenId), 1);
            let newfavoriteValue = favoriteValue
              .map(item => {
                return item;
              })
              .join("_");
            // console.log("after remove favoriteValue: ", newfavoriteValue);
            Auth.updateUserAttributes(user, {
              "custom:favorite": newfavoriteValue
            })
              .then(data => {
                // console.log("updateUserAttributes: ", data);
              })
              .catch(error => {
                console.log("updateUserAttributes error: ", error);
              });
          }
        }
      });
    } else {
      this.setState({ rating: 1 });
      let user = await Auth.currentAuthenticatedUser();
      Auth.userAttributes(user).then(attribute => {
        const favorite = filter(attribute, { Name: "custom:favorite" });
        // console.log("add favorite: ", favorite);
        if (favorite.length > 0) {
          let favoriteValue = favorite[0].Value.split("_");
          // console.log(
          //   "favorite.length > 0, before add favoriteValue: ",
          //   favoriteValue
          // );
          if (favoriteValue.indexOf(this.state.sirenId) < 0) {
            favoriteValue.push(this.state.sirenId);
            let newfavoriteValue = favoriteValue
              .map(item => {
                return item;
              })
              .join("_");
            // console.log(
            //   "favorite.length > 0, after add favoriteValue: ",
            //   newfavoriteValue
            // );
            Auth.updateUserAttributes(user, {
              "custom:favorite": newfavoriteValue
            })
              .then(data => {
                // console.log("updateUserAttributes: ", data);
              })
              .catch(error => {
                console.log("updateUserAttributes error: ", error);
              });
          }
        } else {
          let favoriteValue = [];
          favoriteValue.push(this.state.sirenId);
          let newfavoriteValue = favoriteValue.join("_");
          // console.log("favorite.length = 0, after add favoriteValue: ", newfavoriteValue);
          Auth.updateUserAttributes(user, {
            "custom:favorite": newfavoriteValue
          })
            .then(data => {
              // console.log("updateUserAttributes: ", data);
            })
            .catch(error => {
              console.log("updateUserAttributes error: ", error);
            });
        }
      });
    }
  };

  render() {
    return (
      <div id="user-info-container3">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div>
          <div className="urlbox">
            <Button
              basic
              className="change_calenDay_button"
              onClick={this.goPreDay}
            >
              <Icon name="angle left" />
              Previous day
            </Button>
            <DateRangePicker
              onChange={this.onChangeDateRange}
              value={[this.state.DateRangeDate[0], this.state.DateRangeDate[1]]}
              clearIcon=""
              tileClassName={this.dateRangeClassName()}
              minDetail="month"
              locale="en"
              isOpen={this.state.ifOpen}
              onActiveDateChange={this.turnChange}
            />
            <Button
              basic
              className="change_calenDay_button"
              onClick={this.goNextDay}
            >
              Next day
              <Icon name="angle right" />
            </Button>
          </div>
        </div>
        {this.props.complianceId ? (
          <div className="complianceBlank" />
        ) : (
          <TagCharts />
        )}
      </div>
    );
  }
}
const mapStateToProp = state => ({
  tagTimeWorn: state.patientsListStore.tagTimeWorn
});
const mapDispatchToProp = dispatch => ({
  getTagData: (self, id, userId, dateRange) =>
    dispatch(getTagData(self, id, userId, dateRange)),
  getTagMinuteWorn: (id, userid, startDate, endDate, self, needClear) =>
    dispatch(getTagMinuteWorn(id, userid, startDate, endDate, self, needClear))
});
export default connect(mapStateToProp, mapDispatchToProp)(calendarTag);
