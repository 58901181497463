import moment from "moment";
import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Icon, Modal } from 'semantic-ui-react'
import { Col, Divider, Form, Input, message, Row, Select } from "antd";
import { createPrescription, listPrescription } from "../../graphql/API";

const columns = [
	{
		Header: "Date",
		accessor: "createdAt",
		Cell: props => {
			return moment(props.value).format('YYYY-MM-DD')
		}
	},
	{
		Header: "Shipment Status",
		accessor: "shipment",
	},
	{
		Header: "Dispense Method",
		accessor: "dispenseType",
	},
	{
		Header: "Size",
		accessor: "sockSize",
	},
	{
		Header: "Color",
		accessor: "sockColor",
	},
	{
		Header: "Notes",
		accessor: "notes",
	},
];

export default class PrescriptionList extends Component {

	state = {
		loading: false,
		data: []
	}

  componentDidMount() {
		this.refresh();
  }

	refresh = () => {
		this.setState({
			loading: true
		});
    listPrescription(this.props.userId).then(data => {
			this.setState({
				data
			})
		}).catch(err => {
			message.error(JSON.stringify(err))
		}).finally(() => {
			this.setState({
				loading: false
			});
		})
	}

  render() {    
    return (
			<div>
				<AddPrescription userId={this.props.userId} onAdded={this.refresh} />
				<div className="note_title_box" style={{marginTop: 20}}>
					<div className="note_title">PRESCRIPTION HISTORY</div>
				</div>
				<ReactTable
					loading={this.state.loading}
					className="-striped -highlight"
					defaultSorted={[{ id: "createdAt", desc: true }]}
					filterable={true}
					defaultFilterMethod={(filter, row) => {
						if (!row[filter.id]) {
							return false;
						}
						return row[filter.id]
							.toString()
							.toLowerCase()
							.includes(filter.value.toLowerCase());
					}}
					data={this.state.data}
					columns={columns}
					showPagination={false}
					minRows={0}
				/>
			</div>
    );
  }
}

const { Option } = Select

const formLayout = {
	item: {
		marginBottom: 0
	},
	section: {
		marginTop: 30
	}
}

class AddPrescription extends Component {

	state = {
		open: false,
		sockSize: null,
		sockColor: null,
		dispenseStyle: null,
		shipAddress: null,
		shipCity: null,
		shipState: null,
		shipZip: null,
		notes: '',
		loading: false
	}

	validateForm() {
		let pass = true;
		if (!this.state.sockSize) {
			this.setState({
				sockSizeStatus: 'error'
			});
			pass = false;
		}
		if (!this.state.sockColor) {
			this.setState({
				sockColorStatus: 'error'
			});
			pass = false;
		}
		if (!this.state.dispenseStyle) {
			this.setState({
				dispenseStyleStatus: 'error'
			});
			pass = false;
		}
		return pass;
	}

	onSubmit = () => {
		if (!this.validateForm()) {
			return
		}
		this.setState({
			loading: true
		})
		const input = {
			userId: this.props.userId,
			sockSize: this.state.sockSize,
			sockColor: this.state.sockColor,
			dispenseType: this.state.dispenseStyle
		}
		if (this.state.notes) {
			input.notes = this.state.notes
		}
		if (this.state.dispenseType === 'SIREN_SHIPPED') {
			input.shipAddress = this.state.shipAddress && undefined;
			input.shipCity = this.state.shipCity && undefined;
			input.shipState = this.state.shipState && undefined;
			input.shipZip = this.state.shipZip && undefined;
		}
		createPrescription(input).then(data => {
			if (typeof this.props.onAdded === 'function') {
				this.props.onAdded()
			}
			this.setState({
				open: false
			})
		}).catch(err => {
			message.error(JSON.stringify(err))
		}).finally(() => {
			this.setState({
				loading: true
			})
		})
	}

	render() {
		return (
			<Modal
				onClose={() => this.setState({ open: false })}
				onOpen={() => this.setState({ open: true })}
				open={this.state.open}
				trigger={<Button><Icon name='plus' /> Add New Prescription</Button>}
			>
				<Modal.Header>Add New Prescription</Modal.Header>
				<Modal.Content>
					<div style={{paddingLeft: 40}}>
						Enter the patient's prescription. Review the information for accuracy before clicking the 'Submit' button.
						<br/>
						<strong>The order can not be changed after it has been placed.</strong>
					</div>
					<Form style={{paddingLeft: 80, paddingRight: 80, paddingBottom: 40}}>
						<div style={formLayout.section}>
							<Divider orientation="left">Prescription Information</Divider>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										label='Sock Size'
										required
										style={formLayout.item}
										validateStatus={this.state.sockSizeStatus}
										help={this.state.sockSizeStatus === 'error' ? 'Please select sock size' : ''}
									>
										<Select
											value={this.state.sockSize}
											onChange={value => {
												this.setState({
													sockSize: value,
													sockSizeStatus: 'success'
												})
											}}
										>
											<Option value="Small">
												<strong>Small </strong>(Womens 5.5 - 9)
											</Option>
											<Option value="Middle">
												<strong>Medium </strong>(Mens 7.5 - 11.5, Womens 9.5 - 13)
											</Option>
											<Option value="Large">
												<strong>Large </strong>(Mens 12 - 14.5, Womens 13.5 - 16)
											</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label='Sock Color'
										required
										style={formLayout.item}
										validateStatus={this.state.sockColorStatus}
										help={this.state.sockColorStatus === 'error' ? 'Please select sock color' : ''}
									>
										<Select
											value={this.state.sockColor}
											onChange={value => {
												this.setState({
													sockColor: value,
													sockColorStatus: 'success'
												})
											}}
										>
											<Option value="Patient Preference">Patient Preference</Option>
											<Option value="White">White</Option>
											<Option value="Black">Black</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label='Dispense Method'
										required
										style={formLayout.item}
										validateStatus={this.state.dispenseStyleStatus}
										help={this.state.dispenseStyleStatus === 'error' ? 'Please select dispense method' : ''}
									>
										<Select
											value={this.state.dispenseStyle}
											onChange={value => {
												this.setState({
													dispenseStyle: value,
													dispenseStyleStatus: 'success'
												})
											}}
										>
											<Option value="CLINIC_DISPENSED">Clinic Dispensed</Option>
											<Option value="SIREN_SHIPPED">Ship direct to patient</Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							{ this.state.dispenseStyle === 'SIREN_SHIPPED' && (<Row gutter={16}>
								<Col span={24} style={{marginTop: 10, marginBottom: 10}}>
									<div>Shipping Address</div>
								</Col>
								<Col span={12}>
									<Input
										placeholder="Address"
										onChange={e => this.setState({shipAddress: e.target.value})}
										value={this.state.shipAddress}
									/>
								</Col>
								<Col span={6}>
									<Input
										placeholder="City"
										onChange={e => this.setState({shipCity: e.target.value})}
										value={this.state.shipCity}
									/>
								</Col>
								<Col span={3}>
									<Input
										placeholder="State"
										onChange={e => this.setState({shipState: e.target.value})}
										value={this.state.shipState}
									/>
								</Col>
								<Col span={3}>
									<Input
										placeholder="Zip"
										onChange={e => this.setState({shipZip: e.target.value})}
										value={this.state.shipZip}
									/>
								</Col>
							</Row>)}
						</div>
						<div style={formLayout.section}>
							<Divider orientation="left">Notes</Divider>
							<Input.TextArea
								value={this.state.notes}
								onChange={e => this.setState({notes: e.target.value})}
								placeholder="Write your comments here"
								autoSize={{ minRows: 3, maxRows: 5 }}
							/>
						</div>
					</Form>
				</Modal.Content>
				<Modal.Actions style={{textAlign: 'center'}}>
					<Button
						inverted
						color='red'
						onClick={() => this.setState({open: false})}
					>
						<Icon name='remove' /> Cancel
					</Button>
					<Button
						loading={this.state.loading}
						color='green'
						onClick={this.onSubmit}
					>
						<Icon name='checkmark' /> Submit
					</Button>
				</Modal.Actions>
			</Modal>
		)
	}
}
