import { Select } from "antd";
import { Auth } from "aws-amplify";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import {
  createAdminAccount,
  getlistClinicSites
} from "../../action/registerAction";
import * as EMRs from "../../tools/EMRList";
import modalInfo from "../../tools/module-info";
import { lowercaseRmSpace, uppercaseFirstStr } from "../../tools/stringProcess";
import AdminSuccess from "./adminSuccess";
import "./NewSite.css";
import { getEMRList } from "../../action/emrAction";

const { Option } = Select;

class AddAdminUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      firstName: "",
      lastName: "",
      email: "",
      userGroup: "STAFF",
      showUserPaw: false,
      groups: [],
      selectSiteValues: [],
      selectEMRValues: [],
      selectBackupValues: [],
      patientLoad: "200"
    };
    this.onSelectSiteChange = this.onSelectSiteChange.bind(this);
    this.onSelectEMRChange = this.onSelectEMRChange.bind(this);
    this.onSelectBackupChange = this.onSelectBackupChange.bind(this);
  }

  onSelectSiteChange(value) {
    let newValue = _.clone(value);
    const self = this;
    const site = _.filter(this.props.clinicSites, function(t) {
      if (self.state.userGroup === "SALES") {
        return t.siteId.startsWith(value[value.length - 1]);
      }
      return t.siteId === value[value.length - 1];
    }).map(p => {
      return p.siteId;
    });
    let selectSiteValues = _.uniq([...newValue, ...site]);
    if (this.state.userGroup === "STAFF") {
      if (this.state.selectEMRValues.length === 0) {
        modalInfo.warning(
          "Incompatible EMR System",
          "Please select EMR System first."
        );
        return;
      } else {
        const self = this;
        _.some(selectSiteValues, function(p) {
          const mapSite = _(self.props.clinicSites).find({ siteId: p });
          if (!self.state.selectEMRValues.includes(mapSite.EMRSystem)) {
            modalInfo.warning(
              "Incompatible EMR System",
              "Please select clinics with compatible EMR systems or add additional EMR proficiencies."
            );
            selectSiteValues = self.state.selectSiteValues;
            return true;
          }
        });
      }
    }
    this.setState({ selectSiteValues });
  }

  onSelectEMRChange(value) {
    this.setState({
      selectEMRValues: value
    });
  }

  onSelectBackupChange(value) {
    this.setState({
      selectBackupValues: value
    });
  }

  changeFirstName = event => {
    this.setState({ firstName: uppercaseFirstStr(event.target.value) });
  };

  changeLastName = event => {
    this.setState({ lastName: uppercaseFirstStr(event.target.value) });
  };
  changeEmail = event => {
    this.setState({ email: lowercaseRmSpace(event.target.value) });
  };
  onChangedGroup = event => {
    this.setState({ userGroup: event });
  };

  onChangedPatientLoad = event => {
    this.setState({ patientLoad: event });
  };

  close = () => {
    this.setState({
      open: false,
      firstName: "",
      lastName: "",
      email: "",
      userGroup: "STAFF",
      showUserPaw: false,
      groups: [],
      selectSiteValues: [],
      selectEMRValues: [],
      selectBackupValues: [],
      patientLoad: "200"
    });
  };
  handleCreateAccount = () => {
    const { createAdminAccount } = this.props;
    if (_.isEmpty(this.state.firstName)) {
      modalInfo.warning("Incomplete Field", `Please input First Name.`);
      return;
    }
    if (_.isEmpty(this.state.lastName)) {
      modalInfo.warning("Incomplete Field", `Please input Last Name.`);
      return;
    }
    if (_.isEmpty(this.state.email)) {
      modalInfo.warning("Incomplete Field", `Please input Email.`);
      return;
    }
    // if (this.state.userGroup === "STAFF" || this.state.userGroup === "SALES") {
    //   if (this.state.selectSiteValues.length === 0) {
    //     modalInfo.warning("Incomplete Field", "Please select primary site");
    //     return;
    //   }
    // }
    if (this.state.userGroup === "STAFF") {
      if (this.state.selectEMRValues.length === 0) {
        modalInfo.warning("Incomplete Field", "Please select EMR System");
        return;
      }
      if (_.isEmpty(this.state.patientLoad)) {
        modalInfo.warning("Incomplete Field", "Please select Patient Load");
        return;
      }
    }

    let CreateAdminInput = {
      input: {
        email: this.state.email,
        group: this.state.userGroup,
        firstName: this.state.firstName,
        lastName: this.state.lastName
      }
    };
    if (this.state.userGroup === "SALES" || this.state.userGroup === "STAFF") {
      Object.assign(CreateAdminInput.input, {
        primarySites: JSON.stringify(this.state.selectSiteValues)
      });
    }
    if (this.state.userGroup === "STAFF") {
      Object.assign(CreateAdminInput.input, {
        EMRSystem: JSON.stringify(this.state.selectEMRValues)
      });
      if (this.state.selectBackupValues.length > 0) {
        Object.assign(CreateAdminInput.input, {
          backupFor: JSON.stringify(this.state.selectBackupValues)
        });
      }
      Object.assign(CreateAdminInput.input, {
        patientLoad: parseInt(this.state.patientLoad)
      });
    }
    createAdminAccount(CreateAdminInput, this);
  };

  handleOpen = async () => {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    if (!groups.includes("admin") && !groups.includes("developer")) {
      modalInfo.warning(
        "Create Admin Dashboard User",
        "Please contact an administrator to create a new account. User does not have Admin Dashboard account creation privileges."
      );
      return;
    }
    this.setState({ open: true, groups });
    if (this.props.EMRSystemList.length === 0) {
      this.props.getEMRList();
    }
  };

  renderLPNForm() {
    return (
      <>
        <div className="inputTotal" style={{ width: 580 }}>
          <div className="inputLable">
            EMR System <span style={{ color: "red", marginLeft: 5 }}>*</span>
          </div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select EMR"
            onChange={this.onSelectEMRChange}
            optionLabelProp="value"
          >
            {this.props.EMRSystemList.map((p, index) => (
              <Option key={index} value={p.name}>
                {p.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="inputTotal" style={{ width: 580 }}>
          <div className="inputLable">
            Primary Sites
            {/*<span style={{ color: "red", marginLeft: 5 }}>*</span>*/}
          </div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select sites"
            onChange={this.onSelectSiteChange}
            optionLabelProp="value"
          >
            {this.props.clinicSites
              .filter(s => this.state.selectEMRValues.includes(s.EMRSystem))
              .map(site => {
                return (
                  <Option key={site.siteId} value={site.siteId}>
                    {site.siteId} {site.name} - {site.EMRSystem}
                  </Option>
                );
              })}
          </Select>
        </div>
        <div className="inputTotal" style={{ width: 580 }}>
          <div className="inputLable">Backup For</div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select backup"
            onChange={this.onSelectBackupChange}
          >
            {this.props.sirenStaffList.map(staff => {
              return (
                <Option key={staff.id} value={staff.id}>
                  {staff.firstName} {staff.lastName}
                </Option>
              );
            })}
          </Select>
        </div>
        <div
          style={{
            display: "flex",
            width: "40%",
            marginLeft: "10%",
            marginRight: "10%"
          }}
        >
          <div className="inputTotal">
            <p className="inputLable">Patient Load</p>
            <Select
              className="selectStyle"
              value={this.state.patientLoad}
              filterOption={(input, option) =>
                option.props.value
                  .toLocaleLowerCase()
                  .indexOf(input.toLocaleLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
              onChange={this.onChangedPatientLoad}
            >
              {this.state.groups.includes("admin") ? (
                <Option value="50">50</Option>
              ) : null}
              <Option value="100">100</Option>
              <Option value="150">150</Option>
              <Option value="200">200</Option>
              <Option value="250">250</Option>
              <Option value="300">300</Option>
              <Option value="350">350</Option>
              <Option value="400">400</Option>
            </Select>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { Option } = Select;
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Button size="small" onClick={this.handleOpen}>
            Add Admin Account
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>{"Add Admin Account"}</Modal.Header>
        <Modal.Content scrolling>
          <div>
            <div className="newSite_box">
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">
                    First Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <Input
                    placeholder="John"
                    className="inputContent"
                    onChange={this.changeFirstName}
                    value={this.state.firstName}
                  />
                </div>
                <div className="inputTotal">
                  <p className="inputLable">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <Input
                    placeholder="Smith"
                    className="inputContent"
                    onChange={this.changeLastName}
                    value={this.state.lastName}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">
                    Email <span style={{ color: "red" }}>*</span>
                  </p>
                  <Input
                    placeholder="Johnsmith@email.com"
                    className="inputContent"
                    onChange={this.changeEmail}
                    value={this.state.email}
                  />
                </div>
                <div className="inputTotal">
                  <p className="inputLable">User Group</p>
                  <Select
                    className="selectStyle"
                    value={this.state.userGroup}
                    filterOption={(input, option) =>
                      option.props.value
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                    onChange={this.onChangedGroup}
                  >
                    {this.state.groups.includes("admin") ? (
                      <Option value="ADMIN">Super User</Option>
                    ) : null}
                    <Option value="DEVELOPER">Admin</Option>
                    <Option value="SUPPORT">Support</Option>
                    <Option value="STAFF">Siren Staffing</Option>
                    <Option value="SALES">Sales</Option>
                    <Option value="THIRD_PARTY">Third Party</Option>
                  </Select>
                </div>
              </div>
              {this.state.userGroup === "SALES" && (
                <div className="inputTotal" style={{ width: 580 }}>
                  <div className="inputLable">
                    Primary Sites{" "}
                    {/*<p style={{ color: "red", marginLeft: 5 }}>*</p>*/}
                  </div>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select sites"
                    onChange={this.onSelectSiteChange}
                    optionLabelProp="value"
                  >
                    {this.props.clinicSites.map(site => {
                      return (
                        <Option key={site.siteId} value={site.siteId}>
                          {site.siteId} {site.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              )}
              {this.state.userGroup === "STAFF" && this.renderLPNForm()}
              {this.state.showUserPaw ? (
                <div className="inputTotal">
                  <AdminSuccess
                    userName={this.state.email}
                    closeAddAdminUser={this.close.bind(this)}
                  />
                </div>
              ) : null}
            </div>
            <div style={{ height: 50 }} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.handleCreateAccount}>
            <Icon name="checkmark" />
            Create
          </Button>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  listAdminStaff: state.patientsListStore.listAdminStaff,
  sirenStaffList: state.patientsListStore.listAdminStaff.filter(
    p => p.group === "staff"
  ),
  clinicSites: state.patientsListStore.clinicSites,
  EMRSystemList: state.patientsListStore.EMRSystemList
});
const mapDispatchToProp = dispatch => ({
  createAdminAccount: (CreateAdminInput, self) =>
    dispatch(createAdminAccount(CreateAdminInput, self)),
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getEMRList: () => dispatch(getEMRList())
});
export default connect(mapStateToProp, mapDispatchToProp)(AddAdminUser);
