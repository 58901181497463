import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import _api from "../graphql/_api";
import modalInfo from "../tools/module-info";
import {
  changeLoadingChartsState
} from "./patientAction";
/**
 *  Created by shengli.zhang on 12/9/20 2:16 PM
 */

export function updateNotification(input, self) {
  return async dispatch => {
    try {
      dispatch(changeLoadingChartsState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.UpdateNewPatient(), { input })
      );
      dispatch(changeLoadingChartsState(false));
      console.log("newEvent: ", newEvent);
      if (newEvent) {
        if (newEvent.data.updateNewPatient) {
          modalInfo.success(
            "Update Notification",
            self.state.enableNotification
              ? "Open Notification Success."
              : "Close Notification Success."
          );
        } else {
          self.setState({ enableNotification: !self.state.enableNotification });
          modalInfo.warning(
            "Update Notification",
            self.state.enableNotification
              ? "Open Notification Failed."
              : "Close Notification Failed."
          );
        }
      }
      dispatch(changeLoadingChartsState(false));
    } catch (e) {
      dispatch(changeLoadingChartsState(false));
      self.setState({ enableNotification: !self.state.enableNotification });
      console.log(`updateNotification status error: ${e}`);
      modalInfo.error(
        "Update Notification",
        self.state.enableNotification
          ? `Open Notification Error. ${JSON.stringify(e)}`
          : `Close Notification Error. ${JSON.stringify(e)}`
      );
    }
  };
}

function updatePatientInfoWithFailed(self, id, checked, dispatch) {
  let newPatientList = _.clone(self.state.data);
  let patientInfo = _(newPatientList).find({ id });
  if (!_.isEmpty(patientInfo)) {
    if (checked) {
      patientInfo.enableApp = true;
    } else {
      patientInfo.enableApp = false;
      patientInfo.enableSms = false;
      patientInfo.enableEmail = false;
    }
    self.setState({ data: newPatientList });
  }
}

export function updatePatientPushSuc(self, input) {
  return dispatch => {
    updatePatientInfoWithSuccess(self, input, dispatch);
  };
}

export function updatePatientInfoWithSuccess(self, input, dispatch) {
  let newPatientList = _.clone(self.state.data);
  let patientInfo = _(newPatientList).find({ id: input.id });
  if (!_.isEmpty(patientInfo)) {
    if (input.hasOwnProperty("enableApp")) {
      patientInfo.enableApp = input.enableApp;
    }
    if (input.hasOwnProperty("enableSms")) {
      patientInfo.enableSms = input.enableSms;
    }
    if (input.hasOwnProperty("enableEmail")) {
      patientInfo.enableEmail = input.enableEmail;
    }
    if (input.lastNotification) {
      patientInfo.lastNotification = input.lastNotification;
    }
    if (input.footStatusPush) {
      patientInfo.footStatusPush = input.footStatusPush;
    }
    if (input.reminderPush) {
      patientInfo.reminderPush = input.reminderPush;
    }
    self.setState({ data: newPatientList });
  }
}

export function changeNotificationState(self, id, checked) {
  return dispatch => {
    updatePatientInfoWithFailed(self, id, checked, dispatch);
  };
}

export function updateNewPatientNotification(
  input,
  self,
  checked,
  sirenId,
  type
) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updatePatientNotification(), { input })
      );
      console.log("newEvent: ", newEvent);
      if (newEvent) {
        if (newEvent.data.updatePatientNotification) {
          modalInfo.success(
            "Notification Update",
            checked
              ? `${type} OFF for SirenId ${sirenId}.`
              : `${type} ON for SirenId ${sirenId}.`
          );
          // updatePatientInfoWithSuccess(self, input, dispatch);
        } else {
          modalInfo.warning(
            "Notification Update",
            checked
              ? `${type} OFF failed for SirenId ${sirenId}.`
              : `${type} ON failed for SirenId ${sirenId}.`
          );
          // updatePatientInfoWithFailed(self, input.id, !checked, dispatch);
        }
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log(`updateNotification status error: ${e}`);
      modalInfo.error(
        "Notification Update",
        checked
          ? `${type} OFF error for SirenId ${sirenId}. ${JSON.stringify(e)}`
          : `${type} ON error for SirenId ${sirenId}. ${JSON.stringify(e)}`
      );
      // updatePatientInfoWithFailed(self, input.id, !checked, dispatch);
    }
  };
}
