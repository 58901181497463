// eslint-disable
// this is an auto generated file. This will be overwritten

export const onCreatePatient = `subscription OnCreatePatient {
  onCreatePatient {
    id
    sirenId
    email
    phone
    fullName
    footStatus
    shippingAddr
    medicalHistory
    supportRecords
    wearingTime
    steps
    avgTemp
    alerts
    doctorReviewRequest
    doctorReviewComment
  }
}
`;
export const onUpdatePatient = `subscription OnUpdatePatient {
  onUpdatePatient {
    id
    sirenId
    email
    phone
    fullName
    footStatus
    shippingAddr
    medicalHistory
    supportRecords
    wearingTime
    steps
    avgTemp
    alerts
    doctorReviewRequest
    doctorReviewComment
  }
}
`;
export const onDeletePatient = `subscription OnDeletePatient {
  onDeletePatient {
    id
    sirenId
    email
    phone
    fullName
    footStatus
    shippingAddr
    medicalHistory
    supportRecords
    wearingTime
    steps
    avgTemp
    alerts
    doctorReviewRequest
    doctorReviewComment
  }
}
`;
export const onCreatePrivateNote = `subscription OnCreatePrivateNote {
  onCreatePrivateNote {
    id
    content
  }
}
`;
export const onUpdatePrivateNote = `subscription OnUpdatePrivateNote {
  onUpdatePrivateNote {
    id
    content
  }
}
`;
export const onDeletePrivateNote = `subscription OnDeletePrivateNote {
  onDeletePrivateNote {
    id
    content
  }
}
`;
export const onReportGenerated = `subscription OnReportGenerated {
  onReportGenerated {
    path
    generatedAt
    token
  }
}`;
