import React, { Component } from "react";
import { Select, Input, Modal, Button, Row, Col } from "antd";
import { FOOT, SYMPTOMS, SYMPTOMS_AREA } from "../tools/patient-medical";
import _ from "lodash";
import "./patient-medical.css";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";

/**
 *  Created by shengli.zhang on 4/30/21 3:17 PM
 */
const { Option } = Select;

export default class AddSymptoms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectSymptom: undefined,
      detail: undefined,
      selectFoot: undefined,
      selectArea: [],
      otherSymptom: ""
    };
  }

  handleSymptomChange = value => {
    this.setState({
      detail: undefined
    });

    if (value === "No new symptoms") {
      this.state.selectSymptom = value;
      this.setState({ selectSymptom: value });
      this.saveSymptom();
    } else {
      this.setState({ selectSymptom: value });
    }
  };

  handleFootChange = value => {
    this.setState({ selectFoot: value });
  };

  handleAreaChange = value => {
    if (this.checkSymptom(value)) {
      this.setState({ selectArea: value });
    }
  };

  onOtherSymptomChange = event => {
    this.setState({ otherSymptom: event.target.value });
  };

  checkSymptom = selectArea => {
    if (!this.state.selectSymptom) {
      Modal.warning({
        title: "Save Symptoms Failed",
        content: "Please select a symptom.",
        centered: true
      });
      return false;
    }
    if (!this.state.selectFoot) {
      Modal.warning({
        title: "Save Symptoms Failed",
        content: "Please select foot.",
        centered: true
      });
      return false;
    }
    if (selectArea.length === 0) {
      Modal.warning({
        title: "Save Symptoms Failed",
        content: "Please select area.",
        centered: true
      });
      return false;
    }
    if (
      this.state.selectSymptom === "Other" &&
      _.isEmpty(this.state.otherSymptom)
    ) {
      Modal.warning({
        title: "Save Symptoms Failed",
        content: "Please input a symptom.",
        centered: true
      });
      return false;
    }
    return true;
  };

  saveSymptom = () => {
    if (!this.state.selectSymptom) {
      return;
    }
    const noSymptoms = this.state.selectSymptom === "No new symptoms";
    if (!noSymptoms) {
      if (!this.state.selectFoot) {
        return;
      }
      if (this.state.selectArea.length === 0) {
        return;
      }
      if (
        this.state.selectSymptom === "Other" &&
        _.isEmpty(this.state.otherSymptom)
      ) {
        return;
      }
    }
    if (this.props.saveAction) {
      this.props.saveAction({
        reportTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        footSymptom:
          this.state.selectSymptom === "Other"
            ? this.state.otherSymptom
            : this.state.selectSymptom,
        detail: this.state.detail,
        foot: noSymptoms ? "" : this.state.selectFoot,
        footArea: noSymptoms ? "" : this.state.selectArea.join(",")
      });
    }
    this.clearSymptom();
  };

  clearSymptom = () => {
    this.setState({
      selectSymptom: undefined,
      detail: undefined,
      selectFoot: undefined,
      selectArea: [],
      otherSymptom: ""
    });
  };

  removeSymptom = () => {
    if (this.props.removeAction) {
      this.clearSymptom();
      this.props.removeAction();
    }
  };

  render() {
    let subOptions = null;
    if (this.state.selectSymptom) {
      const tmp = SYMPTOMS.find(s => s.name === this.state.selectSymptom);
      if (tmp) {
        subOptions = tmp.options;
      }
    }
    return (
      <div style={this.props.style}>
        <Row gutter={[16, 16]}>
          <Col span={subOptions ? 6 : 8}>
            <Select
              value={this.state.selectSymptom}
              onChange={this.handleSymptomChange}
              style={{ width: "100%" }}
              placeholder="Select a symptom"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
            >
              {SYMPTOMS.map(p => p.name).map((p, index) => (
                <Option value={p} key={index}>
                  {p}
                </Option>
              ))}
            </Select>
            {this.state.selectSymptom === "Other" && (
              <Input
                style={{ marginTop: 12 }}
                value={this.state.otherSymptom}
                placeholder="Other Symptom"
                onChange={this.onOtherSymptomChange}
              />
            )}
          </Col>
          {subOptions && (
            <Col span={6}>
              <Select
                value={this.state.detail}
                placeholder="Optional detail"
                style={{ width: "100%" }}
                onChange={value => this.setState({ detail: value })}
              >
                {subOptions.map(o => (
                  <Option value={o} key={o}>
                    {o}
                  </Option>
                ))}
              </Select>
            </Col>
          )}
          <Col span={4}>
            <Select
              value={this.state.selectFoot}
              onChange={this.handleFootChange}
              style={{ width: "100%" }}
              placeholder="Select foot"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
            >
              {FOOT.map((p, index) => {
                return (
                  <Option value={p} key={index}>
                    {p}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              mode="multiple"
              value={this.state.selectArea}
              onChange={this.handleAreaChange}
              style={{ width: "100%" }}
              placeholder="Select area"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onBlur={event => this.saveSymptom()}
              showSearch
              optionFilterProp="children"
            >
              {SYMPTOMS_AREA.map((p, index) => {
                return (
                  <Option value={p} key={index}>
                    {p}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col span={4}>
            <CloseOutlined
              onClick={this.removeSymptom}
              style={{ marginTop: 8 }}
            />
          </Col>
          {/*<Col span={2}>*/}
          {/*  <Button*/}
          {/*    type="link"*/}
          {/*    onClick={this.saveSymptom}*/}
          {/*    style={{ marginLeft: -16 }}*/}
          {/*  >*/}
          {/*    Save*/}
          {/*  </Button>*/}
          {/*</Col>*/}
          {/*<Col span={2}>*/}
          {/*  <Button*/}
          {/*    type="link"*/}
          {/*    style={{ marginLeft: -16 }}*/}
          {/*    onClick={this.removeSymptom}*/}
          {/*  >*/}
          {/*    Remove*/}
          {/*  </Button>*/}
          {/*</Col>*/}
        </Row>
      </div>
    );
  }
}
