import echarts from "echarts";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getChartPositionOptions,
  getChartStepsOptions
} from "./tagChartOptions";

class tagCharts extends Component {
  componentDidUpdate() {
    this.getUserData();
  }
  getUserData() {
    let myChart1 = echarts.init(document.getElementById("tag1"));
    let myChart2 = echarts.init(document.getElementById("tag2"));
    let option1 = getChartStepsOptions(
      this.props.stepsTag,
      this.props.min1_or_min30_tag
    );
    let option2 = getChartPositionOptions(
      this.props.positionTag,
      this.props.min1_or_min30_tag
    );
    myChart1.setOption(option1);
    myChart2.setOption(option2);

    //link all charts
    echarts.connect([myChart1, myChart2]);

    window.onresize = function() {
      myChart1.resize();
      myChart2.resize();
    };
  }

  render() {
    return (
      <div className="charts_container">
        <div id="tag1" style={{ width: "100%", height: 350 }} />
        <div
          id="tag2"
          style={{ width: "100%", height: 350, marginTop: -50 }}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  stepsTag: state.patientsListStore.stepsTag,
  min1_or_min30_tag: state.patientsListStore.min1_or_min30_tag,
  positionTag: state.patientsListStore.positionTag
});
export default connect(mapStateToProp, null)(tagCharts);
