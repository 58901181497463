import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import "../SupportDashboard/account/NewSite.css";
class StaffSuccess extends Component {
  render() {
    return (
      <div className="success_box">
        <Icon name="check circle" id="siteIcon" />
        <p className="siteTitle">Congratulations!</p>
        <p className="finishtext">
          <span>New Staff</span>
          <span className="addUnderline">
            &nbsp;{this.props.SuccessFirstName}&nbsp;
            {this.props.SuccessLastName}&nbsp;
          </span>
          <span>was successfully created for site(s)</span>
          <span className="addUnderline">
            &nbsp;{this.props.SuccessSiteId},&nbsp;
          </span>
          <span>password: </span>
          <span className="addUnderline">
            &nbsp;<strong>Sirensock5!</strong>
          </span>
        </p>
        <p className="staff_log_tip">
          Please have staff log into Siren Medical Dashboard to reset password.
        </p>
      </div>
    );
  }
}
const mapStateToProp = state => ({});

const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(StaffSuccess);
