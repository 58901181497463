import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import _api from "./_api";

const DefaultPagination = {
  page: 0,
  size: 20
};

export function patientListAPI(
  searchStr,
  filter,
  sort = { field: "sirenId", direction: "desc" },
  pagination = DefaultPagination
) {
  let combineFilter = Object.assign(
    {
      group: {
        ne: "TRIAL_SILENCE"
      },
      initializedStr: {
        eq: "Yes"
      },
      accountStatus: {
        ne: "DELETED"
      }
    },
    filter
  );

  return API.graphql(
    graphqlOperation(_api.searchPatientsConnection(), {
      searchStr,
      filter: combineFilter,
      sort,
      limit: pagination.size,
      nextToken: pagination.page * pagination.size
    })
  );
}

export function registeredPatientListAPI(
  filter,
  sort = { field: "sirenId", direction: "desc" },
  pagination = DefaultPagination
) {
  let combineFilter = Object.assign(
    {
      group: {
        ne: "TRIAL_SILENCE"
      },
      accountStatus: {
        ne: "DELETED"
      }
    },
    filter
  );

  return API.graphql(
    graphqlOperation(_api.listRegisteredPatients(), {
      filter: combineFilter,
      sort,
      limit: pagination.size,
      nextToken: pagination.page * pagination.size
    })
  );
}

export function deletedPatientListAPI(
  filter,
  sort = { field: "sirenId", direction: "desc" },
  pagination = DefaultPagination
) {
  let combineFilter = Object.assign(
    {
      group: {
        ne: "TRIAL_SILENCE"
      },
      accountStatus: {
        eq: "DELETED"
      }
    },
    filter
  );

  return API.graphql(
    graphqlOperation(_api.listRegisteredPatients(), {
      filter: combineFilter,
      sort,
      limit: pagination.size,
      nextToken: pagination.page * pagination.size
    })
  );
}

export function sirenStaffingPatientListAPI(
  filter,
  sort = { field: "sirenId", direction: "desc" },
  pagination = DefaultPagination,
  primarySites,
  lpns
) {
  let combineFilter = Object.assign(
    {
      group: {
        ne: "TRIAL_SILENCE"
      },
      accountStatus: {
        ne: "DELETED"
      }
    },
    filter
  );
  delete combineFilter["medicalReview"];
  // add LPN filter. LPN must not be none
  if (combineFilter.and) {
    combineFilter.and.push({
      LPN: {
        exists: true
      }
    }, {
      LPN: {
        ne: ""
      }
    })
  } else {
    combineFilter.and = [
      {
        LPN: {
          exists: true
        }
      },
      {
        LPN: {
          ne: ""
        }
      }
    ]
  }
  if (
    primarySites &&
    !_.isEmpty(primarySites) &&
    !filter.hasOwnProperty("siteId")
  ) {
    const sites = JSON.parse(primarySites).join("|");
    Object.assign(combineFilter, { siteId: { match: sites } });
  }

  if (lpns && JSON.parse(lpns).length > 0) {
    const sirenStaff = JSON.parse(lpns).map(t => {
      return { LPN: { eq: t } };
    });
    Object.assign(combineFilter, { or: sirenStaff });
  }

  return API.graphql(
    graphqlOperation(_api.getSirenStaffingPatients(), {
      filter: combineFilter,
      sort,
      limit: pagination.size,
      nextToken: pagination.page * pagination.size
    })
  );
}

export async function getSirenStaffingStat(siteId, lpn) {
  let filter = {};
  if (siteId) {
    filter.siteId = siteId;
  }
  if (lpn) {
    filter.lpn = lpn;
  }
  const res = await API.graphql(
    graphqlOperation(_api.getSirenStaffCount(), filter)
  );

  return res.data.getSirenStaffingCount;
}

export async function resetPasswordStaff(newPassword) {
  const newEvent = await API.graphql(
    graphqlOperation(_api.resetStaffPassword(), newPassword)
  );
  if (newEvent.data.resetStaffPassword.status) {
    return true;
  } else {
    throw new Error(newEvent.data.resetStaffPassword.message);
  }
}

export async function resetPasswordAdmin(newPassword) {
  const newEvent = await API.graphql(
    graphqlOperation(_api.resetAdminPassword(), newPassword)
  );
  if (newEvent.data.resetAdminPassword.status) {
    return true;
  } else {
    throw new Error(newEvent.data.resetAdminPassword.message);
  }
}

export async function getManagementSummaryStat(filter) {
  let combineFilter = Object.assign(
    {
      group: {
        ne: "TRIAL_SILENCE"
      },
      accountStatus: {
        ne: "DELETED"
      }
    },
    filter
  );
  const newEvent = await API.graphql(
    graphqlOperation(_api.managementSummaryStat(), { filter: combineFilter })
  );
  if (newEvent.errors) {
    throw new Error(JSON.stringify(newEvent.errors));
  }
  return newEvent.data.lambdaManagementSummary;
}

export function listManagementSummaryPatients(
  searchStr,
  filter,
  sort,
  pagination = DefaultPagination
) {
  let combineFilter = Object.assign(
    {
      group: {
        ne: "TRIAL_SILENCE"
      },
      accountStatus: {
        ne: "DELETED"
      }
    },
    filter
  );

  return API.graphql(
    graphqlOperation(_api.listManagementSummary(), {
      searchStr,
      filter: combineFilter,
      sort,
      limit: pagination.size,
      nextToken: pagination.page * pagination.size
    })
  );
}

export function searchBoxList(
  filter,
  sort = { field: "registeredAt", direction: "desc" },
  pagination = DefaultPagination
) {
  return API.graphql(
    graphqlOperation(_api.searchableBoxConnection(), {
      filter,
      sort,
      limit: pagination.size,
      nextToken: pagination.page * pagination.size
    })
  );
}

export async function searchHubList(
  filter,
  sort = { field: "registeredAt", direction: "desc" },
  pagination = DefaultPagination
) {
  const result = await API.graphql(
    graphqlOperation(_api.listHubs(), {
      filter,
      sort,
      limit: pagination.size,
      nextToken: pagination.page * pagination.size
    })
  );
  if (result.data) {
    return result.data.lambdaSearchHubs;
  } else {
    throw result.errors;
  }
}

export async function getExpireHubs(month) {
  if (!month) {
    throw "invalid arugment";
  }
  const fmtDate = month.split("-").join("");
  const filter = {
    expireDate: {
      wildcard: `${fmtDate}*`
    }
  };
  const sort = {
    field: "hubId",
    direction: "asc"
  };
  const limit = 100;
  let nextToken = 0;
  let hubs = [];
  while (true) {
    const result = await API.graphql(
      graphqlOperation(_api.getExpireHubs(), {
        filter,
        sort,
        limit,
        nextToken
      })
    );
    if (result.data) {
      const res = result.data.lambdaSearchHubs;
      hubs.push(res.items);
      if (res.nextToken == -1 || res.total == 0) {
        break;
      }
      nextToken = res.nextToken;
    } else {
      throw result.errors;
    }
  }

  return hubs.flat();
}

export async function getCompiledRpmNoteTemplate(tmplId, patientUserId) {
  const result = await API.graphql(
    graphqlOperation(_api.getCompiledRpmNoteTemplate(), {
      tmplId,
      patientUserId
    })
  );

  return result.data.getCompiledRpmNoteTemplate;
}

export async function listRpmNoteDetailedTemplates() {
  const result = await API.graphql(
    graphqlOperation(_api.listRpmNoteTemplatesDetails())
  );

  return result.data.getAllRpmNoteTemplates;
}

export async function createRpmNoteTemplate(input) {
  const result = await API.graphql(
    graphqlOperation(_api.createRpmNoteTemplate(), {
      input
    })
  );

  return result.data.createRpmNoteTemplate;
}

export async function updateRpmNoteTemplate(input) {
  const result = await API.graphql(
    graphqlOperation(_api.updateRpmNoteTemplate(), {
      input
    })
  );

  return result.data.updateRpmNoteTemplate;
}

export async function deleteRpmNoteTemplate(id) {
  const result = await API.graphql(
    graphqlOperation(_api.deleteRpmNoteTemplate(), {
      id
    })
  );

  return result.data.deleteRpmNoteTemplate;
}

export async function getSMSDripListAPI(filter, limit, nextToken) {
  const result = await API.graphql(
    graphqlOperation(_api.getSMSDripList(), {
      filter,
      limit,
      nextToken
    })
  );

  return result.data.listSmsDrip;
}

export async function getStaffList(filter, limit, nextToken) {
  const result = await API.graphql(
    graphqlOperation(_api.listStaff(), {
      filter,
      limit,
      nextToken
    })
  );
  return result.data.listStaff;
}

export async function getPatientActivity(
  patientId,
  limit,
  nextToken,
  yearMonth,
  category
) {
  const result = await API.graphql(
    graphqlOperation(_api.getPatientActivity(), {
      patientId,
      limit,
      nextToken,
      yearMonth,
      category
    })
  );

  return result.data.getPatientActivity;
}

export async function registerPatientAccount(formData) {
  const newEvent = await API.graphql(
    graphqlOperation(_api.registerAccount(), formData)
  );

  if (newEvent.data) {
    if (newEvent.data.createPatient.status) {
      return newEvent.data.createPatient.message;
    } else {
      throw newEvent.data.createPatient.message;
    }
  } else {
    throw newEvent.errors;
  }
}

export async function markSockData(input) {
  const newEvent = await API.graphql(
    graphqlOperation(_api.markSockData(), { input })
  );

  if (newEvent.data) {
    return;
  } else {
    throw newEvent.errors;
  }
}

export async function listPrescription(userId) {
  const result = await API.graphql(
    graphqlOperation(_api.listPrescription(), {
      userId
    })
  );

  return result.data.listPrescriptionHistory;
}

export async function createPrescription(input) {
  const result = await API.graphql(
    graphqlOperation(_api.createPrescription(), {
      input
    })
  );

  return result.data.createPrescription;
}

export async function updatePatientSingleFoot(id, singleFoot) {
  const result = await API.graphql(
    graphqlOperation(_api.updatePatientSingleFoot(), {
      id,
      singleFoot
    })
  );

  return result.data.updatePatientSingleFoot;
}

export function createStopwatchLog(input) {
  return API.graphql(
    graphqlOperation(_api.createStopwatchLog(), {
      input
    })
  );
}

export function listStopwatchLogs(userId) {
  return API.graphql(
    graphqlOperation(_api.listStopwatchLogs(), {
      userId
    })
  );
}

export async function listPatientMin1SlowStep(id, userId, startDate, endDate) {
  const input = {
    id,
    userId,
    startDate,
    endDate
  }
  const res = await API.graphql(
    graphqlOperation(_api.listPatientMin1SlowStep(), input)
  );

  return res.data.getPatient.min1SlowStep;
}
