import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { unlinkSock } from "../action/sockAction";
import ButtonSuccess from "../home/buttonSuccess";
import "../share/button.css";
import "./unlink.css";
class Unlink extends Component {
  constructor(props) {
    super(props);
    this.handleConfirm.bind(this);
  }
  state = { open: false, confirmSuccess: false };

  show = () => {
    this.setState({ open: true, confirmSuccess: false });
  };
  handleConfirm = () => {
    this.setState({ confirmSuccess: true });
    const { unlinkSock } = this.props;
    const userId = this.props.item.userId;
    const sockId = this.props.serialNo;
    unlinkSock(userId, sockId, this, this.props.id);
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini">
            Unlink Sock
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <p>
              Are you sure to unlink the sock "{this.props.serialNo}" from box "
              {this.props.boxId}"?
            </p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  boxList: state.boxListStore.boxList
});
const mapDispatchToProp = dispatch => ({
  unlinkSock: (userId, sockId, self, id) =>
    dispatch(unlinkSock(userId, sockId, self, id))
});
export default connect(mapStateToProp, mapDispatchToProp)(Unlink);
