import React, { Component } from "react";
import { Modal, Button, Icon, TextArea, Input } from "semantic-ui-react";
import "./SendMess.css";
import { Select } from "antd";
import { connect } from "react-redux";
import { getSendMess } from "../action/addAction";
import ButtonSuccess from "./buttonSuccess";
import "./ViewPhoto.css";
import "./SendMess.css";
import "./ViewPhoto";

const { Option } = Select;
class Mess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      type: "NORMAL",
      inputTerm: [],
      checkboxGroup: {
        appValue: true,
        msgValue: false,
        emailValue: false
      },
      showmsgValue: "",
      showemailValue: "",
      msgshow: true,
      emailshow: true
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShowSns = this.handleShowSns.bind(this);
    this.handleShowEmail = this.handleShowEmail.bind(this);
  }
  handleShowSns(event) {
    this.setState({ showmsgValue: event.target.value });
  }
  handleShowEmail(event) {
    this.setState({ showemailValue: event.target.value });
  }
  clear = () =>
    this.setState({
      showmsgValue: "",
      showemailValue: "",
      msgshow: true,
      emailshow: true,
      type: "NORMAL",
      checkboxGroup: {
        appValue: true,
        msgValue: false,
        emailValue: false
      },
      inputTerm: {
        subject: "",
        content: ""
      }
    });
  handleOpen = () =>
    this.setState({ open: true, confirmSuccess: false, type: "NORMAL" });
  close = () => this.setState({ open: false, confirmSuccess: false });
  handleChange(Input, value) {
    let inputTerm = Object.assign([], this.state.inputTerm);
    inputTerm[Input] = value;
    this.setState({ inputTerm: inputTerm });
  }
  handleCheck(event) {
    let obj = Object.assign(this.state.checkboxGroup);
    obj[event.target.value] = event.target.checked;
    this.setState({ checkboxGroup: obj });
  }
  handleSelect(value) {
    this.setState({
      type: value
    });
  }
  sendMsg() {
    this.setState({ showmsgValue: this.props.phone });
    this.setState({ msgshow: !this.state.msgshow });
  }
  sendEmail() {
    this.setState({ showemailValue: this.props.email });
    this.setState({ emailshow: !this.state.emailshow });
  }
  submit = () => {
    if (
      this.state.checkboxGroup.emailValue === false &&
      this.state.checkboxGroup.msgValue === false &&
      this.state.checkboxGroup.appValue === false
    ) {
    } else {
      this.setState({ confirmSuccess: true });
      const { getSendMess } = this.props;
      const information = {
        userId: this.props.userId,
        type: this.state.type === "PHOTO_UPLOAD" ? "RED" : this.state.type,
        app: this.state.checkboxGroup.appValue,
        email: this.state.checkboxGroup.emailValue,
        sms: this.state.checkboxGroup.msgValue,
        emailList: this.state.showemailValue,
        phoneList: this.state.showmsgValue,
        subject: this.state.inputTerm.subject,
        body: this.state.inputTerm.content
      };
      getSendMess(information, this);
      this.clear();
      this.setState({ modalIsOpen: true });
    }
  };
  render() {
    return (
      <Modal
        className="modal_box"
        trigger={
          <Button size="mini" onClick={this.handleOpen}>
            Send Message
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header className="edit-title">Send Message</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div className="mess_box">
              <div className="type_box">
                <span className="type_label">Type</span>
                <Select
                  value={this.state.type}
                  onChange={this.handleSelect}
                  className="select_common_style"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="NORMAL">NORMAL</Option>
                  <Option value="PHOTO_UPLOAD">PHOTO_UPLOAD</Option>
                </Select>
              </div>
              <div className="type_box">
                <span className="type_label">Channel</span>
                <label className="checkboxlabel">
                  <input
                    type="checkbox"
                    name="checkboxGroup"
                    value="appValue"
                    checked={this.state.checkboxGroup["appValue"]}
                    onChange={this.handleCheck}
                  />
                  <span className="kongge" />
                  In-App
                </label>
                <label className="checkboxlabel">
                  <input
                    type="checkbox"
                    name="checkboxGroup"
                    value="msgValue"
                    checked={this.state.checkboxGroup["msgValue"]}
                    onChange={this.handleCheck}
                    onClick={e => this.sendMsg()}
                  />
                  <span className="kongge" />
                  SMS
                </label>
                <label className="checkboxlabel">
                  <input
                    type="checkbox"
                    name="checkboxGroup"
                    value="emailValue"
                    checked={this.state.checkboxGroup["emailValue"]}
                    onChange={this.handleCheck}
                    onClick={e => this.sendEmail()}
                  />
                  <span className="kongge" />
                  Email
                </label>
              </div>
              <div className="type_box">
                <div
                  className="show_box showSns_box"
                  style={{ display: this.state.msgshow ? "none" : "block" }}
                >
                  <Input
                    value={this.state.showmsgValue}
                    placeholder="SMS"
                    type="text"
                    className="show_input"
                    onChange={this.handleShowSns}
                  />
                </div>
                <div
                  className="show_box"
                  style={{ display: this.state.emailshow ? "none" : "block" }}
                >
                  <Input
                    value={this.state.showemailValue}
                    placeholder="Email"
                    type="text"
                    className="show_input"
                    onChange={this.handleShowEmail}
                  />
                </div>
              </div>
              <div className="type_box">
                <span className="type_label">Title</span>
                <Input
                  value={this.state.inputTerm.subject || ""}
                  type="text"
                  className="title_input"
                  onChange={(e, data) => {
                    this.handleChange("subject", e.target.value);
                  }}
                />
              </div>
              <div className="type_box type_mess_box">
                <span className="mess_label">Message</span>
                <TextArea
                  className="mess_input"
                  value={this.state.inputTerm.content}
                  onChange={(e, data) => {
                    this.handleChange("content", e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  display:
                    this.state.checkboxGroup.emailValue === false &&
                    this.state.checkboxGroup.msgValue === false &&
                    this.state.checkboxGroup.appValue === false
                      ? "block"
                      : "none",
                  color: "red"
                }}
              >
                (*Checkbox must be selected to submit)
              </div>
              <div className="red_tip">
                <strong>PHOTO_UPLOAD</strong>：Send RED alert, require patient
                to upload photos.
              </div>
              <div className="normal_tip">
                <strong>NORMAL</strong>: Send notification
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.submit()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Send
          </Button>
          <Button
            basic
            onClick={e => this.clear()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="removeButton"
          >
            <Icon name="redo" />
            Reset
          </Button>
          <Button
            color="green"
            onClick={e => this.close()}
            style={{
              display: this.state.confirmSuccess ? "inline-block" : "none"
            }}
          >
            <Icon name="checkmark" />
            close
          </Button>
          <button onClick={e => this.close()} className="closebutton">
            <Icon name="remove" className="closeicon" />
          </button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  // complianceList: state.patientsListStore.complianceList
});
const mapDispatchToProp = dispatch => ({
  getSendMess: (information, self) => dispatch(getSendMess(information, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(Mess);
