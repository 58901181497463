import { Col, Row, Input, Select, Form } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getlistClinicSites,
  getlistStaff,
  listLPNs,
} from "../../action/registerAction";

const { Option } = Select;

class Enrollment extends Component {

  constructor(props) {
    super(props)
    this.state = {
      site: this.props.clinicSites.find(s => s.siteId === this.props.userInfo.siteId),
      doctorId: this.props.userInfo.doctorId,
      LPN: this.props.userInfo.LPN,
      patientHasSmartPhone: this.props.userInfo.patientHasSmartPhone
    }

    if (typeof this.props.onSiteChange === 'function') {
      this.props.onSiteChange(this.state.site)
    }
  }

  componentDidMount() {
    if (this.props.clinicSites.length === 0) {
      this.props.getlistClinicSites();
    }
    if (this.props.listStaff.length === 0) {
      this.props.getlistStaff();
    }
    if (this.props.listAdminStaff.length === 0) {
      this.props.listLPNs();
    }
    if (typeof this.props.injectGetMethod === 'function') {
			this.props.injectGetMethod(this.getEnrollmentCallback)
		}
  }

	getEnrollmentCallback = () => {
		const data = {...this.state}

		return data
	}

  handleSiteIdChange = (siteId) => {
    this.setState({
      site: this.props.clinicSites.find(s => s.siteId === siteId)
    }, () => {
      if (typeof this.props.onSiteChange === 'function') {
        this.props.onSiteChange(this.state.site)
      }
    })

    // auto fill doctor
    const tmp = this.props.listStaff.filter(item => item.isPrescribing && item.siteId.split("-").includes(siteId))
    if (tmp.length > 0) {
      this.setState({
        doctorId: tmp[0].email,
        doctorStatus: 'success'
      })
    }
  };

  render() {
    return (
      <div style={{ width: 600, margin: 'auto' }}>
        <Form>
          <div className='form-section'>
            <div className='form-section-title'>Enrollment Criteria</div>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Site Name"
                  required
                  className='form-item'
                >
                  <Select
                    size='large'
                    onChange={this.handleSiteIdChange}
                    value={this.state.site && this.state.site.siteId}
                    showSearch
                    optionFilterProp="children"
                    disabled={this.props.userGroups.includes("staff")}
                  >
                    {this.props.clinicSites.map(site => (
                      <Option value={site.siteId} key={site.siteId}>
                        {site.siteId + " - " + site.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Staffing"
                  required
                  className='form-item'
                >
                  <Input
                    size="large"
                    disabled
                    value={this.state.site ? (this.state.site.onStaffing === 'Yes' ? 'On Staffing' : 'Not On Staffing') : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Doctor'
                  required
                  className='form-item'
                >
                  <Select
                    size="large"
                    onChange={value => {
                      this.setState({
                        doctorId: value
                      })
                    }}
                    value={this.state.doctorId}
                    showSearch
                    optionFilterProp="children"
                  >
                    {this.props.listStaff
                      .filter(item => this.state.site ? (item.isPrescribing && item.siteId.split("-").includes(this.state.site.siteId)) : false)
                      .map(doctor => (
                        <Option value={doctor.email} key={doctor.email}>
                          {doctor.isPrescribing && !doctor.firstName.includes("Dr.")
                            ? "Dr. " + doctor.firstName + " " + doctor.lastName
                            : doctor.firstName + " " + doctor.lastName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Nurse'
                  required
                  className='form-item'
                >
                  <Select
                    size="large"
                    onChange={value => {
                      this.setState({
                        LPN: value
                      })
                    }}
                    value={this.state.LPN}
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value={""}>None</Option>
                    {this.props.listAdminStaff
                      .filter(item => item.group === "staff" && item.primarySites && JSON.parse(item.primarySites).includes(this.state.site.siteId))
                      .map(nurse => (
                        <Option value={nurse.id} key={nurse.id}>
                          {nurse.firstName} {nurse.lastName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className='form-section'>
            <div className='form-section-title'>General Questions</div>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Does the patient have a smartphone?"
                  className='form-item'
                  validateStatus={this.state.hasPhoneStatus}
                >
                  <Select
                    size="large"
                    onChange={value => {
                      this.setState({
                        patientHasSmartPhone: value
                      })
                    }}
                    value={this.state.patientHasSmartPhone}
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value={"Yes"}>Yes</Option>
                    <Option value={"Caregiver"}>Caregiver has a smartphone</Option>
                    <Option value={"No"}>No</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  userGroups: state.authGroupStore.session.userGroups,
  listStaff: state.patientsListStore.listStaff,
  clinicSites: state.patientsListStore.clinicSites,
  listAdminStaff: state.patientsListStore.listAdminStaff,
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getlistStaff: () => dispatch(getlistStaff()),
  listLPNs: () => dispatch(listLPNs()),
});
export default connect(mapStateToProp, mapDispatchToProp)(Enrollment);
