import React, { Component } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { unlinkRegisteredHub } from "../action/boxAction";
import { notification } from "antd";
import "./registerbox.css";

export default class unregisterHub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      regiSuccess: false
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  close = () => this.setState({ open: false });

  submit() {
    const creds = {
      userId: this.props.item.userId,
      serial: this.props.item.hubId,
      authcode: this.props.item.authcode
    };
    this.setState({
      loading: true
    })
    unlinkRegisteredHub(creds).then((res) =>{
      this.setState({
        loading: false
      })
      if (res.status) {
        this.setState({
          regiSuccess: true
        })
        setTimeout(() => {
          this.setState({
            open: false
          })
          if (typeof this.props.onChange === "function") {
            this.props.onChange(creds)
          }
        }, 500)
      } else {
        let desc = null
        if (res.msg) {
          desc = res.msg
        } else if (res.error) {
          if (res.error.msg) {
            desc = res.error.msg
          } else if (res.error instanceof Error) {
            desc = res.error.message
          } else {
            desc = JSON.stringify(res.error)
          }
        } else {
          desc = JSON.stringify(res)
        }
        notification.error({
          message: 'System Error',
          description: desc
        });
      }
    }).catch(error => {
      this.setState({
        loading: false
      })
      if (error instanceof Error) {
        return notification.error({
          message: 'System Error',
          description: error.message
        });
      }
      // graphQL error
      if (error.errors) {
        notification.error({
          message: 'System Error',
          description: error.errors[0].message
        });
      }
    });
  }

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={<Button onClick={this.handleOpen}>Unregister</Button>}
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>
          {this.state.regiSuccess
            ? "UnRegister the Hub success"
            : `Hub UnRegistration`}
        </Modal.Header>
        <Modal.Content>
          {this.state.regiSuccess ? (
            <div className="regiSuccess">
              <Icon name="check circle" id="regiSuccessIcon" />
              <p className="regiSuccessTitle">Awesome!</p>
              <p className="regiSuccesstext">
                Hub has been successfully unregistered.
              </p>
            </div>
          ) : (
            <div>
              <div className="search_patient_container">
                Are you sure you want to unregister hub: {this.props.item.hubId}?
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" loading={this.state.loading} onClick={e => this.submit()}>
            <Icon name="checkmark" />
            UnRegister Hub
          </Button>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.regiSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
