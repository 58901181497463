import _ from "lodash";
import React, { Component } from "react";
import { Button as AntdButton, Modal as AntdModal } from "antd";
import { connect } from "react-redux";
import { Button, Form, Icon, Input, Modal, TextArea } from "semantic-ui-react";
import { deleteSirenNote, editNote } from "../../action/DoctorNoteAction";
import ButtonSuccess from "../../home/buttonSuccess";
import { PushpinOutlined, PushpinFilled } from "@ant-design/icons";
import "./AddText.css";
const { confirm } = AntdModal;
class Add extends Component {
  state = {
    open: false,
    confirmSuccess: false,
    title: this.props.patientNote.title || "",
    note: this.props.patientNote.note || "",
    pinned: false,
    loadings: []
  };

  handleOpen = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      title: this.props.patientNote.title || "",
      note: this.props.patientNote.note || "",
      pinned: this.props.patientNote.pinned === "Yes"
    });
  };
  close = () =>
    this.setState({ open: false, confirmSuccess: false, loadings: [] });
  handleTitleChange = event => {
    this.setState({ title: event.target.value });
  };
  handleNoteChange = event => {
    this.setState({ note: event.target.value });
  };
  handlePinClick = () => {
    this.setState(state => ({
      pinned: !state.pinned
    }));
  };
  submit = () => {
    this.setState({ open: false });
    const input = {
      id: this.props.patientNote.id,
      author: this.props.loginId,
      timestamp: this.props.patientNote.timestamp
    };
    if (this.state.note && this.state.note !== "") {
      input.note = this.state.note;
    }
    if (this.state.title && this.state.title !== "") {
      input.title = this.state.title;
    }
    input.pinned = this.state.pinned ? "Yes" : "No";
    this.props.editNote(input);
  };
  showTitle() {
    // if there is a title, show 'title'. if no title, show 'note'
    let text = this.props.patientNote.title;
    if (!text) {
      text = this.props.patientNote.note;
    }
    if (!text) {
      text = "";
    }

    return text;
  }
  isAutoGenNote() {
    const autoGenTitles = [
      "Special Case: Yes",
      "Special Case: No",
      "Account Status Active",
      "Account Status Paused",
      "Account Service Status: On Service",
      "Account Service Status: Not On Service"
    ];
    return autoGenTitles.includes(this.state.title);
  }

  deleteNoteConfirm = () => {
    confirm({
      title: "Delete Note",
      content: `Are you sure you want to delete this note?`,
      cancelText: "No",
      okText: "Yes",
      onCancel: () => {},
      onOk: () => {
        this.handleDeleteNote();
      }
    });
  };

  handleDeleteNote = () => {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[0] = true;
      return {
        loadings: newLoadings
      };
    });
    const input = {
      id: this.props.patientNote.id,
      timestamp:  this.props.patientNote.timestamp
    };
    this.props.deleteSirenNote(input, this);
  };

  render() {
    const { open } = this.state;

    return (
      <Modal
        trigger={
          <div
            className="note_title_table two_line_ellipses"
            onClick={this.handleOpen}
          >
            {this.props.patientNote.pinned === "Yes" && (
              <PushpinFilled style={{ color: "#ffe58f" }} />
            )}{" "}
            {this.showTitle()}
          </div>
        }
        open={open}
      >
        <Modal.Header>
          EDIT NOTE
          <div
            style={{ float: "right" }}
            onClick={this.handlePinClick}
            title={this.state.pinned ? "Pinned" : "Unpinned"}
          >
            {this.state.pinned ? <PushpinFilled /> : <PushpinOutlined />}
          </div>
        </Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <Input
                    readOnly={this.isAutoGenNote()}
                    onChange={(e, data) => {
                      this.handleTitleChange(e);
                    }}
                    value={this.state.title}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width="16">
                  <TextArea
                    className="note_comments"
                    onChange={(e, data) => {
                      this.handleNoteChange(e);
                    }}
                    value={this.state.note}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions style={{ display: "flex" }}>
          {(this.props.loginId === this.props.patientNote.authorId ||
            this.props.loginId === this.props.patientNote.author) && (
            <AntdButton
              type="link"
              style={{
                color: "red"
                // float: "left",
                // position: "absolute"
              }}
              loading={this.state.loadings[0]}
              onClick={this.deleteNoteConfirm}
            >
              Delete
            </AntdButton>
          )}
          <div style={{flex: 1}}/>
          {(this.props.loginId === this.props.patientNote.authorId ||
            this.props.loginId === this.props.patientNote.author) && (
            <Button
              color="green"
              onClick={e => this.submit()}
              style={{
                display: this.state.confirmSuccess ? "none" : "inline-block",
                float: "right"
              }}
            >
              <Icon name="checkmark" />
              Submit
            </Button>
          )}
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  loginId: state.authGroupStore.profile.loginId
});
const mapDispatchToProp = dispatch => ({
  editNote: input => dispatch(editNote(input)),
  deleteSirenNote: (input, self) => dispatch(deleteSirenNote(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(Add);
