import { API, graphqlOperation, Storage } from "aws-amplify";
import { forEach } from "lodash";
import zlib from "react-zlib-js";
import Buffer from "react-zlib-js/buffer";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";

export function searchPatient(self, sirenId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listRegisteredPatients(), {
          filter: {
            group: {
              ne: "TRIAL_SILENCE"
            },
            sirenId: { eq: sirenId }
          },
          limit: 10
        })
      );
      if (newEvent) {
        if (
          newEvent.data.lambdaSearchPatients.items &&
          newEvent.data.lambdaSearchPatients.items.length > 0
        ) {
          self.setState({
            patientInfo: newEvent.data.lambdaSearchPatients.items[0],
            id: newEvent.data.lambdaSearchPatients.items[0].id,
            userId: newEvent.data.lambdaSearchPatients.items[0].userId,
            patient: newEvent.data.lambdaSearchPatients.items
          });
          self.handleOK();
        }
      }
    } catch (e) {
      console.log("searchPatient error: ", e);
    }
  };
}

export function getPatientUserId(self, sirenId) {
  return async dispatch => {
    try {
      let logKeys = [];
      const newEvent = await API.graphql(
        graphqlOperation(_api.searchPatientsConnection(), {
          filter: {
            sirenId: {
              eq: sirenId
            }
          },
          limit: 10
        })
      );
      if (newEvent) {
        if (
          newEvent.data.lambdaSearchPatients.items &&
          newEvent.data.lambdaSearchPatients.items.length > 0
        ) {
          self.setState({
            userId: newEvent.data.lambdaSearchPatients.items[0].userId
          });
          Storage.list("", {
            identityId: newEvent.data.lambdaSearchPatients.items[0].userId,
            level: "private"
          })
            .then(result => {
              forEach(result.reverse(), function(item) {
                if (
                  item.key.includes("siren_log") ||
                  item.key.includes("ble_log")
                ) {
                  logKeys.push(item.key);
                }
              });
              // console.log("logKeys: ", logKeys);
              dispatch(changeLogKeysState(logKeys));
            })
            .catch(err => {
              console.log("err: ", err);
            });
        }
      }
    } catch (e) {
      console.log("searchPatient error: ", e);
    }
  };
}

export function getLogFromS3(self, key) {
  return dispatch => {
    Storage.get(key, {
      identityId: self.state.userId,
      level: "private"
    })
      .then(async result => {
        fetch(result)
          .then(res => {
            return res.text();
          })
          .then(data => {
            const inflateData = zlib
              .inflateSync(new Buffer(data, "base64"))
              .toString();
            self.setState({ log: inflateData });
          })
          .catch(e => console.log(`get log for key ${key} failed: `, e));
      })
      .catch(err => {
        console.log(`get log for key ${key} failed: `, err);
      });
  };
}

function changeLogKeysState(logKeys) {
  return {
    type: TYPES.LOG_KEYS,
    text: logKeys
  };
}

export function testSimulateData(jsonData, self) {
  return async dispatch => {
    try {
      self.setState({ modalIsOpen: true });
      await API.graphql(
        graphqlOperation(_api.sendSimulateData(), {
          input: {
            simulateData: jsonData
          }
        })
      );
      self.setState({ modalIsOpen: false });
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("testSimulateData failed: ", e);
    }
  };
}
