import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import Calendar from "react-calendar";
import { connect } from "react-redux";
import "../MedicalDashboard/medicaldetails/calendar.css";
import { getTagMinuteWorn } from "../action/tagAction";
class UserStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      userId: "",
      id: ""
    };
  }

  componentDidMount() {
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const userId = params.userId;
    const id = params.id.replace(" ", "+");
    this.setState({ userId, id });
  }

  turnChange = ({ activeStartDate }) => {
    const { getTagMinuteWorn } = this.props;
    let StartDate = moment(activeStartDate)
      .add(-6, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment(activeStartDate)
      .add(35, "days")
      .format("YYYY-MM-DD");
    getTagMinuteWorn(
      this.state.id,
      this.state.userId,
      StartDate,
      EndDate,
      this
    );
  };

  // everytime return a new function, so Calender will recalculate
  calendarClassName = patientWorn => ({ date, view }) => {
    let renderDate = moment(date).format("YYYY-MM-DD");
    const month = date.getMonth();
    let activemonth = new Date().getMonth();
    for (let index = 0; index < patientWorn.length; index++) {
      const data = patientWorn[index];
      // patientWorn is acending ordered
      if (data.date > renderDate) {
        return;
      }
      if (data.date === renderDate && data.minuteWorn >= 30) {
        if (activemonth === month) {
          return "timeMore";
        } else {
          return "timeMoreLast";
        }
      }
    }
  };

  render() {
    return (
      <div>
        <div
          className="calendar_userbox2"
          style={{ height: 420, marginLeft: 60, marginTop: "4px" }}
        >
          <div className="title" style={{ textAlign: "center", height: 30 }}>
            COMPLIANCE CALENDAR
          </div>
          <div>
            <Calendar
              tileClassName={this.calendarClassName(this.props.tagTimeWorn)}
              minDetail="month"
              locale="en"
              onActiveDateChange={this.turnChange}
              style={{ height: "340px" }}
            />
          </div>
          <div className="ico1box">
            <div className="ico4" />
            <div className="ico4text">30+ mins of wear</div>
          </div>
        </div>
        <div className="userbox4" />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  tagTimeWorn: state.patientsListStore.tagTimeWorn
});

const mapDispatchToProp = dispatch => ({
  getTagMinuteWorn: (id, userid, startDate, endDate, self) =>
    dispatch(getTagMinuteWorn(id, userid, startDate, endDate, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(UserStatus);
