import { Select } from "antd";
import _, { isEmpty } from "lodash";
import React, { Component } from "react";
import ReactPhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { Button, Checkbox, Icon, Input, Modal } from "semantic-ui-react";
import { updateSiteId } from "../../action/registerAction";
import { editSiteNPI } from "../../action/toolsAction";
import ButtonSuccess from "../../home/buttonSuccess";
import { phoneWithCountryFormat } from "../../tools/phoneFormat";
import "./NewSite.css";
import message from "../../tools/message-info";
import { getEMRList } from "../../action/emrAction";

class EditSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      siteId: "",
      siteName: "",
      address: "",
      phone: "",
      fax: "",
      contact: "",
      confirmSuccess: false,
      serviceDayType: "DISPENSED_DAY",
      ifAutoReport: false,
      siteNPI: "",
      siteNPIs: [],
      newSiteNPI: "",
      city: "",
      siteState: "",
      zip: "",
      IRBConsent: "",
      hubContract: "",
      EMRSystem: "",
      parentSiteId: ""
    };
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.hubContractChanged = this.hubContractChanged.bind(this);
    this.IRBConsentChanged = this.IRBConsentChanged.bind(this);
    this.EMRSystemChanged = this.EMRSystemChanged.bind(this);
    this.handleCheckAuto = this.handleCheckAuto.bind(this);
    this.patientSiteChanged = this.patientSiteChanged.bind(this);
  }
  EMRSystemChanged(value) {
    this.setState({ EMRSystem: value });
  }
  handleTypeChange(value) {
    this.setState({ serviceDayType: value });
  }
  handleCheckAuto() {
    this.setState({ ifAutoReport: !this.state.ifAutoReport });
  }
  patientSiteChanged(value) {
    this.setState({ siteId: value });
  }
  handleOpen = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      siteId:
        this.props.newSite && this.props.newSite.siteId
          ? this.props.newSite.siteId
          : "",
      parentSiteId:
        this.props.newSite && this.props.newSite.parentSiteId
          ? this.props.newSite.parentSiteId
          : this.props.newSite && this.props.newSite.siteId,
      siteName:
        this.props.newSite && this.props.newSite.name
          ? this.props.newSite.name
          : "",
      address:
        this.props.newSite && this.props.newSite.address
          ? this.props.newSite.address
          : "",
      city:
        this.props.newSite && this.props.newSite.city
          ? this.props.newSite.city
          : "",
      siteState:
        this.props.newSite && this.props.newSite.state
          ? this.props.newSite.state
          : "",
      zip:
        this.props.newSite && this.props.newSite.zip
          ? this.props.newSite.zip
          : "",
      siteNPI:
        this.props.newSite && this.props.newSite.siteNPI
          ? this.props.newSite.siteNPI
          : "",
      phone:
        this.props.newSite && this.props.newSite.phone
          ? phoneWithCountryFormat(this.props.newSite.phone)
          : "",
      contact:
        this.props.newSite && this.props.newSite.contact
          ? this.props.newSite.contact
          : "",
      serviceDayType: this.props.newSite
        ? this.props.newSite.serviceDate
        : "DISPENSED_DAY",
      ifAutoReport: this.props.newSite
        ? this.props.newSite.autoDoctorReport
        : false,
      IRBConsent:
        this.props.newSite && this.props.newSite.IRBConsent
          ? this.props.newSite.IRBConsent
          : "",
      hubContract:
        this.props.newSite && this.props.newSite.hubContract
          ? this.props.newSite.hubContract.replace("Wi-Fi", "Wifi")
          : "Wifi",
      EMRSystem:
        this.props.newSite && this.props.newSite.EMRSystem
          ? this.props.newSite.EMRSystem
          : "",
      onStaffing:
        this.props.newSite && this.props.newSite.onStaffing
          ? this.props.newSite.onStaffing
          : "No"
    });
    const { checkSiteNPI } = this.props;
    checkSiteNPI(
      this.props.newSite && this.props.newSite.siteId
        ? this.props.newSite.name
        : "",
      this
    );
    if (this.props.EMRSystemList.length === 0) {
      this.props.getEMRList();
    }
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });
  changeSiteName = event => {
    this.setState({ siteName: event.target.value });
  };

  changeAddress = event => {
    this.setState({ address: event.target.value });
  };

  onChangeCity = event => {
    this.setState({ city: event.target.value });
  };

  onChangeState = event => {
    this.setState({ siteState: event.target.value });
  };
  onChangeZip = event => {
    this.setState({ zip: event.target.value });
  };

  hubContractChanged(value) {
    this.setState({ hubContract: value });
  }

  changeSiteNPI = event => {
    this.setState({ siteNPI: event });
  };

  IRBConsentChanged(value) {
    this.setState({ IRBConsent: value });
  }

  changeSearchSiteNPI(value) {
    if (!!value) {
      this.setState({ newSiteNPI: value });
    }
  }

  changeBlurSiteNPI(value) {
    const { newSiteNPI } = this.state;
    if (!!newSiteNPI) {
      this.changeSiteNPI(newSiteNPI);
      this.setState({ newSiteNPI: "" });
    }
  }

  changePhone = phone => {
    let noSym1 = phone.replace("(", "");
    let noSym2 = noSym1.replace(")", "");
    let noSym3 = noSym2.replace("-", "");
    let noBlank = noSym3.replace(/\s+/g, "");
    this.setState({ phone: noBlank });
  };

  changeContactName = event => {
    this.setState({ contact: event.target.value });
  };
  handleConfirm = () => {
    const { updateSiteId } = this.props;
    const updateSiteInput = {
      input: {
        siteId: this.state.siteId,
        serviceDate: this.state.serviceDayType,
        autoDoctorReport: this.state.ifAutoReport,
        hubContract: this.state.hubContract,
        EMRSystem: this.state.EMRSystem,
        onStaffing: this.state.onStaffing
      }
    };
    if (!isEmpty(this.state.siteName)) {
      Object.assign(updateSiteInput.input, {
        name: this.state.siteName
      });
    }
    if (!isEmpty(this.state.address)) {
      Object.assign(updateSiteInput.input, {
        address: this.state.address
      });
    }
    if (!isEmpty(this.state.city)) {
      Object.assign(updateSiteInput.input, {
        city: this.state.city
      });
    }
    if (!isEmpty(this.state.siteState)) {
      Object.assign(updateSiteInput.input, {
        state: this.state.siteState
      });
    }
    if (!isEmpty(this.state.zip)) {
      Object.assign(updateSiteInput.input, {
        zip: this.state.zip
      });
    }
    if (!isEmpty(this.state.phone)) {
      if (isEmpty(phoneWithCountryFormat(this.state.phone))) {
        message.info("Invalid phone number");
        return;
      }
      Object.assign(updateSiteInput.input, {
        phone: this.state.phone
      });
    }
    if (!isEmpty(this.state.contact)) {
      Object.assign(updateSiteInput.input, {
        contact: this.state.contact
      });
    }
    if (this.state.siteNPI !== "") {
      Object.assign(updateSiteInput.input, {
        siteNPI: this.state.siteNPI
      });
    }
    if (!isEmpty(this.state.IRBConsent)) {
      Object.assign(updateSiteInput.input, {
        IRBConsent: this.state.IRBConsent
      });
    }
    updateSiteId(updateSiteInput, this);
    this.setState({ confirmSuccess: true });
  };

  checkSiteNpi() {
    if (isEmpty(this.state.siteName)) {
      return;
    }
    const { checkSiteNPI } = this.props;
    checkSiteNPI(this.state.siteName, this);
  }

  render() {
    const { open } = this.state;
    const { Option } = Select;
    return (
      <Modal
        trigger={
          <Button
            size="small"
            onClick={this.handleOpen}
            style={{ display: "none" }}
          >
            Edit
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>Edit site</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div
              className="newSite_box"
              style={{ height: 400, overflowY: "auto" }}
            >
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal" style={{ marginTop: "5%" }}>
                  <Checkbox
                    className="checkbox_box"
                    checked={this.state.ifAutoReport}
                    onChange={this.handleCheckAuto}
                  />
                  <span className="checkbox_label" style={{ paddingLeft: 10 }}>
                    Auto Report
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">Site Name</p>
                  <Input
                    placeholder="Podiatrist Office Name"
                    className="inputContent"
                    value={this.state.siteName}
                    onChange={this.changeSiteName}
                    onBlur={event => this.checkSiteNpi(event)}
                  />
                </div>
                <div className="inputTotal">
                  <p className="inputLable">Parent Site</p>
                  <Input
                    placeholder="Patient Site"
                    className="inputContent"
                    value={this.state.parentSiteId}
                    disabled
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">Phone</p>
                  <ReactPhoneInput
                    inputExtraProps={{
                      name: "phone",
                      required: true,
                      autoFocus: false
                    }}
                    defaultCountry={"us"}
                    value={this.state.phone}
                    onChange={phone => this.changePhone(phone)}
                    disableAreaCodes
                  />
                </div>
                <div className="inputTotal">
                  <p className="inputLable">Primary Contact</p>
                  <Input
                    placeholder="John Smith"
                    className="inputContent"
                    value={this.state.contact}
                    onChange={this.changeContactName}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "70%",
                  marginLeft: "15%",
                  marginRight: "10%",
                  marginBottom: 20
                }}
              >
                {/*<div className="inputTotal">*/}
                <p className="inputLable">Site Address</p>
                <Input
                  placeholder="Address"
                  style={{ width: "100%" }}
                  value={this.state.address}
                  onChange={this.changeAddress}
                />
                <div style={{ marginTop: 10 }}>
                  <Input
                    style={{ width: "40%" }}
                    placeholder="City"
                    value={this.state.city}
                    onChange={this.onChangeCity}
                  />
                  <Input
                    style={{ width: "30%", marginLeft: "1%" }}
                    placeholder="State"
                    value={this.state.siteState}
                    onChange={this.onChangeState}
                  />
                  <Input
                    style={{ width: "28%", marginLeft: "1%" }}
                    placeholder="Zip"
                    value={this.state.zip}
                    onChange={this.onChangeZip}
                  />
                </div>
                {/*</div>*/}
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">Service Day Type</p>
                  <Select
                    className="selectStyle"
                    onChange={this.handleTypeChange}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.serviceDayType}
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="DISPENSED_DAY">DISPENSED_DAY</Option>
                    <Option value="THE_16TH_DAY">THE_16TH_DAY</Option>
                  </Select>
                </div>
                <div className="inputTotal">
                  <p className="inputLable">Registry Consent</p>
                  <Select
                    className="selectStyle"
                    onChange={this.IRBConsentChanged}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.IRBConsent}
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="Not Approved">Not Approved</Option>
                    <Option value="Approved">Approved</Option>
                  </Select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">Site NPI</p>
                  {/*/>*/}
                  <Select
                    className="selectStyle"
                    value={this.state.siteNPI}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                    onChange={this.changeSiteNPI}
                    onSearch={value => this.changeSearchSiteNPI(value)}
                    onBlur={value => this.changeBlurSiteNPI(value)}
                  >
                    <Option value={" "}>None</Option>
                    {this.state.siteNPIs.map((npi, i) => {
                      return (
                        <Option value={npi.number} key={"npi" + i}>
                          {npi.number +
                            " - " +
                            npi.addresses[0].address_1 +
                            " " +
                            npi.addresses[0].city +
                            ", " +
                            npi.addresses[0].state +
                            " " +
                            npi.addresses[0].postal_code}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="inputTotal">
                  <p className="inputLable">Hub Contract</p>
                  <Select
                    className="selectStyle"
                    onChange={this.hubContractChanged}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.hubContract}
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="Wifi">Wi-Fi</Option>
                    <Option value="4G/Wifi">4G/Wi-Fi</Option>
                  </Select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">EMR System</p>
                  <Select
                    className="selectStyle"
                    onChange={this.EMRSystemChanged}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.EMRSystem}
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="">None</Option>
                    {this.props.EMRSystemList.map(p => {
                      return (
                        <Option value={p.name} key={p.name}>
                          {p.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="inputTotal">
                  <p className="inputLable">Staffing</p>
                  <Select
                    className="selectStyle"
                    onChange={value => this.setState({ onStaffing: value })}
                    value={this.state.onStaffing}
                    optionFilterProp="children"
                  >
                    <Option value="No">Not On Staffing</Option>
                    <Option value="Yes">On Staffing</Option>
                  </Select>
                </div>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button basic color="red" onClick={e => this.handleCancel()}>
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  EMRSystemList: state.patientsListStore.EMRSystemList
});
const mapDispatchToProp = dispatch => ({
  updateSiteId: (updateSiteInput, self) =>
    dispatch(updateSiteId(updateSiteInput, self)),
  checkSiteNPI: (siteName, self) => dispatch(editSiteNPI(siteName, self)),
  getEMRList: () => dispatch(getEMRList())
});
export default connect(mapStateToProp, mapDispatchToProp)(EditSite);
