import { DatePicker, Select } from "antd";
import "antd/dist/antd.css";
import { API, Auth } from "aws-amplify";
import { filter, find } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import "react-dropdown/style.css";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Button } from "semantic-ui-react";
import {
  changeMonthlyReportList,
  checkAllReportList,
  resetAllSirenIdReport,
  updateBilledStatus
} from "../action/bliMonthyReportAction";
import { getListInvoicePatients } from "../action/patientAction";
import { getInvoicelistClinicSites } from "../action/registerAction";
import aws_exports from "../aws-exports-siren";
import Progress from "../home/progress";
import "../share/common.css";
import "./biMonthly.css";
import message from "../tools/message-info";
import { removeAllSpace } from "../tools/stringProcess";

// const COLOR_LIST = [
//   "#FFFF99",
//   "#B5FF91",
//   "#94DBFF",
//   "#FFBAFF",
//   "#FFBD9D",
//   "#C7A3ED",
//   "#CC9898",
//   "#8AC007",
//   "#CCC007",
//   "#FFAD5C"
// ];
const MONTH_TEMP = [
  "1 - January",
  "2 - February",
  "3 - March",
  "4 - April",
  "5 - May",
  "6 - June",
  "7 - July",
  "8 - August",
  "9 - September",
  "10 - October",
  "11 - November",
  "12 - December"
];
Modal.setAppElement("div");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};
// const monthFormat = "YYYY/MM";
const { MonthPicker } = DatePicker;
const { Option } = Select;
class InvoiceReport extends Component {
  constructor(props) {
    super(props);
    this.needs = [];
    this.state = {
      modalIsOpen: false,
      billingYear: new Date().getFullYear(),
      billingMonth: new Date().getMonth() + 1,
      billingEndMonth: new Date().getMonth() + 2,
      billingEndMonthStr: MONTH_TEMP[new Date().getMonth() + 1],
      siteId: ["All"],
      checkedAll: true,
      // reportPeriod: new Date().getDate() <= 15 ? "firstHalf" : "SecondHalf"
      reportPeriod: "allMonth",
      startDate: moment().format("YYYY-MM-01"),
      endDate: moment()
        .add(1, "month")
        .format("YYYY-MM-01")
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.searchUsers = this.searchUsers.bind(this);
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.selectBillMonth = this.selectBillMonth.bind(this);
    this.selectBillEndMonth = this.selectBillEndMonth.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
  }
  componentDidMount() {
    const { getlistClinicSites } = this.props;
    // getGroups(this);
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites(this);
    }
    this.getInvoicePatient(this.state.siteId);
  }

  getInvoicePatient() {
    const { getListInvoicePatients } = this.props;
    const billingInputValue = {
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };
    getListInvoicePatients(billingInputValue, this);
  }

  formatMonth(month) {
    if (month < 10) {
      return "0" + month;
    }
    return String(month);
  }

  handleCheckAll() {
    this.setState({ checkedAll: !this.state.checkedAll });
    const { checkAllReportList } = this.props;
    const CheckAllValue = {
      checkedAll: !this.state.checkedAll,
      siteId: this.state.siteId
    };
    checkAllReportList(this, CheckAllValue);
  }
  handleCheck(patient) {
    const { changeMonthlyReportList } = this.props;
    changeMonthlyReportList(this, patient);
  }
  handleClear() {
    const { resetAllSirenIdReport } = this.props;
    resetAllSirenIdReport();
  }
  onSelectChange(value) {
    let filtered = value.filter(function(valueFilter, index, arr) {
      return valueFilter !== "All";
    });
    if (value.indexOf("All") >= 0) {
      if (value.length > 1 && value[value.length - 1] !== "All") {
        this.setState({ siteId: filtered });
      } else {
        this.setState({ siteId: ["All"] });
      }
    } else {
      this.setState({ siteId: value });
    }
  }

  selectBillMonth(value) {
    if (this.state.billingEndMonth < value) {
      this.setState({
        billingEndMonth: parseInt(value),
        billingEndMonthStr: MONTH_TEMP[parseInt(value) - 1]
      });
    }
    this.setState({ billingMonth: parseInt(value) });
  }

  selectBillEndMonth(value) {
    this.setState({
      billingEndMonth: parseInt(value),
      billingEndMonthStr: MONTH_TEMP[parseInt(value) - 1]
    });
  }

  searchUsers() {
    const { getListInvoicePatients } = this.props;
    if (this.state.siteId.length > 0) {
      const billingInputValue = {
        startDate: this.state.startDate,
        endDate: this.state.endDate
      };
      const billingInputValueWithSiteId = {
        siteIds: this.state.siteId,
        startDate: this.state.startDate,
        endDate: this.state.endDate
      };
      getListInvoicePatients(
        this.state.siteId.indexOf("All") >= 0
          ? billingInputValue
          : billingInputValueWithSiteId,
        this
      );
    } else {
      message.info("Please select siteId.");
    }
  }

  handleYearChange = value => {
    this.setState({ billingYear: parseInt(value) });
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  async downloadReport() {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const siteId = this.state.siteId;
      const startDate = this.state.startDate;
      const endDate = this.state.endDate;
      let downloadUrl;
      const endpointInfo = filter(aws_exports.aws_cloud_logic_custom, {
        name: "dev-pdf-exporter"
      });
      const pdfEndpoint = endpointInfo[0].endpoint;
      if (this.state.siteId.indexOf("All") >= 0) {
        downloadUrl = `${pdfEndpoint}/admin/invoice?startDate=${startDate}&endDate=${endDate}&token=${token}`;
      } else {
        downloadUrl = `${pdfEndpoint}/admin/invoice?siteIds=${JSON.stringify(
          siteId
        )}&startDate=${startDate}&endDate=${endDate}&token=${token}`;
      }
      window.open(downloadUrl);
    } catch (e) {
      console.log("download pdf error: ", e);
    }
  }

  getReportPdf() {
    const apiName = "dev-pdf-exporter";
    const path = "/admin/pdf";
    const params = {
      queryStringParameters: {
        siteId: this.state.siteId,
        year: this.state.billingYear,
        month: this.state.billingMonth - 1
      }
    };
    return API.get(apiName, path, params);
  }

  getColorByRandom(colorList) {
    let colorIndex = Math.floor(Math.random() * colorList.length);
    let color = colorList[colorIndex];
    return color;
  }

  changeStartDate = (date, dateString) => {
    this.setState({ startDate: dateString + "-01" });
  };
  changeEndDate = (date, dateString) => {
    this.setState({ endDate: dateString + "-01" });
  };

  render() {
    return (
      <div className="device-list-container">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="search_patient_container">
          <span className="search_label">SiteID:</span>
          <Select
            mode="multiple"
            tokenSeparators={[" "]}
            onChange={this.onSelectChange}
            value={this.state.siteId}
            optionFilterProp="children"
            filterOption={(input, option) =>
              removeAllSpace(option.props.children)
                .toLowerCase()
                .indexOf(removeAllSpace(input).toLowerCase()) >= 0
            }
          >
            <Option value={"All"} key={"groupAll"}>
              All
            </Option>
            {this.props.clinicSites &&
              this.props.clinicSites.map((group, i) => {
                return (
                  <Option value={group.siteId} key={"group" + i}>
                    {group.siteId + " - " + group.name}
                  </Option>
                );
              })}
          </Select>
          <span className="search_label search_time_label">StartDate: </span>
          <MonthPicker
            value={moment(this.state.startDate, "YYYY-MM")}
            onChange={this.changeStartDate}
            allowClear={false}
          />
          <span className="search_label search_time_label">End Date:</span>
          <MonthPicker
            value={moment(this.state.endDate, "YYYY-MM")}
            onChange={this.changeEndDate}
            allowClear={false}
          />
          <Button
            size="small"
            onClick={this.searchUsers}
            style={{ marginLeft: 20 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.downloadReport()}
            style={{ marginLeft: 20 }}
          >
            Download
          </Button>
        </div>
        <ReactTable
          data={
            this.props.listInvoicePatient &&
            this.props.listInvoicePatient.map(p => {
              const fullName = p.givenName;
              const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
              const dispensedDay = p.createdAt ? p.createdAt.split("T")[0] : "";
              const siteInfo = find(this.props.clinicSites, {
                siteId: p.siteId
              });
              const siteName = siteInfo === undefined ? "" : siteInfo.name;
              const siteId = p.siteId;
              const daysWorn = p.stdDaysWorn;
              const checkMonth = p.month;
              return {
                ...p,
                fullName,
                createdAt,
                dispensedDay,
                siteId,
                siteName,
                daysWorn,
                checkMonth
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Month",
              accessor: "checkMonth",
              maxWidth: 100,
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "SiteId",
              accessor: "siteId",
              maxWidth: 100,
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "SiteName",
              accessor: "siteName",
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "SirenID",
              accessor: "sirenId",
              maxWidth: 60,
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "Full Name",
              accessor: "fullName",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.fullName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Created Day",
              accessor: "createdAt",
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "Dispensed Day",
              accessor: "dispensedDay",
              minWidth: 110,
              // Cell: props => {
              //   return (
              //     <div>
              //       {props.original.dispensedDay}
              //       <ChangeDispensedDay
              //         userId={props.original.userId}
              //         dispensedDay={props.original.dispensedDay}
              //         from="BiMonthly"
              //       />
              //     </div>
              //   );
              // },
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "Days Worn",
              accessor: "daysWorn",
              filterable: false
            }
          ]}
          // defaultSorted={[{ id: "sirenId", desc: false }]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  searchPatientsList: state.monthlyReportStore.searchPatientsList,
  bliSirenIdReport: state.monthlyReportStore.bliSirenIdReport,
  filterGroup: state.patientsListStore.filterGroup,
  clinicSites: state.patientsListStore.clinicSites,
  listInvoicePatient: state.patientsListStore.listInvoicePatient
});
const mapDispatchToProp = dispatch => ({
  getListInvoicePatients: (billingInputValue, self) =>
    dispatch(getListInvoicePatients(billingInputValue, self)),
  changeMonthlyReportList: (self, patient) =>
    dispatch(changeMonthlyReportList(self, patient)),
  resetAllSirenIdReport: () => dispatch(resetAllSirenIdReport()),
  checkAllReportList: (self, CheckAllValue) =>
    dispatch(checkAllReportList(self, CheckAllValue)),
  updateBilledStatus: (inputData, self) =>
    dispatch(updateBilledStatus(inputData, self)),
  getlistClinicSites: self => dispatch(getInvoicelistClinicSites(self))
});
export default connect(mapStateToProp, mapDispatchToProp)(InvoiceReport);
