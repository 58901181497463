import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal, Input } from "semantic-ui-react";
import { Select } from "antd";
import { getrRemoteConnect } from "../action/sockAction";
import ButtonSuccess from "../home/buttonSuccess";
import "../share/button.css";
import "./hubunlink.css";
import "./remoteConnect.css";
import _ from "lodash";
class RemoteConnect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      server: "54.202.187.226",
      port: "8081",
      name: "ubuntu",
      status: "on"
    };
    this.handleConfirm.bind(this);
    this.handleInputServer = this.handleInputServer.bind(this);
    this.handleInputPort = this.handleInputPort.bind(this);
    this.handleInputName = this.handleInputName.bind(this);
    this.handleInputStatus = this.handleInputStatus.bind(this);
  }
  state = { open: false, confirmSuccess: false };

  show = () =>
    this.setState({
      open: true,
      confirmSuccess: false,
      server: "54.202.187.226",
      port: "8081",
      name: "ubuntu",
      status: "on"
    });
  handleConfirm = () => {
    this.setState({ confirmSuccess: true });
    const { getrRemoteConnect } = this.props;
    const inputValue = {
      input: {
        userId: this.props.item.userId,
        hubId: this.props.serial,
        server: this.state.server,
        port: this.state.port,
        userName: this.state.name,
        status: this.state.status
      }
    };
    getrRemoteConnect(inputValue, this);
  };
  handleCancel = () =>
    this.setState({ open: false, confirmSuccess: false, status: "" });
  handleInputServer(event) {
    this.setState({ server: event.target.value });
  }
  handleInputName(event) {
    this.setState({ name: event.target.value });
  }
  handleInputPort(event) {
    this.setState({ port: event.target.value });
  }
  handleInputStatus(event) {
    this.setState({ status: event });
  }
  render() {
    const { Option } = Select;
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini">
            Remote Connect
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header>Remote Connect</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <p className="remoteConnect_tip">
                Input the Server and Port of the 4G Hub, and then Send the
                request for remote connection.
              </p>
              <div>
                <div className="remoteConnect_left">
                  <div className="remoteConnect_label">Server (IP):</div>
                  <Input
                    onChange={this.handleInputServer}
                    value={this.state.server}
                  />
                </div>
                <div className="remoteConnect_right">
                  <div className="remoteConnect_label">Port:</div>
                  <Input
                    onChange={this.handleInputPort}
                    value={this.state.port}
                  />
                </div>
                <div style={{ clear: "both" }} />
              </div>
              <div style={{ marginTop: 10 }}>
                <div className="remoteConnect_left">
                  <div className="remoteConnect_label">User Name:</div>
                  <Input
                    onChange={this.handleInputName}
                    value={this.state.name}
                  />
                </div>
                <div className="remoteConnect_right">
                  <div className="remoteConnect_label">Status:</div>
                  <Select
                    style={{ width: 177, height: 38 }}
                    onChange={(e, data) => {
                      this.handleInputStatus(e);
                    }}
                    value={this.state.status}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="on">ON</Option>
                    <Option value="off">OFF</Option>
                  </Select>
                </div>
                <div style={{ clear: "both" }} />
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display:
                this.state.confirmSuccess ||
                _.isEmpty(this.state.server) ||
                _.isEmpty(this.state.port) ||
                _.isEmpty(this.state.name) ||
                _.isEmpty(this.state.status)
                  ? "none"
                  : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Send
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({
  getrRemoteConnect: (inputValue, self) =>
    dispatch(getrRemoteConnect(inputValue, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(RemoteConnect);
