import moment from "moment";
import React from "react";

const RowClickableClassName = "row-clickable";

/**
 * 这里只是修复ReactTable的行点击事件。用法：
```
 const trProps = (state, rowInfo, column) => ({
		className: reactTablePatch.RowClickableClassName,
		onClick: reactTablePatch.onClick(e => {
			// 可以访问到 state rowInfo 等变量
		})
	});

	<ReactTable
		getTrProps={trProps}
		...
		/>
```
 */
export const reactTablePatch = {
  RowClickableClassName: RowClickableClassName,
  onClick: function(clickAction) {
    return function(e, handleOriginal) {
      // there's some bugs in ReactTable.
      // when you click a modal over the table,
      // the table row click event can catch the event
      let target = e.target;
      let clickRaw = e.currentTarget;
      let match = false;
      while (target) {
        if (target === clickRaw) {
          match = true;
          break;
        }
        target = target.parentElement;
      }
      if (!match) {
        console.warn("ReactTable table row click bug");
        return;
      }
      clickAction.call(this, e, handleOriginal);

      // IMPORTANT! React-Table uses onClick internally to trigger
      // events like expanding SubComponents and pivots.
      // By default a custom 'onClick' handler will override this functionality.
      // If you want to fire the original onClick handler, call the
      // 'handleOriginal' function.
      if (handleOriginal) {
        handleOriginal();
      }
    };
  }
};

/**
 * fix ReactTable row click event bug
 *
 * @param { {event, handleOriginal, state, rowInfo, column} => void } onClickFixed
 * @param { other trProps props, refer to documents https://www.npmjs.com/package/react-table-v6#custom-props } props
 */
export default function getPatchTrProps(onClickFixed, props) {
  if (typeof onClickFixed != "function") {
    console.warn("argument onClickFixed must be function");
    return;
  }
  return (state, rowInfo, column) =>
    Object.assign(
      {
        className: RowClickableClassName,
        onClick: function(event, handleOriginal) {
          // there's some bugs in ReactTable.
          // when you click a modal over the table,
          // the table row click event can catch the event
          let target = event.target;
          let clickRaw = event.currentTarget;
          let match = false;
          while (target) {
            if (target === clickRaw) {
              match = true;
              break;
            }
            target = target.parentElement;
          }
          if (!match) {
            console.warn("ReactTable table row click bug");
            return;
          }
          // context `this` is not so important. use arrow function to capture `this` is best practice
          onClickFixed.call(this, {
            event,
            handleOriginal,
            state,
            rowInfo,
            column
          });
          // IMPORTANT! React-Table uses onClick internally to trigger
          // events like expanding SubComponents and pivots.
          // By default a custom 'onClick' handler will override this functionality.
          // If you want to fire the original onClick handler, call the
          // 'handleOriginal' function.
          if (handleOriginal) {
            handleOriginal();
          }
        }
      },
      props
    );
}

/**
 * The default FilterComponent will bind onFetchData on input's onChange event
 * Change this behavior, enter key event only trigger onFetchData
 */
export const InputEnterKeyTriggerFilterComponent = ctx => ({
  filter,
  onChange,
  column
}) => (
  <input
    type="text"
    style={{
      width: "100%"
    }}
    placeholder={column.Placeholder}
    defaultValue={filter ? filter.value : ""}
    onChange={event => {
      if (ctx.onChange) {
        ctx.onChange(event.target.value);
      }
      onChange(event.target.value);
    }}
    onKeyPress={event => {
      if (event.keyCode === 13 || event.which === 13) {
        if (ctx.onPressEnter) {
          ctx.onPressEnter(event.target.value);
        }
        onChange(event.target.value);
      }
    }}
  />
);

// Those fields in Patient table are type of text, should be filtered by keyword
const PatientTableSortTextField = [
  "patientId",
  "siteId",
  "firstName",
  "lastName",
  "doctorName",
  "phone",
  "email",
  "gender",
  "appInitialized",
  "patientIRB",
  "overallStatus",
  "sockStatus",
  "appVersion",
  "accountServiceStatus",
  "accountStatus",
  "patientHasSmartPhone",
  "patientHasWifi",
  "contactName",
  "contactPhone",
  "secondaryEmail",
  "relation",
  "deletedBy",
  "specialCase",
  "hubType",
  "siteName",
  "totalMinutesSpent",
  "sockRegistered"
];

export const DateFormat = "YYYY-MM-DD";

const Format1Reg = /^(\d{2}[-/]){0,2}\d{4}$/; // DD/MM/YYYY
const Format2Reg = /^\d{4}([-/]\d{2}){0,2}$/; // YYYY/MM/DD
// if some date is specified to be filtered, we transform it to a range
export const dateStringToRange = dateString => {
  if (Format1Reg.test(dateString)) {
    if (dateString.length === 10) {
      // DD-MM-YYYY to YYYY-MM-DD
      dateString = `${dateString.substring(6, 10)}-${dateString.substring(
        3,
        5
      )}-${dateString.substring(0, 2)}`;
    } else if (dateString.length === 7) {
      // MM-YYYY to YYYY-MM
      dateString = `${dateString.substring(3, 7)}-${dateString.substring(
        0,
        2
      )}`;
    }
  } else if (Format2Reg.test(dateString)) {
    if (dateString.length === 10) {
      // YYYY/MM/DD to YYYY-MM-DD
      dateString = `${dateString.substring(0, 4)}-${dateString.substring(
        5,
        7
      )}-${dateString.substring(8, 10)}`;
    } else if (dateString.length === 7) {
      // YYYY/MM to YYYY-MM
      dateString = `${dateString.substring(0, 4)}-${dateString.substring(
        5,
        7
      )}`;
    }
  }
  let date = moment(dateString, DateFormat);
  let endDate;
  if (dateString.length === 4) {
    endDate = date.clone().add(1, "year");
  } else if (dateString.length === 7) {
    endDate = date.clone().add(1, "month");
  } else {
    endDate = date.clone().add(1, "day");
  }
  endDate = endDate.subtract(1, "day");

  return [
    date.utcOffset(0, true).toISOString(true),
    endDate.utcOffset(0, true).toISOString(true)
  ];
};

/**
 * format ReactTable search parameters. only work on Patient Table
 * @param {*} tableInstance ReactTable instance
 * @param {(filter: Array, filterId: string, filterValue: any) => void} postIterateFilter side effect function, called dural process filter
 * @return {{filter, sort, pagination}}
 */
export function transformPatientTableParams(tableInstance, postIterateFilter) {
  const { page, pageSize, sorted, filtered } = tableInstance.state;
  const pagination = { page, size: pageSize };
  // make sort
  const [sortOption] = sorted;
  let sort;
  if (sortOption) {
    sort = {
      field: sortOption.id,
      direction: sortOption.desc ? "desc" : "asc"
    };
    if (PatientTableSortTextField.includes(sortOption.id)) {
      sort.field = `${sortOption.id}.keyword`;
    }
    if (sort.field === "lastSeen") {
      sort.field = "dataLastSeen";
    }
    if (sort.field === "dob") {
      sort.field = "birthday";
    }
    if (sort.field === "newgroup") {
      sort.field = "siteId";
    }
    if (sort.field.endsWith("ProgramStatus")) {
      sort.field = `${sort.field}.keyword`;
    }
    if (sort.field.endsWith("DeviceStatus")) {
      sort.field = `${sort.field}.keyword`;
    }
  }

  // simple filter
  const filter = filtered.reduce((result, item) => {
    // remove empty condition
    if (Array.isArray(item.value)) {
      if (item.value.length === 0) {
        return result;
      }
    } else {
      item.value = item.value.trim();
      if (!item.value) {
        return result;
      }
    }

    switch (item.id) {
      case "deviceStatus": {
        const cond = item.value.map(e => {
          const s = e.split(":");
          return {
            [`${s[0].toLowerCase()}DeviceStatus`]: {
              eq: s[1]
            }
          };
        });
        if (!result.and) {
          result.and = [];
        }
        result.and.push({
          or: cond
        });
        break;
      }
      case "programStatus": {
        const cond = item.value.map(e => {
          const s = e.split(":");
          return {
            [`${s[0].toLowerCase()}ProgramStatus`]: {
              eq: s[1]
            }
          };
        });
        if (!result.and) {
          result.and = [];
        }
        result.and.push({
          or: cond
        });
        break;
      }
      case "sirenId": {
        result[item.id] = {
          eq: parseInt(item.value)
        };
        break;
      }
      case "newgroup": {
        if (item.value === "all") {
          // NOPE
        } else {
          result["siteId"] = {
            eq: item.value
          };
        }
        break;
      }
      case "dob": {
        result["birthday"] = {
          range: dateStringToRange(item.value)
        };
        break;
      }
      case "lastSeen": {
        result["dataLastSeen"] = {
          range: dateStringToRange(item.value)
        };
        break;
      }
      case "singleFoot": {
        if (item.value === "all") {
          // NOPE
        } else if (item.value === "N/A") {
          result[item.id] = {
            exists: false
          };
        } else {
          result[item.id] = {
            eq: item.value
          };
        }
        break;
      }
      case "appLastSeen":
      case "hubLastSeen":
      case "hubDataLastSeen":
      case "birthday":
      case "deletedAt":
      case "dispensedDay":
      case "createdAt": {
        result[item.id] = {
          range: dateStringToRange(item.value)
        };
        break;
      }
      case "initialized": {
        if (item.value === "Yes") {
          result[item.id] = {
            eq: "Yes"
          };
        } else if (item.value === "No") {
          result[item.id] = {
            ne: "Yes"
          };
        }
        break;
      }
      case "appInitialized": {
        if (item.value === "Yes") {
          result[item.id] = {
            eq: "Yes"
          };
        } else if (item.value === "No") {
          result[item.id] = {
            ne: "Yes"
          };
        }
        break;
      }
      case "patientIRB": {
        if (item.value === "Yes") {
          result[item.id] = {
            eq: "Accepted"
          };
        } else if (item.value === "No") {
          result[item.id] = {
            ne: "Accepted"
          };
        }
        break;
      }
      case "overallStatus": {
        if (item.value === "all") {
          // NOPE
        } else if (item.value === "GREEN") {
          result[item.id] = {
            eq: "NORMAL"
          };
        } else {
          result[item.id] = {
            eq: item.value
          };
        }
        break;
      }
      case "accountStatus": {
        const filterAnd = result.and ? result.and : [];
        if (item.value === "all") {
          // NOPE
        } else if (item.value === "PAUSED") {
          result.and = [
            ...filterAnd,
            {
              and: [
                {
                  accountStatus: {
                    eq: "PAUSED"
                  }
                }
              ]
            }
          ];
        } else {
          // result[item.id] = {
          //   ne: "PAUSED"
          // };
          result.and = [
            ...filterAnd,
            {
              and: [
                {
                  accountStatus: {
                    ne: "PAUSED"
                  }
                },
                {
                  accountStatus: {
                    ne: "DELETED"
                  }
                }
              ]
            }
          ];
        }
        break;
      }
      case "accountServiceStatus": {
        if (item.value === "all") {
          // NOPE
        } else if (item.value === "NOT_ON_SERVICE") {
          result[item.id] = {
            eq: "NOT_ON_SERVICE"
          };
        } else {
          result[item.id] = {
            eq: "ON_SERVICE"
          };
        }
        break;
      }
      case "sockStatus":
      case "sockRegistered":
      case "patientHasSmartPhone":
      case "patientHasWifi":
      case "relation": {
        if (item.value === "all") {
          // NOPE
        } else {
          result[item.id] = {
            eq: item.value
          };
        }
        break;
      }
      case "hubType": {
        if (item.value === "all") {
          // NOPE
        } else {
          result[item.id] = {
            wildcard: `*${item.value.toLowerCase()}*`
          };
        }
        break;
      }
      case "specialCase": {
        if (item.value === "all") {
          // NOPE
        } else if (item.value === "Yes") {
          result[item.id] = {
            eq: "Yes"
          };
        } else if (item.value === "No") {
          result[item.id] = {
            eq: "No"
          };
        } else {
          result[item.id] = {
            exists: false
          };
        }
        break;
      }
      case "siteName":
      case "doctorName":
      case "contactName":
      case "contactPhone":
      case "secondaryEmail":
      case "patientId":
      case "phone":
      case "email":
      case "lastName":
      case "firstName": {
        result[item.id] = {
          wildcard: `*${item.value.toLowerCase()}*`
        };
        break;
      }
      default:
        result[item.id] = {
          eq: item.value
        };
        break;
    }
    if (typeof postIterateFilter === "function") {
      postIterateFilter(result, item.id, item.value);
    }
    return result;
  }, {});

  return { filter, sort, pagination };
}

export function transformBoxTableParams(tableInstance, postIterateFilter) {
  const { page, pageSize, sorted, filtered } = tableInstance.state;
  const pagination = { page, size: pageSize };
  // make sort
  const [sortOption] = sorted;
  let sort;
  if (sortOption) {
    sort = {
      field: sortOption.id,
      direction: sortOption.desc ? "desc" : "asc"
    };
  }

  // simple filter
  const filter = filtered.reduce((result, item) => {
    switch (item.id) {
      case "id": {
        result[item.id] = {
          wildcard: `*${item.value.toLowerCase()}*`
        };
        break;
      }
      case "socks": {
        // if input is mac address likely, remove colon
        const sn = item.value
          .split(":")
          .join("")
          .toLowerCase();
        result[item.id] = {
          wildcard: `021111*${sn}`
        };
        break;
      }
      case "registered":
      case "size":
      case "color": {
        if (item.value === "all") {
          // NOPE
        } else {
          result[item.id] = {
            eq: item.value
          };
        }
        break;
      }
      case "createdAt":
      case "registeredAt": {
        result[item.id] = {
          range: dateStringToRange(item.value)
        };
        break;
      }
      case "canRegistered": {
        if (item.value === "all") {
          // NOPE
        } else if (item.value === "No") {
          result[item.id] = {
            eq: item.value
          };
        } else {
          result[item.id] = {
            ne: "No"
          };
        }
        break;
      }
      default:
        result[item.id] = {
          eq: item.value
        };
        break;
    }
    if (typeof postIterateFilter === "function") {
      postIterateFilter(result, item.id, item.value);
    }
    return result;
  }, {});

  return { filter, sort, pagination };
}

export function transformHubTableParams(tableInstance, postIterateFilter) {
  const { page, pageSize, sorted, filtered } = tableInstance.state;
  const pagination = { page, size: pageSize };
  // make sort
  const [sortOption] = sorted;
  let sort;
  if (sortOption) {
    sort = {
      field: sortOption.id,
      direction: sortOption.desc ? "desc" : "asc"
    };
  }

  // simple filter
  const filter = filtered.reduce((result, item) => {
    switch (item.id) {
      case "registered": {
        if (item.value === "all") {
          // NOPE
        } else if (item.value === "N/A") {
          result[item.id] = {
            exists: false
          };
        } else {
          result[item.id] = {
            eq: item.value
          };
        }
        break;
      }
      case "canRegistered": {
        if (item.value === "all") {
          // NOPE
        } else if (item.value === "No") {
          result[item.id] = {
            eq: item.value
          };
        } else {
          result[item.id] = {
            ne: "No"
          };
        }
        break;
      }
      case "hubType": {
        if (item.value === "4G") {
          result.hubId = {
            wildcard: "H4G*"
          };
        }
        break;
      }
      case "expireDate": {
        // remove hyphon
        const fmtDate = item.value.split("-").join("");
        result[item.id] = {
          wildcard: `${fmtDate}*`
        };
        break;
      }
      case "createdAt":
      case "registeredAt": {
        result[item.id] = {
          range: dateStringToRange(item.value)
        };
        break;
      }
      default:
        result[item.id] = {
          eq: item.value
        };
        break;
    }
    if (typeof postIterateFilter === "function") {
      postIterateFilter(result, item.id, item.value);
    }
    return result;
  }, {});

  return { filter, sort, pagination };
}
