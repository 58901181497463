import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { getDeleteAdminStaff } from "../../action/registerAction";
import ButtonSuccess from "../../home/buttonSuccess";
import "../../share/button.css";
import "./DeleteSite.css";
import { Auth } from "aws-amplify";
import modalInfo from "../../tools/module-info";
import { Modal as AntdModal } from "antd";
import { Control } from "react-keeper";
const { confirm } = AntdModal;
class DeleteAdminStaff extends Component {
  constructor(props) {
    super(props);
    this.handleConfirm.bind(this);
  }
  state = { open: false, confirmSuccess: false };

  gotoDoctorDetailPage = id => {
    Control.go(`/LpnPatient?id=${id}`);
  };

  showConfirm = () => {
    confirm({
      title: "Oops",
      content: `There are ${this.props.patientCount} patients under ${this.props.email}, so you must unbind them before delete this LPN. Are you sure you want to delete it?`,
      cancelText: "No",
      onCancel: () => {},
      okText: "Yes",
      onOk: () => {
        this.gotoDoctorDetailPage(this.props.email);
      },
      centered: true
    });
  };

  show = () => {
    if (this.props.patientCount > 0) {
      this.showConfirm();
    } else {
      this.setState({ open: true, confirmSuccess: false });
    }
  };
  handleConfirm = async () => {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    if (groups.includes("admin")) {
      const { getDeleteAdminStaff } = this.props;
      const email = this.props.email;
      getDeleteAdminStaff(email, this);
    } else {
      modalInfo.warning(
        "Delete Admin User",
        "You have no access to delete user, please contact the super administrator."
      );
    }
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });
  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini" className="deleteButton">
            Delete
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <p>Are you sure you want to permanently delete this user?</p>
              <div>
                <p className="cofirmSiteId">
                  Account:
                  <strong className="cofirmBold">{this.props.email}</strong>
                </p>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  listAdminStaff: state.patientsListStore.listAdminStaff
});
const mapDispatchToProp = dispatch => ({
  getDeleteAdminStaff: (email, self) =>
    dispatch(getDeleteAdminStaff(email, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(DeleteAdminStaff);
