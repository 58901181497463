import React, { Component } from "react";
import { Button, Form, Icon, Modal, Popup, TextArea } from "semantic-ui-react";
import "../share/button.css";
import "../home/AccountStatus.css";
import ButtonSuccess from "../home/buttonSuccess";
import { changeHubStatus } from "../action/boxAction";

export default class HubStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      canRegisteredReason: ""
    };
  }
  show = () =>
    this.setState({
      open: true,
      confirmSuccess: false,
      canRegistered: this.props.canRegistered
    });
  handleConfirm = async () => {
    this.setState({ confirmSuccess: true });

    const input = {
      hubId: this.props.hubId,
      canRegistered: this.state.canRegistered === "Yes" ? "No" : "Yes",
      canRegisteredReason: this.state.canRegisteredReason
    };
    changeHubStatus(input).then(() => {
      this.setState({ open: false, confirmSuccess: false });
      if (typeof this.props.onChange === "function") {
        this.props.onChange(input);
      }
    });
  };

  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  handleInputChange(value) {
    this.setState({ canRegisteredReason: value });
  }

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <div style={{ display: "flex" }}>
            {this.props.canRegistered === "Yes" ? (
              this.props.canRegistered
            ) : (
              <Popup
                trigger={
                  <p className="sr-text-ellipsis">{this.props.canRegistered}</p>
                }
              >
                <p>
                  <strong>Reason:</strong> {this.props.canRegisteredReason}
                </p>
              </Popup>
            )}
            {/*{this.props.canRegistered}*/}
            <Icon as="i" name="edit" className="edit" />
          </div>
        }
        onClose={this.handleCancel}
        onOpen={this.show}
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>
          {this.props.canRegistered === "Yes"
            ? "Hub Can Not Registered"
            : "Hub Can Registered"}
        </Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <TextArea
                    className="note_comments"
                    onChange={(e, data) => {
                      this.handleInputChange(e.target.value);
                    }}
                    placeholder="Reason for change to hub status"
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
