import { Col, Divider, Form, Input, Modal, Row, Select } from "antd";
import React from 'react';
import { connect } from "react-redux";
import {
	getlistClinicSites,
	getlistStaff
} from "../../../action/registerAction";
import NewAccountStep from "./NewAccountStep";

const { Option } = Select

class NewAccountStep1 extends NewAccountStep {

	static Name = 'Enrollment'

	state = {
		site: this.props.site,
		siteStatus: 'success',
		doctorId: this.props.doctorId,
		doctorStatus: 'success',
		hasPhone: this.props.hasPhone,
		hasPhoneStatus: null,
	}

	componentDidMount() {
		super.componentDidMount()
		if (this.props.clinicSites.length === 0) {
			this.setState({
				siteStatus: 'validating'
			})
			this.props.getlistClinicSites().then(() => {
				this.autoFillSiteAndDoctor()
			})
		} else {
			this.autoFillSiteAndDoctor()
		}
		if (this.props.listStaff.length === 0) {
			this.setState({
				doctorStatus: 'validating'
			})
			this.props.getlistStaff().then(() => {
				this.autoFillSiteAndDoctor()
			})
		} else {
			this.autoFillSiteAndDoctor()
		}
	}

	autoFillSiteAndDoctor() {
		if (!this.state.site && this.props.clinicSites.length > 0) {
			this.setState({
				site: this.props.clinicSites[0],
				siteStatus: 'success'
			})
		}
		if (!this.state.doctorId && this.props.listStaff.length > 0 && this.props.clinicSites.length > 0) {
			const site = this.props.clinicSites[0]
			const tmp = this.props.listStaff.filter(item => item.isPrescribing && item.siteId.split("-").includes(site.siteId))
			if (tmp.length > 0) {
				this.setState({
					doctorId: tmp[0].email,
					doctorStatus: 'success'
				})
			}
		}
	}

	validateForm() {
		let pass = true
		if (!this.state.site) {
			this.setState({
				siteStatus: 'error'
			})
			pass = false
		}
		const hubContract = this.state.site.hubContract || "Wifi";
		if (this.state.hasPhone === 'No' && hubContract !== '4G/Wifi') {
			pass = false
			this.makePhoneWarning()
		}
		return pass
	}

	submitForm() {
		if (typeof this.props.onSubmitForm === 'function') {
			const copyState = {...this.state}
			delete copyState.siteStatus
			delete copyState.doctorStatus
			delete copyState.hasPhoneStatus
			const doctor = this.props.listStaff.find(staff => staff.email === copyState.doctorId)
			copyState.doctorName = `${doctor.firstName} ${doctor.lastName}`

			this.props.onSubmitForm(this.constructor.Name, copyState)
		}
	}

	onSelectSite = (siteId) => {
		this.setState({
			site: this.props.clinicSites.find(s => s.siteId === siteId),
			siteStatus: 'success'
		}, () => {
			this.makePhoneWarning()
		})
		// auto fill doctor
		const tmp = this.props.listStaff.filter(item => item.isPrescribing && item.siteId.split("-").includes(siteId))
		if (tmp.length > 0) {
			this.setState({
				doctorId: tmp[0].email,
				doctorStatus: 'success'
			})
		}
	}

	makePhoneWarning = () => {
		this.setState({
			hasPhoneStatus: null
		})
		const hubContract = this.state.site.hubContract || "Wifi";
		if (this.state.hasPhone === 'No' && hubContract !== '4G/Wifi') {
			this.setState({
				hasPhoneStatus: 'error'
			})
			Modal.warning({
				title: 'Smartphone Required',
				content: 'Patient and/or caregiver must have a smartphone to upload sock data and photos for their doctor to review.'
			})
		}
	}

	render() {
		return (
			<div style={{width: 600, margin: 'auto', marginTop: 40}}>
				<Form>
					<div className='form-section'>
						<Divider orientation="left">Enrollment Criteria</Divider>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Form.Item
									label="Site Name"
									required
									hasFeedback
									validateStatus={this.state.siteStatus}
									help={this.state.siteStatus === 'error' ? 'Please select site' : '' }
									className='form-item'
								>
									<Select
										size='large'
										onChange={this.onSelectSite}
										value={this.state.site && this.state.site.siteId}
										showSearch
										optionFilterProp="children"
									>
										{this.props.clinicSites.map(site => (
											<Option value={site.siteId} key={site.siteId}>
												{`${site.siteId} - ${site.name}`}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label="Staffing"
									required
									className='form-item'
								>
									<Input
										size='large'
										disabled
										value={this.state.site ? (this.state.site.onStaffing === 'Yes' ? 'On Staffing' : 'Not On Staffing') : ''}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label='Doctor'
									required
									hasFeedback
									validateStatus={this.state.doctorStatus}
									help={this.state.doctorStatus === 'error' ? 'Please select doctor' : '' }
									className='form-item'
								>
									<Select
										size='large'
										value={this.state.doctorId}
										onChange={value => {
											this.setState({
												doctorId: value,
												doctorStatus: 'success'
											})
										}}
										showSearch
										optionFilterProp="children"
									>
										{this.props.listStaff
											.filter(item => this.state.site ? (item.isPrescribing && item.siteId.split("-").includes(this.state.site.siteId)) : false)
											.map(doctor => (
												<Option value={doctor.email} key={doctor.email}>
													{doctor.isPrescribing && !doctor.firstName.includes("Dr.")
														? "Dr. " + doctor.firstName + " " + doctor.lastName
														: doctor.firstName + " " + doctor.lastName}
												</Option>
											))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</div>
					<div className='form-section'>
						<Divider orientation="left">General Questions</Divider>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Form.Item
									label="Does the patient have a smartphone?"
									className='form-item'
									validateStatus={this.state.hasPhoneStatus}
								>
									<Select
										size='large'
										onChange={value => {
											this.setState({hasPhone: value}, () => {
												this.makePhoneWarning()
											})
										}}
										value={this.state.hasPhone}
									>
										<Option value={"Yes"}>Yes</Option>
										<Option value={"Caregiver"}>Caregiver has a smartphone</Option>
										<Option value={"No"}>No</Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</div>
				</Form>
			</div>
		)
	}

}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
	listStaff: state.patientsListStore.listStaff
})

const mapDispatchToProp = dispatch => ({
	getlistClinicSites: () => dispatch(getlistClinicSites()),
	getlistStaff: () => dispatch(getlistStaff())
})

export default connect(mapStateToProp, mapDispatchToProp)(NewAccountStep1)
