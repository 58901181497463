import * as TYPES from "../types/types";

const initialState = {
  reportList: {},
  reportManualList: {},
  doctorReportHistory: []
};

export default function reportListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.DOCTOR_REPORT_LIST:
      return {
        ...state,
        reportList: action.text
      };
    case TYPES.REPORT_MANUAL_LIST:
      return {
        ...state,
        reportManualList: action.text
      };
    case TYPES.RESET_ALL_REPORT:
      return {
        ...state,
        reportManualList: {}
      };
    case TYPES.DOCTOR_REPORT_HISTORT:
      return {
        ...state,
        doctorReportHistory: action.text
      };
    default:
      return state;
  }
}
