import { Modal as AntdModal, Select } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Button, Popup } from "semantic-ui-react";
import {
  changeStaffStatus,
  getDeletedListStaff
} from "../action/registerAction";
import DeleteStaff from "./DeleteStaff";
import "../SupportDashboard/account/SiteList.css";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import Modal from "react-modal";

const { Option } = Select;
const { confirm } = AntdModal;

class DeletedStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
  }
  async componentDidMount() {
    this.setState({
      modalIsOpen: true
    });
    this.props.getlistStaff().finally(() => {
      this.setState({ modalIsOpen: false });
    });
  }
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleReInstateStaff = email => {
    const input = {
      email,
      status: "REINSTATE"
    };
    this.props.changeStaffStatus(input, this);
  };

  confirmReInstate = (email, fullName) => {
    confirm({
      title: "Confirming",
      content: (
        <div>
          Are you sure you want to reinstate the staff{" "}
          <strong>{fullName}</strong>?
        </div>
      ),
      cancelText: "No",
      onCancel: () => {},
      okText: "Yes",
      onOk: () => {
        this.handleReInstateStaff(email);
      },
      centered: true
    });
  };

  render() {
    const isAdmin = this.props.userGroups.includes("admin");
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <ReactTable
          data={
            this.props.deletedListStaff &&
            this.props.deletedListStaff.map(p => {
              const fullName =
                p.isPrescribing && !p.firstName.includes("Dr.")
                  ? "Dr. " + p.firstName + " " + p.lastName
                  : p.firstName + " " + p.lastName;
              const isPrescribing =
                p.isPrescribing === true
                  ? "Yes"
                  : p.isPrescribing === false
                  ? "No"
                  : "";
              const notificationEmail = p.notificationEmail
                ? p.notificationEmail
                : p.email;
              const isAdministrator =
                p.isAdministrator && p.isAdministrator === "Yes" ? "Yes" : "No";
              const lastLogin = p.lastLogin
                ? moment(p.lastLogin).format("MM/DD/YYYY HH:mm:ss")
                : "";
              const deletedOn = p.deletedAt
                ? moment(p.deletedAt).format("MM/DD/YYYY/HH:mm:ss")
                : "";
              return {
                ...p,
                fullName,
                notificationEmail,
                isPrescribing,
                isAdministrator,
                lastLogin,
                deletedOn
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) => {
            if (!row[filter.id]) {
              return false;
            }
            return row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          }}
          columns={[
            {
              Header: "Site Id",
              accessor: "siteId",
              Cell: props => (
                <Popup trigger={<p className="sr-text-ellipsis">{props.value}</p>}>
                  {props.value.split("-").map((name, index) => (
                    <p key={index}>{name}</p>
                  ))}
                </Popup>
              )
            },
            {
              Header: "User Name",
              accessor: "email"
            },
            {
              Header: "Notification Email",
              accessor: "notificationEmail"
            },
            {
              Header: "Full Name",
              accessor: "fullName",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.fullName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Prescribing Doctor",
              accessor: "isPrescribing",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Yes") {
                  return row[filter.id].includes("Yes");
                }
                if (filter.value === "No") {
                  return row[filter.id] === "No";
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "NPI",
              accessor: "doctorNPI",
              sortable: false
            },
            {
              Header: "Role",
              accessor: "doctorRole",
              sortable: false,
              filterable: false
            },
            {
              Header: "Administrator",
              accessor: "isAdministrator",
              sortable: false
            },
            {
              Header: "Deleted On",
              accessor: "deletedOn"
            },
            {
              Header: "Deleted By",
              accessor: "deletedBy"
            },
            {
              Header: "Actions",
              accessor: "",
              sortable: false,
              minWidth: 250,
              filterable: false,
              show: isAdmin,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <DeleteStaff
                      email={props.original.email}
                      givenName={props.original.fullName}
                    />
                    <Button
                      size="mini"
                      onClick={() => {
                        this.confirmReInstate(
                          props.original.email,
                          props.original.fullName
                        );
                      }}
                    >
                      ReInstate
                    </Button>
                  </div>
                );
              }
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "siteId",
              desc: false
            },
            {
              id: "email",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  listStaff: state.patientsListStore.listStaff,
  deletedListStaff: state.patientsListStore.deletedListStaff,
  userGroups: state.authGroupStore.session.userGroups
});
const mapDispatchToProp = dispatch => ({
  getlistStaff: () => dispatch(getDeletedListStaff()),
  changeStaffStatus: (input, self) => dispatch(changeStaffStatus(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(DeletedStaff);
