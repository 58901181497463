import * as TYPES from "../types/types";
const initialState = {
  userGroup: "",
  profile: {},
  session: {
    userGroups: [],
    favorites: [],
    emailVerified: null
  }
};

export default function authGroup(state = initialState, action) {
  switch (action.type) {
    case TYPES.AUTH_GROUP:
      return {
        ...state,
        userGroup: action.text
      };
    case TYPES.USER_PROFILE:
      return {
        ...state,
        profile: action.profile
      }
    case TYPES.TEMP_UNIT:
      const next = {...state.profile, tempUnit: action.text}
      return {
        ...state,
        profile: next
      };
    case TYPES.USER_SESSION:
      return {
        ...state,
        session: action.session
      }
    default:
      return state;
  }
}
