import {
  Button as AntdButton,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Modal as AntdModal,
  Select
} from "antd";
import _, { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-tabs/style/react-tabs.css";
import { Button, Icon, Modal } from "semantic-ui-react";
import {
  deleteMonitoringTracker,
  update99457Service
} from "../action/ServiceAction";
import SpecialCasePop from "./specialCasePop";
import DividLine from "./dividLine";
import PatientMedicalHistory from "./patientMedicalHistory";
import FootTreatment from "./footTreatment";
import FootCondition from "./footCondition";
import FootSymptom from "./footSymptom";
import { changeSpecialCaseStatus } from "../action/addAction";
import { getCompiledRpmNoteTemplate } from "../graphql/API";
import {
  changeDoctorDetails,
  changeDoctorDetailsBK,
  changeDMTModalStatus
} from "../action/patientAction";
import {
  ServiceOptionList,
  ServiceOptionList_PCM
} from "../tools/patient-medical";
import PatientICDCode from "./patientICDCode";
import { removeAllSpace } from "../tools/stringProcess";

const dateFormat = "ddd, Do MMM YYYY";
const { Option, OptGroup } = Select;
const { confirm } = AntdModal;
const programOptions = ["CCM", "PCM", "RPM", "RTM"];
const cptCodes = [
  "99490 & 99439",
  "99426 & 99427",
  "99457 & 99458",
  "98980 & 98981"
];
const program2CPT = {
  RPM: "99457 & 99458",
  RTM: "98980 & 98981",
  PCM: "99426 & 99427",
  CCM: "99490 & 99439"
};
const defaultNote = {
  rpmKey: _.concat(
    moment().format("YYYY-MM-DDTHH:mm:ss"),
    ".",
    (Math.random() * 1000).toFixed(0),
    "Z"
  ).join(""),
  program: undefined,
  cptCode: undefined,
  doctorTime: 0,
  nurseTime: 0,
  spendTime: 0,
  description: ""
};
class Edit99457Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      canEdit: false,
      serviceDate: moment().format("YYYY-MM-DD"),
      spentTime: 0,
      description: "",
      doctorId: "",
      doctorName: "",
      doctorTime: 0,
      nurseTime: 0,
      specialCaseStatus: "",
      specialCaseReason: "",
      symptomData: [],
      conditionData: [],
      treatmentData: [],
      submitting: false,
      medicalCondition: [],
      footSpecific: [],
      footAmputation: [],
      footConditionStatus: "Yes",
      footTreatmentStatus: "Yes",
      loadings: [],
      programNotes: [],
      diagnosisCodes: [],
      programs: []
    };
  }

  handleSpecialCaseChange = value => {
    this.setState({
      specialCaseStatus: value,
      specialCaseReason: value === "Yes" ? [] : "Not a special case"
    });
  };

  handleSpecialCaseReasonChange = value => {
    this.setState({ specialCaseReason: value });
  };

  updateSpecialCase = () => {
    if (!this.state.specialCaseStatus) {
      return;
    }
    let reason = this.state.specialCaseReason;
    if (Array.isArray(reason)) {
      reason = reason.join(",");
    }
    if (
      _.isEmpty(this.state.specialCaseStatus) ||
      (this.state.specialCaseStatus === "No" &&
        this.props.patientDetails.specialCase ===
          this.state.specialCaseStatus) ||
      (this.state.specialCaseStatus === "Yes" && _.isEmpty(reason)) ||
      (this.props.patientDetails.specialCase === this.state.specialCaseStatus &&
        this.props.patientDetails.specialCaseReason === reason)
    ) {
      return;
    }
    const { profile } = this.props;
    let doctorName = "Siren Doctor";
    if (profile.firstName && profile.lastName) {
      doctorName = `${profile.firstName} ${profile.lastName}`;
    }

    const newStatus = {
      id: this.props.patientDetails.id,
      userId: this.props.patientDetails.userId,
      specialCase: this.state.specialCaseStatus,
      specialCaseReason: reason,
      note: reason,
      author: doctorName
    };
    if (this.state.specialCaseStatus === "Yes") {
      newStatus.activeSpecialCaseBy = profile.loginId;
      newStatus.title = "Special Case: Yes";
    } else {
      newStatus.pausedSpecialCaseBy = profile.loginId;
      newStatus.title = "Special Case: No";
    }
    changeSpecialCaseStatus(newStatus);
  };

  checkService = (program, rpmService) => {
    const service = rpmService.split(",");
    const rpm = ["A", "B", "C", "D", "E"];
    const pcm = ["F", "G", "H", "I"];
    return (
      (program !== "PCM" && _.intersection(service, rpm).length > 0) ||
      (program === "PCM" && _.intersection(service, pcm).length > 0)
    );
  };

  submit = () => {
    const { update99457Service } = this.props;
    if (_.isEmpty(this.props.patientDetails.userId)) {
      this.errorModal("Can not get user information.");
      return;
    }
    if (this.state.specialCaseStatus === "Yes") {
      let checkFailed = false;
      if (Array.isArray(this.state.specialCaseReason)) {
        if (this.state.specialCaseReason.length === 0) {
          checkFailed = true;
        }
      } else if (!this.state.specialCaseReason) {
        checkFailed = true;
      }
      if (checkFailed) {
        this.errorModal("Please select special case reason.");
        return;
      }
    }
    const programNotes = _.compact(
      this.state.programNotes.map(item => {
        if (
          item.program &&
          !isEmpty(item.description) &&
          !isEmpty(item.rpmService) &&
          this.checkService(item.program, item.rpmService)
        ) {
          return item;
        }
        return null;
      })
    );
    if (programNotes.length < this.state.programNotes.length) {
      this.errorModal("Program, Service and Details are required.");
      return;
    }
    let input = {
      userId: this.props.patientDetails.userId,
      siteId: this.props.patientDetails.siteId,
      serviceDate: moment(this.props.serviceInfo.serviceDate).format(
        "YYYY-MM-DD"
      ),
      doctorId: this.state.doctorId,
      doctorName: this.state.doctorName,
      programTime: this.props.serviceInfo.programTime,
      currentMonth: moment().format("YYYY-MM"),
      programNotes
    };
    if (
      this.state.serviceDate !==
      this.props.serviceInfo.programTime.substr(0, 10)
    ) {
      Object.assign(input, {
        newServiceDate: moment(this.state.serviceDate).format("YYYY-MM-DD")
      });
    }
    if (this.state.symptomData.length > 0) {
      input.symptoms = this.state.symptomData.map(item => ({
        ...item,
        userId: this.props.patientDetails.userId
      }));
    }
    if (this.state.diagnosisCodes.length > 0) {
      Object.assign(input, {
        diagnosisCodes: this.state.diagnosisCodes
          .map(p => {
            return p;
          })
          .join("_")
      });
    }
    if (
      this.state.canEdit &&
      (this.state.conditionData.length > 0 ||
        this.state.footConditionStatus === "No")
    ) {
      Object.assign(input, { conditions: this.state.conditionData });
    }
    if (
      this.state.canEdit &&
      (this.state.treatmentData.length > 0 ||
        this.state.footTreatmentStatus === "No")
    ) {
      Object.assign(input, { treatments: this.state.treatmentData });
    }
    if (this.state.canEdit && this.state.medicalCondition) {
      Object.assign(input, {
        medicalCondition: this.state.medicalCondition.join(",")
      });
    }
    if (this.state.canEdit && this.state.footSpecific) {
      Object.assign(input, {
        footSpecific: this.state.footSpecific.join(",")
      });
    }
    if (this.state.canEdit && this.state.footAmputation) {
      Object.assign(input, {
        footAmputation: this.state.footAmputation.join(",")
      });
    }
    this.updateSpecialCase();
    update99457Service(input, this).then(() => {
      document.dispatchEvent(new Event("refreshMedicalDetail"));
    });
  };

  handleOpen = () => {
    this.props.isEdit(true);
    let canEdit = false;
    // const maxServiceInfo = _.maxBy(this.props.npmService, "rpmKey");
    if (_.isEmpty(this.props.patientDetails)) {
      AntdModal.warning({
        title: "Oops",
        content: "There is no user information, please check your network.",
        centered: true
      });
      return;
    }
    this.props.changeDMTModalStatus("open");
    const programs =
      this.props.patientDetails &&
      this.props.patientDetails.program &&
      this.props.patientDetails.program.map((p, index) => {
        return (
          this.props.patientDetails[`${p.toLowerCase()}ProgramStatus`] ===
            "ON_SERVICE" && p
        );
      });
    const allServiceInfo = _(this.props.npmService)
      .sortBy(["programTime", "createdAt"])
      .value();
    const maxServiceInfo =
      allServiceInfo.length > 0 && allServiceInfo[allServiceInfo.length - 1];
    if (
      moment(this.props.serviceInfo.serviceDate).format("YYYY-MM") ===
        moment().format("YYYY-MM") &&
      maxServiceInfo.programTime === this.props.serviceInfo.programTime
    ) {
      canEdit = true;
    }
    let doctorId = this.props.serviceInfo.doctorId;
    let doctorName = this.props.serviceInfo.doctorName;
    let serviceDate = moment(this.props.serviceInfo.serviceDate).format(
      "YYYY-MM-DD"
    );
    let spentTime = this.props.serviceInfo.spentTime.split(" ")[0];
    let description = this.props.serviceInfo.description;

    this.setState({
      open: true,
      canEdit,
      confirmSuccess: false,
      doctorName,
      doctorId,
      spentTime,
      serviceDate,
      description,
      programs: _.compact(programs).sort(),
      symptomData: this.props.serviceInfo.symptoms
        ? this.props.serviceInfo.symptoms
        : [],
      doctorTime: this.props.serviceInfo.doctorTime,
      nurseTime: this.props.serviceInfo.nurseTime,
      specialCaseStatus: this.props.patientDetails.specialCase
        ? this.props.patientDetails.specialCase
        : undefined,
      specialCaseReason: this.props.patientDetails.specialCaseReason
        ? this.props.patientDetails.specialCaseReason.split(",")
        : [],
      medicalCondition: this.props.patientDetails.medicalCondition
        ? this.props.patientDetails.medicalCondition.split(",")
        : [],
      footSpecific: this.props.serviceInfo.footSpecific
        ? this.props.serviceInfo.footSpecific.split(",")
        : [],
      footAmputation: this.props.serviceInfo.footAmputation
        ? this.props.serviceInfo.footAmputation.split(",")
        : [],
      conditionData: this.props.serviceInfo.footConditions
        ? this.props.serviceInfo.footConditions
        : [],
      treatmentData: this.props.serviceInfo.footTreatments
        ? this.props.serviceInfo.footTreatments
        : [],
      programNotes: this.props.serviceInfo.programNotes.map(item => {
        return {
          ...item,
          cptCode: item.cptCode ? item.cptCode : program2CPT[item.program]
        };
      }),
      diagnosisCodes: this.props.patientDetails.diagnosisCodes
        ? this.props.patientDetails.diagnosisCodes.split("_")
        : []
    });
  };

  close = () => {
    this.props.changeDMTModalStatus("close");
    this.props.isEdit(false);
    this.setState({
      open: false,
      confirmSuccess: false,
      canEdit: false,
      serviceDate: moment().format("YYYY-MM-DD"),
      spentTime: 0,
      description: "",
      doctorId: "",
      doctorName: "",
      doctorTime: 0,
      nurseTime: 0,
      specialCaseStatus: "",
      specialCaseReason: "",
      symptomData: [],
      conditionData: [],
      treatmentData: [],
      submitting: false,
      medicalCondition: [],
      footSpecific: [],
      footAmputation: [],
      footConditionStatus: "Yes",
      footTreatmentStatus: "Yes",
      programNotes: []
    });
  };

  onDateChanged = (date, dateString) => {
    this.setState({ serviceDate: moment(date).format("YYYY-MM-DD") });
  };

  onSpentTimeChanged = value => {
    this.setState({ spentTime: value });
  };

  updateNoteList = (newValue, index) => {
    const programNotes = this.state.programNotes.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          ...newValue
        };
      }
      return item;
    });
    this.setState({ programNotes });
  };

  onDoctorTimeChanged = (value, index) => {
    this.updateNoteList({ doctorTime: value && parseInt(value) }, index);
  };

  handleProgramChange = (value, index) => {
    this.updateNoteList(
      { program: value, cptCode: program2CPT[value], rpmService: "" },
      index
    );
  };

  onNurseTimeChanged = (value, index) => {
    this.updateNoteList({ nurseTime: value && parseInt(value) }, index);
  };

  onDescriptionChanged = (event, index) => {
    this.updateNoteList({ description: event.target.value }, index);
  };

  onServiceChanged = (event, index) => {
    this.updateNoteList({ rpmService: event.join(",") }, index);
  };

  handleSelectTmpl = value => {
    this.setState({
      compiling: true
    });
    getCompiledRpmNoteTemplate(value, this.props.serviceInfo.userId)
      .then(text => {
        this.setState({
          description: text
        });
      })
      .catch(err => {
        AntdModal.info({
          title: "compile template error",
          content: JSON.stringify(err)
        });
      })
      .finally(() => {
        this.setState({
          compiling: false
        });
      });
  };

  errorModal = content => {
    AntdModal.info({
      title: "Incomplete Fields",
      content,
      centered: true
    });
  };

  renderService = service => {
    return service.map((v, i) => (
      <div style={{ marginTop: 10 }} key={i}>
        <Checkbox value={v.option} style={{ width: "100%" }}>
          <strong
            style={{ paddingLeft: 4, paddingRight: 4, display: "inline-block" }}
          >
            {v.option}.
          </strong>
          <span
            style={{
              display: "inline-block",
              width: "calc(100% - 60px)",
              verticalAlign: "top"
            }}
          >
            {v.brief} <strong>{v.memo}</strong>
          </span>
        </Checkbox>
      </div>
    ));
  };

  renderDescSection(item, i) {
    return (
      <div style={{ marginTop: 15 }}>
        <div>
          <Select
            style={{
              marginTop: 8,
              marginBottom: 8,
              width: 300,
              display: "none"
            }}
            placeholder="select a template"
            loading={this.state.compiling}
            onChange={this.handleSelectTmpl}
          >
            {this.props.rpmNoteTmpls.map(item => {
              if (item.children) {
                return (
                  <OptGroup label={item.title} key={item.id}>
                    {item.children.map(subItem => (
                      <Option value={subItem.id} key={subItem.id}>
                        {subItem.title}
                      </Option>
                    ))}
                  </OptGroup>
                );
              } else {
                return (
                  <Select.Option value={item.id} key={item.id}>
                    {item.title}
                  </Select.Option>
                );
              }
            })}
          </Select>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <div style={{ display: "flex" }}>
              <strong>Program</strong> <p style={{ color: "red" }}>*</p>
            </div>
            <Select
              value={item.program}
              onChange={value => this.handleProgramChange(value, i)}
              style={{ width: 200, marginTop: 10, marginBottom: 15 }}
              placeholder={"Select a program"}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
            >
              {this.props.patientDetails &&
                this.props.patientDetails.program &&
                this.props.patientDetails.program.map((p, index) => {
                  return (
                    (this.props.patientDetails[
                      `${p.toLowerCase()}ProgramStatus`
                    ] === "ON_SERVICE" ||
                      (this.props.patientDetails.siteId.startsWith("0203") &&
                        this.props.patientDetails[
                          `${p.toLowerCase()}ProgramStatus`
                        ] === "NOT_ON_SERVICE")) && (
                      <Option value={p} key={index}>
                        {p}
                      </Option>
                    )
                  );
                })}
            </Select>
          </div>
          <div style={{ marginLeft: 20 }}>
            <div style={{ display: "flex" }}>
              <strong>CPT Codes</strong> <p style={{ color: "red" }}>*</p>
            </div>
            <Select
              value={item.cptCode}
              style={{ width: 200, marginTop: 10, marginBottom: 15 }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
              disabled
            >
              {cptCodes.map((p, index) => {
                return (
                  <Option value={p} key={index}>
                    {p}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <div>
              <strong>Doctor Time</strong>
            </div>
            <InputNumber
              min={0}
              value={item.doctorTime}
              onChange={value => this.onDoctorTimeChanged(value, i)}
              style={{ width: 150, marginTop: 5 }}
              type={"number"}
              disabled={
                item.program &&
                this.props.patientDetails[
                  `${item.program.toLowerCase()}ProgramStatus`
                ] === "NOT_ON_SERVICE"
              }
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <div>
              <strong>Nurse Time</strong>
            </div>
            <InputNumber
              min={0}
              value={item.nurseTime}
              onChange={value => this.onNurseTimeChanged(value, i)}
              style={{ width: 150, marginTop: 5 }}
              type={"number"}
              disabled={
                item.program &&
                this.props.patientDetails[
                  `${item.program.toLowerCase()}ProgramStatus`
                ] === "NOT_ON_SERVICE"
              }
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <div>
              <strong>Total Time (mins)</strong>
            </div>
            <InputNumber
              min={0}
              value={item.doctorTime + item.nurseTime}
              style={{ width: 150, marginTop: 5 }}
              type={"number"}
              disabled
            />
          </div>
        </div>
        <div style={{ height: 40 }} />
        <strong>
          {item.program === "PCM"
            ? "Select the Principal Care Management (PCM) "
            : "Select the remote monitoring treatment management "}
          services provided:
        </strong>
        <Checkbox.Group
          onChange={event => this.onServiceChanged(event, i)}
          value={
            !isEmpty(item.rpmService)
              ? removeAllSpace(item.rpmService).split(",")
              : null
          }
        >
          {item.program === "PCM"
            ? this.renderService(ServiceOptionList_PCM)
            : this.renderService(ServiceOptionList)}
        </Checkbox.Group>
        <div style={{ marginTop: 15, display: "flex" }}>
          <strong>Details</strong> <p style={{ color: "red" }}>*</p>
        </div>
        <Input.TextArea
          autoSize={{
            minRows: 4
          }}
          value={item.description}
          onChange={e => this.onDescriptionChanged(e, i)}
          style={{ marginTop: 5 }}
        />
        {this.props.serviceInfo &&
          this.props.serviceInfo.addendums &&
          this.props.serviceInfo.addendums.map((item, i) => {
            return (
              <div style={{ marginTop: 20 }} key={i}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: 12
                    }}
                  >
                    Addendum
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      marginLeft: 15,
                      paddingTop: "4px",
                      fontStyle: "oblique"
                    }}
                  >
                    Added by {item.userName} on {item.addendumTime}
                  </div>
                </div>
                <Input.TextArea
                  autoSize={{
                    minRows: 4
                  }}
                  value={item.addendum}
                  disabled
                />
              </div>
            );
          })}
      </div>
    );
  }

  deleteRpmConfirm = () => {
    confirm({
      title: "Delete DMT Confirmation",
      content: `Are you sure you want to permanently delete this DMT entry?`,
      cancelText: "No",
      okText: "Yes",
      onCancel: () => {},
      onOk: () => {
        this.handleDeleteDMT();
      }
    });
  };

  handleDeleteDMT = () => {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[0] = true;
      return {
        loadings: newLoadings
      };
    });
    const input = {
      userId: this.props.serviceInfo.userId,
      programTime: this.props.serviceInfo.programTime,
      programNotes: this.props.serviceInfo.programNotes,
      currentMonth: moment().format("YYYY-MM"),
      updatePatientMedicalInfoVersion: true,
      siteId: this.props.patientDetails.siteId,
      serviceDate: this.props.serviceInfo.serviceDate
    };
    this.props.deleteMonitoringTracker(input, this).then(() => {
      this.props.loadNewEditValue();
    });
  };

  disableFutureDate = current =>
    moment(current).format("YYYY-MM") > moment().format("YYYY-MM") ||
    moment(current).format("YYYY-MM") < moment().format("YYYY-MM");

  render() {
    const { open } = this.state;
    const { Option } = Select;
    return (
      <Modal
        trigger={
          <Button
            size="mini"
            onClick={this.handleOpen}
            style={{ display: "none" }}
          >
            Edit
          </Button>
        }
        open={open}
      >
        <Modal.Header>
          <div>Edit DMT</div>
        </Modal.Header>
        <Modal.Content scrolling style={{ padding: "1.5rem 3rem" }}>
          {this.state.confirmSuccess ? (
            {
              /*<ButtonSuccess />*/
            }
          ) : (
            <div style={{ fontSize: 14 }}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex" }}>
                    <div>
                      <div>
                        <strong>Service Date</strong>
                      </div>
                      <DatePicker
                        onChange={this.onDateChanged}
                        value={moment(this.state.serviceDate)}
                        format={dateFormat}
                        style={{ width: 200, marginTop: 10 }}
                        allowClear={false}
                        disabledDate={
                          this.props.patientDetails.siteId &&
                          !this.props.patientDetails.siteId.startsWith(
                            "0203"
                          ) &&
                          this.disableFutureDate
                        }
                        disabled={
                          this.props.patientDetails.siteId &&
                          !this.props.patientDetails.siteId.startsWith("0203")
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", marginTop: 10 }}>
                    <div style={{ width: 200 }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          marginBottom: 10,
                          display: "flex"
                        }}
                      >
                        Special Case
                        <SpecialCasePop />
                      </div>
                      <Select
                        value={this.state.specialCaseStatus}
                        onChange={this.handleSpecialCaseChange}
                        placeholder="N/A"
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        optionFilterProp="children"
                        disabled={!this.state.canEdit}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                    <div style={{ width: 400, marginLeft: 20 }}>
                      <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                        Reason
                      </div>
                      <Select
                        value={this.state.specialCaseReason}
                        onChange={this.handleSpecialCaseReasonChange}
                        style={{ width: "100%" }}
                        mode="multiple"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        optionFilterProp="children"
                        disabled={
                          !this.state.canEdit ||
                          this.state.specialCaseStatus !== "Yes"
                        }
                      >
                        {this.state.specialCaseStatus === "No" && (
                          <Option value="Not a special case">
                            Not a special case
                          </Option>
                        )}
                        <Option value="Patient medical history indicates amputation">
                          Patient medical history indicates amputation
                        </Option>
                        <Option value="Patient has a current medical condition">
                          Patient has a current medical condition
                        </Option>
                        <Option value="Patient is undergoing treatment">
                          Patient is undergoing treatment
                        </Option>
                      </Select>
                    </div>
                  </div>
                  <DividLine />
                  {
                    <FootSymptom
                      data={this.props.serviceInfo.symptoms}
                      onSymptomChange={symptomData => {
                        symptomData = symptomData.map(p => {
                          return {
                            ...p,
                            reportTime:
                              this.state.serviceDate +
                              p.reportTime.substring(10)
                          };
                        });
                        this.setState({ symptomData });
                      }}
                    />
                  }
                  <PatientMedicalHistory
                    collapse
                    readOnly
                    canEdit={this.state.canEdit}
                    medicalCondition={this.state.medicalCondition}
                    footSpecific={this.state.footSpecific}
                    footAmputation={this.state.footAmputation}
                    updateMedicalCondition={medicalCondition => {
                      this.setState({ medicalCondition });
                    }}
                    updateFootSpecific={footSpecific => {
                      this.setState({ footSpecific });
                    }}
                    updateFootAmputation={footAmputation => {
                      this.setState({
                        footAmputation
                      });
                    }}
                  />
                  <DividLine />
                  <PatientICDCode
                    canEdit={true}
                    diagnosisCodes={this.state.diagnosisCodes}
                    saveDiagnosisCodes={diagnosisCodes => {
                      this.setState({ diagnosisCodes });
                    }}
                  />
                  <DividLine />
                  {
                    <FootCondition
                      type="EDIT"
                      canEdit={this.state.canEdit}
                      serviceInfo={this.props.serviceInfo}
                      onChangeFootConditionData={conditionData => {
                        this.setState({ conditionData });
                      }}
                      onChangeFootConditionStatus={status => {
                        console.log("edit foot condition status: ", status);
                        this.setState({ footConditionStatus: status });
                      }}
                    />
                  }
                  <FootTreatment
                    type="EDIT"
                    canEdit={this.state.canEdit}
                    serviceInfo={this.props.serviceInfo}
                    onChangeFootTreatmentData={treatmentData => {
                      this.setState({ treatmentData });
                    }}
                    onChangeFootTreatmentStatus={status => {
                      console.log("edit foot treatment status: ", status);
                      this.setState({ footTreatmentStatus: status });
                    }}
                  />
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: 12,
                      marginTop: 20
                    }}
                  >
                    Notes
                  </div>
                  {this.state.programNotes.map((item, i) => {
                    return (
                      <div key={i}>
                        {i > 0 && <DividLine />}
                        {this.renderDescSection(item, i)}
                      </div>
                    );
                  })}
                  <AntdButton
                    type="link"
                    style={{
                      marginTop: 20,
                      marginLeft: -16
                    }}
                    onClick={() =>
                      this.setState({
                        programNotes: [
                          ...this.state.programNotes,
                          {
                            ...defaultNote,
                            rpmKey: _.concat(
                              moment().format("YYYY-MM-DDTHH:mm:ss"),
                              ".",
                              (Math.random() * 1000).toFixed(0),
                              "Z"
                            ).join(""),
                            program: this.state.programs[0],
                            cptCode: program2CPT[this.state.programs[0]]
                          }
                        ]
                      })
                    }
                  >
                    + Add another note
                  </AntdButton>
                </div>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions style={{ display: "flex" }}>
          <AntdButton
            type="link"
            style={{
              color: "red",
              float: "left",
              position: "absolute"
            }}
            loading={this.state.loadings[0]}
            onClick={this.deleteRpmConfirm}
          >
            Delete
          </AntdButton>
          <div style={{ textAlign: "center", flex: 1 }}>
            <Button
              color="green"
              onClick={e => this.submit()}
              style={{
                display: this.state.confirmSuccess ? "none" : "inline-block"
              }}
              disabled={this.state.submitting}
            >
              <Icon name="checkmark" />
              Submit
            </Button>
            <Button
              basic
              color="red"
              onClick={e => this.close()}
              style={{ marginLeft: 20 }}
            >
              <Icon name="remove" />
              {this.state.confirmSuccess ? "Close" : "Cancel"}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  rpmNoteTmpls: state.app.rpmNoteTmpls,
  listStaff: state.patientsListStore.listStaff,
  npmService: state.patientsListStore.npmService,
  patientDetails: state.patientsListStore.doctorDetails,
  doctorDetailsBK: state.patientsListStore.doctorDetailsBK,
  loading_charts: state.patientsListStore.loading_charts,
  profile: state.authGroupStore.profile
});
const mapDispatchToProp = dispatch => ({
  changePatientDetails: data => dispatch(changeDoctorDetails(data)),
  update99457Service: (input, self) =>
    dispatch(update99457Service(input, self)),
  deleteMonitoringTracker: (input, self) =>
    dispatch(deleteMonitoringTracker(input, self)),
  changeDMTModalStatus: status => dispatch(changeDMTModalStatus(status))
});
export default connect(mapStateToProp, mapDispatchToProp)(Edit99457Service);
