import { Alert, Select } from "antd";
import _, { isEmpty } from "lodash";
import moment from "moment-timezone";
import React, { Component } from "react";
import "react-dropdown/style.css";
import ReactTable from "react-table";
import DateInput from "../common/DateInput";
import { deletedPatientListAPI } from "../graphql/API";
import { formatNumber } from "../tools/phoneFormat";
import {
  DateFormat,
  InputEnterKeyTriggerFilterComponent,
  transformPatientTableParams
} from "../tools/reactTablePatch";
import DeleteNewPatient from "./deletePatientForever";
import "./DeviceList.css";
import RecoveryPatient from "./recoveryPatient";
import { Auth } from "aws-amplify";

const { Option } = Select;
export default class DeletePatientsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      totalSize: 0,
      error: null,
      showDelete: false
    };

    this.tableInstance = null;
    this.makeRequestInstant = true;
  }

  async componentDidMount() {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    this.setState({
      showDelete: groups.includes("admin")
    });
  }

  // wrapper a function if it should trigger request instantly
  reqShouldInstantWrapper = (shouldInstant, onEvent) => {
    return value => {
      this.makeRequestInstant = shouldInstant;
      return onEvent(value);
    };
  };

  reqPatientList = _.debounce(() => {
    const { filter, sort, pagination } = transformPatientTableParams(
      this.tableInstance,
      (filter, key, value) => {
        if (key === "deletedBy") {
          filter["deletedBy"] = {
            wildcard: `*${value.toLowerCase()}*`
          };
        }
      }
    );

    this.rawReqPatientList(filter, sort, pagination);
  }, 3000);

  rawReqPatientList(filter, sort, pagination) {
    this.setState({
      loading: true,
      error: null
    });

    deletedPatientListAPI(filter, sort, pagination)
      .then(data => {
        let patiens = data.data.lambdaSearchPatients.items;
        let totalSize = data.data.lambdaSearchPatients.total;

        this.setState({
          data: patiens,
          totalSize: totalSize
        });
      })
      .catch(error => {
        this.setState({
          error: error
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  dateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
        defaultValue={filter ? filter.value : null}
        onPressEnter={this.reqShouldInstantWrapper(true, onChange)}
        onChange={this.reqShouldInstantWrapper(false, onChange)}
      />
    );
  };

  render() {
    return (
      <div className="device-list-container">
        {this.state.error && (
          <Alert
            message={JSON.stringify(this.state.error)}
            type="error"
            closable
          />
        )}
        <ReactTable
          className="-striped -highlight"
          manual
          loading={this.state.loading}
          defaultSorted={[{ id: "createdAt", desc: true }]}
          defaultFiltered={[
            {
              id: "initialized",
              value: "No"
            }
          ]}
          defaultPageSize={20}
          pages={
            !this.tableInstance
              ? -1
              : Math.ceil(
                  this.state.totalSize / this.tableInstance.state.pageSize
                )
          }
          minRows={0}
          showPaginationTop={true}
          filterable
          FilterComponent={InputEnterKeyTriggerFilterComponent({
            onChange: () => {
              this.makeRequestInstant = false;
            },
            onPressEnter: () => {
              this.makeRequestInstant = true;
            }
          })}
          onSortedChange={() => {
            this.makeRequestInstant = true;
          }}
          data={this.state.data}
          onFetchData={(_, instance) => {
            this.tableInstance = instance;

            this.reqPatientList();
            if (this.makeRequestInstant) {
              this.reqPatientList.flush();
            }
          }}
          resolveData={data =>
            data.map(p => {
              const phone = !isEmpty(p.phone)
                ? formatNumber(p.phone).trim()
                : "";
              const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
              const initialized =
                p.initialized === true
                  ? "Yes"
                  : p.initialized === false
                  ? "No"
                  : p.initialized === null
                  ? "No"
                  : "";
              const appInitialized = p.appInitialized ? p.appInitialized : "No";
              const contactName = p.contactName === null ? "" : p.contactName;
              const contactPhone = isEmpty(p.contactPhone)
                ? ""
                : formatNumber(p.contactPhone).trim();
              const email = p.email && p.email.includes("@") ? p.email : "";
              const primaryKey = p.id;
              const dispensed =
                p.sockStatus ||
                p.socks.length > 0 ||
                (p.dispenseType && p.dispenseType === "CLINIC_DISPENSED") ||
                (p.dispenseType &&
                  p.dispenseType === "SIREN_SHIPPED" &&
                  p.shipmentStatus &&
                  (p.shipmentStatus === "In Transit" ||
                    p.shipmentStatus === "Delivered"))
                  ? "Yes"
                  : "No";
              const sirenId = p.sirenId ? p.sirenId : "";
              const deletedAt = p.deletedAt
                ? moment.tz(p.deletedAt, p.userTimezone).format(DateFormat)
                : "";
              return {
                ...p,
                phone,
                createdAt,
                deletedAt,
                initialized,
                appInitialized,
                contactName,
                contactPhone,
                email,
                primaryKey,
                dispensed,
                sirenId
              };
            })
          }
          columns={[
            {
              Header: "SiteId",
              accessor: "siteId",
              maxWidth: 70,
              sortable: false
            },
            {
              Header: "SiteName",
              accessor: "siteName"
            },
            {
              Header: "SirenId",
              accessor: "sirenId",
              maxWidth: 70
            },
            {
              Header: "First Name",
              accessor: "firstName"
            },
            {
              Header: "Last Name",
              accessor: "lastName"
            },
            {
              Header: "Phone",
              accessor: "phone",
              minWidth: 140
            },
            {
              Header: "Email",
              accessor: "email",
              minWidth: 180
            },
            {
              Header: "Account Created",
              accessor: "createdAt",
              Filter: this.dateInputComponent
            },
            {
              Header: "Socks Dispensed",
              accessor: "dispensed",
              sortable: false,
              filterable: false,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "App Activated",
              accessor: "appInitialized",
              maxWidth: 72,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={this.reqShouldInstantWrapper(true, onChange)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "Deleted On",
              accessor: "deletedAt",
              Filter: this.dateInputComponent
            },
            {
              Header: "Deleted By",
              accessor: "deletedBy",
              minWidth: 180
            },
            {
              Header: "Actions",
              sortable: false,
              filterable: false,
              minWidth: 200,
              Cell: props => {
                return (
                  <div>
                    {this.state.showDelete && (
                      <DeleteNewPatient
                        id={props.original.primaryKey}
                        userInfo={props.original}
                        onDeleted={id => {
                          const nextData = this.state.data.filter(
                            p => p.id !== id
                          );
                          this.setState({ data: nextData });
                        }}
                      />
                    )}
                    <RecoveryPatient
                      id={props.original.primaryKey}
                      userInfo={props.original}
                      onRecovery={id => {
                        const nextData = this.state.data.filter(
                          p => p.id !== id
                        );
                        this.setState({ data: nextData });
                      }}
                    />
                  </div>
                );
              }
            }
          ]}
        />
      </div>
    );
  }
}
