import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { resetPwd } from "../action/registerAction";
import ButtonSuccess from "../home/buttonSuccess";
import "../SupportDashboard/account/DeleteSite.css";
import "./ResetStaffPwd.css";
function isSimplePwd(s) {
  if (s.length < 8) {
    return 0;
  }
  var ls = 0;
  if (s.length >= 8) {
    ls++;
  }
  if (s.match(/([a-z])+/)) {
    ls++;
  }
  if (s.match(/([0-9])+/)) {
    ls++;
  }
  if (s.match(/([A-Z])+/)) {
    ls++;
  }
  if (s.match(/[^a-zA-Z0-9]+/)) {
    ls++;
  }
  return ls;
}
function isLowletter(a) {
  if (a.match(/([a-z])+/)) {
    return true;
  } else {
    return false;
  }
}
function isUpperletter(a) {
  if (a.match(/([A-Z])+/)) {
    return true;
  } else {
    return false;
  }
}
function isNum(a) {
  if (a.match(/([0-9])+/)) {
    return true;
  } else {
    return false;
  }
}
function isSpecial(a) {
  if (a.match(/[^a-zA-Z0-9]+/)) {
    return true;
  } else {
    return false;
  }
}
class ResetStaffPwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      showPassword: true,
      newPwd: "",
      simplePwd: false,
      haveLength: true,
      haveLowletter: true,
      haveUpperletter: true,
      haveNum: true,
      haveSpecial: true
    };
    this.handleInputChangePwd = this.handleInputChangePwd.bind(this);
    this.changeShowPassword = this.changeShowPassword.bind(this);
    this.handleConfirm.bind(this);
  }
  changeShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleInputChangePwd(event) {
    this.setState({
      newPwd: event.target.value
    });
  }
  show = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      showPassword: true,
      newPwd: ""
    });
  };
  handleCancel = () =>
    this.setState({
      open: false,
      confirmSuccess: false
    });
  handleConfirm = () => {
    if (!isEmpty(this.state.newPwd)) {
      if (isSimplePwd(this.state.newPwd) === 5) {
        this.setState({ simplePwd: false });
        const { resetPwd } = this.props;
        const newPwdInput = {
          input: {
            id: this.props.id,
            password: this.state.newPwd
          }
        };
        resetPwd(newPwdInput, this);
        this.setState({ confirmSuccess: true });
      } else {
        this.setState({ simplePwd: true });
        if (this.state.newPwd.length < 8) {
          this.setState({ haveLength: false });
        } else {
          this.setState({ haveLength: true });
          if (isLowletter(this.state.newPwd) === false) {
            this.setState({ haveLowletter: false });
          } else {
            this.setState({ haveLowletter: true });
            if (isNum(this.state.newPwd) === false) {
              this.setState({ haveNum: false });
            } else {
              this.setState({ haveNum: true });
              if (isUpperletter(this.state.newPwd) === false) {
                this.setState({ haveUpperletter: false });
              } else {
                this.setState({ haveUpperletter: true });
                if (isSpecial(this.state.newPwd) === false) {
                  this.setState({ haveSpecial: false });
                } else {
                  this.setState({ haveSpecial: true });
                }
              }
            }
          }
        }
      }
    }
  };
  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini">
            Reset Password
          </Button>
        }
        open={this.state.open}
        className="resetPwd_Modal"
      >
        <Modal.Header>Reset Password</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <form className="newSite_box">
              <div className="resetTitleBox">
                <p className="resetTip">
                  Must be 8 or more characters. Use letters, numbers, and
                  symbols
                </p>
                <div className="totalBox">
                  <div className="resetPwd_Box">
                    <p className="resetPwd_Label">New Password</p>
                    <Input
                      placeholder="New Password"
                      className="resetPwd_input"
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.handleInputChangePwd}
                    />
                    <div className="clearFloat" />
                    <Icon
                      as="i"
                      name={this.state.showPassword ? "eye slash" : "eye"}
                      onClick={this.changeShowPassword}
                      className="resetPwd_eyeIcon"
                    />
                  </div>
                  <div className="resetPwd_Tip_Box">
                    <Icon
                      as="i"
                      name="check"
                      className="resetPwd_checkIcon"
                      style={{
                        color: "red",
                        visibility: this.state.simplePwd ? "visible" : "hidden"
                      }}
                    />
                    <div className="clearFloat" />
                  </div>
                  <div
                    className="resetPwd_error_Tip"
                    style={{
                      color: "red",
                      visibility: this.state.simplePwd ? "visible" : "hidden"
                    }}
                  >
                    {this.state.haveLength === false
                      ? "Minimum length is 8"
                      : this.state.haveLowletter === false
                      ? "Require lowercase letters"
                      : this.state.haveNum === false
                      ? "Require numbers"
                      : this.state.haveUpperletter === false
                      ? "Require uppercase letters"
                      : this.state.haveSpecial === false
                      ? "Require special character"
                      : "OK"}
                  </div>
                  <div className="clearFloat" />
                </div>
              </div>
            </form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({
  resetPwd: (newPwdInput, self) => dispatch(resetPwd(newPwdInput, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(ResetStaffPwd);
