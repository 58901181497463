import {
  Alert,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Popover,
  Row,
  Select
} from "antd";
import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import { checkBirthdayValid } from "../../../tools/dateFormat";
import { PATIENT_RESIDENCE } from "../../../tools/patient-medical";
import { validatePhone } from "../../../tools/phoneFormat";
import NewAccountStep from "./NewAccountStep";

const { Option } = Select;

const GenderList = ["Female", "Male", "Other", "Prefer not to say"];

const RelationList = [
  "Spouse/Partner",
  "Sibling",
  "Child",
  "Niece/Nephew",
  "Grandchild",
  "Caregiver",
  "Other"
];

export default class NewAccountStep2 extends NewAccountStep {
  static Name = "Patient Account";

  state = {
    firstName: this.props.firstName,
    firstNameStatus: "success",
    lastName: this.props.lastName,
    lastNameStatus: "success",
    dob: this.props.dob,
    dobStatus: "success",
    phone: this.props.phone,
    phoneStatus: "success",
    homePhone: this.props.homePhone,
    homePhoneStatus: "success",
    email: this.props.email,
    emailStatus: "success",
    gender: this.props.gender,
    patientID: this.props.patientID,
    MBI: this.props.MBI,
    residence: this.props.residence || "Home",
    shipAddress: this.props.shipAddress,
    shipCity: this.props.shipCity,
    shipState: this.props.shipState,
    shipZip: this.props.shipZip,
    secondaryName: this.props.secondaryName,
    secondaryPhone: this.props.secondaryPhone,
    secondaryPhoneStatus: "success",
    secondaryEmail: this.props.secondaryEmail,
    secondaryEmailStatus: "success",
    secondaryRelation: this.props.secondaryRelation,
    authSecondary: this.props.authSecondary
  };

  validateForm() {
    let pass = true;
    if (!this.state.firstName) {
      this.setState({
        firstNameStatus: "error"
      });
      pass = false;
    }
    if (!this.state.lastName) {
      this.setState({
        lastNameStatus: "error"
      });
      pass = false;
    }
    if (!this.state.dob) {
      this.setState({
        dobStatus: "error"
      });
      pass = false;
    }
    if (!validatePhone(this.state.phone)) {
      this.setState({
        phoneStatus: "error"
      });
      pass = false;
    }
    if (this.state.homePhone && !validatePhone(this.state.homePhone)) {
      this.setState({
        homePhoneStatus: "error"
      });
      pass = false;
    }
    if (!checkBirthdayValid(this.state.dob)) {
      pass = false;
    }
    if (this.state.email && !this.validateEmail(this.state.email)) {
      this.setState({
        emailStatus: "error"
      });
      pass = false;
    }
    if (
      this.state.secondaryEmail &&
      !this.validateEmail(this.state.secondaryEmail)
    ) {
      this.setState({
        secondaryEmailStatus: "error"
      });
      pass = false;
    }
    if (
      this.state.secondaryPhone &&
      !validatePhone(this.state.secondaryPhone)
    ) {
      this.setState({
        secondaryPhoneStatus: "error"
      });
      pass = false;
    }

    return pass;
  }

  validateEmail(email) {
    const reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  }

  render() {
    return (
      <div style={{ width: 800, margin: "auto", marginTop: 40 }}>
        <Form>
          <div className="form-section">
            <Divider orientation="left">Patient Information</Divider>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  label="First Name"
                  required
                  className="form-item"
                  validateStatus={this.state.firstNameStatus}
                  help={
                    this.state.firstNameStatus === "error"
                      ? "Please input FirstName"
                      : ""
                  }
                >
                  <Input
                    size="large"
                    value={this.state.firstName}
                    onChange={e =>
                      this.setState({
                        firstName: e.target.value,
                        firstNameStatus: "success"
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Last Name"
                  required
                  className="form-item"
                  validateStatus={this.state.lastNameStatus}
                  help={
                    this.state.lastNameStatus === "error"
                      ? "Please input LastName"
                      : ""
                  }
                >
                  <Input
                    size="large"
                    value={this.state.lastName}
                    onChange={e =>
                      this.setState({
                        lastName: e.target.value,
                        lastNameStatus: "success"
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Date of Birth (DOB)"
                  required
                  className="form-item"
                  validateStatus={this.state.dobStatus}
                  help={
                    this.state.dobStatus === "error"
                      ? "Please input Birthday"
                      : ""
                  }
                >
                  <DatePicker
                    size="large"
                    onChange={date =>
                      this.setState({
                        dob: date,
                        dobStatus: "success"
                      })
                    }
                    value={this.state.dob}
                    format="MM-DD-YYYY"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <div style={{ clear: "both" }}></div>
              <Col span={8}>
                <Form.Item
                  label="Username"
                  required
                  className="form-item"
                  validateStatus={this.state.phoneStatus}
                  help={
                    this.state.phoneStatus === "error"
                      ? "Invalid phone number"
                      : ""
                  }
                >
                  <ReactPhoneInput
                    inputClass="phone-input"
                    buttonClass="phone-button"
                    inputExtraProps={{
                      name: "Cell phone",
                      required: true,
                      autoFocus: false
                    }}
                    defaultCountry={"us"}
                    value={this.state.phone}
                    onChange={value => {
                      this.setState({
                        phone: value,
                        phoneStatus: "success"
                      });
                    }}
                    disableAreaCodes
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Cell Phone" className="form-item">
                  <ReactPhoneInput
                    inputClass="phone-input"
                    buttonClass="phone-button"
                    inputExtraProps={{
                      autoFocus: false
                    }}
                    defaultCountry={"us"}
                    value={this.state.phone}
                    onChange={value =>
                      this.setState({
                        phone: value,
                        phoneStatus: "success"
                      })
                    }
                    disableAreaCodes
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Home Phone"
                  className="form-item"
                  validateStatus={this.state.homePhoneStatus}
                  help={
                    this.state.homePhoneStatus === "error"
                      ? "Invalid phone number"
                      : ""
                  }
                >
                  <ReactPhoneInput
                    inputClass="phone-input"
                    buttonClass="phone-button"
                    inputExtraProps={{
                      autoFocus: false
                    }}
                    defaultCountry={"us"}
                    value={this.state.homePhone}
                    onChange={value => this.setState({ homePhone: value })}
                    disableAreaCodes
                  />
                </Form.Item>
              </Col>
              <div style={{ clear: "both" }}></div>
              <Col span={8}>
                <Form.Item
                  label="Email"
                  className="form-item"
                  validateStatus={this.state.emailStatus}
                  help={
                    this.state.emailStatus === "error"
                      ? "Invalid email format"
                      : ""
                  }
                >
                  <Input
                    size="large"
                    onChange={e => this.setState({ email: e.target.value })}
                    value={this.state.email}
                    type="email"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Gender" className="form-item">
                  <Select
                    size="large"
                    onChange={value => this.setState({ gender: value })}
                    value={this.state.gender}
                    filterOption={(input, option) =>
                      option.props.value
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="" key="-1">
                      &nbsp;
                    </Option>
                    {GenderList.map((v, i) => (
                      <Option value={v} key={i}>
                        {v}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Patient ID" className="form-item">
                  <Input
                    size="large"
                    onChange={e => this.setState({ patientID: e.target.value })}
                    value={this.state.patientID}
                  />
                </Form.Item>
              </Col>
              <div style={{ clear: "both" }}></div>
              <Col span={8}>
                <Form.Item label="MBI" className="form-item">
                  <Input
                    size="large"
                    placeholder="Medicare Beneficiary Identifier"
                    onChange={e => this.setState({ MBI: e.target.value })}
                    value={this.state.MBI}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Patient Residence" className="form-item">
                  <Select
                    size="large"
                    onChange={value => this.setState({ residence: value })}
                    value={this.state.residence}
                    filterOption={(input, option) =>
                      option.props.value
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    {PATIENT_RESIDENCE.map((v, i) => (
                      <Option value={v.value} key={i}>
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ paddingBottom: 0 }}>
                <span style={{ marginRight: 8 }}>Shipping Address</span>
                <Popover
                  content={
                    <Alert
                      banner
                      message="Required for shipping and/or replacements. No P.O. Box deliveries"
                      type="warning"
                    />
                  }
                >
                  <Icon type="info-circle" style={{ color: "#faad14" }} />
                </Popover>
              </Col>
              <Col span={12}>
                <Input
                  size="large"
                  placeholder="Address"
                  onChange={e => this.setState({ shipAddress: e.target.value })}
                  value={this.state.shipAddress}
                />
              </Col>
              <Col span={6}>
                <Input
                  size="large"
                  placeholder="City"
                  onChange={e => this.setState({ shipCity: e.target.value })}
                  value={this.state.shipCity}
                />
              </Col>
              <Col span={3}>
                <Input
                  size="large"
                  placeholder="State"
                  onChange={e => this.setState({ shipState: e.target.value })}
                  value={this.state.shipState}
                />
              </Col>
              <Col span={3}>
                <Input
                  size="large"
                  placeholder="Zip"
                  onChange={e => this.setState({ shipZip: e.target.value })}
                  value={this.state.shipZip}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div style={{ lineHeight: "30px" }}>
                  Preferred Contact Method (Select all that apply)
                </div>
                <Checkbox
                  size="large"
                  onChange={e => {
                    if (this.props.site.onStaffing === "Yes") {
                      message.warning(
                        "This clinic is on the Siren Staffing program."
                      );
                      return;
                    }
                    this.setState({ contactByPhone: e.target.checked });
                  }}
                  checked={this.state.contactByPhone}
                  style={{ marginRight: 10 }}
                >
                  App Notification
                </Checkbox>
                <Checkbox
                  size="large"
                  onChange={e => {
                    if (this.props.site.onStaffing === "Yes") {
                      message.warning(
                        "This clinic is on the Siren Staffing program."
                      );
                      return;
                    }
                    this.setState({ contactByEmail: e.target.checked });
                  }}
                  checked={this.state.contactByEmail}
                  style={{ marginRight: 10 }}
                >
                  Email
                </Checkbox>
                <Checkbox
                  size="large"
                  onChange={e => {
                    if (this.props.site.onStaffing === "Yes") {
                      message.warning(
                        "This clinic is on the Siren Staffing program."
                      );
                      return;
                    }
                    this.setState({ contactByMessage: e.target.checked });
                  }}
                  checked={this.state.contactByMessage}
                  style={{ marginRight: 10 }}
                >
                  SMS/Text Message
                </Checkbox>
              </Col>
            </Row>
          </div>
          <div className="form-section">
            <Divider orientation="left">Emergency Contact Information</Divider>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item label="Emergency Contact Name" className="form-item">
                  <Input
                    size="large"
                    onChange={e =>
                      this.setState({ secondaryName: e.target.value })
                    }
                    value={this.state.secondaryName}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Emergency Contact Phone"
                  className="form-item"
                  validateStatus={this.state.secondaryPhoneStatus}
                  help={
                    this.state.secondaryPhoneStatus === "error"
                      ? "Invalid phone number"
                      : ""
                  }
                >
                  <ReactPhoneInput
                    inputClass="phone-input"
                    buttonClass="phone-button"
                    inputExtraProps={{
                      name: "seccondaryPhone",
                      required: false,
                      autoFocus: false
                    }}
                    defaultCountry="us"
                    value={this.state.secondaryPhone}
                    onChange={value => this.setState({ secondaryPhone: value })}
                    disableAreaCodes
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Emergency Contact Email"
                  className="form-item"
                  validateStatus={this.state.secondaryEmailStatus}
                  help={
                    this.state.secondaryEmailStatus === "error"
                      ? "Invalid email format"
                      : ""
                  }
                >
                  <Input
                    size="large"
                    onChange={e =>
                      this.setState({
                        secondaryEmail: e.target.value,
                        secondaryEmailStatus: "success"
                      })
                    }
                    value={this.state.secondaryEmail}
                    type="email"
                  />
                </Form.Item>
              </Col>
              <div style={{ clear: "both" }}></div>
              <Col span={8}>
                <Form.Item
                  label="Relationship to patient"
                  className="form-item"
                >
                  <Select
                    size="large"
                    onChange={value =>
                      this.setState({ secondaryRelation: value })
                    }
                    value={this.state.secondaryRelation}
                    filterOption={(input, option) =>
                      option.props.value
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="" key="-1">
                      &nbsp;
                    </Option>
                    {RelationList.map((v, i) => (
                      <Option value={v} key={i}>
                        {v}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item className="form-item">
                  <Checkbox
                    size="large"
                    onChange={e =>
                      this.setState({ authSecondary: e.target.checked })
                    }
                    checked={this.state.authSecondary}
                  >
                    HIPAA Authorization obtained for Emergency Contact
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}
