import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Icon, Popup } from "semantic-ui-react";
import getPatchTrProps from "../tools/reactTablePatch";
import { lowercaseRmSpace } from "../tools/stringProcess";
import "../SupportDashboard/account/NewSite.css";
import "../SupportDashboard/account/SiteList.css";
import { Control } from "react-keeper";
import { EditTwoTone } from "@ant-design/icons";
import { Modal, Select } from "antd";
import {
  getLpnStaff,
  updateLpnPatientLoad
} from "../action/lpnManagementAction";
import EditAdminUser from "../SupportDashboard/account/EditAdminUser";
import _, { isEmpty } from "lodash";

const { Option } = Select;
const { confirm } = Modal;

class LpnList extends Component {
  state = {
    lpnPatientLoad: "",
    loading: false
  };

  componentDidMount() {
    if (this.props.listSirenStaff.length === 0) {
      this.props.getLpnStaff();
    }
  }

  refreshList = () => {
    this.setState({
      loading: true
    });
    this.props.getLpnStaff().finally(() => {
      this.setState({
        loading: false
      });
    });
  };

  gotoDoctorDetailPage = id => {
    Control.go(`/LpnPatient?id=${id}`);
  };

  handleChangePatientLoad = value => {
    this.setState({ lpnPatientLoad: value });
  };

  updatePatientLoadForLpn = lpnInfo => {
    confirm({
      title: (
        <div>
          Change Patient Load For{" "}
          <strong>
            {lpnInfo.firstName} {lpnInfo.lastName}
          </strong>
        </div>
      ),
      content: (
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="Please select"
            defaultValue={lpnInfo.patientLoad}
            onChange={this.handleChangePatientLoad}
          >
            <Option value="50">50</Option>
            <Option value="100">100</Option>
            <Option value="150">150</Option>
            <Option value="200">200</Option>
            <Option value="250">250</Option>
            <Option value="300">300</Option>
            <Option value="350">350</Option>
            <Option value="400">400</Option>
          </Select>
        </div>
      ),
      cancelText: "Cancel",
      onCancel: () => {
        this.setState({ lpnPatientLoad: "" });
      },
      okText: "OK",
      onOk: () => {
        if (!isEmpty(this.state.lpnPatientLoad)) {
          const input = {
            id: lpnInfo.id,
            firstName: lpnInfo.firstName,
            lastName: lpnInfo.lastName,
            patientLoad: parseInt(this.state.lpnPatientLoad)
          };
          this.props.updateLpnPatientLoad(input, this);
        }
      },
      centered: true
    });
  };

  render() {
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(rowInfo.original.id);
    });
    return (
      <div>
        <ReactTable
          getTrProps={trProps}
          loading={this.state.loading}
          data={
            this.props.listSirenStaff &&
            this.props.listSirenStaff.map(p => {
              const userName = p.id;
              const patientLoad = parseInt(p.patientLoad);

              const group =
                p.group === "admin"
                  ? "Super User"
                  : p.group === "developer"
                  ? "Admin"
                  : p.group === "support"
                  ? "Support"
                  : p.group === "staff"
                  ? "Siren Staffing"
                  : "Sales";
              return {
                ...p,
                userName,
                patientLoad,
                group
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            lowercaseRmSpace(String(row[filter.id])).includes(
              lowercaseRmSpace(filter.value)
            )
          }
          columns={[
            {
              Header: "User",
              accessor: "userName"
            },
            {
              Header: "First Name",
              accessor: "firstName",
              maxWidth: 160
            },
            {
              Header: "Last Name",
              accessor: "lastName",
              maxWidth: 160
            },
            {
              Header: "Primary Site",
              accessor: "primarySites",
              maxWidth: 400,
              Cell: props => {
                let siteList = [];
                if (props.value) {
                  siteList = JSON.parse(props.value);
                }
                return (
                  <div>
                    <Popup
                      pinned="true"
                      trigger={
                        <p className="sr-text-ellipsis">{siteList.join(",")}</p>
                      }
                    >
                      <div style={{ maxHeight: 600, overflowY: "auto" }}>
                        {siteList.map((id, index) => (
                          <p key={index}>{id}</p>
                        ))}
                      </div>
                    </Popup>
                  </div>
                );
              }
            },
            {
              Header: "Backup For",
              accessor: "backupFor",
              maxWidth: 400,
              Cell: props => {
                let backUpList = [];
                let hasDeleted = false;
                if (props.value) {
                  backUpList = JSON.parse(props.value);
                  backUpList = backUpList.map(id => {
                    const adminStaff = this.props.listSirenStaff.find(
                      user => user.id === id
                    );
                    if (adminStaff) {
                      return (
                        <span>{`${adminStaff.firstName} ${adminStaff.lastName}`}</span>
                      );
                    } else {
                      hasDeleted = true;
                      return <del>{id}</del>;
                    }
                  });
                }
                if (backUpList.length === 0) {
                  return "";
                }
                return (
                  <Popup
                    trigger={
                      <p className="sr-text-ellipsis">
                        {hasDeleted && (
                          <Icon color="yellow" name="warning sign" />
                        )}
                        {backUpList.reduce((prev, curr) => [prev, ", ", curr])}
                      </p>
                    }
                  >
                    {backUpList.map((name, index) => (
                      <p key={index}>{name}</p>
                    ))}
                  </Popup>
                );
              }
            },
            {
              Header: "Patient Load",
              accessor: "patientLoad",
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <div>
                      {props.original.patientLoad}
                      {this.props.userGroups.includes("admin") && (
                        <EditTwoTone
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            this.updatePatientLoadForLpn(props.original);
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              }
            },
            {
              Header: "Patient Count",
              accessor: "patientCount"
            },
            {
              Header: "Rate(%)",
              accessor: "rate"
            },
            {
              Header: "Action",
              sortable: false,
              filterable: false,
              show: this.props.userGroups.includes("admin"),
              Cell: props => (
                <div onClick={e => e.stopPropagation()}>
                  <EditAdminUser
                    adminUser={props.original}
                    showTrigger
                    afterCommit={() => {
                      this.props.getLpnStaff();
                    }}
                  />
                </div>
              )
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "rate",
              desc: true
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  listSirenStaff: state.patientsListStore.listSirenStaff,
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
  getLpnStaff: () => dispatch(getLpnStaff()),
  updateLpnPatientLoad: (input, self) =>
    dispatch(updateLpnPatientLoad(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(LpnList);
