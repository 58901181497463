import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { changeRenderDate } from "../action/chartAction";
import { changeCalendarData, getFootStatus } from "../action/patientAction";
import PatientCharts from "../chart/patientCharts";
import "./calendarRange.css";
import "./MedicalDetailsHome.css";
import { Switch } from "antd";
import PatientDetailUrlContext from "../MedicalDashboard/medicaldetails/PatientDetailUrlContext";

class calendarRange extends Component {
  static contextType = PatientDetailUrlContext;

  constructor(props) {
    super(props);
    this.state = {
      rangeDate: [],
      showDiff: process.env.REACT_APP_ENV === "staging"
    };
    this.DELAY = 24 * 60 * 60 * 1000;
  }

  // component maybe lazy load, so both of `componentDidMount` & `componentDidUpdate` should be implemented
  componentDidMount() {
    this.initData();
  }

  componentDidUpdate() {
    if (this.oldContext !== this.context) {
      this.oldContext = this.context;

      this.initData();
    }
  }

  initData = () => {
    // in case of being repeatly called
    this.oldContext = this.context;

    const { id, userId, startDate, endDate } = this.context.param;

    const dateStartDate = moment(startDate).toDate();
    const dateEndDate = moment(endDate).toDate();
    this.setState({
      rangeDate: [dateStartDate, dateEndDate]
    });

    this.props.changeCalendarData(
      id,
      userId,
      moment(startDate)
        .startOf("month")
        .add(-6, "days")
        .format("YYYY-MM-DD"),
      moment(endDate)
        .endOf("month")
        .add(5, "days")
        .format("YYYY-MM-DD")
    );

    this.props.fetchChartData(this, id, userId, [dateStartDate, dateEndDate]);
    if (dateStartDate === dateEndDate) {
      this.props.getFootStatus(id, userId, dateEndDate, dateEndDate);
    }
  };

  goPreDay = () => {
    const prevDate = moment(this.state.rangeDate[0]).add(-1, "days");
    const newDateRange = [prevDate.toDate(), prevDate.toDate()];
    this.setState({ rangeDate: newDateRange }, this.fetchData);
  };

  goNextDay = () => {
    const nextDate = moment(this.state.rangeDate[1]).add(1, "days");
    const newDateRange = [nextDate.toDate(), nextDate.toDate()];
    this.setState({ rangeDate: newDateRange }, this.fetchData);
  };

  onChangeDateRange = rangeDate => {
    this.setState({ rangeDate }, this.fetchData);
  };

  // fetch data in `componentDidUpdate`
  fetchData = () => {
    const { id, userId } = this.context.param;
    const rangeDate = this.state.rangeDate;

    // const {
    //   fetchChartData,
    // } = this.props;

    // fetchChartData(this, id, userId, rangeDate);
    const calenDate = [
      moment(rangeDate[0]).format("YYYY-MM-DD"),
      moment(rangeDate[1]).format("YYYY-MM-DD")
    ];
    this.context.updateParam(
      Object.assign({}, this.context.param, {
        startDate: calenDate[0],
        endDate: calenDate[1]
      })
    );
  };

  turnChange = ({ activeStartDate, view }) => {
    const { id, userId } = this.context.param;
    const { changeCalendarData } = this.props;
    const startDate = moment(activeStartDate)
      .startOf("month")
      .add(-6, "days")
      .format("YYYY-MM-DD");
    const endDate = moment(activeStartDate)
      .endOf("month")
      .add(5, "days")
      .format("YYYY-MM-DD");
    changeCalendarData(id, userId, startDate, endDate);
  };

  daterangeClassName = () => ({ date, view }) => {
    let getDate = moment(date).format("YYYY-MM-DD");
    let activemonth = new Date().getMonth() + 1;
    const dispensedDay = this.props.doctorDetails.dispensedDay
      ? moment(this.props.doctorDetails.dispensedDay).format("YYYY-MM-DD")
      : moment(this.props.doctorDetails.createdAt).format("YYYY-MM-DD");
    if (getDate < dispensedDay) {
      return "";
    }
    if (
      this.props.calendardata &&
      this.props.calendardata.hasOwnProperty("footStatuses")
    ) {
      let markStatus = "";
      let markTimeWorn = "";
      _.forEach(this.props.calendardata.footStatuses, function(footStatus) {
        if (footStatus.date === getDate) {
          if (footStatus.overall === "NORMAL") {
            if (parseInt(footStatus.date.split("-")[1]) === activemonth) {
              markStatus = "normalmetabase2";
            } else {
              markStatus = "normalmetabase";
            }
          } else if (footStatus.overall === "YELLOW") {
            if (parseInt(footStatus.date.split("-")[1]) === activemonth) {
              markStatus = "yellowmetabase2";
            } else {
              markStatus = "yellowmetabase";
            }
          } else if (footStatus.overall === "RED") {
            if (parseInt(footStatus.date.split("-")[1]) === activemonth) {
              markStatus = "redmetabase2";
            } else {
              markStatus = "redmetabase";
            }
          }
        }
      });
      if (markStatus !== "") {
        return markStatus;
      }

      _.forEach(this.props.calendardata.footStatuses, function(item) {
        if (
          item.date.split("T")[0] === getDate &&
          Number(item.minuteWorn) >= 30
        ) {
          return (markTimeWorn = getDate);
        }
      });
      if (markTimeWorn !== "") {
        return "timeMore";
      }
    }
  };

  render() {
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <Button
            basic
            className="change_calenDay_button"
            onClick={this.goPreDay}
          >
            <Icon name="angle left" />
            Previous day
          </Button>
          <DateRangePicker
            onChange={this.onChangeDateRange}
            value={this.state.rangeDate}
            tileClassName={this.daterangeClassName()}
            clearIcon=""
            minDetail="month"
            locale="en"
            onActiveDateChange={this.turnChange}
          />
          <Button
            basic
            className="change_calenDay_button"
            onClick={this.goNextDay}
          >
            Next day
            <Icon name="angle right" />
          </Button>
          {process.env.REACT_APP_ENV === "staging" && (
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={this.state.showDiff}
              onChange={checked => this.setState({ showDiff: checked })}
            />
          )}
        </div>
        <PatientCharts
          userId={this.context.param.userId}
          showDiff={
            process.env.REACT_APP_ENV === "staging" && this.state.showDiff
          }
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  calendardata: state.patientsListStore.calendardata,
  footStatus: state.patientsListStore.footStatus
});
const mapDispatchToProp = dispatch => ({
  changeCalendarData: (id, userId, startDate, endDate) =>
    dispatch(changeCalendarData(id, userId, startDate, endDate)),
  fetchChartData: (self, id, userId, dateRender) =>
    dispatch(changeRenderDate(self, id, userId, dateRender)),
  getFootStatus: (id, userId, start, end) =>
    dispatch(getFootStatus(id, userId, start, end))
});
export default connect(mapStateToProp, mapDispatchToProp)(calendarRange);
