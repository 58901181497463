import {
  SortAscendingOutlined,
  SortDescendingOutlined
} from "@ant-design/icons";
import {
  Checkbox,
  Col,
  Divider,
  Icon,
  Modal,
  Popover,
  Row,
  Select,
  Tag
} from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import { connect } from "react-redux";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Popup } from "semantic-ui-react";
import {
  getlistClinicSites,
  listLPNForPatients,
  updateLPNFilter
} from "../action/registerAction";
import DateInput from "../common/DateInput";
import {
  sirenStaffingTableDefaultConfig,
  sirenStaffingTableName
} from "../common/TableFilterCustomize";
import {
  COLOR,
  DeviceOptions,
  ProgramOptions
} from "../constant/programDeviceStatus";
import {
  getSirenStaffingStat,
  sirenStaffingPatientListAPI
} from "../graphql/API";
import ChangeSpecialCase from "../home/changeSpecialCase";
import CircleColor from "../home/circle-color";
import RpmDeviceStatus from "../home/RpmDeviceStatus";
import RtmDeviceStatus from "../home/RtmDeviceStatus";
import { updateSirenStaffingTableFilter } from "../reducers/tableFilterReducer";
import "../share/common.css";
import DeviceStatusDesc from "../tools/device-status-desc";
import ProgramStatusDesc from "../tools/program-status-desc";
import getPatchTrProps, {
  dateStringToRange,
  InputEnterKeyTriggerFilterComponent,
  transformPatientTableParams
} from "../tools/reactTablePatch";
import "./biMonthly.css";
import "./selectComponent.css";

const ReactTableFixedColumns = withFixedColumns(ReactTable);
const { Option } = Select;
const NOT_EXIST_EMAIL = "emailWhichNotExist";
const OPEN_NEW_WINDOW = require("../img/open_new_window.png");

/**
 * 页面加载流程：
 * 首先做若干个请求，不做tableData请求。
 * listLPNForPatients 请求返回时，我们根据 currentLPN 的数据更新 this.state.tableSetting，手动请求 patientList
 */
class SirenStaffing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      onboardingCount: 0,
      medicalReviewCount: 0,
      photoReviewCount: 0,
      complianceCount: 0,
      specialCaseCount: 0,
      followUpCount: 0,
      symptomCount: 0,
      dataUpdateCount: 0,
      noneCount: 0,
      showFilter: false,
      backupFor: [],
      filterBackup: [],
      currentLPN: {},
      patientLoading: false,
      patientData: [],
      patientTotalSize: 0,
      patientPageIndex: 0,
      patientPageSize: 20,
      patientError: null,
      allPrimarySites: [],
      rowClickedData: null,
      rpmSort: null,
      rtmSort: null,
      pcmSort: null,
      ccmSort: null,
      socksSort: null,
      moveSort: null
    };

    this.tableInstance = null;
    this.debounceSearchPatientReq = false;
  }

  componentDidMount() {
    const queryParams = queryString.parseUrl(
      window.location.hash.substring(1),
      { parseNumbers: true }
    );
    this.setState({
      patientPageIndex: queryParams.query.pageIndex || 0,
      patientPageSize: queryParams.query.pageSize || 20
    });

    const { getlistClinicSites, listLPNForPatients } = this.props;
    if (this.props.clinicSites.length === 0) {
      Promise.all([getlistClinicSites(), listLPNForPatients()]).then(
        this.updateCustomizeSetting
      );
    } else {
      this.setState({
        patientLoading: true
      });
      listLPNForPatients().then(this.updateCustomizeSetting);
    }
  }

  componentWillUnmount() {
    // save table filter to server. check if this.state.currentLPN exist, in case `listLPNForPatients` req not finished
    if (this.state.currentLPN.id) {
      const currentLPN = this.props.listAdminStaff.find(
        e => e.id === this.props.userId
      );
      if (!currentLPN) {
        return;
      }
      if (currentLPN.filterColumns) {
        const allTableSetting = JSON.parse(currentLPN.filterColumns);
        const savedTableSetting = allTableSetting[sirenStaffingTableName];

        // table filter has no change
        if (_.isEqual(savedTableSetting, this.props.tableDefaultState)) {
          console.log("table filter has no change, skip upload to server");
          return;
        }
      }
      let filterColumns = {};
      if (this.state.currentLPN.filterColumns) {
        filterColumns = JSON.parse(this.state.currentLPN.filterColumns);
        // legacy code, filterColumns was array, only save sirenstaffing table. Now is a map, save multi table
        if (Array.isArray(filterColumns)) {
          filterColumns = {};
        }
      }
      filterColumns[sirenStaffingTableName] = this.props.tableDefaultState;

      let UpdateAdminStaffInput = {
        input: {
          id: this.state.currentLPN.id,
          filterColumns: JSON.stringify(filterColumns),
          filterBackup: JSON.stringify(this.state.filterBackup)
        }
      };

      this.props.updateLPNFilter(UpdateAdminStaffInput);
    }
  }

  updateUrlPath = () => {
    const queryParams = queryString.parseUrl(Control.path);
    queryParams.query.pageIndex = this.state.patientPageIndex;
    queryParams.query.pageSize = this.state.patientPageSize;

    Control.replace(
      `${queryParams.url}?${queryString.stringify(queryParams.query)}`
    );
  };

  updateCustomizeSetting = () => {
    const currentLPN = this.props.listAdminStaff.find(
      e => e.id === this.props.userId
    );
    if (!currentLPN) {
      return;
    }

    let bkFor = [];
    if (currentLPN.backupFor) {
      const bkIds = JSON.parse(currentLPN.backupFor);
      bkFor = bkIds
        .map(id => {
          const staff = this.props.listAdminStaff.find(e => e.id === id);
          if (staff) {
            return {
              id,
              name: `${staff.firstName} ${staff.lastName}`
            };
          } else {
            return null;
          }
        })
        .filter(e => !!e);
    }
    let tableSetting = [...sirenStaffingTableDefaultConfig];
    if (currentLPN.filterColumns) {
      const allTableSetting = JSON.parse(currentLPN.filterColumns);
      const savedTableSetting = allTableSetting[sirenStaffingTableName];
      // object shape is right.
      if (
        savedTableSetting &&
        savedTableSetting.length > 0 &&
        savedTableSetting[0].column
      ) {
        for (let index = 0; index < tableSetting.length; index++) {
          const element = tableSetting[index];
          const savedElement = savedTableSetting.find(
            e2 => e2.column === element.column
          );
          if (savedElement) {
            // fix type. if filter is String, change to Array<String>
            if (
              savedElement.column === "programStatus" ||
              savedElement.column === "deviceStatus"
            ) {
              if (typeof savedElement.filter === "string") {
                savedElement.filter = [savedElement.filter];
              }
            }
            tableSetting[index] = savedElement;
          }
        }
      }
      this.props.updateTableDefaultState(tableSetting);
    }

    this.setState(
      {
        currentLPN,
        backupFor: currentLPN.backupFor
          ? [
              {
                id: currentLPN.id,
                name: `${currentLPN.firstName} ${currentLPN.lastName}`
              },
              ...bkFor
            ]
          : [
              {
                id: currentLPN.id,
                name: `${currentLPN.firstName} ${currentLPN.lastName}`
              }
            ],
        filterBackup: currentLPN.filterBackup
          ? JSON.parse(currentLPN.filterBackup)
          : [currentLPN.id],
        allPrimarySites: currentLPN.primarySites
          ? JSON.parse(currentLPN.primarySites).sort(this.compareSiteId)
          : []
      },
      this.manualFetchData
    );
  };

  reqPatientList = (primarySites, lpns) => {
    this.debounceReqPatientList(primarySites, lpns);
    if (!this.debounceSearchPatientReq) {
      this.debounceReqPatientList.flush();
    }
  };

  debounceReqPatientList = _.debounce((primarySites, lpns) => {
    if (!this.tableInstance) {
      return;
    }

    // save table filter
    this.saveTableFilter();

    const { filter, sort, pagination } = transformPatientTableParams(
      this.tableInstance,
      (filter, key, value) => {
        if (key === "nurse") {
          const filterCondition = this.props.listAdminStaff
            .filter(item => {
              const fullName = `${item.firstName} ${item.lastName}`;
              return fullName.toLowerCase().includes(value.toLowerCase());
            })
            .map(item => ({ LPN: { eq: item.id } }));

          // Not found
          if (filterCondition.length === 0) {
            filterCondition.push({ doctorId: { eq: NOT_EXIST_EMAIL } });
          }
          if (filter.and) {
            filter.and.push({ or: filterCondition });
          } else {
            filter.and = [{ or: filterCondition }];
          }

          delete filter[key];
        } else if (key === "issue") {
          switch (value) {
            case "all":
              // NOPE
              break;
            case "Compliance":
              filter.category = {
                eq: value
              };
              // exclude PAUSED account
              if (!filter.and) {
                filter.and = [];
              }
              filter.and.push({
                or: [
                  {
                    and: [
                      {
                        rpmDeviceStatus: {
                          exists: true
                        }
                      },
                      {
                        rpmDeviceStatus: {
                          ne: "PAUSED"
                        }
                      },
                      {
                        rpmDeviceStatus: {
                          ne: "NOT_ON_SERVICE"
                        }
                      }
                    ]
                  },
                  {
                    and: [
                      {
                        rtmDeviceStatus: {
                          exists: true
                        }
                      },
                      {
                        rtmDeviceStatus: {
                          ne: "PAUSED"
                        }
                      },
                      {
                        rtmDeviceStatus: {
                          ne: "NOT_ON_SERVICE"
                        }
                      }
                    ]
                  }
                ]
              });

              break;
            case "Follow-up":
              if (!filter.and) {
                filter.and = [];
              }
              filter.and.push({
                followUpTimes: {
                  lte: moment()
                    .utcOffset(0, true)
                    .toISOString(true)
                }
              });
              break;
            case "None":
              // if accountStatus is 'PAUSED' and category only contains 'Compliance'
              if (!filter.or) {
                filter.or = [];
              }
              filter.or.push(
                {
                  and: [
                    {
                      rpmDeviceStatus: {
                        ne: "ACTIVE"
                      }
                    },
                    {
                      rpmDeviceStatus: {
                        ne: "IDLE"
                      }
                    },
                    {
                      rtmDeviceStatus: {
                        ne: "ACTIVE"
                      }
                    },
                    {
                      rtmDeviceStatus: {
                        ne: "IDLE"
                      }
                    },
                    {
                      not: {
                        category: {
                          match:
                            "Medical Review|Data Upload|Photo Review|Symptoms|Onboarding"
                        }
                      }
                    },
                    {
                      category: {
                        match: "Compliance"
                      }
                    }
                  ]
                },
                {
                  category: {
                    exists: false
                  }
                }
              );
              // exculde 'Follow Up'
              if (!filter.not) {
                filter.not = {};
              }
              filter.not.followUpTimes = {
                lte: moment()
                  .utcOffset(0, true)
                  .toISOString(true)
              };
              break;
            default:
              filter.category = {
                eq: value
              };
              break;
          }
          delete filter[key];
        } else if (key === "last4DaysWorn") {
          if (value === "all") {
            // NOPE
            delete filter[key];
          } else {
            filter["last4DaysWorn"] = {
              eq: parseInt(value)
            };
          }
        } else if (key === "siteId") {
          if (value === "all") {
            // NOPE
            if (filter.siteId && !filter.siteId.hasOwnProperty("match")) {
              delete filter[key];
            }
          } else {
            filter["siteId"] = {
              match: value
            };
          }
        } else if (key === "medicalStatus") {
          if (value === "all") {
            // NOPE
            delete filter[key];
          } else {
            delete filter[key];
            filter["overallStatus"] = {
              match: value === "GREEN" ? "NORMAL" : value
            };
          }
        } else if (key === "lastDMT") {
          filter["lastDMT"] = {
            range: dateStringToRange(value)
          };
        } else if (key === "lastServiceE") {
          filter["lastServiceE"] = {
            range: dateStringToRange(value)
          };
        } else if (key === "serviceE") {
          if (value === "all") {
            // NOPE
            delete filter[key];
          } else {
            if (value === "Yes") {
              filter["serviceE"] = {
                eq: `Yes/${moment().format("YYYY-MM")}`
              };
            } else {
              filter["serviceE"] = {
                ne: `Yes/${moment().format("YYYY-MM")}`
              };
            }
          }
        } else if (["ccmTime", "pcmTime", "rpmTime", "rtmTime"].includes(key)) {
          if (value === "all") {
            delete filter[key];
          } else if (value === "10") {
            filter[key] = {
              lt: parseInt(value)
            };
            filter["lastDMT"] = {
              range: dateStringToRange(moment().format("YYYY-MM"))
            };
          } else if (value === "50") {
            filter[key] = {
              gte: parseInt(value)
            };
            filter["lastDMT"] = {
              range: dateStringToRange(moment().format("YYYY-MM"))
            };
          } else {
            filter[key] = {
              range: [
                parseInt(value.split("-")[0]),
                parseInt(value.split("-")[1]) - 1
              ]
            };
            filter["lastDMT"] = {
              range: dateStringToRange(moment().format("YYYY-MM"))
            };
          }
        } else if (key === "followUpTimes") {
          filter["followUpTimes"] = {
            range: dateStringToRange(value)
          };
        } else if (key === "birthday") {
          // dob is special, filter by MM-dd
          if (/^\d\d[-/]\d\d$/.test(value)) {
            filter["birthday"] = {
              eq: moment(value, "MM-DD")
                .utcOffset(0, true)
                .toISOString(true)
            };
          }
        }
      }
    );

    if (sort) {
      if (sort.field === "nurse") {
        sort.field = "LPN.keyword";
      } else if (sort.field === "issue") {
        sort.field = "reviewStatus.keyword";
      } else if (sort.field === "medicalStatus") {
        sort.field = "overallStatus.keyword";
      } else if (sort.field === "totalTime") {
        sort.field = "totalMinutesSpent";
      }
    }

    // when refresh page, tableInstance can't provide correct value
    pagination.page = this.state.patientPageIndex;
    pagination.size = this.state.patientPageSize;
    this.updateUrlPath();
    this.rawReqPatientList(filter, sort, pagination, primarySites, lpns);
  }, 3000);

  rawReqPatientList(filter, sort, pagination, primarySites, lpns) {
    this.debounceSearchPatientReq = false;
    this.setState({
      patientLoading: true,
      patientError: null
    });
    // only onStaffing sites
    if (!primarySites) {
      primarySites = JSON.stringify(this.props.clinicSites.map(v => v.siteId));
    } else {
      const tmpSites = JSON.parse(primarySites);
      primarySites = tmpSites.filter(id =>
        this.props.clinicSites.find(v => v.siteId === id)
      );
      primarySites = JSON.stringify(primarySites);
    }
    getSirenStaffingStat(primarySites, lpns).then(stat => {
      this.setState(stat);
    });
    sirenStaffingPatientListAPI(filter, sort, pagination, primarySites, lpns)
      .then(data => {
        let patiens = data.data.lambdaSearchPatients.items;
        let totalSize = data.data.lambdaSearchPatients.total;
        this.setState({
          patientData: _.compact(patiens),
          patientTotalSize: totalSize
        });
      })
      .catch(error => {
        this.setState({
          patientError: error
        });
      })
      .finally(() => {
        this.setState({
          patientLoading: false
        });
      });
  }

  programSort = program => {
    const nextSort = {
      asc: "desc",
      desc: null
    };
    let rpmSort = null,
      rtmSort = null,
      pcmSort = null,
      ccmSort = null;
    switch (program) {
      case "RPM":
        rpmSort = !this.state.rpmSort ? "asc" : nextSort[this.state.rpmSort];
        break;
      case "RTM":
        rtmSort = !this.state.rtmSort ? "asc" : nextSort[this.state.rtmSort];
        break;
      case "PCM":
        pcmSort = !this.state.pcmSort ? "asc" : nextSort[this.state.pcmSort];
        break;
      case "CCM":
        ccmSort = !this.state.ccmSort ? "asc" : nextSort[this.state.ccmSort];
        break;
      default:
        break;
    }
    this.setState(
      {
        rpmSort,
        rtmSort,
        pcmSort,
        ccmSort
      },
      () => {
        this.tableInstance.sortColumn({
          id: `${program.toLowerCase()}ProgramStatus`
        });
      }
    );
  };

  deviceSort = device => {
    const deviceName2Code = {
      socks: "rpm",
      move: "rtm"
    };
    const nextSort = {
      asc: "desc",
      desc: null
    };
    let socksSort = null,
      moveSort = null;
    switch (device) {
      case "Socks":
        socksSort = !this.state.socksSort
          ? "asc"
          : nextSort[this.state.socksSort];
        break;
      case "Move":
        moveSort = !this.state.moveSort ? "asc" : nextSort[this.state.moveSort];
        break;
      default:
        break;
    }
    this.setState(
      {
        socksSort,
        moveSort
      },
      () => {
        this.tableInstance.sortColumn({
          id: `${deviceName2Code[device.toLowerCase()]}DeviceStatus`
        });
      }
    );
  };

  showAntdModal = () => {
    this.setState({
      showFilter: true
    });
  };

  hiddenAntdModal = () => {
    this.setState({
      showFilter: false
    });
  };

  saveFilters = () => {
    this.setState({ showFilter: false });
  };

  compareSiteId = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  onChangeBackup = value => {
    this.setState({ filterBackup: value });
    const sites = value
      .map(p => {
        const adminStaff = _(this.props.listAdminStaff).find({ id: p });
        return JSON.parse(adminStaff.primarySites).join("-");
      })
      .join("-");
    const allPrimarySites = _.compact(_.uniq(sites.split("-"))).sort(
      this.compareSiteId
    );
    if (value.length > 0) {
      this.setState({ allPrimarySites });
      this.reqPatientList(undefined, JSON.stringify(value));
    } else {
      this.setState({ patientData: [], allPrimarySites });
    }
  };

  saveTableFilter = () => {
    if (!this.tableInstance) {
      return;
    }
    const { state } = this.tableInstance;
    const nextTableSetting = [...this.props.tableDefaultState];
    for (let i = 0; i < nextTableSetting.length; i++) {
      const element = nextTableSetting[i];
      delete element.sort;
      delete element.filter;

      state.sorted.forEach(sort => {
        if (sort.id === element.column) {
          element.sort = sort.desc ? "desc" : "asc";
        }
      });
      state.filtered.forEach(filter => {
        if (filter.id === element.column) {
          element.filter = filter.value;
        }
      });
      state.resized.forEach(resize => {
        if (resize.id === element.column) {
          element.width = resize.value;
        }
      });
    }
    this.props.updateTableDefaultState(nextTableSetting);
  };

  onResetFilters = () => {
    this.setState({
      filterBackup: [this.state.currentLPN.id]
    });
    const nextTableSetting = [...this.props.tableDefaultState];
    for (let i = 0; i < nextTableSetting.length; i++) {
      const element = nextTableSetting[i];
      delete element.sort;
      delete element.filter;
      delete element.width;
    }
    this.props.updateTableDefaultState(nextTableSetting);
    this.setState(
      {
        patientPageIndex: 0
      },
      () => {
        this.manualFetchData();
      }
    );
  };

  onChangeFilterColumns = values => {
    const nextTableSetting = this.props.tableDefaultState.map(e => {
      if (e.fixed) {
        return e;
      }
      e.show = values.includes(e.column);
      return e;
    });
    this.props.updateTableDefaultState(nextTableSetting);
  };

  tableSorts = () => {
    return this.props.tableDefaultState
      .filter(element => element.show && element.sort)
      .map(element => ({ id: element.column, desc: element.sort === "desc" }));
  };

  tableFilters = () => {
    return this.props.tableDefaultState
      .filter(element => element.show && element.filter)
      .map(element => ({ id: element.column, value: element.filter }));
  };

  tableColumns = () => {
    const leftFixedColumns = [];
    const floatColumns = [];
    this.props.tableDefaultState.forEach(element => {
      let columnProps = this.tableColumnsMap()[element.column];

      if (columnProps) {
        columnProps.accessor = element.column;
        columnProps.show = element.show;
        columnProps.width = element.width;

        if (element.fixed === "left") {
          leftFixedColumns.push(columnProps);
        } else {
          floatColumns.push(columnProps);
        }
      }
    });

    const columns = [
      {
        Header: "Name",
        fixed: "left",
        columns: leftFixedColumns
      },
      {
        Header: "Info",
        columns: floatColumns
      }
    ];

    return columns;
  };

  tableColumnsMap = () => ({
    issue: {
      Header: "Tasks",
      sortable: false,
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All Patients</Option>
          {[
            "Compliance",
            "Data Update",
            "Follow-up",
            "Medical Review",
            "Onboarding",
            "Photo Review",
            "Symptoms",
            "None"
          ].map((e, index) => (
            <Option value={e} key={index}>
              {e}
            </Option>
          ))}
        </Select>
      ),
      Cell: props => {
        let tasks = _.clone(props.original.category);
        if (!tasks) {
          tasks = [];
        }
        if (tasks.includes("Compliance")) {
          if (
            props.original.rpmDeviceStatus &&
            props.original.rtmDeviceStatus
          ) {
            if (
              props.original.rpmDeviceStatus !== "ACTIVE" &&
              props.original.rpmDeviceStatus !== "IDLE" &&
              props.original.rtmDeviceStatus !== "ACTIVE" &&
              props.original.rtmDeviceStatus !== "IDLE"
            ) {
              _.remove(tasks, function(t) {
                return t === "Compliance";
              });
            }
          } else if (props.original.rpmDeviceStatus) {
            if (
              props.original.rpmDeviceStatus !== "ACTIVE" &&
              props.original.rpmDeviceStatus !== "IDLE"
            ) {
              _.remove(tasks, function(t) {
                return t === "Compliance";
              });
            }
          } else if (props.original.rtmDeviceStatus) {
            if (
              props.original.rtmDeviceStatus !== "ACTIVE" &&
              props.original.rtmDeviceStatus !== "IDLE"
            ) {
              _.remove(tasks, function(t) {
                return t === "Compliance";
              });
            }
          }
        }
        if (tasks.length === 0) {
          tasks.push("None");
        }
        if (tasks.length > 1) {
          return (
            <Popover
              content={
                <div>
                  {tasks.map((e, index) => (
                    <p key={index}>{e}</p>
                  ))}
                </div>
              }
              trigger="hover"
            >
              {tasks.join(", ")}
            </Popover>
          );
        } else {
          return tasks.join(", ");
        }
      }
    },
    firstName: {
      Header: "First Name"
    },
    lastName: {
      Header: "Last Name"
    },
    birthday: {
      Header: "DOB",
      Filter: args => this.DOBdateInputComponent(args)
    },
    sirenId: {
      Header: "SirenID"
    },
    doctorName: {
      Header: "Doctor"
    },
    siteName: {
      Header: "Site Name"
    },
    siteId: {
      Header: "SiteID",
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All</Option>
          {this.state.currentLPN.primarySites &&
          (this.state.currentLPN.group === "staff" ||
            this.state.currentLPN.group === "sales")
            ? this.state.allPrimarySites.map((gr, i) => {
                return (
                  <Option value={gr} key={"gr" + i}>
                    {gr}
                  </Option>
                );
              })
            : this.props.clinicSites.map((gr, i) => {
                return (
                  <Option value={gr.siteId} key={"gr" + i}>
                    {gr.siteId}
                  </Option>
                );
              })}
        </Select>
      )
    },
    patientHasSmartPhone: {
      Header: "Smartphone",
      maxWidth: 200,
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All</Option>
          <Option value="Yes">Yes</Option>
          <Option value="No">No</Option>
          <Option value="Caregiver">Caregiver</Option>
        </Select>
      )
    },
    nurse: {
      Header: "Nurse",
      accessor: "LPN",
      Cell: props => {
        const nurseInfo = this.props.listAdminStaff.find(
          e => e.id === props.original.LPN
        );
        if (nurseInfo) {
          return `${nurseInfo.firstName} ${nurseInfo.lastName}`;
        } else {
          return (
            <>
              <Icon
                type="warning"
                theme="filled"
                style={{ color: "#faad14" }}
              />
              <del>{props.original.LPN}</del>
            </>
          );
        }
      }
    },
    lastDMT: {
      Header: "Last DMT",
      Filter: args => this.dateInputComponent(args)
    },
    followUpTimes: {
      Header: "Follow-up Date",
      accessor: "followUpTimes",
      Cell: props => {
        return props.original.followUpTimes ? (
          <Popup
            trigger={
              <p className="sr-text-ellipsis">
                {props.original.followUpTimes.join(",")}
              </p>
            }
          >
            {props.original.followUpTimes.map((name, index) => (
              <p key={index}>{name}</p>
            ))}
          </Popup>
        ) : (
          ""
        );
      },
      Filter: args => this.dateInputComponent(args)
    },
    serviceE: {
      Header: "Service E",
      sortable: false,
      // filterable: false,
      maxWidth: 100,
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All</Option>
          <Option value="Yes">Yes</Option>
          <Option value="No">No</Option>
        </Select>
      )
    },
    lastServiceE: {
      Header: "Service E Date",
      Filter: args => this.dateInputComponent(args)
    },
    ccmTime: {
      Header: "CCM Total Time (mins)",
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All</Option>
          <Option value="10"> {"< 10"} </Option>
          <Option value="10-20"> {"10 - 20"} </Option>
          <Option value="20-30">{"20 - 30"}</Option>
          <Option value="30-40">{"30 - 40"}</Option>
          <Option value="40-50">{"40 - 50"}</Option>
          <Option value="50"> {">= 50"}</Option>
        </Select>
      ),
      Cell: props => {
        if (
          props.original.lastDMT &&
          moment(props.original.lastDMT).isSame(new Date(), "month")
        ) {
          return props.value || 0;
        }
        return 0;
      }
    },
    pcmTime: {
      Header: "PCM Total Time (mins)",
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All</Option>
          <Option value="10"> {"< 10"} </Option>
          <Option value="10-20"> {"10 - 20"} </Option>
          <Option value="20-30">{"20 - 30"}</Option>
          <Option value="30-40">{"30 - 40"}</Option>
          <Option value="40-50">{"40 - 50"}</Option>
          <Option value="50"> {">= 50"}</Option>
        </Select>
      ),
      Cell: props => {
        if (
          props.original.lastDMT &&
          moment(props.original.lastDMT).isSame(new Date(), "month")
        ) {
          return props.value || 0;
        }
        return 0;
      }
    },
    rpmTime: {
      Header: "RPM Total Time (mins)",
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All</Option>
          <Option value="10"> {"< 10"} </Option>
          <Option value="10-20"> {"10 - 20"} </Option>
          <Option value="20-30">{"20 - 30"}</Option>
          <Option value="30-40">{"30 - 40"}</Option>
          <Option value="40-50">{"40 - 50"}</Option>
          <Option value="50"> {">= 50"}</Option>
        </Select>
      ),
      Cell: props => {
        if (
          props.original.lastDMT &&
          moment(props.original.lastDMT).isSame(new Date(), "month")
        ) {
          return props.value || 0;
        }
        return 0;
      }
    },
    rtmTime: {
      Header: "RTM Total Time (mins)",
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All</Option>
          <Option value="10"> {"< 10"} </Option>
          <Option value="10-20"> {"10 - 20"} </Option>
          <Option value="20-30">{"20 - 30"}</Option>
          <Option value="30-40">{"30 - 40"}</Option>
          <Option value="40-50">{"40 - 50"}</Option>
          <Option value="50"> {">= 50"}</Option>
        </Select>
      ),
      Cell: props => {
        if (
          props.original.lastDMT &&
          moment(props.original.lastDMT).isSame(new Date(), "month")
        ) {
          return props.value || 0;
        }
        return 0;
      }
    },
    medicalStatus: {
      Header: "Medical Status",
      minWidth: 120,
      Cell: props => {
        return (
          <div onClick={e => e.stopPropagation()}>
            <span
              className="spanonclick"
              onClick={() =>
                this.gotoDoctorDetailPage(
                  props.original.id,
                  props.original.userId,
                  props.original.lastSeen
                )
              }
            >
              {props.original.medicalStatus}
            </span>
          </div>
        );
      },
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All</Option>
          <Option value="GREEN">GREEN</Option>
          <Option value="RED">RED</Option>
          <Option value="YELLOW">YELLOW</Option>
        </Select>
      )
    },
    last4DaysWorn: {
      Header: "Days Worn (Last 4 days)",
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All</Option>
          <Option value="0">0</Option>
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
          <Option value="4">4</Option>
        </Select>
      )
    },
    specialCase: {
      Header: "Special Case",
      minWidth: 130,
      Cell: props => {
        return (
          <div onClick={e => e.stopPropagation()}>
            <ChangeSpecialCase
              userId={props.original.userId}
              specialCaseStatus={props.original.specialCase}
              id={props.original.id}
              onChange={(status, specialCount) => {
                let patientData = [...this.state.patientData];
                patientData[props.index].specialCase = status;
                this.setState({
                  patientData,
                  specialCaseCount: this.state.specialCaseCount + specialCount
                });
              }}
            />
          </div>
        );
      },
      Filter: ({ filter, onChange }) => (
        <Select
          className="select_common_style"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          showSearch
          optionFilterProp="children"
        >
          <Option value="all">All</Option>
          <Option value="Yes">Yes</Option>
          <Option value="No">No</Option>
          <Option value="N/A">N/A</Option>
        </Select>
      )
    },
    programStatus: {
      style: {
        cursor: "pointer",
        textAlign: "left",
        paddingLeft: 10
      },
      Header: (
        <>
          <Popover placement="top" content={<ProgramStatusDesc />}>
            Program Status
          </Popover>
          <div
            style={{ cursor: "pointer", marginTop: 6 }}
            title="sort by program status"
          >
            {["RPM", "RTM", "PCM", "CCM"].map((program, index) => {
              const stateKey = `${program.toLowerCase()}Sort`;
              return (
                <React.Fragment key={program}>
                  {index ? <Divider type="vertical" /> : null}
                  <span onClick={() => this.programSort(program)}>
                    {program}
                    <SortAscendingOutlined
                      style={{
                        display:
                          this.state[stateKey] === "asc"
                            ? "inline-block"
                            : "none"
                      }}
                    />
                    <SortDescendingOutlined
                      style={{
                        display:
                          this.state[stateKey] === "desc"
                            ? "inline-block"
                            : "none"
                      }}
                    />
                  </span>
                </React.Fragment>
              );
            })}
          </div>
        </>
      ),
      accessor: "programStatus",
      minWidth: 220,
      sortable: false,
      Cell: props => {
        return (
          <div>
            {props.original.rpmProgramStatus && (
              <Tag color={COLOR[props.original.rpmProgramStatus]} key={"RPM"}>
                {"RPM"}
              </Tag>
            )}
            {props.original.rtmProgramStatus && (
              <Tag color={COLOR[props.original.rtmProgramStatus]} key={"RTM"}>
                {"RTM"}
              </Tag>
            )}
            {props.original.pcmProgramStatus && (
              <Tag color={COLOR[props.original.pcmProgramStatus]} key={"PCM"}>
                {"PCM"}
              </Tag>
            )}
            {props.original.ccmProgramStatus && (
              <Tag color={COLOR[props.original.ccmProgramStatus]} key={"CCM"}>
                {"CCM"}
              </Tag>
            )}
          </div>
        );
      },
      Filter: ({ filter, onChange }) => (
        <Select
          mode="multiple"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter && filter.value}
          placeholder="All"
          showSearch
          optionLabelProp="label"
        >
          {ProgramOptions.map(e => (
            <Option
              value={e.value}
              key={e.value}
              label={<Tag color={e.color}>{e.tagText}</Tag>}
            >
              <CircleColor color={e.color} />
              {e.text}
            </Option>
          ))}
        </Select>
      )
    },
    deviceStatus: {
      style: {
        textAlign: "left",
        paddingLeft: 15
      },
      Header: (
        <>
          <Popover placement="topLeft" content={<DeviceStatusDesc />}>
            Device Status
          </Popover>
          <div
            style={{ cursor: "pointer", marginTop: 6 }}
            title="sort by device status"
          >
            {["Socks", "Move"].map((device, index) => {
              const stateKey = `${device.toLowerCase()}Sort`;
              return (
                <React.Fragment key={device}>
                  {index ? <Divider type="vertical" /> : null}
                  <span onClick={() => this.deviceSort(device)}>
                    {device}
                    <SortAscendingOutlined
                      style={{
                        display:
                          this.state[stateKey] === "asc"
                            ? "inline-block"
                            : "none"
                      }}
                    />
                    <SortDescendingOutlined
                      style={{
                        display:
                          this.state[stateKey] === "desc"
                            ? "inline-block"
                            : "none"
                      }}
                    />
                  </span>
                </React.Fragment>
              );
            })}
          </div>
        </>
      ),
      accessor: "deviceStatus",
      minWidth: 200,
      sortable: false,
      Cell: props => {
        return (
          <div onClick={e => e.stopPropagation()}>
            {props.original.rpmDeviceStatus && (
              <RpmDeviceStatus
                deviceStatus={props.original.rpmDeviceStatus}
                userId={props.original.userId}
                id={props.original.id}
                userInfo={props.original}
                onChange={status => {
                  let patientData = [...this.state.patientData];
                  patientData[props.index].rpmDeviceStatus = status;
                  this.setState({ patientData });
                }}
              />
            )}
            {props.original.rtmDeviceStatus && (
              <RtmDeviceStatus
                deviceStatus={props.original.rtmDeviceStatus}
                userId={props.original.userId}
                id={props.original.id}
                userInfo={props.original}
                onChange={status => {
                  let patientData = [...this.state.patientData];
                  patientData[props.index].rtmDeviceStatus = status;
                  this.setState({ patientData });
                }}
              />
            )}
          </div>
        );
      },
      Filter: ({ filter, onChange }) => (
        <Select
          mode="multiple"
          onChange={onChange}
          style={{ width: "100%" }}
          value={filter && filter.value}
          showSearch
          placeholder="All"
          optionLabelProp="label"
        >
          {DeviceOptions.map(e => (
            <Option
              value={e.value}
              key={e.value}
              label={<Tag color={e.color}>{e.tagText}</Tag>}
            >
              <CircleColor color={e.color} />
              {e.text}
            </Option>
          ))}
        </Select>
      )
    }
  });

  gotoDoctorDetailPage(id, userId, date) {
    Control.go(
      "/MedicalDetailsHome?SirenStaffing/id=" +
        id +
        "&userId=" +
        userId +
        "&startDate=" +
        date +
        "&endDate=" +
        date,
      {
        patientList: this.state.patientData.map(p => ({
          id: p.id,
          userId: p.userId
        }))
      }
    );
  }

  openPatientDetailNewWindow = (id, userId, date) => {
    const goUrl = `${
      window.location.href.split("SirenStaffing")[0]
    }MedicalDetailsHome?SirenStaffing/id=${id}&userId=${userId}&startDate=${date}&endDate=${date}`;
    window.open(goUrl);
  };

  dateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
        defaultValue={filter ? filter.value : null}
        onPressEnter={value => {
          this.debounceSearchPatientReq = false;
          onChange(value);
        }}
        onChange={value => {
          this.debounceSearchPatientReq = true;
          onChange(value);
        }}
      />
    );
  };

  DOBdateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
        placeholder="(YYYY-)MM-DD"
        defaultValue={filter ? filter.value : null}
        pattern="\d{2}[-/]\d{2}|\d{4}([-/]\d{2}){0,2}"
        onPressEnter={value => {
          this.debounceSearchPatientReq = false;
          onChange(value);
        }}
        onChange={value => {
          this.debounceSearchPatientReq = true;
          onChange(value);
        }}
      />
    );
  };

  aweContextMenu = () => {
    return (
      <ContextMenu
        id="SIREN_STAFFING"
        style={{
          backgroundColor: "rgba(45,45,52,0.9)",
          width: 241,
          borderRadius: 5,
          position: "absolute",
          zIndex: 10
        }}
        onHide={() => {
          this.setState({ rowClickedData: null });
        }}
      >
        {/*<MenuItem*/}
        {/*  onClick={() => {*/}
        {/*    this.state.rowClickedData !== null &&*/}
        {/*      this.gotoDoctorDetailPage(*/}
        {/*        this.state.rowClickedData.id,*/}
        {/*        this.state.rowClickedData.userId,*/}
        {/*        moment().format("YYYY-MM-DD")*/}
        {/*      );*/}
        {/*    this.setState({ rowClickedData: null });*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      textAlign: "left",*/}
        {/*      color: "white",*/}
        {/*      marginLeft: 10,*/}
        {/*      marginTop: 8,*/}
        {/*      height: 30,*/}
        {/*      cursor: "pointer"*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    View detail*/}
        {/*  </div>*/}
        {/*</MenuItem>*/}
        {/*<div*/}
        {/*  style={{*/}
        {/*    marginLeft: 10,*/}
        {/*    width: 215,*/}
        {/*    height: 1,*/}
        {/*    backgroundColor: "rgba(209,209,213,0.5)"*/}
        {/*  }}*/}
        {/*/>*/}
        <MenuItem
          onClick={() => {
            this.state.rowClickedData !== null &&
              this.openPatientDetailNewWindow(
                this.state.rowClickedData.id,
                this.state.rowClickedData.userId,
                moment().format("YYYY-MM-DD")
              );
            this.setState({ rowClickedData: null });
          }}
        >
          <div
            style={{
              textAlign: "left",
              color: "white",
              marginLeft: 10,
              marginTop: 8,
              height: 30,
              cursor: "pointer"
            }}
          >
            <img
              src={OPEN_NEW_WINDOW}
              style={{ width: 15, height: 15, marginRight: 10 }}
            />
            Open in new window
          </div>
        </MenuItem>
      </ContextMenu>
    );
  };

  renderStatSection() {
    let statProps = [
      {
        key: "totalCount",
        title: "All Patients",
        style: { marginLeft: 0 }
      },
      {
        key: "specialCaseCount",
        title: "Special Case",
        filterAction: filter => {
          if (filter.column === "specialCase") {
            filter.filter = "Yes";
            filter.show = true;
          }
        }
      },
      {
        key: "onboardingCount",
        title: "Onboarding",
        filterAction: filter => {
          if (filter.column === "issue") {
            filter.filter = "Onboarding";
          }
        }
      },
      {
        key: "followUpCount",
        title: "Follow-ups",
        filterAction: filter => {
          if (filter.column === "issue") {
            filter.filter = "Follow-up";
          }
        }
      },
      {
        key: "dataUpdateCount",
        title: "Data Update",
        filterAction: filter => {
          if (filter.column === "issue") {
            filter.filter = "Data Update";
          }
        }
      },
      {
        key: "medicalReviewCount",
        title: "Medical Review",
        filterAction: filter => {
          if (filter.column === "issue") {
            filter.filter = "Medical Review";
          }
        }
      },
      {
        key: "photoReviewCount",
        title: "Photo Review",
        filterAction: filter => {
          if (filter.column === "issue") {
            filter.filter = "Photo Review";
          }
        }
      },
      {
        key: "complianceCount",
        title: "Compliance",
        filterAction: filter => {
          if (filter.column === "issue") {
            filter.filter = "Compliance";
          }
        }
      },
      {
        key: "symptomCount",
        title: "Symptoms from App",
        filterAction: filter => {
          if (filter.column === "issue") {
            filter.filter = "Symptoms";
          }
        }
      },
      {
        key: "noneCount",
        title: "None",
        filterAction: filter => {
          if (filter.column === "issue") {
            filter.filter = "None";
          }
        }
      }
    ];

    const onClick = s => {
      // this.setState({
      //   filterBackup: [this.state.currentLPN.id]
      // });
      const nextTableSetting = [...this.props.tableDefaultState];
      for (let i = 0; i < nextTableSetting.length; i++) {
        const element = nextTableSetting[i];
        delete element.filter;
        if (s.filterAction) {
          s.filterAction(element);
        }
      }
      this.props.updateTableDefaultState(nextTableSetting);
      this.setState(
        {
          patientPageIndex: 0
        },
        this.manualFetchData
      );
    };

    return (
      <div className="summary_des_container" style={{ whiteSpace: "nowrap" }}>
        {statProps.map(s => (
          <div
            className="summary_des_box"
            key={s.key}
            style={Object.assign(
              {
                width: s.key === "symptomCount" ? 150 : 120,
                cursor: "pointer"
              },
              s.style ? s.style : {}
            )}
            onClick={() => onClick(s)}
          >
            <div style={{ marginTop: 15 }}>
              <p className="summary_des_number" style={{ textAlign: "center" }}>
                {this.state[s.key]}
              </p>
              <p className="summary_des_title">{s.title}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderTableSettingModal() {
    return (
      <Modal
        title={<div style={{ fontSize: 20, fontWeight: "bold" }}>Filters</div>}
        visible={this.state.showFilter}
        onOk={this.saveFilters}
        onCancel={this.hiddenAntdModal}
        okText="Save"
        cancelText="Cancel"
        centered
        closable={false}
      >
        {this.props.userGroup === "staff" ? (
          <div>
            <div style={{ fontSize: 18, fontWeight: "bold" }}>Nurses</div>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={this.state.filterBackup}
              onChange={this.onChangeBackup}
            >
              <Row style={{ marginLeft: 20 }}>
                {this.state.backupFor.map((p, i) => {
                  return (
                    <Col span={30} key={`staff${i}`} style={{ marginTop: 5 }}>
                      <Checkbox value={p.id}>{p.name}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </div>
        ) : null}
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            marginTop: this.props.userGroup === "staff" ? 30 : 0
          }}
        >
          Columns
        </div>
        <Checkbox.Group
          style={{ width: "100%" }}
          value={this.props.tableDefaultState
            .filter(e => e.show && e.fixed !== "left")
            .map(e => e.column)}
          onChange={this.onChangeFilterColumns}
        >
          <Row style={{ marginLeft: 20 }}>
            {this.props.tableDefaultState
              .filter(e => e.fixed !== "left")
              .map((p, i) => {
                return (
                  <Col span={12} key={`columns${i}`} style={{ marginTop: 5 }}>
                    <Checkbox value={p.column}>
                      {p.column === "programStatus"
                        ? "Program Status"
                        : p.column === "deviceStatus"
                        ? "Device Status"
                        : this.tableColumnsMap()[p.column]
                        ? this.tableColumnsMap()[p.column].Header
                        : p.column}
                    </Checkbox>
                  </Col>
                );
              })}
          </Row>
        </Checkbox.Group>
      </Modal>
    );
  }

  manualFetchData = () => {
    // fetch data
    if (this.props.userGroup === "staff" || this.props.userGroup === "sales") {
      this.reqPatientList(
        this.props.userGroup === "sales"
          ? this.state.currentLPN.primarySites
          : undefined,
        this.props.userGroup === "staff"
          ? JSON.stringify(this.state.filterBackup)
          : undefined
      );
    } else if (
      this.props.userGroup === "admin" ||
      this.props.userGroup === "developer" ||
      this.props.userGroup === "support"
    ) {
      this.reqPatientList();
    }
  };

  renderTable() {
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(
        rowInfo.original.id,
        rowInfo.original.userId,
        moment().format("YYYY-MM-DD")
      );
    });

    return (
      <ReactTableFixedColumns
        innerRef={ref => {
          if (ref) {
            this.tableInstance = ref;
          }
        }}
        className="-striped -highlight"
        getTheadThProps={() => ({
          style: {
            paddingTop: 10,
            paddingBottom: 10
          }
        })}
        manual
        getTrProps={trProps}
        getTdProps={(state, rowInfo) => {
          return {
            onContextMenu: () => {
              this.setState({ rowClickedData: rowInfo.original });
            }
          };
        }}
        loading={this.state.patientLoading}
        minRows={0}
        filterable
        FilterComponent={InputEnterKeyTriggerFilterComponent({
          onChange: () => {
            this.debounceSearchPatientReq = true;
          },
          onPressEnter: () => {
            this.debounceSearchPatientReq = false;
          }
        })}
        defaultFiltered={this.tableFilters()}
        defaultSorted={this.tableSorts()}
        defaultPageSize={20}
        page={this.state.patientPageIndex}
        pageSize={this.state.patientPageSize}
        pages={
          !this.tableInstance
            ? -1
            : Math.ceil(
                this.state.patientTotalSize / this.state.patientPageSize
              )
        }
        onPageChange={pageIndex => {
          this.setState(
            {
              patientPageIndex: pageIndex
            },
            this.manualFetchData
          );
        }}
        onPageSizeChange={(pageSize, pageIndex) => {
          this.setState(
            {
              patientPageIndex: pageIndex,
              patientPageSize: pageSize
            },
            this.manualFetchData
          );
        }}
        onFilteredChange={() => {
          if (!this.debounceSearchPatientReq) {
            this.setState(
              {
                patientPageIndex: 0
              },
              this.manualFetchData
            );
          } else {
            this.manualFetchData();
          }
        }}
        onSortedChange={newSorted => {
          if (!newSorted[0].id.endsWith("ProgramStatus")) {
            this.setState({
              rpmSort: null,
              rtmSort: null,
              pcmSort: null,
              ccmSort: null
            });
          }
          if (!newSorted[0].id.endsWith("DeviceStatus")) {
            this.setState({
              socksSort: null,
              moveSort: null
            });
          }
          this.manualFetchData();
        }}
        onResizedChange={() => {
          this.debounceSearchPatientReq = true;
          this.manualFetchData();
        }}
        data={this.state.patientData}
        resolveData={data =>
          data.map(p => {
            // const serviceE = p.rpmInfo && p.rpmInfo.serviceE;
            const serviceE = p.serviceE
              ? p.serviceE.split("/")[1] === moment().format("YYYY-MM")
                ? p.serviceE.split("/")[0]
                : "No"
              : "No";
            let issue = p.category ? [...p.category] : [];
            if (p.followUpTimes) {
              const today = moment()
                .utcOffset(0, true)
                .toISOString(true);
              if (p.followUpTimes.find(t => t < today)) {
                issue.push("Follow-up");
              }
            }
            // a PAUSED account could not be Compliance
            if (p.accountStatus === "PAUSED") {
              issue = issue.filter(e => e !== "Compliance");
            }
            if (issue.length === 0) {
              issue.push("None");
            }
            issue.sort();

            const specialCase = p.specialCase ? p.specialCase : "N/A";
            const lastSeen = p.dataLastSeen ? p.dataLastSeen.split("T")[0] : "";
            const medicalStatus =
              p.overallStatus === "NORMAL" ? "GREEN" : p.overallStatus;
            return {
              ...p,
              serviceE,
              issue,
              specialCase,
              lastSeen,
              medicalStatus
            };
          })
        }
        columns={this.tableColumns()}
      />
    );
  }

  render() {
    return (
      <div>
        <div>
          <div
            className="tab_title_box tab_title report_des_container"
            style={{ height: 30 }}
          >
            <p className="tab_title_box">
              <strong className="tab_title" style={{ paddingLeft: 0 }}>
                Siren Staffing
              </strong>
            </p>
            <Icon
              type="reload"
              title="reset all filters"
              style={{
                position: "absolute",
                right: 40,
                top: 10
              }}
              onClick={this.onResetFilters}
            />
            <Icon
              type="setting"
              title="table setting"
              style={{
                position: "absolute",
                right: 10,
                top: 10
              }}
              onClick={this.showAntdModal}
            />
            {this.renderTableSettingModal()}
          </div>
        </div>
        {this.renderStatSection()}
        {/*{this.state.patientError && (*/}
        {/*  <Alert*/}
        {/*    message={JSON.stringify(this.state.patientError)}*/}
        {/*    type="error"*/}
        {/*    closable*/}
        {/*  />*/}
        {/*)}*/}
        <ContextMenuTrigger id="SIREN_STAFFING">
          {this.renderTable()}
        </ContextMenuTrigger>
        {this.aweContextMenu()}
      </div>
    );
  }
}

const mapStateToProp = state => ({
  userId: state.authGroupStore.profile.loginId,
  listAdminStaff: state.patientsListStore.listAdminStaff,
  clinicSites: state.patientsListStore.clinicSites.filter(
    v => v.onStaffing === "Yes"
  ),
  userGroup: state.patientsListStore.userGroup,
  userName: state.patientsListStore.userName,
  tableDefaultState: state.tableFilter.sirenStaffing
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  listLPNForPatients: () => dispatch(listLPNForPatients),
  updateLPNFilter: input => dispatch(updateLPNFilter(input)),
  updateTableDefaultState: state =>
    dispatch(updateSirenStaffingTableFilter(state))
});
export default connect(mapStateToProp, mapDispatchToProp)(SirenStaffing);
