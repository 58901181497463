import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { resetNewPatientPwd } from "../action/patientAction";
import "../share/button.css";
import ButtonSuccess from "./buttonSuccess";
class ReNewPatientPwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      showPassword: true,
      newPwd: "",
      haveLength: true
    };
    this.handleInputChangePwd = this.handleInputChangePwd.bind(this);
    this.changeShowPassword = this.changeShowPassword.bind(this);
    this.handleConfirm.bind(this);
  }
  changeShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleInputChangePwd(event) {
    this.setState({
      newPwd: event.target.value
    });
  }
  show = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      showPassword: true,
      newPwd: ""
    });
  };
  handleCancel = () =>
    this.setState({
      open: false,
      confirmSuccess: false
    });
  handleConfirm = () => {
    if (!_.isEmpty(this.state.newPwd)) {
      if (this.state.newPwd.length < 8) {
        this.setState({ haveLength: false });
      } else {
        this.setState({ haveLength: true });
        const { resetNewPatientPwd } = this.props;
        const newPwdInput = {
          input: {
            id: this.props.id,
            password: this.state.newPwd
          }
        };
        resetNewPatientPwd(newPwdInput, this);
        this.setState({ confirmSuccess: true });
      }
    }
  };
  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini">
            Reset Password
          </Button>
        }
        open={this.state.open}
        className="resetPwd_Modal"
      >
        <Modal.Header>Reset Password</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <form className="newSite_box">
              <div className="resetTitleBox">
                <p className="resetTip">Must be 8 or more characters.</p>
                <div className="totalBox">
                  <div className="resetPwd_Box">
                    <p className="resetPwd_Label">New Password</p>
                    <Input
                      placeholder="New Password"
                      className="resetPwd_input"
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.handleInputChangePwd}
                    />
                    <div className="clearFloat" />
                    <Icon
                      as="i"
                      name={this.state.showPassword ? "eye slash" : "eye"}
                      onClick={this.changeShowPassword}
                      className="resetPwd_eyeIcon"
                    />
                  </div>
                  <div className="resetPwd_Tip_Box">
                    <Icon
                      as="i"
                      name="check"
                      className="resetPwd_checkIcon"
                      style={{
                        color: "red",
                        visibility: this.state.haveLength ? "hidden" : "visible"
                      }}
                    />
                    <div className="clearFloat" />
                  </div>
                  <div
                    className="resetPwd_error_Tip"
                    style={{
                      color: "red",
                      visibility: this.state.haveLength ? "hidden" : "visible"
                    }}
                  >
                    {this.state.haveLength === false
                      ? "Minimum length is 8"
                      : "OK"}
                  </div>
                  <div className="clearFloat" />
                </div>
              </div>
            </form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({
  resetNewPatientPwd: (newPwdInput, self) =>
    dispatch(resetNewPatientPwd(newPwdInput, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(ReNewPatientPwd);
