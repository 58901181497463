import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Button, Checkbox } from "semantic-ui-react";
import {
  changeManualDoctorSelect,
  changeManualReportList,
  resetAllReport
} from "../action/doctorReportAction";
import { getlistClinicSites, getlistStaff } from "../action/registerAction";
import Progress from "../home/progress";
import "./ReportSite.css";
import SendReport from "./sendReport";
import ReportMenu from "./reportMenu";
Modal.setAppElement("div");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};
class ReportSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
    this.handleClear = this.handleClear.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  handleClear() {
    const { resetAllReport } = this.props;
    resetAllReport();
  }

  handleSiteCheck(siteId) {
    const { changeManualReportList } = this.props;
    changeManualReportList(this, siteId);
  }
  handleDocCheck(siteId, staff) {
    const { changeManualDoctorSelect } = this.props;
    changeManualDoctorSelect(this, siteId, staff.email);
  }
  componentDidMount() {
    const { getlistClinicSites, getlistStaff } = this.props;
    getlistClinicSites();
    getlistStaff();
  }
  render() {
    return (
      <div className="boxlistcontainer">
        <ReportMenu />
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <ReactTable
          data={
            this.props.clinicSites &&
            this.props.clinicSites.map(p => {
              return {
                ...p
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              columns: [
                {
                  Header: "Actions",
                  accessor: "action",
                  sortable: false,
                  filterable: false,
                  Cell: props => {
                    return (
                      <Checkbox
                        onChange={(e, data) =>
                          this.handleSiteCheck(props.original.siteId)
                        }
                        checked={this.props.reportManualList.hasOwnProperty(
                          props.original.siteId
                        )}
                        name="selectSite"
                      />
                    );
                  }
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Site Id",
                  accessor: "siteId",
                  filterMethod: (filter, row) =>
                    String(row[filter.id]).includes(filter.value)
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Name",
                  accessor: "name",
                  filterMethod: (filter, row) => {
                    let rowfilterfullname = row._original.name
                      ? row[filter.id].toLowerCase()
                      : "";
                    let filterfullname = filter.value.toLowerCase();
                    return rowfilterfullname.includes(filterfullname);
                  }
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Doctor",
                  //style: { overflow: "auto", textAlign: "left" },
                  accessor: "doctors",
                  sortable: false,
                  filterable: false,
                  Cell: props => {
                    return (
                      <div>
                        {this.props.listStaff
                          .filter(staff => staff.siteId.indexOf(props.original.siteId) !== -1)
                          .sort((a, b) => {
                            if (a.isPrescribing) {
                              if (b.isPrescribing) {
                                return a.lastName.localeCompare(b.lastName)
                              } else {
                                return -1
                              }
                            } else {
                              if (b.isPrescribing) {
                                return 1
                              } else {
                                return a.firstName.localeCompare(b.firstName)
                              }
                            }
                          })
                          .map((staff, i) => (
                            <Checkbox
                              key={"staff-" + i}
                              id={`Doctor_Checkbox${i}${props.row._index}`}
                              style={{float: 'left', margin: 12}}
                              checked={
                                !!(
                                  this.props.reportManualList.hasOwnProperty(
                                    props.original.siteId
                                  ) &&
                                  this.props.reportManualList[
                                    props.original.siteId
                                  ].includes(staff.email)
                                )
                              }
                              onChange={(e, data) =>
                                this.handleDocCheck(
                                  props.original.siteId,
                                  staff
                                )
                              }
                              name="selectDoc"
                              label={
                                staff.isPrescribing
                                  ? "Dr." + staff.lastName
                                  : staff.firstName
                              }
                            />)
                        )}
                      </div>
                    );
                  }
                }
              ]
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "siteId",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
        <div className="add_import_box" style={{ textAlign: "center" }}>
          <SendReport />
          <Button size="small" onClick={this.handleClear}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  listStaff: state.patientsListStore.listStaff,
  reportManualList: state.reportListStore.reportManualList
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getlistStaff: () => dispatch(getlistStaff()),
  resetAllReport: () => dispatch(resetAllReport()),
  changeManualReportList: (self, siteId) =>
    dispatch(changeManualReportList(self, siteId)),
  changeManualDoctorSelect: (self, siteId, doctorId) =>
    dispatch(changeManualDoctorSelect(self, siteId, doctorId))
});
export default connect(mapStateToProp, mapDispatchToProp)(ReportSite);
