import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import "./buttonSuccess.css";
class Success extends Component {
  render() {
    return (
      <div className="success_box">
        <Icon name="check circle" id="siteIcon" />
        <p className="siteTitle">Done!</p>
      </div>
    );
  }
}
export default Success;
