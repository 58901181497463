import React, { Component } from "react";
import ReactTable from "react-table";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { registerHub } from "../action/boxAction";
import { Modal as AntdModal, message, notification } from "antd";
import { registeredPatientListAPI } from "../graphql/API";
import "./registerbox.css";

export default class RegisterHub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      regiSuccess: false,
      searchSirenId: "",
      loadingPatient: false,
      loading: false,
      patient: []
    };
  }

  handleOpen = () => {
    if (this.props.item.canRegistered === "No") {
      AntdModal.warning({
        title: "Oops",
        content: `Hub ${this.props.item.hubId} has been paused, can not be registered.`,
        centered: true
      });
      return;
    }
    this.setState({ open: true });
  };
  close = () => this.setState({ open: false });
  searchPatient = () => {
    if (!this.state.searchSirenId) {
      message.warning('please input sirenId');
      return
    }

    this.setState({
      loadingPatient: true,
      patient: []
    })
    registeredPatientListAPI({
      sirenId: {
        eq: this.state.searchSirenId
      }
    }).then(res => {
      this.setState({
        loadingPatient: false
      });
      if (res.data.lambdaSearchPatients.total > 0) {
        this.setState({
          patient: res.data.lambdaSearchPatients.items
        });
      }
    }).catch(error => {
      this.setState({
        loadingPatient: false
      });
      // graphQL error
      if (error.errors) {
        notification.error({
          message: 'System Error',
          description: error.errors[0].message
        });
      }
    })
  };

  submit(value) {
    const creds = {
      userId: value.userId,
      serial: this.props.item.hubId,
      authcode: this.props.item.authcode
    };
    this.setState({
      loading: true
    })
    registerHub(creds).then((res) => {
      this.setState({
        loading: false
      })
      if (res.status) {
        this.setState({
          regiSuccess: true
        })
        // automatically close after success animation
        setTimeout(() => {
          this.setState({
            open: false
          })
          if (typeof this.props.onChange === "function") {
            this.props.onChange(creds);
          }
        }, 500)
      } else {
        let desc = null
        if (res.msg) {
          desc = res.msg
        } else if (res.error) {
          if (res.error.msg) {
            desc = res.error.msg
          } else if (res.error instanceof Error) {
            desc = res.error.message
          } else {
            desc = JSON.stringify(res.error)
          }
        } else {
          desc = JSON.stringify(res)
        }
        notification.error({
          message: 'System Error',
          description: desc
        });
      }
    }).catch(error => {
      this.setState({
        loading: false
      });
      if (error instanceof Error) {
        return notification.error({
          message: 'System Error',
          description: error.message
        });
      }
      // graphQL error
      if (error.errors) {
        notification.error({
          message: 'System Error',
          description: error.errors[0].message
        });
      }
    });
  }

  searchSirenIdChanged = event => {
    this.setState({ searchSirenId: event.target.value });
  };

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={<Button onClick={this.handleOpen}>Register</Button>}
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>
          {this.state.regiSuccess
            ? "Register the box success"
            : `Hub Registration`}
        </Modal.Header>
        <Modal.Content>
          {this.state.regiSuccess ? (
            <div className="regiSuccess">
              <Icon name="check circle" id="regiSuccessIcon" />
              <p className="regiSuccessTitle">Awesome!</p>
              <p className="regiSuccesstext">
                Hub has been successfully registered.
              </p>
            </div>
          ) : (
            <div>
              <div className="search_patient_container">
                <span className="search_label search_email_label">
                  SirenId:
                </span>
                <Input
                  placeholder="Search"
                  type="number"
                  onChange={this.searchSirenIdChanged}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      this.searchPatient()
                    }
                  }}
                  value={this.state.searchSirenId || ""}
                />
                <Icon
                  name="search"
                  size="large"
                  className="search_icon"
                  onClick={this.searchPatient}
                />
              </div>
              <ReactTable
                loading={this.state.loadingPatient}
                data={this.state.patient}
                columns={[
                  {
                    Header: "SirenID",
                    accessor: "sirenId"
                  },
                  {
                    Header: "Full Name",
                    accessor: "fullName",
                    Cell: props => `${props.original.firstName} ${props.original.lastName}`
                  },
                  {
                    Header: "Actions",
                    sortable: false,
                    filterable: false,
                    Cell: props => {
                      return (
                        <div>
                          <Button
                            loading={this.state.loading}
                            color="green"
                            onClick={e => this.submit(props.original)}
                          >
                            <Icon name="checkmark" />
                            Register Hub
                          </Button>
                        </div>
                      );
                    }
                  }
                ]}
                defaultSorted={[{ id: "sirenId", desc: false }]}
                defaultPageSize={10}
                className="-striped -highlight"
                minRows={0}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.regiSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
