import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { changeBilledPatient } from "../action/bliMonthyReportAction";
import ButtonSuccess from "../home/buttonSuccess";
import { limitDay, limitMonth, limitYear } from "../tools/dateFormat";
import "./changeDispensedDay.css";
class changeDispensedDay extends Component {
  constructor(props) {
    super(props);
    this.submit.bind(this);
    this.state = {
      open: false,
      dispensedMonth: "",
      dispensedDay: "",
      dispensedYear: "",
      emptyDispensed: false,
      confirmSuccess: false,
      monthErrorFormat: false,
      dayErrorFormat: false,
      yearErrorFormat: false
    };
  }
  handleOpen = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      dispensedMonth: this.props.dispensedDay
        ? this.props.dispensedDay.split("-")[1]
        : "",
      dispensedDay: this.props.dispensedDay
        ? this.props.dispensedDay.split("-")[2]
        : "",
      dispensedYear: this.props.dispensedDay
        ? this.props.dispensedDay.split("-")[0]
        : ""
    });
  };
  close = () => this.setState({ open: false, confirmSuccess: false });
  handleMonthChange = event => {
    this.setState({ dispensedMonth: event.target.value });
    if (event.target.value.length === 2) {
      document.getElementById("DispensedMonth").blur();
      document.getElementById("DispensedDay").focus();
    }
  };

  handleDayChange = event => {
    this.setState({ dispensedDay: event.target.value });
    if (event.target.value.length === 2) {
      document.getElementById("DispensedDay").blur();
      document.getElementById("DispensedYear").focus();
    }
  };

  handleYearChange = event => {
    this.setState({ dispensedYear: event.target.value });
    if (event.target.value.length === 4) {
      document.getElementById("DispensedYear").blur();
      document.getElementById("DispensedButton").focus();
    }
  };

  submit() {
    if (
      isEmpty(this.state.dispensedMonth) ||
      isEmpty(this.state.dispensedDay) ||
      isEmpty(this.state.dispensedYear)
    ) {
      this.setState({ emptyDispensed: true });
    }
    if (
      !isEmpty(this.state.dispensedMonth) &&
      limitMonth(this.state.dispensedMonth) === false
    ) {
      this.setState({ monthErrorFormat: true, emptyDispensed: false });
    } else {
      this.setState({ monthErrorFormat: false });
    }
    if (
      !isEmpty(this.state.dispensedDay) &&
      limitDay(this.state.dispensedDay) === false
    ) {
      this.setState({ dayErrorFormat: true, emptyDispensed: false });
    } else {
      this.setState({ dayErrorFormat: false });
    }
    if (
      !isEmpty(this.state.dispensedYear) &&
      limitYear(this.state.dispensedYear) === false
    ) {
      this.setState({ yearErrorFormat: true, emptyDispensed: false });
    } else {
      this.setState({ yearErrorFormat: false });
    }
    if (
      !isEmpty(this.state.dispensedMonth) &&
      limitMonth(this.state.dispensedMonth) === true &&
      !isEmpty(this.state.dispensedDay) &&
      limitDay(this.state.dispensedDay) === true &&
      !isEmpty(this.state.dispensedYear) &&
      limitYear(this.state.dispensedYear) === true
    ) {
      this.setState({ confirmSuccess: true });
      const { changeBilledPatient } = this.props;
      const dispensedInput = {
        userId: this.props.userId,
        id: this.props.id,
        dispensedDay: moment(
          this.state.dispensedYear +
            "-" +
            this.state.dispensedMonth +
            "-" +
            this.state.dispensedDay
        ).format("YYYY-MM-DD")
      };
      changeBilledPatient(dispensedInput, this.props.from, this);
    }
  }
  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Icon as="i" name="edit" className="edit" onClick={this.handleOpen} />
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>Modify Your Dispensed Day</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <label>Dispensed Day</label>
                  <div className="ui input DispensedDayBox">
                    <label className="dispensed_input_label">Month</label>
                    <input
                      type="text"
                      placeholder="Month"
                      onChange={this.handleMonthChange}
                      id="DispensedMonth"
                      maxLength="2"
                      value={this.state.dispensedMonth}
                    />
                  </div>
                  <div className="ui input DispensedDayBox DispensedDayBox_center">
                    <label className="dispensed_input_label">Day</label>
                    <input
                      type="text"
                      placeholder="Day"
                      onChange={this.handleDayChange}
                      maxLength="2"
                      id="DispensedDay"
                      value={this.state.dispensedDay}
                    />
                  </div>
                  <div className="ui input DispensedDayBox">
                    <label className="dispensed_input_label">Year</label>
                    <input
                      type="text"
                      placeholder="Year"
                      onChange={this.handleYearChange}
                      maxLength="4"
                      id="DispensedYear"
                      value={this.state.dispensedYear}
                    />
                  </div>
                  <div
                    className="error_birth_tip"
                    style={{
                      display:
                        this.state.monthErrorFormat ||
                        this.state.dayErrorFormat ||
                        this.state.yearErrorFormat
                          ? "block"
                          : "none"
                    }}
                  >
                    Enter a valid birthday
                  </div>
                  <div
                    className="error_birth_tip"
                    style={{
                      display: this.state.emptyDispensed ? "block" : "none"
                    }}
                  >
                    Please input Dispensed Day
                  </div>
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.submit()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            id="DispensedButton"
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.close()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  listPatients: state.patientsListStore.listPatients,
  searchPatientsList: state.monthlyReportStore.searchPatientsList
});
const mapDispatchToProp = dispatch => ({
  changeBilledPatient: (dispensedInput, from, self) =>
    dispatch(changeBilledPatient(dispensedInput, from, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(changeDispensedDay);
