import React, { Component } from "react";
import "./index.css";
class About extends Component {
  render() {
    return (
      <div>
        ## V0.7.4 ### Bug Fixed - Fix the can not edit staff ### Feature - Added
        the ability to modify the staff
      </div>
    );
  }
}
export default About;
