import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import ReactTable from "react-table";
import _ from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { formatNumber } from "../tools/phoneFormat";
import {
  getSiteLpnPatients,
  removeLpnForPatient
} from "../action/lpnManagementAction";

/**
 *  Created by shengli.zhang on 4/2/21 4:35 PM
 */

class RemoveLpnForSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      disableSubmit: true,
      sitePatients: [],
      removePatients: []
    };
  }

  handleOpen = () => {
    this.setState({ open: true, loading: true });
    this.props.getSiteLpnPatients(this, this.props.siteId).finally(() => {
      this.setState({ loading: false });
    });
  };

  close = () =>
    this.setState({
      open: false,
      loading: false,
      disableSubmit: true,
      sitePatients: [],
      removePatients: []
    });

  submit = () => {
    this.setState({ disableSubmit: true, loading: true });
    this.props
      .removeLpnForPatient(this.state.removePatients, this)
      .finally(() => {
        this.close();
      });
  };

  render() {
    return (
      <Modal
        trigger={
          <Button
            onClick={this.handleOpen}
            size="mini"
            className="deleteButton"
          >
            Remove
          </Button>
        }
        open={this.state.open}
        className="add-box-form-container"
      >
        <Modal.Header>
          Remove LPN for {this.props.siteId}
          <CloseOutlined
            style={{ float: "right" }}
            onClick={e => this.close()}
          />
        </Modal.Header>
        <Modal.Content>
          <div>
            <ReactTable
              loading={this.state.loading}
              data={this.state.sitePatients.map(p => {
                const givenName = `${p.firstName} ${p.lastName}`;
                const LPNInfo = _(this.props.listSirenStaff).find({
                  id: p.LPN
                });
                const id = !_.isEmpty(p.id) ? formatNumber(p.id).trim() : "";
                const LPN = LPNInfo
                  ? `${LPNInfo.firstName} ${LPNInfo.lastName}`
                  : p.LPN;
                return {
                  ...p,
                  id,
                  givenName,
                  LPN
                };
              })}
              columns={[
                {
                  Header: "ID",
                  accessor: "id",
                  filterMethod: (filter, row) => {
                    return String(row[filter.id])
                      .toLowerCase()
                      .includes(String(filter.value).toLowerCase());
                  }
                },
                {
                  Header: "SirenId",
                  accessor: "sirenId",
                  filterMethod: (filter, row) => {
                    return String(row[filter.id]).includes(filter.value);
                  }
                },
                {
                  Header: "SiteId",
                  accessor: "siteId"
                },
                {
                  Header: "Full Name",
                  accessor: "givenName",
                  filterMethod: (filter, row) => {
                    let rowfilterfullname = row._original.givenName
                      ? row[filter.id].toLowerCase()
                      : "";
                    let filterfullname = filter.value.toLowerCase();
                    return rowfilterfullname.includes(filterfullname);
                  }
                },
                {
                  Header: "Nurse",
                  accessor: "LPN",
                  filterMethod: (filter, row) => {
                    let rowfilterLpn = row._original.LPN
                      ? row[filter.id].toLowerCase()
                      : "";
                    let filterLpn = filter.value.toLowerCase();
                    return rowfilterLpn.includes(filterLpn);
                  }
                }
              ]}
              filterable
              defaultSorted={[{ id: "LPN", desc: false }]}
              defaultPageSize={10}
              showPageSizeOptions={false}
              className="-striped -highlight"
              minRows={0}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.regiSuccess ? "Close" : "Cancel"}
          </Button>
          <Button
            color="green"
            onClick={e => this.submit()}
            disabled={this.state.disableSubmit}
          >
            <Icon name="checkmark" />
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  listSirenStaff: state.patientsListStore.listSirenStaff
});
const mapDispatchToProp = dispatch => ({
  getSiteLpnPatients: (self, siteId) =>
    dispatch(getSiteLpnPatients(self, siteId)),
  removeLpnForPatient: (patients, self) =>
    dispatch(removeLpnForPatient(patients, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(RemoveLpnForSite);
