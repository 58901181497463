import _, { isEmpty, merge, isNumber } from "lodash";
import moment from "moment/moment";

//Time Worn Without Shoes
export function getWithoutShoesTimeWorn(footstatus) {
  if (isEmpty(footstatus) || isEmpty(footstatus.noShoesDetails)) {
    const option = baseTemperatureOption();

    const extraOption = {
      title: {
        text: `Time Worn Without Shoes${
          isNumber(footstatus.timewornWithoutShoes)
            ? ": " + footstatus.timewornWithoutShoes
            : ""
        }`
      },
      color: ["#706DAB"],
      legend: [
        {
          data: ["Without shoes"]
        }
      ],
      xAxis: [
        {
          data: []
        }
      ],
      yAxis: [
        {
          name: "",
          min: 0,
          max: 1.5
        }
      ],
      series: [
        {
          name: "Without shoes",
          type: "bar",
          xAxisIndex: 0,
          yAxisIndex: 0,
          symbolSize: 8,
          hoverAnimation: false,
          data: [],
          symbol: "none",
          itemStyle: {
            normal: {
              lineStyle: {
                color: "#706DAB"
              }
            }
          }
        }
      ]
    };

    return merge(option, extraOption);
  }
  let startDateStr = moment(footstatus.date).format("YYYY-MM-DDT00:00");
  // fulfill the time gap.
  const startDate = moment(startDateStr);
  const gap = 1;

  let emptyTimeGaps = Array((24 * 60) / gap)
    .fill(0)
    .map((_, i) => ({
      usertime: startDate
        .clone()
        .add(i * gap, "minutes")
        .format("YYYY-MM-DDTHH:mm"),
      data: "-"
    }));
  let realTime = JSON.parse(footstatus.noShoesDetails).map(item => {
    let m1 = moment(item.start.replace("Z", ""));
    let m2 = moment(item.end.replace("Z", ""));
    let dateDiff = m2.diff(m1, "minutes");
    return Array((dateDiff + 1) / gap)
      .fill(0)
      .map((_, i) => ({
        usertime: m1
          .clone()
          .add(i * gap, "minutes")
          .format("YYYY-MM-DDTHH:mm"),
        data: "1"
      }));
  });
  let timeWornWithoutShoeData = emptyTimeGaps.map(p => {
    let d = _(_.flatten(realTime)).find({ usertime: p.usertime });
    if (d) {
      return d;
    }
    return p;
  });
  // console.log("timeWornWithoutShoeData: ", timeWornWithoutShoeData);

  // usertime
  let dateList = timeWornWithoutShoeData.map(p =>
    moment(p.usertime).format("MM/DD HH:mm")
  );
  let wornData = timeWornWithoutShoeData.map(p => p.data);
  // console.log("dataList: ", dateList);
  // console.log("wornData: ", wornData);

  const option = baseTemperatureOption();

  const extraOption = {
    title: {
      text: `Time Worn Without Shoes: ${footstatus.timewornWithoutShoes}`
    },
    color: ["#706DAB"],
    legend: [
      {
        data: ["Without shoes"]
      }
    ],
    xAxis: [
      {
        data: dateList
      }
    ],
    yAxis: [
      {
        name: "",
        min: 0,
        max: 1.5
      }
    ],
    series: [
      {
        name: "Without shoes",
        type: "bar",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: wornData,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#706DAB"
            }
          }
        }
      }
    ]
  };

  return merge(option, extraOption);
}

function baseTemperatureOption() {
  return {
    tooltip: {
      trigger: "axis",
      textStyle: {
        align: "left"
      }
    },
    legend: [
      {
        top: "15%",
        left: 50,
        x: "left"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -100,
        show: true,
        realtime: true,
        start: 0,
        end: 100
      }
    ],
    title: {
      textStyle: {
        fontSize: 14
      },
      top: 20,
      left: 15
    },
    grid: [
      {
        left: 40,
        right: 40,
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CED1",
            width: 1.5
          }
        },
        position: "bottom",
        axisLabel: {
          interval: "auto",
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "",
        show: false,
        type: "value",
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        min: 0,
        max: 2,
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 15,
          color: "#ff0000",
          padding: [0, 0, -22, 5]
        }
      }
    ]
  };
}
