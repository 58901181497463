import * as TYPES from "../types/types";
import { API, Auth, graphqlOperation } from "aws-amplify";
import _api from "../graphql/_api";
import _ from "lodash";
import aws_exports from "../aws-exports-siren";

let generateVaTimer = null;

function showGenerateVaDelayStatus() {
  return {
    type: TYPES.SHOW_GENERATE_VA_DELAY,
    text: true
  };
}

function hiddenGenerateVaDelayStatus() {
  return {
    type: TYPES.SHOW_GENERATE_VA_DELAY,
    text: false
  };
}

function startGenerateVaDelayStatus(generateVaDelay) {
  return {
    type: TYPES.GENERATE_VA_DELAY,
    text: generateVaDelay
  };
}
function clearVaTimer(dispatch) {
  clearInterval(generateVaTimer);
  dispatch(hiddenGenerateVaDelayStatus());
  dispatch(startGenerateVaDelayStatus(""));
}

function startVaTimer(time, dispatch) {
  let sys_second = time * 1000;
  generateVaTimer = setInterval(() => {
    if (sys_second > 1000) {
      sys_second -= 1000;
      let minute = Math.floor((sys_second / 1000 / 60) % 60);
      let second = Math.floor((sys_second / 1000) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      let generateDelay = minute + "m" + second + "s";
      dispatch(startGenerateVaDelayStatus(generateDelay));
    } else {
      clearVaTimer(dispatch);
    }
  }, 1000);
}

export function generateBillingReportVa(input) {
  return async dispatch => {
    try {
      dispatch(showGenerateVaDelayStatus());
      let reportLength = input.reportPatients.length;
      let waitAbout = reportLength * 30;
      startVaTimer(waitAbout, dispatch);
      const newEvent = await API.graphql(
        graphqlOperation(_api.generateBillingReport(), { input })
      );
      console.log("generateBillingReport for va success: ", newEvent);
      if (newEvent) {
        const subscription = API.graphql(
          graphqlOperation(_api.subscribeBillingReport())
        ).subscribe({
          next: async todoData => {
            console.log("subscrib billing repeort for va:", todoData);
            if (todoData.value.data.onReportGenerated.token === input.token) {
              clearVaTimer(dispatch);
              //unsubscribe
              subscription.unsubscribe();
              //auto download
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              const cptCode = "99453-4";
              const downloadKey = todoData.value.data.onReportGenerated.path;
              const endpointInfo = _.filter(
                aws_exports.aws_cloud_logic_custom,
                {
                  name: "dev-pdf-exporter"
                }
              );
              const pdfEndpoint = endpointInfo[0].endpoint;
              const downloadUrl = `${pdfEndpoint}/admin/unBilledReport?key=${downloadKey}&token=${token}&generatedDate=${input.generatedDate}`;
              window.open(downloadUrl);
            }
          }
        });
      }
    } catch (e) {
      clearVaTimer(dispatch);
      console.log("generateBillingReport for va error: ", e);
    }
  };
}

export function getPatientBySiteIds(siteId, self) {
  return async dispatch => {
    try {
      console.log("siteId: ", siteId);
      self.setState({ modalIsOpen: true });
      const patient = await API.graphql(
        graphqlOperation(_api.getPatientBySiteId(), { siteId })
      );
      if (patient) {
        const allPatients = patient.data.getPatientBySiteId.items;
        self.setState({ allPatients });
      }
    } catch (e) {
      console.log("get patient by site id failed, ", e);
    } finally {
      self.setState({ modalIsOpen: false });
    }
  };
}
//siteId, reportMonth, token
export function generateVaZipReport(input) {
  return async dispatch => {
    try {
      dispatch(showGenerateVaDelayStatus());
      let waitAbout = 30;
      startVaTimer(waitAbout, dispatch);
      const va_zip = await API.graphql(
        graphqlOperation(_api.generateVaZipReport(), { input })
      );
      if (va_zip) {
        const subscription = API.graphql(
          graphqlOperation(_api.subscribeBillingReport())
        ).subscribe({
          next: async todoData => {
            console.log("subscrib va zip repeort:", todoData);
            if (todoData.value.data.onReportGenerated.token === input.token) {
              clearVaTimer(dispatch);
              //unsubscribe
              subscription.unsubscribe();
              //auto download
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              const downloadKey = todoData.value.data.onReportGenerated.path;
              const endpointInfo = _.filter(
                aws_exports.aws_cloud_logic_custom,
                {
                  name: "dev-pdf-exporter"
                }
              );
              const pdfEndpoint = endpointInfo[0].endpoint;
              const downloadUrl = `${pdfEndpoint}/admin/vaZipReport?key=${downloadKey}&token=${token}&reportMonth=${input.reportMonth}&siteId=${input.siteId}&generatedDate=${input.generatedDate}`;
              window.open(downloadUrl);
            }
          }
        });
      }
    } catch (e) {
      clearVaTimer(dispatch);
      console.log("generate va zip report error: ", JSON.stringify(e));
    }
  };
}
