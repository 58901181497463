import moment from "moment";
import _ from "lodash";
/**
 *  Created by shengli.zhang on 9/23/21 5:32 PM
 */

export function formatReviewTaskType(reviewType) {
  switch (reviewType) {
    case "SYMPTOM":
      return "Symptoms";
    case "UPLOAD_PHOTO":
      return "Photo Review";
    case "DATA_UPDATE":
      return "Data Update";
    case "MEDICAL_REVIEW":
      return "Medical Review";
    case "Onboarding":
      return "Onboarding";
  }
}

export function formatHotspot(spot) {
  switch (spot) {
    case "ltoe":
      return "Hallux";
    case "rtoe":
      return "Hallux";
    case "lmts1":
      return "MTS 1";
    case "rmts1":
      return "MTS 1";
    case "lmts3":
      return "MTS 3";
    case "rmts3":
      return "MTS 3";
    case "lmts5":
      return "MTS 5";
    case "rmts5":
      return "MTS 5";
    case "larch":
      return "Arch";
    case "rarch":
      return "Arch";
    case "lheel":
      return "Heel";
    case "rheel":
      return "Heel";
  }
}

function sperateOnboarding(value) {
  const onboardingTask = _.uniqBy(
    value.filter(t => t.type === "Onboarding"),
    "program"
  ).map(t => {
    return {
      status: t.status,
      program: t.program,
      device: t.device
    };
  });
  const otherTasks = _.uniqBy(
    value.filter(t => t.type !== "Onboarding"),
    "type"
  ).map(t => {
    return {
      status: t.status,
      program: t.program,
      device: t.device,
      date: t.date
    };
  });
  return [...onboardingTask, ...otherTasks];
}

export function formatReviewTasks(reviewTasks) {
  let allReviewTasks = [];
  if (reviewTasks) {
    for (const task of reviewTasks) {
      if (task.oldStatus) {
        const oldStatus = JSON.parse(task.oldStatus);
        const newStatus = JSON.parse(task.newStatus);
        const daysDelay =
          parseInt(moment(task.taskTime).format("DD")) -
          parseInt(moment(oldStatus.date).format("DD"));
        const footStatusChange = formatFootStatusChange(oldStatus, newStatus);
        let obj = {
          taskTime: moment(oldStatus.date).format("YYYY-MM-DD"),
          status: formatReviewTaskType(task.reviewType),
          oldStatusDate: oldStatus.date,
          newStatusDate: moment(task.taskTime).format("YYYY-MM-DD"),
          daysDelay,
          type: task.reviewType,
          footStatusChange
        };
        allReviewTasks.push(obj);
      } else {
        let obj = {
          taskTime:
            task.reviewType === "MEDICAL_REVIEW" && task.date
              ? moment(task.date).format("YYYY-MM-DD")
              : task.reviewType === "SYMPTOM" && task.reportTime
              ? moment(task.reportTime).format("YYYY-MM-DD")
              : moment(task.taskTime).format("YYYY-MM-DD"),
          status: formatReviewTaskType(task.reviewType),
          type: task.reviewType,
          program: task.program,
          device: task.device,
          date: moment(task.taskTime).format("YYYY-MM-DD")
        };
        allReviewTasks.push(obj);
      }
    }
  }

  const otherReviewTask = _(
    _.filter(allReviewTasks, function(item) {
      return item.type !== "DATA_UPDATE";
    })
  )
    .groupBy(p => {
      return p.taskTime;
    })
    .flatMap((value, key) => {
      return {
        taskTime: key,
        tasks: sperateOnboarding(value)
      };
    })
    .uniq()
    .value();
  const dataUpdateTask = _.filter(allReviewTasks, function(item) {
    return item.type === "DATA_UPDATE";
  });
  return _.orderBy(
    [...otherReviewTask, ...dataUpdateTask],
    ["taskTime"],
    ["desc"]
  );
}

function formatFootStatusChange(oldStatus, newStatus) {
  const spothots = [
    "ltoe",
    "lmts1",
    "lmts3",
    "lmts5",
    "larch",
    "lheel",
    "rtoe",
    "rmts1",
    "rmts3",
    "rmts5",
    "rarch",
    "rheel"
  ];
  const a = _.pick(oldStatus, spothots);
  const b = _.pick(newStatus, spothots);

  const diff = _.compact(
    spothots.map(p => {
      if (a[p] !== b[p]) {
        return {
          old: a[p] === "NORMAL" ? "GREEN" : a[p],
          new: b[p] === "NORMAL" ? "GREEN" : b[p],
          key: formatHotspot(p),
          foot: p.startsWith("r") ? "Right foot" : "Left foot"
        };
      }
      return null;
    })
  );
  const diffGroup = _(diff)
    .groupBy(item => [item.foot, item.old, item.new])
    .flatMap((value, key) => {
      return {
        old: key.split(",")[1],
        new: key.split(",")[2],
        foot: key.split(",")[0],
        key: value.map(p => p.key).join(", ")
      };
    })
    .uniq()
    .value();
  return { diff: diffGroup, oldStatus: a, newStatus: b };
}
