import moment from "moment";
import * as TYPES from "../types/types";
const initialState = {
  patientStatus: {},
  sockStatus: {},
  sockHomeStatus: {},
  medicalStatus: {},
  supportStatus: {},
  patientStatusDetail: [],
  chooseuser: [],
  trialuser: [],
  calendardata: {},
  timezone: {},
  footStatus: {},
  cleanedStatus: {},
  footOverview: {},
  symtomDatas: [],
  weightScale: [],
  delta: [],
  leftFootTemp: [],
  rightFootTemp: [],
  deltaWashed: [],
  deltaAutoWashed: false,
  leftFootTempWashed: [],
  rightFootTempWashed: [],
  leftFootTempMarked: [],
  rightFootTempMarked: [],
  steps: [],
  stepsTag: [],
  leftPosition: [],
  rightPosition: [],
  positionTag: [],
  positionPillBottle: [],
  showClosePillBottle: true,
  complianceList: [],
  treatDatas: [],
  filterGroup: [],
  userGroup: "",
  clinicSites: [],
  clinicSitesDeleted: [],
  EMRSystemList: [],
  listStaff: [],
  deletedListStaff: [],
  min1_or_min30: "min1",
  min1_or_min30_tag: "min1",
  delta_auto: false,
  loading_charts: false,
  changeFootDate: new Date(),
  tagTimeWorn: [],
  pillBottleDateWorn: [],
  tagTimeWornByWeek: [],
  doctorDetails: {},
  newPatient: {},
  tempUnit: "Fahrenheit",
  medicalConditionList: [],
  listInvoicePatient: [],
  selRightToe: false,
  selRightMts: false,
  selRightArch: false,
  selRightHeel: false,
  selLeftToe: false,
  selLeftMts: false,
  selLeftArch: false,
  selLeftHeel: false,
  initializedNote: [],
  npmService: [],
  filterNpmService: [],
  modalState: false,
  allClinicSites: [],
  shoeTagList: [],
  pillBottleList: [],
  showLpnCharts: false,
  dev_env: false,
  listAdminStaff: [],
  listAdminDeletedStaff: [],
  listSirenStaff: [],
  listLpnPatients: [],
  listNeedAssignedPatients: [],
  listNotOnServicePatients: [],
  listCarePlan: [],
  needAssignedCount: 0,
  listSirenPatients: [
    {
      issue: "Medical Review",
      firstName: "Shengli",
      lastName: "Zhang",
      birthday: "1990-10-06",
      sirenId: 23,
      doctor: "Shengli Zhang",
      siteId: "0000",
      siteName: "Siren Test",
      lastDMT: "2020-01-19",
      serviceE: "Yes",
      totalTime: 30,
      daysWorn: 3,
      specialCase: "Yes",
      accountStatus: "ACTIVE"
    }
  ],
  DMTModalStatus: 'close',
};
export default function patientsList(state = initialState, action) {
  switch (action.type) {
    case TYPES.UPDATE_ENV:
      return {
        ...state,
        dev_env: action.text
      };
    case TYPES.SHOE_TAG_LIST:
      return {
        ...state,
        shoeTagList: action.text
      };
    case TYPES.PILL_BOTTLE_LIST:
      return {
        ...state,
        pillBottleList: action.text
      };
    case TYPES.LIST_SIREN_PATIENT:
      return {
        ...state,
        listSirenPatients: action.text
      };
    case TYPES.INVOICE_PATIENT:
      return {
        ...state,
        listInvoicePatient: action.text
      };
    case TYPES.TEMP_UNIT:
      return {
        ...state,
        tempUnit: action.text
      };
    case TYPES.MIN1_OR_MIN30_TAG:
      return {
        ...state,
        min1_or_min30_tag: action.text
      };
    case TYPES.DOCTOR_DETAILS:
      return {
        ...state,
        doctorDetails: action.text
      };
    case TYPES.INITIALIZED_NOTE:
      return {
        ...state,
        initializedNote: action.text
      };
    case TYPES.SOCK_CHART_DATA: {
      const {
        leftFootTemp,
        rightFootTemp,
        leftFootPosition,
        rightFootPosition,
        delta,
        deltaAuto,
        steps,
      } = action.payload;
      return Object.assign({}, state, {
        delta,
        leftFootTemp,
        rightFootTemp,
        steps,
        leftPosition: leftFootPosition,
        rightPosition: rightFootPosition,
        delta_auto: deltaAuto,
        min1_or_min30: action.payload["min1_or_30"],
      });
    }
    case TYPES.DELTA_WASHED:
      return {
        ...state,
        deltaWashed: action.text
      };
    case TYPES.DELTA_AUTO_WASHED:
      return {
        ...state,
        deltaAutoWashed: action.text
      };
    case TYPES.LEFT_FOOT_TEMP_WASHED:
      return {
        ...state,
        leftFootTempWashed: action.text
      };
    case TYPES.RIGHT_FOOT_TEMP_WASHED:
      return {
        ...state,
        rightFootTempWashed: action.text
      };
    case TYPES.LEFT_FOOT_TEMP_MARKED:
      return {
        ...state,
        leftFootTempMarked: action.text
      };
    case TYPES.RIGHT_FOOT_TEMP_MARKED:
      return {
        ...state,
        rightFootTempMarked: action.text
      };
    case TYPES.STEPS_TAG:
      return {
        ...state,
        stepsTag: action.text
      };
    case TYPES.POSITION_TAG:
      return {
        ...state,
        positionTag: action.text
      };
    case TYPES.POSITION_PILL_BOTTLE:
      return {
        ...state,
        positionPillBottle: action.text
      };
    case TYPES.SHOW_CLOSE_PILL_BOTTLE_DATA:
      return {
        ...state,
        showClosePillBottle: !state.showClosePillBottle
      };
    case TYPES.PATIENTS_STATUS_DETAIL:
      return {
        ...state,
        patientStatusDetail: action.text
      };
    case TYPES.PATIENTS_LIST:
      return {
        ...state,
        listPatients: action.text
      };
    case TYPES.PATIENTS_STATUS:
      return {
        ...state,
        patientStatus: action.text
      };
    case TYPES.SOCK_STATUS:
      return {
        ...state,
        sockStatus: action.text
      };
    case TYPES.SOCK_HOME_STATUS:
      return {
        ...state,
        sockHomeStatus: action.text
      };
    case TYPES.CHOOSE_USER:
      return {
        ...state,
        chooseuser: action.text
      };
    case TYPES.TRIAL_USER:
      return {
        ...state,
        trialuser: action.text
      };
    case TYPES.CALENDAR_DATA:
      return {
        ...state,
        calendardata: action.text
      };
    case TYPES.TIME_ZONE:
      return {
        ...state,
        timezone: action.text
      };
    case TYPES.FOOT_STATUS:
      return {
        ...state,
        footStatus: action.text,
        cleanedStatus: action.cleanedStatus,
        footOverview: action.footOverview,
      };
    case TYPES.FOOT_SYMTOMS:
      return {
        ...state,
        symtomDatas: action.text
      };
    case TYPES.WEIGHT_SCALE:
      return {
        ...state,
        weightScale: action.text
      };
    case TYPES.COMPLIANCE_LIST:
      return {
        ...state,
        complianceList: action.text
      };
    case TYPES.TREAT_MENT:
      return {
        ...state,
        treatDatas: action.text
      };
    case TYPES.FILTER_GROUP:
      return {
        ...state,
        filterGroup: action.text
      };
    case TYPES.USER_GROUPS:
      return {
        ...state,
        userGroup: action.text
      };
    case TYPES.CLINIC_SITES:
      return {
        ...state,
        clinicSites: action.text
      };
    case TYPES.CLINIC_SITES_DELETED:
      return {
        ...state,
        clinicSitesDeleted: action.text
      };
    case TYPES.EMR_SYSTEM_LIST:
      return {
        ...state,
        EMRSystemList: action.text
      };
    case TYPES.LIST_STAFF:
      return {
        ...state,
        listStaff: action.text
      };
    case TYPES.DELETED_LIST_STAFF:
      return {
        ...state,
        deletedListStaff: action.text
      };
    case TYPES.LIST_SIREN_STAFF:
      return {
        ...state,
        listSirenStaff: action.text
      };
    case TYPES.LIST_LPN_PATIENTS:
      return {
        ...state,
        listLpnPatients: action.text
      };
    case TYPES.LIST_NEED_ASSIGNED_PATIENTS:
      return {
        ...state,
        listNeedAssignedPatients: action.text
      };
    case TYPES.NEED_ASSIGNED_COUNT:
      return {
        ...state,
        needAssignedCount: action.text
      };
    case TYPES.LIST_NOT_ON_SERVICE_PATIENTS:
      return {
        ...state,
        listNotOnServicePatients: action.text
      };
    case TYPES.LIST_ADMIN_STAFF:
      return {
        ...state,
        listAdminStaff: action.text
      };
    case TYPES.LIST_ADMIN_DELETED_STAFF:
      return {
        ...state,
        listAdminDeletedStaff: action.text
      };
    case TYPES.UPDATE_LPN_FILTER: {
      const nextList = [...state.listAdminStaff];
      const staff = nextList.find(e => e.id === action.id);
      staff.filterColumns = action.filter;
      return {
        ...state,
        listAdminStaff: nextList
      };
    }
    case TYPES.FOOT_DATA:
      return {
        ...state,
        changeFootDate: action.text
      };
    case TYPES.TAG_TIME_WORN:
      return {
        ...state,
        tagTimeWorn: action.text
      };
    case TYPES.PILL_BOTTLE_DATE_WORN:
      return {
        ...state,
        pillBottleDateWorn: action.text
      };
    case TYPES.TAG_TIME_WORN_BY_WEEK:
      return {
        ...state,
        tagTimeWornByWeek: action.text
      };
    case TYPES.LOADING_CHARTS:
      return {
        ...state,
        loading_charts: action.text
      };
    case TYPES.SEL_RIGHT_TOE:
      return {
        ...state,
        selRightToe: !state.selRightToe
      };
    case TYPES.SEL_RIGHT_MTS:
      return {
        ...state,
        selRightMts: !state.selRightMts
      };
    case TYPES.SEL_RIGHT_ARCH:
      return {
        ...state,
        selRightArch: !state.selRightArch
      };
    case TYPES.SEL_RIGHT_HEEL:
      return {
        ...state,
        selRightHeel: !state.selRightHeel
      };
    case TYPES.SEL_LEFT_TOE:
      return {
        ...state,
        selLeftToe: !state.selLeftToe
      };
    case TYPES.SEL_LEFT_MTS:
      return {
        ...state,
        selLeftMts: !state.selLeftMts
      };
    case TYPES.SEL_LEFT_ARCH:
      return {
        ...state,
        selLeftArch: !state.selLeftArch
      };
    case TYPES.SEL_LEFT_HEEL:
      return {
        ...state,
        selLeftHeel: !state.selLeftHeel
      };
    case TYPES.NPM_SERVICE:
      return {
        ...state,
        npmService: action.text
      };
    case TYPES.FILTER_NPM_SERVICE:
      return {
        ...state,
        filterNpmService: action.text
      };
    case TYPES.MODAL_STATE:
      return {
        ...state,
        modalState: action.text
      };
    case TYPES.ALL_CLINIC_SITES:
      return {
        ...state,
        allClinicSites: action.text
      };
    case TYPES.SHOW_LPN_CHARTS:
      return {
        ...state,
        showLpnCharts: action.text
      };
    case TYPES.LIST_CARE_PLAN:
      return {
        ...state,
        listCarePlan: action.text
      };
    case TYPES.DMT_MODAL_STATUS:
      return {
        ...state,
        DMTModalStatus: action.status
      }
    default:
      return state;
  }
}
