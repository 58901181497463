import {
  TABLE_FILTER_REPORT_99453_4_NEEDS_REVIEW,
  TABLE_FILTER_REPORT_99457_8_NEEDS_REVIEW,
  TABLE_FILTER_REPORT_99426_7_NEEDS_REVIEW,
  TABLE_FILTER_REPORT_99453_4_REVIEWED,
  TABLE_FILTER_REPORT_99457_8_REVIEWED,
  TABLE_FILTER_REPORT_99426_7_REVIEWED,
  TABLE_FILTER_REPORT_99453_4_CURRENT_MONTH,
  TABLE_FILTER_REPORT_99457_8_CURRENT_MONTH,
  TABLE_FILTER_REPORT_99426_7_CURRENT_MONTH,
  TABLE_FILTER_SIREN_STAFFING,
} from "../types/types";

const initialState = {
  report99453_4NeedsReview: {
    filtered: [],
    sorted: [{ id: "sirenId", desc: true }],
    resized: [],
  },
  report99457_8NeedsReview: {
    filtered: [],
    sorted: [{ id: "sirenId", desc: true }],
    resized: [],
  },
  report99426_7NeedsReview: {
    filtered: [],
    sorted: [{ id: "sirenId", desc: true }],
    resized: [],
  },
  report99453_4Reviewed: {
    filtered: [],
    sorted: [{ id: "sirenId", desc: true }],
    resized: [],
  },
  report99457_8Reviewed: {
    filtered: [],
    sorted: [{ id: "sirenId", desc: true }],
    resized: [],
  },
  report99426_7Reviewed: {
    filtered: [],
    sorted: [{ id: "sirenId", desc: true }],
    resized: [],
  },
  report99453_4CurrentMonth: {
    filtered: [],
    sorted: [{ id: "sirenId", desc: true }],
    resized: [],
  },
  report99457_8CurrentMonth: {
    filtered: [],
    sorted: [{ id: "sirenId", desc: true }],
    resized: [],
  },
  report99426_7CurrentMonth: {
    filtered: [],
    sorted: [{ id: "sirenId", desc: true }],
    resized: [],
  },
  sirenStaffing: [],
};

export default function tableFilter(state = initialState, action) {
  switch (action.type) {
    case TABLE_FILTER_REPORT_99453_4_NEEDS_REVIEW:
      return {
        ...state,
        report99453_4NeedsReview: action.state
      };
    case TABLE_FILTER_REPORT_99457_8_NEEDS_REVIEW:
      return {
        ...state,
        report99457_8NeedsReview: action.state
      }
    case TABLE_FILTER_REPORT_99426_7_NEEDS_REVIEW:
      return {
        ...state,
        report99426_7NeedsReview: action.state
      }
    case TABLE_FILTER_REPORT_99453_4_REVIEWED:
      return {
        ...state,
        report99453_4Reviewed: action.state
      };
    case TABLE_FILTER_REPORT_99457_8_REVIEWED:
      return {
        ...state,
        report99457_8Reviewed: action.state
      }
    case TABLE_FILTER_REPORT_99426_7_REVIEWED:
      return {
        ...state,
        report99426_7Reviewed: action.state
      }
    case TABLE_FILTER_REPORT_99453_4_CURRENT_MONTH:
      return {
        ...state,
        report99453_4CurrentMonth: action.state
      };
    case TABLE_FILTER_REPORT_99457_8_CURRENT_MONTH:
      return {
        ...state,
        report99457_8CurrentMonth: action.state
      }
    case TABLE_FILTER_REPORT_99426_7_CURRENT_MONTH:
      return {
        ...state,
        report99426_7CurrentMonth: action.state
      }
    case TABLE_FILTER_SIREN_STAFFING:
      return {
        ...state,
        sirenStaffing: action.state
      }
    default:
      return state;
  }
}

export function updateReportNeedsReviewTableFilter(cptCode, state) {
  switch (cptCode) {
    case "99453-4":
      return {
        type: TABLE_FILTER_REPORT_99453_4_NEEDS_REVIEW,
        state
      }
    case "99426-7":
      return {
        type: TABLE_FILTER_REPORT_99426_7_NEEDS_REVIEW,
        state
      }
    case "99457-8":
      return {
        type: TABLE_FILTER_REPORT_99457_8_NEEDS_REVIEW,
        state
      }
    default:
      return {}
  }
}

export function updateReportReviewedTableFilter(cptCode, state) {
  switch (cptCode) {
    case "99453-4":
      return {
        type: TABLE_FILTER_REPORT_99453_4_REVIEWED,
        state
      }
    case "99426-7":
      return {
        type: TABLE_FILTER_REPORT_99426_7_REVIEWED,
        state
      }
    case "99457-8":
      return {
        type: TABLE_FILTER_REPORT_99457_8_REVIEWED,
        state
      }
    default:
      return {}
  }
}

export function updateReportCurrentMonthTableFilter(cptCode, state) {
  switch (cptCode) {
    case "99453-4":
      return {
        type: TABLE_FILTER_REPORT_99453_4_CURRENT_MONTH,
        state
      }
    case "99426-7":
      return {
        type: TABLE_FILTER_REPORT_99426_7_CURRENT_MONTH,
        state
      }
    case "99457-8":
      return {
        type: TABLE_FILTER_REPORT_99457_8_CURRENT_MONTH,
        state
      }
    default:
      return {}
  }
}

export function updateSirenStaffingTableFilter(state) {
  return {
    type: TABLE_FILTER_SIREN_STAFFING,
    state
  }
}
