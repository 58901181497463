import { Select } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { sendDocReport } from "../action/addAction";
import ButtonSuccess from "../home/buttonSuccess";
import "../share/button.css";
import message from "../tools/message-info";

const ReportType = [
  { type: "TEST", key: "1" },
  { type: "DOCTOR", key: "2" }
];

const { Option } = Select;
class sendReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      type: "TEST",
      toEmails: "",
      siteIds: [],
      doctorEmails: [],
      startDay: new Date(moment().add(-3, "days"))
    };
    this.handleConfirm.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }
  show = () => {
    if (isEmpty(this.props.reportManualList)) {
      message.info("Please select doctors");
      return;
    }
    this.setState({ open: true, confirmSuccess: false });
  };
  changeDay(date) {
    this.setState({ startDay: date });
  }
  handleTypeChange(value) {
    this.setState({ type: value });
  }
  changeEmail = event => {
    this.setState({ toEmails: event.target.value });
  };
  handleConfirm = () => {
    if (this.state.type === "TEST" && isEmpty(this.state.toEmails)) {
      message.info("Please input email");
    } else if (this.state.type === "TEST" && !isEmpty(this.state.toEmails)) {
      if (isEmpty(this.props.reportManualList)) {
        message.info("Please select doctors");
        return;
      }
      this.setState({ confirmSuccess: true });
      const { sendDocReport } = this.props;
      const sendValue = {
        siteIds: Object.keys(this.props.reportManualList),
        startDay: moment(this.state.startDay).format("YYYY-MM-DD"),
        type: this.state.type,
        toEmails: this.state.toEmails,
        doctorEmails: JSON.stringify(this.props.reportManualList)
      };
      sendDocReport(sendValue, this);
    } else if (this.state.type === "DOCTOR") {
      if (isEmpty(this.props.reportManualList)) {
        message.info("Please select doctors");
        return;
      }
      this.setState({ confirmSuccess: true });
      const { sendDocReport } = this.props;
      const sendValue = {
        siteIds: Object.keys(this.props.reportManualList),
        startDay: moment(this.state.startDay).format("YYYY-MM-DD"),
        type: "SEND_TO_DOCTOR",
        doctorEmails: JSON.stringify(this.props.reportManualList)
      };
      sendDocReport(sendValue, this);
    }
  };
  handleCancel = () =>
    this.setState({
      open: false,
      confirmSuccess: false,
      siteIds: [],
      doctorEmails: [],
      type: "TEST"
    });
  render() {
    const { open } = this.state;

    return (
      <Modal
        trigger={
          <Button size="small" onClick={this.show}>
            Send
          </Button>
        }
        open={open}
      >
        <Modal.Header>Send Report</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div className="newSite_box">
              <div className="inputTotal">
                <p className="inputLable">Start Day</p>
                <DatePicker
                  className="DOBPicker"
                  selected={this.state.startDay}
                  onChange={this.changeDay.bind(this)}
                />
              </div>
              <div className="inputTotal">
                <p className="inputLable">Type</p>
                <Select
                  className="selectStyle"
                  onChange={this.handleTypeChange}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={this.state.type}
                  showSearch
                  optionFilterProp="children"
                >
                  {ReportType.map((rtype, i) => {
                    return (
                      <Option value={rtype.type} key={"rtype" + i}>
                        {rtype.type}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div
                className="inputTotal"
                style={{
                  display: this.state.type === "TEST" ? "block" : "none"
                }}
              >
                <p className="inputLable">To Emails</p>
                <Input
                  placeholder="Johnsmith@email.com"
                  className="inputContent"
                  onChange={this.changeEmail}
                  value={this.state.toEmails}
                />
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  reportManualList: state.reportListStore.reportManualList
});
const mapDispatchToProp = dispatch => ({
  sendDocReport: (sendValue, self) => dispatch(sendDocReport(sendValue, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(sendReport);
