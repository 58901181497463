import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import {
  Button,
  Grid,
  Icon,
  Input,
  Label,
  Segment,
  Table
} from "semantic-ui-react";
import {
  getCurrentBoxInfo,
  getReplaceBoxInfo,
  resetBoxInfo,
  saveBoxInfo,
  updateBoxInfo,
  updateCurrentBoxInfo
} from "../action/ReplaceSocksAction";
import "./replaceSocks.css";
import message from "../tools/message-info";

class ReplaceSocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBoxId: "",
      replaceBoxId: "",
      currentBoxInfo: [],
      replaceBoxInfo: []
    };
    this.brokenBoxId = "";
    this.replaceBoxId = "";
    this.brokenSocks = [];
    this.replaceSocks = [];
  }

  changeCurrentBoxId = event => {
    this.setState({ currentBoxId: event.target.value });
  };

  changeReplaceBoxId = event => {
    this.setState({ replaceBoxId: event.target.value });
  };

  getCurrentBoxInfo() {
    const { getCurrentBoxInfo } = this.props;
    if (isEmpty(this.state.currentBoxId)) {
      message.info("Please input boxId");
      return;
    }
    this.brokenBoxId = this.state.currentBoxId;
    getCurrentBoxInfo(this.state.currentBoxId, this);
  }

  getReplaceBoxInfo() {
    const { getReplaceBoxInfo } = this.props;
    if (isEmpty(this.state.replaceBoxId)) {
      message.info("Please input need replaced boxId");
      return;
    }
    this.replaceBoxId = this.state.replaceBoxId;
    getReplaceBoxInfo(this.state.replaceBoxId, this);
  }

  resetBoxInfo() {
    const { resetBoxInfo } = this.props;
    resetBoxInfo(this);
  }

  saveBoxInfo() {
    const { saveBoxInfo } = this.props;
    saveBoxInfo(this);
  }

  render() {
    return (
      <div className="Statistics-container">
        <div>
          <div
            style={{ float: "left", width: "45%" }}
            className="replace_input_container"
          >
            <div className="replace_input_box">
              <span className="replace_input_label">Broken BoxId</span>
              <Input
                placeholder="John"
                className="replace_input_content"
                onChange={this.changeCurrentBoxId}
                value={this.state.currentBoxId.toUpperCase()}
              />
              <Button
                size="small"
                onClick={this.getCurrentBoxInfo.bind(this)}
                className="replace_input_button"
              >
                Load
              </Button>
            </div>
          </div>
          <div
            style={{ float: "right", width: "45%" }}
            className="replace_input_container"
          >
            <div className="replace_input_box">
              <span className="replace_input_label">Replace BoxId</span>
              <Input
                placeholder="John"
                className="replace_input_content"
                onChange={this.changeReplaceBoxId}
                value={this.state.replaceBoxId.toUpperCase()}
              />
              <Button
                size="small"
                onClick={this.getReplaceBoxInfo.bind(this)}
                className="replace_input_button"
              >
                Load
              </Button>
            </div>
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div>
          <div style={{ float: "left", width: "45%" }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Segment>
                    <div className="seg-title">
                      <Label as="a" color="red" ribbon>
                        Broken Box Information:
                      </Label>
                    </div>
                    <Table celled className="result-table">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>BoxId</Table.HeaderCell>
                          <Table.HeaderCell>serialNo</Table.HeaderCell>
                          <Table.HeaderCell>Foot</Table.HeaderCell>
                          <Table.HeaderCell>Battery</Table.HeaderCell>
                          <Table.HeaderCell>Firmware Version</Table.HeaderCell>
                          <Table.HeaderCell>LastSeen</Table.HeaderCell>
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.props.currentBoxInfo.map((sock, i) => {
                          let tableRow = (
                            <Table.Row key={"sock-list-" + i}>
                              <Table.Cell>{sock.boxId}</Table.Cell>
                              <Table.Cell>{sock.serialNo}</Table.Cell>
                              <Table.Cell>{sock.foot}</Table.Cell>
                              <Table.Cell>{sock.battery}</Table.Cell>
                              <Table.Cell>
                                {sock.fwVersion === "0.1"
                                  ? "-"
                                  : sock.fwVersion}
                              </Table.Cell>
                              <Table.Cell>{sock.lastSeen}</Table.Cell>
                              <Table.Cell>
                                <Icon
                                  color="red"
                                  name="delete"
                                  onClick={() => {
                                    const { updateCurrentBoxInfo } = this.props;
                                    updateCurrentBoxInfo(sock, this);
                                  }}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                          return tableRow;
                        })}
                      </Table.Body>
                    </Table>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div style={{ float: "right", width: "45%" }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Segment>
                    <div className="seg-title">
                      <Label as="a" color="red" ribbon>
                        Replace Box Information:
                      </Label>
                    </div>
                    <Table celled className="result-table">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell />
                          <Table.HeaderCell>BoxId</Table.HeaderCell>
                          <Table.HeaderCell>serialNo</Table.HeaderCell>
                          <Table.HeaderCell>Foot</Table.HeaderCell>
                          <Table.HeaderCell>Battery</Table.HeaderCell>
                          <Table.HeaderCell>Firmware Version</Table.HeaderCell>
                          <Table.HeaderCell>LastSeen</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.props.replaceBoxInfo.map((sock, i) => {
                          let tableRow = (
                            <Table.Row key={"sock-list-" + i}>
                              <Table.Cell>
                                <Icon
                                  color="green"
                                  name="arrow left"
                                  onClick={() => {
                                    const { updateBoxInfo } = this.props;
                                    updateBoxInfo(sock, this);
                                  }}
                                />
                              </Table.Cell>
                              <Table.Cell>{sock.boxId}</Table.Cell>
                              <Table.Cell>{sock.serialNo}</Table.Cell>
                              <Table.Cell>{sock.foot}</Table.Cell>
                              <Table.Cell>{sock.battery}</Table.Cell>
                              <Table.Cell>
                                {sock.fwVersion === "0.1"
                                  ? "-"
                                  : sock.fwVersion}
                              </Table.Cell>
                              <Table.Cell>{sock.lastSeen}</Table.Cell>
                            </Table.Row>
                          );
                          return tableRow;
                        })}
                      </Table.Body>
                    </Table>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="replace_button_box">
          <Button
            color="green"
            className="replace_button_save"
            onClick={this.saveBoxInfo.bind(this)}
          >
            <Icon name="checkmark" />
            Save
          </Button>
          <Button
            basic
            color="red"
            className="replace_button_reset"
            onClick={this.resetBoxInfo.bind(this)}
          >
            <Icon name="redo" />
            Reset
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  currentBoxInfo: state.boxInfoStore.currentBoxInfo,
  replaceBoxInfo: state.boxInfoStore.replaceBoxInfo
});

const mapDispatchToProp = dispatch => ({
  getCurrentBoxInfo: (boxId, self) => dispatch(getCurrentBoxInfo(boxId, self)),
  getReplaceBoxInfo: (boxId, self) => dispatch(getReplaceBoxInfo(boxId, self)),
  updateCurrentBoxInfo: (sock, self) =>
    dispatch(updateCurrentBoxInfo(sock, self)),
  updateBoxInfo: (sock, self) => dispatch(updateBoxInfo(sock, self)),
  resetBoxInfo: self => dispatch(resetBoxInfo(self)),
  saveBoxInfo: self => dispatch(saveBoxInfo(self))
});
export default connect(mapStateToProp, mapDispatchToProp)(ReplaceSocks);
