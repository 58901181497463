import echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getChartOptionsReport } from "../constant/chartOptionsReport";

/**
 * Create by shengli.zhang on 2019/4/8
 */

class reportCharts extends Component {
  componentDidMount() {
    // this.getUserData();
  }

  componentDidUpdate() {
    // this.getUserData();
  }

  getUserData() {
    let myChart = echarts.init(document.getElementById("main" + this.props.i));
    let deltaData = this.props.delta;
    let stepsData = this.props.steps;
    let leftFootTempData = this.props.leftFootTemp;
    let rightFootTempData = this.props.rightFootTemp;
    let leftPositionData = this.props.leftPosition;
    let rightPositionData = this.props.rightPosition;
    let options = getChartOptionsReport(
      deltaData,
      stepsData,
      leftFootTempData,
      rightFootTempData,
      leftPositionData,
      rightPositionData,
      this.props.min1_or_min30
    );
    myChart.setOption(options);
    window.onresize = function() {
      myChart.resize();
    };
    myChart.clear();
  }

  onChartReady = echarts => {
    // console.log("echart is ready", echarts);
  };

  render() {
    // console.log("this.props.delta", this.props.delta);
    return (
      <div>
        {/*<div*/}
        {/*  id={"main" + this.props.i}*/}
        {/*  style={{ width: "100%", height: 2000 }}*/}
        {/*/>*/}
        <ReactEcharts
          option={getChartOptionsReport(
            this.props.min30,
            this.props.StartDate,
            this.props.EndDate
          )}
          style={{ height: 1500 }}
          lazyUpdate={true}
          onChartReady={this.onChartReady}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  delta: state.patientsListStore.delta,
  steps: state.patientsListStore.steps,
  min1_or_min30: state.patientsListStore.min1_or_min30,
  leftFootTemp: state.patientsListStore.leftFootTemp,
  rightFootTemp: state.patientsListStore.rightFootTemp,
  leftPosition: state.patientsListStore.leftPosition,
  rightPosition: state.patientsListStore.rightPosition
});
const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(reportCharts);
