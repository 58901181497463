import React from "react";
import { Tree, Empty, Spin, message, Button, Modal, Checkbox, Switch } from 'antd';
import { listRpmNoteDetailedTemplates, createRpmNoteTemplate, updateRpmNoteTemplate, deleteRpmNoteTemplate } from "../../graphql/API";
import { Form } from 'semantic-ui-react'

const { confirm } = Modal;
const referTablesName = ['patient', 'site', 'staff', 'footStatus']

export default class DMTTemplateManagement extends React.Component {

	state = {
		loading: false,
		originList: [],
		treeData: [],
		showEditModal: false,
		editModalLoading: false,
		editingItem: null,
		showCreateModal: false,
		createModalLoading: false,
		creatingItem: {},
	}

	componentDidMount() {
		this.refreshTree()
	}

	refreshTree = () => {
		this.setState({
			loading: true
		});
		listRpmNoteDetailedTemplates().then((lists) => {
			lists = lists.sort((e1, e2) => {
				if (e1.order === null) {
					return 1
				} else {
					if (e2.order == null) {
						return -1
					} else {
						return e1.order - e2.order
					}
				}
			})
			lists.forEach(tmpl => {
				if (tmpl.parentId) {
					let parent = lists.find(e => e.id === tmpl.parentId);
					if (parent) {
						if (!parent.children) {
							parent.children = [];
						}
						parent.children.push(tmpl);
					}
				}
				if (tmpl.customVar) {
					tmpl.customVar = JSON.parse(tmpl.customVar);
				}

				// adapte antd tree
				tmpl.key = tmpl.id;
			});
			this.originList = lists;
			this.setState({
				treeData: lists.filter(e => !e.parentId)
			})
		}).finally(() => {
			this.setState({
				loading: false
			})
		})
	}

	onSelect = (selectedKeys, info) => {
		if (info.selected) {
			const editingItem = this.originList.find(item => item.key === selectedKeys[0]);
			this.setState({
				editingItem: {...editingItem},
				showEditModal: true
			});
		}
  };

	onDragEnter = (info) => {
		message.warning('not supported yet');
	}

	onDrop = (info) => {
		message.warning('not supported yet');
	}

	onSubmitEditModal = () => {
		this.setState({
			editModalLoading: true
		})
		const input = {...this.state.editingItem};
		delete input.key
		delete input.children
		updateRpmNoteTemplate(input).finally(() => {
			this.setState({
				showEditModal: false,
				editModalLoading: false
			})
			this.refreshTree()
		})
	}

	onCancelEditModal = () => {
		this.setState({
			showEditModal: false
		})
	}

	onConfirmDeleteTemplate = () => {
		confirm({
			title: 'Are you sure delete this template?',
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			onOk: this.deleteTemplate,
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	deleteTemplate = () => {
		this.setState({
			editModalLoading: true
		})
		deleteRpmNoteTemplate(this.state.editingItem.id).then(() => {
			message.success('deleted')
			this.setState({
				showEditModal: false,
				editModalLoading: false
			})
			this.refreshTree()
		}).catch(err => {
			console.log('delete template err:', JSON.stringify(err))
			// message(JSON.stringify(err.descrition))
		})
	}

	renderEditModal() {
		if (!this.state.editingItem) {
			return null;
		}
		const isTopLevel = this.state.editingItem && !this.state.editingItem.parentId;
		const hasChildren = this.state.editingItem && this.state.editingItem.children;
		return (
			<Modal
        title="Edit Template"
        visible={this.state.showEditModal}
        onOk={this.onSubmitEditModal}
        confirmLoading={this.state.editModalLoading}
        onCancel={this.onCancelEditModal}
				width={800}
      >
				<Form>
					<Form.Field>
						{ isTopLevel && <Button style={{marginRight: 12}} type="primary" onClick={() => this.openCreateModal(this.state.editingItem.id)}>Add Sub Template</Button>}
						{ !hasChildren && <Button type="danger" onClick={this.onConfirmDeleteTemplate}>Delete Template</Button>}
					</Form.Field>
					<Form.Field required>
						<label>Title</label>
						<input name="title" value={this.state.editingItem.title || ''} onChange={e => {
							this.setState({
								editingItem: {
									...this.state.editingItem,
									title: e.target.value
								}
							})
						}} />
					</Form.Field>
					<Form.Field>
						<label>Order</label>
						<input name="order" value={this.state.editingItem.order || ''} onChange={e => {
							this.setState({
								editingItem: {
									...this.state.editingItem,
									order: e.target.value
								}
							})
						}} />
					</Form.Field>
					{ !hasChildren && (
					<>
						<Form.Field>
							<label>Template</label>
							<textarea name="content" value={this.state.editingItem.content || ''} onChange={e => {
								this.setState({
									editingItem: {
										...this.state.editingItem,
										content: e.target.value
									}
								})
							}} />
						</Form.Field>
						<Form.Field>
							<label>Refered tables</label>
							<Checkbox.Group options={referTablesName} value={this.state.editingItem.referTables} onChange={checkedValues => {
								this.setState({
									editingItem: {
										...this.state.editingItem,
										referTables: checkedValues
									}
								})
							}} />
						</Form.Field>
					</>
					) }
				</Form>
      </Modal>
		)
	}

	openCreateModal = (parentId) => {
		if (parentId) {
			this.setState({
				showEditModal: false,
				showCreateModal: true,
				creatingItem: {
					parentId,
					isDirectory: false
				}
			})
		} else {
			this.setState({
				showCreateModal: true,
				creatingItem: {
					isDirectory: true
				}
			})
		}
	}

	onToggleDirectory = (checked) => {
		this.setState({
			creatingItem: {
				...this.state.creatingItem,
				isDirectory: checked
			}
		})
	}

	onSubmitCreateModal = () => {
		this.setState({
			createModalLoading: true
		})
		let input = null;
		if (this.state.creatingItem.isDirectory) {
			input = {
				title: this.state.creatingItem.title,
				order: this.state.creatingItem.order
			}
		} else {
			input = {...this.state.creatingItem};
			delete input.isDirectory
		}

		createRpmNoteTemplate(input).finally(() => {
			this.setState({
				showCreateModal: false,
				createModalLoading: false
			})
			this.refreshTree()
		})
	}

	onCancelCreateModal = () => {
		this.setState({
			showCreateModal: false
		})
	}

	renderCreateModal() {
		const isSecondLevel = this.state.creatingItem && this.state.creatingItem.parentId;
		return (
			<Modal
        title="Create Template"
        visible={this.state.showCreateModal}
        onOk={this.onSubmitCreateModal}
        confirmLoading={this.state.createModalLoading}
        onCancel={this.onCancelCreateModal}
				width={800}
      >
				<Form>
					<Form.Field required>
						<label>Title</label>
						<input name="title" value={this.state.creatingItem.title || ''} onChange={e => {
							this.setState({
								creatingItem: {
									...this.state.creatingItem,
									title: e.target.value
								}
							})
						}} />
					</Form.Field>
					<Form.Field>
						<label>Order</label>
						<input name="order" value={this.state.creatingItem.order || ''} onChange={e => {
							this.setState({
								creatingItem: {
									...this.state.creatingItem,
									order: e.target.value
								}
							})
						}} />
					</Form.Field>
					{ !isSecondLevel && (
					<Form.Field>
						<label>Is Directory</label>
						<Switch checked={this.state.creatingItem.isDirectory} onChange={this.onToggleDirectory} />
					</Form.Field>
					)}
					{ !this.state.creatingItem.isDirectory && (
					<>
						<Form.Field required={!this.state.creatingItem.isDirectory}>
							<label>Template</label>
							<textarea name="content" value={this.state.creatingItem.content || ''} onChange={e => {
								this.setState({
									creatingItem: {
										...this.state.creatingItem,
										content: e.target.value
									}
								})
							}} />
						</Form.Field>
						<Form.Field>
							<label>Refered tables</label>
							<Checkbox.Group options={referTablesName} value={this.state.creatingItem.referTables} onChange={checkedValues => {
								this.setState({
									creatingItem: {
										...this.state.creatingItem,
										referTables: checkedValues
									}
								})
							}} />
						</Form.Field>
					</>
					) }
				</Form>
      </Modal>
		)
	}

	render() {
    return (
			<div className="device-list-container" style={{textAlign: 'left'}}>
				<Button type="primary" onClick={() => this.openCreateModal()}>Add Template</Button>
				{
					this.state.loading ? <Spin /> :
					this.state.treeData.length === 0 ? (
						<Empty />
					) : (
						<Tree
							className="draggable-tree"
							draggable
							onSelect={this.onSelect}
							onDragEnter={this.onDragEnter}
							onDrop={this.onDrop}
							treeData={this.state.treeData}
						/>
					)
				}
				{ this.renderCreateModal() }
				{ this.renderEditModal() }
			</div>
    );
  }

}