import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { unlinkBox } from "../action/sockAction";
import ButtonSuccess from "../home/buttonSuccess";
import { notification } from 'antd';
import "../share/button.css";
import "./unlink.css";
class UnlinkBox extends Component {

  state = { open: false, confirmSuccess: false, loading: false };

  show = () => {
    this.setState({ open: true, confirmSuccess: false });
  };
  handleConfirm = () => {
    this.setState({ loading: true });
    this.props.unlinkBox(this.props.item.userId, this.props.boxId, this.props.id)
      .then(res => {
        this.setState({
          loading: false
        })
        console.log("res:", res)
        if (res.success) {
          this.setState({
            confirmSuccess: true
          })
          // automatically close after success animation
          setTimeout(() => {
            this.setState({
              open: false
            })
          }, 500)
        } else {
          let desc = null
          if (res.msg) {
            desc = res.msg
          } else if (res.error) {
            if (res.error.msg) {
              desc = res.error.msg
            } else if (res.error instanceof Error) {
              desc = res.error.message
            } else {
              desc = JSON.stringify(res.error)
            }
          } else {
            desc = JSON.stringify(res)
          }
          notification.error({
            message: 'System Error',
            description: desc
          });
        }
      }).catch(error => {
        this.setState({
          loading: false
        })
        if (error instanceof Error) {
          return notification.error({
            message: 'System Error',
            description: error.message
          });
        }
        // graphQL error
        if (error.errors) {
          notification.error({
            message: 'System Error',
            description: error.errors[0].message
          });
        }
      });
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini">
            Unlink Box
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header>
          {this.state.confirmSuccess
            ? "Unlink the box success"
            : `Unlink box`}
        </Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <p>Are you sure to unlink box "{this.props.boxId}"?</p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={this.state.loading}
            color="green"
            onClick={this.handleConfirm}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={this.handleCancel}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProp = dispatch => ({
  unlinkBox: (userId, sockId, id) =>
    dispatch(unlinkBox(userId, sockId, id))
});
export default connect(null, mapDispatchToProp)(UnlinkBox);
