import {
  RPM_NOTE_TEMPLATES,
  LIST_ICD_10_CODES,
  LIST_MEDICAL_CONDITIONS,
} from "../types/types";

const initialState = {
  rpmNoteTmpls: [],
  ICD10Codes: [],
};

export default function authGroup(state = initialState, action) {
  switch (action.type) {
    case RPM_NOTE_TEMPLATES:
      return {
        ...state,
        rpmNoteTmpls: action.tmpls
      };
    case LIST_ICD_10_CODES:
      return {
        ...state,
        ICD10Codes: action.payload
      }
    case LIST_MEDICAL_CONDITIONS:
      return {
        ...state,
        medicalConditions: action.payload
      }
    default:
      return state;
  }
}
