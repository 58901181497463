import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import "./NewSite.css";
class SiteSuccess extends Component {
  render() {
    return (
      <div className="success_box">
        <Icon name="check circle" id="siteIcon" />
        <p className="siteTitle">Congratulations!</p>
        <p className="finishtext">
          <span>New Site</span>
          <span className="addUnderline">
            &nbsp;{this.props.SuccessSiteName}&nbsp;
          </span>
          <span>was successfully created.</span>
        </p>
      </div>
    );
  }
}
const mapStateToProp = state => ({});

const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(SiteSuccess);
