import React, { Component } from "react";
import { Modal, Button, Icon, Checkbox } from "semantic-ui-react";
import "./EditCompliance.css";
import { connect } from "react-redux";
import { addComplianceReviewReq } from "../action/addAction";
import ButtonSuccess from "./buttonSuccess";
import "./ViewPhoto.css";
import CalendarRange from "./calendar";
class EditCompliance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      checkboxGroup: this.props.reviewed ? true : false,
      confirmSuccess: false
    };
    this.handleCheck = this.handleCheck.bind(this);
  }
  handleOpen = () => this.setState({ open: true, confirmSuccess: false });
  close = () => this.setState({ open: false, confirmSuccess: false });
  handleCheck() {
    this.setState({ checkboxGroup: !this.state.checkboxGroup });
  }
  submit = () => {
    this.setState({ confirmSuccess: true });
    const { addComplianceReviewReq } = this.props;

    const reviewReq = {
      id: this.props.id,
      complianceReviewReq: this.state.checkboxGroup,
      complianceReviewed: false
    };
    addComplianceReviewReq(reviewReq, this);
  };
  render() {
    return (
      <Modal
        trigger={
          <Button size="mini" onClick={this.handleOpen}>
            Review
          </Button>
        }
        open={this.state.open}
        className="edit-compliance"
      >
        <Modal.Header className="edit-title">Review Request</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <CalendarRange
                id={this.props.id}
                complianceId={this.props.complianceId}
                compliancezone={this.props.compliancezone}
              />
              <Checkbox
                className="checkboxVertical"
                checked={this.state.checkboxGroup}
                onChange={this.handleCheck}
              />
              <label>Request Review</label>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <button onClick={e => this.submit()} className="submitbutton">
            {this.state.confirmSuccess ? "Close" : "Submit"}
          </button>
          <button onClick={e => this.close()} className="closebutton">
            <Icon name="remove" className="closeicon" />
          </button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  complianceList: state.patientsListStore.complianceList
});
const mapDispatchToProp = dispatch => ({
  addComplianceReviewReq: (reviewReq, self) =>
    dispatch(addComplianceReviewReq(reviewReq, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(EditCompliance);
