import { Checkbox, DatePicker, message, Select } from "antd";
import { Auth } from "aws-amplify";
import moment from "moment-timezone";
import queryString from "query-string";
import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Button } from "semantic-ui-react";
import { getlistClinicSites, getlistStaff } from "../../action/registerAction";
import Progress from "../../home/progress";
import "../../share/common.css";
import getPatchTrProps from "../../tools/reactTablePatch";
import "../biMonthly.css";
import "../report.css";
import "../selectComponent.css";
import { removeAllSpace } from "../../tools/stringProcess";
import {
  generateBillingReportVa,
  generateVaZipReport,
  getPatientBySiteIds
} from "../../favorite/report-va-action";
import { v1 as uuid } from "uuid";
import _ from "lodash";

const { Option } = Select;
Modal.setAppElement("div");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};
class ReportReviewed_va extends Component {
  constructor(props) {
    super(props);
    this.needs = [];
    this.state = {
      userGroup: [],
      modalIsOpen: false,
      siteId: "",
      selectedPatients: [],
      reportPeriod: "allMonth",
      billingMonth: moment().format("YYYY-MM"),
      allPatients: []
    };
    this.onChangeBillingMonth = this.onChangeBillingMonth.bind(this);
  }
  onChangeBillingMonth(date, dateString) {
    this.setState({ billingMonth: dateString });
  }

  getDoctorName = doctorId => {
    const doctor = this.props.listStaff.find(e => e.email === doctorId);
    if (!doctor) {
      return "";
    }

    let name = `${doctor.firstName} ${doctor.lastName}`;
    name = name.trim();
    if (doctor.isPrescribing && !name.startsWith("Dr.")) {
      name = "Dr. " + name;
    }

    return name;
  };

  async componentDidMount() {
    const { getlistStaff, getlistClinicSites } = this.props;
    getlistStaff();
    getlistClinicSites();
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    this.setState({ userGroup: groups });
    if (window.location.href.includes("siteId")) {
      const url = window.location.href.split("?")[1];
      let params = queryString.parse(url);
      const siteId = params.siteId;
      const startDate = params.startDate;
      const endDate = params.endDate;
      this.setState({
        siteId: siteId,
        startDate: startDate,
        endDate: endDate
      });
    }
  }

  handleCheck(checked, index) {
    let selectedPatients = [...this.state.selectedPatients];
    console.log(selectedPatients);
    if (checked) {
      selectedPatients.push(index);
    } else {
      selectedPatients = selectedPatients.filter(p => p !== index);
    }

    this.setState({
      selectedPatients
    });
  }

  searchUsers = () => {
    const { getPatientBySiteIds } = this.props;
    if (!_.isEmpty(this.state.siteId)) {
      getPatientBySiteIds(this.state.siteId, this);
    }
    // if (this.state.siteId.length) {
    //   let siteId = this.state.siteId
    //     .map(p => {
    //       return p;
    //     })
    //     .join("_");
    //   getPatientBySiteIds(siteId, this);
    // }
  };

  gotoDoctorDetailPage(userId, id, date) {}

  handleGenerateVaZipReport = async () => {
    if (_.isEmpty(this.state.siteId)) {
      message.info("Please select siteId...");
      return;
    }
    const input = {
      siteId: this.state.siteId,
      reportMonth: this.state.billingMonth,
      token: uuid()
    };
    const { generateVaZipReport } = this.props;
    generateVaZipReport(input);
  };

  handleGenerateReport = async () => {
    const reportPatients = this.state.selectedPatients.map(index => {
      const patient = this.state.allPatients[index];
      return {
        sirenId: patient.sirenId,
        userId: patient.userId,
        reportMonth: this.state.billingMonth
      };
    });
    if (this.state.selectedPatients.length !== 1) {
      message.info("Please select one patient record to download a report.");
      return;
    }
    const { generateBillingReportVa } = this.props;
    const session = await Auth.currentSession();
    const input = {
      siteIds: "",
      source: "ADMIN",
      token: uuid(),
      doctorId: session.getIdToken().payload.email,
      reportPatients,
      generatedDate: moment().format("YYYY-MM-DD"),
      isVaReport: true
    };
    generateBillingReportVa(input);
  };

  render() {
    const { MonthPicker } = DatePicker;
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(
        rowInfo.original.userId,
        rowInfo.original.id,
        moment().format("YYYY-MM-DD")
      );
    });
    return (
      <div className="device-list-container">
        {/*<Report />*/}
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div
          className="search_patient_container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span className="search_label">SiteID:</span>
          <Select
            // mode="multiple"
            style={{ maxWidth: "40%", minWidth: 200 }}
            placeholder="Please select sites"
            value={this.state.siteId}
            onChange={value => {
              this.setState({
                siteId: value
              });
            }}
            // onSelect={value => {
            //   // pre-select all child sites
            //   const nextSites = new Set(this.state.siteId);
            //   this.props.clinicSites
            //     .map(site => site.siteId)
            //     .filter(site => site.startsWith(value))
            //     .forEach(site => nextSites.add(site));
            //
            //   // special rule. Bay Area
            //   if (value === "0136") {
            //     nextSites.add("0001");
            //     nextSites.add("0002");
            //     nextSites.add("0028");
            //     nextSites.add("0041");
            //   }
            //   this.setState({
            //     siteId: [...nextSites]
            //   });
            // }}
            optionLabelProp="value"
            optionFilterProp="children"
            filterOption={(input, option) =>
              removeAllSpace(option.props.children)
                .toLowerCase()
                .indexOf(removeAllSpace(input).toLowerCase()) >= 0 ||
              option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.props.clinicSites.map(site => (
              <Option key={site.siteId} value={site.siteId}>
                {`${site.siteId} - ${site.name}`}
              </Option>
            ))}
          </Select>
          <span className="search_label search_time_label">Month:</span>
          <MonthPicker
            onChange={this.onChangeBillingMonth}
            value={moment(this.state.billingMonth, "YYYY-MM")}
            allowClear={false}
          />

          <Button
            size="small"
            onClick={this.searchUsers}
            style={{ marginLeft: 20 }}
            className="fixedButtonHeight"
          >
            Search
          </Button>
          <Button
            onClick={this.handleGenerateReport}
            style={{
              marginLeft: 30,
              whiteSpace: "nowrap",
              marginTop: 20,
              marginBottom: 20
            }}
            disabled={this.props.showGenerateVaDelay}
          >
            Generate VA Report
          </Button>
          <Button
            onClick={this.handleGenerateVaZipReport}
            style={{
              marginLeft: 30,
              whiteSpace: "nowrap",
              marginTop: 20,
              marginBottom: 20
            }}
            disabled={this.props.showGenerateVaDelay}
          >
            Generate VA Zip Report
          </Button>
          {this.props.showGenerateVaDelay ? (
            <div
              style={{
                color: "red",
                marginLeft: 20,
                marginTop: 5,
                fontWeight: "bold",
                whiteSpace: "nowrap"
              }}
            >
              {"Generating Report in " + this.props.generateVaDelay}
            </div>
          ) : null}
        </div>
        <ReactTable
          getTrProps={trProps}
          data={
            this.state.allPatients &&
            this.state.allPatients.map(p => {
              const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
              const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
              const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
              const initializedDate = p.initializedDate
                ? p.initializedDate.split("T")[0]
                : "";
              const appInitialized = p.appInitialized ? p.appInitialized : "No";
              const diagnosisCodes = p.diagnosisCodes
                ? p.diagnosisCodes.replace(/_/g, ",")
                : "";
              const billingMonth = moment(this.state.billingMonth).format(
                "MM/YYYY"
              );
              return {
                ...p,
                createdAt,
                sirenId,
                initializedDate,
                dispensedDay,
                diagnosisCodes,
                billingMonth,
                appInitialized
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) => {
            if (!row[filter.id]) {
              return false;
            }
            return row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          }}
          columns={[
            {
              columns: [
                {
                  accessor: "",
                  maxWidth: 60,
                  sortable: false,
                  filterable: false,
                  Cell: props => {
                    return (
                      <div onClick={e => e.stopPropagation()}>
                        <Checkbox
                          onChange={(e, data) =>
                            this.handleCheck(e.target.checked, props.index)
                          }
                          checked={this.state.selectedPatients.includes(
                            props.index
                          )}
                          name="selectSite"
                        />
                      </div>
                    );
                  }
                }
              ]
            },
            {
              Header: "SiteID",
              accessor: "siteId",
              maxWidth: 60
            },
            {
              Header: "SiteName",
              accessor: "siteName",
              minWidth: 100
            },
            {
              Header: "Doctor",
              accessor: "doctorId",
              minWidth: 140,
              Cell: props => this.getDoctorName(props.value)
            },
            {
              Header: "SirenID",
              accessor: "sirenId",
              maxWidth: 60
            },
            {
              Header: "PatientID",
              accessor: "patientId"
            },
            {
              Header: "First Name",
              accessor: "firstName"
            },
            {
              Header: "Last Name",
              accessor: "lastName"
            },
            {
              Header: "Date of Birth",
              accessor: "birthday"
            },
            {
              Header: "Month",
              accessor: "billingMonth"
            }
          ]}
          defaultSorted={[{ id: "sirenId", desc: true }]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => {
  const doctorRoleList = state.patientsListStore.doctorRoleList;
  const bliSirenIdReport = state.monthlyReportStore.bliSirenIdReport;
  const filterGroup = state.patientsListStore.filterGroup;
  const clinicSites = state.patientsListStore.clinicSites;
  return {
    doctorRoleList: doctorRoleList,
    bliSirenIdReport: bliSirenIdReport,
    filterGroup: filterGroup,
    listStaff: state.patientsListStore.listStaff,
    clinicSites,
    showGenerateVaDelay: state.monthlyReportStore.showGenerateVaDelay,
    generateVaDelay: state.monthlyReportStore.generateVaDelay
  };
};
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getlistStaff: () => dispatch(getlistStaff()),
  getPatientBySiteIds: (siteId, self) =>
    dispatch(getPatientBySiteIds(siteId, self)),
  generateBillingReportVa: input => dispatch(generateBillingReportVa(input)),
  generateVaZipReport: input => dispatch(generateVaZipReport(input))
});
export default connect(mapStateToProp, mapDispatchToProp)(ReportReviewed_va);
