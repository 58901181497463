import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import "../share/button.css";
import "../home/AccountStatus.css";
import { Input, Modal as AntdModal } from "antd";
import _ from "lodash";
import { addShoeTag } from "../action/shoeTagAction";
import { removeAllSpace, uppercaseRmSpace } from "../tools/stringProcess";
import moment from "moment";

class AddShoeTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mac: "",
      authCode: "",
      disableCreate: false
    };
  }
  show = () => {
    this.setState({
      open: true
    });
    this.tagId =
      moment().format("YYYYMMDD") + Math.round(Math.random() * 1000000);
  };

  isMacValid = mac => {
    const regexMac = /^([0-9A-F]{2}[:]){5}([0-9A-F]{2})$/i;
    return regexMac.test(mac);
  };

  handleConfirm = () => {
    if (_.isEmpty(removeAllSpace(this.state.mac))) {
      AntdModal.warning({
        title: "Add Tag Failed",
        content: "MacAddress can't be empty.",
        centered: true
      });
      return;
    }
    if (_.isEmpty(removeAllSpace(this.state.authCode))) {
      AntdModal.warning({
        title: "Add Tag Failed",
        content: "Auth Code can't be empty.",
        centered: true
      });
      return;
    }
    if (!this.isMacValid(this.state.mac)) {
      AntdModal.warning({
        title: "Add Tag Failed",
        content: "MacAddress is error, please check it carefully.",
        centered: true
      });
      return;
    }

    const input = {
      mac: this.state.mac,
      tagId: this.tagId,
      authCode: this.state.authCode
    };
    this.props.addShoeTag(input, this);
  };
  handleCancel = () =>
    this.setState({ open: false, mac: "", authCode: "", disableCreate: false });

  handleMacChange = e => {
    this.setState({
      mac: uppercaseRmSpace(e.target.value.replace(/[^a-fA-F0-9:]/g, ""))
    });
  };
  handleAuthCodeChange = e => {
    this.setState({
      authCode: uppercaseRmSpace(e.target.value.replace(/[^\w]/g, ""))
    });
  };

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Button size="small" onClick={this.show}>
            Add Tag
          </Button>
        }
        open={open}
        className="add-box-form-container"
        style={{ width: "40%" }}
      >
        <Modal.Header>Add Tag</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field width="16">
                <Input
                  addonBefore={
                    <div style={{ textAlign: "center", width: 70 }}>
                      <strong>Tag ID</strong>
                    </div>
                  }
                  value={this.tagId}
                  size="large"
                  disabled
                />
                <div style={{ height: 20 }} />
                <Input
                  addonBefore={
                    <div style={{ textAlign: "center", width: 70 }}>
                      <strong>MAC</strong>
                    </div>
                  }
                  value={this.state.mac}
                  size="large"
                  onChange={this.handleMacChange}
                  placeholder="02:11:11:01:02:03"
                  maxLength={17}
                />
                <div style={{ height: 20 }} />
                <Input
                  addonBefore={
                    <div style={{ textAlign: "center", width: 70 }}>
                      <strong>Auth Code</strong>
                    </div>
                  }
                  value={this.state.authCode}
                  size="large"
                  onChange={this.handleAuthCodeChange}
                  maxLength={10}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
            disabled={this.state.disableCreate}
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {"Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({
  addShoeTag: (input, self) => dispatch(addShoeTag(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(AddShoeTag);
