import { Select } from "antd";
import { isEmpty } from "lodash";
import React, { Component } from "react";
import { Control } from "react-keeper";
import ReactPhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { Button, Checkbox, Icon, Input, Modal, Popup } from "semantic-ui-react";
import { createSiteId } from "../../action/registerAction";
import { checkSiteNPI } from "../../action/toolsAction";
import "./NewSite.css";
import SiteSuccess from "./SiteSuccess";
import message from "../../tools/message-info";
import { QuestionCircleFilled } from "@ant-design/icons";
import { getEMRList } from "../../action/emrAction";
import { phoneWithCountryFormat } from "../../tools/phoneFormat";
import _ from "lodash";
class NewSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      siteName: "",
      city: "",
      siteState: "",
      zip: "",
      address: "",
      phone: "",
      fax: "",
      siteNPI: "",
      siteNPIs: [],
      contact: "",
      addSiteSuccess: false,
      serviceDayType: "DISPENSED_DAY",
      ifAutoReport: false,
      parentSiteId: "",
      newSiteNPI: "",
      IRBConsent: "Not Approved",
      hubContract: "4G/Wifi",
      EMRSystem: "",
      disabledCreate: false
    };
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.IRBConsentChanged = this.IRBConsentChanged.bind(this);
    this.hubContractChanged = this.hubContractChanged.bind(this);
    this.EMRSystemChanged = this.EMRSystemChanged.bind(this);
    this.patientSiteChanged = this.patientSiteChanged.bind(this);
    this.handleCheckAuto = this.handleCheckAuto.bind(this);
    this.changeSiteName = this.changeSiteName.bind(this);
  }
  handleTypeChange(value) {
    this.setState({ serviceDayType: value });
  }
  IRBConsentChanged(value) {
    this.setState({ IRBConsent: value });
  }
  hubContractChanged(value) {
    this.setState({ hubContract: value });
  }
  EMRSystemChanged(value) {
    this.setState({ EMRSystem: value });
  }
  patientSiteChanged(value) {
    this.setState({ parentSiteId: value });
  }
  handleCheckAuto() {
    this.setState({ ifAutoReport: !this.state.ifAutoReport });
  }

  handleOpen = () => {
    this.setState({
      open: true,
      siteName: "",
      city: "",
      siteState: "",
      zip: "",
      address: "",
      phone: "",
      fax: "",
      siteNPI: "",
      siteNPIs: [],
      contact: "",
      addSiteSuccess: false,
      serviceDayType: "DISPENSED_DAY",
      ifAutoReport: false,
      parentSiteId: "",
      newSiteNPI: "",
      EMRSystem: "",
      onStaffing: "No"
    });
    if (this.props.EMRSystemList.length === 0) {
      this.props.getEMRList();
    }
  };
  close = () => this.setState({ open: false, addSiteSuccess: false });
  create() {
    Control.go("/SiteSuccess");
  }
  changeSiteName = event => {
    this.setState({ siteName: event.target.value });
  };

  changeAddress = event => {
    this.setState({ address: event.target.value });
  };

  onChangeCity = event => {
    this.setState({ city: event.target.value });
  };

  onChangeState = event => {
    this.setState({ siteState: event.target.value });
  };
  onChangeZip = event => {
    this.setState({ zip: event.target.value });
  };

  changeSiteNPI = event => {
    this.setState({ siteNPI: event });
  };

  changeSearchSiteNPI(value) {
    if (!!value) {
      this.setState({ newSiteNPI: value });
    }
  }

  changeBlurSiteNPI(value) {
    const { newSiteNPI } = this.state;
    if (!!newSiteNPI) {
      this.changeSiteNPI(newSiteNPI);
      this.setState({ newSiteNPI: "" });
    }
  }

  changePhone = phone => {
    let noSym1 = phone.replace("(", "");
    let noSym2 = noSym1.replace(")", "");
    let noSym3 = noSym2.replace("-", "");
    let noBlank = noSym3.replace(/\s+/g, "");
    this.setState({ phone: noBlank });
  };

  changeFax = event => {
    this.setState({ fax: event.target.value });
  };

  changeContactName = event => {
    this.setState({ contact: event.target.value });
  };
  createSiteId = () => {
    const { createSiteId } = this.props;
    if (this.state.siteName === "") {
      message.info("Please input Site Name");
      return;
    }
    if (this.state.address === "") {
      message.info("Please input Mailing Address");
      return;
    }
    // if (this.state.phone === "") {
    //   message.info("Please input Phone");
    //   return;
    // }
    if (!_.isEmpty(this.state.phone) && _.isEmpty(phoneWithCountryFormat(this.state.phone))) {
      message.info("Invalid phone number");
      return;
    }
    if (this.state.contact === "") {
      message.info("Please input Primary Contact");
      return;
    }
    if (isEmpty(this.state.city)) {
      message.info("Please input City");
      return;
    }
    if (isEmpty(this.state.siteState)) {
      message.info("Please input State");
      return;
    }
    if (isEmpty(this.state.zip)) {
      message.info("Please input Zip");
      return;
    }
    let input = {
      siteId: "0001",
      name: this.state.siteName,
      address: this.state.address,
      city: this.state.city,
      state: this.state.siteState,
      zip: this.state.zip,
      phone: this.state.phone,
      contact: this.state.contact,
      serviceDate: this.state.serviceDayType,
      autoDoctorReport: this.state.ifAutoReport,
      hubContract: this.state.hubContract,
      onStaffing: this.state.onStaffing
    };
    if (!isEmpty(this.state.parentSiteId)) {
      Object.assign(input, { parentSiteId: this.state.parentSiteId });
    }
    if (this.state.siteNPI !== "") {
      Object.assign(input, { siteNPI: this.state.siteNPI });
    }
    if (!isEmpty(this.state.IRBConsent)) {
      Object.assign(input, { IRBConsent: this.state.IRBConsent });
    }
    if (!isEmpty(this.state.EMRSystem)) {
      Object.assign(input, { EMRSystem: this.state.EMRSystem });
    }
    const CreateSiteId = { input };
    createSiteId(CreateSiteId, this);
  };

  refreshNewSite = () => {
    this.setState({
      address: "",
      phone: "",
      fax: "",
      contact: "",
      serviceDayType: "DISPENSED_DAY",
      ifAutoReport: false
    });
  };

  checkSiteNpi() {
    if (isEmpty(this.state.siteName)) {
      return;
    }
    const { checkSiteNPI } = this.props;
    checkSiteNPI(this.state.siteName, this);
  }

  render() {
    const { open } = this.state;
    const { Option } = Select;
    return (
      <Modal
        trigger={
          <Button size="small" onClick={this.handleOpen}>
            New Site
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>
          {this.state.addSiteSuccess
            ? "New Site Has Been Created"
            : "Create New Site"}
          {this.state.addSiteSuccess ? null : (
            <Popup
              trigger={<QuestionCircleFilled className="add_site_icon" />}
              position="bottom left"
              hoverable
              flowing
            >
              {/*<Popup.Header className="popHeader">*/}
              {/*  Summary Terminology*/}
              {/*</Popup.Header>*/}
              <Popup.Content>
                <div className="popBox">
                  <div className="popContainer">
                    <p className="popTitle">Auto Report: </p>
                    <p className="popContent">
                      Generates weekly clinic emails regarding compliance and
                      hotspots.
                      <br />
                      <p1>
                        Email preferences can be managed under Report
                        Management.
                      </p1>
                    </p>
                  </div>
                  <div className="popContainer">
                    <p className="popTitle">Service Day Type: </p>
                    <p className="popContent">
                      Date of service as defined by the clinic.
                    </p>
                  </div>
                  <div className="popContainer">
                    <p className="popTitle">Registry Consent: </p>
                    <p className="popContent">
                      Clinic's enrollment status in the Registry study.
                    </p>
                  </div>
                  <div className="popContainer">
                    <p className="popTitle">Site NPI: </p>
                    <p className="popContent">
                      Clinic's National Provider Identifier (NPI).
                    </p>
                  </div>
                </div>
              </Popup.Content>
            </Popup>
          )}
        </Modal.Header>
        <Modal.Content>
          {this.state.addSiteSuccess ? (
            <SiteSuccess SuccessSiteName={this.state.siteName} />
          ) : (
            <div
              className="newSite_box"
              style={{ height: 400, overflowY: "auto" }}
            >
              <div className="newSiteBox">
                <div
                  className="inputTotal"
                  style={{
                    marginTop: "5%"
                  }}
                >
                  <Checkbox
                    className="checkbox_box"
                    checked={this.state.ifAutoReport}
                    onChange={this.handleCheckAuto}
                  />
                  <span className="checkbox_label" style={{ paddingLeft: 10 }}>
                    Auto Report
                  </span>
                </div>
              </div>
              <div className="newSiteBox">
                <div className="inputTotal" style={{ float: "left" }}>
                  <p className="inputLable">
                    Site Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <Input
                    placeholder="Podiatrist Office Name"
                    className="inputContent"
                    onChange={this.changeSiteName}
                    onBlur={event => this.checkSiteNpi(event)}
                  />
                </div>
                <div className="inputTotal" style={{ float: "right" }}>
                  <p className="inputLable">Parent Site</p>
                  <Select
                    className="selectStyle"
                    onChange={this.patientSiteChanged}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.parentSiteId}
                    showSearch
                    optionFilterProp="children"
                  >
                    <option value={""}>None</option>
                    {this.props.clinicSites.map((si, i) => {
                      return (
                        <Option value={si.siteId} key={"si" + i}>
                          {si.siteId + " - " + si.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              <div className="newSiteBox">
                <div className="inputTotal" style={{ float: "left" }}>
                  <p className="inputLable">
                    Phone
                    {/*<span style={{ color: "red" }}>*</span>*/}
                  </p>
                  <ReactPhoneInput
                    inputExtraProps={{
                      name: "phone",
                      required: true,
                      autoFocus: false
                    }}
                    defaultCountry={"us"}
                    value={this.state.phone}
                    onChange={phone => this.changePhone(phone)}
                    disableAreaCodes
                  />
                </div>
                <div className="inputTotal" style={{ float: "right" }}>
                  <p className="inputLable">
                    Primary Contact <span style={{ color: "red" }}>*</span>
                  </p>
                  <Input
                    placeholder="John Smith"
                    className="inputContent"
                    onChange={this.changeContactName}
                  />
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              <div className="newSiteBox">
                <p className="inputLable">
                  Site Address <span style={{ color: "red" }}>*</span>
                </p>
                <Input
                  placeholder="Address"
                  style={{ width: "100%" }}
                  onChange={this.changeAddress}
                />
                <div style={{ marginTop: 10, marginBottom: 20 }}>
                  <Input
                    style={{ width: "40%" }}
                    placeholder="City"
                    onChange={this.onChangeCity}
                  />
                  <Input
                    style={{ width: "30%", marginLeft: "1%" }}
                    placeholder="State"
                    onChange={this.onChangeState}
                  />
                  <Input
                    style={{ width: "28%", marginLeft: "1%" }}
                    placeholder="Zip"
                    onChange={this.onChangeZip}
                  />
                </div>
              </div>
              <div className="newSiteBox">
                <div className="inputTotal" style={{ float: "left" }}>
                  <p className="inputLable">Service Day Type</p>
                  <Select
                    className="selectStyle"
                    onChange={this.handleTypeChange}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.serviceDayType}
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="DISPENSED_DAY">DISPENSED_DAY</Option>
                    <Option value="THE_16TH_DAY">THE_16TH_DAY</Option>
                  </Select>
                </div>
                <div className="inputTotal" style={{ float: "right" }}>
                  <p className="inputLable">Registry Consent</p>
                  <Select
                    className="selectStyle"
                    onChange={this.IRBConsentChanged}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.IRBConsent}
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="Not Approved">Not Approved</Option>
                    <Option value="Approved">Approved</Option>
                  </Select>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              <div className="newSiteBox">
                <div className="inputTotal" style={{ float: "left" }}>
                  <p className="inputLable">Site NPI</p>
                  <Select
                    className="selectStyle"
                    value={this.state.siteNPI}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                    onChange={this.changeSiteNPI}
                    onSearch={value => this.changeSearchSiteNPI(value)}
                    onBlur={value => this.changeBlurSiteNPI(value)}
                  >
                    <Option value={""}>None</Option>
                    {this.state.siteNPIs.map((npi, i) => {
                      return (
                        <Option value={npi.number} key={"npi" + i}>
                          {npi.number +
                            " - " +
                            npi.addresses[0].address_1 +
                            " " +
                            npi.addresses[0].city +
                            ", " +
                            npi.addresses[0].state +
                            " " +
                            npi.addresses[0].postal_code}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="inputTotal" style={{ float: "right" }}>
                  <p className="inputLable">Hub Contract</p>
                  <Select
                    className="selectStyle"
                    onChange={this.hubContractChanged}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.hubContract}
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="Wifi">Wi-Fi</Option>
                    <Option value="4G/Wifi">4G/Wi-Fi</Option>
                  </Select>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
              <div className="newSiteBox">
                <div className="inputTotal" style={{ float: "left" }}>
                  <p className="inputLable">EMR System</p>
                  <Select
                    className="selectStyle"
                    onChange={this.EMRSystemChanged}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.EMRSystem}
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="">None</Option>
                    {this.props.EMRSystemList.map(p => {
                      return (
                        <Option value={p.name} key={p.name}>
                          {p.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="inputTotal" style={{ float: "right" }}>
                  <p className="inputLable">Staffing</p>
                  <Select
                    className="selectStyle"
                    onChange={value => this.setState({ onStaffing: value })}
                    value={this.state.onStaffing}
                    optionFilterProp="children"
                  >
                    <Option value="No">Not On Staffing</Option>
                    <Option value="Yes">On Staffing</Option>
                  </Select>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.createSiteId()}
            style={{
              display: this.state.addSiteSuccess ? "none" : "inline-block"
            }}
            disabled={this.state.disabledCreate}
          >
            <Icon name="checkmark" />
            CREATE SITE
          </Button>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.addSiteSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  EMRSystemList: state.patientsListStore.EMRSystemList
});
const mapDispatchToProp = dispatch => ({
  createSiteId: (CreateSiteId, self) =>
    dispatch(createSiteId(CreateSiteId, self)),
  checkSiteNPI: (siteName, self) => dispatch(checkSiteNPI(siteName, self)),
  getEMRList: () => dispatch(getEMRList())
});
export default connect(mapStateToProp, mapDispatchToProp)(NewSite);
