import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Button, Icon, Input } from "semantic-ui-react";
import Progress from "../home/progress";
import "./PatientLog.css";
import "./simulateSock.css";
import { Modal as AntdModal, Select } from "antd";
import _ from "lodash";
import { checkPatientAppVersion, upgradeApp } from "../action/upgradeAppAction";
import { Auth } from "aws-amplify";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};

class UpgradeApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      appVersion: "",
      upgradeMethod: "All",
      sirenId: "",
      groups: []
    };
    this.upgradeMethodChanged = this.upgradeMethodChanged.bind(this);
  }

  async componentWillMount() {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    this.setState({ groups });
  }

  upgradeMethodChanged(value) {
    this.setState({ upgradeMethod: value });
  }

  onChangeAppVersion = event => {
    this.setState({ appVersion: event.target.value });
  };

  onChangeSirenId = event => {
    this.setState({ sirenId: event.target.value });
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  clear() {
    this.setState({ appVersion: "", upgradeMethod: "All", sirenId: "" });
  }

  warning = content => {
    AntdModal.warning({
      title: "Warning",
      content,
      centered: true
    });
  };

  info = content => {
    AntdModal.info({
      title: "Info",
      content,
      centered: true
    });
  };

  upgradeAppNotifications = (appVersion, userId) => {
    const { upgradeApp } = this.props;
    const UpgradeAppInput = {
      input: {
        appVersion,
        userId
      }
    };
    upgradeApp(UpgradeAppInput, this);
  };

  sendUpgradeNotification() {
    const { checkPatientAppVersion, upgradeApp } = this.props;
    if (this.state.upgradeMethod === "All") {
      if (_.isEmpty(this.state.appVersion)) {
        this.warning("Please input the app version you want to upgrade to.");
        return;
      }
      console.log(
        `upgradeMethod: ${this.state.upgradeMethod}, appVersion: ${this.state.appVersion}`
      );
      const UpgradeAppInput = {
        input: {
          appVersion: this.state.appVersion
        }
      };
      this.setState({ modalIsOpen: true });
      upgradeApp(UpgradeAppInput, this);
    } else {
      if (_.isEmpty(this.state.appVersion)) {
        this.warning("Please input the app version you want to upgrade to.");
        return;
      }
      if (_.isEmpty(this.state.sirenId)) {
        this.warning(
          `Please input sirenId which patient you want to upgrade to ${this.state.appVersion}.`
        );
        return;
      }
      this.setState({ modalIsOpen: true });
      checkPatientAppVersion(this.state.sirenId, this.state.appVersion, this);
    }
  }

  render() {
    const { Option } = Select;
    if (this.state.groups.includes("admin")) {
      return (
        <div className="device-list-container">
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            transitionEnterTimeout={10000}
            transitionLeaveTimeout={10000}
          >
            <Progress />
          </Modal>
          <div>
            <p
              style={{
                textAlign: "left",
                marginLeft: 30,
                marginTop: 20
              }}
            >
              <p1 style={{ fontWeight: "bold", fontSize: 18 }}>
                Please follow the below steps to upgrade app:
              </p1>
              <br />
              <br />
              <p1
                style={{
                  marginLeft: 30,
                  fontWeight: "bold",
                  fontSize: 16
                }}
              >
                1. Input the app version which you want to upgrade.
              </p1>
              <br />
              <br />
              <p1
                style={{
                  textAlign: "left",
                  marginLeft: 30,
                  fontWeight: "bold",
                  fontSize: 16
                }}
              >
                2. Choose an upgrade method:
              </p1>
              <br />
              <br />
              <p1 style={{ fontSize: 14, marginLeft: 30 }}>
                &#160;&#160;&#160;&#160;&#160;
                <p1 style={{ fontWeight: "bold" }}>1) All Users:</p1>
                Upgrade all users whose app version less than the app version
                you input.
              </p1>
              <br />
              <br />
              <p1 style={{ fontSize: 14, marginLeft: 30 }}>
                &#160;&#160;&#160;&#160;&#160;
                <p1 style={{ fontWeight: "bold" }}>2) Single User:</p1>
                Upgrade single user by sirenId whose app version less than the
                app version you input.
              </p1>
            </p>
          </div>
          <div style={{ marginLeft: 80, marginTop: 30, width: 250 }}>
            <div style={{ display: "flex" }}>
              <div style={{ float: "left" }}>
                <div
                  style={{
                    textAlign: "left",
                    marginTop: 7,
                    fontWeight: "bold",
                    marginRight: 10,
                    width: 250
                  }}
                >
                  Upgrade Method:
                </div>
                <Select
                  style={{ float: "left", marginTop: 5 }}
                  className="selectStyle"
                  onChange={this.upgradeMethodChanged}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={this.state.upgradeMethod}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="All">All Users</Option>
                  <Option value="Single">Single User</Option>
                </Select>
              </div>
              <div style={{ float: "left" }}>
                <div
                  style={{
                    textAlign: "left",
                    marginTop: 10,
                    fontWeight: "bold",
                    marginRight: 10
                  }}
                >
                  App Version:
                </div>
                <Input
                  style={{ width: 250, float: "left", marginTop: 5 }}
                  value={this.state.appVersion}
                  placeholder="Ex.1.5.1"
                  onChange={this.onChangeAppVersion}
                />
              </div>
            </div>
            {this.state.upgradeMethod === "Single" ? (
              <div style={{ float: "left" }}>
                <div
                  style={{
                    textAlign: "left",
                    marginTop: 10,
                    fontWeight: "bold",
                    marginRight: 10
                  }}
                >
                  SirenId:
                </div>
                <Input
                  style={{ width: 250, float: "left", marginTop: 5 }}
                  value={this.state.sirenId}
                  placeholder="Ex.288"
                  onChange={this.onChangeSirenId}
                />
              </div>
            ) : null}
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              marginLeft: "13.5%",
              marginTop: 30
            }}
          >
            <Button color="green" onClick={e => this.sendUpgradeNotification()}>
              <Icon name="checkmark" />
              Send
            </Button>
            <Button
              basic
              color="red"
              onClick={e => this.clear()}
              style={{ marginLeft: 30 }}
            >
              <Icon name="remove" />
              Cancel
            </Button>
          </div>
        </div>
      );
    } else {
      return <div className="device-list-container" />;
    }
  }
}
const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({
  upgradeApp: (input, self) => dispatch(upgradeApp(input, self)),
  checkPatientAppVersion: (sirenId, appVersion, self) =>
    dispatch(checkPatientAppVersion(sirenId, appVersion, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(UpgradeApp);
