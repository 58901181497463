import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import getPatchTrProps from "../tools/reactTablePatch";
import { lowercaseRmSpace } from "../tools/stringProcess";
import "../SupportDashboard/account/NewSite.css";
import "../SupportDashboard/account/SiteList.css";
import {
  assignAnyLpnForPatients,
  getLpnStaff,
  getNeedAssignedPatients
} from "../action/lpnManagementAction";
import Modal from "react-modal";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import { Select, Modal as AntdModal, Tag, Popover } from "antd";
import AssignLpnForPatients from "./AssignLpnForPatients";
import _ from "lodash";
import CircleColor from "../home/circle-color";
import ProgramStatusDesc from "../tools/program-status-desc";

const { Option } = Select;
const { confirm } = AntdModal;
const COLOR = {
  PENDING: "#F1BE0A",
  Pending: "#F1BE0A",
  ON_SERVICE: "#73D1AE",
  NOT_ON_SERVICE: "#6F7381",
  ACTIVE: "#73D1AE",
  IDLE: "#AEC3BB",
  PAUSED: "#F1BE0A"
};

class NeedAssignedPatients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      siteIds: [],
      selectedLpn: ""
    };
  }
  componentDidMount() {
    if (this.props.listNeedAssignedPatients.length === 0) {
      this.setState({ modalIsOpen: true });
      this.props.getNeedAssignedPatients(this);
    }
    if (this.props.listSirenStaff.length === 0) {
      this.props.getLpnStaff();
    }
  }

  refreshNeedAssignList = () => {
    this.setState({ modalIsOpen: true });
    const self = this;
    setTimeout(function() {
      self.props.getNeedAssignedPatients(self);
    }, 3000);
  };

  onCheckLpn = value => {
    this.setState({ selectedLpn: value });
  };

  assignPatientToLpn = patient => {
    confirm({
      title: (
        <div>
          Assign patient{" "}
          <strong style={{ textDecorationLine: "underline" }}>
            {patient.firstName} {patient.lastName}
          </strong>{" "}
          to
        </div>
      ),
      content: (
        <div style={{ marginTop: 10 }}>
          <Select
            style={{ minWidth: 240, maxWidth: 400 }}
            placeholder="Please select a nurse"
            onChange={this.onCheckLpn}
          >
            {this.props.listSirenStaff.map(lpn => (
              <Option key={lpn.id} value={lpn.id}>
                {lpn.firstName} {lpn.lastName}
              </Option>
            ))}
          </Select>
        </div>
      ),
      cancelText: "Cancel",
      onCancel: () => {
        this.setState({ selectedLpn: "" });
      },
      okText: "OK",
      onOk: () => {
        const patients = [{ id: patient.id, LPN: this.state.selectedLpn }];
        const nurse = _(this.props.listSirenStaff).find({
          id: this.state.selectedLpn
        });
        if (nurse) {
          const primarySites = nurse.primarySites
            ? JSON.parse(nurse.primarySites)
            : [];
          if (primarySites.includes(patient.siteId)) {
            this.assignPatientToLpnConfirm(patients);
          } else {
            this.showConfirm(patients, patient, nurse);
          }
        }
      },
      centered: true
    });
  };

  showConfirm = (patients, patient, nurse) => {
    confirm({
      title: "Confirming",
      content: (
        <div>
          Patient{" "}
          <strong>
            {patient.firstName} {patient.lastName}
          </strong>{" "}
          was in site {patient.siteId}, but this site hasn't been assigned to
          this nurse{" "}
          <strong>
            {nurse.firstName} {nurse.lastName}
          </strong>
          , are you sure you want to continue assigning the patient to this
          nurse?
        </div>
      ),
      cancelText: "Cancel",
      onCancel: () => {
        this.setState({ selectedLpn: "" });
      },
      okText: "OK",
      onOk: () => {
        this.assignPatientToLpnConfirm(patients);
      },
      centered: true
    });
  };

  assignPatientToLpnConfirm = patients => {
    this.props.assignAnyLpnForPatient(patients).finally(() => {
      this.setState({ selectedLpn: "" });
      this.refreshNeedAssignList();
    });
  };

  render() {
    const trProps = getPatchTrProps(({ rowInfo }) => {
      if (this.props.userGroups.includes("admin")) {
        this.assignPatientToLpn(rowInfo.original);
      }
    });
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div style={{ textAlign: "left", marginBottom: 10 }}>
          <AssignLpnForPatients
            refreshNeedAssignList={this.refreshNeedAssignList}
            userGroups={this.props.userGroups}
          />
        </div>
        <ReactTable
          getTrProps={trProps}
          data={
            this.props.listNeedAssignedPatients &&
            this.props.listNeedAssignedPatients.map(p => {
              const accountServiceStatus = p.accountServiceStatus
                ? p.accountServiceStatus === "ON_SERVICE"
                  ? "On Service"
                  : "Not On Service"
                : "On Service";
              const programStatus = [];
              if (p.rpmProgramStatus) {
                programStatus.push(`RPM:${p.rpmProgramStatus}`);
              }
              if (p.rtmProgramStatus) {
                programStatus.push(`RTM:${p.rtmProgramStatus}`);
              }
              if (p.pcmProgramStatus) {
                programStatus.push(`PCM:${p.pcmProgramStatus}`);
              }
              if (p.ccmProgramStatus) {
                programStatus.push(`CCM:${p.ccmProgramStatus}`);
              }
              return {
                ...p,
                accountServiceStatus,
                programStatus: programStatus.join(',')
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            lowercaseRmSpace(String(row[filter.id])).includes(
              lowercaseRmSpace(filter.value)
            )
          }
          columns={[
            {
              Header: "SiteID",
              accessor: "siteId",
              minWidth: 60,
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                } else {
                  return String(row[filter.id]).includes(filter.value);
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  style={{ width: "100%" }}
                  onChange={onChange}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  {this.state.siteIds.map((p, key) => {
                    return (
                      <Option value={p} key={key}>
                        {p}
                      </Option>
                    );
                  })}
                </Select>
              )
            },
            {
              Header: "SiteName",
              accessor: "siteName",
              minWidth: 100
            },
            {
              Header: "SirenID",
              accessor: "sirenId",
              minWidth: 60
            },
            {
              Header: "First Name",
              accessor: "firstName"
            },
            {
              Header: "last Name",
              accessor: "lastName"
            },
            {
              Header: "DOB",
              accessor: "birthday"
            },
            {
              Header: "Smartphone",
              accessor: "patientHasSmartPhone",
              maxWidth: 200,
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Yes") {
                  return row[filter.id] === "Yes";
                }
                if (filter.value === "No") {
                  return row[filter.id] === "No";
                }
                if (filter.value === "Caregiver") {
                  return row[filter.id] === "Caregiver";
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  style={{ width: "100%" }}
                  onChange={onChange}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                  <Option value="Caregiver">Caregiver</Option>
                </Select>
              )
            },
            {
              Header: (
                <Popover placement="top" content={<ProgramStatusDesc />}>
                  Program Status
                </Popover>
              ),
              accessor: "programStatus",
              Cell: props => {
                return (
                  <div>
                    {props.original.rpmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.rpmProgramStatus]}
                        key={"RPM"}
                      >
                        {"RPM"}
                      </Tag>
                    )}
                    {props.original.rtmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.rtmProgramStatus]}
                        key={"RTM"}
                      >
                        {"RTM"}
                      </Tag>
                    )}
                    {props.original.pcmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.pcmProgramStatus]}
                        key={"PCM"}
                      >
                        {"PCM"}
                      </Tag>
                    )}
                    {props.original.ccmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.ccmProgramStatus]}
                        key={"CCM"}
                      >
                        {"CCM"}
                      </Tag>
                    )}
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "All"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="">All</Option>
                  <Option value="CCM:ON_SERVICE">
                    <CircleColor color={COLOR.ON_SERVICE} />
                    CCM: On Service
                  </Option>
                  <Option value="PCM:ON_SERVICE">
                    <CircleColor color={COLOR.ON_SERVICE} />
                    PCM: On Service
                  </Option>
                  <Option value="RPM:ON_SERVICE">
                    <CircleColor color={COLOR.ON_SERVICE} />
                    RPM: On Service
                  </Option>
                  <Option value="RTM:ON_SERVICE">
                    <CircleColor color={COLOR.ON_SERVICE} />
                    RTM: On Service
                  </Option>
                </Select>
              )
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "siteId",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  listNeedAssignedPatients: state.patientsListStore.listNeedAssignedPatients,
  listSirenStaff: state.patientsListStore.listSirenStaff
});
const mapDispatchToProp = dispatch => ({
  getNeedAssignedPatients: self => dispatch(getNeedAssignedPatients(self)),
  getLpnStaff: () => dispatch(getLpnStaff()),
  assignAnyLpnForPatient: patients =>
    dispatch(assignAnyLpnForPatients(patients))
});
export default connect(mapStateToProp, mapDispatchToProp)(NeedAssignedPatients);
