import { message } from "antd";

/**
 *  Created by shengli.zhang on 2020-05-22 15:41
 */
const messageInfo = {
  info: msg => message.info(msg ? msg : ""),
  warn: msg => message.warn(msg ? msg : ""),
  error: msg => message.error(msg ? msg : ""),
  success: msg => message.success(msg ? msg : ""),
  loading: msg => message.loading(msg ? msg : "")
};
message.config({
  top: 100
});
export default messageInfo;
