import React, { Component } from 'react'

export default class NewAccountStep extends Component {

	componentDidMount() {
		this.props.bindTriggerSubmit(this.commit)
	}

	commit = (validate=true) => {
		if (validate) {
			if (this.validateForm()) {
				this.submitForm()
				return true
			}
			return false
		} else {
			this.submitForm()
			return true
		}
	}

	validateForm() {
		return true
	}

	submitForm() {
		if (typeof this.props.onSubmitForm === 'function') {
			const copyState = {...this.state}

			this.props.onSubmitForm(this.constructor.Name, copyState)
		}
	}

	render() {
		throw new Error('need override')
		return
	}

}