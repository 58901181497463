import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import _ from "lodash";
import { Modal as AntdModal, Tabs } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { assignLpn } from "./assign-lpn-script";
import AssignPreview from "./AssignPreview";
import NotAssignPreview from "./NotAssignPreview";
import LpnListPreview from "./LpnListPreview";
import { assignLpnForPatient } from "../action/lpnManagementAction";

/**
 *  Created by shengli.zhang on 4/2/21 4:35 PM
 */
const { TabPane } = Tabs;
const NEED_ASSIGN = require("../img/Unbilled.png");
class AssignLpnForPatients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      assignedInfo: [],
      lpnList: [],
      notAssignInfo: [],
      disableSubmit: true,
      loading: false
    };
  }

  handleOpen = () => {
    if (!this.props.userGroups.includes("admin")) {
      return;
    }
    if (this.props.listNeedAssignedPatients.length === 0) {
      AntdModal.warning({
        title: "Oops",
        content: `No patient need to assign Siren Staff.`,
        centered: true
      });
      return;
    }
    if (this.props.listSirenStaff.length === 0) {
      AntdModal.warning({
        title: "Oops",
        content: `No Siren Staff to be assigned.`,
        centered: true
      });
      return;
    }
    const { res, lpnList } = assignLpn(
      this.props.listNeedAssignedPatients,
      this.props.listSirenStaff
    );
    const notAssignInfo = this.props.listNeedAssignedPatients.map(p => {
      const patientInfo = _(res).find({ id: p.id });
      if (!patientInfo) {
        const siteId = p.siteId;
        const lpns = _.filter(lpnList, function(t) {
          return JSON.parse(t.primarySites).includes(siteId);
        });
        let reason = "";
        if (lpns.length === 0) {
          reason = `unassigned staffing site.`;
        } else {
          reason = lpns
            .map(p => {
              return `${p.firstName} ${p.lastName} - full assignment capacity.`;
            })
            .join("\n");
        }
        return { ...p, reason };
      }
      return null;
    });
    const lpnListPreview = lpnList.map(p => {
      const rate = parseFloat((p.rate * 100).toFixed(1));
      return {
        ...p,
        rate
      };
    });
    this.setState({
      open: true,
      assignedInfo: res,
      lpnList: _.orderBy(lpnListPreview, ["rate"], ["desc"]),
      notAssignInfo: _.compact(notAssignInfo),
      disableSubmit: res.length === 0
    });
  };

  close = () =>
    this.setState({
      open: false,
      assignedInfo: [],
      lpnList: [],
      notAssignInfo: [],
      disableSubmit: true,
      loading: false
    });

  submit = () => {
    //update lpn for patients
    //this.props.patients: all patients with id ---[id1, id2, id2, ....]
    //this.state.selectLpn: update to LPN ---save to dynamodb
    const patients = this.state.assignedInfo.map(p => {
      return { id: p.id, LPN: p.LPN };
    });
    this.setState({ loading: true });
    const self = this;
    this.props.assignLpnForPatient(patients, this).finally(() => {
      setTimeout(function() {
        self.close();
        self.props.refreshNeedAssignList();
      }, 1500);
    });
  };

  onChangeTab = activeKey => {
    if (activeKey === "1" && this.state.assignedInfo.length > 0) {
      this.setState({ disableSubmit: false });
    } else {
      this.setState({ disableSubmit: true });
    }
  };

  render() {
    return (
      <Modal
        trigger={
          <div
            className="report_des_container_dark"
            style={{
              marginTop: 10,
              height: 100,
              width: 314,
              cursor: "pointer"
            }}
            onClick={this.handleOpen}
          >
            <div style={{ display: "flex" }}>
              <img
                src={NEED_ASSIGN}
                alt=""
                style={{ width: 70, height: 70, marginLeft: 20 }}
              />
              <div style={{ marginLeft: 10 }}>
                <div style={{ color: "#f3f3f3", fontSize: 30 }}>
                  <strong>{this.props.needAssignedCount}</strong>
                </div>
                <div style={{ color: "#f3f3f3", fontSize: 16 }}>
                  <strong>Total Records Unassigned</strong>
                </div>
              </div>
            </div>
          </div>
        }
        open={this.state.open}
        className="add-box-form-container"
      >
        <Modal.Header>
          Review Nurse Assignments
          <CloseOutlined
            style={{ float: "right" }}
            onClick={e => this.close()}
          />
        </Modal.Header>
        <Modal.Content>
          <div>
            <Tabs style={{ textAlign: "left" }} onChange={this.onChangeTab}>
              <TabPane tab="Recommendations" key="1">
                <AssignPreview
                  assignedInfo={this.state.assignedInfo}
                  loading={this.state.loading}
                />
              </TabPane>
              <TabPane tab="Issues" key="2">
                <NotAssignPreview notAssignInfo={this.state.notAssignInfo} />
              </TabPane>
              <TabPane tab="Nurse Preview" key="3">
                <LpnListPreview lpnList={this.state.lpnList} />
              </TabPane>
            </Tabs>
          </div>
        </Modal.Content>
        {!this.state.disableSubmit ? (
          <Modal.Actions>
            <Button basic color="red" onClick={e => this.close()}>
              <Icon name="remove" />
              {this.state.regiSuccess ? "Close" : "Cancel"}
            </Button>
            <Button
              color="green"
              onClick={e => this.submit()}
              disabled={this.state.disableSubmit}
            >
              <Icon name="checkmark" />
              OK
            </Button>
          </Modal.Actions>
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  listNeedAssignedPatients: state.patientsListStore.listNeedAssignedPatients,
  listSirenStaff: state.patientsListStore.listSirenStaff,
  needAssignedCount: state.patientsListStore.needAssignedCount
});
const mapDispatchToProp = dispatch => ({
  assignLpnForPatient: (patients, self) =>
    dispatch(assignLpnForPatient(patients, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(AssignLpnForPatients);
