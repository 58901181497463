import { Tabs } from "antd";
import "antd/dist/antd.css";
import React from "react";
import "react-table/react-table.css";
import "../SupportDashboard/account/SiteList.css";
import ActiveStaff from "./ActiveStaff";
import DeletedStaff from "./DeletedStaff";
const { TabPane } = Tabs;
export default function StaffList() {
  return (
    <div className="boxlistcontainer">
      <Tabs style={{ textAlign: "left" }}>
        <TabPane tab="Active" key="1">
          <ActiveStaff />
        </TabPane>
        <TabPane tab="Deleted" key="2">
          <DeletedStaff />
        </TabPane>
      </Tabs>
    </div>
  )
}
