import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./reducers/reducers";
import { CookiesProvider } from "react-cookie";
import { HashRouter } from "react-keeper";
import { MixpanelProvider } from "react-mixpanel";
import mixpanel from "mixpanel-browser";
import aws_exports from "./aws-exports-siren";

// const debugSwitch = true;
// if (!debugSwitch) {
//   console.log = () => {};
// }

const persistConfig = {
  key: "sirenReduxStore",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["tableFilter"]
  // debug: true,
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers(reducers)
);

let enhanceRedux = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  : applyMiddleware(thunk);
const store = createStore(persistedReducer, {}, enhanceRedux);
let persistor = persistStore(store);

//shield the browser default right-click event
// document.oncontextmenu = function(e) {
//   e = e || window.event;
//   return false;
// };

document.addEventListener("wheel", function(event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

const systemDebugSwitch = false;
//env: development、staging、production
const env =
  aws_exports &&
  aws_exports.aws_cloud_logic_custom &&
  aws_exports.aws_cloud_logic_custom.length > 0
    ? aws_exports.aws_cloud_logic_custom[0].key.split("/")
    : [];
if (!env.includes("dev")) {
  console.log = () => {};
} else {
  if (systemDebugSwitch) {
    window.LOG_LEVEL = "DEBUG";
  }
}

ReactDOM.render(
  <CookiesProvider>
    <MixpanelProvider mixpanel={mixpanel}>
      <Provider store={store}>
        <PersistGate loading={<div>loading...</div>} persistor={persistor}>
          {/* ConnectedRouter will use the store from Provider automatically */}
          <HashRouter>
            {/* <HashRouter exact path="/" component={App} /> */}
            {/* <Route exact path="/" component={App} /> */}
            <App />
          </HashRouter>
        </PersistGate>
      </Provider>
    </MixpanelProvider>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
