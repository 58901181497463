import {
  Collapse,
  DatePicker,
  Divider,
  Empty,
  Icon,
  List,
  Select,
  Skeleton,
  Spin
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { getPatientActivity } from "../../graphql/API";
import callinSvg from "../../img/callin.svg";
import calloutSvg from "../../img/callout.svg";
import smsSvg from "../../img/sms.svg";
import TextArea from "antd/es/input/TextArea";
import PatientDetailUrlContext from "./PatientDetailUrlContext";

const { Panel } = Collapse;
const { Option } = Select;
const { MonthPicker } = DatePicker;

const loadingContainerStyle = {
  position: "absolute",
  bottom: 40,
  width: "100%",
  textAlign: "center"
};

export default class ActivityLog extends Component {

  static contextType = PatientDetailUrlContext;

  state = {
    filter: [],
    data: [1, 2, 3, 4, 5, 6, 7, 8], // init Skeleton
    limit: 20,
    nextToken: 0,
    loading: false,
    hasMore: false,
    noData: false
  };

  componentDidMount() {
    this.reqPatientActivity();
  }

  reqPatientActivity = () => {
    this.setState({
      loading: true,
      hasMore: false,
      noData: false
    });

    getPatientActivity(
      this.context.param.id,
      this.state.limit,
      this.state.nextToken,
      this.state.yearMonth,
      this.state.category
    )
      .then(res => {
        if (this.state.nextToken === 0) {
          this.setState({
            loading: false,
            data: res.items,
            nextToken: res.nextToken,
            hasMore: res.nextToken != -1,
            noData: res.items.length === 0
          });
        } else {
          this.setState({
            loading: false,
            data: this.state.data.concat(res.items),
            nextToken: res.nextToken,
            hasMore: res.nextToken !== -1
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleInfiniteOnLoad = () => {
    this.reqPatientActivity();
  };

  handleFilterChange = value => {
    this.setState(
      {
        data: [1, 2, 3, 4, 5, 6, 7, 8], // init Skeleton
        nextToken: 0,
        category: value
      },
      this.reqPatientActivity
    );
  };

  handleMonthSelect = (date, dateString) => {
    this.setState(
      {
        data: [1, 2, 3, 4, 5, 6, 7, 8], // init Skeleton
        nextToken: 0,
        yearMonth: dateString
      },
      this.reqPatientActivity
    );
  };

  render() {
    return (
      <div style={{ marginLeft: 30, marginRight: 30 }}>
        <div style={{ marginBottom: 8 }}>
          <label>Filter by:</label>
          <Select
            mode="multiple"
            style={{ marginLeft: 12, width: 300 }}
            onChange={this.handleFilterChange}
          >
            <Option key="call" value="call">
              Calls
            </Option>
            <Option key="email" value="email">
              Emails
            </Option>
            <Option key="sms" value="sms">
              SMS
            </Option>
          </Select>
          <MonthPicker
            onChange={this.handleMonthSelect}
            placeholder="Select month"
            style={{ marginLeft: 20 }}
          />
        </div>
        {this.state.noData ? (
          <Empty />
        ) : (
          <div style={{ overflow: "auto", height: "calc(100vh - 310px)" }}>
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={this.handleInfiniteOnLoad}
              hasMore={!this.state.loading && this.state.hasMore}
              useWindow={false}
            >
              <List
                itemLayout="vertical"
                dataSource={this.state.data}
                split={false}
                renderItem={item => (
                  <List.Item key={item.createdDate}>
                    <Skeleton
                      loading={this.state.nextToken === 0}
                      active
                      avatar
                      title={{ width: 150 }}
                      paragraph={{ rows: 1, width: 300 }}
                    >
                      <ActivityItem {...item} />
                    </Skeleton>
                  </List.Item>
                )}
              >
                {this.state.loading && this.state.hasMore && (
                  <div style={loadingContainerStyle}>
                    <Spin />
                  </div>
                )}
                {!this.state.loading && !this.state.hasMore && (
                  <Divider>No more data</Divider>
                )}
              </List>
            </InfiniteScroll>
          </div>
        )}
      </div>
    );
  }
}

function activityType(props) {
  let type = "callout";
  if (props.callType === "Outbound") {
    type = "callout";
  } else if (props.callType === "Inbound") {
    type = "callin";
  } else if (props.loggingType === "sms") {
    type = "sms";
  } else if (props.subject && props.subject.startsWith("Email:")) {
    type = "email";
  }

  return type;
}

const activityTitle = {
  callin: "Inbound call",
  callout: "Outbound call",
  sms: "SMS sent",
  email: "Email sent",
  other: "Other"
};

function activitySummary(type, activity) {
  if (type === "sms") {
    // at least 8 numbers
    let tel = activity.subject.match(/\+?\d{8,}/);
    // if tel is not null, then it is an array. get the first element
    if (tel) {
      tel = [tel];
    }
    if (tel) {
      return (
        <div>
          <strong>SMS:</strong> {activity.createdBy}send an SMS to {tel},{" "}
          <strong>Subject: </strong>
          {activity.subject}
        </div>
      );
    } else {
      return (
        <div>
          <strong>SMS:</strong> {activity.createdBy} send an SMS,{" "}
          <strong>Subject: </strong>
          {activity.subject}
        </div>
      );
    }
  } else if (type === "email") {
    let email = null;
    if (activity.description) {
      // the email address is in first line
      const index = activity.description.indexOf("\n");
      if (index !== -1) {
        let firstLine = activity.description.substring(0, index);
        let tokens = firstLine.split(" ");
        tokens.forEach(element => {
          if (element.includes("@")) {
            email = element;
          }
        });
      }
    }
    if (email) {
      return (
        <div>
          <strong>Email:</strong> {activity.createdBy} send an email to {email},{" "}
          <strong>Subject: </strong>
          {activity.subject}
        </div>
      );
    } else {
      return (
        <div>
          <strong>Email:</strong> {activity.createdBy} send an email,{" "}
          <strong>Subject: </strong>
          {activity.subject}
        </div>
      );
    }
  } else if (type === "callin" || type === "callout") {
    let tel = activity.subject.match(/\+?\d{8,}/);
    if (tel) {
      tel = [tel];
    }
    if (tel) {
      return (
        <div>
          <strong>Call:</strong> {activity.createdBy} logged a call to {tel},{" "}
          <strong>Subject: </strong>
          {activity.subject}
        </div>
      );
    } else {
      return (
        <div>
          <strong>Call:</strong> {activity.createdBy} logged a call,{" "}
          <strong>Subject: </strong>
          {activity.subject}
        </div>
      );
    }
  } else {
    return (
      <div>
        <strong>Subject: </strong>
        {activity.subject}
      </div>
    );
  }
}

function activityIcon(type) {
  if (type === "sms") {
    return <img src={smsSvg} style={{ height: 30 }} />;
  } else if (type === "email") {
    return <Icon type="mail" style={{ fontSize: 26 }} />;
  } else if (type === "callin") {
    return <img src={callinSvg} style={{ height: 26 }} />;
  } else if (type === "callout") {
    return <img src={calloutSvg} style={{ height: 26 }} />;
  } else {
    return <Icon type="carry-out" style={{ fontSize: 26 }} />;
  }
}

const DateFMT = "MMMM DD, YYYY, h.mm A PT";

function emailFMT(email) {
  if (email.startsWith("Additional ")) {
    email = email.substring(11);
  }
  return email;
}

function ActivityItem(props) {
  const type = activityType(props);
  return (
    <div
      style={{
        backgroundColor: "#E0E6EC",
        padding: 20,
        marginRight: 10,
        color: "rgba(0, 0, 0, 0.85)"
      }}
    >
      <div style={{ fontSize: 20 }}>
        <div
          style={{
            width: 48,
            display: "inline-block",
            verticalAlign: "middle"
          }}
        >
          {activityIcon(type)}
        </div>
        <span style={{ fontWeight: "bold" }}>{activityTitle[type]}</span>
        <p style={{ fontSize: 14, float: "right" }}>
          {moment(props.createdDate).format(DateFMT)}
        </p>
      </div>
      <div style={{ marginLeft: 30 }}>
        {props.description ? (
          <>
            <Collapse bordered={false}>
              <Panel
                header={activitySummary(type, props)}
                key="1"
                style={{
                  backgroundColor: "#E0E6EC",
                  border: 0
                }}
              >
                {type === "email" ? (
                  <TextArea
                    style={{
                      width: "100%",
                      height: 500,
                      border: "0px solid #E0E6EC",
                      backgroundColor: "#e0e6ec"
                    }}
                    value={emailFMT(props.description)}
                  />
                ) : (
                  <div>{props.description}</div>
                )}
              </Panel>
            </Collapse>
          </>
        ) : (
          <>
            <div style={{ marginTop: 12, marginLeft: 18 }}>
              {activitySummary(type, props)}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
