import * as TYPES from "../types/types";
import _api from "../graphql/_api";
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify/lib/index";
import message from "../tools/message-info";


export function createDoctorAccount(CreateDoctorInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.registerDoctorAccount(), CreateDoctorInput)
      );
      if (newEvent.data.createDoctor.status) {
        let StaffList = self.props.listStaff;
        const newStaff = _.clone(StaffList);
        newStaff.push(CreateDoctorInput.input);
        dispatch(changelistStaff(newStaff));
        self.setState({
          addStaffSuccess: true
        });
      } else {
        message.info(newEvent.data.createDoctor.message);
      }
    } catch (err) {
      console.log("createDoctorAccount error: ", err);
    }
  };
}
export function updateStaff(newStaffInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateDoctor(), newStaffInput)
      );
      if (newEvent.data.updateDoctor.status) {
        const StaffInputValue = _.clone(newStaffInput);
        StaffInputValue.input.doctorRole = "STAFF_CLINICAL";
        let StaffList = self.props.listStaff;
        const newStaffInfo = _.clone(StaffList);
        _.remove(newStaffInfo, {
          email: StaffInputValue.input.email
        });
        newStaffInfo.push(StaffInputValue.input);
        const staffInfoAfterDel = newStaffInfo;
        dispatch(changelistStaff(staffInfoAfterDel));
      } else {
        message.info(newEvent.data.updateDoctor.message);
      }
    } catch (err) {
      console.log("updateStaff error: ", err);
    }
  };
}

export function sendTaskToTest(TestTaskInput, self) {
  return async dispatch => {
    try {
      self.setState({ modalIsOpen: true });
      await API.graphql(
        graphqlOperation(_api.sendTestTask(), TestTaskInput)
      );
      self.setState({ modalIsOpen: false, open: false });
    } catch (err) {
      self.setState({ modalIsOpen: false });
      console.log("sendTaskToTest error: ", err);
    }
  };
}

export function createSiteId(CreateSiteIdInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.createClinicSiteAccount(), CreateSiteIdInput)
      );
      if (newEvent) {
        let siteIds = self.props.clinicSites;
        const newSites = _.clone(siteIds);
        newSites.push(newEvent.data.createClinicSite);

        dispatch(changelistClinicSites(newSites));
        self.setState({
          addSiteSuccess: true,
          phone: ""
        });
      } else {
        message.info(newEvent.data.createClinicSite.message);
      }
    } catch (err) {
      message.info(err);
      console.log("createSiteId error: ", err);
    }
  };
}

function compareSiteId(a, b) {
  if (a.siteId < b.siteId) return -1;
  if (a.siteId > b.siteId) return 1;
  return 0;
}
export function getlistClinicSites() {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listClinicSites())
      );
      if (newEvent) {
        dispatch(
          changelistClinicSites(
            newEvent.data.listClinicSitesNew.items.sort(compareSiteId)
          )
        );
      }
    } catch (e) {
      console.log("getlistClinicSites error: ", e);
    }
  };
}

export function getDeleteSite(siteId, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteClinicSite(), { input: { siteId } })
      );
      if (newEvent) {
        self.setState({ open: false });
        const tmpSites = _.clone(self.props.clinicSites);
        _.remove(tmpSites, {
          siteId: siteId
        });
        const itemsAfterDel = tmpSites;
        dispatch(changelistClinicSites(itemsAfterDel));
      }
    } catch (err) {
      console.log("getDeleteSite exception: ", err);
    }
  };
}

export function changelistClinicSites(clinicSites) {
  return {
    type: TYPES.CLINIC_SITES,
    text: clinicSites
  };
}
export function getlistStaff() {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(graphqlOperation(_api.listStaff()));
      if (newEvent) {
        dispatch(
          changelistStaff(newEvent.data.listStaff.items.sort(compareSiteId))
        );
      }
    } catch (e) {
      console.log("getlistStaff error: ", e);
    }
  };
}
export function changelistStaff(listStaff) {
  return {
    type: TYPES.LIST_STAFF,
    text: listStaff
  };
}

export function getDeleteStaff(email, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteDoctor(), { input: { email } })
      );
      if (newEvent) {
        self.setState({ open: false });
        const tmpSites = _.clone(self.props.listStaff);
        _.remove(tmpSites, {
          email: email
        });
        const itemsAfterDel = tmpSites;
        dispatch(changelistStaff(itemsAfterDel));
      }
    } catch (err) {
      console.log("getDeleteStaff exception: ", err);
    }
  };
}

export function resetPwd(newPwdInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.resetStaffPassword(), newPwdInput)
      );
      if (newEvent.data.resetStaffPassword.status) {
        self.setState({ newPwd: "", open: false });
      } else {
        message.info(newEvent.data.resetStaffPassword.message);
      }
    } catch (err) {
      console.log("resetPwd error: ", err);
    }
  };
}
export function getReportHistory(reportHistoryFilter, self) {
  return async dispatch => {
    try {
      const siteId = reportHistoryFilter.siteId;
      const reportDay = reportHistoryFilter.reportDay;
      self.setState({ modalIsOpen: true });
      let filter = {};
      if (!_.isEmpty(siteId)) {
        filter = {
          siteId: {
            eq: siteId
          },
          reportDay: {
            gt: reportDay
          }
        };
      } else {
        filter = {
          reportDay: {
            gt: reportDay
          }
        };
      }
      const newEvent = await API.graphql(
        graphqlOperation(_api.searchDoctorReports(), {
          filter,
          limit: 1000
        })
      );
      self.setState({ modalIsOpen: false });
      if (newEvent) {
        dispatch(
          changeReportHistory(
            newEvent.data.searchDoctorReports.items.sort(compareSiteId)
          )
        );
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("getReportHistory error: ", e);
    }
  };
}
export function changeReportHistory(doctorReportHistory) {
  return {
    type: TYPES.DOCTOR_REPORT_HISTORT,
    text: doctorReportHistory
  };
}
