import { Button, Modal, Result, Spin, Steps } from "antd";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import React from "react";
import { connect } from "react-redux";
import { listAdminUsers } from "../../../action/registerAction";
import {
  listICD10Codes,
  listMedicalConditions
} from "../../../action/userGroupAction";
import { registerPatientAccount } from "../../../graphql/API";
import "./index.css";
import NewAccountStepConfirm from "./NewAccountStepConfirm";
import NewAccountStepDone from "./NewAccountStepDone";
import NewAccountStepEnrollment from "./NewAccountStepEnrollment";
import NewAccountStepMedical from "./NewAccountStepMedical";
import NewAccountStepProfile from "./NewAccountStepProfile";

const { Step } = Steps;

const StepComponents = [
  NewAccountStepEnrollment,
  NewAccountStepProfile,
  NewAccountStepMedical,
  NewAccountStepConfirm,
  NewAccountStepDone
];

class NewAccount extends React.Component {
  state = {
    currentStep: 0,
    loading: false,
    failed: false,
    [NewAccountStepEnrollment.Name]: {},
    [NewAccountStepProfile.Name]: {},
    [NewAccountStepMedical.Name]: {},
    [NewAccountStepConfirm.Name]: {}
  };

  componentDidMount() {
    if (this.props.listAdminStaff.length === 0) {
      this.props.listAdminUsers();
    }
  }

  isLastStep() {
    return this.state.currentStep === StepComponents.length - 1;
  }

  isFirstStep() {
    return this.state.currentStep === 0;
  }

  handlePrevStep = () => {
    if (this.state.currentStep > 0) {
      this.setState({
        currentStep: this.state.currentStep - 1
      });
      this.triggerSubmit(false);
    }
  };

  handleNextStep = () => {
    if (this.isLastStep()) {
      this.setState({
        currentStep: 0,
        [NewAccountStepEnrollment.Name]: {},
        [NewAccountStepProfile.Name]: {},
        [NewAccountStepMedical.Name]: {},
        [NewAccountStepConfirm.Name]: {}
      });
      return;
    }
    if (this.state.currentStep + 1 < StepComponents.length) {
      const confirmStep = StepComponents.indexOf(NewAccountStepConfirm);

      const formValidate = this.triggerSubmit(true);
      if (!formValidate) {
        return;
      }
      if (this.state.currentStep < confirmStep) {
        this.setState({
          currentStep: this.state.currentStep + 1
        });
      } else if (this.state.currentStep === confirmStep) {
        this.setState(
          {
            currentStep: confirmStep
          },
          () => {
            this.createPatient();
          }
        );
      }
    }
  };

  handleStepChange = step => {
    if (this.isLastStep()) {
      const _this = this;
      Modal.confirm({
        title: "Invalid operation",
        content:
          "Go back is not permitted. Do you want to create another new patient?",
        onOk() {
          _this.setState({
            currentStep: 0,
            [NewAccountStepEnrollment.Name]: {},
            [NewAccountStepProfile.Name]: {},
            [NewAccountStepMedical.Name]: {},
            [NewAccountStepConfirm.Name]: {}
          });
        },
        onCancel() {}
      });
      return;
    }
    if (step < this.state.currentStep) {
      // step back
      this.setState({
        currentStep: step
      });
      this.triggerSubmit(false);
    }
  };

  onStepSubmit = (componentID, data) => {
    if (componentID === NewAccountStepConfirm.Name) {
      this.setState(prevState => {
        const nextEnrollment = Object.assign(
          {},
          prevState[NewAccountStepEnrollment.Name],
          { doctorId: data.doctorId }
        );
        const nextProfile = Object.assign(
          {},
          prevState[NewAccountStepProfile.Name],
          {
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            email: data.email,
            shipAddress: data.shipAddress,
            shipCity: data.shipCity,
            shipZip: data.shipZip
          }
        );
        return {
          [NewAccountStepEnrollment.Name]: nextEnrollment,
          [NewAccountStepProfile.Name]: nextProfile,
          [NewAccountStepConfirm.Name]: data
        };
      });
    } else {
      this.setState({
        [componentID]: data
      });
    }
  };

  formatPhone(phone) {
    return phone.replace(/[\(\)\-\s]+/g, "");
  }

  createPatient() {
    // data of NewAccountStepConfirm will overwrite data of NewAccountStepEnrollment
    const mergeData = Object.assign(
      {},
      this.state[NewAccountStepEnrollment.Name],
      this.state[NewAccountStepProfile.Name],
      this.state[NewAccountStepMedical.Name],
      this.state[NewAccountStepConfirm.Name]
    );
    const formData = {
      id: this.formatPhone(mergeData.phone),
      phone: this.formatPhone(mergeData.phone),
      firstName: mergeData.firstName,
      lastName: mergeData.lastName,
      siteId: mergeData.site.siteId,
      siteName: mergeData.site.name,
      clinicIRB: mergeData.site.IRBConsent || "Not Approved",
      birthday: mergeData.dob.format("YYYY-MM-DD"),
      doctorId: mergeData.doctorId,
      doctorName: mergeData.doctorName,
      userTimezone: moment.tz.guess(),
      dispenseAddress: mergeData.shipAddress,
      dispenseCity: mergeData.shipCity,
      dispenseState: mergeData.shipState,
      dispenseZip: mergeData.shipZip,
      authSecondaryContact: mergeData.authSecondary ? "Yes" : "No",
      patientHasSmartPhone: mergeData.hasPhone,
      accountStatus: "PAUSED",
      pausedReason: "Account - Pending New order shipment",
      accountServiceStatus: "NOT_ON_SERVICE"
    };

    if (!isEmpty(mergeData.homePhone)) {
      formData.homePhone = this.formatPhone(mergeData.homePhone);
    }
    if (!isEmpty(mergeData.MBI)) {
      formData.patientMBI = mergeData.MBI;
    }
    if (!isEmpty(mergeData.gender)) {
      formData.gender = mergeData.gender;
    }
    if (!isEmpty(mergeData.patientID)) {
      formData.patientId = mergeData.patientID;
    }
    if (!isEmpty(mergeData.email)) {
      formData.email = mergeData.email;
    }
    if (!isEmpty(mergeData.residence)) {
      formData.patientResidence = mergeData.residence;
    }
    if (!isEmpty(mergeData.contactByPhone)) {
      formData.enableApp = mergeData.contactByPhone;
    }
    if (!isEmpty(mergeData.contactByEmail)) {
      formData.enableEmail = mergeData.contactByEmail;
    }
    if (!isEmpty(mergeData.contactByMessage)) {
      formData.enableSms = mergeData.contactByMessage;
    }
    if (!isEmpty(mergeData.secondaryPhone)) {
      formData.contactPhone = this.formatPhone(mergeData.secondaryPhone);
    }
    if (!isEmpty(mergeData.secondaryName)) {
      formData.contactName = mergeData.secondaryName;
    }
    if (!isEmpty(mergeData.secondaryEmail)) {
      formData.secondaryEmail = mergeData.secondaryEmail;
    }
    if (!isEmpty(mergeData.secondaryRelation)) {
      formData.relation = mergeData.secondaryRelation;
    }
    if (mergeData.medicalConditions.length > 0) {
      if (mergeData.medicalConditions.includes("Other")) {
        const medicalConditions = mergeData.medicalConditions.filter(
          item => item !== "Other"
        );
        medicalConditions.push(`Other(${mergeData.otherDisease})`);
        formData.medicalCondition = medicalConditions.join(",");
      } else {
        formData.medicalCondition = mergeData.medicalConditions.join(",");
      }
    }
    if (mergeData.footSpecific.length > 0) {
      formData.footSpecific = mergeData.footSpecific.join(",");
    }
    if (mergeData.footAmputation.length > 0) {
      formData.footAmputation = mergeData.footAmputation.join(",");
    }
    if (mergeData.diagnosisCodes.length > 0) {
      formData.diagnosisCodes = mergeData.diagnosisCodes.join("_");
    }
    if (!isEmpty(mergeData.dispenseComment)) {
      formData.dispenseComments = mergeData.dispenseComment;
    }

    this.setState({
      loading: true
    });
    registerPatientAccount({ input: formData })
      .then(res => {
        this.setState({
          loading: false,
          currentStep: StepComponents.length - 1
        });
        this.props.listICD10Codes();
        this.props.listMedicalConditions();
      })
      .catch(err => {
        this.setState({
          loading: false,
          failed: true,
          failReason: err
        });
      });
  }

  renderCreateFailure() {
    const confirmStep = StepComponents.indexOf(NewAccountStepConfirm);
    return (
      <Result
        status="error"
        title="Failed to create patient!"
        subTitle={
          <div>
            <p>Please check and modify the order information.</p>
            <p>{JSON.stringify(this.state.failReason)}</p>
          </div>
        }
        extra={[
          <Button
            type="primary"
            onClick={() => {
              this.setState({
                currentStep: confirmStep,
                failed: false
              });
            }}
          >
            Edit patient information
          </Button>
        ]}
      />
    );
  }

  renderStepContent() {
    const SubStep = StepComponents[this.state.currentStep];
    const props = {
      ...this.state[SubStep.Name],
      bindTriggerSubmit: fn => {
        this.triggerSubmit = fn;
      },
      onSubmitForm: this.onStepSubmit
    };
    if (StepComponents[this.state.currentStep] === NewAccountStepProfile) {
      props.site = this.state[NewAccountStepEnrollment.Name].site;
    } else if (
      StepComponents[this.state.currentStep] === NewAccountStepConfirm
    ) {
      Object.assign(
        props,
        this.state[NewAccountStepEnrollment.Name],
        this.state[NewAccountStepProfile.Name],
        this.state[NewAccountStepMedical.Name]
      );
    } else if (StepComponents[this.state.currentStep] === NewAccountStepDone) {
      props.phone = this.state[NewAccountStepProfile.Name].phone;
    }
    return React.createElement(SubStep, props);
  }

  render() {
    return (
      <Spin size="large" tip="Loading..." spinning={this.state.loading}>
        <div id="new-account">
          {this.state.failed ? (
            this.renderCreateFailure()
          ) : (
            <>
              <Steps
                size="small"
                current={this.state.currentStep}
                onChange={this.handleStepChange}
                labelPlacement="vertical"
              >
                {StepComponents.map(p => p.Name).map((label, key) => (
                  <Step key={key} title={label} />
                ))}
              </Steps>
              <div>
                {this.renderStepContent()}
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  <Button
                    size="large"
                    style={{
                      marginRight: 12,
                      color: "#7C35AF",
                      borderColor: "#7C35AF",
                      display:
                        this.isFirstStep() || this.isLastStep()
                          ? "none"
                          : "inline-block"
                    }}
                    onClick={this.handlePrevStep}
                  >
                    Back
                  </Button>
                  <Button
                    size="large"
                    style={{
                      color: "white",
                      borderColor: "#7C35AF",
                      backgroundColor: "#7C35AF"
                    }}
                    onClick={this.handleNextStep}
                  >
                    {StepComponents[this.state.currentStep] ===
                    NewAccountStepConfirm
                      ? "Complete Patient Creation"
                      : this.isLastStep()
                      ? "Create Another New Patient"
                      : "Next"}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Spin>
    );
  }
}

const mapStateToProp = state => ({
  listAdminStaff: state.patientsListStore.listAdminStaff,
  clinicSites: state.patientsListStore.clinicSites
});

const mapDispatchToProp = dispatch => ({
  listAdminUsers: () => dispatch(listAdminUsers()),
  listICD10Codes: () => dispatch(listICD10Codes),
  listMedicalConditions: () => dispatch(listMedicalConditions)
});

export default connect(mapStateToProp, mapDispatchToProp)(NewAccount);
