import { Select, Modal as AntdModal } from "antd";
import { Auth } from "aws-amplify";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { updateAdminUserInfo } from "../../action/registerAction";
import modalInfo from "../../tools/module-info";
import { uppercaseFirstStr } from "../../tools/stringProcess";
import "./NewSite.css";
import { getEMRList } from "../../action/emrAction";
import { Control } from "react-keeper";

const { Option } = Select;
const { confirm } = AntdModal;
const roleMap = {
  ADMIN: "admin",
  DEVELOPER: "developer",
  SUPPORT: "support"
};

class EditAdminUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      firstName: "",
      lastName: "",
      email: "",
      userGroup: "",
      showUserPaw: false,
      oldGroup: "",
      groups: [],
      selectSiteValues: [],
      selectEMRValues: [],
      selectBackupValues: [],
      patientLoad: ""
    };
    this.onSelectSiteChange = this.onSelectSiteChange.bind(this);
    this.onSelectEMRChange = this.onSelectEMRChange.bind(this);
    this.onSelectBackupChange = this.onSelectBackupChange.bind(this);
  }

  onSelectSiteChange(value) {
    let newValue = _.clone(value);
    const self = this;
    const site = _.filter(this.props.clinicSites, function(t) {
      // if (self.state.userGroup === "SALES") {
      //   return t.siteId.startsWith(value[value.length - 1]);
      // }
      return t.siteId === value[value.length - 1];
    }).map(p => {
      return p.siteId;
    });
    let selectSiteValues = _.uniq([...newValue, ...site]);
    if (this.state.userGroup === "STAFF") {
      if (this.state.selectEMRValues.length === 0) {
        modalInfo.warning(
          "Incompatible EMR System",
          "Please select EMR System first."
        );
        return;
      } else {
        const self = this;
        _.some(selectSiteValues, function(p) {
          const mapSite = _(self.props.clinicSites).find({ siteId: p });
          if (!self.state.selectEMRValues.includes(mapSite.EMRSystem)) {
            modalInfo.warning(
              "Incompatible EMR System",
              "Please select clinics with compatible EMR systems or add additional EMR proficiencies."
            );
            selectSiteValues = self.state.selectSiteValues;
            return true;
          }
        });
      }
    }
    this.setState({ selectSiteValues });
  }

  onSelectEMRChange(value) {
    const selectSiteValues = this.state.selectSiteValues.filter(siteId => {
      const site = this.props.clinicSites.find(site => site.siteId === siteId);
      if (site) {
        return value.includes(site.EMRSystem);
      }
      return false;
    });
    this.setState({
      selectEMRValues: value,
      selectSiteValues
    });
  }

  onSelectBackupChange(value) {
    this.setState({
      selectBackupValues: value
    });
  }

  changeFirstName = event => {
    this.setState({ firstName: uppercaseFirstStr(event.target.value) });
  };

  changeLastName = event => {
    this.setState({ lastName: uppercaseFirstStr(event.target.value) });
  };
  onChangedGroup = event => {
    this.setState({ userGroup: event });
  };
  onChangedPatientLoad = event => {
    this.setState({ patientLoad: event });
  };

  close = () =>
    this.setState({
      showUserPaw: false,
      open: false,
      firstName: "",
      lastName: "",
      userGroup: "",
      groups: []
    });

  handleOpen = async () => {
    const session = await Auth.currentSession();
    const username = session.getIdToken().payload[`cognito:username`];
    const groups = session.getIdToken().payload[`cognito:groups`];
    if (username !== this.props.adminUser.userName) {
      if (!groups.includes("admin") && !groups.includes("developer")) {
        modalInfo.warning(
          "Update Admin Dashboard User",
          "Please contact an administrator to edit the account. User does not have Admin Dashboard account editing privileges."
        );
        return;
      }
    }
    if (this.props.EMRSystemList.length === 0) {
      this.props.getEMRList();
    }
    this.setState({
      open: true,
      email: this.props.adminUser.email,
      firstName: this.props.adminUser.firstName
        ? this.props.adminUser.firstName
        : "",
      lastName: this.props.adminUser.lastName
        ? this.props.adminUser.lastName
        : "",
      userGroup:
        this.props.adminUser.group === "Super User"
          ? "ADMIN"
          : this.props.adminUser.group === "Admin"
          ? "DEVELOPER"
          : this.props.adminUser.group === "Support"
          ? "SUPPORT"
          : this.props.adminUser.group === "Siren Staffing"
          ? "STAFF"
          : "SALES",
      oldGroup:
        this.props.adminUser.group === "Super User"
          ? "ADMIN"
          : this.props.adminUser.group === "Admin"
          ? "DEVELOPER"
          : this.props.adminUser.group === "Support"
          ? "SUPPORT"
          : this.props.adminUser.group === "Siren Staffing"
          ? "STAFF"
          : "SALES",
      groups: groups.includes("admin")
        ? ["admin", "developer", "support", "staff", "sales"]
        : groups.includes("developer")
        ? ["developer", "support", "staff", "sales"]
        : ["staff", "sales"],
      selectSiteValues: this.props.adminUser.primarySites
        ? JSON.parse(this.props.adminUser.primarySites)
        : [],
      selectEMRValues: this.props.adminUser.EMRSystem
        ? JSON.parse(this.props.adminUser.EMRSystem)
        : [],
      selectBackupValues: this.props.adminUser.backupFor
        ? JSON.parse(this.props.adminUser.backupFor)
        : [],
      patientLoad: this.props.adminUser.patientLoad
        ? this.props.adminUser.patientLoad
        : "200"
    });
  };

  gotoDoctorDetailPage = id => {
    Control.go(`/LpnPatient?id=${id}`);
  };

  checkPatientsInLpn = (id, patientCount) => {
    AntdModal.warning({
      title: "Oops",
      content: (
        <div>
          There are <strong>{patientCount}</strong> patients assigned to{" "}
          <strong>{id}</strong>. Patients must be reassigned before the account
          can be deleted.
        </div>
      ),
      // cancelText: "No",
      // onCancel: () => {},
      okText: "OK",
      onOk: () => {
        // this.gotoDoctorDetailPage(id);
      },
      centered: true
    });
  };

  checkBackup = (backupBy, id) => {
    AntdModal.warning({
      title: "Oops",
      content: (
        <div>
          To delete <strong>{id}</strong>, remove user as a backup for{" "}
          <strong>
            {backupBy
              .map(p => {
                return p;
              })
              .join("/")}
          </strong>
          .
        </div>
      ),
      okText: "OK",
      onOk: () => {},
      centered: true
    });
  };

  checkBackupByLpn = id => {
    const adminStaffs = this.props.listAdminStaff.filter(
      item => item.group === "staff"
    );
    const backupBy = adminStaffs.map(p => {
      if (p.backupFor && JSON.parse(p.backupFor).includes(id)) return p.id;
      return null;
    });
    return _.compact(backupBy);
  };

  handleUpdateAccount = () => {
    const { updateAdminUser } = this.props;
    if (_.isEmpty(this.state.firstName)) {
      modalInfo.warning("Incomplete Field", `Please input First Name.`);
      return;
    }
    if (_.isEmpty(this.state.lastName)) {
      modalInfo.warning("Incomplete Field", `Please input Last Name.`);
      return;
    }
    if (this.state.userGroup !== this.state.oldGroup) {
      if (
        this.state.oldGroup === "STAFF" &&
        this.props.adminUser.patientCount > 0
      ) {
        this.checkPatientsInLpn(
          this.props.adminUser.userName,
          this.props.adminUser.patientCount
        );
        return;
      }
      if (
        this.state.oldGroup === "STAFF" &&
        this.checkBackupByLpn(this.props.adminUser.userName).length > 0
      ) {
        this.checkBackup(
          this.checkBackupByLpn(this.props.adminUser.userName),
          this.props.adminUser.userName
        );
        return;
      }
    }
    if (this.state.userGroup === "STAFF") {
      if (this.state.selectEMRValues.length === 0) {
        modalInfo.warning("Incomplete Field", "Please select EMR System");
        return;
      }
      if (!this.state.patientLoad) {
        modalInfo.warning("Incomplete Field", "Please select Patient Load");
        return;
      }
    }
    let UpdateAdminStaffInput = {
      input: {
        id: this.props.adminUser.userName,
        firstName: this.state.firstName,
        lastName: this.state.lastName
      }
    };
    Object.assign(UpdateAdminStaffInput.input, {
      group: this.state.userGroup
    });
    Object.assign(UpdateAdminStaffInput.input, {
      oldGroup: this.state.oldGroup
    });
    if (this.state.userGroup === "SALES" || this.state.userGroup === "STAFF") {
      Object.assign(UpdateAdminStaffInput.input, {
        primarySites: JSON.stringify(this.state.selectSiteValues)
      });
    }
    if (this.state.userGroup === "STAFF") {
      Object.assign(UpdateAdminStaffInput.input, {
        EMRSystem: JSON.stringify(this.state.selectEMRValues)
      });
      Object.assign(UpdateAdminStaffInput.input, {
        backupFor: JSON.stringify(this.state.selectBackupValues)
      });
      Object.assign(UpdateAdminStaffInput.input, {
        patientLoad: parseInt(this.state.patientLoad)
      });
    }
    updateAdminUser(UpdateAdminStaffInput).then(() => {
      this.setState({
        open: false
      });
      if (this.props.afterCommit) {
        this.props.afterCommit();
      }
    });
  };

  renderLPNForm() {
    const isAuthed =
      this.state.groups.includes("admin") ||
      this.state.groups.includes("developer");
    return (
      <>
        <div className="inputTotal" style={{ width: 580 }}>
          <div className="inputLable">
            EMR System <span style={{ color: "red", marginLeft: 5 }}>*</span>
          </div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select EMR"
            onChange={this.onSelectEMRChange}
            optionLabelProp="value"
            value={this.state.selectEMRValues}
            disabled={!isAuthed}
          >
            {this.props.EMRSystemList.map((p, index) => (
              <Option key={index} value={p.name}>
                {p.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="inputTotal" style={{ width: 580 }}>
          <div className="inputLable">
            Sites
            {/*<span style={{ color: "red", marginLeft: 5 }}>*</span>*/}
          </div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select sites"
            onChange={this.onSelectSiteChange}
            optionLabelProp="value"
            value={this.state.selectSiteValues}
            disabled={!isAuthed}
          >
            {this.props.clinicSites
              .filter(s => this.state.selectEMRValues.includes(s.EMRSystem))
              .map(site => {
                return (
                  <Option key={site.siteId} value={site.siteId}>
                    {site.siteId} {site.name} - {site.EMRSystem}
                  </Option>
                );
              })}
          </Select>
        </div>
        <div className="inputTotal" style={{ width: 580 }}>
          <div className="inputLable">Backup For</div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select backup"
            onChange={this.onSelectBackupChange}
            defaultValue={this.state.selectBackupValues}
            disabled={!isAuthed}
          >
            {this.props.sirenStaffList.map(staff => {
              return (
                <Option key={staff.id} value={staff.id}>
                  {staff.firstName} {staff.lastName}
                </Option>
              );
            })}
          </Select>
        </div>
        <div
          style={{
            display: "flex",
            width: "40%",
            marginLeft: "10%",
            marginRight: "10%"
          }}
        >
          <div className="inputTotal">
            <p className="inputLable">Patient Load</p>
            <Select
              className="selectStyle"
              value={this.state.patientLoad}
              filterOption={(input, option) =>
                option.props.value
                  .toLocaleLowerCase()
                  .indexOf(input.toLocaleLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
              onChange={this.onChangedPatientLoad}
              disabled={!isAuthed}
            >
              {this.state.groups.includes("admin") ? (
                <Option value="50">50</Option>
              ) : null}
              <Option value="100">100</Option>
              <Option value="150">150</Option>
              <Option value="200">200</Option>
              <Option value="250">250</Option>
              <Option value="300">300</Option>
              <Option value="350">350</Option>
              <Option value="400">400</Option>
            </Select>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { Option } = Select;
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Button
            size="small"
            onClick={this.handleOpen}
            style={this.props.showTrigger ? {} : { display: "none" }}
          >
            Edit
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>{"Edit Admin Account"}</Modal.Header>
        <Modal.Content scrolling>
          <div>
            <div className="newSite_box">
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">
                    First Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <Input
                    placeholder="John"
                    className="inputContent"
                    onChange={this.changeFirstName}
                    value={this.state.firstName}
                  />
                </div>
                <div className="inputTotal">
                  <p className="inputLable">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <Input
                    placeholder="Smith"
                    className="inputContent"
                    onChange={this.changeLastName}
                    value={this.state.lastName}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">Email</p>
                  <div style={{ backgroundColor: "#f5f5f5" }}>
                    <Input
                      placeholder=""
                      className="inputContent"
                      value={this.state.email}
                      disabled
                    />
                  </div>
                </div>
                <div className="inputTotal">
                  <p className="inputLable">User Group</p>
                  <Select
                    className="selectStyle"
                    value={this.state.userGroup}
                    filterOption={(input, option) =>
                      option.props.value
                        .toLocaleLowerCase()
                        .indexOf(input.toLocaleLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                    onChange={this.onChangedGroup}
                    disabled={
                      !(
                        this.state.groups.includes("admin") ||
                        this.state.groups.includes("developer")
                      )
                    }
                  >
                    {this.state.groups.includes("admin") ? (
                      <Option value="ADMIN">Super User</Option>
                    ) : null}
                    {this.state.groups.includes("admin") ||
                    this.state.groups.includes("developer") ? (
                      <Option value="DEVELOPER">Admin</Option>
                    ) : null}
                    {this.state.groups.includes("admin") ||
                    this.state.groups.includes("developer") ||
                    this.state.groups.includes("support") ? (
                      <Option value="SUPPORT">Support</Option>
                    ) : null}
                    <Option value="STAFF">Siren Staffing</Option>
                    <Option value="SALES">Sales</Option>
                    <Option value="THIRD_PARTY">Third Party</Option>
                  </Select>
                </div>
              </div>
              {this.state.userGroup === "SALES" && (
                <div className="inputTotal" style={{ width: 580 }}>
                  <div className="inputLable">
                    Primary Sites Access{" "}
                    {/*<span style={{ color: "red", marginLeft: 5 }}>*</span>*/}
                  </div>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select sites"
                    onChange={this.onSelectSiteChange}
                    optionLabelProp="value"
                    disabled={
                      !this.state.groups.includes("admin") &&
                      !this.state.groups.includes("developer")
                    }
                    value={this.state.selectSiteValues}
                  >
                    {this.props.clinicSites.map(site => {
                      return (
                        <Option key={site.siteId} value={site.siteId}>
                          {site.siteId} {site.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              )}
              {this.state.userGroup === "STAFF" && this.renderLPNForm()}
            </div>
            <div style={{ height: 50 }} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.handleUpdateAccount}>
            <Icon name="checkmark" />
            Submit
          </Button>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  listAdminStaff: state.patientsListStore.listAdminStaff,
  sirenStaffList: state.patientsListStore.listAdminStaff.filter(
    p => p.group === "staff"
  ),
  clinicSites: state.patientsListStore.clinicSites,
  EMRSystemList: state.patientsListStore.EMRSystemList
});
const mapDispatchToProp = dispatch => ({
  updateAdminUser: input => dispatch(updateAdminUserInfo(input)),
  getEMRList: () => dispatch(getEMRList())
});
export default connect(mapStateToProp, mapDispatchToProp)(EditAdminUser);
