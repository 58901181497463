import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Icon, Modal, TextArea } from "semantic-ui-react";
import "../share/button.css";
import "./AccountStatus.css";
import ButtonSuccess from "./buttonSuccess";
import _ from "lodash";
import moment from "moment";
import { DatePicker, Switch } from "antd";
import {
  updateNewPatientNotification,
  updatePatientPushSuc
} from "../action/notificationAction";

class reminderPushStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      note: "",
      endDate: ""
    };
    this.handleConfirm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  show = () => this.setState({ open: true, confirmSuccess: false });
  handleConfirm = async () => {
    let user = await Auth.currentAuthenticatedUser();
    this.setState({ confirmSuccess: true });
    let editInput = {
      id: this.props.userInfo.id,
      reminderPush: this.props.enableReminderPush ? "No" : "Yes"
    };

    Object.assign(editInput, {
      author: `${user.attributes.given_name} ${user.attributes.family_name}`
    });
    Object.assign(editInput, {
      authorId: `${user.username}`
    });
    Object.assign(editInput, { note: this.state.note });
    Object.assign(editInput, { reminderPushEndDate: this.state.endDate });
    if (this.props.enableReminderPush) {
      if (_.isEmpty(this.state.endDate)) {
        Object.assign(editInput, {
          title: "Reminder Notifications Turned Off"
        });
      } else {
        Object.assign(editInput, {
          title: `Reminder Notifications Turned Off, ends ${moment(
            this.state.endDate
          ).format("MM/DD/YYYY")} `
        });
      }
    } else {
      Object.assign(editInput, {
        title: "Reminder Notifications Turned On"
      });
    }
    console.log("editInput:", editInput);
    this.props
      .updateNotification(
        editInput,
        this,
        this.props.enableReminderPush,
        this.props.userInfo.sirenId,
        "Reminder Push Notifications"
      )
      .then(() => {
        if (typeof this.props.onChange === "function") {
          this.props.onChange(editInput);
        }
      });
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  handleInputChange(value) {
    this.setState({ note: value });
  }

  handleEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Switch
            checked={this.props.enableReminderPush}
            style={{
              marginTop: 5,
              marginRight: 10
            }}
          />
        }
        onClose={this.handleCancel}
        onOpen={this.show}
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>{"Notification Update"}</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <label style={{ marginBottom: 10, fontSize: 14 }}>
                    {this.props.enableReminderPush
                      ? `Reminder Push Notification OFF for SirenId ${this.props.userInfo.sirenId}.`
                      : `Reminder Push Notification ON for SirenId ${this.props.userInfo.sirenId}.`}
                  </label>
                  {this.props.enableReminderPush ? (
                    _.isEmpty(this.state.endDate) ? (
                      <div>
                        <p
                          className="inputLable"
                          style={{ fontWeight: "bold", fontSize: 14 }}
                        >
                          End Date
                        </p>
                        <DatePicker
                          className="inputDateContent"
                          style={{ marginBottom: 10 }}
                          format={"MM/DD/YYYY"}
                          onChange={this.handleEndDateChange}
                          allowClear={false}
                        />
                      </div>
                    ) : (
                      <div>
                        <p
                          className="inputLable"
                          style={{ fontWeight: "bold", fontSize: 14 }}
                        >
                          End Date
                        </p>
                        <DatePicker
                          className="inputDateContent"
                          format={"MM/DD/YYYY"}
                          style={{ marginBottom: 10 }}
                          onChange={this.handleEndDateChange}
                          allowClear={false}
                          value={moment(this.state.endDate, "MM/DD/YYYY")}
                        />
                      </div>
                    )
                  ) : null}
                  <TextArea
                    className="note_comments"
                    onChange={(e, data) => {
                      this.handleInputChange(e.target.value);
                    }}
                    placeholder="Reason for reminder notification change"
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  listPatients: state.patientsListStore.listPatients
});
const mapDispatchToProp = dispatch => ({
  updateNotification: (editInput, self, checked, sirenId, type) =>
    dispatch(
      updateNewPatientNotification(editInput, self, checked, sirenId, type)
    )
});
export default connect(mapStateToProp, mapDispatchToProp)(reminderPushStatus);
