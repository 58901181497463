import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import _ from "lodash";
import { formatNumber } from "../tools/phoneFormat";

/**
 *  Created by shengli.zhang on 4/2/21 4:35 PM
 */
class AssignPreview extends Component {
  render() {
    return (
      <div>
        <ReactTable
          loading={this.props.loading}
          data={
            this.props.assignedInfo &&
            this.props.assignedInfo.map(p => {
              const patientInfo = _(this.props.listNeedAssignedPatients).find({
                id: p.id
              });
              const givenName = patientInfo.firstName
                ? `${patientInfo.firstName} ${patientInfo.lastName}`
                : "";
              const LPNInfo = _(this.props.listSirenStaff).find({
                id: p.LPN
              });
              const id = !_.isEmpty(p.id) ? formatNumber(p.id).trim() : "";
              const LPN = `${LPNInfo.firstName} ${LPNInfo.lastName}`;
              return {
                ...p,
                id,
                givenName,
                LPN
              };
            })
          }
          columns={[
            {
              Header: "ID",
              accessor: "id",
              filterMethod: (filter, row) => {
                return String(row[filter.id])
                  .toLowerCase()
                  .includes(String(filter.value).toLowerCase());
              }
            },
            {
              Header: "SirenId",
              accessor: "sirenId",
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "SiteId",
              accessor: "siteId"
            },
            {
              Header: "Full Name",
              accessor: "givenName",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.givenName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Nurse",
              accessor: "LPN",
              filterMethod: (filter, row) => {
                let rowfilterLpn = row._original.LPN
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterLpn = filter.value.toLowerCase();
                return rowfilterLpn.includes(filterLpn);
              }
            }
          ]}
          filterable
          defaultSorted={[{ id: "LPN", desc: false }]}
          defaultPageSize={10}
          showPageSizeOptions={false}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  listNeedAssignedPatients: state.patientsListStore.listNeedAssignedPatients,
  listSirenStaff: state.patientsListStore.listSirenStaff
});
const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(AssignPreview);
