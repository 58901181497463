import React, { Component } from "react";
import "../../medicaldetails/MedicalDetailsHome.css";
import "../../medicaldetails/status.css";
import { connect } from "react-redux";
import leftFoot from "../../img/Left@3x.png";
import rightFoot from "../../img/Right@3x.png";
const warning_red_big = require("../../img/warn_red_big@3x.png");
const green_big = require("../../img/green_big.png");
const warning_yellow_big = require("../../img/warn_yellow_big@3x.png");
const purple_big = require("../../img/purple_big@3x.png");
class reviewTaskFootSvg extends Component {
  render() {
    let footStatus = this.props.footStatus;
    return (
      <svg
        crossOrigin="anonymous"
        width="100%"
        height="80%"
        id="svg_style"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 380 360"
      >
        <g className="right_style">
          <image xlinkHref={rightFoot} x="0" width="50%" height="90%" />
          <g className="rarch">
            {footStatus && footStatus.rarch === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="18%"
                y="42%"
                width="14%"
                height="14%"
              />
            ) : footStatus && footStatus.rarch === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="18%"
                y="42%"
                width="14%"
                height="14%"
              />
            ) : footStatus && footStatus.rarch === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="18%"
                y="42%"
                width="14%"
                height="14%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="18%"
                y="42%"
                width="14%"
                height="14%"
              />
            )}
          </g>
          <g className="rheel">
            {footStatus && footStatus.rheel === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="19%"
                y="70%"
                width="14%"
                height="14%"
              />
            ) : footStatus && footStatus.rheel === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="19%"
                y="70%"
                width="14%"
                height="14%"
              />
            ) : footStatus && footStatus.rheel === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="19%"
                y="70%"
                width="14%"
                height="14%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="19%"
                y="70%"
                width="14%"
                height="14%"
              />
            )}
          </g>
          <g className="rmts1">
            {footStatus && footStatus.rmts1 === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="30%"
                y="22%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.rmts1 === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="30%"
                y="22%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.rmts1 === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="30%"
                y="22%"
                width="8%"
                height="8%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="30%"
                y="22%"
                width="8%"
                height="8%"
              />
            )}
          </g>
          <g className="rmts3">
            {footStatus && footStatus.rmts3 === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="20%"
                y="21%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.rmts3 === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="20%"
                y="21%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.rmts3 === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="20%"
                y="21%"
                width="8%"
                height="8%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="20%"
                y="21%"
                width="8%"
                height="8%"
              />
            )}
          </g>
          <g className="rmts5">
            {footStatus && footStatus.rmts5 === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="11%"
                y="29%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.rmts5 === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="11%"
                y="29%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.rmts5 === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="11%"
                y="29%"
                width="8%"
                height="8%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="11%"
                y="29%"
                width="8%"
                height="8%"
              />
            )}
          </g>
          <g className="rtoe">
            {footStatus && footStatus.rtoe === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="31%"
                y="9%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.rtoe === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="31%"
                y="9%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.rtoe === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="31%"
                y="9%"
                width="8%"
                height="8%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="31%"
                y="9%"
                width="8%"
                height="8%"
              />
            )}
          </g>
        </g>
        <g className="left_style">
          <image
            xlinkHref={leftFoot}
            className="footImg"
            x="50%"
            width="50%"
            height="90%"
          />
          <g className="larch">
            {footStatus && footStatus.larch === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="68%"
                y="42%"
                width="14%"
                height="14%"
              />
            ) : footStatus && footStatus.larch === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="68%"
                y="42%"
                width="14%"
                height="14%"
              />
            ) : footStatus && footStatus.larch === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="68%"
                y="42%"
                width="14%"
                height="14%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="68%"
                y="42%"
                width="14%"
                height="14%"
              />
            )}
          </g>
          <g className="lheel">
            {footStatus && footStatus.lheel === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="67%"
                y="70%"
                width="14%"
                height="14%"
              />
            ) : footStatus && footStatus.lheel === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="67%"
                y="70%"
                width="14%"
                height="14%"
              />
            ) : footStatus && footStatus.lheel === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="67%"
                y="70%"
                width="14%"
                height="14%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="67%"
                y="70%"
                width="14%"
                height="14%"
              />
            )}
          </g>
          <g className="lmts1">
            {footStatus && footStatus.lmts1 === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="62%"
                y="22%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.lmts1 === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="62%"
                y="22%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.lmts1 === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="62%"
                y="22%"
                width="8%"
                height="8%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="62%"
                y="22%"
                width="8%"
                height="8%"
              />
            )}
          </g>
          <g className="lmts3">
            {footStatus && footStatus.lmts3 === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="72%"
                y="21%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.lmts3 === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="72%"
                y="21%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.lmts3 === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="72%"
                y="21%"
                width="8%"
                height="8%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="72%"
                y="21%"
                width="8%"
                height="8%"
              />
            )}
          </g>
          <g className="lmts5">
            {footStatus && footStatus.lmts5 === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="80%"
                y="29%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.lmts5 === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="80%"
                y="29%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.lmts5 === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="80%"
                y="29%"
                width="8%"
                height="8%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="80%"
                y="29%"
                width="8%"
                height="8%"
              />
            )}
          </g>
          <g className="ltoe">
            {footStatus && footStatus.ltoe === "NORMAL" ? (
              <image
                xlinkHref={green_big}
                x="61%"
                y="9%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.ltoe === "RED" ? (
              <image
                xlinkHref={warning_red_big}
                x="61%"
                y="9%"
                width="8%"
                height="8%"
              />
            ) : footStatus && footStatus.ltoe === "YELLOW" ? (
              <image
                xlinkHref={warning_yellow_big}
                x="61%"
                y="9%"
                width="8%"
                height="8%"
              />
            ) : (
              <image
                xlinkHref={purple_big}
                x="61%"
                y="9%"
                width="8%"
                height="8%"
              />
            )}
          </g>
        </g>
      </svg>
    );
  }
}
const mapStateToProp = state => ({});

const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(reviewTaskFootSvg);
