import moment from "moment";

const TIME_INTERVAL = 24 * 3600 * 1000;

export function getMacInfoOption(battery, steps, count) {
  if (battery.length === 0) {
    return {};
  }

  let dateListReal = battery.map(function(item) {
    return item.usertime;
  });

  let sixMonthBefore = moment(dateListReal[dateListReal.length - 1])
    .add(-180, "days")
    .format("YYYY-MM-DD");

  let totalDays =
    (moment(dateListReal[0]) - moment(sixMonthBefore)) / TIME_INTERVAL;

  let beforeDateList = [];
  let beforeBatteryList = [];

  for (let i = 0; i < totalDays; i++) {
    beforeBatteryList.push({
      usertime: moment(sixMonthBefore)
        .add(i, "days")
        .format("YYYY-MM-DD"),
      battery: "-"
    });
    beforeDateList.push(
      moment(sixMonthBefore)
        .add(i, "days")
        .format("YYYY-MM-DD")
    );
  }

  let dateList = [...beforeDateList, ...dateListReal];
  let batteryListTotal = [...beforeBatteryList, ...battery];
  let batteryList = batteryListTotal.map(function(item) {
    return item.battery;
  });

  let batteryColor = ["#00FF7F", "#FFA500", "#FF0000"];

  const option = {
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (600 - boxHeight) / 2;

        return [x, y];
      }
    },
    color: [...batteryColor],
    legend: [
      {
        data: [
          { name: "Good", icon: "rect" },
          { name: "Warn", icon: "rect" },
          { name: "Bad", icon: "rect" }
        ],
        left: 100,
        itemGap: 20,
        top: "5%",
        x: "left",
        y: "top"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        show: false,
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    // dataZoom: [
    //   {
    //     orient: "horizontal",
    //     x: 0,
    //     y: -100,
    //     show: true,
    //     realtime: true,
    //     start: minStatus === "min1" ? 30 : 0,
    //     end: 100
    //   }
    // ],
    grid: [
      {
        left: 40,
        right: 40,
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        position: "bottom",
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        axisLabel: {
          interval: "auto",
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "Battery",
        type: "value",
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 10, 5, 0]
        },
        min: 2000,
        max: 3050
      }
    ],
    visualMap: {
      show: false,
      type: "piecewise",
      seriesIndex: 3, //控制series 对应的区域
      pieces: [
        {
          gt: 2800,
          color: "#00FF7F"
        },
        {
          gt: 2700,
          lte: 2800,
          color: "#FFA500"
        },
        {
          gt: 0,
          lte: 2700,
          color: "red"
        }
      ]
    },
    series: [
      {
        name: "Good",
        type: "line"
      },
      {
        name: "Warn",
        type: "line"
      },
      {
        name: "Bad",
        type: "line"
      },
      {
        name: "battery",
        type: "line",
        seriesIndex: 3,
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        showAllSymbol: false,
        hoverAnimation: false,
        data: batteryList,
        // symbol: "none",
        label: {
          normal: {
            show: true
          }
        }
      }
    ]
  };

  return option;
}
