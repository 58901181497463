import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import _ from "lodash";
import { lowercaseRmSpace } from "../tools/stringProcess";
import { Icon, Popup } from "semantic-ui-react";

/**
 *  Created by shengli.zhang on 4/2/21 4:35 PM
 */

class LpnListPreview extends Component {
  render() {
    return (
      <div>
        <ReactTable
          data={
            this.props.lpnList &&
            this.props.lpnList.map(p => {
              const userName = p.id;
              const fullName = `${p.firstName} ${p.lastName}`;
              return {
                ...p,
                userName,
                fullName
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            lowercaseRmSpace(String(row[filter.id])).includes(
              lowercaseRmSpace(filter.value)
            )
          }
          columns={[
            {
              Header: "User",
              accessor: "userName"
            },
            {
              Header: "Full Name",
              accessor: "fullName",
              maxWidth: 160
            },
            {
              Header: "Primary Site",
              accessor: "primarySites",
              maxWidth: 400,
              Cell: props => {
                let siteList = [];
                if (props.value) {
                  siteList = JSON.parse(props.value);
                }
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <Popup
                      on="click"
                      pinned="true"
                      trigger={
                        <p className="sr-text-ellipsis">{siteList.join(",")}</p>
                      }
                    >
                      <div style={{ maxHeight: 600, overflowY: "auto" }}>
                        {siteList.map((id, index) => (
                          <p key={index}>{id}</p>
                        ))}
                      </div>
                    </Popup>
                  </div>
                );
              }
            },
            {
              Header: "Backup For",
              accessor: "backupFor",
              sortable: false,
              maxWidth: 400,
              Cell: props => {
                let backUpList = [];
                let hasDeleted = false;
                if (props.value) {
                  backUpList = JSON.parse(props.value);
                  backUpList = backUpList.map(id => {
                    const adminStaff = this.props.listSirenStaff.find(
                      user => user.id === id
                    );
                    if (adminStaff) {
                      return (<span>{`${adminStaff.firstName} ${adminStaff.lastName}`}</span>);
                    } else {
                      hasDeleted = true;
                      return (<del>{id}</del>);
                    }
                  });
                }
                if (backUpList.length === 0) {
                  return ""
                }
                return (
                  <Popup
                    trigger={
                      <p className="sr-text-ellipsis">{hasDeleted && <Icon color='yellow' name='warning sign' />}{backUpList.reduce((prev, curr) => [prev, ", ", curr])}</p>
                    }
                  >
                    {backUpList.map((name, index) => (
                      <p key={index}>{name}</p>
                    ))}
                  </Popup>
                );
              }
            },
            {
              Header: "Patient Load",
              accessor: "patientLoad"
            },
            {
              Header: "Patient Count",
              accessor: "patientCount"
            },
            {
              Header: "Rate(%)",
              accessor: "rate"
            }
          ]}
          defaultPageSize={10}
          showPageSizeOptions={false}
          defaultSorted={[
            {
              id: "rate",
              desc: true
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  listNeedAssignedPatients: state.patientsListStore.listNeedAssignedPatients,
  listSirenStaff: state.patientsListStore.listSirenStaff
});
export default connect(mapStateToProp, null)(LpnListPreview);
