import {
  Checkbox,
  Col,
  Icon,
  Input,
  Modal,
  notification,
  Popover,
  Row,
  Select
} from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";

const { Option } = Select;

export default class TableFitlerCustomize extends Component {
  state = {
    isModalVisible: false,
    loading: false,
    setting: this.props.tableConfig || this.props.defaultConfig || []
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.tableConfig !== prevProps.tableConfig) {
      // add missing column. if some columns are added later, server side didn't save them, add them from defaultColumns
      const setting = [...sirenStaffingTableDefaultConfig];
      for (let index = 0; index < setting.length; index++) {
        const element = setting[index];
        const savedElement = this.props.tableConfig.find(
          e2 => e2.column === element.column
        );
        if (savedElement) {
          setting[index] = savedElement;
        }
      }

      this.setState({ setting });
    }
  }

  showModal = () => {
    this.setState({
      isModalVisible: true
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    });
  };

  handleOk = () => {
    if (typeof this.props.onSaveFilter === "function") {
      this.setState({
        loading: true
      });
      this.props
        .onSaveFilter()
        .then(() => {
          this.setState({
            isModalVisible: false,
            loading: false
          });
        })
        .catch(e => {
          this.setState({
            loading: false
          });
          notification.error({
            message: "System Error",
            description: e.toString()
          });
        });
    } else {
      this.setState({
        isModalVisible: false
      });
    }
  };

  onChangeShowColumn = (event, column, index) => {
    const { checked } = event.target;
    const newColumn = { ...column, show: checked };
    const newSetting = this.state.setting.map((item, i) =>
      i === index ? newColumn : item
    );
    this.setState(
      {
        setting: newSetting
      },
      () => {
        if (typeof this.props.onChange === "function") {
          this.props.onChange(this.state.setting, newColumn, index);
        }
      }
    );
  };

  onChangeFixedColumn = (value, column, index) => {
    const newColumn = { ...column, fixed: value };
    if (newColumn.fixed === "none") {
      delete newColumn.fixed;
    }
    const newSetting = this.state.setting.map((item, i) =>
      i === index ? newColumn : item
    );
    this.setState(
      {
        setting: newSetting
      },
      () => {
        if (typeof this.props.onChange === "function") {
          this.props.onChange(this.state.setting, newColumn, index);
        }
      }
    );
  };

  renderHelp() {
    return (
      <div>
        <p>check row to display or hide table column</p>
        <p>Filter & Sort is readonly. Just operate table to make effect</p>
        <p style={{ display: "none" }}>drag & drop to reorder rows</p>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Icon type="setting" onClick={this.showModal} />
        <Modal
          title="Customize table display"
          width={800}
          visible={this.state.isModalVisible}
          confirmLoading={this.state.loading}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText={this.state.loading ? "Save to server" : "OK"}
        >
          <Popover content={this.renderHelp()} trigger="hover">
            <Icon
              type="info-circle"
              style={{
                fontSize: 24,
                color: "#ffe58f",
                position: "absolute",
                right: 20,
                top: 70,
                zIndex: 100
              }}
            />
          </Popover>
          {this.state.setting.map((item, index) => (
            <Row key={item.column} gutter={[8, 16]}>
              <Col span={6}>
                <Checkbox
                  key={item.column}
                  checked={item.show}
                  onChange={value =>
                    this.onChangeShowColumn(value, item, index)
                  }
                >
                  {item.column}
                </Checkbox>
              </Col>
              <Col span={5}>
                <label>Fixed: </label>
                <Select
                  defaultValue="none"
                  placeholder="None"
                  style={{ width: 80 }}
                  size="small"
                  value={item.fixed}
                  onChange={value =>
                    this.onChangeFixedColumn(value, item, index)
                  }
                >
                  <Option value="none">None</Option>
                  <Option value="left">Left</Option>
                  <Option value="right" disabled>
                    Right
                  </Option>
                </Select>
              </Col>
              <Col span={7}>
                <label>Filter: </label>
                <Input
                  style={{ width: 160 }}
                  placeholder="None"
                  disabled
                  value={item.filter}
                  size="small"
                />
              </Col>
              <Col span={6}>
                <label>Sort: </label>
                <Input
                  style={{ width: 80 }}
                  placeholder="None"
                  disabled
                  value={item.sort}
                  size="small"
                />
              </Col>
            </Row>
          ))}
        </Modal>
      </div>
    );
  }
}

TableFitlerCustomize.propTypes = {
  tableName: PropTypes.string.isRequired,
  tableConfig: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string.isRequired,
      show: PropTypes.bool.isRequired,
      fixed: PropTypes.oneOf(["left", "right"]),
      filter: PropTypes.string,
      sort: PropTypes.oneOf(["asc", "desc"])
    })
  ),
  defaultConfig: PropTypes.array,
  onChange: PropTypes.func,
  // should be a promise
  onSaveFilter: PropTypes.func
};

export const DMTTableName = "dmt";

export const sirenStaffingTableName = "sirenStaffing";
export const sirenStaffingTableColumnDisplayNameMap = {
  patientHasSmartPhone: "Smartphone"
};
export const sirenStaffingTableDefaultConfig = [
  {
    column: "issue",
    show: true,
    fixed: "left"
  },
  {
    column: "firstName",
    show: true,
    fixed: "left"
  },
  {
    column: "lastName",
    show: true,
    fixed: "left"
  },
  {
    column: "sirenId",
    show: true,
    sort: "desc"
  },
  {
    column: "birthday",
    show: true
  },
  {
    column: "doctorName",
    show: true
  },
  {
    column: "siteName",
    show: true
  },
  {
    column: "siteId",
    show: true
  },
  {
    column: "patientHasSmartPhone",
    show: true
  },
  {
    column: "nurse",
    show: true
  },
  {
    column: "lastDMT",
    show: true
  },
  {
    column: "followUpTimes",
    show: false
  },
  {
    column: "serviceE",
    show: true
  },
  {
    column: "lastServiceE",
    show: true
  },
  {
    column: "ccmTime",
    show: true
  },
  {
    column: "pcmTime",
    show: true
  },
  {
    column: "rpmTime",
    show: true
  },
  {
    column: "rtmTime",
    show: true
  },
  {
    column: "medicalStatus",
    show: true
  },
  {
    column: "last4DaysWorn",
    show: true
  },
  {
    column: "specialCase",
    show: true
  },
  {
    column: "programStatus",
    show: true
  },
  {
    column: "deviceStatus",
    show: true
  }
];
