import { message, Select } from "antd";
import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Icon, Modal, TextArea } from "semantic-ui-react";
import { changeAccountBillingStatus } from "../action/addAction";
import "../share/button.css";
import "./AccountStatus.css";
import ButtonSuccess from "./buttonSuccess";

const ACTIVE = "Active";
const NOT_ACTIVE = "Not active";

class ChangeBillingStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      note: "",
      accountBillingStatus: ACTIVE
    };
    this.handleConfirm.bind(this);
  }
  show = () => {
    if (this.props.userGroups.includes("admin")) {
      let accountBillingStatus = NOT_ACTIVE;
      if (this.props.accountBillingStatus === NOT_ACTIVE) {
        accountBillingStatus = ACTIVE;
      }
      this.setState({
        open: true,
        accountBillingStatus
      });
    }
  };
  handleConfirm = async () => {
    if (this.state.accountBillingStatus === this.props.accountBillingStatus) {
      message.info("you didn't modify status");
      return;
    }
    let user = await Auth.currentAuthenticatedUser();
    this.setState({ confirmSuccess: true });
    const { changeAccountBillingStatus } = this.props;

    const newStatus = {
      id: this.props.id,
      userId: this.props.userId,
      accountBillingStatus: this.state.accountBillingStatus,
      note: this.state.note,
      author: `${user.attributes.given_name} ${user.attributes.family_name}`
    };
    if (this.state.accountBillingStatus === ACTIVE) {
      newStatus.activeBillingBy = user.username;
      newStatus.title = "Account Billing Status: Active";
    } else {
      newStatus.pausedBillingBy = user.username;
      newStatus.title = "Account Billing Status: Not active";
    }
    changeAccountBillingStatus(newStatus, this.props.userInfo, this).then(
      () => {
        if (typeof this.props.onChange === "function") {
          this.props.onChange(this.state.accountBillingStatus);
        }
      }
    );
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  handleInputChange(value) {
    this.setState({ note: value });
  }
  handleBillingStatusChange = value => {
    this.setState({ accountBillingStatus: value, note: "" });
  };

  render() {
    const { Option } = Select;
    const { open } = this.state;
    return (
      <Modal
        onOpen={this.show}
        onClose={this.handleCancel}
        trigger={
          <div>
            {this.props.accountBillingStatus === NOT_ACTIVE
              ? "Not active"
              : this.props.accountBillingStatus === ACTIVE
              ? "Active"
              : ""}
            {this.props.userGroups.includes("admin") && (
              <Icon as="i" name="edit" className="edit" />
            )}
          </div>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>Modify Patient's Account Billing Status</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <label>Account Billing Status</label>
                  <Select
                    value={this.state.accountBillingStatus}
                    onChange={this.handleBillingStatusChange}
                    style={{ width: "100%" }}
                    placeholder="Please Select"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value={ACTIVE}>Active</Option>
                    <Option value={NOT_ACTIVE}>Not active</Option>
                  </Select>
                  <label style={{ marginTop: 20 }}>Reason</label>
                  <TextArea
                    className="note_comments"
                    onChange={(e, data) => {
                      this.handleInputChange(e.target.value);
                    }}
                    placeholder="Reason for change to patient's Account Billing Status"
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  listPatients: state.patientsListStore.listPatients,
  userGroups: state.authGroupStore.session.userGroups
});
const mapDispatchToProp = dispatch => ({
  changeAccountBillingStatus: (newStatus, userInfo, self) =>
    dispatch(changeAccountBillingStatus(newStatus, userInfo, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(ChangeBillingStatus);
