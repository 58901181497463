import moment from "moment/moment";

//Steps
export function getChartStepsOptions(steps, minStatus) {
  //usertime
  let dateList = steps.map(function(item) {
    return item.usertime
      ? moment(item.usertime.replace("Z", "")).format("MM/DD HH:mm")
      : "-";
  });
  //steps
  let stepData = steps.map(function(item) {
    return item.steps;
  });
  let stepColor = ["#706DAB"];

  return {
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (330 - boxHeight) / 2;

        return [x, y];
      }
    },
    color: [...stepColor],
    legend: [
      {
        data: ["steps"],
        left: 50,
        top: "15%",
        x: "left"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        show: false,
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -10,
        show: true,
        realtime: true,
        start: minStatus === "min1" ? 30 : 0,
        end: 100,
        top: "4%",
        left: 100,
        right: 100
      }
    ],
    grid: [
      {
        left: 40,
        right: 40,
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        position: "bottom",
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        axisLabel: {
          interval: minStatus === "min1" ? 200 : 100,
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "Steps",
        type: "value",
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 10, 5, 0]
        },
        min: 0,
        max: "dataMax"
      }
    ],
    series: [
      {
        name: "steps",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: stepData,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#706DAB"
            }
          }
        }
      }
    ]
  };
}

//Left Foot Position
export function getChartPositionOptions(positionTag, minStatus) {
  //usertime
  let dateList = positionTag.map(function(item) {
    return item.usertime
      ? moment(item.usertime.replace("Z", "")).format("MM/DD HH:mm")
      : "-";
  });

  //left gsensor
  let gsensorX = positionTag.map(function(item) {
    return item.gsensorx;
  });
  let gsensorY = positionTag.map(function(item) {
    return item.gsensory;
  });
  let gsensorZ = positionTag.map(function(item) {
    return item.gsensorz;
  });

  let gsensorColor = ["#529DE1", "#9AC078", "#7472AF"];

  return {
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (330 - boxHeight) / 2;

        return [x, y];
      }
    },
    color: [...gsensorColor],
    legend: [
      {
        data: ["gsensorX", "gsensorY", "gsensorZ"],
        top: "15%",
        left: 50,
        x: "left"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -100,
        show: true,
        realtime: true,
        start: minStatus === "min1" ? 30 : 0,
        end: 100
      }
    ],
    grid: [
      {
        left: 40,
        right: 40,
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        position: "bottom",
        axisLabel: {
          interval: minStatus === "min1" ? 200 : 100,
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "Position",
        type: "value",
        axisLabel: {
          formatter: function(data) {
            return Math.abs(data);
          }
        },
        axisLine: { onZero: true, show: false },
        inverse: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 10, 5, 0]
        }
      }
    ],
    series: [
      {
        name: "gsensorX",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: gsensorX,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4E9FE4"
            }
          }
        }
      },
      {
        name: "gsensorY",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: gsensorY,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#9AC177"
            }
          }
        }
      },
      {
        name: "gsensorZ",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: gsensorZ,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ac89c4"
            }
          }
        }
      }
    ]
  };
}
