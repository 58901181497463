import { API, graphqlOperation } from "aws-amplify";
import _api from "../graphql/_api";

/**
 *  Created by shengli.zhang on 2020-05-07 16:25
 */

export function getDoctorNpi(firstName, lastName, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.getDoctorNpi(), { firstName, lastName })
      );
      if (newEvent) {
        let res = JSON.parse(newEvent.data.getDoctorNpi);
        if (res.result_count > 0) {
          if (self.state.isPrescribing) {
            self.setState({
              doctorNPIs: res.results,
              doctorNPI: res.results[0].number
            });
          } else {
            self.setState({
              doctorNPI: "",
              doctorNPIs: res.results
            });
          }
        } else {
          self.setState({ doctorNPIs: [], doctorNPI: "" });
        }
      }
    } catch (e) {
      console.log("getDoctorNpi error: ", e);
    }
  };
}

export function editDoctorNpi(firstName, lastName, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.getDoctorNpi(), { firstName, lastName })
      );
      if (newEvent) {
        let res = JSON.parse(newEvent.data.getDoctorNpi);
        if (res.result_count > 0) {
          self.setState({
            doctorNPIs: res.results
            // doctorNPI: res.results[0].number
          });
        } else {
          self.setState({ doctorNPIs: [] });
        }
      }
    } catch (e) {
      console.log("getDoctorNpi error: ", e);
    }
  };
}

export function checkSiteNPI(organizationName, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.getSiteNpi(), { organizationName })
      );
      if (newEvent) {
        let res = JSON.parse(newEvent.data.getSiteNpi);
        if (res.result_count > 0) {
          self.setState({
            siteNPIs: res.results,
            siteNPI: res.results[0].number
          });
        } else {
          self.setState({ siteNPIs: [], siteNPI: "" });
        }
      }
    } catch (e) {
      console.log("checkSiteNPI error: ", e);
    }
  };
}

export function editSiteNPI(organizationName, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.getSiteNpi(), { organizationName })
      );
      if (newEvent) {
        let res = JSON.parse(newEvent.data.getSiteNpi);
        if (res.result_count > 0) {
          self.setState({
            siteNPIs: res.results
          });
        } else {
          self.setState({ siteNPIs: [] });
        }
      }
    } catch (e) {
      console.log("checkSiteNPI error: ", e);
    }
  };
}
