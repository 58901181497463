import { CloseOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Modal, Select, Tab } from "semantic-ui-react";
import { changeTempUnitState, clearUserGroup } from "../action/chartAction";

const Fahrenheit = "Fahrenheit";
const Celsius = "Celsius";

class ProfileSetting extends Component {
  state = {
    open: false,
    changingUnit: false,
    email: "",
    fullName: ""
  };

  componentDidMount() {
    Auth.currentSession().then(data => {
      const { email, given_name, family_name } = data.idToken.payload;
      this.setState({
        email,
        fullName: `${given_name || ""} ${family_name || ""}`
      });
    });
  }

  logout = async () => {
    try {
      await Auth.signOut();
      document.dispatchEvent(new Event("changeNewPassword"));
    } catch (e) {
      notification.error({
        message: "Internal Error",
        description: e.toString()
      });
    }
  };

  temperatureUnitChange = async unit => {
    this.setState({ changingUnit: true });
    this.props.clearUserGroup("");
    this.props.changeTempUnit(unit);

    try {
      let user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:temp_unit": unit
      });
    } catch (error) {
      notification.error({
        message: "Internal Error",
        description: error.toString()
      });
    }

    this.setState({ changingUnit: false });
  };

  render() {
    return (
      <Modal
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={this.state.open}
        trigger={<div>Profile Settings</div>}
      >
        <Modal.Header>
          Profile Settings
          <CloseOutlined
            style={{ float: "right" }}
            onClick={e => this.setState({ open: false })}
          />
        </Modal.Header>
        <Modal.Content>
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={[
              {
                menuItem: "Profile",
                render: () => (
                  <Tab.Pane attached={false} style={{ minHeight: 300 }}>
                    <Form size="large" style={{ margin: 16 }}>
                      <Form.Field
                        label="Full Name"
                        control="input"
                        value={this.state.fullName}
                        disabled
                      />
                      <Form.Field
                        label="Email"
                        control="input"
                        value={this.state.email}
                        disabled
                      />
                      <Form.Field
                        label="Groups"
                        control="input"
                        value={this.props.session.userGroups.join(',')}
                        disabled
                      />
                      <div style={{ marginTop: 20 }}>
                        <span
                          className="spanonclick"
                          onClick={this.logout}
                          style={{ color: "#0f8cff" }}
                        >
                          Change password
                        </span>
                      </div>
                    </Form>
                  </Tab.Pane>
                )
              },
              {
                menuItem: "Temperature Units",
                render: () => {
                  return (
                    <Tab.Pane attached={false} style={{ minHeight: 300 }}>
                      <Form
                        size="large"
                        style={{ margin: 16 }}
                        loading={this.state.changingUnit}
                      >
                        <Form.Field
                          control={Select}
                          fluid
                          label="Temperature"
                          defaultValue={this.props.tempUnit}
                          options={[
                            {
                              key: Fahrenheit,
                              text: Fahrenheit,
                              value: Fahrenheit
                            },
                            { key: Celsius, text: Celsius, value: Celsius }
                          ]}
                          placeholder="Temperature"
                          onChange={(e, { value }) => {
                            this.temperatureUnitChange(value);
                          }}
                        />
                      </Form>
                    </Tab.Pane>
                  );
                }
              }
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({ open: false })} primary>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  tempUnit: state.patientsListStore.tempUnit,
  session: state.authGroupStore.session
});

const mapDispatchToProp = dispatch => ({
  changeTempUnit: tempUnit => dispatch(changeTempUnitState(tempUnit)),
  clearUserGroup: userGroup => dispatch(clearUserGroup(userGroup))
});

export default connect(mapStateToProp, mapDispatchToProp)(ProfileSetting);
