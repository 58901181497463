import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { updateSockDFU } from "../action/sockAction";
import ButtonSuccess from "../home/buttonSuccess";
import "../share/button.css";
import "./unlink.css";

class SockDFU extends Component {
  constructor(props) {
    super(props);
    this.handleConfirm.bind(this);
  }
  state = { open: false, confirmSuccess: false };

  show = () => this.setState({ open: true, confirmSuccess: false });
  handleConfirm = value => {
    this.setState({ confirmSuccess: true, open: false });
    this.props.updateSockDFU(this.props.userId, this.props.serialNo, value);
  };
  handleCancel = value => {
    this.setState({ open: false, confirmSuccess: false });
    this.props.updateSockDFU(this.props.userId, this.props.serialNo, value);
  };

  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini" style={{ heigth: "35.28px" }}>
            SockDFU
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <p>Are you sure to sockDfu?</p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm("YES")}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            YES
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel("NO")}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "NO"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({
  updateSockDFU: (userId, serialNo, serialDFU) =>
    dispatch(updateSockDFU(userId, serialNo, serialDFU))
});
export default connect(mapStateToProp, mapDispatchToProp)(SockDFU);
