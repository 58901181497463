import { forEach, isNumber, merge } from "lodash";
import moment from "moment/moment";
import { celToFahrenheit, celToFahrenheitDelta } from "../action/chartAction";

const TemperatureColor = [
  "#4E9FE4",
  "#9AC177",
  "#AC89C4",
  "#EE8C8E",
  "#F9D463",
  "#F2B456"
];

const KSeriesBasic = {
  type: "line",
  xAxisIndex: 0,
  yAxisIndex: 0,
  symbolSize: 8,
  hoverAnimation: false,
  symbol: "none"
};

const waterMarkColors = ["#67c23a42", "#e6a23c42"];
function getMarkColor(mark) {
  if (mark == 3) {
    let r = parseInt(waterMarkColors[0].substring(1, 3), 16);
    let g = parseInt(waterMarkColors[0].substring(3, 5), 16);
    let b = parseInt(waterMarkColors[0].substring(5, 7), 16);
    let a = Number.prototype.toFixed.call(
      parseInt(waterMarkColors[0].substring(7, 9), 16) / 255,
      2
    );

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  } else if (mark == 6) {
    let r = parseInt(waterMarkColors[1].substring(1, 3), 16);
    let g = parseInt(waterMarkColors[1].substring(3, 5), 16);
    let b = parseInt(waterMarkColors[1].substring(5, 7), 16);
    let a = Number.prototype.toFixed.call(
      parseInt(waterMarkColors[1].substring(7, 9), 16) / 255,
      2
    );

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  } else {
    let y1 = parseInt(waterMarkColors[0].substring(1, 3), 16);
    let y2 = parseInt(waterMarkColors[1].substring(1, 3), 16);
    let r = Math.round(((parseFloat(mark) - 3) / (6 - 3)) * (y2 - y1) + y1);

    y1 = parseInt(waterMarkColors[0].substring(3, 5), 16);
    y2 = parseInt(waterMarkColors[1].substring(3, 5), 16);
    let g = Math.round(((parseFloat(mark) - 3) / (6 - 3)) * (y2 - y1) + y1);

    y1 = parseInt(waterMarkColors[0].substring(5, 7), 16);
    y2 = parseInt(waterMarkColors[1].substring(5, 7), 16);
    let b = Math.round(((parseFloat(mark) - 3) / (6 - 3)) * (y2 - y1) + y1);

    y1 = parseInt(waterMarkColors[0].substring(7, 9), 16);
    y2 = parseInt(waterMarkColors[1].substring(7, 9), 16);
    let a = Number.prototype.toFixed.call(
      (((parseFloat(mark) - 3) / (6 - 3)) * (y2 - y1) + y1) / 255,
      2
    );

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }
}

//Delta
export function getChartDeltaOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus,
  tempUnit,
  delta_auto,
  footStatus
) {
  //usertime
  let dateList = delta.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime ? moment(item.usertime).format("MM/DD HH:mm") : "-";
  });

  let data_area = [];
  let color_area = [];
  if (minStatus === "min1") {
    if (footStatus && footStatus.noShoesDetails) {
      let withoutShoesPeriod = JSON.parse(footStatus.noShoesDetails);
      forEach(withoutShoesPeriod, function(item) {
        data_area.push([
          {
            name: "",
            xAxis: moment(moment(item.start.replace("Z", ""))).format(
              "MM/DD HH:mm"
            )
          },
          {
            xAxis: moment(moment(item.end.replace("Z", ""))).format(
              "MM/DD HH:mm"
            )
          }
        ]);
        color_area.push("rgba(112, 109, 171, 0.3)");
      });
    }
  }

  const extractChart = [
    {
      key: "dtoe",
      name: "dtoe"
    },
    {
      key: "dmts1",
      name: "dmts1"
    },
    {
      key: "dmts3",
      name: "dmts3"
    },
    {
      key: "dmts5",
      name: "dmts5"
    },
    {
      key: "darch",
      name: "darch"
    },
    {
      key: "dheel",
      name: "dheel"
    }
  ];

  const option = baseTemperatureOption(minStatus, tempUnit);

  const series = extractChart.map(e => ({
    ...KSeriesBasic,
    name: e.name,
    data: delta.map(function(item) {
      return tempUnit === "Fahrenheit"
        ? item[e.key] > 0
          ? celToFahrenheitDelta(item[e.key])
          : celToFahrenheitDelta(item[e.key])
        : item[e.key];
    }),
    markLine: {
      symbol: "none",
      data: [
        {
          yAxis: tempUnit === "Fahrenheit" ? 4 : 2.2,
          lineStyle: {
            color: "#a88ac3",
            width: 2,
            type: "dashed"
          }
        },
        {
          yAxis: tempUnit === "Fahrenheit" ? -4 : -2.2,
          lineStyle: {
            color: "#a88ac3",
            width: 2,
            type: "dashed"
          },
          label: {
            show: true,
            position: "end",
            color: "#a88ac3",
            formatter: tempUnit === "Fahrenheit" ? "4" : "2.2"
          }
        },
        {
          yAxis: 0,
          lineStyle: {
            color: "#748390",
            width: 2,
            type: "dashed"
          }
        }
      ]
    }
  }));

  series[0].markArea = {
    data: data_area,
    itemStyle: {
      normal: {
        color: color_area
      }
    },
    label: {
      normal: {
        show: true,
        position: "insideTop",
        verticalAlign: "top",
        opacity: 1,
        padding: 20
      }
    }
  };

  const extraOption = {
    title: {
      text:
        minStatus === "min1" &&
        footStatus &&
        isNumber(footStatus.timewornWithoutShoes)
          ? "Delta(left-right)   Time Worn Without Shoes: " +
            footStatus.timewornWithoutShoes +
            " minutes"
          : "Delta(left-right)",
      top: 60
    },
    legend: [
      {
        data: ["dtoe", "dmts1", "dmts3", "dmts5", "darch", "dheel"],
        top: "25%",
        left: 50,
        x: "left"
      }
    ],
    dataZoom: [
      {
        top: "4%"
      }
    ],
    grid: [
      {
        top: "30%"
      }
    ],
    xAxis: [
      {
        data: dateList
      }
    ],
    yAxis: [
      {
        axisLabel: {
          formatter: function(data) {
            return Math.abs(data);
          }
        },
        min: delta_auto ? null : tempUnit === "Fahrenheit" ? -10 : -6,
        max: delta_auto ? null : tempUnit === "Fahrenheit" ? 10 : 6
      },
      {
        type: "category",
        data: [
          {
            value: "Right",
            textStyle: {
              color: "#828591"
            }
          },
          {
            value: "Left",
            textStyle: {
              color: "#000"
            }
          }
        ],
        position: "left",
        offset: 15,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          rotate: 90,
          fontWeight: "bolder",
          fontSize: 14
        }
      }
    ],
    series
  };

  return merge(option, extraOption);
}

export function getChartDeltaWashedOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus,
  tempUnit,
  delta_auto
) {
  const options = getChartDeltaOptions(
    delta,
    steps,
    leftTemp,
    rightTemp,
    leftPosition,
    rightPosition,
    minStatus,
    tempUnit,
    delta_auto
  );

  options.title.text = "Delta Cleaned(left-right)";
  options.dataZoom[0].show = false;

  return options;
}

//Steps
export function getChartStepsOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus
) {
  //usertime
  let dateList = steps.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime ? moment(item.usertime).format("MM/DD HH:mm") : "-";
  });

  //steps
  let stepData = steps.map(function(item) {
    return item.steps;
  });

  const option = baseTemperatureOption(minStatus);

  const extraOption = {
    title: {
      text: "Steps"
    },
    color: ["#706DAB"],
    legend: [
      {
        data: ["steps"]
      }
    ],
    xAxis: [
      {
        data: dateList
      }
    ],
    yAxis: [
      {
        name: "",
        min: 0,
        max: "dataMax"
      }
    ],
    series: [
      {
        name: "steps",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: stepData,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#706DAB"
            }
          }
        }
      }
    ]
  };

  return merge(option, extraOption);
}

function baseTemperatureOption(minStatus, tempUnit) {
  return {
    tooltip: {
      trigger: "axis",
      textStyle: {
        align: "left"
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (2000 / 6 - boxHeight) / 2;

        return [x, y];
      }
    },
    color: TemperatureColor,
    legend: [
      {
        top: "15%",
        left: 50,
        x: "left"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -100,
        show: true,
        realtime: true,
        start: 0,
        end: 100
      }
    ],
    title: {
      textStyle: {
        fontSize: 14
      },
      top: 20,
      left: 15
    },
    grid: [
      {
        left: 40,
        right: 40,
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CED1",
            width: 1.5
          }
        },
        position: "bottom",
        axisLabel: {
          interval: minStatus === "min1" ? 179 : "auto",
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: tempUnit === "Fahrenheit" ? "ºF" : "ºC",
        type: "value",
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        min: tempUnit === "Fahrenheit" ? celToFahrenheit(20) : 20,
        max: tempUnit === "Fahrenheit" ? celToFahrenheit(45) : 45,
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 15,
          color: "#ff0000",
          padding: [0, 0, -22, 5]
        }
      }
    ]
  };
}

function getChartTempOptions(foot, data, minStatus, tempUnit) {
  //usertime
  let dateList = data.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime ? moment(item.usertime).format("MM/DD HH:mm") : "-";
  });

  let source = null;
  // let source_app = null;
  let source_hub = null;
  let data_area = [];
  let color_area = [];
  if (minStatus === "min1") {
    source = data.map(function(item) {
      if (item.source) {
        if (item.source === "simulator") {
          return "-";
        } else {
          if (item.source.includes(":")) {
            return item.source.split(":")[0].includes("app") ? "app" : "hub";
          } else if (item.source.includes("app")) {
            return "app";
          } else {
            if (item.source === "-") {
              return "-";
            }
            return "hub";
          }
        }
      }
      return "-";
    });
    // source_app = source.map(function(item) {
    //   return item === "app" ? 20 : "-";
    // });
    source_hub = source.map(function(item) {
      return item === "hub" ? 20 : "-";
    });
    let hub_area = [];
    let start = false;
    let startIndex = 0;
    let endIndex = 0;
    forEach(source_hub, function(item, k) {
      if (item === 20) {
        if (!start) {
          start = true;
          startIndex = k;
          if (k === source_hub.length - 1) {
            start = false;
            endIndex = k;
            hub_area.push([startIndex, endIndex]);
          }
        } else {
          if (k === source_hub.length - 1) {
            start = false;
            endIndex = k;
            hub_area.push([startIndex, endIndex]);
          }
        }
      } else {
        if (start) {
          start = false;
          endIndex = k - 1;
          hub_area.push([startIndex, endIndex]);
        }
      }
    });
    forEach(hub_area, function(item) {
      data_area.push([
        {
          name: "Hub",
          xAxis: dateList[item[0]]
        },
        { xAxis: dateList[item[1]] }
      ]);
      color_area.push("rgba(193, 210, 240, 0.1)");
    });
  }

  const option = baseTemperatureOption(minStatus, tempUnit);

  const extractChart = [
    {
      key: "toe",
      name: foot === "left" ? "ltoe" : "rtoe"
    },
    {
      key: "mts1",
      name: foot === "left" ? "lmts1" : "rmts1"
    },
    {
      key: "mts3",
      name: foot === "left" ? "lmts3" : "rmts3"
    },
    {
      key: "mts5",
      name: foot === "left" ? "lmts5" : "rmts5"
    },
    {
      key: "arch",
      name: foot === "left" ? "larch" : "rarch"
    },
    {
      key: "heel",
      name: foot === "left" ? "lheel" : "rheel"
    }
  ];
  const series = extractChart.map(e => ({
    ...KSeriesBasic,
    name: e.name,
    data: data.map(function(item) {
      return tempUnit === "Fahrenheit"
        ? celToFahrenheit(item[e.key])
        : item[e.key];
    })
  }));
  if (minStatus === "min1") {
    series.push(
      {
        type: "custom",
        name: "MAC",
        data: data.map(item => item.mac),
        renderItem: function() {}
      },
      {
        type: "custom",
        name: "lag",
        data: data.map(item => item.lag),
        renderItem: function() {}
      }
    );
  }

  series[0].markArea = {
    data: data_area,
    itemStyle: {
      normal: {
        color: color_area
      }
    },
    label: {
      normal: {
        show: true,
        position: "insideTop",
        verticalAlign: "top",
        opacity: 1,
        padding: 20
      }
    }
  };

  const extraOption = {
    legend: [
      {
        data: extractChart.map(v => v.name)
      }
    ],
    title: {
      text: `${foot === "left" ? "Left" : "Right"} Foot Temperature`
    },
    xAxis: [
      {
        data: dateList
      }
    ],
    tooltip: {
      formatter: function(params) {
        // if lag >= 3, show it; else hide it
        const tips = params.map(p => {
          if (p.seriesName === "lag") {
            if (p.data && p.data >= 3) {
              return `${p.marker}${p.seriesName}: ${p.data}d`;
            }
          } else {
            return `${p.marker}${p.seriesName}: ${p.data || "-"}`;
          }
        });
        tips.unshift(params[0].name);

        return tips.join("<br/>");
      }
    },
    series
  };

  return merge(option, extraOption);
}

//Left Foot Temperature
export function getChartLeftTempOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus,
  tempUnit
) {
  return getChartTempOptions("left", leftTemp, minStatus, tempUnit);
}

export function getChartLeftTempWashedOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus,
  tempUnit,
  markedData
) {
  //usertime
  let dateList = leftTemp.map(function(item) {
    return item.usertime ? moment(item.usertime).format("MM/DD HH:mm") : "-";
  });

  const extractChart = [
    {
      key: "toe",
      name: "ltoe"
    },
    {
      key: "mts1",
      name: "lmts1"
    },
    {
      key: "mts3",
      name: "lmts3"
    },
    {
      key: "mts5",
      name: "lmts5"
    },
    {
      key: "arch",
      name: "larch"
    },
    {
      key: "heel",
      name: "lheel"
    }
  ];

  // compute mark area
  let data_area = [];
  if (minStatus === "min1") {
    let lastItemIndex = 0;
    let lastItem = leftTemp[lastItemIndex];
    leftTemp.forEach((item, index) => {
      if (item.mark !== lastItem.mark || index === leftTemp.length - 1) {
        if (lastItem.toe && lastItem.toe !== "-") {
          data_area.push([
            {
              name: lastItem.mark,
              xAxis: dateList[lastItemIndex],
              itemStyle: {
                color: getMarkColor(lastItem.mark)
              }
            },
            { xAxis: dateList[index - 1] }
          ]);
        }
        lastItemIndex = index;
        lastItem = leftTemp[lastItemIndex];
      }
    });
  }

  const option = baseTemperatureOption(minStatus, tempUnit);

  const series = extractChart.map(e => ({
    ...KSeriesBasic,
    name: e.name,
    data: leftTemp.map(function(item) {
      return tempUnit === "Fahrenheit"
        ? celToFahrenheit(item[e.key])
        : item[e.key];
    })
  }));
  series[0].markArea = {
    data: data_area,
    label: {
      normal: {
        show: true,
        position: "insideTop",
        verticalAlign: "top",
        opacity: 1,
        padding: 20
      }
    }
  };

  series.push({
    type: "custom",
    renderItem: function(params, api) {
      var start = api.coord([api.value(0), 20]);
      var end = api.coord([api.value(1), 25]);
      return {
        type: "rect",
        shape: {
          x: start[0],
          y: start[1],
          width: end[0] - start[0],
          height: end[1] - start[1]
        },
        style: api.style()
      };
    },
    label: {
      show: true,
      position: "insideTop",
      formatter: "{@[2]}"
    },
    data: markedData.map(e => {
      let h = parseInt(e.startTime.substring(11, 13));
      let m = parseInt(e.startTime.substring(14, 16));
      const start = h * 60 + m;
      h = parseInt(e.endTime.substring(11, 13));
      m = parseInt(e.endTime.substring(14, 16));
      const end = h * 60 + m;

      return [start, end, e.mark];
    })
  });

  const extraOption = {
    title: {
      text: "Left Foot Temperature Cleaned"
    },
    legend: [
      {
        data: ["ltoe", "lmts1", "lmts3", "lmts5", "larch", "lheel"]
      }
    ],
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          show: false
        },
        restore: {
          show: false
        },
        dataZoom: {
          show: false
        },
        brush: {
          type: ["lineX"]
        }
      }
    },
    brush: {
      id: "leftfoot",
      throttleType: "debounce",
      throttleDelay: 300,
      xAxisIndex: 0,
      outOfBrush: {
        colorAlpha: 0.1
      }
    },
    xAxis: [
      {
        data: dateList
      }
    ],
    series
  };
  return merge(option, extraOption);
}

//Right Foot Temperature
export function getChartRightTempOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus,
  tempUnit
) {
  return getChartTempOptions("right", rightTemp, minStatus, tempUnit);
}

export function getChartRightTempWashedOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus,
  tempUnit,
  markedData
) {
  //usertime
  let dateList = rightTemp.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime ? moment(item.usertime).format("MM/DD HH:mm") : "-";
  });

  const extractChart = [
    {
      key: "toe",
      name: "rtoe"
    },
    {
      key: "mts1",
      name: "rmts1"
    },
    {
      key: "mts3",
      name: "rmts3"
    },
    {
      key: "mts5",
      name: "rmts5"
    },
    {
      key: "arch",
      name: "rarch"
    },
    {
      key: "heel",
      name: "rheel"
    }
  ];

  let data_area = [];
  if (minStatus === "min1") {
    let lastItemIndex = 0;
    let lastItem = rightTemp[lastItemIndex];
    rightTemp.forEach((item, index) => {
      if (item.mark !== lastItem.mark || index === rightTemp.length - 1) {
        if (lastItem.toe && lastItem.toe !== "-") {
          data_area.push([
            {
              name: lastItem.mark,
              xAxis: dateList[lastItemIndex],
              itemStyle: {
                color: getMarkColor(lastItem.mark)
              }
            },
            { xAxis: dateList[index - 1] }
          ]);
        }
        lastItemIndex = index;
        lastItem = rightTemp[lastItemIndex];
      }
    });
  }

  const option = baseTemperatureOption(minStatus, tempUnit);

  const series = extractChart.map(e => ({
    ...KSeriesBasic,
    name: e.name,
    data: rightTemp.map(function(item) {
      return tempUnit === "Fahrenheit"
        ? celToFahrenheit(item[e.key])
        : item[e.key];
    })
  }));
  series[0].markArea = {
    data: data_area,
    label: {
      normal: {
        show: true,
        position: "insideTop",
        verticalAlign: "top",
        opacity: 1,
        padding: 20
      }
    }
  };
  series.push({
    type: "custom",
    renderItem: function(params, api) {
      var start = api.coord([api.value(0), 20]);
      var end = api.coord([api.value(1), 25]);
      return {
        type: "rect",
        shape: {
          x: start[0],
          y: start[1],
          width: end[0] - start[0],
          height: end[1] - start[1]
        },
        style: api.style()
      };
    },
    label: {
      show: true,
      position: "insideTop",
      formatter: "{@[2]}"
    },
    data: markedData.map(e => {
      let h = parseInt(e.startTime.substring(11, 13));
      let m = parseInt(e.startTime.substring(14, 16));
      const start = h * 60 + m;
      h = parseInt(e.endTime.substring(11, 13));
      m = parseInt(e.endTime.substring(14, 16));
      const end = h * 60 + m;

      return [start, end, e.mark];
    })
  });

  const extraOption = {
    title: {
      text: "Right Foot Temperature Cleaned"
    },
    legend: [
      {
        data: ["rtoe", "rmts1", "rmts3", "rmts5", "rarch", "rheel"]
      }
    ],
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          show: false
        },
        restore: {
          show: false
        },
        dataZoom: {
          show: false
        },
        brush: {
          type: ["lineX"]
        }
      }
    },
    brush: {
      id: "rightfoot",
      throttleType: "debounce",
      throttleDelay: 300,
      xAxisIndex: 0,
      outOfBrush: {
        colorAlpha: 0.1
      }
    },
    xAxis: [
      {
        data: dateList
      }
    ],
    series
  };

  return merge(option, extraOption);
}

//Left Foot Position
export function getChartLeftPositionOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus
) {
  return getFootPositionOption("left", leftPosition, minStatus);
}

//Right Foot Position
export function getChartRightPositionOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus
) {
  return getFootPositionOption("right", rightPosition, minStatus);
}

function getFootPositionOption(foot, data, minStatus) {
  //usertime
  let dateList = data.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime ? moment(item.usertime).format("MM/DD HH:mm") : "-";
  });

  let gsensorColor = ["#529DE1", "#9AC078", "#7472AF"];
  const legendNames =
    foot === "left"
      ? ["lgsensorX", "lgsensorY", "lgsensorZ"]
      : ["rgsensorX", "rgsensorY", "rgsensorZ"];

  const option = baseTemperatureOption(minStatus);

  const extraOption = {
    title: {
      text: `${foot === "left" ? "Left" : "Right"} Foot Position`
    },
    color: gsensorColor,
    legend: [
      {
        data: legendNames
      }
    ],
    xAxis: [
      {
        data: dateList
      }
    ],
    yAxis: [
      {
        name: "",
        axisLabel: {
          formatter: function(data) {
            return Math.abs(data);
          }
        },
        inverse: false,
        min: null,
        max: null
      }
    ],
    series: [
      {
        ...KSeriesBasic,
        name: legendNames[0],
        data: data.map(item => item.gsensorx)
      },
      {
        ...KSeriesBasic,
        name: legendNames[1],
        data: data.map(item => item.gsensory)
      },
      {
        ...KSeriesBasic,
        name: legendNames[2],
        data: data.map(item => item.gsensorz)
      }
    ]
  };
  return merge(option, extraOption);
}
