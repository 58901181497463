import * as TYPES from "../types/types";
import _ from "lodash";
import { API } from "aws-amplify";
import message from "../tools/message-info";

async function getSocksInBox(boxId) {
  const apiName = "dev-trial-dashboard";
  const path = "/admin/getSocksInBox";
  const myInit = {
    queryStringParameters: {
      boxId
    }
  };
  return API.get(apiName, path, myInit);
}

async function replaceBoxSocks(replaceData) {
  const apiName = "dev-trial-dashboard";
  const path = "/admin/replaceSocks";
  const myInit = {
    body: replaceData,
    headers: {}
  };
  return API.post(apiName, path, myInit);
}

export function getCurrentBoxInfo(boxId, self) {
  return async dispatch => {
    try {
      let ret = await getSocksInBox(boxId);
      handleBoxInfo("current", ret, dispatch, self);
    } catch (e) {
      message.info(e);
      console.log("getCurrentBoxInfo error: ", e);
    }
  };
}

export function getReplaceBoxInfo(boxId, self) {
  return async dispatch => {
    try {
      let ret = await getSocksInBox(boxId);
      handleBoxInfo("replace", ret, dispatch, self);
    } catch (e) {
      message.info(e);
      console.log("getReplaceBoxInfo error: ", e);
    }
  };
}

function handleBoxInfo(type, data, dispatch, self) {
  let newBoxInfo = [];
  _.forEach(data, function(item) {
    if (newBoxInfo.length === 0) {
      newBoxInfo.push(item);
    } else {
      let index = _.filter(newBoxInfo, { serialNo: item.serialNo });
      if (index.length > 0) {
        if (index[0].fwVersion < item.fwVersion) {
          for (let i = 0; i < newBoxInfo.length; i++) {
            if (newBoxInfo[i].serialNo === item.serialNo) {
              newBoxInfo.splice(i, 1, item);
              break;
            }
          }
        }
      } else {
        newBoxInfo.push(item);
      }
    }
  });
  if (type === "current") {
    dispatch(changeCurrentBoxInfoState(newBoxInfo));
    self.setState({ currentBoxInfo: newBoxInfo });
  } else {
    dispatch(changeReplaceBoxInfoState(newBoxInfo));
    self.setState({ replaceBoxInfo: newBoxInfo });
  }
}

export function updateCurrentBoxInfo(sock, self) {
  return dispatch => {
    self.brokenSocks.push(sock.serialNo);
    let newCurrentBoxInfo = _.clone(self.props.currentBoxInfo);
    _.forEach(newCurrentBoxInfo, function(item, i) {
      if (item.serialNo === sock.serialNo) {
        delete newCurrentBoxInfo[i];
      }
    });
    dispatch(changeCurrentBoxInfoState(newCurrentBoxInfo.filter(d => d)));
  };
}

export function updateBoxInfo(sock, self) {
  return dispatch => {
    self.replaceSocks.push(sock.serialNo);
    let newCurrentBoxInfo = _.clone(self.props.currentBoxInfo);
    let newReplaceBoxInfo = _.clone(self.props.replaceBoxInfo);
    _.forEach(newReplaceBoxInfo, function(item, i) {
      if (item.serialNo === sock.serialNo) {
        delete newReplaceBoxInfo[i];
      }
    });
    newCurrentBoxInfo.push(sock);
    dispatch(changeReplaceBoxInfoState(newReplaceBoxInfo.filter(d => d)));
    dispatch(changeCurrentBoxInfoState(newCurrentBoxInfo.filter(d => d)));
  };
}

export function resetBoxInfo(self) {
  return dispatch => {
    self.brokenBoxId = "";
    self.replaceBoxId = "";
    self.brokenSocks = [];
    self.replaceSocks = [];
    dispatch(changeCurrentBoxInfoState(self.state.currentBoxInfo));
    dispatch(changeReplaceBoxInfoState(self.state.replaceBoxInfo));
  };
}

export function saveBoxInfo(self) {
  return async dispatch => {
    if (_.isEmpty(self.replaceBoxId)) {
      message.info("Please select replace box.");
      return;
    }
    let brokenSocks = { boxId: self.brokenBoxId, socks: self.brokenSocks };
    let replaceSocks = { boxId: self.replaceBoxId, socks: self.replaceSocks };
    try {
      let ret = await replaceBoxSocks({ brokenSocks, replaceSocks });
      if (ret) {
        self.brokenSocks = [];
        self.replaceSocks = [];
      }
    } catch (e) {
      self.brokenSocks = [];
      self.replaceSocks = [];
      console.log("replace socks error: ", e);
    }
  };
}

function changeCurrentBoxInfoState(currentBoxInfo) {
  return {
    type: TYPES.CURRENT_BOX_INFO,
    text: currentBoxInfo
  };
}

function changeReplaceBoxInfoState(replaceBoxInfo) {
  return {
    type: TYPES.REPLACE_BOX_INFO,
    text: replaceBoxInfo
  };
}
