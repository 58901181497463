import { API, graphqlOperation } from "aws-amplify";
import _api from "../graphql/_api";
import _ from "lodash";
import moment from "moment";
import * as TYPES from "../types/types";
import { isToday } from "../constant/TimeConversion";
import zlib from "react-zlib-js";
import Buffer from "react-zlib-js/buffer";
/**
 *  Created by shengli.zhang on 8/20/21 10:04 PM
 */

export function getTagData(self, id, userId, dateRender) {
  return async dispatch => {
    self.openModal();
    let start = dateRender ? dateRender[0] : null;
    let end = dateRender ? dateRender[1] : null;
    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end)
      .add(1, "days")
      .format("YYYY-MM-DD");
    let params = {
      id,
      userId,
      startDate,
      endDate
    };
    let DELAY = moment(endDate) - moment(startDate);
    let reg = new RegExp('"', "g");
    converStepsTag([], dispatch, DELAY, self, startDate);
    converPositionTag([], dispatch, DELAY, self, startDate);
    try {
      const newEvent = await API.graphql(
        graphqlOperation(
          DELAY > self.DELAY
            ? _api.listPatientMin30Tag()
            : _api.listPatientMin1Tag(),
          params
        )
      );
      self.closeModal();
      if (newEvent) {
        dispatch(
          changeMin1ORMin30TagStatus(DELAY > self.DELAY ? "min30" : "min1")
        );
        let deflateDate = null;
        if (DELAY > self.DELAY) {
          deflateDate = newEvent.data.getPatient.min30tag.replace(reg, "");
        } else {
          deflateDate = newEvent.data.getPatient.min1tag.replace(reg, "");
        }
        const inflateData = zlib
          .inflateSync(new Buffer(deflateDate, "base64"))
          .toString();
        let stepsTag = [];
        let positionTag = [];
        _.forEach(inflateData.split("|"), function(item) {
          let keyDataRaw = [
            "usertime",
            "gsensorx",
            "gsensory",
            "gsensorz",
            "steps"
          ];

          let tagData = {};

          item.split(",").forEach((subItem, index) => {
            tagData[keyDataRaw[index]] = subItem;
          });

          let objPositionTag = {};
          let objStepsTag = {};

          Object.assign(objPositionTag, {
            usertime: tagData.usertime,
            gsensorx: tagData.gsensorx,
            gsensory: tagData.gsensory,
            gsensorz: tagData.gsensorz
          });
          positionTag.push(objPositionTag);

          Object.assign(objStepsTag, {
            usertime: tagData.usertime,
            steps: tagData.steps
          });
          stepsTag.push(objStepsTag);
        });
        converStepsTag(stepsTag, dispatch, DELAY, self, startDate);
        converPositionTag(positionTag, dispatch, DELAY, self, startDate);
      }
    } catch (e) {
      self.closeModal();
      console.log("get tag data error: ", e);
    }
  };
}

function changeMin1ORMin30TagStatus(minStatus) {
  return {
    type: TYPES.MIN1_OR_MIN30_TAG,
    text: minStatus
  };
}

//parse steps
function converStepsTag(steps, dispatch, delay, self, startTime) {
  let arrayB = [];
  let DEFATLT_INTEVER = delay > self.DELAY ? 30 * 60 * 1000 : 60 * 1000;
  for (let i = 0; i < steps.length; i++) {
    if (i === 0) {
      if (
        moment(steps[i].usertime.replace("Z", "")) - moment(startTime) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
          steps: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(steps[i].usertime.replace("Z", "")) - moment(startTime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startTime) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            steps: "-"
          };
          arrayB.push(obj);
        }
      }
      arrayB.push(steps[0]);
    } else {
      if (
        moment(steps[i].usertime) - moment(steps[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(steps[i]);
      } else {
        let intever = Math.floor(
          (moment(steps[i].usertime) - moment(steps[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(steps[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            steps: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(steps[i]);
      }
    }
  }
  if (steps.length > 0) {
    if (delay > self.DELAY) {
      dispatch(changeStepsTagStatus(arrayB));
    } else {
      let inteverSub =
        Math.floor(
          (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
            moment(steps[steps.length - 1].usertime.replace("Z", ""))
        ) / DEFATLT_INTEVER;
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(steps[steps.length - 1].usertime.replace("Z", "")) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          steps: "-"
        };
        arrayB.push(obj);
      }
      dispatch(changeStepsTagStatus(arrayB));
    }
  } else {
    let inteverSub =
      Math.floor(
        (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
          moment(startTime + "00:00:00")
      ) / DEFATLT_INTEVER;
    for (let j = 0; j < inteverSub - 1; j++) {
      let obj = {
        usertime: moment(
          moment(startTime + "00:00:00") + DEFATLT_INTEVER * j
        ).format("YYYY-MM-DDTHH:mm:ss"),
        steps: "-"
      };
      arrayB.push(obj);
    }
    dispatch(changeStepsTagStatus(arrayB));
  }
}

function converPositionTag(positionTag, dispatch, delay, self, startTime) {
  let arrayB = [];
  let DEFATLT_INTEVER = delay > self.DELAY ? 30 * 60 * 1000 : 60 * 1000;
  for (let i = 0; i < positionTag.length; i++) {
    if (i === 0) {
      if (
        moment(positionTag[i].usertime.replace("Z", "")) - moment(startTime) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
          gsensorx: "-",
          gsensory: "-",
          gsensorz: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(positionTag[i].usertime.replace("Z", "")) -
            moment(startTime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startTime) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            gsensorx: "-",
            gsensory: "-",
            gsensorz: "-"
          };
          arrayB.push(obj);
        }
      }
      arrayB.push(positionTag[0]);
    } else {
      if (
        moment(positionTag[i].usertime) - moment(positionTag[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(positionTag[i]);
      } else {
        let intever = Math.floor(
          (moment(positionTag[i].usertime) -
            moment(positionTag[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(positionTag[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            gsensorx: "-",
            gsensory: "-",
            gsensorz: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(positionTag[i]);
      }
    }
  }
  if (positionTag.length > 0) {
    if (delay > self.DELAY) {
      dispatch(changePositionTagStatus(arrayB));
    } else {
      let inteverSub =
        Math.floor(
          (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
            moment(
              positionTag[positionTag.length - 1].usertime.replace("Z", "")
            )
        ) / DEFATLT_INTEVER;
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(
              positionTag[positionTag.length - 1].usertime.replace("Z", "")
            ) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          gsensorx: "-",
          gsensory: "-",
          gsensorz: "-"
        };
        arrayB.push(obj);
      }
      dispatch(changePositionTagStatus(arrayB));
    }
  } else {
    let inteverSub =
      Math.floor(
        (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
          moment(startTime + "00:00:00")
      ) / DEFATLT_INTEVER;
    for (let j = 0; j < inteverSub - 1; j++) {
      let obj = {
        usertime: moment(
          moment(startTime + "00:00:00") + DEFATLT_INTEVER * j
        ).format("YYYY-MM-DDTHH:mm:ss"),
        gsensorx: "-",
        gsensory: "-",
        gsensorz: "-"
      };
      arrayB.push(obj);
    }
    dispatch(changePositionTagStatus(arrayB));
  }
}

function changeStepsTagStatus(steps) {
  return {
    type: TYPES.STEPS_TAG,
    text: steps
  };
}

function changePositionTagStatus(position) {
  return {
    type: TYPES.POSITION_TAG,
    text: position
  };
}

export function getTagMinuteWorn(
  id,
  userid,
  startDate,
  endDate,
  self,
  needClear
) {
  return async dispatch => {
    try {
      if (needClear) {
        dispatch(changeTagMinuteWorn([]));
      }
      const newEvent = await API.graphql(
        graphqlOperation(_api.TagMinuteWorn(), {
          id,
          userid,
          startDate,
          endDate
        })
      );
      if (newEvent) {
        let time_worn = _.clone(self.props.tagTimeWorn);
        const history_worn_date = time_worn.map(p => {
          return p.date;
        });
        if (newEvent.data.getPatient.tagTimeWornByDay.length > 0) {
          _.forEach(newEvent.data.getPatient.tagTimeWornByDay, function(item) {
            if (!history_worn_date.includes(item.date)) {
              time_worn.push(item);
            }
          });
        }
        dispatch(changeTagMinuteWorn(_.sortBy(time_worn, ["date"], ["asc"])));
      }
    } catch (e) {
      console.log("getTagMinuteWorn error: ", e);
    }
  };
}

function changeTagMinuteWorn(tagTimeWorn) {
  return {
    type: TYPES.TAG_TIME_WORN,
    text: tagTimeWorn
  };
}

export function getTagWeekWorn(id, userid, startDate, endDate, self) {
  return async dispatch => {
    try {
      dispatch(changeTagWeekWorn({}));
      const newEvent = await API.graphql(
        graphqlOperation(_api.TagWeekWorn(), {
          id,
          userid,
          startDate,
          endDate
        })
      );
      if (newEvent) {
        dispatch(changeTagWeekWorn(newEvent.data.getPatient.tagTimeWornByWeek));
      }
    } catch (e) {
      console.log("getTagWeekWorn error: ", e);
    }
  };
}

export function changeTagWeekWorn(tagTimeWorn) {
  return {
    type: TYPES.TAG_TIME_WORN_BY_WEEK,
    text: tagTimeWorn
  };
}
