import { API, graphqlOperation } from "aws-amplify";
import _api from "../graphql/_api";

/**
 *  Created by shengli.zhang on 12/13/21 1:56 PM
 */

export function updateProgramStatus(UpdateProgramStatusInput) {
  return API.graphql(
    graphqlOperation(_api.updateProgramStatus(), UpdateProgramStatusInput)
  );
}
