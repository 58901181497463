import { Switch, Tabs } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getlistClinicSites, listAdminUsers } from "../action/registerAction";
import "../SupportDashboard/account/NewSite.css";
import "../SupportDashboard/account/SiteList.css";
import {
  changeShowLpnCharts,
  getLpnStaff
} from "../action/lpnManagementAction";
import LpnList from "./LpnList";
import Modal from "react-modal";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import LpnChart from "./LpnChart";
import NeedAssignedPatients from "./NeedAssignedPatients";
import { Auth } from "aws-amplify";
import LpnSiteList from "./LpnSiteList";

const { TabPane } = Tabs;

class LpnManagement extends Component {
  state = {
    modalIsOpen: false,
    activeKey: "1",
    showLpnManagement: false,
    selectSiteIndex: 0,
    userGroups: []
  };

  async componentWillMount() {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    this.setState({
      showLpnManagement:
        groups.includes("admin") || groups.includes("developer"),
      userGroups: groups
    });
  }

  componentDidMount = async () => {
    if (this.props.clinicSites.length === 0) {
      this.props.getlistClinicSites();
    }
    // disaster. edit LPN needs this list
    if (this.props.listAdminUsers.length === 0) {
      this.props.listAdminUsers();
    }
  };

  refreshActiveKey = (activeKey, selectSiteIndex) => {
    this.setState({ activeKey, selectSiteIndex });
    this.props.changeShowLpnCharts(false);
  };

  onSwitchChange = (checked, event) => {
    this.props.changeShowLpnCharts(checked);
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  onTabsChange = activeKey => {
    this.setState({ activeKey });
  };

  render() {
    return (
      <div>
        {this.state.showLpnManagement ? (
          <div className="boxlistcontainer">
            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              style={commonStyles}
              transitionEnterTimeout={10000}
              transitionLeaveTimeout={10000}
            >
              <Progress />
            </Modal>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                className="search_patient_container"
                style={{ width: "80%" }}
              >
                <div
                  className="tab_title_box tab_title report_des_container"
                  style={{ height: 30 }}
                >
                  <p className="tab_title_box">
                    <strong className="tab_title" style={{ paddingLeft: 0 }}>
                      Nurse Management
                    </strong>
                  </p>
                </div>
              </div>
              <div style={{ width: "20%" }}>
                <Switch
                  checkedChildren="Chart"
                  unCheckedChildren="List"
                  checked={this.props.showLpnCharts}
                  onChange={this.onSwitchChange}
                  style={{ float: "right", marginTop: 20 }}
                />
              </div>
            </div>
            {this.props.showLpnCharts ? (
              <LpnChart refreshActiveKey={this.refreshActiveKey} />
            ) : (
              <Tabs
                onChange={this.onTabsChange}
                activeKey={this.state.activeKey}
                style={{ textAlign: "left" }}
              >
                <TabPane tab="Unassigned" key="1">
                  <NeedAssignedPatients userGroups={this.state.userGroups} />
                </TabPane>
                <TabPane tab="Nurses" key="2">
                  <LpnList userGroups={this.state.userGroups} />
                </TabPane>
                <TabPane tab="Sites" key="3">
                  <LpnSiteList
                    onLoading={status => {
                      this.setState({ modalIsOpen: status });
                    }}
                    selectSiteIndex={this.state.selectSiteIndex}
                    userGroups={this.state.userGroups}
                  />
                </TabPane>
                {/*<TabPane tab="Not On Service" key="3">*/}
                {/*  <NotOnServicePatients />*/}
                {/*</TabPane>*/}
              </Tabs>
            )}
          </div>
        ) : (
          <div className="boxlistcontainer" />
        )}
      </div>
    );
  }
}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  showLpnCharts: state.patientsListStore.showLpnCharts
});
const mapDispatchToProp = dispatch => ({
  listAdminUsers: () => dispatch(listAdminUsers()),
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  changeShowLpnCharts: checked => dispatch(changeShowLpnCharts(checked))
});
export default connect(mapStateToProp, mapDispatchToProp)(LpnManagement);
