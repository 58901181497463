import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import AWS from "aws-sdk";
import _api from "../graphql/_api";
import message from "../tools/message-info";
import * as TYPES from "../types/types";
import modalInfo from "../tools/module-info";
/**
 *  Created by shengli.zhang on 3/31/21 3:38 PM
 */

export function getLpnStaff(lpn, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(graphqlOperation(_api.getLpnStaff()));
      if (newEvent) {
        if (lpn) {
          const lpnInfo = _(newEvent.data.getLpnStaff.items).find({ id: lpn });
          self.setState({ lpnInfo });
        }
        const listLpnStaff = newEvent.data.getLpnStaff.items.map(p => {
          const rate = parseFloat(
            ((p.patientCount / p.patientLoad) * 100).toFixed(1)
          );
          return {
            ...p,
            rate
          };
        });
        dispatch(
          changeSirenStaffStatus(_.orderBy(listLpnStaff, ["rate"], ["desc"]))
        );
      }
    } catch (e) {
      console.log("getLpnStaff error: ", e);
    }
  };
}

function changeSirenStaffStatus(sirenStaff) {
  return {
    type: TYPES.LIST_SIREN_STAFF,
    text: sirenStaff
  };
}

export function getLpnPatients(lpn, self, site) {
  return async dispatch => {
    self.setState({ modalIsOpen: true });
    try {
      let combineFilter = Object.assign({
        group: {
          ne: "TRIAL_SILENCE"
        },
        accountStatus: {
          ne: "DELETED"
        },
        or: [
          {
            rpmProgramStatus: {
              eq: "ON_SERVICE"
            }
          },
          {
            rtmProgramStatus: {
              eq: "ON_SERVICE"
            }
          },
          {
            pcmProgramStatus: {
              eq: "ON_SERVICE"
            }
          },
          {
            ccmProgramStatus: {
              eq: "ON_SERVICE"
            }
          }
        ],
        userId: {
          exists: true
        },
        LPN: {
          eq: lpn
        }
      });
      if (!_.isEmpty(site)) {
        Object.assign(combineFilter, { siteId: { eq: site } });
      }
      const sort = { field: "sirenId", direction: "desc" };
      const newEvent = await API.graphql(
        graphqlOperation(_api.getSirenStaffingPatients(), {
          filter: combineFilter,
          sort,
          limit: 500
        })
      );
      self.setState({ modalIsOpen: false });
      if (newEvent) {
        if (_.isEmpty(site)) {
          const listSites = _.uniqBy(
            newEvent.data.lambdaSearchPatients.items,
            "siteId"
          ).map(p => {
            const siteInfo = _(self.props.clinicSites).find({
              siteId: p.siteId
            });
            return {
              siteId: p.siteId,
              siteName: siteInfo ? siteInfo.name : p.siteName
            };
          });
          self.setState({ listSites: listSites.sort(compareSiteIdObj) });
        }
        dispatch(
          changeLpnPatientStatus(newEvent.data.lambdaSearchPatients.items)
        );
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("get lpn patients error: ", e);
    }
  };
}

export function refreshLpnPatientsList(patients) {
  return dispatch => {
    dispatch(changeLpnPatientStatus(patients));
  };
}

function changeLpnPatientStatus(lpnPatients) {
  return {
    type: TYPES.LIST_LPN_PATIENTS,
    text: lpnPatients
  };
}

export function changeShowLpnCharts(checked) {
  return dispatch => {
    dispatch(changeShowLpnChartsState(checked));
  };
}

function changeShowLpnChartsState(checked) {
  return {
    type: TYPES.SHOW_LPN_CHARTS,
    text: checked
  };
}

function compareSiteId(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

function compareSiteIdObj(a, b) {
  if (a.siteId < b.siteId) return -1;
  if (a.siteId > b.siteId) return 1;
  return 0;
}

export function getNeedAssignedPatients(self) {
  return async dispatch => {
    // self.setState({ modalIsOpen: true });
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.getNeedAssignPatients())
      );
      if (newEvent) {
        const siteIds = _.uniqBy(
          _.compact(newEvent.data.listNeedAssignPatients.items),
          "siteId"
        )
          .map(p => {
            return p.siteId;
          })
          .sort(compareSiteId);
        self.setState({
          modalIsOpen: false,
          siteIds
        });
        dispatch(
          changeNeedAssignedCountState(
            newEvent.data.listNeedAssignPatients.items.length
          )
        );
        dispatch(
          changeNeedAssignedPatientState(
            newEvent.data.listNeedAssignPatients.items
          )
        );
      } else {
        self.setState({ modalIsOpen: false });
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("get need assigned patients error: ", e);
    }
  };
}

function changeNeedAssignedPatientState(needAssignedPatients) {
  return {
    type: TYPES.LIST_NEED_ASSIGNED_PATIENTS,
    text: needAssignedPatients
  };
}

function changeNeedAssignedCountState(count) {
  return {
    type: TYPES.NEED_ASSIGNED_COUNT,
    text: count
  };
}

export function getNotOnServicePatients(self) {
  return async dispatch => {
    self.setState({ modalIsOpen: true });
    try {
      let combineFilter = Object.assign({
        group: {
          ne: "TRIAL_SILENCE"
        },
        accountStatus: {
          ne: "DELETED"
        },
        userId: {
          exists: true
        },
        and: [
          {
            rpmProgramStatus: {
              ne: "ON_SERVICE"
            }
          },
          {
            rtmProgramStatus: {
              ne: "ON_SERVICE"
            }
          },
          {
            pcmProgramStatus: {
              ne: "ON_SERVICE"
            }
          },
          {
            ccmProgramStatus: {
              ne: "ON_SERVICE"
            }
          }
        ]
      });
      const newEvent = await API.graphql(
        graphqlOperation(_api.getSirenStaffingPatients(), {
          filter: combineFilter,
          limit: 500
        })
      );
      self.setState({ modalIsOpen: false });
      if (newEvent) {
        dispatch(
          changeNotOnServicePatientState(
            newEvent.data.lambdaSearchPatients.items
          )
        );
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("get need assigned patients error: ", e);
    }
  };
}

function changeNotOnServicePatientState(notOnServicePatients) {
  return {
    type: TYPES.LIST_NOT_ON_SERVICE_PATIENTS,
    text: notOnServicePatients
  };
}

export function updateLpnForPatients(input, self) {
  return async dispatch => {
    try {
      self.setState({ disableSubmit: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateLpnForPatients(), { input })
      );
    } catch (e) {
      self.setState({ disableSubmit: false });
      console.log("updateLpnForPatients error: ", e);
    }
  };
}

export function updateLpnPatientLoad(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAdminUser(), { input })
      );
      if (newEvent) {
        if (newEvent.data.updateAdminStaff.status) {
          let userList = _.clone(self.props.listSirenStaff);
          let userInfo = _(userList).find({
            id: input.id
          });
          userInfo.patientLoad = input.patientLoad;
          userInfo.rate = parseFloat(
            (userInfo.patientCount / input.patientLoad) * 100
          ).toFixed(1);
          self.setState({ lpnPatientLoad: "" });
          dispatch(changeSirenStaffStatus(userList));
        } else {
          modalInfo.error(
            "Update patient load for lpn failed",
            newEvent.data.updateAdminStaff.message
          );
        }
      }
    } catch (e) {
      modalInfo.error("Update patient load for lpn error ", JSON.stringify(e));
      console.log("update patient load for lpn error:", e);
    }
  };
}

export function removeLpnForPatient(patients, self) {
  return async dispatch => {
    try {
      self.setState({ modalIsOpen: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.removeLpnForPatients(), { input: { patients } })
      );
      if (newEvent) {
        self.setState({ modalIsOpen: false });
        if (self.refresh) {
          setTimeout(function() {
            self.refresh(patients);
          }, 500);
        }
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log(`remove lpn for patient error: `, e);
    }
  };
}

export function getSiteLpnPatients(self, siteId) {
  return async dispatch => {
    try {
      let combineFilter = Object.assign({
        group: {
          ne: "TRIAL_SILENCE"
        },
        accountStatus: {
          ne: "DELETED"
        },
        or: [
          {
            rpmProgramStatus: {
              eq: "ON_SERVICE"
            }
          },
          {
            rtmProgramStatus: {
              eq: "ON_SERVICE"
            }
          },
          {
            pcmProgramStatus: {
              eq: "ON_SERVICE"
            }
          },
          {
            ccmProgramStatus: {
              eq: "ON_SERVICE"
            }
          }
        ],
        userId: {
          exists: true
        },
        LPN: {
          exists: true
        },
        siteId: {
          eq: siteId
        }
      });
      const sort = { field: "sirenId", direction: "desc" };
      const newEvent = await API.graphql(
        graphqlOperation(_api.getSirenStaffingPatients(), {
          filter: combineFilter,
          sort,
          limit: 1000
        })
      );
      if (newEvent) {
        const removePatients = newEvent.data.lambdaSearchPatients.items.map(
          p => {
            return { id: p.id, LPN: p.LPN };
          }
        );
        self.setState({
          sitePatients: newEvent.data.lambdaSearchPatients.items,
          removePatients,
          disableSubmit: removePatients.length === 0
        });
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("get lpn patients error: ", e);
    }
  };
}

export function assignLpnForPatient(patients, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.assignLpnForPatients(), { input: { patients } })
      );
      if (newEvent) {
        const listSirenStaff = self.state.lpnList.map(p => {
          return {
            ...p,
            patients: []
          };
        });

        dispatch(changeSirenStaffStatus(listSirenStaff));
      }
    } catch (e) {
      console.log(`assign lpn for patient error: `, e);
    }
  };
}

export function assignAnyLpnForPatients(patients) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.assignLpnForPatients(), { input: { patients } })
      );
    } catch (e) {
      console.log(`assign any lpn for patient error: `, e);
    }
  };
}
