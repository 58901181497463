import _ from "lodash";
import echarts from "echarts";

/**
 *  Created by shengli.zhang on 4/2/21 9:43 AM
 */

export function getSiteInfoOption(siteList, lpnList) {
  const onStaffing = _.filter(siteList, { onStaffing: "Yes" });
  const notOnStaffing = _.filter(siteList, { onStaffing: "No" });
  const allPrimarySites = _.filter(lpnList, function(t) {
    return !_.isEmpty(t.primarySites);
  });
  let allAssignedSites = [];
  for (const lpn of allPrimarySites) {
    const site = JSON.parse(lpn.primarySites);
    allAssignedSites = [...allAssignedSites, ...site];
  }
  const assignedSite = _.filter(onStaffing, function(t) {
    return allAssignedSites.includes(t.siteId);
  });
  const notAssignedSite = _.filter(onStaffing, function(t) {
    return !allAssignedSites.includes(t.siteId);
  });
  const assignedSiteInNotOnStaffing = _.filter(notOnStaffing, function(t) {
    return allAssignedSites.includes(t.siteId);
  });
  const notAssignedSiteInNotOnStaffing = _.filter(notOnStaffing, function(t) {
    return !allAssignedSites.includes(t.siteId);
  });
  const option = {
    title: {
      text: "Site Assignment Information",
      left: 5,
      top: 5
    },
    tooltip: {
      trigger: "item",
      textStyle: {
        align: "left"
      },
      formatter: function(params) {
        return `${params.seriesName}<br/>${params.marker}${params.name}: ${params.value} (${params.percent}%)`;
      }
    },
    legend: {
      orient: "vertical",
      left: "right",
      top: "top",
      bottom: 20
    },
    series: [
      {
        name: "Site Staffing Information",
        type: "pie",
        radius: [0, "50%"],
        label: {
          normal: {
            position: "inner"
          }
        },
        data: [
          { value: onStaffing.length, name: "Staffing sites" },
          { value: notOnStaffing.length, name: "Non-staffing sites" }
        ],
        color: ["#10EFE0", "#FF8F03"]
      },
      {
        name: "Site Assignment Information",
        type: "pie",
        radius: ["50%", "80%"],
        labelLine: {
          normal: {
            show: true
          }
        },
        data: [
          { value: assignedSite.length, name: "Assigned staffing sites" },
          { value: notAssignedSite.length, name: "Unassigned staffing sites" },
          {
            value: notAssignedSiteInNotOnStaffing.length,
            name: "Unassigned non-staffing sites"
          },
          {
            value: assignedSiteInNotOnStaffing.length,
            name: "Assigned non-staffing sites"
          }
        ],
        color: ["#51CEC6", "#6AE2D9", "#FFB703", "#FFCD50"]
      }
    ]
  };

  return option;
}
