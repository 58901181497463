import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "semantic-ui-react";
import "./PatientInfo.css";
import "./SirenNotes.css";
class UserStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ""
    };
  }
  async componentDidMount() {
    this.setState({ url: window.location.href });
  }
  render() {
    return (
      <div
        className={
          this.state.url.includes("BrokenMedicalDetails") ? "info" : "sym_box"
        }
      >
        <div className="title">PATIENT SYMPTOMS</div>
        <div className="patientContent">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: 130, textAlign: "center" }}>
                  DATE
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center", width: 150 }}>
                  SOURCE
                </Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>
                  SYMPTOMS
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.symtomDatas &&
                this.props.symtomDatas.footSymptoms &&
                this.props.symtomDatas.footSymptoms.map((sym, i) => {
                  return (
                    <Table.Row key={"sym-list" + i}>
                      <Table.Cell>{sym.reportTime}</Table.Cell>
                      <Table.Cell style={{ textAlign: "Center" }}>
                        {sym.source}
                      </Table.Cell>
                      <Table.Cell>{sym.symptomType}</Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  symtomDatas: state.patientsListStore.symtomDatas
});

const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(UserStatus);
