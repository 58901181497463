import { Checkbox, Select } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  generate99457BillingReport,
  searchBilling99457Patients
} from "../action/bliMonthyReportAction";
import { getlistClinicSites, getlistStaff } from "../action/registerAction";
import ReportHeader99457_8 from "./ReportHeader99457_8";
import ReportReviewedBase from "./ReportReviewedBase";
import _ from "lodash";
import { updateReportReviewedTableFilter } from "../reducers/tableFilterReducer";

const { Option } = Select;

class ReportReviewed99457_8 extends ReportReviewedBase {
  cptCode = "99457-8";

  headerClass = ReportHeader99457_8;

  renderTableProps() {
    const data =
      this.state.tableData &&
      this.state.tableData.map(p => {
        const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
        const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
        const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
        const initializedDate = p.initializedDate
          ? p.initializedDate.split("T")[0]
          : "";
        const appInitialized = p.appInitialized ? p.appInitialized : "No";
        const spentTime = p.totalMinutesSpent ? p.totalMinutesSpent : 0;
        const billedAt = p.billedAt
          ? moment.tz(p.billedAt, p.userTimezone).format("YYYY-MM-DD")
          : "N/A";
        let billedBy = p.billedBy ? p.billedBy : "N/A";
        let staffInfo = _.filter(this.props.listStaff, {
          email: p.billedBy
        });
        if (staffInfo.length > 0) {
          billedBy = staffInfo[0].firstName + " " + staffInfo[0].lastName;
        }
        const diagnosisCodes = p.diagnosisCodes
          ? p.diagnosisCodes.replace(/_/g, ",")
          : "";
        const billingMonth = p.billingDate
          ? moment(p.billingDate).format("MM/YYYY")
          : "";
        return {
          ...p,
          createdAt,
          sirenId,
          initializedDate,
          dispensedDay,
          spentTime,
          billedAt,
          billedBy,
          diagnosisCodes,
          billingMonth,
          appInitialized
        };
      });

    const columns = [
      {
        Header: props => {
          return (
            <Checkbox
              onChange={this.handleCheckAll}
              indeterminate={
                this.state.selectedPatients.length > 0 &&
                this.state.selectedPatients.length < this.state.tableData.length
              }
              checked={
                this.state.selectedPatients.length > 0 &&
                this.state.selectedPatients.length ===
                  this.state.tableData.length
              }
            />
          );
        },
        accessor: "",
        maxWidth: 60,
        sortable: false,
        filterable: false,
        Cell: props => {
          return (
            <div onClick={e => e.stopPropagation()}>
              <Checkbox
                onChange={(e, data) =>
                  this.handleCheck(e.target.checked, props.index)
                }
                checked={this.state.selectedPatients.includes(props.index)}
                name="selectSite"
              />
            </div>
          );
        }
      },
      {
        Header: "SiteID",
        accessor: "siteId",
        maxWidth: 60
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        minWidth: 100
      },
      {
        Header: "Doctor",
        accessor: "doctorName",
        minWidth: 100
      },
      {
        Header: "SirenID",
        accessor: "sirenId",
        maxWidth: 60
      },
      {
        Header: "PatientID",
        accessor: "patientId"
      },
      {
        Header: "First Name",
        accessor: "firstName"
      },
      {
        Header: "Last Name",
        accessor: "lastName"
      },
      {
        Header: "Date of Birth",
        accessor: "birthday"
      },
      {
        Header: "ICD-10 Codes",
        accessor: "diagnosisCodes"
      },
      {
        Header: "Account Created",
        accessor: "createdAt"
      },
      // {
      //   Header: "App Activated",
      //   accessor: "appInitialized",
      //   maxWidth: 72,
      //   filterMethod: (filter, row) => {
      //     if (filter.value === "all") {
      //       return true;
      //     }
      //     if (filter.value === "Yes") {
      //       return row[filter.id] === "Yes";
      //     }
      //     return row[filter.id] === "No";
      //   },
      //   Filter: ({ filter, onChange }) => (
      //     <Select
      //       className="select_common_style"
      //       onChange={onChange}
      //       style={{ width: "100%" }}
      //       value={filter ? filter.value : "all"}
      //       filterOption={(input, option) =>
      //         option.props.children
      //           .toLowerCase()
      //           .indexOf(input.toLowerCase()) >= 0
      //       }
      //       showSearch
      //       optionFilterProp="children"
      //     >
      //       <Option value="all">All</Option>
      //       <Option value="Yes">Yes</Option>
      //       <Option value="No">No</Option>
      //     </Select>
      //   )
      // },
      {
        Header: "Socks Dispensed Date",
        accessor: "dispensedDay",
        minWidth: 120
      },
      {
        Header: "Monitoring Month",
        accessor: "billingMonth"
      },
      {
        Header: "Total Time (mins)",
        accessor: "spentTime"
      },
      {
        Header: "Reviewed By",
        accessor: "billedBy"
      },
      {
        Header: "Reviewed Date",
        accessor: "billedAt"
      }
    ];

    return {
      data,
      columns
    };
  }
}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  listStaff: state.patientsListStore.listStaff,
  tableDefaultState: state.tableFilter.report99457_8Reviewed,
  showGenerateDelay: state.monthlyReportStore.showGenerate99457Delay,
  generateDelay: state.monthlyReportStore.generate99457Delay,
  cpt: "99457-8"
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  searchBillingPatients: input => dispatch(searchBilling99457Patients(input)),
  getlistStaff: () => dispatch(getlistStaff()),
  updateTableDefaultState: (cptCode, state) =>
    dispatch(updateReportReviewedTableFilter(cptCode, state)),
  generateBillingReport: input => dispatch(generate99457BillingReport(input))
});

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(ReportReviewed99457_8);
