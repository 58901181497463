import { message, Select, Row, Col } from "antd";
import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { Button, Form, Icon, Modal, TextArea } from "semantic-ui-react";
import { changeSpecialCaseStatus } from "../action/addAction";
import "../share/button.css";
import "./AccountStatus.css";
import ButtonSuccess from "./buttonSuccess";

const SPECIAL_CASE_YES = "Yes";
const SPECIAL_CASE_NO = "No";

export default class ChangeSpecialCase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      note: "",
      specialCaseStatus: SPECIAL_CASE_YES,
      specialCaseReason: []
    };
  }
  show = () => {
    if (this.props.specialCaseStatus === SPECIAL_CASE_NO) {
      this.setState({
        specialCaseStatus: SPECIAL_CASE_YES,
        specialCaseReason: []
      });
    } else {
      this.setState({
        specialCaseStatus: SPECIAL_CASE_NO,
        specialCaseReason: "Not a special case"
      });
    }
    this.setState({
      open: true
    });
  };
  handleConfirm = async () => {
    if (this.state.specialCaseStatus === this.props.specialCaseStatus) {
      message.info("you didn't modify status");
      return;
    }
    if (this.state.specialCaseStatus === "Yes") {
      let checkFailed = false;
      if (Array.isArray(this.state.specialCaseReason)) {
        if (this.state.specialCaseReason.length === 0) {
          checkFailed = true;
        }
      } else if (!this.state.specialCaseReason) {
        checkFailed = true;
      }
      if (checkFailed) {
        message.info("Please select special case reason.");
        return;
      }
    }
    let user = await Auth.currentAuthenticatedUser();
    this.setState({ confirmSuccess: true });

    const newStatus = {
      id: this.props.id,
      userId: this.props.userId,
      specialCase: this.state.specialCaseStatus,
      specialCaseReason: Array.isArray(this.state.specialCaseReason) ? this.state.specialCaseReason.join(',') : this.state.specialCaseReason,
      note: this.state.note,
      author: `${user.attributes.given_name} ${user.attributes.family_name}`
    };
    if (this.state.specialCaseStatus === SPECIAL_CASE_YES) {
      newStatus.activeSpecialCaseBy = user.username;
      newStatus.title = "Special Case: Yes";
    } else {
      newStatus.pausedSpecialCaseBy = user.username;
      newStatus.title = "Special Case: No";
    }
    changeSpecialCaseStatus(newStatus).then(data => {
      this.setState({
        open: false,
        confirmSuccess: false
      });
      if (typeof this.props.onChange === "function") {
        this.props.onChange(
          this.state.specialCaseStatus,
          this.state.specialCaseStatus === SPECIAL_CASE_YES ? 1 : -1
        );
      }
    });
  };
  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  render() {
    const { Option } = Select;
    const { open } = this.state;
    return (
      <Modal
        onOpen={this.show}
        trigger={
          <div>
            {this.props.specialCaseStatus}
            <Icon as="i" name="edit" className="edit" />
          </div>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>Modify Special Case Status</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Row gutter={[16, 24]}>
                <Col span={6}>
                  <Form.Field>
                    <label>Special Case</label>
                    <Select
                      value={this.state.specialCaseStatus}
                      onChange={value => {
                        this.setState({
                          specialCaseStatus: value,
                          specialCaseReason: value === "Yes" ? [] : "Not a special case"
                        });
                      }}
                      style={{ width: "100%" }}
                      placeholder="Please Select"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      optionFilterProp="children"
                    >
                      <Option value={SPECIAL_CASE_YES}>Yes</Option>
                      <Option value={SPECIAL_CASE_NO}>No</Option>
                    </Select>
                  </Form.Field>
                </Col>
                <Col span={18}>
                  <Form.Field>
                    <label>Reason</label>
                    <Select
                      value={this.state.specialCaseReason}
                      onChange={value => this.setState({ specialCaseReason: value })}
                      style={{ width: "100%" }}
                      mode="multiple"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      optionFilterProp="children"
                      disabled={this.state.specialCaseStatus === SPECIAL_CASE_NO}
                    >
                      {this.state.specialCaseStatus === SPECIAL_CASE_NO && (
                        <Option value="Not a special case">
                          Not a special case
                        </Option>
                      )}
                      <Option value="Patient medical history indicates amputation">
                        Patient medical history indicates amputation
                      </Option>
                      <Option value="Patient has a current medical condition">
                        Patient has a current medical condition
                      </Option>
                      <Option value="Patient is undergoing treatment">
                        Patient is undergoing treatment
                      </Option>
                    </Select>
                  </Form.Field>
                </Col>
              </Row>
              <Form.Field>
                <label>Notes</label>
                <TextArea
                  className="note_comments"
                  onChange={(e, data) => {
                    this.setState({ note: e.target.value })
                  }}
                />
              </Form.Field>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
