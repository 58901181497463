import { Icon, Button, Tooltip } from "antd";
import React, { Component } from "react";

const WatchState = {
  START: "start",
  PAUSE: "pause",
  STOP: "stop"
};

export default class StopWatch extends Component {
  constructor(props) {
    super(props);
    if (props.case === WatchState.START) {
      this.timer = setInterval(() => {
        this.setState(state => {
          return {
            seconds: state.seconds + 1
          };
        });
      }, 1000);
    }
  }

  state = {
    seconds: 0,
    case: this.props.case || WatchState.STOP
  };

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  toggle = () => {
    if (this.state.case === WatchState.START) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.setState({
        case: WatchState.PAUSE
      });
    } else {
      this.timer = setInterval(() => {
        this.setState(state => {
          return {
            seconds: state.seconds + 1
          };
        });
      }, 1000);
      this.setState({
        case: WatchState.START
      });
    }
  };

  reset = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.setState({
      seconds: 0,
      case: WatchState.STOP
    });
  };

  formatTime() {
    const min = Math.floor(this.state.seconds / 60);
    const sec = this.state.seconds % 60;
    return `${min < 10 ? "0" + min : min}:${sec < 10 ? "0" + sec : sec}`;
  }

  render() {
    const { style } = this.props;
    return (
      <div
        style={Object.assign(
          {
            display: "inline-block",
            fontSize: 16,
            minWidth: 90,
            padding: 8,
            textAlign: "center",
            position: "relative",
          },
          style
        )}
      >
        <Icon type="clock-circle" style={{ paddingRight: 4 }} />
        <span>{this.formatTime()}</span>
        <div>
          <Tooltip
            placement="bottom"
            title={this.state.case === WatchState.START ? "Pause" : "Start"}
          >
            <Button
              onClick={this.toggle}
              type="link"
              icon={
                this.state.case === WatchState.START
                  ? "pause-circle"
                  : "play-circle"
              }
              ghost
            ></Button>
          </Tooltip>
          <Tooltip placement="bottom" title="Reset">
            <Button
              onClick={this.reset}
              type="link"
              icon="rollback"
              ghost
            ></Button>
          </Tooltip>
        </div>
      </div>
    );
  }
}
