import { Select } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import PatientMedicalHistory from "../../report/patientMedicalHistory";
import "./editpatient.css";

const { Option } = Select;

class PatientIntakeDetails extends Component {

  state = {
    open: false
  }

  handleOpen = () => {
    if (_.isEmpty(this.props.userInfo)) {
      return;
    }
    this.setState({
      open: true
    });
  };

  close = () => {
    this.setState({
      open: false,
    })
  };

  showTitle() {
    // if there is a title, show 'title'. if no title, show 'note'
    let text = this.props.title;
    if (!text) {
      text = this.props.note;
    }
    if (!text) {
      text = "";
    }

    return text;
  }

  parseData(patientInfo) {
    if (!patientInfo) {
      return {}
    }
    let diagnosisCodes = []
    if (patientInfo.diagnosisCodes) {
      diagnosisCodes = patientInfo.diagnosisCodes.split('_');
    }
    let medicalCondition = []
    if (patientInfo.medicalCondition) {
      medicalCondition = patientInfo.medicalCondition.split(',')
    }
    let footSpecific = []
    if (patientInfo.footSpecific) {
      footSpecific = patientInfo.footSpecific.split(',')
    }
    let footAmputation = []
    if (patientInfo.footAmputation) {
      footAmputation = patientInfo.footAmputation.split(',')
    }
    return {
      diagnosisCodes,
      medicalCondition,
      footSpecific,
      footAmputation
    }
  }

  render() {
    if (!this.props.userInfo) {
      return (
        <div className="note_title_table" onClick={this.handleOpen}>
          {this.showTitle()}
        </div>
      )
    }
    const { diagnosisCodes, medicalCondition, footSpecific, footAmputation } = this.parseData(this.props.userInfo);
    return (
      <Modal
        trigger={
          <div className="note_title_table" onClick={this.handleOpen}>
            {this.showTitle()}
          </div>
        }
        open={this.state.open}
      >
        <Modal.Header>Patient Intake Details</Modal.Header>
        <Modal.Content>
          <div style={{ marginLeft: 40, marginRight: 40 }}>
            <PatientMedicalHistory
              readOnly
              medicalCondition={medicalCondition}
              footSpecific={footSpecific}
              footAmputation={footAmputation}
            />
            <div className="patient_note_label" style={{ marginTop: 20 }}>
              ICD-10 Codes
            </div>
            <div style={{ marginTop: 10 }}>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                optionLabelProp="value"
                value={diagnosisCodes}
                disabled
              >
                {this.props.ICD10Codes.map((si) => (
                  <Option value={si} key={si}>
                    {si}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={this.close}
          >
            <Icon name="checkmark" />
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  ICD10Codes: state.app.ICD10Codes.map(item => item.code),
});
export default connect(mapStateToProp, null)(PatientIntakeDetails);
