import React from "react";
import "./MedicalDetailsHome.css";
import "./status.css";
import leftFoot from "../img/Left@3x.png";
import rightFoot from "../img/Right@3x.png";
const warning_red_big = require("../img/warn_red_big@3x.png");
const green_big = require("../img/green_big.png");
const warning_yellow_big = require("../img/warn_yellow_big@3x.png");
const purple_big = require("../img/purple_big@3x.png");

const SPOT_LOCATION = {
  rarch: {
    x: "18%",
    y: "42%",
    width: "14%",
    height: "14%"
  },
  rheel: {
    x: "19%",
    y: "70%",
    width: "14%",
    height: "14%"
  },
  rmts1: {
    x: "30%",
    y: "22%",
    width: "8%",
    height: "8%"
  },
  rmts3: {
    x: "20%",
    y: "21%",
    width: "8%",
    height: "8%"
  },
  rmts5: {
    x: "11%",
    y: "29%",
    width: "8%",
    height: "8%"
  },
  rtoe: {
    x: "31%",
    y: "9%",
    width: "8%",
    height: "8%"
  },
  larch: {
    x: "68%",
    y: "42%",
    width: "14%",
    height: "14%"
  },
  lheel: {
    x: "67%",
    y: "70%",
    width: "14%",
    height: "14%"
  },
  lmts1: {
    x: "62%",
    y: "22%",
    width: "8%",
    height: "8%"
  },
  lmts3: {
    x: "72%",
    y: "21%",
    width: "8%",
    height: "8%"
  },
  lmts5: {
    x: "80%",
    y: "29%",
    width: "8%",
    height: "8%"
  },
  ltoe: {
    x: "61%",
    y: "9%",
    width: "8%",
    height: "8%"
  }
};

const SPOT_IMG = {
  NORMAL: green_big,
  RED: warning_red_big,
  YELLOW: warning_yellow_big,
  DEFAULT: purple_big
};

function renderSpot(status = "DEFAULT", spot) {
  return (
    <image
      xlinkHref={SPOT_IMG[status === null ? "DEFAULT" : status]}
      {...SPOT_LOCATION[spot]}
    />
  );
}

export default function footSvg(props) {
  const footStatus = props.footStatus || {};

  return (
    <svg
      crossOrigin="anonymous"
      width="100%"
      height="80%"
      id="svg_style"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 380 360"
    >
      <g className="right_style">
        <image xlinkHref={rightFoot} x="0" width="50%" height="90%" />
        {["rarch", "rheel", "rmts1", "rmts3", "rmts5", "rtoe"].map(v => (
          <g key={v} className={v}>
            {renderSpot(footStatus[v], v)}
          </g>
        ))}
      </g>
      <g className="left_style">
        <image
          xlinkHref={leftFoot}
          className="footImg"
          x="50%"
          width="50%"
          height="90%"
        />
        {["larch", "lheel", "lmts1", "lmts3", "lmts5", "ltoe"].map(v => (
          <g key={v} className={v}>
            {renderSpot(footStatus[v], v)}
          </g>
        ))}
      </g>
    </svg>
  );
}
