import { Button as AntdButton, Modal as AntdModal, message, Affix } from "antd";
import { Storage } from "aws-amplify";
import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { deleteDocument } from "../action/carePlanAction";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `pdf.worker.min.js`;

const { confirm } = AntdModal;
const scales = [0.2, 0.5, 1, 1.37, 2, 2.5, 3];
class DocumentPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      numPages: null,
      pdf: "",
      loadings: [],
      carePlanInfo: {},
      loadingProgress: 0,
      index: 3
    };
  }

  handleOpen = () => {
    const carePlanInfo = this.props.carePlanInfo;
    this.props.reviewDocument(true);
    this.setState({
      open: true,
      carePlanInfo
    });
    Storage.get(carePlanInfo.location, {
      identityId: this.props.doctorDetails.userId,
      level: "private"
    })
      .then(uri => {
        this.setState({ pdf: uri });
      })
      .catch(err => {
        console.log(`get s3 data error: ${JSON.stringify(err)}`);
      });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages, loadingProgress: 0 });
  };

  handleClose = () => {
    this.props.reviewDocument(false);
    this.setState({
      open: false,
      numPages: null,
      pdf: "",
      loadings: [],
      carePlanInfo: {},
      loadingProgress: 0
    });
  };

  handleDownload = () => {
    Storage.get(this.state.carePlanInfo.location, {
      identityId: this.props.doctorDetails.userId,
      level: "private"
    })
      .then(data => {
        window.open(data);
      })
      .catch(err => {
        console.log("download pdf error: ", err);
      });
  };

  deleteDocumentConfirm = () => {
    if (this.props.loginId === this.props.carePlanInfo.authorId) {
      confirm({
        title: "Delete PDF File",
        content: `Are you sure you want to permanently delete this document?`,
        cancelText: "No",
        okText: "Yes",
        onCancel: () => {},
        onOk: () => {
          this.handleDeleteDocument();
        }
      });
    } else {
      message.error("Only author can delete it.");
    }
  };

  handleDeleteDocument = () => {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[0] = true;
      return {
        loadings: newLoadings
      };
    });
    const input = {
      userId: this.state.carePlanInfo.userId,
      createdAt: this.state.carePlanInfo.createdAt
    };
    this.props.deleteDocument(input, this);
  };

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Button
            size="mini"
            onClick={this.handleOpen}
            style={{ display: "none" }}
          >
            Preview
          </Button>
        }
        open={open}
      >
        <Modal.Header>
          <div>Preview</div>
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{ padding: "1.5rem 3rem", backgroundColor: "gray" }}
        >
          <Affix style={{ position: "absolute", top: 20, right: 20 }}>
            <AntdButton
              size={"large"}
              shape="circle"
              type="link"
              onClick={() => {
                let index = this.state.index - 1;
                this.setState({ index });
              }}
              disabled={this.state.index === 0}
            >
              <ZoomOutOutlined />
            </AntdButton>
            <AntdButton
              size={"large"}
              shape="circle"
              type="link"
              onClick={() => {
                let index = this.state.index + 1;
                this.setState({ index });
              }}
              style={{ marginLeft: 20 }}
              disabled={this.state.index === 6}
            >
              <ZoomInOutlined />
            </AntdButton>
          </Affix>
          <Document
            file={this.state.pdf}
            onLoadSuccess={this.onDocumentLoadSuccess}
            style={{ alignSelf: "center" }}
            onLoadProgress={({ loaded, total }) => {
              this.setState({ loadingProgress: (loaded / total) * 100 });
            }}
            renderMode="svg"
            loading={`Loading PDF...${parseInt(this.state.loadingProgress)}%`}
          >
            {Array.from(new Array(this.state.numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={scales[this.state.index]}
              />
            ))}
          </Document>
        </Modal.Content>
        <Modal.Actions style={{ display: "flex" }}>
          {this.props.loginId === this.props.carePlanInfo.authorId && (
            <AntdButton
              type="link"
              style={{
                color: "red",
                float: "left",
                position: "absolute"
              }}
              loading={this.state.loadings[0]}
              onClick={this.deleteDocumentConfirm}
            >
              Delete
            </AntdButton>
          )}
          <div style={{ textAlign: "center", flex: 1 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                basic
                color="red"
                onClick={e => this.handleClose()}
                style={{ marginLeft: 20 }}
              >
                <Icon name="remove" />
                {"Close"}
              </Button>
              <Button
                basic
                color="green"
                onClick={e => this.handleDownload()}
                style={{ marginLeft: 20 }}
              >
                <Icon name="download" />
                {"Download"}
              </Button>
            </div>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  loginId: state.authGroupStore.profile.loginId,
  doctorDetails: state.patientsListStore.doctorDetails,
  listCarePlans: state.patientsListStore.listCarePlan
});

const mapDispatchToProp = dispatch => ({
  deleteDocument: (input, self) => dispatch(deleteDocument(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(DocumentPreview);
