import { API, graphqlOperation } from "aws-amplify";
import { clone, forEach } from "lodash";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
import { changelistStaff } from "./registerAction";

export function changeReportList(self, siteId) {
  return dispatch => {
    let docReportList = clone(self.props.reportList);
    if (docReportList.hasOwnProperty(siteId)) {
      delete docReportList[siteId];
    } else {
      let doctorList = [];
      forEach(self.props.listStaff, function(item) {
        if (item.siteId.includes(siteId)) {
          doctorList.push(item.email);
        }
      });
      Object.assign(docReportList, { [siteId]: doctorList });
    }
    dispatch(changeReportListStatus(docReportList));
  };
}

export function changeDoctorSelect(self, siteId, doctorId) {
  return dispatch => {
    let docReportList = clone(self.props.reportList);
    if (docReportList.hasOwnProperty(siteId)) {
      if (docReportList[siteId].includes(doctorId)) {
        let index = docReportList[siteId].indexOf(doctorId);
        docReportList[siteId].splice(index, 1);
        if (docReportList[siteId].length === 0) {
          delete docReportList[siteId];
        }
      } else {
        docReportList[siteId].push(doctorId);
      }
    } else {
      let doctorList = [];
      doctorList.push(doctorId);
      Object.assign(docReportList, { [siteId]: doctorList });
    }
    dispatch(changeReportListStatus(docReportList));
  };
}

export function saveReport(reportList, self) {
  return async dispatch => {
    let keys = Object.keys(reportList);
    let autoDoctorReportInfo = [];
    let saveObj = {};
    if (keys.length > 0) {
      forEach(keys, function(key) {
        let keyMatch = reportList[key];
        forEach(keyMatch, function(item) {
          if (!saveObj.hasOwnProperty(item)) {
            Object.assign(saveObj, {
              [item]: [key]
            });
          } else {
            saveObj[item].push(key);
          }
        });
      });
    }
    let keysDoctor = Object.keys(saveObj);
    if (keysDoctor.length > 0) {
      forEach(keysDoctor, function(item) {
        autoDoctorReportInfo.push({ email: item, siteId: saveObj[item] });
      });
    }
    try {
      self.setState({ modalIsOpen: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAutoDoctorReport(), {
          input: { autoDoctorReportInfo }
        })
      );
      if (newEvent) {
        self.setState({ modalIsOpen: false });
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("save auto doctor report error: ", e);
    }
  };
}

export function getReportStaffList() {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(graphqlOperation(_api.listStaff()));
      if (newEvent) {
        checkAutoReportList(
          dispatch,
          newEvent.data.listStaff.items.sort(compareSiteId)
        );
        dispatch(
          changelistStaff(newEvent.data.listStaff.items.sort(compareSiteId))
        );
      }
    } catch (e) {
      console.log("getlistStaff error: ", e);
    }
  };
}

function checkAutoReportList(dispatch, staffList) {
  let reportList = {};
  forEach(staffList, function(item) {
    let siteIdList = JSON.parse(item.autoReportSites);
    if (siteIdList) {
      forEach(siteIdList, function(siteId) {
        if (!reportList.hasOwnProperty(siteId)) {
          Object.assign(reportList, { [siteId]: [item.email] });
        } else {
          reportList[siteId].push(item.email);
        }
      });
      dispatch(changeReportListStatus(reportList));
    }
  });
}

function compareSiteId(a, b) {
  if (a.siteId < b.siteId) return -1;
  if (a.siteId > b.siteId) return 1;
  return 0;
}

function changeReportListStatus(reportList) {
  return {
    type: TYPES.DOCTOR_REPORT_LIST,
    text: reportList
  };
}

export function resetAllReport() {
  return dispatch => {
    dispatch(resetAllReportState());
  };
}
export function resetAllReportState() {
  return {
    type: TYPES.RESET_ALL_REPORT
  };
}

export function changeManualReportList(self, siteId) {
  return dispatch => {
    let docReportList = clone(self.props.reportManualList);
    if (docReportList.hasOwnProperty(siteId)) {
      delete docReportList[siteId];
    } else {
      let doctorList = [];
      forEach(self.props.listStaff, function(item) {
        if (item.siteId.includes(siteId)) {
          doctorList.push(item.email);
        }
      });
      Object.assign(docReportList, { [siteId]: doctorList });
    }
    dispatch(changeManualReportStatus(docReportList));
  };
}

export function changeManualDoctorSelect(self, siteId, doctorId) {
  return dispatch => {
    let docReportList = clone(self.props.reportManualList);
    if (docReportList.hasOwnProperty(siteId)) {
      if (docReportList[siteId].includes(doctorId)) {
        let index = docReportList[siteId].indexOf(doctorId);
        docReportList[siteId].splice(index, 1);
        if (docReportList[siteId].length === 0) {
          delete docReportList[siteId];
        }
      } else {
        docReportList[siteId].push(doctorId);
      }
    } else {
      let doctorList = [];
      doctorList.push(doctorId);
      Object.assign(docReportList, { [siteId]: doctorList });
    }
    dispatch(changeManualReportStatus(docReportList));
  };
}
function changeManualReportStatus(reportManualList) {
  return {
    type: TYPES.REPORT_MANUAL_LIST,
    text: reportManualList
  };
}

export function updateAutoDoctorReport(action, siteId, doctorId, self) {
  return async dispatch => {
    try {
      self.setState({ modalIsOpen: true });
      let UpdateAutoDoctorReportItemInput = {
        input: {
          action,
          siteId,
          doctorId
        }
      };
      await API.graphql(
        graphqlOperation(
          _api.updateAutoDoctorReportItem(),
          UpdateAutoDoctorReportItemInput
        )
      );
      self.setState({ modalIsOpen: false });
      let docReportList = clone(self.props.reportList);
      if (docReportList.hasOwnProperty(siteId)) {
        if (docReportList[siteId].includes(doctorId)) {
          let index = docReportList[siteId].indexOf(doctorId);
          docReportList[siteId].splice(index, 1);
          if (docReportList[siteId].length === 0) {
            delete docReportList[siteId];
          }
        } else {
          docReportList[siteId].push(doctorId);
        }
      } else {
        let doctorList = [];
        doctorList.push(doctorId);
        Object.assign(docReportList, { [siteId]: doctorList });
      }
      dispatch(changeReportListStatus(docReportList));
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("updateAutoDoctorReport error: ", e);
    }
  };
}
