import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { getAppLink } from "../action/addAction";
import ButtonSuccess from "./buttonSuccess";
import "./SendMess.css";
import message from "../tools/message-info";
class appLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      checkboxGroup: {
        msgValue: true,
        emailValue: false
      },
      showmsgValue: "",
      showemailValue: "",
      msgshow: false,
      emailshow: true
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.handleShowSns = this.handleShowSns.bind(this);
    this.handleShowEmail = this.handleShowEmail.bind(this);
  }
  handleShowSns(event) {
    this.setState({ showmsgValue: event.target.value });
  }
  handleShowEmail(event) {
    this.setState({ showemailValue: event.target.value });
  }
  clear = () =>
    this.setState({
      showemailValue: "",
      msgshow: false,
      emailshow: true,
      checkboxGroup: {
        msgValue: true,
        emailValue: false
      }
    });
  handleOpen = () =>
    this.setState({
      open: true,
      confirmSuccess: false,
      showmsgValue: this.props.phone,
      showemailValue: this.props.email
    });
  close = () => this.setState({ open: false, confirmSuccess: false });
  handleCheck(event) {
    let obj = Object.assign(this.state.checkboxGroup);
    obj[event.target.value] = event.target.checked;
    this.setState({ checkboxGroup: obj });
  }
  sendMsg() {
    this.setState({ msgshow: !this.state.msgshow });
  }
  sendEmail() {
    this.setState({ emailshow: !this.state.emailshow });
  }
  submit = () => {
    if (
      this.state.checkboxGroup.emailValue === false &&
      this.state.checkboxGroup.msgValue === false
    ) {
      message.info("You have to choose a radio button");
    } else {
      this.setState({ confirmSuccess: true });
      const { getAppLink } = this.props;
      const information = {
        type: "APP"
      };
      if (
        this.state.checkboxGroup.emailValue &&
        this.state.showemailValue &&
        this.state.showemailValue !== ""
      ) {
        Object.assign(information, { emailList: this.state.showemailValue });
      }
      if (
        this.state.checkboxGroup.msgValue &&
        this.state.showmsgValue &&
        this.state.showmsgValue !== ""
      ) {
        Object.assign(information, { phoneList: this.state.showmsgValue });
      }
      getAppLink(information, this);
      this.clear();
      this.setState({ modalIsOpen: true });
    }
  };
  render() {
    return (
      <Modal
        className="modal_box"
        trigger={
          <Button size="mini" onClick={this.handleOpen}>
            Send App Link
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header className="edit-title">Send App Link</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div className="mess_box">
              <div className="type_box">
                <span className="type_label">Channel</span>
                <label className="checkboxlabel">
                  <input
                    type="checkbox"
                    name="checkboxGroup"
                    value="msgValue"
                    checked={this.state.checkboxGroup["msgValue"]}
                    onChange={this.handleCheck}
                    onClick={e => this.sendMsg()}
                  />
                  <span className="kongge" />
                  SMS
                </label>
                <label className="checkboxlabel">
                  <input
                    type="checkbox"
                    name="checkboxGroup"
                    value="emailValue"
                    checked={this.state.checkboxGroup["emailValue"]}
                    onChange={this.handleCheck}
                    onClick={e => this.sendEmail()}
                  />
                  <span className="kongge" />
                  Email
                </label>
              </div>
              <div className="type_box">
                <div
                  className="show_box showSns_box"
                  style={{ display: this.state.msgshow ? "none" : "block" }}
                >
                  <Input
                    value={this.state.showmsgValue}
                    placeholder="SMS"
                    type="text"
                    className="show_input"
                    onChange={this.handleShowSns}
                  />
                </div>
                <div
                  className="show_box"
                  style={{ display: this.state.emailshow ? "none" : "block" }}
                >
                  <Input
                    value={this.state.showemailValue}
                    placeholder="Email"
                    type="text"
                    className="show_input"
                    onChange={this.handleShowEmail}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.submit()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Send
          </Button>
          <Button
            basic
            onClick={e => this.clear()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="removeButton"
          >
            <Icon name="redo" />
            Reset
          </Button>
          <Button
            color="green"
            onClick={e => this.close()}
            style={{
              display: this.state.confirmSuccess ? "inline-block" : "none"
            }}
            className="removeButton"
          >
            <Icon name="checkmark" />
            close
          </Button>
          <button onClick={e => this.close()} className="closebutton">
            <Icon name="remove" className="closeicon" />
          </button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({
  getAppLink: (information, self) => dispatch(getAppLink(information, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(appLink);
