import _ from "lodash";
import echarts from "echarts";

/**
 *  Created by shengli.zhang on 4/2/21 9:43 AM
 */

export function getLpnInfoOption(lpnList) {
  if (lpnList.length === 0) {
    return {};
  }

  const allLpn = lpnList.map(p => {
    return `${p.firstName.substring(0, 20)} ${p.lastName.substring(0, 20)}`;
  });

  const patientLoad = lpnList.map(p => {
    return parseInt(p.patientLoad);
  });

  const patientCount = lpnList.map(p => {
    return p.patientCount;
  });

  const rate = lpnList.map(p => {
    return (p.patientCount / p.patientLoad).toFixed(3);
  });

  let lpnColor = ["#00FF7F", "#FFA500", "#FF0000"];

  const option = {
    title: {
      text: "Staffing Assignment Information",
      left: "20",
      top: 20
    },
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      axisPointer: {
        type: "line" //'line' | 'shadow'
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (600 - boxHeight) / 2;

        return [x, y];
      },
      formatter: function(params) {
        return `${
          allLpn[params[0].dataIndex]
        }<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#00FF7F;"></span> patientCount: ${
          patientCount[params[0].dataIndex]
        }<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#FFA500;"></span> patientLoad: ${
          patientLoad[params[0].dataIndex]
        }<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#FF0000;"></span> rate: ${
          rate[params[0].dataIndex]
        }`;
      }
    },
    color: [...lpnColor],
    legend: [
      {
        // orient: "vertical",
        data: ["Good", "Warn", "Bad"],
        top: 20,
        x: "left",
        y: "center",
        itemGap: 20,
        left:"80%"
      }
    ],
    grid: {
      left: "3%",
      right: "4%",
      bottom: "2%",
      top: "20%",
      containLabel: true
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -10,
        show: true,
        realtime: true,
        left: "25%",
        right: "25%",
        top: 20,
        start: 0,
        end:
          patientLoad.length > 10
            ? parseInt((10 / patientLoad.length) * 100)
            : 100
      }
    ],
    xAxis: [
      {
        type: "category",
        data: allLpn,
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          inside: false,
          interval: 0,
          rotate: -40
        }
      }
    ],
    yAxis: [
      {
        name: "LPN",
        type: "value",
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 10, 5, 0]
        },
        min: 0,
        max: _.max(patientCount)
      }
    ],
    series: [
      {
        name: "Good",
        type: "bar"
      },
      {
        name: "Warn",
        type: "bar"
      },
      {
        name: "Bad",
        type: "bar"
      },
      {
        name: "Patient Count",
        type: "bar",
        barWidth: "30%",
        data: patientCount,
        itemStyle: {
          normal: {
            color: function(params) {
              const rateIndex = rate[params.dataIndex];
              if (rateIndex < 0.6) {
                return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "#00FF7F" }
                ]);
              } else if (rateIndex < 0.8) {
                return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "#00FF7F" },
                  { offset: 0.4, color: "#FFA500" }
                ]);
              }
              return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 1, color: "#00FF7F" },
                { offset: 0.4, color: "#FFA500" },
                { offset: 0.2, color: "#FF0000" }
              ]);
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                fontSize: 16,
                color: "#6A00FF"
              },
              formatter: function(params) {
                return `${(rate[params.dataIndex] * 100).toFixed(1)}%`;
              }
            }
          }
        }
      }
    ]
  };

  return option;
}
