import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import _ from "lodash";
import { Modal as AntdModal } from "antd";
import { assignLpn } from "./assign-lpn-script";
import { formatNumber } from "../tools/phoneFormat";

/**
 *  Created by shengli.zhang on 4/2/21 4:35 PM
 */
class NotAssignPreview extends Component {
  render() {
    return (
      <div>
        <ReactTable
          data={
            this.props.notAssignInfo &&
            this.props.notAssignInfo.map(p => {
              const patientInfo = p;
              const givenName = patientInfo.firstName
                ? `${patientInfo.firstName} ${patientInfo.lastName}`
                : "";
              const id = !_.isEmpty(p.id) ? formatNumber(p.id).trim() : "";
              return {
                ...p,
                id,
                givenName
              };
            })
          }
          columns={[
            {
              Header: "ID",
              accessor: "id",
              maxWidth: 150,
              filterMethod: (filter, row) => {
                return String(row[filter.id])
                  .toLowerCase()
                  .includes(String(filter.value).toLowerCase());
              }
            },
            {
              Header: "SirenId",
              accessor: "sirenId",
              maxWidth: 80,
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "SiteId",
              accessor: "siteId",
              maxWidth: 100
            },
            {
              Header: "Full Name",
              accessor: "givenName",
              maxWidth: 150,
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.givenName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Reason",
              accessor: "reason",
              style: {
                padding: 10,
                border: 0,
                whiteSpace: "break-spaces",
                overflow: "hidden",
                wordbreak: "normal"
              },
              filterMethod: (filter, row) => {
                let rowReason = row._original.reason
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterReason = filter.value.toLowerCase();
                return rowReason.includes(filterReason);
              }
            }
          ]}
          filterable
          defaultSorted={[{ id: "siteId", desc: false }]}
          defaultPageSize={10}
          showPageSizeOptions={false}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  listNeedAssignedPatients: state.patientsListStore.listNeedAssignedPatients,
  listSirenStaff: state.patientsListStore.listSirenStaff
});
const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(NotAssignPreview);
