import { InfoCircleFilled } from "@ant-design/icons";
import { Checkbox, Divider, Select, Input, Tooltip } from "antd";
import React from 'react';
import { connect } from "react-redux";
import { Popup } from "semantic-ui-react";
import { FOOT_AMPUTATION, FOOT_SPECIFIC, MEDICAL_GENERAL } from "../../../tools/patient-medical";
import NewAccountStep from "./NewAccountStep";

const AMPUTATION_ON_FOOT = require("../../../img/Amputation_on_Foot.png");

const { Option } = Select
const { TextArea } = Input

class NewAccountStepMedical extends NewAccountStep {

	static Name = 'Medical History'

	state = {
		medicalConditions: this.props.medicalConditions || [],
		footSpecific: this.props.footSpecific || [],
    footAmputation: this.props.footAmputation || [],
		diagnosisCodes: this.props.diagnosisCodes || [],
		otherDisease: this.props.otherDisease || ''
	}

	validateForm() {
		let pass = true
		if (this.state.medicalConditions.includes('Other') && this.state.otherDisease.length === 0) {
			this.setState({
				otherStatus: 'error'
			})
			pass = false
		}
		return pass
	}

	onChangeFootSpecific = (checked, footSpecificInfo, side) => {
    let footSpecific = [...this.state.footSpecific];
		const value = side === 'left' ? footSpecificInfo.value_L : footSpecificInfo.value_R
    if (checked) {
      footSpecific.push(value);
    } else {
			footSpecific = footSpecific.filter(p => p !== value);
    }
    this.setState({ footSpecific });
  }

  onChangeFootAmputation = (checked, footAmputationInfo, side) => {
    let footAmputation = [...this.state.footAmputation];
		const value = side === 'left' ? footAmputationInfo.value_L : footAmputationInfo.value_R
    if (checked) {
      footAmputation.push(value);
    } else {
			footAmputation = footAmputation.filter(p => p !== value);
    }
    this.setState({ footAmputation });
  }

	onChangeMedicalGeneral = (checked, option) => {
    let medicalConditions = [...this.state.medicalConditions]
    if (checked) {
      medicalConditions.push(option)
    } else {
			medicalConditions = medicalConditions.filter(p => p !== option)
    }
    this.setState({ medicalConditions })
  }

	render() {
		return (
			<div style={{width: 800, margin: 'auto', marginTop: 40}}>
				<div className='form-section'>
					<Divider orientation="left">Patient Medical History</Divider>
					<div style={{display: 'flex'}}>
						<div style={{flex: 1, marginRight: 16}}>
							<div style={{ fontWeight: "bold", color: "black", marginBottom: 20 }}>
								General
							</div>
							{
								MEDICAL_GENERAL.map((p, index) => (
									<div style={{marginBottom: 10}} key={index}>
										<div style={{display: 'inline-block', maxWidth: '80%'}}>{p.name}</div>
										<Checkbox
											checked={this.state.medicalConditions.includes(p.value)}
											onChange={e => this.onChangeMedicalGeneral(e.target.checked, p.value)}
											style={{ float: 'right' }}
										/>
									</div>
								))
							}
							<div style={{marginBottom: 10}}>
								<div style={{display: 'inline-block', maxWidth: '80%'}}>Other (optional)</div>
								<Checkbox
									checked={this.state.medicalConditions.includes('Other')}
									onChange={e => this.onChangeMedicalGeneral(e.target.checked, 'Other')}
									style={{ float: 'right' }}
								/>
								<div style={{marginTop: 8, display: this.state.medicalConditions.includes('Other') ? 'block' : 'none'}}>
									<TextArea
										style={this.state.otherStatus === 'error' ? {borderColor: 'red'} : {}}
										placeholder='Click to add medical condition'
										autoSize={{minRows: 2, maxRows: 4}}
										value={this.state.otherDisease}
										onChange={e => {
											if (e.target.value.length <= 50) {
												this.setState({
													otherDisease: e.target.value.replaceAll(',', ';'),
													otherStatus: 'success'
												})
											}
										}}
									/>
									<div style={{float: 'left'}}>50 characters limit</div>
									<div style={{float: 'right', color: this.state.otherDisease.length === 50 ? 'red' : '#00000073'}}>{this.state.otherDisease.length} / 50</div>
									<div style={{clear: 'both', color: 'red', display: this.state.otherStatus === 'error' ? 'block' : 'none'}}>Other condition cannot be empty</div>
								</div>
							</div>
						</div>
						<Divider type="vertical" style={{height: 340}}></Divider>
						<div style={{flex: 1, marginRight: 16, marginLeft: 16}}>
							<div style={{ position: 'relative', marginBottom: 20 }}>
								<span style={{fontWeight: "bold", color: "black"}}>Foot Specific</span>
								<span style={{position: 'absolute', fontSize: 12, right: 32, bottom: -10}}>Left</span>
								<span style={{position: 'absolute', fontSize: 12, right: -6, bottom: -10}}>Right</span>
							</div>
							{FOOT_SPECIFIC.map((p, index) => (
								<div style={{marginBottom: 10}} key={index}>
									<div style={{display: 'inline-block', width: "70%" }}>{p.name}</div>
									<Checkbox
										style={{ float: 'right', marginLeft: 20 }}
										checked={this.state.footSpecific.includes(p.value_R)}
										onChange={e => this.onChangeFootSpecific(e.target.checked, p, 'right')}
									/>
									<Checkbox
										style={{ float: 'right' }}
										checked={this.state.footSpecific.includes(p.value_L)}
										onChange={e => this.onChangeFootSpecific(e.target.checked, p, 'left')}
									/>
								</div>
							))}
						</div>
						<Divider type="vertical" style={{height: 340}}></Divider>
						<div style={{flex: 1, marginLeft: 16}}>
							<div style={{ position: 'relative', marginBottom: 20 }}>
								<span style={{fontWeight: "bold", color: "black"}}>Amputation</span>
								<Popup
									trigger={
										<InfoCircleFilled
											style={{
												color: "#D3D6E1",
												marginLeft: 5
											}}
										/>
									}
									position="bottom center"
									hoverable
									flowing
									style={{ backgroundColor: "#F1F1F1" }}
								>
									<Popup.Header
										style={{
											marginLeft: 10,
											marginTop: 5,
											fontSize: 14
										}}
									>
										Amputation on Foot
									</Popup.Header>
									<Popup.Content>
										<img
											src={AMPUTATION_ON_FOOT}
											style={{ width: 300, height: (300 * 205) / 284 }}
										/>
									</Popup.Content>
								</Popup>
								<span style={{position: 'absolute', fontSize: 12, right: 32, bottom: -10}}>Left</span>
								<span style={{position: 'absolute', fontSize: 12, right: -6, bottom: -10}}>Right</span>
							</div>
							{FOOT_AMPUTATION.map((p, index) => (
								<div style={{marginBottom: 10}} key={index}>
									<div style={{display: 'inline-block', width: "70%" }}>{p.name}</div>
									<Checkbox
										style={{float: 'right', marginLeft: 20}}
										checked={this.state.footAmputation.includes(p.value_R)}
										onChange={e => this.onChangeFootAmputation(e.target.checked, p, 'right')}
									/>
									<Checkbox
										style={{float: 'right'}}
										checked={this.state.footAmputation.includes(p.value_L)}
										onChange={e => this.onChangeFootAmputation(e.target.checked, p, 'left')}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className='form-section'>
					<Divider orientation="left">
						ICD-10 Codes
						<Tooltip title="To add a new code to the list, press enter after typing the code into the search bar.">
							<InfoCircleFilled
								style={{
									color: "#D3D6E1",
									marginLeft: 5
								}}
							/>
						</Tooltip>
					</Divider>
					<Select
						size='large'
						mode="tags"
						style={{ width: "100%" }}
						placeholder="Click to add ICD-10 codes"
						tokenSeparators={['_', ',']}
						onChange={value => {
							const filterValue = value.map(v => v.trim()).filter(v => v.length)
							this.setState({ diagnosisCodes: filterValue })
						}}
						value={this.state.diagnosisCodes}
					>
						{this.props.ICD10Codes.map((v) => (
							<Option value={v} key={v}>
								{v}
							</Option>
						))}
					</Select>
				</div>
			</div>
		)
	}

}

const mapStateToProp = state => ({
  ICD10Codes: state.app.ICD10Codes.map(item => item.code),
	medicalConditionList: state.app.medicalConditions.map(item => ({name: item.value, value: item.condition}))
})

export default connect(mapStateToProp)(NewAccountStepMedical)
