import { Select } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  curMonthPatient99453
} from "../action/bliMonthyReportAction";
import { getlistClinicSites } from "../action/registerAction";
import { updateReportCurrentMonthTableFilter } from "../reducers/tableFilterReducer";
import ReportCurrentMonthBase from "./ReportCurrentMonthBase";
import ReportHeader99453_4 from "./ReportHeader99453_4";

const { Option } = Select;

class ReportCurrentMonth99453_4 extends ReportCurrentMonthBase {

  cptCode = "99453-4"

  headerClass = ReportHeader99453_4

  renderTableProps() {
    const data = this.state.tableData.map(p => {
      const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
      const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
      const dispensedDay = p.dispensedDay
        ? p.dispensedDay
        : moment(p.createdAt).format("YYYY-MM-DD");
      const Eligible = p.estimated99454DaysWorn
        ? p.estimated99454DaysWorn >= 16
          ? "Yes"
          : "No"
        : "No";
      const estimated99454DaysWorn = p.estimated99454DaysWorn;
      const estimated99454StartDay = p.estimated99454StartDay;
      const dateStart = moment(estimated99454StartDay);
      const dateEnd = moment(moment().format("YYYY-MM-DD"));
      const difValue = dateEnd.diff(dateStart, "days") + 1;
      const daysRemaining = 30 - difValue < 0 ? 0 : 30 - difValue;
      const runRate = Math.round(
        (estimated99454DaysWorn / difValue) * 30
      );
      let doctor = "";
      if (p.rpmInfo && p.rpmInfo.length > 0) {
        doctor = p.rpmInfo[0].doctorName;
      }
      const initializedDate = p.initializedDate
        ? p.initializedDate.split("T")[0]
        : "";
      const appInitialized = p.appInitialized ? p.appInitialized : "No";
      const diagnosisCodes = p.diagnosisCodes
        ? p.diagnosisCodes.replace(/_/g, ",")
        : "";
      return {
        ...p,
        sirenId,
        createdAt,
        doctor,
        dispensedDay,
        Eligible,
        initializedDate,
        daysRemaining,
        estimated99454StartDay,
        runRate,
        diagnosisCodes,
        estimated99454DaysWorn,
        appInitialized
      };
    })

    const columns = [
      {
        Header: "SiteID",
        accessor: "siteId",
        maxWidth: 60,
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        minWidth: 100,
      },
      {
        Header: "Doctor",
        accessor: "doctorName",
        minWidth: 100,
      },
      {
        Header: "SirenID",
        accessor: "sirenId",
        maxWidth: 60,
      },
      {
        Header: "PatientID",
        accessor: "patientId",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Date of Birth",
        accessor: "birthday",
      },
      {
        Header: "ICD-10 Codes",
        accessor: "diagnosisCodes",
      },
      {
        Header: "Account Created",
        accessor: "createdAt",
      },
      // {
      //   Header: "App Activated",
      //   accessor: "appInitialized",
      //   maxWidth: 72,
      //   filterMethod: (filter, row) => {
      //     if (filter.value === "all") {
      //       return true;
      //     }
      //     if (filter.value === "Yes") {
      //       return row[filter.id] === "Yes";
      //     }
      //     return row[filter.id] === "No";
      //   },
      //   Filter: ({ filter, onChange }) => (
      //     <Select
      //       className="select_common_style"
      //       onChange={onChange}
      //       style={{ width: "100%" }}
      //       value={filter ? filter.value : "all"}
      //       filterOption={(input, option) =>
      //         option.props.children
      //           .toLowerCase()
      //           .indexOf(input.toLowerCase()) >= 0
      //       }
      //       showSearch
      //       optionFilterProp="children"
      //     >
      //       <Option value="all">All</Option>
      //       <Option value="Yes">Yes</Option>
      //       <Option value="No">No</Option>
      //     </Select>
      //   )
      // },
      {
        Header: "Socks Dispensed Date",
        accessor: "dispensedDay",
        minWidth: 120,
        Cell: props => {
          return <div>{props.original.dispensedDay}</div>;
        },
      },
      {
        Header: "Estimated Start",
        accessor: "estimated99454StartDay",
      },
      {
        Header: "Days Recorded",
        accessor: "estimated99454DaysWorn",
      },
      {
        Header: "Days Remaining",
        accessor: "daysRemaining",
      },
      {
        Header: "Run Rate",
        accessor: "runRate",
      }
    ]

    return { data, columns }
  }

}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  tableDefaultState: state.tableFilter.report99453_4CurrentMonth,
});

const mapDispatchToProp = dispatch => ({
  searchCurrentMonthPatients: (billingInputValue) =>
    dispatch(curMonthPatient99453(billingInputValue)),
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  updateTableDefaultState: (cptCode, state) => dispatch(updateReportCurrentMonthTableFilter(cptCode, state))
});

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(ReportCurrentMonth99453_4);
