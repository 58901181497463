import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../BoxManagement/BoxList.css";
import { createEMR, deleteEMR, getEMRList } from "../action/emrAction";
import moment from "moment";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import Modal from "react-modal";
import { Button } from "semantic-ui-react";
import { Modal as AntdModal, Input } from "antd";
import _ from "lodash";
import { removeAllSpace } from "../tools/stringProcess";
import { getlistClinicSites } from "../action/registerAction";
const { confirm } = AntdModal;
class EMRList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      addEMRName: ""
    };
  }
  componentDidMount() {
    if (this.props.EMRSystemList.length === 0) {
      this.setState({ modalIsOpen: true });
      this.refreshEMRList();
    }
    if (this.props.clinicSites.length === 0) {
      this.props.getlistClinicSites();
    }
  }

  handleDeleteEMR = name => {
    const sites = _.filter(this.props.clinicSites, { EMRSystem: name });
    const siteIds = sites
      .map(p => {
        return p.siteId;
      })
      .join(",");
    if (sites.length > 0) {
      AntdModal.warning({
        title: "Delete EMR Failed",
        content: (
          <div>
            EMR <strong>{name}</strong> has assigned to{" "}
            {sites.length > 1 ? "sites" : "site"} <strong>{siteIds}</strong>,
            please unbind them first.
          </div>
        ),
        centered: true
      });
      return;
    }
    confirm({
      title: "Confirming",
      content: (
        <div>
          Are you sure you want to delete{" "}
          <strong style={{ fontWeight: "bold" }}>{name}</strong>?
        </div>
      ),
      cancelText: "No",
      onCancel: () => {},
      okText: "Yes",
      onOk: () => {
        this.deleteEMRConfirm(name);
      },
      centered: true
    });
  };

  onAddEMRChanged = e => {
    this.setState({ addEMRName: e.target.value });
  };

  handleAddEMR = () => {
    confirm({
      title: "Add EMR",
      content: (
        <div>
          Please enter the EMR name you want to add:
          <div style={{ width: "90%" }}>
            <Input allowClear onChange={this.onAddEMRChanged} />
          </div>
        </div>
      ),
      cancelText: "Cancel",
      onCancel: () => {},
      okText: "OK",
      onOk: () => {
        this.createEMR();
      },
      centered: true
    });
  };

  createEMR = () => {
    if (_.isEmpty(removeAllSpace(this.state.addEMRName))) {
      AntdModal.warning({
        title: "Add Failed",
        content: "Please input EMR you want to add.",
        centered: true
      });
      return;
    }
    const EMRInfo = _(this.props.EMRSystemList).find({
      name: this.state.addEMRName
    });
    if (EMRInfo) {
      AntdModal.warning({
        title: "Add Failed",
        content: `${this.state.addEMRName} has exist.`,
        centered: true
      });
      return;
    }
    const input = { name: this.state.addEMRName };
    this.setState({ modalIsOpen: true });
    this.props.createEMR(input, this).finally(() => {
      this.refreshEMRList();
      this.setState({ addEMRName: "" });
    });
  };

  deleteEMRConfirm = name => {
    const input = { name };
    this.setState({ modalIsOpen: true });
    this.props.deleteEMR(input, this).finally(() => {
      this.refreshEMRList();
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  refreshEMRList = () => {
    this.props.getEMRList().finally(() => {
      this.setState({ modalIsOpen: false });
    });
  };

  render() {
    return (
      <div className="boxlistcontainer">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="add_import_box" style={{ textAlign: "left" }}>
          <Button size="small" onClick={this.handleAddEMR}>
            New EMR
          </Button>
        </div>
        <ReactTable
          data={
            this.props.EMRSystemList &&
            this.props.EMRSystemList.map(p => {
              const createdAt = moment(p.createdAt).format(
                "MM/DD/YYYY HH:mm:ss"
              );
              const updatedAt = moment(p.updatedAt).format(
                "MM/DD/YYYY HH:mm:ss"
              );
              return {
                ...p,
                createdAt,
                updatedAt
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          }
          columns={[
            {
              columns: [
                {
                  Header: "EMR System",
                  accessor: "name"
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Created At",
                  accessor: "createdAt"
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Updated At",
                  accessor: "updatedAt"
                }
              ]
            },
            {
              columns: [
                {
                  Header: "Actions",
                  accessor: "",
                  minWidth: 110,
                  sortable: false,
                  filterable: false,
                  Cell: props => {
                    return (
                      <div>
                        <Button
                          size="mini"
                          className="deleteButton"
                          onClick={() => {
                            this.handleDeleteEMR(props.original.name);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    );
                  }
                }
              ]
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "name",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  EMRSystemList: state.patientsListStore.EMRSystemList,
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
  getEMRList: () => dispatch(getEMRList()),
  createEMR: (input, self) => dispatch(createEMR(input, self)),
  deleteEMR: (input, self) => dispatch(deleteEMR(input, self)),
  getlistClinicSites: () => dispatch(getlistClinicSites())
});
export default connect(mapStateToProp, mapDispatchToProp)(EMRList);
