import React, { Component } from "react";
import { connect } from "react-redux";
import "./biMonthly.css";
import { Control } from "react-keeper";
import "./report.css";
class ReportMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ""
    };
  }

  async componentDidMount() {
    window.location.href.includes("ReportSetting")
      ? this.setState({ url: "ReportSetting" })
      : window.location.href.includes("ReportHistory")
      ? this.setState({ url: "ReportHistory" })
      : this.setState({ url: "CustomReport" });
  }

  render() {
    return (
      <div className="tab_container">
        <span
          className={this.state.url === "CustomReport" ? "tab_nav-active" : "tab_nav"}
          onClick={() => Control.go("/CustomReport")}
        >
          Custom Report
        </span>
        <span
          className={
            this.state.url === "ReportHistory" ? "tab_nav-active" : "tab_nav"
          }
          onClick={() => Control.go("/ReportHistory")}
        >
          History
        </span>
        <span
          className={
            this.state.url === "ReportSetting" ? "tab_nav-active" : "tab_nav"
          }
          onClick={() => Control.go("/ReportSetting")}
        >
          Settings
        </span>
      </div>
    );
  }
}

const mapStateToProp = state => ({ });
const mapDispatchToProp = dispatch => ({ });
export default connect(mapStateToProp, mapDispatchToProp)(ReportMenu);
