import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { registeredShoeTag } from "../action/boxAction";
import { searchPatient } from "../action/SimulateAction";
import "../BoxManagement/registerbox.css";
import { createNote } from "../action/DoctorNoteAction";
import { Auth } from "aws-amplify";

class RegisterShoeTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      regiSuccess: false,
      searchSirenId: "",
      loading: false,
      patient: []
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  close = () => this.setState({ open: false });
  searchPatient = () => {
    const { searchPatient } = this.props;
    if (!isEmpty(this.state.searchSirenId)) {
      searchPatient(this, this.state.searchSirenId);
    }
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      const { searchPatient } = this.props;
      if (!isEmpty(this.state.searchSirenId)) {
        searchPatient(this, this.state.searchSirenId);
      }
    }
  };

  handleOK = () => {};

  submit = async value => {
    const user = await Auth.currentAuthenticatedUser();
    const creds = {
      userId: value.userId,
      tagId: this.props.item.tagId,
      authcode: this.props.item.authCode
    };
    this.setState({
      loading: true
    });
    console.log(creds);
    registeredShoeTag(creds)
      .then(res => {
        console.log("res: ", res);
        if (res.status) {
          this.setState({
            loading: false,
            regiSuccess: true
          });
          const input = {
            id: value.id,
            author: `${user.username}`,
            authorId: `${user.username}`,
            title: isEmpty(value.rtmDeviceStatus)
              ? "New Device Registered: Siren Move, Device Status = Paused"
              : "Replacement Device Registered: Siren Move",
            note: `Registered Siren Move ${this.props.item.tagId}`
          };
          this.props.createNote(input);
          setTimeout(() => {
            this.props.onChange(creds);
            this.setState({
              open: false
            });
          }, 500);
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(err => {
        console.log("err: ", err);
        this.setState({
          loading: false
        });
      });
  };

  searchSirenIdChanged = event => {
    this.setState({ searchSirenId: event.target.value });
  };

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Button onClick={this.handleOpen} size="mini">
            Register
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>
          {this.state.regiSuccess ? "Register tag success" : `Tag Registration`}
        </Modal.Header>
        <Modal.Content>
          {this.state.regiSuccess ? (
            <div className="regiSuccess">
              <Icon name="check circle" id="regiSuccessIcon" />
              <p className="regiSuccessTitle">Awesome!</p>
              <p className="regiSuccesstext">
                Tag has been successfully registered.
              </p>
            </div>
          ) : (
            <div>
              <div className="search_patient_container">
                <span className="search_label search_email_label">
                  SirenId:
                </span>
                <Input
                  placeholder="Search"
                  type="text"
                  onChange={this.searchSirenIdChanged.bind(this)}
                  onBlur={event => this.searchPatient(event)}
                  value={this.state.searchSirenId || ""}
                  onKeyPress={this.handleKeyPress}
                />
                <Icon
                  name="search"
                  size="small"
                  className="search_icon"
                  onClick={this.searchPatient}
                />
              </div>
              <ReactTable
                data={this.state.patient.map(patient => {
                  const fullName = patient.firstName + " " + patient.lastName;
                  return {
                    ...patient,
                    fullName
                  };
                })}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    Header: "SirenID",
                    accessor: "sirenId",
                    filterMethod: (filter, row) => {
                      return String(row[filter.id]).includes(filter.value);
                    }
                  },
                  {
                    Header: "Full Name",
                    accessor: "fullName",
                    filterMethod: (filter, row) => {
                      let rowfilterfullname = row._original.fullName
                        ? row[filter.id].toLowerCase()
                        : "";
                      let filterfullname = filter.value.toLowerCase();
                      return rowfilterfullname.includes(filterfullname);
                    }
                  },
                  {
                    Header: "Actions",
                    sortable: false,
                    filterable: false,
                    Cell: props => {
                      return (
                        <div>
                          <Button
                            loading={this.state.loading}
                            color="green"
                            onClick={e => this.submit(props.original)}
                          >
                            <Icon name="checkmark" />
                            Register Tag
                          </Button>
                        </div>
                      );
                    }
                  }
                ]}
                defaultSorted={[{ id: "sirenId", desc: false }]}
                defaultPageSize={10}
                className="-striped -highlight"
                minRows={0}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.regiSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProp = dispatch => ({
  searchPatient: (self, sirenId) => dispatch(searchPatient(self, sirenId)),
  createNote: input => dispatch(createNote(input))
});
export default connect(null, mapDispatchToProp)(RegisterShoeTag);
