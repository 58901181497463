import {
  Button, Checkbox, Empty, Form, Input, message, Modal, Radio, Select
} from 'antd';
import _ from 'lodash';
import { PageSizes, PDFDocument, setFontAndSize, StandardFonts } from 'pdf-lib';
import PDFObject from 'pdfobject';
import React, { Component } from "react";
import SignaturePad from 'signature_pad';

const TextArea = Input.TextArea;

export default class SignDemo extends Component {

  state = {
    signature: null
  }

  onLoadFile = async (e) => {
    this.pdfFile = e.target.files[0];

    const tmpBuffer = await this.pdfFile.arrayBuffer();

    const b64PDF = 'data:application/pdf;base64,' + Buffer.from(tmpBuffer).toString('base64');

    PDFObject.embed(b64PDF, this.pdfContainer)
  }

  debouncePreviewPDF = _.debounce(async (param) => {
    console.log('update pdf preview', param)

    const fontSize = 14, fieldHeight = 18, defaultDA = setFontAndSize(StandardFonts.Courier, 14).toString()
    const pdfDoc = await PDFDocument.load(await this.pdfFile.arrayBuffer())

    const page = pdfDoc.addPage(PageSizes.A4)
    const pageHeight = page.getHeight()

    const form = pdfDoc.getForm()


    page.drawText('Checkbox:', { x: 50, y: pageHeight - 60, size: fontSize })

    page.drawText('c1', { x: 200, y: pageHeight - 60, size: fontSize })
    page.drawText('c2', { x: 300, y: pageHeight - 60, size: fontSize })
    page.drawText('c3', { x: 400, y: pageHeight - 60, size: fontSize })

    const exiaField = form.createCheckBox('c1')
    const kyriosField = form.createCheckBox('c2')
    const virtueField = form.createCheckBox('c3')

    exiaField.addToPage(page, { x: 150, y: pageHeight - 60, width: fieldHeight, height: fieldHeight })
    kyriosField.addToPage(page, { x: 250, y: pageHeight - 60, width: fieldHeight, height: fieldHeight })
    virtueField.addToPage(page, { x: 350, y: pageHeight - 60, width: fieldHeight, height: fieldHeight })

    if (param.checkbox) {
      if (param.checkbox.includes('c1')) {
        exiaField.check()
      }
      if (param.checkbox.includes('c2')) {
        kyriosField.check()
      }
      if (param.checkbox.includes('c3')) {
        virtueField.check()
      }
    }

    page.drawText('Radio:', { x: 50, y: pageHeight - 100, size: fontSize })

    page.drawText('r1', { x: 200, y: pageHeight - 100, size: fontSize })
    page.drawText('r2', { x: 300, y: pageHeight - 100, size: fontSize })
    page.drawText('r3', { x: 400, y: pageHeight - 100, size: fontSize })

    const rocketField = form.createRadioGroup('radio')
    rocketField.addOptionToPage('r1', page, { x: 150, y: pageHeight - 100, width: fieldHeight, height: fieldHeight })
    rocketField.addOptionToPage('r2', page, { x: 250, y: pageHeight - 100, width: fieldHeight, height: fieldHeight })
    rocketField.addOptionToPage('r3', page, { x: 350, y: pageHeight - 100, width: fieldHeight, height: fieldHeight })
    param.radio && rocketField.select(param.radio)


    page.drawText('Input:', { x: 50, y: pageHeight - 140, size: fontSize })

    const inputField = form.createTextField('input')
    inputField.setText(param.input)
    inputField.acroField.setDefaultAppearance(defaultDA)
    inputField.setFontSize(14)
    inputField.addToPage(page, { x: 150, y: pageHeight - 140, height: fieldHeight })


    page.drawText('Select:', { x: 50, y: pageHeight - 180, size: fontSize })

    const planetsField = form.createDropdown('select')
    planetsField.addOptions(['s1', 's2', 's3'])
    if (param.select) {
      planetsField.select(param.select)
    }
    planetsField.acroField.setDefaultAppearance(defaultDA)
    planetsField.setFontSize(14)
    planetsField.addToPage(page, { x: 150, y: pageHeight - 180, height: fieldHeight })


    page.drawText('TextArea:', { x: 50, y: pageHeight - 220, size: 14, height: fieldHeight })

    const textField = form.createTextField('textarea')
    textField.setText(param.textarea)
    textField.enableMultiline()
    textField.acroField.setDefaultAppearance(defaultDA)
    textField.setFontSize(14)
    textField.addToPage(page, { x: 150, y: pageHeight - 220 - 40, height: 60 })

    page.drawText('Sign:', { x: 50, y: pageHeight - 300, size: 14, height: fieldHeight })
    if (param.signature) {
      const jpgImage = await pdfDoc.embedJpg(param.signature)
      page.drawImage(jpgImage, {
        x: 150,
        y: pageHeight - 320,
        width: jpgImage.width / jpgImage.height * 50,
        height: 50,
      })
    }

    const pdfBytes = await pdfDoc.save()

    const b64PDF = 'data:application/pdf;base64,' + Buffer.from(pdfBytes).toString('base64');

    PDFObject.embed(b64PDF, this.pdfContainer)
  }, 1000)

  onSubmit = e => {
    e.preventDefault()
    const formData = {
      signature: this.state.signature,
      checkbox: this.state.checkbox,
      radio: this.state.radio,
      input: this.state.input,
      select: this.state.select,
      textarea: this.state.textarea
    }

    if (!this.pdfFile) {
      message.info('load PDF first...')
      return
    }

    this.debouncePreviewPDF(formData)
  }

  handleCreateSignature = (signImg) => {
    console.log('handle signature')
    if (!signImg) {
      message.info('You have not create a signature')
    } else {
      this.setState({signature: signImg})
    }
  }

  render() {
    return (
      <div style={{ textAlign: 'left' }}>
        <p>PDF 签名 Demo 演示：</p>
        <ol>
          <li>选择 PDF 文件</li>
          <li>填写表单</li>
          <li>点击 submit 按钮，将绘制表单到 PDF 中</li>
        </ol>
        <div style={{ marginBottom: 16 }}>
          <input type='file' accept='.pdf' onChange={this.onLoadFile}></input>
        </div>
        <Form
          style={{ width: 500, float: 'left' }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onSubmit={this.onSubmit}
        >
          <Form.Item label="Sign">
            <SignModal onCreateSignature={this.handleCreateSignature} />
            <div style={{width: 200}}>
            {this.state.signature ? <img style={{width: '100%'}} src={this.state.signature} alt="sign" /> : <Empty description='No Signature' />}
            </div>
          </Form.Item>
          <Form.Item label="Checkbox">
            <Checkbox.Group value={this.state.checkbox} onChange={value => this.setState({ checkbox: value })}>
              <Checkbox value="c1">c1</Checkbox>
              <Checkbox value="c2">c2</Checkbox>
              <Checkbox value="c3">c3</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item label="Radio">
            <Radio.Group onChange={e => this.setState({ radio: e.target.value })} value={this.state.radio}>
              <Radio value="r1"> r1 </Radio>
              <Radio value="r2"> r2 </Radio>
              <Radio value="r3"> r3 </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Input">
            <Input value={this.state.input} onChange={e => this.setState({ input: e.target.value })} />
          </Form.Item>
          <Form.Item label="Select">
            <Select value={this.state.select} onChange={value => this.setState({ select: value })}>
              <Select.Option value="s1">s1</Select.Option>
              <Select.Option value="s2">s2</Select.Option>
              <Select.Option value="s3">s3</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="TextArea">
            <TextArea rows={4} value={this.state.textarea} onChange={e => this.setState({ textarea: e.target.value })} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        <div style={{ width: 800, height: 600, float: 'right' }} ref={el => this.pdfContainer = el}></div>
      </div>
    );
  }
}

class SignModal extends Component {

  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  // Adjust canvas coordinate space taking into account pixel ratio,
  // to make it look crisp on mobile devices.
  // This also causes canvas to be cleared.
  resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio = Math.max(window.devicePixelRatio || 1, 1);

    // This part causes the canvas to be cleared
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext("2d").scale(ratio, ratio);

    // This library does not listen for canvas changes, so after the canvas is automatically
    // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
    // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
    // that the state of this library is consistent with visual state of the canvas, you
    // have to clear it manually.
    this.signaturePad.clear();
  }

  handleOk = e => {
    this.setState({
      visible: false,
    });

    if (typeof this.props.onCreateSignature === 'function') {
      if (this.signaturePad.isEmpty()) {
        this.props.onCreateSignature()
      } else {
        this.props.onCreateSignature(this.signaturePad.toDataURL("image/jpeg"))
      }
    }
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  handleClear = e => {
    this.signaturePad.clear()
  }

  handleUndo = e => {
    var data = this.signaturePad.toData();

    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  handleSave = e => {
    if (this.signaturePad.isEmpty()) {
      message.info('Please provide a signature first.');
    } else {
      var dataURL = this.signaturePad.toDataURL("image/jpeg");
      this.download(dataURL, "signature.jpg");
    }
  }

  download(dataURL, filename) {
    var blob = this.dataURLToBlob(dataURL);
    var url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  // One could simply use Canvas#toBlob method instead, but it's just to show
  // that it can be done using result of SignaturePad#toDataURL.
  dataURLToBlob(dataURL) {
    // Code taken from https://github.com/ebidel/filer.js
    var parts = dataURL.split(';base64,');
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  render() {
    return (
      <div>
        <Button type="primary" onClick={this.showModal}>
          Create Signature
        </Button>
        <Modal
          title="Create Signature"
          width={800}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <canvas style={{ width: '100%', height: 400, boxShadow: '0 0 5px rgb(0 0 0 / 2%) inset', border: '1px solid #f4f4f4' }} ref={el => {
            console.log('set canvas ref:', el)
            this.canvas = el
            if (el) {
              if (!this.signaturePad) {
                this.signaturePad = new SignaturePad(this.canvas, {
                  // It's Necessary to use an opaque color when saving image as JPEG;
                  // this option can be omitted if only saving as PNG or SVG
                  backgroundColor: 'rgb(255, 255, 255)'
                });

                this.resizeCanvas();
              }
            }
          }}></canvas>
          <p style={{textAlign: 'center', fontSize: 12, color: 'gray'}}>Sign above</p>
          <Button onClick={this.handleClear}>clear</Button>
          <Button onClick={this.handleUndo}>undo</Button>
          <Button onClick={this.handleSave}>save</Button>
        </Modal>
      </div>
    );
  }

}
