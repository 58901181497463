import { Checkbox } from "antd";
import { Auth } from "aws-amplify";
import moment from "moment";
import React, { Component } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { updateBilledPCMStatus } from "../../action/bliMonthyReportAction";
import ButtonSuccess from "../../home/buttonSuccess";
import "../../share/button.css";
import "../report.css";

export default class ChangeBilledPCM extends Component {

  state = { open: false, loading: false, confirmSuccess: false };

  show = () => this.setState({ open: true, loading: false, confirmSuccess: false });

  handleConfirm = async () => {
    let user = await Auth.currentAuthenticatedUser();

    let inputData = {
      input: {
        billingKey: this.props.userInfo.billingKey,
        userId: this.props.userInfo.userId,
        id: this.props.userInfo.id,
        billedBy: user.username,
        billedStatus: "BILLED",
        siteId: this.props.userInfo.siteId
      }
    };
    this.setState({ loading: true });
    try {
      await updateBilledPCMStatus(inputData);
      this.setState({ confirmSuccess: true });
      setTimeout(() => {
        this.setState({ loading: false });
        this.props.searchUsers();
      }, 300)
    } catch (e) {
      this.setState({ loading: false });
      console.log("updateBilledPCMStatus failed: ", e);
    }
  };

  handleCancel = () => this.setState({ open: false, confirmSuccess: false });

  render() {
    return (
      <Modal
        trigger={
          <div>
            <Checkbox
              onClick={this.show}
              checked={
                this.props.userInfo.billedPCM === "Yes" ||
                this.props.userInfo.billedPCM === "true"
              }
            />
          </div>
        }
        open={this.state.open}
      >
        <Modal.Header>Confirm Patient Information</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <p>
                Carefully review details before clicking submit. The patient's record will be moved to the Reviewed section after confirmation.
              </p>
              <p className="change99457BilledTip">
                Patient:
                <strong className="cofirmBold">
                  {this.props.userInfo.firstName} {this.props.userInfo.lastName}
                </strong>
              </p>
              <p className="change99457BilledTip">
                Monitoring Month:
                <strong className="cofirmBold">
                  {moment(this.props.userInfo.billingKey).format("MMMM YYYY")}
                </strong>
              </p>
              <p className="change99457BilledTip">
                Total Time(mins):
                <strong className="cofirmBold">{this.props.spentTime}</strong>
              </p>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            loading={this.state.loading}
            onClick={this.handleConfirm}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
          >
            <Icon name="checkmark" />
            Submit
          </Button>
          <Button
            basic
            color="red"
            onClick={this.handleCancel}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
